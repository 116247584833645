import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import PropTypes from "prop-types";


class AppointmentsHeader extends Component {
    state = {
        calendarDisabled: this.props.calendarView
    }

    constructor(props) {
        super(props);
    }

    /**
     * The view is changed to grid on grid button click
     */
    changeToGridView = () => {
        let calendarDisabled = true;
        this.setState({calendarDisabled});
        this.props.changeView();
    }

    /**
     * The view is changed to list on list button click
     */
    changeToListView = () => {
        let calendarDisabled = false;
        this.setState({calendarDisabled});
        this.props.changeView();
    }

    render() {
        return (
            <div className='patient-card-header'>
                <div className='row space-between-justify'>
                    <h2 className='card-title card-header-title'>{$$('appointments_label')}</h2>
                    <div className='row patient-view-form-container form-margin'>
                        <button className='btn patient-view-form-btn' disabled={this.state.calendarDisabled}
                                onClick={this.changeToGridView}>
                            <i className='kt-menu__link-icon patient-view-form-icon flaticon2-grids'/>
                            {$$('calendar_label')}
                        </button>
                        <button className='btn patient-view-form-btn' disabled={!this.state.calendarDisabled}
                                onClick={this.changeToListView}>
                            <i className='kt-menu__link-icon patient-view-form-icon flaticon2-list-1'/>
                            {$$('appointments_label')}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

AppointmentsHeader.propTypes = {
    calendarView: PropTypes.bool,
    changeView:PropTypes.func,
    filterUsers: PropTypes.func
}

export default AppointmentsHeader
