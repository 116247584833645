import React, {createContext, useContext, useEffect, useState} from "react";
import {nhisGetNomenclatures} from "../../../../service/nhis_service";
import {sortByLabel, sortByProp, convertToOptions} from "./utils";

export const NhisExaminationContext = createContext(null);

export function NhisExaminationProvider({children}) {
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [nomenclatures, setNomenclatures] = useState();
    const [options, setOptions] = useState();

    const [directedByOptions, setDirectedByOptions] = useState();
    const [examClassOptions, setExamClassOptions] = useState();
    const [purposeOptions, setPurposeOptions] = useState();
    const [financingSourceOptions, setFinancingSourceOptions] = useState();
    const [rhifAreaNumberOptions, setRhifAreaNumberOptions] = useState();
    const [diagnosisRoleOptions, setDiagnosisRoleOptions] = useState();
    const [icdCodeOptions, setIcdCodeOptions] = useState();
    const [clinicalStatusOptions, setClinicalStatusOptions] = useState();
    const [verificationStatusOptions, setVerificationStatusOptions] = useState();
    const [conclusionOptions, setConclusionOptions] = useState();
    const [dischargeDispositionOptions, setDischargeDispositionOptions] = useState();
    const [specialtyOptions, setSpecialtyOptions] = useState();
    const [medicationCodeOptions, setMedicationCodeOptions] = useState();
    const [countyOptions, setCountyOptions] = useState();

    useEffect(() => {
        load();
    }, []);

    const load = () => nhisGetNomenclatures(["CL049", "CL047", "CL060", "CL069", "CL029", "CL076", "CL011", "CL077", "CL078", "CL100", "CL080", "CL006", "CL009", "CL041"]).then((res) => {
        const object = res.reduce(
            (obj, item) => Object.assign(obj, {[item.nomenclatureId]: item}), {});
        setNomenclatures(object);
        const items = Object.getOwnPropertyNames(object);
        const opt = {};
        const toSortbyValue = [];
        const unsorted = [];
        for (const item of items) {
            opt[item] = toSortbyValue.includes(item) ? convertToOptions(object[item], sortByProp("value")) : (unsorted.includes(item) ? convertToOptions(object[item], null) : convertToOptions(object[item], sortByProp("label")));
        }

        setOptions(opt);
        setDirectedByOptions(opt["CL060"]);
        setExamClassOptions(opt["CL049"]);
        setPurposeOptions(opt["CL047"]);
        setFinancingSourceOptions(opt["CL069"]);
        setRhifAreaNumberOptions(opt["CL029"]);
        setDiagnosisRoleOptions(opt["CL076"]);
        setIcdCodeOptions(opt["CL011"]);
        setClinicalStatusOptions(opt["CL077"]);
        setVerificationStatusOptions(opt["CL078"]);
        setConclusionOptions(opt["CL100"]);
        setDischargeDispositionOptions(opt["CL080"]);
        setSpecialtyOptions(opt["CL006"].filter(o => o.item.meta.role?.includes('Лекари') || o.value === "0000"))
        setMedicationCodeOptions(opt["CL009"]);
        setCountyOptions(opt["CL041"]);
        setLoading(false);
        setError(false);
    }).catch(e => {
            setError(true);
            setLoading(false);
    });

    const reload = () => {
        setLoading(true);
        load()
    }


    return <NhisExaminationContext.Provider
        value={{
            nomenclatures,
            options,
            directedByOptions,
            examClassOptions,
            purposeOptions,
            financingSourceOptions,
            rhifAreaNumberOptions,
            diagnosisRoleOptions,
            icdCodeOptions,
            clinicalStatusOptions,
            verificationStatusOptions,
            conclusionOptions,
            dischargeDispositionOptions,
            specialtyOptions,
            medicationCodeOptions,
            countyOptions,
            isLoading,
            isError,
            reload
        }}
    >{children}</NhisExaminationContext.Provider>
}

export function useNhisExaminationContext() {
    const context = useContext(NhisExaminationContext);
    if (!context) {
        throw new Error('NhisExaminationContext must be used within a NhisPrescriptionProvider');
    }
    return context;
}