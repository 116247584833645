import {fetchHelper} from '../helpers/request_helper';
import {USER_GENERAL_PRACTITIONERS_URL} from '../constants/api_paths';

export const generalPractitionerService = {
    fetchSelectedUserGeneralPractitioner
};

/**
 * Get the General Practitioner for the selected user
 *
 * @param {string} userId id of the user to fetch the General Practitioner for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserGeneralPractitioner(userId, params) {
    return fetchHelper.callGet(USER_GENERAL_PRACTITIONERS_URL.replace('{userId}', userId), params);
}
