import React, {Component} from "react";
import {fetchHelper} from "../../helpers/request_helper";
import {
    DOWNLOAD_ENCOUNTER_DOCUMENT_URL, DOWNLOAD_ENCOUNTER_ARCHIVE_ENTRY_FROM_DOCUMENT_URL,
    GET_ENCOUNTER_DOCUMENT_ARCHIVE_CONTENTS_LIST_URL
} from "../../constants/api_paths";
import {documentsService} from "../../service/docments_service";
import {downloadUtils} from "../../utils/downloadUtils";
import DocumentEntry from "../documents/DocumentEntry";
import {$$} from "../../helpers/localization";
import CenteredModal from "../shared/CenteredModal";
import ReactDOM from "react-dom";
import DicomViewer from "../dicom/DicomViewer";
import PropTypes from "prop-types";

export default class EncounterDocuments extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {

        }
    }

    openDocumentInViewer = (v) => {
        if (v.mime_type === "application/zip" || v.mime_type === "application/x-zip-compressed") {
            fetchHelper.callGet(
                GET_ENCOUNTER_DOCUMENT_ARCHIVE_CONTENTS_LIST_URL
                    .replace('{documentId}', v.id)
                    .replace('{encounterId}', this.props.encounter.id))
                .then((res) => {
                    this.setState({openInViewer: v, imageIds: this.toArchiveDocumentURLs(v, res)});
                });
        } else {
            this.setState({openInViewer: v, imageIds: this.toNormalDocumentURL(v)})
        }
    }

    toArchiveDocumentURLs = (doc, list) => {
        let res = {};
        for (const [key, value] of Object.entries(list)) {
            res[key] = value.map((o) => {
                return `wadouri:${DOWNLOAD_ENCOUNTER_ARCHIVE_ENTRY_FROM_DOCUMENT_URL
                    .replace('{documentId}', doc.id)
                    .replace('{archiveEntry}', o)
                    .replace('{encounterId}', this.props.encounter.id)}`
            });
        }
        return res;
    }

    toNormalDocumentURL = (document) => {
        return {
            "": [
                `${DOWNLOAD_ENCOUNTER_DOCUMENT_URL.replace('{documentId}', document.id).replace('{encounterId}', this.props.encounter.id)}`
            ]
        };
    }

    /**
     * On confirm modal, download the file.
     */
    onConfirmModal = () => {
        const fileName = this.state.documentName;
        let documentId = this.state.documentEntryId;
        let encounter = this.props.encounter;
        let appointment = this.props.appointment;
        let encounterId = encounter.id;
        let tthis = this;
        documentsService.downloadEncounterFile(documentId, encounterId).then((file) => {
            downloadUtils.download(file, fileName);
            if (!this.props.encounterDocuments) {
                documentsService.markEncounterDocumentAsRead(documentId, encounterId).then(() => {
                    encounter.not_seen_document_ids.splice(encounter.not_seen_document_ids.indexOf(documentId), 1);
                    if (encounter.not_seen_document_ids.length === 0) {
                        appointment.has_not_seen_patient_documents = false;
                        tthis.props.updateEncounter();
                    }
                })
            }
        });
        this.setState({showModal: false, documentEntryId: null});
    }

    getDocuments = () => {
        if (this.props.documents) {
            return <div>
                {this.props.documents.map(document=>{
                    let unread = this.props.encounter.not_seen_document_ids.indexOf(document.id) !== -1;
                    return <DocumentEntry key={document.id}
                                          loggedInUserId={this.props.loggedInUserId}
                                          encounterDocuments = {this.props.encounterDocuments}
                                          showEncounterDocumentsDeleteConfirmationModal = {this.props.showEncounterDocumentsDeleteConfirmationModal}
                                          showEncounterDocumentsAddUpdateModal = {this.props.showEncounterDocumentsAddUpdateModal}
                                          entry={document}
                                          resetDocument={()=>{}}
                                          selectDocument={()=>{}}
                                          showModal={this.showModal}
                                          i18n={this.props.i18n}
                                          unread={unread}
                                          onOpenInViewer={this.openDocumentInViewer}
                    />
                })}
            </div>
        }
    }

    showModal = (documentEntryId, documentName) => {
        this.setState({showModal: true, documentEntryId: documentEntryId, documentName: documentName});
    }

    render() {
        return (
            <>
                <div className="full-width">
                    <div className='medrec-grey-2 text-center'>{this.props.encounterDocuments ? "" : $$("Test_Result_Documents") }</div>
                    <div className="">
                        {this.getDocuments()}
                    </div>
                </div>
                <hr/>
                <CenteredModal title={$$('document_download_dialog_title')}
                               show={this.state.showModal}
                               onHide={() => this.setState({showModal: false})}
                               onConfirm={this.onConfirmModal}
                               confirmBtnLabel={$$('download_btn')}
                               cancelBtnLabel={$$('cancel_btn')}
                >
                    <p className={"format-file-name"}>{!this.props.encounterDocuments ? $$('confirmation_dialog_message').replace('{fileName}', this.state.documentName).replace('{owner}', this.props.selectedUser.fullname): $$('confirmation_provider_document_dialog_message').replace('{fileName}', this.state.documentName)} </p>
                </CenteredModal>
                {this.state.openInViewer && ReactDOM.createPortal(
                    <DicomViewer document={this.state.openInViewer} onClose={() => {
                        this.setState({openInViewer: undefined, imageIds: undefined})
                    }}
                                 imageIds={this.state.imageIds} selectedUser={this.props.selectedUser}
                    />,
                    document.getElementById('dicom-viewer')
                )}
            </>
        )
    }
}

EncounterDocuments.propTypes = {
    onSelect:  PropTypes.func,
    showEncounterDocumentsDeleteConfirmationModal:  PropTypes.func,
    showEncounterDocumentsAddUpdateModal:  PropTypes.func,
    encounterDocuments: PropTypes.bool,
    selectedUser:  PropTypes.any,
    encounter:  PropTypes.any,
    documents:  PropTypes.any,
    appointment:  PropTypes.any,
    i18n:  PropTypes.any,
}