import React from "react";
import { $$ } from "../../../helpers/localization"
import classNames from 'classnames'

export default class MenuItemText extends React.Component {
  render() {
    const { item, parentItem, unreadMessagesCount } = this.props;
    const unreadMessagesText = unreadMessagesCount > 99 ? "99+" : unreadMessagesCount;
    const menuTextClass = classNames('kt-menu__link-text', {
        'no-bullet-submenu-item': parentItem && !parentItem.bullet
    });

    return (
      <>
        {item.icon && <i className={`kt-menu__link-icon ${item.icon}`} />}

        {parentItem && parentItem.bullet === "dot" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
            <span />
          </i>
        )}

        {parentItem && parentItem.bullet === "line" && (
          <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
            <span />
          </i>
        )}

        <span className={menuTextClass} style={{/*{ whiteSpace: "nowrap" }*/}}>
          {item.translate ? $$(item.translate) : item.title}
        </span>

        {item.badge && (
          <span className="kt-menu__link-badge">
            <span className={`kt-badge ${item.badge.type}`}>
              {item.badge.value}
            </span>
          </span>
        )}

        {item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}

        {/* Number of unread messages for communitacion menu */}
        {(item.title === "Communication" && unreadMessagesCount > 0) && <span className="message-count-label kt-menu__link-text">{unreadMessagesText}</span>}
      </>
    );
  }
}
