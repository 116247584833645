import React from 'react'
import {$$} from '../../helpers/localization'
import WizardHeader from './UserInfoHeader'
import UserInfoTabs from './UserInfoTabs'
import history from "../../helpers/history";
import PropTypes from "prop-types";
import _ from "lodash";


export class UserInfo extends React.Component {
    state = {
        wizardStep: 0,
        completedSteps: {
            firstStep: false,
            secondStep: false,
            thirdStep: false,
            fourthStep: false,
        },
        userFields: _.cloneDeep(this.props.userInfo),
        shouldResend: false,
        showPhoneNumError: false
    }


    constructor(props) {
        super(props)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.registration.response && props.registration.response.status === 403) {
            return {
                wizardStep: 0,
                shouldResend: false
            }
        }
        return state;
    }

    componentDidMount() {
        let documents = []

        for (let i in this.props.documents) {
            let item = new File([""], this.props.documents[i]);
            documents.push(item)
        }

        let userFields = {...this.state.userFields};
        userFields.personalInformationValid = true;
        userFields.practiceValid = true;
        userFields.biographyValid = true;
        userFields.documents = documents;
        userFields.submiting = false;
        this.setState({userFields});
    }

    changeDocuments = (documents) => {
        let documentToUpload = documents.filter(x => !this.state.userFields.documents.includes(x));
        if (documentToUpload.length !== 0) {
            this.props.uploadLoggedUserDocument(documentToUpload[0]);
        } else {
            let documentToDelete = this.state.userFields.documents.filter(x => !documents.includes(x))
            if (documentToDelete.length !== 0) {
                this.props.deleteLoggedUserDocument({"filename": documentToDelete[0].name});
            }
        }
        let userFields = {...this.state.userFields};
        userFields.documents = documents
        this.setState({userFields});
    }

    /**
     * Updates the active wizard step
     *
     * @param {number} wizardStep - the updated/new wizard step
     * @param {boolean} isCompleted - a boolean to indicate whether a step is completed or not
     * @param {boolean} shouldResend - the boolean value which is primary used for server errors
     */
    onWizardStepChange = (wizardStep, isCompleted, shouldResend) => {
        if (this.state.wizardStep === 0) {
            this.prepareStateData('firstStep', isCompleted, wizardStep, false);
        } else if (this.state.wizardStep === 1) {
            this.prepareStateData('secondStep', isCompleted, wizardStep, false);
        } else if (this.state.wizardStep === 2) {
            this.prepareStateData('thirdStep', isCompleted, wizardStep, false);
        } else {
            this.prepareStateData('fourthStep', isCompleted, wizardStep, shouldResend);
        }
    }

    /**
     * Prepares the state data based on the step we are
     *
     * @param {string} step - the current step we are at
     * @param {boolean} isCompleted - a boolean to indicate whether a step is completed or not
     * @param {number} wizardStep - the updated/new wizard step
     * @param {boolean} shouldResend - the boolean value which is primary used for server errors
     */
    prepareStateData = (step, isCompleted, wizardStep, shouldResend) => {
        let completedSteps = {...this.state.completedSteps};
        completedSteps[step] = isCompleted;
        this.setState({wizardStep, completedSteps, shouldResend}, function () {
        });
    }

    handleHeaderClick = (idx) => {
        this.onWizardStepChange(idx, true, false);
    }

    dataChanged = (evt, valid) => {
        let userFields = {...this.state.userFields}
        userFields [evt.target.name] = evt.target.value;
        userFields [valid.name] = valid.value;

        this.setState({userFields})
    }

    validPractice = (userFields) => {
        if (userFields.practiceValid === false) {
            return false;
        }

        if (userFields.organizations.length === 0) {
            return false;
        }

        for (let i in userFields.organizations) {
            let organization = userFields.organizations[i];
            if (!this.validOrganization(organization)) {
                if (organization.phone_numbers.length === 0) {
                    this.setState({practiceValid: false});
                }
                return false;
            }
        }
        return true;
    }

    validOrganization = (org) => {
        return org.name.trim().length > 0 &&
            org.address.trim().length > 0 &&
            org.city.trim().length > 0 &&
            org.country.trim().length > 0 &&
            org.phone_numbers.length > 0 &&
            org.details.trim().length > 0;
    }

    submitForm = (noRedirect) => {
        if (this.state.userFields.personalInformationValid === false) {
            this.handleHeaderClick(0);
            let userFields = {...this.state.userFields};
            userFields.submiting = true;
            this.setState({userFields})
            return;
        }
        if (this.state.userFields.biographyValid === false) {
            this.handleHeaderClick(1);
            let userFields = {...this.state.userFields};
            userFields.submiting = true;
            this.setState({userFields})
            return;
        }

        if (!this.validPractice(this.state.userFields)) {
            this.handleHeaderClick(2);

            let userFields = {...this.state.userFields};
            userFields.submiting = true;

            this.setState({userFields})
            return;
        }

        let specialities = []
        let languages = []

        for (let i in this.state.userFields.specialties) {
            if (this.state.userFields.specialties[i].value) {
                specialities.push(this.state.userFields.specialties[i].value)
            } else {
                specialities = this.state.userFields.specialties
            }

        }

        for (let i in this.state.userFields.languages) {
            if (this.state.userFields.languages[i].value) {
                languages.push(this.state.userFields.languages[i].value)
            } else {
                languages = this.state.userFields.languages
            }
        }


        let form = {
            "city": this.state.userFields.city,
            "country": this.state.userFields.country,
            "email": this.state.userFields.email,
            "fullname": this.state.userFields.fullname,
            "id": this.state.userFields.id,
            "languages": languages,
            "organizations": this.state.userFields.organizations,
            "other_info": this.state.userFields.other_info,
            "phone_numbers": this.state.userFields.phone_numbers,
            "contact_email": this.state.userFields.contact_email,
            "specialties": specialities,
            "nhis_specialties": this.state.userFields.nhis_specialties,
            "title": this.state.userFields.title,
            "uin": this.state.userFields.uin
        }

        if (this.state.userFields.personalInformationValid && this.state.userFields.biographyValid && this.state.userFields.practiceValid) {
            let res = this.props.updateUserInfo(form);
            if (!noRedirect) {
                history.push("/");
                return;
            }
            return res
        }
    }

    render() {
        return (
            <>
                <div className='wizard-container'>
                    <div>
                        <WizardHeader wizardStep={this.state.wizardStep}
                                      wizardSteps={[
                                          {step: 0, name: $$('wizard_personal_information'), completed: true},
                                          {step: 1, name: $$('wizard_biography'), completed: true},
                                          {step: 2, name: $$('wizard_practice_details'), completed: true},
                                          {step: 3, name: $$('wizard_upload_documents'), completed: true}]}
                                      onHeaderClick={this.handleHeaderClick}
                                      onWizardStepChange={this.onWizardStepChange}
                                      i18n={this.props.i18n}
                        />
                    </div>
                    <div className={"user-info-tabs"}>
                        <UserInfoTabs wizardStep={this.state.wizardStep}
                                      updateUserInfo={this.props.updateUserInfo}
                                      onWizardStepChange={this.onWizardStepChange}
                                      completedSteps={this.state.completedSteps}
                                      registrationFields={this.state.userFields}
                                      dataChanged={this.dataChanged}
                                      submitForm={this.submitForm}
                                      documents={this.props.documents}
                                      specialties={this.props.specialties}
                                      changeDocuments={this.changeDocuments}
                                      formDisabled={this.props.formDisabled}
                                      showPhoneNumError={this.state.showPhoneNumError}
                                      i18n={this.props.i18n}/>
                    </div>
                </div>
            </>
        )
    }
}

UserInfo.propTypes = {
    deleteLoggedUserDocument: PropTypes.func,
    documents: PropTypes.array,
    formDisabled: PropTypes.object,
    i18n: PropTypes.object,
    registration: PropTypes.object,
    specialties: PropTypes.array,
    updateUserInfo: PropTypes.func,
    uploadLoggedUserDocument: PropTypes.func,
    userInfo: PropTypes.object
}

export default (UserInfo)
