export const sq = {
    text: {
        title: "Medrec:M Clinic",
        main_page_label: "Rekordet mjekësore",
        select_language_label: "Zgjidhni gjuhën",
        login_label: "Kyçuni",
        logout_label: "Dilni",
        save_btn_label: "Ruaj",
        username_label: "Username",
        email_label: "E-mail",
        password_creation_title:"Fjalëkalimi duhet të përmbajë:",
        at_Lest_eight_characters:"Të paktën 8 karaktere",
        at_Lest_one_uppercase_characters:"Të paktën një karakter me shkronja të mëdha (A-Z)",
        at_Lest_one_lowercase_characters:"Të paktën një karakter të vogël (A-Z)",
        at_Lest_one_number:"Të paktën një numër (0-9)",
        at_Lest_one_special:"Të paktën një personazh special (~!@#$%^&*_-+=`|\\(){}[]:;\"'<>,.?/)",
        password_is_not_valid:"Fjalëkalimi nuk është i vlefshëm (Shih Kërkesat e Fjalëkalimit)",
        password_label: "Fjalëkalimi",
        new_password_label: "Fjalëkalimi i ri",
        repeat_password_label: "Përsërit fjalëkalimin",
        change_password_label: "Ndrysho fjalëkalimin",
        user_password_updated_message: 'Fjalëkalimi u përditësua me sukses',
        user_password_update_error_message: 'Gabim në server!',
        email_required_message: "E-mail është i detyrueshëm!",
        password_required_message: "Fjalëkalimi është i detyrueshëm!",
        login_form_invalid_credentials_message: "Kredenciale të pa sakta!",
        register_label: "Regjistrohu",
        register_form_email_exists_message: "E-mail është ekzistues!",
        register_form_email_not_correct_message: "E-mail nuk është i saktë!",
        register_form_passwords_not_match_message: "Fjalëkalimet nuk përputhen!",
        register_form_password_length_message: "Fjalëkalimi duhet të ketë minimumi 8 karaktere!",
        register_form_password_whitespace_message: "Fjalëkalimi nuk duhet të fillojë ose të mbarojë me hapësira!",
        no_records_found: "Nuk u gjendën rekorde",
        register_form_successful_message: "Regjistrimi u përfundua me sukses!",
        register_form_website_not_correct_message: 'Faqja e internetit nuk është e saktë!',
        confirm_btn_label: "Konfirmo",
        close_btn_label: "Mbyll",
        Charts: "Grafikët",
        Logbook: "Ditari",
        logbook_entries_header: " ",
        charts_header: " ",
        Systolic: "Sistolik",
        Diastolic: "Diastolik",
        Pulse: "Pulsi",
        notes: "Shënime",
        close_notes: "Mbyll Shënime",
        total_cholesterol: "Kolesteroli total",
        triglycerides: "Trigliceridet",
        Cholesterol: "Kolesteroli",
        glucose_units: "Njësia e glukozës",
        ketones_units: "Njësia e ketoneve",
        hba1c_units: "Njësia e HBA1C",
        Weight: "Pesha",
        Height: "Gjatësia",
        weight_and_height: "Pesha dhe gjatësia",
        chronic_conditions: "Gjendjet kronike",
        allergies: "Alergji",
        blood_group: "Grupi i gjakut",
        details: "Detaje",
        blood_pressure: "Presioni i gjakut",
        settings_label: "Konfigurime",
        unit_of_measurement_label: "Njësia e matjes",
        cholesterol_units_label: "Njësia e kolesterolit",
        classification_method_label: "Metoda e klasifikimit",
        date_format_label: "Formati i datës",
        time_24hour_label: "Koha format 24 orë",
        settings_updated_message: "Konfigurimet u ruajtën me sukses",
        US: "US (lbs, oz, fl oz, inch)",
        metric: "Metrikë (kg, gram, ml, cm)",
        kg: "Kg",
        pound: "Pound",
        inches: "inch",
        cm: "cm",
        None: "Asnjë",
        diagnosed_in: "Diagnostikuar në",
        mild: "E lehtë",
        mild_to_moderate: "E lehtë në të moderuar",
        moderate: "E moderuar",
        moderate_to_severe: "E moderuar në të rëndë",
        severe: "E rëndë",
        life_threatening: "Rrezik për jetën",
        drug_allergy: "Alergji ndaj barnave",
        dust_allergy: "Alergji ndaj pluhurit",
        food_allergy: "Alergji ndaj ushqimit",
        insect_allergy: "Alergji ndaj insekteve",
        latex_allergy: "Alergji ndaj laktozës",
        mold_allergy: "Alergji ndaj mykut",
        pet_allergy: "Alergji ndaj kafshëve shtëpiake",
        pollen_allergy: "Alergji ndaj polenës",
        other_allergy: "Alergji të tjera",
        Severity: "Ashpërsia",
        Medications: "Mjekime",
        millimol_per_litre: "mmol/L",
        percent: "%",
        millimol_per_mol: "mmol/mol",
        milligram_per_decilitre: "mg/dL",
        millimetre_of_mercury: "mmHg",
        week_label: "7 ditë",
        fortnight_label: "14 ditë",
        month_label: "30 ditë",
        quarter_label: "90 ditë",
        custom_label: "Personalizuar",
        Symptoms: "Simptoma",
        Temperature: "Temperatura",
        Hydration: "Hidrimi",
        Respiratory_rate: "Shkalla e frymëmarrjes",
        Saturation: "Saturimi i oksigjenit",
        celcius: "°C",
        ml: "ml",
        bpm: "bpm",
        fahrenheight: "°F",
        floz: "fl oz",
        refresh_token_expired_message: 'Sesioni përfundoi',
        sharing_label: "Lejet e aksesit",
        schedule_label: "Plani i punës",
        messages_label: "Mesazhet",
        pending_permission_list_label: "Lejet e aksesit në pritje për aprovim",
        approved_permission_list_label: "Lejet e aksesit të aprovuara",
        deny_permission_label: "Moho",
        approve_permission_label: "Aprovo",
        request_label: "Kërko",
        request_permission_label: "Kërko akses",
        requested_user_does_not_exist_message: "Përdoruesi me këtë e-mail nuk ekziston",
        premission_request_success_message: "Kërkesa për leje u realizua me sukses",
        forgot_password: "Keni harruar fjalëkalimin?",
        privacy_policy: "Politikat e privatësisë",
        gender_label: "Gjinia",
        country_label: "Shteti",
        male: "Mashkull",
        female: "Femër",
        city_label: "Qyteti",
        birthday_label: "Datëlindja",
        Dashboard: "Faqja kryesore",
        user_info_label: "Të dhënat e përdoruesit",
        latest_measurements_label: "Matjet e fundit",
        last_week_data: "Grafikët e javës",
        no_recent_measurements: "Nuk ka matje të bëra kohët të fundit",
        loading_data_label: "Duke ngarkuar të dhënat...",
        logbook_no_data_label: "Ky përdorues nuk ka shënuar akoma të dhëna.",
        vitals_logbook_no_appointments: "Nuk keni vizita",
        vitals_logbook_no_notes: "Nuk ka shënime",
        symptoms_no_data_label: "Ky përdorues nuk ka shënuar akoma simptoma.",
        search_placeholder_label: "Kërko...",
        latest_symptoms_label: "Simptomat e 24 orëve të fundit",
        no_latest_symptoms_label: "Nuk ka simptoma të shënuara 24 orët e fundit",
        profile_label: 'Profili',
        edit_profile_label: 'Edito profilin',
        update_btn: 'Modifiko',
        cancel: 'Anulo',
        not_available: 'Jo i disponueshëm',
        available: 'I disponueshëm',
        edit_schedule: "Edito skedulin",
        delete: "Fshi",
        update: "Modifiko",
        holiday: "Pushim zyrtar",
        upload_image_label: 'Ngarko foto profili',
        user_info_updated_message: 'Profili u përditësua me sukses',
        unsupported_image_format: 'Formati i imazhit të zgjedhur nuk është i suportuar',
        choose_file_label: 'Zgjidh',
        Documents: "Dokumente",
        category_lobal: "Kategori",
        document_type_label: "Tipi i dokumentit",
        type_description_label: "Përshkrim i tipit",
        document_download_dialog_title: "Shkarko dokumentin",
        confirmation_dialog_message: "Dokumenti ({fileName}) të cilin ju doni të shkarkoni i përket {owner} dhe përmban të dhëna sensitive. Ruajeni në një vend të sigurtë ose fshijeni pasi të mbaroni punë.",
        documents_no_data_label: "Ky përdorues nuk ka ngarkuar akoma dokumente.",
        download_btn: "Shkarko",
        cancel_btn: "Anulo",
        document_type_none: "Asnjë",
        document_type_epicrisis: "Epikrizë",
        document_type_prescription: "Recetë",
        document_type_lab_results: "Rezultatet laboratorike",
        document_type_ambulance_sheet: "Raport mjekësor",
        document_type_referral: "Rekomandim",
        document_type_other: "Të tjera",
        document_type_diagnostic_imaging: "Imazhe diagnostike",
        all_label: "Të githa",
        select_category: "Zgjidhni kategorinë",
        documents_no_data_for_filters_label: "Nuk ka dokumente të cilat korrespondojnë me filtrat e zgjedhur.",
        pending_for_user_approval_label: "Përdoruesit të cilët duhet të pranojnë kërkesën tuaj",
        start_date: "Datë nga",
        end_date: "Datë tek",
        load_more: "Shiko më shumë",
        afternoon: "Drekë",
        morning: "Mëngjes",
        evening: "Mbrëmje",
        select_gender: "Zgjidhni gjininë",
        my_documents_label: "Dokumentat e mia",
        Blood_sugar: "Sheqeri në gjak",
        HBA1C: "HbA1c",
        Ketones: "Ketone",
        Flow: "Rrjedhja",
        Color: "Ngjyra",
        Consistency: "Konsistenca",
        Urine_pH: "pH i urinës",
        no_flow: "Pa rrjedhje",
        light: "Lehtë",
        medium: "Mesatar",
        new_message_from: "Nga",
        heavy: "Rëndë",
        unexpected: "Pa pritur",
        none: "Asnjë",
        black: "E zezë",
        brown: "Kafe",
        dark_red: "E kuqe e errët",
        bright_red: "E kuqe e hapur",
        pink: "Rozë",
        orange: "Portokalli",
        gray: "Gri",
        waterly: "E lengshme",
        clots: "Mpiksur",
        mmol_mol: "mmol/mol",
        no_chronic_conditions: "Pa gjendje kronike",
        no_allergies: "Pa alergji",
        Communication: "Komunikim",
        my_communication_label: "Komunikimet e mia",
        new_chat: "Chat i ri",
        recent_chats_label: "Komunikimet e fundi",
        available_users_label: "Përdoruesit",
        back: "Mbrapa",
        type_message: "Shkruaj një mesazh...",
        call: "Thirrje hyrëse",
        incoming_call_message: "Thirrje hyrëse nga %1",
        incoming_call_another_user_message: "Thirrje hyrëse nga një përdorues tjetër",
        accept: "Prano",
        no_user_selected_message: 'Asnje përdorues i zgjedhur për të thirrur',
        ok: 'ok',
        no_answer: "Pa përgjigje",
        no_answer_message: "Asnjë përgjigje nga %1",
        you_wrote: "Ti ke shkruar",
        missed_call_label: "Thirrje e humbur",
        rejected_call_label: "Thirrje e refuzuar",
        in_progress_call_label: "Thirrje në progres",
        completed_call_label: "Thirrje e përfunduar",
        ongoing_call: "Videocall në progres!",
        general_practitioner: "Mjek i Përgjithshëm (Mjeku i Familjes)",
        practice_name: "Emri i Klinikës",
        practice_address: "Adresa e Klinikës",
        practice_contact: "Numri i kontaktit",
        practice_centre_code: "Kodi i Klinikës",
        practice_practitioner_code: "Kodi i Mjekut",
        na: "Jo e disponueshme",
        medication_plan: "Plani i mjekimit",
        current_medications: "Barnat aktuale",
        times_per_day: "%1 %2 x %3 herë në ditë",
        every_x_hours: "%1 %2 x çdo %3 orë",
        daily: 'Përditë',
        every_x_days: "Çdo X ditë",
        take_x_rest_x: "Merr X ditë dhe pusho Y ditë",
        when_needed: "Kur duhet",
        ongoing: "Në vazhdimësi",
        until_date: 'Deri në datën ',//%1',
        for_x_days_until: "Për X ditë",// deri më %2",
        effective_from: "Efektive nga %1",
        get_help_label: "Merr Ndihmë",
        appointments_label: 'Vizita',
        appointments_for: 'Vizita Për ',
        today: "Sot",
        month: "Muaj",
        week: "Javë",
        day: "Ditë",
        this_week: "Java aktuale",
        list: "List",
        Lab_Results: "Rezultatet laboratorike",
        select_laboratory_label: "Zgjidhni laboratorin",
        lab_results_no_data_primary_label: "Pacienti nuk ka rezultate nga laboratorët",
        documents_no_data_primary_label: "Përdoruesi nuk ka ngarkuar ende dokumenta",
        documents_no_data_secondary_label: " ",
        all_patients: "Të gjithë pacientët",
        lab_results_no_data_secondary_label: "Pacienti nuk ka të dhëna",
        lab_test_col_name_name: "Група показател",
        lab_test_col_name_result: "Резултат",
        lab_test_col_name_units: "Мерни единици",
        lab_test_col_name_flag: "Флаг",
        lab_test_col_name_range: "Референтни стойности",
        lab_microbiology_col_name_antibiogram: "Антибиограма",
        select_image: 'Zgjidh',
        preview_image: 'Shiko',
        OK: 'OK',
        delete_image_modal_tittle: 'Hiqni imazhin',
        delete_image_modal_message: 'Jeni të sigurt që doni të hiqni imazhin tuaj?',
        upload_documents: 'Ngarko dokument të ri',
        delete_document_entry_modal_title: 'Fshi dokumentin',
        delete_document_entry_modal_message: 'Jeni të sigurt që dëshironi të fshini këtë dokument?',
        patients_label: 'Pacientët',
        search_for_patients_label: 'Kërko për pacientë',
        search_patient_label: 'Kërko pacient',
        search_in_messages_label: "Kërko për mesazhe",
        no_messages_to_show: "Nuk ka mesazhe për t'u shfaqur",
        messages_will_appear_here: "Mesazhet do të shfaqen këtu",
        view_patients_details_label: 'Shiko detaje',
        grid_view_label: 'Tabelë',
        list_view_label: 'Listë',
        year_old: 'vjeç',
        years_old: 'vjeç',
        medical_history: 'Historiku mjekësor',
        about: 'Rreth nesh',
        contact: 'Kontakt',
        select_today_label: 'Sot',
        next_patient_label: 'Pacienti',
        no_pending_appointments_primary_label: 'Nuk keni vizita',
        no_patients_primary_label: 'You have no patients',
        no_staff_primary_label: 'Nuk keni staf për të menaxhuar',
        this_week_label: 'Këtë jave',
        this_month_label: 'Këtë muaj',
        no_pending_appointments_label: 'Nuk keni vizita',
        appointment_label: 'Vizitë',
        eccounter_data_separator: "Më shumë informacion",
        calendar_label: 'Kalendar',
        calendars_label: 'Kalendarët',
        mark_all: "Selektoji të gjitha",
        unmark_all: "Deselektoji të gjitha",
        holidays_label: "Pushimet zyrtare",
        book_time_off: "Rezervo pushimet",
        new_schedule: "Fillimi i skedulit",
        start: "Fillo",
        time_on: "Kohë për punë",
        time_off: "Kohë e lirë",
        from: "Nga",
        to: "Deri",
        end: "Fund",
        all_day: "Gjithë ditën",
        mon: "Hën",
        tue: "Mar",
        wed: "Mër",
        thu: "Enj",
        fri: "Pre",
        sat: "Shtu",
        sun: "Die",
        repeat_for: "Përsërit për",
        do_not_repeat: "Mos përsërit",
        one_week: "1 javë",
        one_month: "1 muaj",
        one_year: "1 vit",
        set: "Vendos",
        set_new_schedule: "Vendos fillimi i skedulit",
        appointment_type_label: 'Tipi i vizitës',
        last_appointment_label: 'Vizita e fundit',
        country_city_label: 'Shtet, Qyteti',
        recent_messages_title: 'Mesazhet e fundit',
        age_label: 'Mosha',
        price_settings_label: 'Lista e Çmimeve',
        price_list_for: 'Lista e Çmimeve Për ',
        schedule_for: 'Orari Për ',
        new_appointment_type: 'Tip i ri vizite',
        edit_appointment_type: 'Modifiko tipin e vizitës',
        create: 'Krijo',
        edit: 'Modifiko',
        exam_type: 'Tipi i Ekzaminimit',
        name: 'Emri',
        organisation: 'Organizimi',
        add: "Shtoni",
        add_organisation: "Shtoni organizimin",
        all: "All",
        location: 'Vendndodhja',
        currency: 'Valuta',
        price: 'Çmimi',
        online: 'Aktiv',
        on_site: 'Në Klinikë',
        primary_exam: 'Ekzaminimi kryesor',
        follow_up_exam: 'Ekzaminim pasues',
        other: 'Tjetër',
        your_price_list_is_empty: "Lista juaj e çmimeve është bosh",
        delete_modal_header: "A je i/e sigurt që do të fshish listimin {name}?",
        delete_modal_body: "Ky listim do të fshihet menjëherë. Nuk mund ta kthesh këtë veprim.",
        waiting_for_doctor_approval: "Duke pritur për konfirmim",
        waiting_for_patient_approval: "Duke pritur për konfirmim",
        need_more_info: "Kërkohet më shume informacion",
        accepted: "Konfirmuar",
        completed: "Përfunduar",
        rejected_by_patient: "Refuzuar nga pacienti",
        canceled_by_patient: "Anulluar nga pacienti",
        rejected_by_provider: "Refuzuar nga mjeku",
        canceled_by_provider: "Anulluar nga mjeku",
        lab_results_filter_label: 'Filtro',
        lab_results_date_label: 'Data',
        lab_results_tests_label: 'Testimet',
        date_label: "Data",
        condition_label: "Gjendja",
        status_label: "Statusi",
        main_diagnosis_label: "Diagnoza kryesore",
        objective_data_label: "Të dhëna objektive",
        subjective_data_label: "Të dhëna subjektive",
        therapy_label: "Terapia",
        concomitant_diseases_label: "Sëmundjet shoqëruese",
        tests_label: "Testet",
        no_encounter_found_label: "Nuk u gjet asnjë vizit",
        category_label: "Kategori",
        title_label: "Titull",
        update_note: "Modifiko shënimin",
        create_note: "Krijo shënim",
        note_required_message: "Shënimet janë të detyrueshme!",
        delete_note_modal_header: "Konfirmoni veprimin",
        delete_note_modal_text: "Jeni i sigurt që doni ta fshini këtë shënim?",
        private_note_label: "Privat",
        to_all_note_label: "Të gjithë",
        provider_only_note_label: "Vetëm mjeku",
        update_visibility: "Ndrysho shikueshmerinë",
        visibility_label: "Shikueshmëria",
        visibility_required_message: "Shikueshmëria është e detyrueshme!",
        delete_label: "Fshi",
        share_label: "Ndaj",
        edit_label: "Ndrysho",
        general_note_label: "Shënim i përgjithshëm",
        new_note_label: "Shënim i ri",
        examination_result_label: "Examination result",
        print_label: "Printo",
        diagnosis_label: "Diagnoza",
        subjective_data_required_message: "Të dhënat subjektive janë të detyrueshme!",
        objective_data_required_message: "Të dhënat objektive janë të detyrueshme!",
        diagnosis_required_message: "Diagnoza është e detyrueshme!",
        therapy_required_message: "Terapia është e detyrueshme!",
        Vitals_Logbook: "Ditari i parametrave jetësorë",
        health_issues_label: "Problemet shëndetësore",
        staff: "Stafit",
        search_for_staff: "Kërko për Staf",
        uin: "UIN:",
        patient_pin: "PIN",
        no_results_found: "Nuk u gjendën rezultate",
        user_logs_label: "Regjistrimet e përdoruesit",
        administrative_portal_label: "Portali Administrativ",
        manage_your_practice: "Identifikohu si administrator",
        select_a_practice: "Zgjidhni një praktikë për të menaxhuar",
        select_practice: "Zgjidhni Praktikë",
        Menstruation: "Menstruacionet",
        vitals_logbook_no_data_primary_label: "Nuk ka rezultate në ditarin e parametrave jetësorë të pacientit",
        note_label: "Shënim",
        no_selected_user: "Përzgjidhni një takim",
        selected_user_will_appear_here: "Informacioni për pacientin do të shfaqet këtu",
        short_sunday_label: "Die",
        short_monday_label: "Hën",
        short_tuesday_label: "Mar",
        short_wednesday_label: "Mër",
        short_thursday_label: "Enj",
        short_friday_label: "Pre",
        short_saturday_label: "Sht",
        open_appointment_label: "Hap vizitën",
        morning_time_label: "Koha e ilaçit në mëngjes",
        noon_time_label: "Koha e ilaçit në drekë",
        evening_time_label: "Koha e ilaçit në darkë",
        bed_time_label: "Koha e ilaçit para gjumit",
        morning_time_required_message: "Koha e ilaçit në mëngjes është e detyrueshme!",
        noon_time_required_message: "Koha e ilaçit në drekë është e detyrueshme!",
        evening_time_required_message: "Koha e ilaçit në darkë është e detyrueshme!",
        bed_time_required_message: "Koha e ilaçit para gjumit është e detyrueshme!",
        complete_appointment_button_label: "Përfundo ekzaminimin",
        complete_appointment_modal_header: "Doni të vazhdoni?",
        complete_appointment_modal_body: "Duke e përfunduar ekzaminimin ju do të lejoni pacientin të shikojë rezultatin e ekzaminimit. Ky veprim është i pakthyeshëm.",
        completed_label: "I PËRFUNDUAR",
        notifications_label: "Njoftimet",
        see_all_label: "Shiko të gjitha",
        minimize_label: "Minimizo",
        created_new_appointment_label: " krijoi një takim të ri për ",
        canceled_their_appointment_label: " anuloi takimin e tij për ",
        seen_label: "lexuar",
        no_new_notifications_label: "Nuk ka njoftime të reja",
        no_notifications_label: "Nuk ka njoftime",
        cancel_appointment_label: "Anulo vizitën",
        patient_information_label: "Të dhënat e pacientit",
        cancel_appointment_confirmation_header: "Jeni i sigurt që doni ta anuloni vizitën me {fullname} për datën {date}?",
        cancel_appointment_confirmation_body: "Kjo vizitë do të anulohet në mënyrë të menjëhershme. Ju nuk mund ta ktheni ketë veprim.",
        remove_label: "Hiq",
        wizard_personal_information: 'Informacion personal',
        wizard_biography: 'Biografia',
        wizard_practice_details: 'Detaje mbi praktikën',
        wizard_upload_documents: 'Ngarko dokumenta',
        wizard_personal_information_fullname_label: "Emri i plotë*",
        wizard_personal_information_title_label: 'Titulli',
        wizard_personal_information_password_confirm_label: "Konfirmo fjalëkalimin*",
        wizard_personal_information_uin_label: 'Numri identifikues*',
        wizard_personal_information_specialties_label: 'Specialitetet*',
        specialties_label: 'Specialitetet',
        wizard_personal_information_fullname_required_label: "Emri i plotë është i detyrueshëm!",
        wizard_personal_information_password_confirm_required_label: "Konfirmimi i fjalëkalimit është i detyrueshëm!",
        wizard_personal_information_uin_required_label: "Numri identifikues është i detyrueshëm!",
        wizard_personal_information_specialties_required_label: 'Specialitete janë të detyrueshme!',
        wizard_biography_languages_label: 'Gjuhët*',
        wizard_biography_languages_required_label: 'Gjuhët janë të detyrueshme!',
        wizard_biography_label: 'Biografia*',
        wizard_biography_required_label: 'Biografia është e detyrueshme!',
        wizard_practice_details_org_name_label: 'Emri i organizatës*',
        wizard_practice_details_address_label: 'Adresa*',
        wizard_practice_details_website_label: 'Faqja e internetit',
        wizard_practice_details_org_name_required_label: 'Emri i organizatës është i detyrueshëm!',
        name_required_label: 'Emri është i detyrueshëm!',
        bgn:"Levë Bullgare",
        euro:"Euro",
        lek:"Leke Shqiptare",
        gbp:"Stërlina Britanike",
        usd:"Dollari i Shteteve të Bashkuara",
        organisation_already_registered: "Organizata është regjistruar tashmë!",
        wizard_practice_details_address_required_label: 'Adresa është e detyrueshme',
        wizard_practice_details_label: 'Detajet e praktikës*',
        wizard_practice_details_required_label: 'Detajet e praktikës janë të detyrueshme!',
        wizard_upload_documents_title: 'Ngarko dhe hidh dokumentat e tua ose kliko për të zgjedhur një skedar',
        wizard_upload_documents_unsupported_file_message: 'Ju lutemi hiqni dokumentat që nuk suportohen!',
        wizard_upload_documents_dropzone_label: 'JPG, PNG, PDF, DOC, DOCX, HTML, TXT, CSV dhe më pak se 10MB',
        wizard_upload_documents_file_error_message: 'Tipi i skedarit nuk është i lejuar ose madhësia e tij kalon limitin e lejuar',
        wizard_upload_documents_required_label: 'Dokumentat janë të detyrueshme!',
        wizard_city_required_label: 'Qyteti është i detyrueshëm!',
        wizard_phone_numbers_label: 'Numrat e telefonit*',
        phone_numbers_label: 'Numrat e telefonit',
        wizard_phone_numbers_required_label: 'Numrat e telefonit janë të detyrueshme!',
        wizard_provider_image_label: 'Ngarkoni foton tuaj',
        wizard_back_label: 'Kthehu',
        wizard_continue_label: 'Vazhdo',
        wizard_completed_modal_title: 'Regjstrimi përfundoi!',
        wizard_completed_modal_message: 'Faleminderit që u regjistruat te Medrec:M Clinic! Të dhënat tuaja do të shqyrtohen së shpejti. Ndërkohë ju mund të familjarizoheni me Medrec:M Clinic. Faleminderit!',
        wizard_back_modal_title: 'Regjistrimi',
        wizard_back_modal_message: 'Jeni të sigurt që doni të ktheheni?',
        obstetrics_gynecology: 'Obstetrikë dhe Gjinekologji',
        allergology: 'Alergologji',
        angiology: 'Angiologji',
        venereology: 'Venerologji',
        internal_medicine: 'Mjekësi e Brendshme',
        gastroenterology: 'Gastroenterologji',
        thoracic_surgery: 'Kirurgji torakale',
        dental_medicine: 'Mjekësi Dentare',
        dermatology: 'Dermatologji',
        pediatric_gastroenterology: 'Gastroenterologji për fëmijë',
        pediatric_endocrinology: 'Endokrinologji për fëmijë',
        pediatric_neurology: 'Neurologji për fëmijë',
        pediatric_nephrology: 'Nefrologji për fëmijë',
        child_psychiatry: 'Psikiatria për fëmijët',
        pediatric_pulmonology: 'Pulmonologji për fëmijë',
        pediatric_rheumatology: 'Reumatologji për fëmijë',
        endocrinology: 'Endokrinologji',
        immunology: 'Immunologji',
        cardiology: 'Kardiologji',
        cardiac_surgery: 'Kirurgji kardiake',
        clinical_genetics: 'Gjenetika klinike',
        speech_therapy: 'Logopedi',
        mammology: 'Mamologji',
        microbiology: 'Mikrobiologji',
        neurology: 'Neurologji',
        neurological_surgery: 'Kirurgji Neurologjike',
        neonatology: 'Neonatologji',
        nephrology: 'Nefrologji',
        nuclear_medicine: 'Mjekësi nukleare',
        imaging_diagnostics: 'Imazheri',
        general_medicine: 'Mjekësi e përgjithshme',
        oncology: 'Onkologji',
        orthodontics: 'Ortodonci',
        orthopedics_traumatology: 'Ortopedi dhe Traumatologji',
        otoneurology: 'Otoneurologji',
        ophthalmology: 'Oftalmologji',
        pediatrics: 'Pediatri',
        psychiatry: 'Psikiatri',
        psychology: 'Psikologji',
        psychotherapy: 'Psikoterapi',
        pulmonology: 'Pulmonologji',
        rheumatology: 'Reumatologji',
        vascular_surgery: 'Kirurgji Vaskulare',
        ent: 'ORL',
        urology: 'Urologji',
        physical_medicine_rehabilitation: 'Mjekësi fizike dhe Rehabilitim',
        hematology: 'Hematologji',
        surgery: 'Kirurgji',
        homeopathy: 'Homeopati',
        payment_initial: "Jo e paguar",
        payment_in_progress: "Pagesa në proces",
        payment_rejected: "Jo e paguar",
        payment_completed: "E paguar",
        accept_appointment_confirmation_header: "Jeni të sigurt që doni të aprovoni vizitën në datën {date} për {fullname}?",
        accept_appointment_confirmation_body: "Kjo vizitë do të aprovohet menjëherë.",
        accept_appointment_label: "Aprovo",
        table_header_morning_label: "Mëngjes",
        table_header_afternoon_label: "Pasdite",
        table_header_evening_label: "Mbrëmje",
        table_header_night_label: "Natë",
        pulse_pressure_label: "Pulsi",
        mean_arterial_pressure_label: "Presioni mesatar arterial",
        table_header_min_label: "Minimum",
        table_header_max_label: "Maksimum",
        table_header_avg_label: "Mesatare",
        table_header_low_label: "I/E Ulët",
        table_header_normal_label: "Normal/e",
        table_header_high_label: "I/E lartë",
        last_7_days: "7 ditët e kaluara",
        daily_avg_title: "Mesatarja ditore",
        min_max_avg_title: "Minimalja, maksimalja dhe mesatarja",
        category_counts_title: "Numrat për kategori",
        total_count_measurements_label: "Numri total i matjeve",
        classification_category_label: "Kategori",
        category_low: 'E ulët',
        category_optimal: 'Optimale',
        category_normal: 'Normale',
        category_normal_high: 'Mbi-Normale',
        category_grade_1: 'Hipertension grada I',
        category_grade_2: 'Hipertension grada II',
        category_grade_3: 'Hipertension grada III',
        category_elevated: 'E rritur',
        category_stage_1: 'Hipertension faza 1',
        category_stage_2: 'Hipertension faza 2',
        category_hypertensive_crysis: 'Kriza hipertensive',
        category_prehypertension: 'Pre-hipertension',
        category_stage_3: 'Hipertension faza 3',
        category_high: 'E lartë',
        cardiac_tab_label: 'Cardiac',
        email_required_label: "E-mail*",
        contact_email:"Email kontakti",
        register_form_password_confirm_required_message: "Konfirmimi i fjalëkalimit është i detyrueshëm",
        country_required_label: "Shteti*",
        city_required_label: "Qyteti*",
        password_required_label: "Fjalëkalimi*",
        just_now_label: 'Tani',
        dictate: "Dikto",
        stop_dictating: "Ndalo Diktimin",
        add_prescription: "Shto Recetë",
        update_prescription: "Modifiko Recetën",
        medication_name_label: "Emri i mjekimit",
        unit_label: "Njësia",
        dose_label: "Sasia",
        days_between_intake: "Një herë në sa ditë",
        days: "ditë",
        number_times_per_day: "Sa herë në ditë",
        number_of_days: "Sa ditë",
        schedule: "Programi",
        duration: "Kohëzgjatja",
        frequency: "Frekuenca",
        frequency_times: "Merr një vlerë",
        frequency_hours: "Numri i orëve",
        frequency_times_day: "X herë në ditë",
        frequency_every_hours: "Çdo X orë",
        take: "Merre",
        rest: "Pusho",
        cycle_day: "Dita e ciklit ",
        unit_type_ampule: "ampulë(a)",
        unit_type_application: "aplikim(e)",
        unit_type_capsule: "kapsulë(a)",
        unit_type_drop: "pikë(a)",
        unit_type_gram: "g",
        unit_type_injection: "injeksion(e)",
        unit_type_mg: "mg",
        unit_type_ml: "ml",
        unit_type_packet: "paketë(a)",
        reset_btn_label: "Rikthe",
        unit_type_patch: "pjesë",
        unit_type_piece: "copë(a)",
        unit_type_tablet: "tabletë(a)",
        unit_type_puff: "thithje",
        unit_type_spray: "spraj(e)",
        unit_type_suppository: "suposto",
        unit_type_tbsp: "lugë gjelle",
        unit_type_tsp: "lugë çaji",
        unit_type_unit: "njësi",
        no_label: "Jo",
        start_date_label: "Data e fillimit",
        end_date_label: "Data e përfundimit",
        delete_prescription: "Fshi Recetën",
        delete_prescription_message: "A jeni i sigurtë që doni ta fshini këtë recetë?",
        prescriptions: "Recetat",
        version: "Version",
        create_new_patient_btn: "Krijo një pacient të ri",
        create_and_register_new_patient_btn: "Krijo dhe regjistrohu",
        create_and_register_new_patient_label: "Krijo dhe regjistro një pacient të ri",
        fullname_label:"Emri",
        phone_label:"Numrat e telefonit",
        appointment_table_provider_label:"Te",
        first_available_hour_label: "Orari i parë i lirë",
        book_label: "Rezervo",
        select_appointment_type_message: "Të lutem, vendos llojin e takimit që të shohësh oraret e lira.",
        no_hours_available_primary_label: "Jo orë të lira",
        appointment_created:"Ju keni krijuar me sukses një vizitë të re.",
        create_appointment:"Rezervoni një takim",
        price_list_is_empty: "Listë çmimesh bosh!",
        choose_clinician_modal_title:"Zgjidhni një mjek për këtë vizitë",
        latest_registrations: "Regjistrimet e fundit",
        clinicians_label: "Klinikët",
        total_amount: "Total",
        amount: "Shuma",
        accounting_menu: "Raportet",
        accounting_page_title: "Raportet",
        login_form_temp_locked_down_message: "Llogaria juaj është bllokuar përkohësisht për shkak të përpjekjeve të përsëritura të dështuara për hyrje. Ju lutemi prisni disa minuta përpara se të provoni të identifikoheni përsëri.",
        login_form_must_reset_password_message: "Llogaria juaj është e bllokuar për shkak të përpjekjeve të përsëritura të dështuara për hyrje. Mund ta zhbllokoni duke rivendosur fjalëkalimin tuaj duke përdorur funksionin Harrove fjalëkalimin.",
        appointments_configuration:"Rishikoni cilësimet",
        start_video:"Fillo videon",
        stop_video:"Ndalo videon",
        no_video:"Nuk ka video",
        no_audio:"Pa audio",
        mute:"Çaktivizo zërin",
        unmute:"Aktivizo zërin",
        join_now: "Bashkohu tani",
        enter_examination_room: "Hyni në dhomën e provimit",
        entering_examination_room: "Hyrja në dhomën e provimit",
        audio: "Audio",
        audio_input:"Hyrja audio",
        video: "Video",
        video_input:"Hyrja video",
        audio_and_video_settings: "Cilësimet audio dhe video",
        no_local_audio: "Nuk ka audio lokale",
        done_btn:"Gati",
        no_local_video:"Nuk ka video lokale",
        You:"Ju",
        patient_label: "Pacienti",
        clinician_label: "Doktor",
        free_label:"Falas",
        zoom_in_schedule:"Zmadhoni orarin",
        zoom_out_schedule:"Zvogëlo orarin",
        delete_time_off_description:"Dëshiron ta heqësh këtë periudhë pushimi nga orari?",
        create_new_busy_slot_description:"Dëshiron ta shtosh këtë periudhë pushimi në orar?",
        select_action_timeslot_or_calendar:"Zgjidh midis shtimit të një Kohë pushimi ose krijimit të një takimi të ri për këtë periudhë",
        mark_time_off:"Shto kohë joaktive",
        select_appointment_type_message_for_schedule: "Zgjidh një takim",
        appointment_creation_failed:"Ndodhi një gabim në server gjatë krijimit të një takimi të ri!",
        date_of_payment:"Data e pagesës",
        date_of_appointment:"Data e takimit",

        medication: {
            unit: {
                label: {
                    ampule: {
                        display_long: 'ampulë(a)',
                        display_short: ''
                    },
                    application: {
                        display_long: 'aplikim(e)',
                        display_short: ''
                    },
                    capsule: {
                        display_long: 'kapsulë(a)',
                        display_short: ''
                    },
                    drop: {
                        display_long: 'pikë(a)',
                        display_short: ''
                    },
                    gram: {
                        display_long: 'gram',
                        display_short: 'g'
                    },
                    injection: {
                        display_long: 'injeksion(e)',
                        display_short: ''
                    },
                    mg: {
                        display_long: 'miligram',
                        display_short: 'mg'
                    },
                    ml: {
                        display_long: 'mililitër(a)',
                        display_short: 'ml(s)'
                    },
                    packet: {
                        display_long: 'paketë(a)',
                        display_short: ''
                    },
                    patch: {
                        display_long: 'pjesë',
                        display_short: ''
                    },
                    piece: {
                        display_long: 'copë(a)',
                        display_short: ''
                    },
                    tablet: {
                        display_long: 'tabletë(a)',
                        display_short: ''
                    },
                    puff: {
                        display_long: 'thithje',
                        display_short: ''
                    },
                    spray: {
                        display_long: 'spraj(e)',
                        display_short: ''
                    },
                    suppository: {
                        display_long: 'suposto',
                        display_short: ''
                    },
                    tbsp: {
                        display_long: 'lugë gjelle',
                        display_short: ''
                    },
                    tsp: {
                        display_long: 'lugë çaji',
                        display_short: ''
                    },
                    unit: {
                        display_long: 'njësi',
                        display_short: ''
                    }
                }
            }
        }
    }
}
