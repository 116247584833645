/* eslint-disable */
import {
    UPDATE_CALL_DATA,
    UPDATE_CAMERA_DEVICES
} from "../../actions/actions";
import {chatService} from "../../service/chat_service";
import store from "../../store";
import {
    CALLING,
    IDLE,
    ONGOING,
    STARTING_CALL
} from "../../constants/call_status";
import {
    COMPLETED,
    MISSED
} from "../../constants/video_message_status";
import moment from 'moment'

export const VideoManager = {

    state: {
        callStatus: IDLE
    },


    setState(obj) {
        this.state = {...this.state, ...obj}
        this.updateVideoData(this.state);
    },

    updateVideoData: function (data) {
        store.dispatch({type: UPDATE_CALL_DATA, result: data})
        /*window && window.QB && window.QB.webrtc && window.QB.webrtc.getMediaDevices("videoinput").then(function (devices) {
            if (devices.length) {
                store.dispatch({type: UPDATE_CAMERA_DEVICES, result: devices})
            }
        });*/
    },

    getVideoData: function () {
        return store.getState().video.data;
    },

    /*getVideoCameras(cameraNo) {
        const currentSession = this.session;
        var deviceId;
        var deviceLabel;
        var audioDeviceId;
        let videoData = this.getVideoData();
        window && window.QB && window.QB.webrtc && window.QB.webrtc.getMediaDevices("videoinput").then(function (devices) {
            if (devices.length) {
                store.dispatch({type: UPDATE_CAMERA_DEVICES, result: devices})
                // here is a list of all available cameras
                if (devices.length == 2) {
                    var deviceInfo = devices[cameraNo];
                    deviceId = deviceInfo.deviceId;
                    deviceLabel = deviceInfo.label;
                    audioDeviceId = deviceInfo.audioDeviceId;
                }
                var constraints = {
                    audio: audioDeviceId || undefined,
                    video: {exact: deviceId}
                };
                if (currentSession) {
                    currentSession.switchMediaTracks(constraints, function (error, stream) {
                        stream.getAudioTracks()[0].enabled = videoData.audioMuted ? false : true
                    }.bind(this));
                }
            }
        });
    },

    onSwitchVideoCamera(cameraNo, devices) {
        const currentSession = this.session;
        var deviceId;
        var deviceLabel;
        var audioDeviceId;
        let videoData = this.getVideoData();
        if (devices.length) {
            // here is a list of all available cameras
            for (var i = 0; i < devices.length; i++) {
                if (cameraNo == i) {
                    var deviceInfo = devices[i];
                    deviceId = deviceInfo.deviceId;
                    deviceLabel = deviceInfo.label;
                    audioDeviceId = deviceInfo.audioDeviceId;
                }
            }
        }
        var constraints = {
            audio: audioDeviceId || undefined,
            video: {exact: deviceId}
        };
        if (currentSession) {
            currentSession.switchMediaTracks(constraints, function (error, stream) {
                stream.getAudioTracks()[0].enabled = videoData.audioMuted ? false : true
            }.bind(this));
        }
    },*/


};
