import React, {Component} from 'react'
import PropTypes from "prop-types";
import {$$} from "../../../helpers/localization";
import CenteredModal from "../../shared/CenteredModal";
import {connect} from "react-redux";

class DocumentDeleteModal extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return <>
            <CenteredModal title={$$('mark_document_entry_deleted_modal_message')}
                           dialogClassName='doc-delete-modal'
                           show={this.props.show}
                           onHide={this.props.onHide}
                           onConfirm={this.props.onConfirm}
                           cancelBtnLabel={$$('cancel_btn')}
                           id="delete-modal-title"
                           className="center-delete-modal"
                           idFooter="footer-delete-modal"
                           confirmBtnLabel={$$('delete_label')}
                           confirmBtnClass="danger"
                           idBtnPrimary="btn-danger"
                           idBtnSecondary="btn-secondary">
                {this.props.selectEntry ? this.props.selectEntry.title : ''} {$$('document_delete_label')}<br/>
                <div className="bin-align">
                    <i className="fas fa-trash-alt fa-3x"/>
                </div>
            </CenteredModal>
        </>
    }
}

DocumentDeleteModal.propTypes = {
    show: PropTypes.any,
    onHide: PropTypes.any,
    onConfirm: PropTypes.any,
    selectEntry: PropTypes.any
}

function mapStateToProps(state) {
    return {
        i18n: state.language.selected,
        settings: state.settings,
        formDisabled: state.formInteractions,
        userInfo: state.userInfo.data
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDeleteModal);
