import React, {useEffect, useState} from "react";
import {clinicService} from "../../../../service/clinic_service";
import {dateAsStr, NHIS_ID_TYPE_OPTIONS} from "./NhisPrescription";
import {nhisFetchNhisExam, nhisLogin, nhisSignFetchNhisExam} from "../../../../service/nhis_service";
import {$$, _$$} from "../../../../helpers/localization";
import DatePicker from "react-datepicker";
import {dateTimeUtils} from "../../../../utils/dateTimeUtils";
import Select from "../../../shared/Select";
import {getResolvedOptions} from "../../../../constants/select_options";
import moment from "moment";
import {useNhisExaminationContext} from "./NhisExaminationProvider";
import Modal from "react-bootstrap/Modal";

const toPatientIdType = (patient) => {
    switch (patient.patientIDType) {
        case "EGN":
            return "1";
        case "LNZ":
            return "2";
        case "SSN":
            return "3";
        case "PASS":
            return "4";
        case "OTHER":
            return "5";
        case "NEWBORN":
            return "6";
    }
}

export default function ImportFromNhisComponent({onClose, patientId, clinicianUIN, orgId, i18n, startTime, onCreate}) {
    const [nrnInput, setNrnInput] = useState("");
    const [egnInput, setEgnInput] = useState("");
    const [d, setD] = useState(new Date(startTime));
    const [idType, setIdType] = useState("1");

    const [sendingInProgress, setSendingInProgress] = useState(false);
    const [error, setError] = useState();
    const [formClass, setFormClass] = useState("");
    const [results, setResults] = useState([]);
    const [examToImport, setExamToImport] = useState();
    const [showImportDialog, setShowImportDialog] = useState(false);
    const [importInProgress, setImportInProgress] = useState(false);

    const {isLoading, isError, reload, medicationCodeOptions, icdCodeOptions, conclusionOptions, dischargeDispositionOptions} = useNhisExaminationContext();

    useEffect(() => {
        clinicService.fetchEncPatientInfo(patientId, orgId).then(patient => {
            if (patient) {
                if (patient.patientID) {
                    setEgnInput(patient.patientID)
                }
                if (patient?.patientIDType) {
                    setIdType(toPatientIdType(patient));
                }
            }

        })
    }, [])

    const search = (e) => {
        e.preventDefault();
        setError(undefined)

        if (!e.target.checkValidity()) {
            setFormClass("was-validated")
            return;
        }

        let message = {
            senderId: clinicianUIN,
            identifierType: egnInput ? idType : null,
            identifier: egnInput ? egnInput : null,
            openDate: d ? dateAsStr(d) : null,
            nrnExamination: nrnInput ? nrnInput : null
        };

        setSendingInProgress(true);
        setError(undefined);
        setResults([]);
        nhisLogin((loginSuccess) => {
            nhisSignFetchNhisExam(message, (signedRequest) => {
                nhisFetchNhisExam(loginSuccess.accessToken, signedRequest)
                    .then(async results => {
                        const res = results.filter(r => {
                            if (r.performer.pmi !== clinicianUIN) {
                                return false;
                            }

                            if (r.subject.identifier !== egnInput) {
                                return false;
                            }

                            if (nrnInput && nrnInput !== r.examination.nrnExamination) {
                                return false;
                            }
                            return true;
                        });
                        setResults(res);
                        if (res.length === 0) {
                            setError($$("no_results_found"))
                        }
                        setSendingInProgress(false)
                    }).catch((e) => {
                    e.then(res => {
                        let contents = res["nhis:message"]["nhis:contents"];
                        let error = contents["nhis:error"];
                        if (error) {
                            if (Array.isArray(error)) {
                                setError(error[0]["nhis:reason"]["value"]);
                            } else {
                                setError(error["nhis:reason"]["value"]);
                            }
                        } else {
                            setError("Error occured");
                        }
                    })
                    setSendingInProgress(false);
                })
            }, (e) => {
                setError(e)
                setSendingInProgress(false);
            })
        }, (e) => {
            setSendingInProgress(false);
            setError(e);
        })

    }

    const importExam = async (e) => {
        let encounter = fromNhisToEncounter(e, patientId, medicationCodeOptions, icdCodeOptions, conclusionOptions, dischargeDispositionOptions);
        await onCreate(encounter);
        setShowImportDialog(false);
        setExamToImport(null);
        onClose();
    };

    if (showImportDialog) {
        return <Modal show={true} onHide={()=>{}}>
            <Modal.Header>
                <h5>{$$("nhis.examination.import_exam_modal_title")}</h5>
            </Modal.Header>
            <Modal.Body>
                <p className="whitespace-pre-line">
                    {_$$("nhis.examination.import_exam_modal_body", examToImport.examination.nrnExamination, dateTimeUtils.getFormattedDateWithTime(examToImport.examination.openDate))}
                </p>

                {error && <p className="text-danger">{error}</p>}
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger" disabled={importInProgress} onClick={async ()=>{
                    setImportInProgress(true)
                    try {
                        await importExam(examToImport)
                    } catch (e) {
                        console.error(e)
                        if (e?.status === 409) {
                            setError($$("nhis.examination.import_server_error_conflict"))
                        } else {
                            setError($$("nhis.examination.import_server_error"))
                        }
                        setImportInProgress(false)
                    }
                }}>{$$("nhis.examination.import_btn")}</button>
                <button type="button" className="btn btn-secondary ml-2" onClick={()=>{setShowImportDialog(false); setExamToImport(null); setError(null)}}>{$$("cancel_btn")}</button>
            </Modal.Footer>
        </Modal>
    }

    return <div className="mt-2 p-2">
        <form onSubmit={search} noValidate={true} className={formClass}>
            {!sendingInProgress && <div className="d-flex align-items-end w-100">
                <div>
                    <label>{$$("nhis.patient.idType")}</label>
                    <Select name="patientIDType"
                            options={getResolvedOptions(NHIS_ID_TYPE_OPTIONS)}
                            value={idType}
                            class="custom-select"
                            required={true}
                            onChange={({value}) => setIdType(value)}
                            placeHolder={$$('nhis.patient.idType')}>
                    </Select>
                </div>
                <div className="ml-2">
                    <label>{$$("nhis.patient.personal_id")}</label>
                    <input type="text" required={true} className="form-control" value={egnInput} onChange={(e) => {
                        setEgnInput(e.target.value)
                    }}/>
                </div>
                <div className="ml-2">
                    <label>{$$("nhis.examination.open_date")}</label>
                    <div>
                        <DatePicker
                            locale={i18n.lang}
                            selected={d}
                            onChange={date => {
                                setD(date);
                            }}
                            required={!nrnInput}
                            selectsStart
                            startDate={d}
                            isClearable
                            dateFormat="dd/MM/yyyy"
                            placeholderText={$$('nhis.examination.open_date')}
                            className="form-control w-100"/>
                    </div>
                </div>
                <div className="ml-2">
                    <label>{$$("nhis.examination.nrn_number")}</label>
                    <input type="text" className="form-control"
                           required={!d && !egnInput}
                           value={nrnInput} onChange={(e) => {

                        let value = e.target.value;
                        setNrnInput(value ? value.toUpperCase() : value)
                    }}/>
                </div>
            </div>}

            {!sendingInProgress && <div className="d-flex justify-content-start mt-3">
                <button type="submit" className="btn btn-primary">{$$("nhis.examination.search_nhis")}</button>
                <button type="button" className="btn btn-primary ml-3" onClick={() => {
                    onClose();
                }}>{$$("cancel_btn")}</button>
            </div>
            }
            {sendingInProgress && <div className="d-flex justify-content-center p-4">
                <h5>{$$("nhis.processing_body")}</h5>
            </div>}
        </form>

        {error && <div className="text-danger pt-2 pb-2">{error}</div>}

        {results && results.length > 0 && <table className="table table-sm table-striped medrec-default-text-color">
            <thead>
            <tr>
                <th>{$$("nhis.examination.nrn_number")}</th>
                <th>{$$("nhis.examination.status")}</th>
                <th>{$$("nhis.patient.title")}</th>
                <th>{$$("nhis.patient.personal_id")}</th>
                <th>{$$("nhis.provider.pmi")}</th>
                <th>{$$("nhis.examination.main_diagnosis")}</th>
                <th>{$$("nhis.examination.open_date")}</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {results.map(r => {
                let statusKey = "nhis.examination.status_" + r.examination.status;
                return <tr key={r.examination.nrnExamination}>
                    <td>{r.examination.nrnExamination}</td>
                    <td><span className={`${r.examination.status !== '2' ? "text-danger":""}`}>{$$(statusKey)}</span></td>
                    <td>{r.subject.givenName} {r.subject.familyName}</td>
                    <td className={`${egnInput && r.subject.identifier != egnInput ? "text-danger" : ''}`}>{r.subject.identifier}</td>
                    <td>{r.performer.pmi}</td>
                    <td>{r.examination.diagnosis.code}</td>
                    <td className={`${d && dateAsStr(d) !== dateAsStr(r.examination.openDate) ? "text-danger" : ''}`}>{dateTimeUtils.getFormattedDateWithTime(r.examination.openDate)}</td>
                    <td>
                        {r.examination.status === '2' && <a href="#" onClick={(e) => {
                        e.preventDefault();
                        setExamToImport(r)
                        setShowImportDialog(true)
                    }}>{$$("nhis.examination.import_action_link")}</a>}
                    </td>
                </tr>
            })}
            </tbody>
        </table>}
    </div>
}

function fromNhisToEncounter(res, patientId, medicationCodeOptions, icdCodeOptions, conclusionOptions, dischargeDispositionOptions) {
    const { childHealthcare_age, closeDate, openDate, comorbidity, motherHealthcare_isPregnant, motherHealthcare_isBreastFeeding, motherHealthcare_gestationalWeek, therapy, class:classification, ...exam} = res.examination;

    let model = { examination: _.cloneDeep(exam), performer: _.cloneDeep(res.performer), subject: _.cloneDeep(res.subject)};
    model.examination.classification = classification;
    model.examination.isPregnant = motherHealthcare_isPregnant;
    model.examination.isBreastFeeding = motherHealthcare_isBreastFeeding;
    model.examination.gestationalWeek = motherHealthcare_gestationalWeek;
    model.examination.additionalDiagnoses = comorbidity || [];
    model.examination.note = therapy?.note ? therapy.note : null;
    model.examination.medicationCodeOption = therapy?.medicationCode ? medicationCodeOptions.filter(o => therapy.medicationCode.includes(o.value)) : null;
    model.examination.closeDate = dateTimeUtils.toOffsetDateTime(closeDate);
    model.examination.openDate = dateTimeUtils.toOffsetDateTime(openDate);

    let enc =  {};
    enc.nhis_examination_json = JSON.stringify(model);
    enc.subject = patientId;
    enc.objective_data = model.examination.objectiveCondition || "";
    enc.subjective_data = model.examination.medicalHistory || "";

    if (model.examination.diagnosis.code) {
        enc.main_diagnosis_code = model.examination.diagnosis.code;
        enc.main_diagnosis = icdCodeOptions.find(o => o.value == enc.main_diagnosis_code).text;
    } else {
        enc.main_diagnosis_code = "";
        enc.main_diagnosis = ""
    }

    if (['10','11','12'].includes(model.examination.diagnosis.verificationStatus)) {
        enc.preliminary_diagnosis = true;
    }

    let add_notes = "";
    if (model.examination.diagnosis.note) {
        add_notes = model.examination.diagnosis.note;
    }

    if (model.examination.diagnosis.additionalCode) {
        enc.concomitant_diseases = model.examination.diagnosis.additionalCode.map(c => icdCodeOptions.find(o => o.value == c).text);
        enc.concomitant_diseases_codes = [...model.examination.diagnosis.additionalCode]
    } else {
        enc.concomitant_diseases = []
        enc.concomitant_diseases_codes = []
    }

    model.examination.additionalDiagnoses.forEach(d => {
        if (d.code) {
            if (!enc.concomitant_diseases_codes.includes(d.code)) {
                enc.concomitant_diseases.push(icdCodeOptions.find(o => o.value == d.code).text);
                enc.concomitant_diseases_codes.push(d.code);
            }
        }

        if (d.additionalCode) {
            let concomitantDiseases = d.additionalCode.map(c => icdCodeOptions.find(o => o.value == c).text);
            enc.concomitant_diseases = [...enc.concomitant_diseases, ...concomitantDiseases];
            enc.concomitant_diseases_codes = [...enc.concomitant_diseases_codes, ...d.additionalCode];
        }

        if (d.note) {
            add_notes += add_notes ? "\n" + d.note : d.note;
        }
    })


    enc.tests = [];

    if (model.examination.medicationCodeOption) {
        enc.therapy = model.examination.medicationCodeOption.map(a => a.text).join("; ");
    } else {
        enc.therapy = "";
    }

    if (model.examination.note) {
        enc.therapy = enc.therapy ? enc.therapy + "\n" + model.examination.note : model.examination.note;
    }


    if (model.examination.conclusion) {
        enc.conclusion = conclusionOptions.find(o => o.value === model.examination.conclusion).text;
    }

    if (model.examination.dischargeDisposition) {
        let text = dischargeDispositionOptions.find(o => o.value === model.examination.dischargeDisposition).text;
        enc.conclusion = enc.conclusion ? enc.conclusion + "\n" + text : text;
    }

    enc.additional_info = add_notes;
    enc.nhis_examination_json = JSON.stringify(model);
    enc.nhis_examination_nrn = exam.nrnExamination;
    enc.update_nhis = false;
    return enc;
}