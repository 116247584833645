import React, {Component} from 'react';
import {$$} from "../../helpers/localization";
import {formatUtils} from "../../utils/formatUtils";
import PropTypes from "prop-types";
import no_appointments from "../../resources/images/no_appointments.png";
import {infoUtils} from "../../utils/infoUtils";
import {appointmentUtils} from "../../utils/appointmentUtils";
import {PriceDirectLink} from "../price-settings/PriceSettingsListView";

class StaffPriceList extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
    }

    getLocationType = (menuItem) => {
        switch (menuItem.location_type) {
            case 'ONLINE':
                return menuItem.encounter_price_type === 'TEXT_ONLY' ? $$('text_consultation') : $$('online');
            case 'ON_SITE':
                return $$('on_site');
        }
    }

    getEncounterType = (menuItem) => {
        switch (menuItem.encounter_type) {
            case 'PRIMARY_EXAM':
                return $$('primary_exam');
            case 'FOLLOW_UP_EXAM':
                return $$('follow_up_exam');
            case'OTHER':
                return menuItem.name
        }
    }

    getNameAndLocationCell = (menuItem) => {
        return <td key={++this.index} className='patient-name-cell'>
            <div className='row'>
                <div className={"dropdown-link"}>
                    <span className='patient-fullname'>{this.getEncounterType(menuItem)}</span>
                    <br/>
                    <span
                        className='medrec-grey-2'>{this.getLocationType(menuItem)}</span>
                </div>
            </div>
        </td>;
    }

    getPriceCell = (MenuItem) => {
        return <td key={++this.index} className='patient-name-cell'>
            <div className='row'>
                <div className={"dropdown-link"}>
                    <span
                        className='patient-fullname'>{formatUtils.currencyFormat(MenuItem.price_cents, MenuItem.currency)}</span>
                </div>
            </div>
        </td>;
    }

    getLinkCell = (MenuItem) => {
        return <td key={MenuItem.id} className='text-center'>
            <PriceDirectLink price={MenuItem} lang={this.props.lang} />
        </td>;
    }

    getDetailsCell = (MenuItem) => {
        let isLoggedInUser = this.props.loggedInUserId === this.props.selectedStaffMember.id;
        let canEditPrice = isLoggedInUser ? this.props.permissions.canEditOwnPrices() : this.props.permissions.canEditStaffPrices();
        let canDeletePrice = isLoggedInUser ? this.props.permissions.canDeleteOwnPrices() : this.props.permissions.canDeleteStaffPrices();
        return <td key={++this.index} className='text-right'>
            {canEditPrice && <input
                type="button"
                className="btn btn-secondary date-picker-button"
                value={$$("edit")}
                onClick={() => this.props.handleEditPriceClick(MenuItem)}
            />}
            &nbsp;
            {canDeletePrice && <input
                type="button"
                className="btn btn-secondary date-picker-button"
                value={$$("delete")}
                onClick={() => this.props.handleDeletePriceClick(MenuItem)}
            />}
        </td>;
    }

    getRow = (menuItem, idx) => {
        let cells = [];
        cells.push(this.getNameAndLocationCell(menuItem));
        cells.push(this.getPriceCell(menuItem));
        cells.push(this.getLinkCell(menuItem));
        cells.push(this.getDetailsCell(menuItem));
        return <tr key={idx}>{cells}</tr>;
    }

    getBody = () => {
        let allRows = [];
        for (let i = 0; i < this.props.priceList.length; ++i) {
            if (this.props.priceList[i].organization_id === this.props.organisationId) {
                allRows.push(this.getRow(this.props.priceList[i], i))
            }
        }
        return [].concat.apply([], allRows);
    }

    noOptions = () => {
        let noPrices = {
            imgClass: 'no-vitals-logbook-img',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: 'no-lab-results-secondary-label',
            src: no_appointments,
            primaryLabel: $$('your_price_list_is_empty'),
            secondaryLabel: ''
        }
        return infoUtils.noData(noPrices);
    }

    hasRecords = () => {
        for (let i = 0; i < this.props.priceList.length; ++i) {
            if (this.props.priceList[i].organization_id === this.props.organisationId) {
                return true
            }
        }
        return false
    }

    render() {
        return (
            <>
                {this.hasRecords() ? <div className='card-body patient-list-view'>
                    <div className='row' style={{'marginLeft': '0px', 'marginRight': '0px'}}>
                        <div className='col-xs-12 col-md-12'>
                            <div className='row'>
                                <div className='patient-table-container'>
                                    <table id='patients' style={{'width': '100%'}}>
                                        <tbody>
                                        {this.getBody()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : this.noOptions()}
            </>
        )
    }
}

StaffPriceList.propTypes = {
    priceList: PropTypes.any,
    selectedStaffMember: PropTypes.any,
    organisationId: PropTypes.any,
    handleEditPriceClick: PropTypes.func,
    handleDeletePriceClick: PropTypes.func
}

export default StaffPriceList;