import React, {Component} from 'react'
import {connect} from 'react-redux'
import {updateAppointmentStatus} from '../../actions/users_actions'
import PropTypes from "prop-types";
import UserImage from "../shared/UserImage";
import {$$} from "../../helpers/localization";
import {getClinicianNameWithTitle} from "../../utils/getClinicianNameWithTitle";


class StaffVerticalCard extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    getSpecialities = (staffMember) => {
        let specialtiesList = staffMember.specialties.map((h, idx) => {
            return <div key={idx} className='patient-health-issue-list'>{$$(h.toLowerCase())}</div>
        });

        return <div key={++this.index} className='text-center patient-health-issue-list-cell'>
            <div className='row left-justify'>{specialtiesList}</div>
        </div>;
    }

    staffHeader = () => {
        let staffImageClass = 'patient-img';

        return (
            <div>
                <br/>
                <div className="row zero-margin-row">
                    <div className="no-img-container">
                        <UserImage userID={this.props.selectedStaffMember.id}
                                   classnames={staffImageClass}/>
                    </div>
                    <div className="row space-between-justify no-margin">
                        <div className="dropdown-link">
                            <div className="patient-name">{getClinicianNameWithTitle(this.props.selectedStaffMember)}</div>
                            <div className="lighter-font">{this.props.selectedStaffMember.email}</div>
                        </div>
                    </div>

                </div>
                <br/>
                <div className="row next-patient-row">
                    <div className="col-sm-6 col-md-6">
                        <div className="medrec-grey-2 next-patient-row-label">{$$('phone_numbers_label')}</div>
                        <div>{this.props.selectedStaffMember.phone_numbers?.join(", ")}</div>
                    </div>
                </div>
            </div>
        )
    }

    staffSpecialities = () => {
        return (
            <div>
                <div className="medrec-grey-2">{$$('specialties_label')}</div>
                <div className="row allergies-conditions-padding zero-margin-row">
                    {this.getSpecialities(this.props.selectedStaffMember)}
                </div>
            </div>
        )
    }

    body = () => {
        return (
            <div className='vertical-patient-card-body'>
                {this.staffHeader()}
                <hr/>
                {this.staffSpecialities()}
            </div>
        )
    }


    render() {
        return (
            <div className='right-column dashboard-card staff-info'>
                {this.body()}
            </div>
        )
    }
}

StaffVerticalCard.propTypes = {
    appointment: PropTypes.object,
    hide: PropTypes.bool,
    i18n: PropTypes.object,
    medicalProfile: PropTypes.object,
    medication: PropTypes.object,
    selectedStaffMember: PropTypes.object,
    appointmentsView: PropTypes.any,
    updateAppointmentStatus: PropTypes.func,
    cancelAppointment: PropTypes.func
}

const mapStateToProps = (state, props) => ({
    selectedUser: props.selectedUser || state.selectedUser.data,
    medicalProfile: state.medicalProfile.data,
    medication: state.medication,
    appointment: props.appointment || state.selectedAppointmentInProgress
});

export default connect(mapStateToProps, {updateAppointmentStatus})(StaffVerticalCard)