import React from 'react';


import MicIcon from './icons/MicIcon';
import MicOffIcon from './icons/MicOffIcon';

import useLocalAudioToggle from './hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from './hooks/useVideoContext/useVideoContext';
import {Button} from "react-bootstrap";
import {$$} from "../../helpers/localization";

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  return (
    <Button
      className={props.className}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      data-cy-audio-toggle
    >
      <span className="mr-2 text-white">{isAudioEnabled ? <MicIcon /> : <MicOffIcon />}</span>
      {$$(!hasAudioTrack ? 'no_audio' : isAudioEnabled ? 'mute' : 'unmute')}
    </Button>
  );
}
