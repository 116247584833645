import React, {Component} from 'react';
import {CONVERTER} from '../../utils/converter'
import _ from 'underscore';
import PropTypes from "prop-types";


export class SymptomEntry extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
    }

    /**
     * Get symptom entry
     *
     * @returns {HTMLElement} the symptom entry
     */
    getSymptomEntry = () => {
        if (this.props.entry && !_.isEmpty(this.props.entry)) {
            return <div className='row zero-margin-row'>
                <div className='medrec-grey-2 vitals-logbook-time entry-time'>
                    <i className='kt-menu__link-icon far fa-clock'/> &nbsp;
                    {CONVERTER.getEntryDateTimeFormat(this.props.datetime)}
                </div>
                <div>
                    {this.getSymptomName()}
                </div>
                &nbsp;&nbsp;
                <div className={"no-symptoms"}>
                    {this.props.entry.type !== 'NO_SYMPTOMS' ? this.getStrengthBar() : this.getStrengthBarElement('light-red-dot', 'light-red-dot', 'light-red-dot')}
                </div>
            </div>
        }
    }

    /**
     * Returns the symptoms name
     *
     * @returns {string} - the symptom name
     */
    getSymptomName() {
        let lang;
        // eslint-disable-next-line no-prototype-builtins
        if (this.props.nomenclature.hasOwnProperty(this.props.i18n.lang)) {
            lang = this.props.i18n.lang;
        } else {
            lang = "en";
        }

        let symptom = this.props.nomenclature[lang].all.find(n => n.type === this.props.entry.type);
        if (symptom) {
            return symptom.description;
        } else {
            if (this.props.entry.description) {
                return this.props.entry.description;
            }
            return "N/A";
        }
    }

    /**
     * Prepares the appropriate strength bar based on the symptom strength
     *
     * @returns {HTMLElement} the strength bar of the symptom
     */
    getStrengthBar = () => {
        switch (this.props.entry.strength) {
            case 0:
                return this.getStrengthBarElement('light-red-dot', 'light-red-dot', 'light-red-dot');
            case 1:
                return this.getStrengthBarElement('red-dot', 'light-red-dot', 'light-red-dot');
            case 2:
                return this.getStrengthBarElement('red-dot', 'red-dot', 'light-red-dot');
            default:
                return this.getStrengthBarElement('red-dot', 'red-dot', 'red-dot');
        }
    }

    /**
     * Prepares the strength bar element based on the classes passed to it
     *
     * @returns {HTMLElement} - the div along with its appropriate css classes
     */
    getStrengthBarElement = (first, second, third) => {
        return <div className='row dropdown-link align-items-center'>
            <div className={first}></div>
            &nbsp;
            <div className={second}></div>
            &nbsp;
            <div className={third}></div>
        </div>;
    }

    render() {
        return (
            <div>
                {this.getSymptomEntry()}
            </div>
        )
    }
}

SymptomEntry.propTypes = {
    datetime: PropTypes.number,
    entry: PropTypes.object,
    i18n: PropTypes.object,
    nomenclature: PropTypes.any
}

export default SymptomEntry