import React from 'react'
import PropTypes from "prop-types";


export class UserInfoHeader extends React.Component {
    constructor(props) {
        super(props)
        this.index = 0;
    }

    setWizardStep = (idx) => {
        this.props.onHeaderClick(idx);
    }


    getWizardStepNames = () => {
        return this.props.wizardSteps.map((w, idx) => {
            return <React.Fragment key={idx}>
                <div className="text-center small-padding">
                    <div className={'user-profile-link'}>
                        <div
                            onClick={() => {
                                this.setWizardStep(idx)
                            }}
                            className={

                                w.step === this.props.wizardStep
                                    ? 'wizard-blue-title'
                                    : !w.completed
                                        ? 'wizard-grey-title'
                                        : 'wizard-title'}>
                            {w.name}
                        </div>
                    </div>
                </div>

            </React.Fragment>
        });
    }


    render() {
        return (
            <div className='wizard-header-container'>
                {this.getWizardStepNames()}
            </div>
        )
    }
}

UserInfoHeader.propTypes = {
    i18n: PropTypes.object,
    onHeaderClick: PropTypes.func,
    onWizardStepChange: PropTypes.func,
    wizardStep: PropTypes.number,
    wizardSteps:  PropTypes.array
}

export default UserInfoHeader
