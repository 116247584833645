import React, {Component} from 'react'
import {WithContext as ReactTags} from 'react-tag-input';
import PropTypes from "prop-types";

export class CustomTagsInput extends Component {
    constructor(props) {
        super(props);
        const KeyCodes = {
            comma: 188,
            enter: 13,
            tab: 9
        };
        this.delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.tab];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isFieldValid !== this.props.isFieldValid) {
            if (!this.props.isFieldValid) {
                this.removeSuccessClass();
                this.addErrorClass();
            } else {
                this.addSuccessClass();
            }
        }
        if (prevProps.formClass !== this.props.formClass && this.props.formClass !== '') {
            if (this.props.isFieldValid) {
                this.addSuccessClass();
            } else {
                this.removeSuccessClass();
                this.addErrorClass();
            }
        }
    }

    /**
     * Handles the logic of adding an item to the list
     *
     * @param {string} tag - the added item
     */
    handleAddition = (tag) => {
        this.removeErrorClass();
        this.props.handleAddition(tag);
    }

    /**
     * Handles the logic of removing an item from the list
     *
     * @param {string} i - the removed item
     */
    handleDelete = (i) => {
        this.props.handleDelete(i);
        if (!this.props.isFieldValid) {
            this.addErrorClass();
        }
    }

    /**
     * Add the error class to the tags input class list
     */
    addErrorClass = () => {
        var tagsInput = document.getElementsByClassName('ReactTags__selected')[0];
        tagsInput.classList.add('ReactTags__selected-invalid');
    }

    /**
     * Removes the error class to the tags input class list
     */
    removeErrorClass = () => {
        var tagsInput = document.getElementsByClassName('ReactTags__selected')[0];
        tagsInput.classList.remove('ReactTags__selected-invalid');
    }

    /**
     * Add the success class to the tags input class list
     */
    addSuccessClass = () => {
        var searchBox = document.getElementsByClassName('ReactTags__selected')[0];
        searchBox.classList.add('ReactTags__selected-success');
    }

    /**
     * Removes the success class to the tags input class list
     */
    removeSuccessClass = () => {
        var searchBox = document.getElementsByClassName('ReactTags__selected')[0];
        searchBox.classList.remove('ReactTags__selected-success');
    }

    /**
     * Returns the phone numbers in the format needed
     *
     * @returns {Array} the formatted phone numbers
     */
    getTags = () => {
        if (this.props.tags && this.props.tags.length > 0) {

            let filteredTags = this.props.tags.filter(t => {
                return (t !== null)
            })

            return filteredTags.map(t => {
                if (typeof t == 'object') return t;
                return {id: t, text: t};
            });
        } else return this.props.tags;
    }

    handleBlur = (v) => {
        let tags = this.getTags();
        let isThere = false;
        if (v) {
            tags.map((tag) => {
                if (tag.id === v) {
                    isThere = true
                }

            })

            if (typeof v !== 'object' && isThere === false) {
                v = {id: v, text: v};
                this.props.handleAddition(v);
            }
        }

    }

    render() {
        return (
            <ReactTags tags={this.getTags()}
                       handleInputBlur={this.handleBlur}
                       handleDelete={this.handleDelete}
                       handleAddition={this.handleAddition}
                       delimiters={this.delimiters}
                       placeholder={this.props.placeholder}
                       autofocus={this.props.autofocus}/>
        )
    }
}

CustomTagsInput.propTypes = {
    isFieldValid: PropTypes.bool,
    autofocus: PropTypes.bool,
    placeholder: PropTypes.string,
    tags: PropTypes.any,
    formClass: PropTypes.any,
    handleAddition: PropTypes.func,
    handleDelete: PropTypes.func,
};

export default CustomTagsInput