import {
    CLEAR_SELECTED_USER,
    CREATE_PRESCRIPTION_ERROR,
    CREATE_PRESCRIPTION_REQUEST_SENT,
    CREATE_PRESCRIPTION_SUCCESS,
    FETCH_SELECTED_USER_MEDICATIONS_ERROR,
    FETCH_SELECTED_USER_MEDICATIONS_SUCCESS,
    LOGOUT,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    SELECT_USER
} from "../actions/actions";
import {requestStatus} from './requests_reducers';

/**
 * Medication reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the symptoms logs
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const medicationInitialState = {medicationPlan: {entries: [], request: requestStatus(undefined, {})}}

export function medication(state = medicationInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_MEDICATIONS_SUCCESS: {
            return {
                ...state,
                medicationPlan: {entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
            }
        }
        case FETCH_SELECTED_USER_MEDICATIONS_ERROR: {
            return {
                ...state,
                medicationPlan: {
                    entries: medicationInitialState.medicationPlan.entries,
                    request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.response})
                }
            }
        }
        case SELECT_USER:
        //case CLEAR_PATIENTS:
        // eslint-disable-next-line no-fallthrough
        case CLEAR_SELECTED_USER:
        case LOGOUT: {
            return {...state, ...medicationInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Medication reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the symptoms logs
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const prescriptionInitialState = {entries: [], request: requestStatus(undefined, {})}

export function prescription(state = prescriptionInitialState, action) {
    switch (action.type) {
        case CREATE_PRESCRIPTION_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case CREATE_PRESCRIPTION_SUCCESS: {
            return {
                ...state,
                entries: action.result,
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case CREATE_PRESCRIPTION_ERROR: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case SELECT_USER:
        case CLEAR_SELECTED_USER:
        case LOGOUT: {
            return {...state, ...prescriptionInitialState};
        }
        default: {
            return state;
        }
    }
}