import React, {Component} from 'react'
import DateRangePicker from '../shared/DateRangePicker'
import Select from '../shared/Select'
import {
    DOCUMENTS_FILTER,
    getResolvedOptions
} from '../../constants/select_options'
import {$$} from '../../helpers/localization'
import moment from 'moment'
import {connect} from 'react-redux'
import {clearSelectedUserData} from '../../actions/users_actions'
import PropTypes from "prop-types";



export class DocumentsFilters extends Component {

    state = {
        type: this.props.filters.type,
        category: this.props.filters.category,
        beforeDateTime: this.props.filters.before_date_time,
        afterDateTime: this.props.filters.after_date_time
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //this.props.clearSelectedUserData();
    }

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields, function () {
            this.props.changeDocumentsFilters(this.state);
        });
    };

    /**
     * Change the documents filters state.
     *
     * @param {object} range - the custom range selected
     */
    onRangeSelected = (range) => {
        this.setState({
            beforeDateTime: range.endDate ? moment(range.endDate).valueOf() : null,
            afterDateTime: range.startDate ? moment(range.startDate).valueOf() : null
        }, function () {
            this.props.changeDocumentsFilters(this.state);
        });
    }

    render() {
        return (
            <div className="lab-results-filters-container">
                <div className='lab-results-filters-select-container'>
                    <Select
                        name="type"
                        options={getResolvedOptions(DOCUMENTS_FILTER.TYPE)}
                        value={this.state.type}
                        onChange={this.onSelectChange}
                        placeHolder={$$('document_type_label')}/>
                </div>
                <div className='lab-results-filters-date-container'>
                    <DateRangePicker
                        onRangeSelected={this.onRangeSelected}
                        settings ={this.props.settings}
                        startDate={this.props.filters.after_date_time}
                        endDate={this.props.filters.before_date_time}/>
                </div>
            </div>
        )
    }
}

DocumentsFilters.propTypes = {
    categoryOptions: PropTypes.array,
    changeDocumentsFilters: PropTypes.func,
    clearSelectedUserData: PropTypes.func,
    settings: PropTypes.any,
    filters: PropTypes.object,
    i18n: PropTypes.object
}

export default connect(null, {clearSelectedUserData})(DocumentsFilters);
