// eslint-disable-next-line no-undef
export const SERVER_URL = process.env.REACT_APP_SERVER_API_URL;
export const SIGN_SERVER_URL = process.env.REACT_APP_SIGN_SERVER_URL;

export const CLINICIAN_LOGIN_URL = `${SERVER_URL}/public/provider/login`;
export const SEND_2FA_EMAIL = `${SERVER_URL}/public/provider/twofa/email/send`;
export const GET_2FA_METHOD = `${SERVER_URL}/user/twofa/method`;
export const GENERATE_TOTP_ENDPOINT = `${SERVER_URL}/user/twofa/generate`;
export const UPDATE_2FA_ENDPOINT = `${SERVER_URL}/user/twofa`;
export const VALIDATE_TOTP_ENDPOINT = `${SERVER_URL}/user/twofa/validate`;
export const REGISTER_URL = `${SERVER_URL}/public/provider`;
export const REFRESH_TOKEN_URL = `${SERVER_URL}/auth/token/refresh`;
export const FORGOT_PASSWORD_URL = `${SERVER_URL}/public/password/forgotPassword?lang={lang}&app=clinic`;
export const PRIVACY_POLICY_URL = `https://medrec-m.com/privacy-policy/{lang}`;

export const ALL_USERS_URL = `${SERVER_URL}/user/provider/users`;
export const ALL_LOGBOOK_ENTIRES_FOR_USER = `${SERVER_URL}/logbook-api/health-issue-data/{targetUserId}`;
export const USER_CHART_DATA_URL = `${SERVER_URL}/charts/{userId}`;
export const LATEST_MEASUREMENtS_FOR_USER = `${SERVER_URL}/logbook-api/{userId}/latest?app=Medrec-M`;
export const USER_PICTURE_URL = `${SERVER_URL}/user/photo/downloadFile/{userId}`;


export const ICD_SEARCH = `${SERVER_URL}/public/provider/icd10/{country}/search?q={query}`;


export const GET_USER_SETTINGS_URL = `${SERVER_URL}/medrec-m/settings/{targetUserId}`;
export const UPDATE_USER_SETTINGS_URL = `${SERVER_URL}/medrec-m/settings/{targetUserId}`;

export const GET_NOMENCLATURE_URL = `${SERVER_URL}/logbook-api/symptoms/nomenclature`;
export const GET_USER_SYMPTOMS_LOG_URL = `${SERVER_URL}/logbook-api/health-issue-data/{targetUserId}`;

export const GET_ALL_PERMISSIONS_URL = `${SERVER_URL}/permission/authorized?app=Medrec-M`;
export const GET_ALL_AUTHORIZING_PERMISSIONS_URL = `${SERVER_URL}/permission/authorizing?app=Medrec-M`;
export const APPROVE_PERMISSION_URL = `${SERVER_URL}/permission/{permissionId}/approve`;
export const DENY_PERMISSION_URL = `${SERVER_URL}/permission/{permissionId}/deny`;
export const REQUEST_PERMISSION_URL = `${SERVER_URL}/user/code/{email}/request?app=Medrec-M&types=READ`;

export const GET_USER_INFO_URL = `${SERVER_URL}/user/provider`;
export const UPDATE_USER_INFO_URL = `${SERVER_URL}/user`;
export const UPDATE_USER_PASSWORD_URL = `${SERVER_URL}/user/password`;
export const UPLOAD_USER_IMAGE_URL = `${SERVER_URL}/user/photo/uploadFile`;
export const DELETE_USER_IMAGE_URL = `${SERVER_URL}/user/photo/delete`;


export const POST_APPOINTMENT_TIMETABLE_RANGE = `${SERVER_URL}/user/provider/appointments/timetable-range`;
export const GET_APPOINTMENT_TIMETABLE_RANGES = `${SERVER_URL}/user/provider/appointments/timetable-ranges`;
export const DELETE_APPOINTMENT_TIMETABLE_RANGE = `${SERVER_URL}/user/provider/appointments/timetable-range/{timetableRangeId}`;

export const GET_USER_DOCUMENTS_URL = `${SERVER_URL}/logbook-api/health-issue-data/{targetUserId}`;
export const DOWNLOAD_DOCUMENT_URL = `${SERVER_URL}/logbook-api/document/attachment/{documentId}?app=Medrec-M&healthIssueIds={healthIssuesIds}`;
export const GET_LOGGED_USER_DOCUMENTS_URL = `${SERVER_URL}/user/provider/documents/filenames`;
export const DOWNLOAD_LOGGED_USER_DOCUMENT_URL = `${SERVER_URL}/user/provider/documents?filename={filename}`;
export const UPLOAD_LOGGED_USER_DOCUMENT_URL = `${SERVER_URL}/user/provider/documents`;
export const DELETE_LOGGED_USER_DOCUMENT_URL = `${SERVER_URL}/user/provider/documents`;

export const MEDICAL_PROFILE_FOR_USER = `${SERVER_URL}/user/medical_profile/{userId}?app=Medrec-M`;

export const GET_CHAT_WITH_USER = `${SERVER_URL}/user/chat/{userId}`;
export const GET_LATEST_MESSAGES_PER_CHAT = `${SERVER_URL}/user/chat/latest`;
export const SEND_MESSAGE_TO_USER = `${SERVER_URL}/user/chat/message`;
export const MARK_MESSAGE_AS_READ_URL = `${SERVER_URL}/user/chat/message/read/{messageId}`;
export const GET_UNREAD_MESSAGES = `${SERVER_URL}/user/chat/unread/count`;
export const START_VIDEO_CALL_SESSION_UTR = `${SERVER_URL}/user/chat/video/session/{userId}`;
export const GET_VIDEO_CALL_SESSION_URL = `${SERVER_URL}/user/chat/video/session/{userId}`;
export const STOP_VIDEO_CALL_SESSION_URL = `${SERVER_URL}/user/chat/video/session`
export const UPDATE_VIDEO_DURATION_AND_STATUS_URL = `${SERVER_URL}/user/chat/message/video/{messageId}`;
export const CHAT_WEBSOCKET_URL = `${SERVER_URL}/public/user/ws`;
export const CHAT_CAN_RECEIVE_MESSAGE_FROM = `${SERVER_URL}/user/chat/can_receive_message_from/{userId}`;
export const BLOCK_CHAT_FROM = `${SERVER_URL}/user/chat/block/{userId}`;
export const EXPLICIT_UNBLOCK_CHAT_FROM = `${SERVER_URL}/user/chat/unblock/{userId}`;
export const RESET_BLOCKING = `${SERVER_URL}/user/chat/reset_blocking/{userId}`;

export const USER_MEDICATIONS_URL = `${SERVER_URL}/medication/plans/{userId}`;

export const USER_GENERAL_PRACTITIONERS_URL = `${SERVER_URL}/user/general-practitioner/{userId}?app=Medrec-M`;
export const PROVIDER_APPOINTMENTS_STATS_URL = `${SERVER_URL}/user/provider/appointments/appointments/stats?timezone={timeZone}`;

export const GET_USER_LAB_RESULTS_URL = `${SERVER_URL}/logbook-api/health-issue-data/{targetUserId}`;
export const GET_LABORATORIES_URL = `${SERVER_URL}/public/logbook-api/laboratories?app=Medrec-M`;

export const GET_PROVIDER_ACTIVE_TEXT_CONSULTATIONS = `${SERVER_URL}/user/provider/appointments/consultations/active`;
export const GET_PROVIDER_TEXT_CONSULTATIONS = `${SERVER_URL}/user/provider/appointments/consultations`;
export const GET_PROVIDER_APPOINTMENTS = `${SERVER_URL}/user/provider/appointments/appointments`;
export const GET_APPOINTMENT = `${SERVER_URL}/user/provider/appointments/appointment/{appointmentId}`;
export const GET_ENCOUNTER_OF_THE_APPOINTMENT = `${SERVER_URL}/user/provider/encounter/{encounterId}`;
export const UPDATE_ENCOUNTER = `${SERVER_URL}/user/provider/encounter`;
export const CREATE_ENCOUNTER = `${SERVER_URL}/user/provider/encounter/{appointmentId}`;
export const UPDATE_APPOINTMENT = `${SERVER_URL}/user/appointment`;
export const UPDATE_APPOINTMENT_STATUS_URL = `${SERVER_URL}/user/provider/appointments/appointment/{appointmentId}/{status}`;
export const UPDATE_APPOINTMENT_PAYMENT_STATUS_TO_PAID_URL = `${SERVER_URL}/user/provider/appointments/appointment/{appointmentId}/payment/paid`;

export const CREATE_NEW_NOTE = `${SERVER_URL}/user/provider/notes`;
export const EDIT_NOTE = `${SERVER_URL}/user/provider/notes`;
export const DELETE_NOTE = `${SERVER_URL}/user/provider/notes/{noteId}`;
export const ALL_NOTES_FOR_USER = `${SERVER_URL}/user/provider/notes/user/{userId}`;
export const ALL_NOTES_FOR_USER_AND_APPOINTMENT = `${SERVER_URL}/user/provider/notes/user/{userId}/appointment/{appointmentId}`;

export const GET_HEALTH_ISSUES_PER_USER_URL = `${SERVER_URL}/user/provider/users/{userId}`;

export const GET_NOTIFICATIONS_URL = `${SERVER_URL}/user/provider/notifications`;
export const UPDATE_NOTIFICATION_STATUS_URL = `${SERVER_URL}/user/provider/notifications/{notificationId}`;

export const GET_SPECIALTIES_URL = `${SERVER_URL}/public/provider/specialties/all`;

export const GET_PRICE_LISTS = `${SERVER_URL}/user/provider/prices/list`;
export const ADD_CONSULTATION_MENU_OPTION = `${SERVER_URL}/user/provider/prices`;
export const DELETE_CONSULTATION_MENU_OPTION = `${SERVER_URL}/user/provider/prices/{encounterPriceId}`;

export const MANAGEMENT_DELETE_CONSULTATION_MENU_OPTION = `${SERVER_URL}/user/clinic/provider/prices/{encounterPriceId}`;
export const MANAGEMENT_ADD_CONSULTATION_MENU_OPTION = `${SERVER_URL}/user/clinic/provider/prices`;

export const GET_USER_CARDIAC_STATS_URL = `${SERVER_URL}/cardiac/stats/{targetUserId}`;

export const GET_DOCUMENT_ARCHIVE_CONTENTS_LIST_URL = `${SERVER_URL}/logbook-api/document/{documentId}/list?app=Medrec-M&healthIssueIds={healthIssuesIds}`;
export const DOWNLOAD_ARCHIVE_ENTRY_FROM_DOCUMENT_URL = `${SERVER_URL}/logbook-api/document/attachment/dicom-archive/{documentId}?app=Medrec-M&healthIssueIds={healthIssuesIds}&archiveEntry={archiveEntry}`;

/*** clinic management endpoints ****/
export const GET_STAFF_LIST = `${SERVER_URL}/user/clinic/staff/list`;

export const MANAGEMENT_POST_APPOINTMENT_TIMETABLE_RANGE = `${SERVER_URL}/user/clinic/timetable-range`;
export const MANAGEMENT_GET_APPOINTMENT_TIMETABLE_RANGES = `${SERVER_URL}/user/clinic/timetable-ranges`;
export const MANAGEMENT_DELETE_APPOINTMENT_TIMETABLE_RANGE = `${SERVER_URL}/user/clinic/timetable-range/{timetableRangeId}`;

export const PATIENT_UIN_URL = `${SERVER_URL}/user/clinic/patient/{org_uuid}/{patient_uin}`;
export const PATIENT_REGISTER_EXISTING_URL = `${SERVER_URL}/user/clinic/patient/register/{org_id}/{user_uin}`;
export const PATIENT_UPDATE_CONTACT_INFO_URL = `${SERVER_URL}/user/clinic/patient/{org_id}/{user_uin}`;
export const ORG_PATIENTS = `${SERVER_URL}/user/clinic/patients?organizationId={organizationId}`;
export const REGISTER_NEW_PATIENT = `${SERVER_URL}/user/clinic/patient/register`;
export const CLINIC_FETCH_APPOINTMENTS = `${SERVER_URL}/user/clinic/appointments`;
export const CLINIC_UPDATE_APPOINTMENT_STATUS_URL = `${SERVER_URL}/user/clinic/appointment/{appointmentId}/{status}`;
export const CLINIC_UPDATE_APPOINTMENT_PAYMENT_STATUS_TO_PAID_URL = `${SERVER_URL}/user/clinic/appointment/{appointmentId}/payment/paid`;
export const CLINIC_UPDATE_APPOINTMENT_URL = `${SERVER_URL}/user/clinic/appointment`;
export const CLINIC_ENCOUNTER_BY_ID = `${SERVER_URL}/user/clinic/encounter/{encounterId}`;
export const GET_PROVIDER_TIMETABLE = `${SERVER_URL}/user/clinic/{providerId}/timetables
?start_timestamp={startTimestamp}
&end_timestamp={endTimestamp}
&timezone={timezone}
&priceId={priceId}
{location}
{organizationId}`;

export const GET_FIRST_AVAILABLE = `${SERVER_URL}/user/clinic/{providerId}/first_available
?start_timestamp={startTimestamp}
&end_timestamp={endTimestamp}
&timezone={timezone}
&priceId={priceId}
{location}
{organizationId}`;

export const CLINIC_CREATE_APPOINTMENT = `${SERVER_URL}/user/clinic/appointment?userId={userId}`;

export const GET_CLINIC_CLINIC_PROVIDER_TEST_TEMPLATES_URL = `${SERVER_URL}/user/clinic/tests_templates/{organizationId}/{providerId}`;
export const CLINIC_CREATE_ENCOUNTER = `${SERVER_URL}/user/clinic/encounter/{appointmentId}/{providerId}`;
export const CLINIC_SAVE_TEST_RESULTS_URL = `${SERVER_URL}/user/clinic/tests_templates/{encounter_id}`;
export const CLINIC_DELETE_TEST_FIELD_VALUES_URL = `${SERVER_URL}/user/clinic/tests_templates/{encounter_id}/{template_id}`;

export const CLINIC_GET_ENC_PATIENT_INFO = `${SERVER_URL}/user/clinic/org/{orgId}/patient/{patientId}/personal`;
export const CLINIC_SAVE_ENC_PATIENT_INFO = `${SERVER_URL}/user/clinic/org/{orgId}/patient/{patientId}/personal`;

export const GET_LATEST_REGISTRATION = `${SERVER_URL}/user/clinic/patients/latest?organizationId={organizationId}&limit={limit}`;
export const GET_CLINIC_REVENUE = `${SERVER_URL}/user/clinic/accounting/revenue?org_id={organizationId}`;
export const GET_CLINIC_REVENUE_DETAILS = `${SERVER_URL}/user/clinic/accounting/revenue/{providerId}`;

export const LOGOUT_URL = `${SERVER_URL}/user/logout`;

export const CREATE_VIDEO_ROOM_URL = `${SERVER_URL}/user/chat/video/room/appointment`;
export const DELETE_VIDEO_ROOM_URL = `${SERVER_URL}/user/chat/video/room/{roomId}`;

export const GET_PROVIDER_CONFIGUIRATION_URL = `${SERVER_URL}/user/provider/appointments/calendar-settings`;
export const UPDATE_PROVIDER_CONFIGUIRATION_URL = `${SERVER_URL}/user/provider/appointments/calendar-settings`;

export const GET_PROVIDER_CHAT_SETTINGS_URL = `${SERVER_URL}/user/chat/chat-settings`;
export const UPDATE_PROVIDER_CHAT_SETTINGS_URL = `${SERVER_URL}/user/chat/chat-settings`;

export const DELETE_PRESCRIPTION_URL = `${SERVER_URL}/user/provider/prescription/{prescriptionId}`;
export const UPDATE_PRESCRIPTION_URL = `${SERVER_URL}/user/provider/prescription`;
export const CREATE_PRESCRIPTION_URL = `${SERVER_URL}/user/provider/prescription`;
export const GET_PRESCRIPTION_TEMPLATES_URL = `${SERVER_URL}/user/provider/prescription_template/list`;
export const DELETE_PRESCRIPTION_TEMPLATES_URL = `${SERVER_URL}/user/provider/prescription_template/{prescriptionTemplateId}`;
export const FETCH_ENCOUNTER_DOCUMENTS_URL = `${SERVER_URL}/logbook-api/documents/encounter/{encounterId}`;
export const DOWNLOAD_EXAMINATION_RESULTS_URL = `${SERVER_URL}/reports/pdf?reportType={reportType}&lang={lang}&id={providerId}`;
export const GET_ENCOUNTER_DOCUMENT_ARCHIVE_CONTENTS_LIST_URL = `${SERVER_URL}/logbook-api/document/encounter/{encounterId}/{documentId}/list?app=Medrec-M`;
export const DOWNLOAD_ENCOUNTER_ARCHIVE_ENTRY_FROM_DOCUMENT_URL = `${SERVER_URL}/logbook-api/document/attachment/dicom-archive/{documentId}?app=Medrec-M&encounterId={encounterId}&archiveEntry={archiveEntry}`;
export const DOWNLOAD_ENCOUNTER_DOCUMENT_URL = `${SERVER_URL}/logbook-api/document/attachment/{documentId}?app=Medrec-M&encounterId={encounterId}`;
export const GET_QUESTIONNAIRES_FOR_ORG_URL = `${SERVER_URL}/user/questionnaire/organization/{orgId}`;
export const GET_SUB_APPOINTMENTS_FOR_APPOINTMENT_URL = `${SERVER_URL}/user/provider/appointments/appointment/sub_appointments/{appointmentId}`;
export const GET_CLINIC_SUB_APPOINTMENTS_FOR_APPOINTMENT_URL = `${SERVER_URL}/user/clinic/appointment/sub_appointments/{appointmentId}`;
export const CLINIC_CREATE_GROUP_APPOINTMENT = `${SERVER_URL}/user/clinic/group_appointment`;
export const CLINIC_UPDATE_GROUP_APPOINTMENT_URL = `${SERVER_URL}/user/clinic/group_appointment`;
export const SEARCH_FOR_MEDICATIONS_URL = `${SERVER_URL}/medication/search`;
export const GET_MENSTRUATION_DIARY_SUMMARY_URL = `${SERVER_URL}/logbook-api/summary/menstruationDiary/{userId}`;
export const GET_PEAK_FLOW_DIARY_STATS_URL = `${SERVER_URL}/logbook-api/summary/peakFlowDiary/{userId}`;
export const GET_PEAK_FLOW_NORM_STATS_URL = `${SERVER_URL}/logbook-api/summary/peakFlowDiary/getNorm/{userId}`;
export const GET_MEDICATION_SUMMARY_STATS_URL = `${SERVER_URL}/medications/intakeSummary/{userId}`;
export const GET_LAST_COMPLETED_APPOINTMENT_URL = `${SERVER_URL}/user/provider/encounter/last_completed/patient/{patientId}`;
export const GET_THERAPY_ADHERENCE_SUMMARY_STATS_URL = `${SERVER_URL}/medications/adherenceStats/{userId}`;
export const PREPARE_PEAK_FLOW_DIARY_REPORT_URL = `${SERVER_URL}/logbook-api/summary/peakFlowDiary/prepareReport/{userId}`;
export const DOWNLOAD_REPORT_URL = `${SERVER_URL}/reports/pdf?reportType={reportType}&lang={lang}`;
export const FETCH_TEST_TEMPLATES_URL = `${SERVER_URL}/user/provider/encounter/tests_templates`;
export const SAVE_TEST_RESULTS_URL = `${SERVER_URL}/user/provider/encounter/tests_templates/{encounter_id}`;
export const DELETE_TEST_FIELD_VALUES_URL = `${SERVER_URL}/user/provider/encounter/tests_templates/{encounter_id}/{template_id}`;
export const GET_LAST_COMPLETED_APPOINTMENT = `${SERVER_URL}/user/provider/appointments/last_completed_appointment`;
export const ENCOUNTER_DOCUMENTS_URL = `${SERVER_URL}/logbook-api/document/encounter/{encounterId}`;
export const GET_PROVIDER_ENCOUNTER_DOCUMENTS_URL = `${SERVER_URL}/logbook-api/documents/encounter/{encounterId}/provider`;
export const DELETE_PROVIDER_ENCOUNTER_DOCUMENTS_URL = `${SERVER_URL}/logbook-api/document/encounter/{encounterId}/document/{documentId}`;
export const MARK_PATIENT_ENCOUNTER_DOCUMENT_AS_SEEN = `${SERVER_URL}/user/clinic/encounter/{encounterId}/patientDocument/{documentId}/seen`;
export const REMOVE_MISSING_UNSEEN_DOCUMENTS = `${SERVER_URL}/user/clinic/encounterPatientDocuments/{encounterId}`;

//edit patient personal information
export const CLINIC_UPDATE_PATIENT_PERSONAL_INFO = `${SERVER_URL}/user/clinic/patient/user`;
export const CLINIC_UPDATE_PATIENT_MEDICAL_PROFILE = `${SERVER_URL}/user/clinic/patient/medicalProfile`;

export const NHIS_GET_NOMENCLATURES = `${SERVER_URL}/nhis/nomenclature/get`;
export const NHIS_PRESCRIPTION_PREPARE_FOR_SIGN = `${SERVER_URL}/nhis/prescription/create/prepare?escape=true`;
export const NHIS_EXAM_PREPARE_FOR_SIGN = `${SERVER_URL}/nhis/examination/submit-offline/prepare?escape=true`;
export const NHIS_ANNUL_EXAM_PREPARE_FOR_SIGN = `${SERVER_URL}/nhis/examination/cancel/prepare?escape=true`;
export const NHIS_EXAM_UPDATE_PREPARE_FOR_SIGN = `${SERVER_URL}/nhis/examination/correct/prepare?escape=true`;
export const NHIS_MEDICAL_NOTICE_CREATE_PREPARE_FOR_SIGN = `${SERVER_URL}/nhis/commons/medicalNotice/issue/prepare?escape=true`;
export const NHIS_MEDICAL_NOTICE_CANCEL_PREPARE_FOR_SIGN = `${SERVER_URL}/nhis/commons/medicalNotice/cancel/prepare?escape=true`;

export const NHIS_EREFERRAL_CANCEL_PREPARE_FOR_SIGN = `${SERVER_URL}/nhis/referral/cancel/prepare?escape=true`;
export const NHIS_EREFERRAL_CHECK_PREPARE_FOR_SIGN = `${SERVER_URL}/nhis/referral/check/prepare?escape=true`;
export const NHIS_CHECK_EREFERRAL = `${SERVER_URL}/nhis/referral/checkToJson`;

export const NHIS_LOGIN = `${SERVER_URL}/nhis/login/token`;
export const NHIS_PREPARE_LOGIN = `${SERVER_URL}/nhis/login/token/prepare?escape=true`;
export const NHIS_CREATE_PRESCRIPTION = `${SERVER_URL}/nhis/prescription/create`;
export const NHIS_CREATE_MEDICAL_NOTICE = `${SERVER_URL}/nhis/commons/medicalNotice/issue`;
export const NHIS_CANCEL_MEDICAL_NOTICE = `${SERVER_URL}/nhis/commons/medicalNotice/cancel`;

export const NHIS_CANCEL_EREFERRAL = `${SERVER_URL}/nhis/referral/cancel`;

export const NHIS_CREATE_EXAMINATION = `${SERVER_URL}/nhis/examination/submit-offline`;
export const NHIS_UPDATE_EXAMINATION = `${SERVER_URL}/nhis/examination/correct`;
export const NHIS_ANNUL_EXAMINATION = `${SERVER_URL}/nhis/examination/cancel`;
export const NHIS_PREPARE_TO_CANCEL_PRESCRIPTION = `${SERVER_URL}/nhis/prescription/cancel/prepare?escape=true`;
export const NHIS_CANCEL_PRESCRIPTION = `${SERVER_URL}/nhis/prescription/cancel`;
export const NHIS_PRESCRIPTION_JSON_TO_MED_PLANS = `${SERVER_URL}/nhis/prescription/jsonToMedicationPlans`;
export const NHIS_PRESCRIPTION_XML_TO_MED_PLANS = `${SERVER_URL}/nhis/prescription/xmlToMedicationPlans`;
export const NHIS_EREFERRAL_CREATE_PREPARE_FOR_SIGN = `${SERVER_URL}/nhis/referral/create/prepare?escape=true`;
export const NHIS_CREATE_EREFERRAL = `${SERVER_URL}/nhis/referral/create`;


export const NHIS_SEARCH_PRESCRIPTION_PREPARE_FOR_SIGN = `${SERVER_URL}/nhis/prescription/search/prepare?escape=true`;
export const NHIS_SEARCH_PRESCRIPTION = `${SERVER_URL}/nhis/prescription/search`;

export const NHIS_SEARCH_EXAM_PREPARE_FOR_SIGN = `${SERVER_URL}/nhis/examination/search/prepare?escape=true`;
export const NHIS_SEARCH_EXAM = `${SERVER_URL}/nhis/examination/searchToJson`;



export const CREATE_NHIS_PRESCRIPTION = `${SERVER_URL}/user/provider/prescription/nhis`;
export const UPDATE_NHIS_PRESCRIPTION = `${SERVER_URL}/user/provider/prescription/nhis`;
export const GET_NHIS_PRESCRIPTION_BY_NRN = `${SERVER_URL}/user/provider/prescription/nhis`;

export const DELETE_NHIS_PRESCRIPTION_BY_NRN = `${SERVER_URL}/user/provider/prescription/nhis/nrn/{nrn}`;
export const CREATE_NHIS_PRESCRIPTION_TEMPLATE = `${SERVER_URL}/user/provider/prescription_template/nhis`;
export const LIST_NHIS_PRESCRIPTION_TEMPLATES = `${SERVER_URL}/user/provider/prescription_template/nhis/list`;
export const DELETE_NHIS_PRESCRIPTION_TEMPLATE = `${SERVER_URL}/user/provider/prescription_template/nhis/{nhisPrescriptionTemplateId}`;
export const CREATE_NHIS_MEDICATION_TEMPLATE = `${SERVER_URL}/user/provider/prescription_template/nhis/medication`;
export const LIST_NHIS_MEDICATION_TEMPLATES = `${SERVER_URL}/user/provider/prescription_template/nhis/medication/list`;
export const DELETE_NHIS_MEDICATION_TEMPLATE = `${SERVER_URL}/user/provider/prescription_template/nhis/medication/{nhisMedicationTemplateId}`;

export const DELETE_EREFERRAL = `${SERVER_URL}/user/provider/encounter/{encounterId}/ereferral/{lrn}`;
export const CREATE_EREFERRAL = `${SERVER_URL}/user/provider/encounter/{encounterId}/ereferral`;
export const UPDATE_EREFERRAL = `${SERVER_URL}/user/provider/encounter/{encounterId}/ereferral`;

export const CLINIC_ADD_NEW_STAFF = `${SERVER_URL}/user/clinic/staff/register`;
export const CLINIC_UPDATE_STAFF_ROLE = `${SERVER_URL}/user/clinic/staff/role`;
export const CLINIC_UPDATE_STAFF_PERSONAL_INFO = `${SERVER_URL}/user/clinic/staff/update`;
export const CLINIC_INVITE_NEW_STAFF = `${SERVER_URL}/user/clinic/staff/invite`;
export const CLINIC_LIST_ACTIVE_INVITATIONS = `${SERVER_URL}/user/clinic/staff/invitation/list/{orgId}`;
export const CLINIC_DELETE_INVITATION = `${SERVER_URL}/user/clinic/staff/invitation/{orgId}/{invId}`;
export const PROVIDER_ACCEPT_INVITATION = `${SERVER_URL}/public/provider/invitation/accept/{invitationId}`;
export const PROVIDER_FETCH_INVITATION = `${SERVER_URL}/public/provider/invitation/get/{invitationId}`;