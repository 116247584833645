import {entryTypeToStringKey} from "../constants/entry_types";
import {$$} from "../helpers/localization";

export const formatUtils = {
    format,
    formatV2,
    formatAsJSON,
    isJSON,
    currencyFormat,
    currencyFormatTotals,
}

/**
 * Convert the string to the appropriate format
 *
 * @param {string} str the string to be formatted
 * @returns {string} the string in the appropriate format
 */
function format(str) {
    if (typeof str !== 'string') {
        return '';
    }

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * Convert the string to the appropriate format
 *
 * @param {string} str the string to be formatted
 * @returns {string} the string in the appropriate format
 */
function formatV2(str) {
    if (typeof str !== 'string') {
        return '';
    }

    return $$(entryTypeToStringKey[str]);
}

/**
 * Replace the slashes found in the string with empty string
 *
 * @param {string} str the string to be processed
 * @returns {string} the JSON object of the manipulated string
 */
function formatAsJSON(str) {
    if (typeof str !== 'string') {
        return '';
    }

    str = str.replace(/\\\([^n]\)1/g, '');
    return JSON.parse(str);
}

/**
 * Check if the given str is in the appropriate JSON format or not
 *
 * @param {string} str the string to be processed
 * @returns {boolean} true if string has JSON format, false otherwise
 */
function isJSON(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


/**
 * Convert the currency to the appropriate format
 *
 * @param {number} amount the amount of money in cents to be formatted
 * @param {string} currency the amount of money in cents to be formatted
 * @returns {string} the string in the appropriate format
 */
function currencyFormat(amount, currency) {
    if (amount === 0) {
        return $$("free_label");
    }

    amount = (amount / 100).toFixed(2);
    switch (currency) {
        case "USD":
            return '$' + amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        case "BGN":
            return amount + " лв";
        case "EUR":
            return "€" + amount;
        case "GBP":
            return "£" + amount;
        case "ALL":
            return amount + " L";
        default:
            return amount + " " + currency;
    }
}


/**
 * Convert the currency to the appropriate format
 *
 * @param {number} amount the amount of money in cents to be formatted
 * @param {string} currency the amount of money in cents to be formatted
 * @returns {string} the string in the appropriate format
 */
function currencyFormatTotals(amount, currency) {

    amount = (amount / 100).toFixed(2);
    switch (currency) {
        case "USD":
            return '$' + amount.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        case "BGN":
            return amount + " лв";
        case "EUR":
            return "€" + amount;
        case "GBP":
            return "£" + amount;
        case "ALL":
            return amount + " L";
        default:
            return amount + " " + currency;
    }
}