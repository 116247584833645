export const MEDICATION = 'MEDICATION';
export const BLOOD_PRESSURE = 'BLOOD_PRESSURE';
export const CHOLESTEROL = 'CHOLESTEROL';
export const WEIGHT = 'WEIGHT';
export const TEMPERATURE = 'TEMPERATURE';
export const HYDRATION = 'HYDRATION';
export const RESPIRATORY_RATE = 'RESPIRATORY_RATE';
export const SATURATION = 'SATURATION';
export const HEIGHT = 'HEIGHT';
export const MENSTRUATION = 'MENSTRUATION';
export const URINE_PH = 'URINE_PH';
export const KETONES = 'KETONES';
export const BLOOD_SUGAR = 'BLOOD_SUGAR';
export const HBA1C = 'HBA1C';
export const PEAK_FLOW = 'PEAK_FLOW';


export const entry_types_array = [MEDICATION, BLOOD_PRESSURE, CHOLESTEROL, WEIGHT, TEMPERATURE, HYDRATION, RESPIRATORY_RATE, SATURATION, HEIGHT, MENSTRUATION, URINE_PH, KETONES, BLOOD_SUGAR, HBA1C, PEAK_FLOW];

export const entryTypeToStringKey = {
    MEDICATION: 'Medications',
    BLOOD_PRESSURE: 'blood_pressure',
    CHOLESTEROL: 'Cholesterol',
    WEIGHT: 'Weight',
    TEMPERATURE: 'Temperature',
    HYDRATION: 'Hydration',
    RESPIRATORY_RATE: 'Respiratory_rate',
    SATURATION: 'Saturation',
    HEIGHT: 'Height',
    MENSTRUATION: 'Menstruation',
    URINE_PH: 'Urine_pH',
    KETONES: 'Ketones',
    BLOOD_SUGAR: 'Blood_sugar',
    HBA1C: 'HBA1C',
    PEAK_FLOW: 'peak_flow'
}
