import React from 'react'
import {$$} from '../../../helpers/localization'
import {validators} from '../../../helpers/validators'
import history from '../../../helpers/history'
import {connect} from 'react-redux'
import classNames from 'classnames'
import {clearRegistrationData} from '../../../actions/register_actions'
import Select from '../../shared/Select'
import {countryOptions} from '../../../constants/countries'
import {Routes} from '../../../constants/routes'
import CustomMultiselect from '../../shared/CustomMultiSelect'
import CustomTagsInput from '../../shared/CustomTagsInput'
import CenteredModal from '../../shared/CenteredModal'
import PropTypes from "prop-types";
import PasswordValidity from "../../shared/PasswordValidity";
import {isPasswordValid} from "../../../utils/validatePassword";
import {nhisGetNomenclatures} from "../../../service/nhis_service";

export class PersonalInformation extends React.Component {
    state = {
        personalInformationFields: {
            fullname: this.props.registrationFields.fullname || '',
            title: this.props.registrationFields.title || '',
            email: this.props.registrationFields.email || '',
            password: this.props.registrationFields.password || '',
            contact_email: this.props.registrationFields.contact_email || '',
            confirm_password: this.props.registrationFields.confirm_password || '',
            phone_numbers: this.props.registrationFields.phone_numbers || [],
            specialties: this.props.registrationFields.specialties || [],
            nhis_specialties: this.props.registrationFields.nhis_specialties || [],
            uin: this.props.registrationFields.uin || '',
            city: this.props.registrationFields.city || '',
            country: this.props.registrationFields.country || countryOptions()[0].value || '',
            nonMedicalPersonnel: this.props.isInternal
        },

        validPhoneNumbersField: true,
        validSpecialtiesField: true,
        showModal: false,
        specialtyOptions: [],

        formclass: '',
        errors: {}
    }

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.registration.response && props.registration.response.status === 403) {
            props.clearRegistrationData();
            return {
                errors: {email: 'register_form_email_exists_message'}
            }
        }
        return state;
    }

    componentDidMount() {
        this._isMounted = true;

        if (this.state.personalInformationFields.specialties.length > 0) {
            const fields = Object.assign({}, this.state);
            fields['personalInformationFields']['specialties'] = this.getSpecialties();
            this.setState(fields);
        }

        if (this.props.registrationFields.submiting) {
            this.validatePhoneNumbersState(this.state.personalInformationFields.phone_numbers);
            this.validateSpecialtiesState(this.state.personalInformationFields.specialties);
            this.displayValidity();
        }

        if (this.props.profilePage) {
            nhisGetNomenclatures(["CL006"]).then((res) => {
                const items = res[0].items;
                let propNames = Object.getOwnPropertyNames(items).sort();
                let arr = propNames.map(prop => ({
                    value: prop,
                    label: prop + " " + items[prop].description,
                    text: items[prop].description,
                    item: items[prop]
                })).filter(o => o.item.meta.role?.includes('Лекари') || o.value === "0000");
                this.setState({specialtyOptions:arr})
            });
        }
    }

    validatePhoneNumbersState = (phone_numbers) => {
        if (phone_numbers.length === 0) {
            this.setState({validPhoneNumbersField: false});
        }
    }

    validateSpecialtiesState = (specialties) => {
        if (specialties.length === 0) {
            this.setState({validSpecialtiesField: false});
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.registrationFields.submiting && this.props.registrationFields.submiting) {
            this.displayValidity();
        }
    }

    /**
     * Set state to show the modal
     */
    showModal = () => {
        this.setState({showModal: true});
    }

    /**
     * Confirm the registration success modal, navigate to login
     */
    onConfirmModal = () => {
        let wizardStep = this.props.wizardStep;
        if (wizardStep === 0) {
            history.push(Routes.LOGIN);
            return;
        }
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        if (this.props.profilePage) {


            let form = this.form;
            let valid = {name: "personalInformationValid", value: false};
            if (this.isValid(form) && evt.target.value !== "") {
                if (evt.target.name === "email") {
                    if (validators.validateEmail(evt.target.value)) {
                        valid.value = true;
                    } else {
                        valid.value = false;
                    }
                } else {
                    valid.value = true;
                }
            }
            this.props.dataChanged(evt, valid);

        }

        const fields = Object.assign({}, this.state);
        fields['personalInformationFields'][evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields['personalInformationFields'][name] = value;
        this.setState(fields);

        if (this.props.profilePage) {
            let evt = {target: {name: name, value: fields['personalInformationFields'][name]}};
            let valid = {name: "personalInformationValid", value: true};
            this.props.dataChanged(evt, valid);
        }

    };

    /**
     * Adds the item in the phone numbers array and updates the state
     *
     * @param {string} tag - the tag entered
     */
    handleAddition = (tag) => {
        const fields = Object.assign({}, this.state);

        fields['personalInformationFields']['phone_numbers'].push(tag.text);
        fields['validPhoneNumbersField'] = true;
        this.setState(fields);


        if (this.props.profilePage) {
            let evt = {target: {name: "phone_numbers", value: fields['personalInformationFields']['phone_numbers']}};
            let valid = {name: "personalInformationValid", value: true};
            this.props.dataChanged(evt, valid);
        }
    };

    /**
     * Removes the item from the phone numbers array and updates the state
     *
     * @param {string} tag - the index of the removed tag
     */
    handleDelete = (i) => {
        const fields = Object.assign({}, this.state);
        fields['personalInformationFields']['phone_numbers'] = fields['personalInformationFields']['phone_numbers'].filter((tag, index) => index !== i);
        let form = this.form;
        this.setState(fields, function () {
            let phone_numbers_validity = !(form['phone_numbers'].checkValidity() === false && this.state.formclass !== '' && this._isMounted);
            if (!phone_numbers_validity) {
                this.setState({validPhoneNumbersField: false});
            }

            if (this.props.profilePage) {
                let evt = {
                    target: {
                        name: "phone_numbers",
                        value: fields['personalInformationFields']['phone_numbers']
                    }
                };
                let valid = {
                    name: "personalInformationValid",
                    value: fields['personalInformationFields']['phone_numbers'] > 0
                };
                this.props.dataChanged(evt, valid);
            }
        });
    };

    /**
     * Adds the item in the specialties array and updates the state
     *
     * @param {string} selectedItem - the selected item from the multiselect
     */
    onSelect = (selectedItem) => {
        const fields = Object.assign({}, this.state);
        fields['personalInformationFields']['specialties'].push(selectedItem);
        fields['validSpecialtiesField'] = true;
        this.setState(fields);

        if (this.props.profilePage) {
            let evt = {target: {name: "specialties", value: fields['personalInformationFields']['specialties']}};
            let valid = {name: "personalInformationValid", value: true};
            this.props.dataChanged(evt, valid);
        }

    }

    /**
     * Removes the item from the specialties array and updates the state
     *
     * @param {string} removedItem - the removed item from the multiselect
     */
    onRemove = (removedItem) => {
        const fields = Object.assign({}, this.state);
        fields['personalInformationFields']['specialties'] = fields['personalInformationFields']['specialties'].filter(s => s !== removedItem);
        let form = this.form;
        this.setState(fields, function () {
            let specialties_validity = !(form['specialties'].checkValidity() === false && this.state.formclass !== '' && this._isMounted);
            if (!specialties_validity) {
                this.setState({validSpecialtiesField: false});
            }
            if (this.props.profilePage) {
                let evt = {target: {name: "specialties", value: fields['personalInformationFields']['specialties']}};
                let valid = {
                    name: "personalInformationValid",
                    value: fields['personalInformationFields']['specialties'].length > 0
                };
                this.props.dataChanged(evt, valid);
            }
        });
    }

    /**
     * Handler to be executed when the Continue button is clicked, validates data and sets error in state if any
     *
     * @param {object} evt - The event handler argument
     */
    onContinueClick = (evt) => {
        const formErrors = this.validate();
        this.setState({errors: formErrors});

        evt.preventDefault();

        if (this.state.formclass !== 'was-validated') {
            this.setState({formclass: 'was-validated'});
        }

        let form = this.form;

        if (form.checkValidity() !== true) {
            if (this.form['phone_numbers'].checkValidity() === false) {
                this.setState({validPhoneNumbersField: false});
            }
            if (this.form['specialties'].checkValidity() === false) {
                this.setState({validSpecialtiesField: false});
            }
            this.displayValidity();
        } else {
            if(isPasswordValid(this.state.personalInformationFields.password)) {
                    let wizardStep = this.props.wizardStep;
                    this.props.onWizardStepChange(this.state.personalInformationFields, ++wizardStep, true);
            }
        }
    }

    /**
     * Handler to be executed when the Back button is clicked
     *
     * @param {object} evt - The event handler argument
     */
    onBackClick = () => {
        this.showModal();
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (this.state.personalInformationFields.email && !validators.validateEmail(this.state.personalInformationFields.email)) {
            errors.email = 'register_form_email_not_correct_message';
        }
        if (this.state.personalInformationFields.email && !validators.validateEmail(this.state.personalInformationFields.contact_email)) {
            errors.contactEmail = 'register_form_email_not_correct_message';
        }
        if (this.state.personalInformationFields.password !== this.state.personalInformationFields.confirm_password) {
            errors.password = 'register_form_passwords_not_match_message';
        }
        if (this.state.personalInformationFields.password &&  !isPasswordValid(this.state.personalInformationFields.password)) {
            errors.password = 'password_is_not_valid';
        }
        if (this.state.personalInformationFields.password.trim().length !== this.state.personalInformationFields.password.length) {
            errors.password = 'register_form_password_whitespace_message';
        }

        return errors;
    }

    /**
     * Returns the specialties in the format needed
     *
     * @returns {Array} the formatted specialties
     */
    getSpecialties = () => {
        return this.props.registrationFields.specialties;/*.map(s => {
            return {value: s, text: $$(s.toLowerCase())};
        });*/
    }

    /**
     * Return select options with the text resolved to the selected specialty
     *
     * @param {Array} options - the list of options
     */
    getSpecialtiesOptions(options) {
        return options.map(o => {
            return {value: o, text: $$(o.toLowerCase())}
        });
    }

    isValid = (form) => {
        return form.checkValidity()
    }

    displayValidity = () => {
        if (this.state.formclass !== 'was-validated') {
            this.setState({formclass: 'was-validated'});
        }
    }

    render() {
        let cityClassNames = classNames('col-sm-6', {
            'register-inline-control-filled': this.state.personalInformationFields.city,
            'register-inline-control': !this.state.personalInformationFields.city
        });
        return (
            <div>
                <form className={this.state.formclass} noValidate={true} ref={(form) => this.form = form} autoComplete="off">
                    <div style={{'minHeight': '43.75rem'}}>
                        <div className='form-group'>
                            {this.state.personalInformationFields.fullname &&
                            <label>{$$('wizard_personal_information_fullname_label')}</label>}
                            <input type='text' className='form-control'
                                   value={this.state.personalInformationFields.fullname}
                                   placeholder={$$('wizard_personal_information_fullname_label')} name='fullname'
                                   onChange={this.onInputChange} required autoComplete="new-password"/>
                            <div className='invalid-feedback'>
                                {$$('wizard_personal_information_fullname_required_label')}
                            </div>
                        </div>
                        {!this.props.isInternal && <div className='form-group'>
                            {this.state.personalInformationFields.title &&
                            <label>{$$('wizard_personal_information_title_label')}</label>}
                            <input type='text' className='form-control'
                                   value={this.state.personalInformationFields.title}
                                   placeholder={$$('wizard_personal_information_title_label')} name='title'
                                   onChange={this.onInputChange}/>
                        </div>}
                        <div className='form-group'>
                            {this.state.personalInformationFields.email && <label>{$$('email_required_label')}</label>}
                            <input type='text'
                                   className={this.state.errors.email ? 'custom-error form-control' : 'form-control'}
                                   value={this.state.personalInformationFields.email}
                                   placeholder={$$('email_required_label')} name='email' onChange={this.onInputChange}
                                   required autoComplete="new-password"/>
                            <div className={this.state.errors.email ? 'custom-invalid-feedback' : 'invalid-feedback'}>
                                {this.state.errors.email ? $$(this.state.errors.email) : $$('email_required_message')}
                            </div>
                        </div>
                        {!this.props.profilePage &&
                        <>
                            <div className='form-group'>
                                {this.state.personalInformationFields.password &&
                                <label>{$$('password_required_label')}</label>}
                                <input type='password'
                                       className={this.state.errors.password ? 'custom-error form-control' : 'form-control'}
                                       value={this.state.personalInformationFields.password}
                                       placeholder={$$('password_required_label')} name='password'
                                       onChange={this.onInputChange} required minLength={8} autoComplete="new-password"/>
                                <div
                                    className={this.state.errors.password ? 'custom-invalid-feedback' : 'invalid-feedback'}>
                                    {this.state.errors.password ? $$(this.state.errors.password) : $$('password_required_message')}
                                </div>
                                <PasswordValidity password = {this.state.personalInformationFields.password}/>
                            </div>
                            <div className='form-group'>
                                {this.state.personalInformationFields.confirm_password &&
                                <label>{$$('wizard_personal_information_password_confirm_label')}</label>}
                                <input type='password' className='form-control'
                                       value={this.state.personalInformationFields.confirm_password}
                                       placeholder={$$('wizard_personal_information_password_confirm_label')}
                                       name='confirm_password' onChange={this.onInputChange} required autoComplete="new-password"/>
                                <div className='invalid-feedback'>
                                    {$$('wizard_personal_information_password_confirm_required_label')}
                                </div>
                            </div>
                        </>}
                        {this.props.isInternal && <div className='form-group'><Select label={$$('country_required_label')}
                                 name='country'
                                 options={countryOptions(this.props.i18n.selected.lang)}
                                 value={this.state.personalInformationFields.country}
                                                             onChange={this.onSelectChange}/></div>}
                        {!this.props.isInternal && <>
                        <div className='form-group'>
                            <input type='text' className='form-control'
                                   value={this.state.personalInformationFields.phone_numbers}
                                   placeholder={$$('wizard_phone_numbers_label')} name='phone_numbers'
                                   onChange={this.onInputChange} required hidden/>
                            <CustomTagsInput
                                tags={this.state.personalInformationFields.phone_numbers}
                                handleAddition={this.handleAddition}
                                handleDelete={this.handleDelete}
                                placeholder={$$('wizard_phone_numbers_label')}
                                autofocus={false}
                                isFieldValid={this.state.validPhoneNumbersField}
                                formClass={this.state.formclass}/>
                            <div className='invalid-feedback'>
                                {$$('wizard_phone_numbers_required_label')}
                            </div>
                        </div>
                        <div className='form-group'>
                            {this.state.personalInformationFields.contact_email &&
                                <label>
                                    {$$('contact_email')}
                                </label>
                            }
                            <input type='text'
                                   className={this.state.errors.contact_email ? 'custom-error form-control' : 'form-control'}
                                   value={this.state.personalInformationFields.contact_email}
                                   placeholder={$$('contact_email')} name='contact_email' onChange={this.onInputChange}
                                   />
                            <div className={this.state.errors.contact_email ? 'custom-invalid-feedback' : 'invalid-feedback'}>
                                {this.state.errors.contactEmail ? $$(this.state.errors.contactEmail) : $$('email_required_message')}
                            </div>
                        </div>
                        <div className='form-group'>
                            <input type='text' className='form-control'
                                   value={this.state.personalInformationFields.specialties} name='specialties'
                                   onChange={this.onInputChange} required hidden/>
                            <CustomMultiselect options={this.getSpecialtiesOptions(this.props.specialties)}
                                               selectedValues={this.state.personalInformationFields.specialties}
                                               onSelect={this.onSelect}
                                               onRemove={this.onRemove}
                                               displayValue="text"
                                               placeholder={$$('wizard_personal_information_specialties_label')}
                                               closeIcon='cancel'
                                               avoidHighlightFirstOption={true}
                                               isFieldValid={this.state.validSpecialtiesField}
                                               formClass={this.state.formclass}/>
                            <div className='invalid-feedback'>
                                {$$('wizard_personal_information_specialties_required_label')}
                            </div>
                        </div>
                        <div className="form-group">
                            <CustomMultiselect options={this.state.specialtyOptions}
                                               selectedValues={this.state.personalInformationFields.nhis_specialties}
                                               onSelect={(selectedItem)=>{
                                                   const fields = Object.assign({}, this.state);
                                                   fields['personalInformationFields']['nhis_specialties'].push(selectedItem);
                                                   this.setState(fields);

                                                   if (this.props.profilePage) {
                                                       let evt = {target: {name: "nhis_specialties", value: fields['personalInformationFields']['nhis_specialties']}};
                                                       let valid = {name: "personalInformationValid", value: true};
                                                       this.props.dataChanged(evt, valid);
                                                   }
                                               }}
                                               onRemove={(removedItem)=>{
                                                   const fields = Object.assign({}, this.state);
                                                   fields['personalInformationFields']['nhis_specialties'] = fields['personalInformationFields']['nhis_specialties'].filter(s => s !== removedItem);
                                                   this.setState(fields);
                                                   if (this.props.profilePage) {
                                                       let evt = {target: {name: "nhis_specialties", value: fields['personalInformationFields']['nhis_specialties']}};
                                                       let valid = {name: "personalInformationValid", value: true};
                                                       this.props.dataChanged(evt, valid);
                                                   }
                                               }}
                                               displayValue="label"
                                               placeholder={$$('nhis.nhis_specialties_search')}
                                               closeIcon='cancel'
                                               avoidHighlightFirstOption={true}
                                               formClass={this.state.formclass}
                                               isFieldValid={true}

                            />
                        </div>
                        <div className='form-group'>
                            {this.state.personalInformationFields.uin &&
                            <label>{$$('wizard_personal_information_uin_label')}</label>}
                            <input type='text'
                                   className={this.state.errors.uin ? 'custom-error form-control' : 'form-control'}
                                   value={this.state.personalInformationFields.uin}
                                   placeholder={$$('wizard_personal_information_uin_label')} name='uin'
                                   onChange={this.onInputChange} required/>
                            <div className={this.state.errors.uin ? 'custom-invalid-feedback' : 'invalid-feedback'}>
                                {this.state.errors.uin ? $$(this.state.errors.uin) : $$('wizard_personal_information_uin_required_label')}
                            </div>
                        </div>
                        <div className='row' style={{'marginTop': '0.3125rem'}}>
                            <div className='col-sm-6'>
                                <Select label={$$('country_required_label')}
                                        name='country'
                                        options={countryOptions(this.props.i18n.selected.lang)}
                                        value={this.state.personalInformationFields.country}
                                        onChange={this.onSelectChange}/>
                            </div>
                            <div className={cityClassNames}>
                                {this.state.personalInformationFields.city &&
                                <label>{$$('city_required_label')}</label>}
                                <input type='text' className='form-control'
                                       value={this.state.personalInformationFields.city} placeholder={$$('city_required_label')}
                                       name='city' onChange={this.onInputChange} required/>
                                <div className='invalid-feedback'>
                                    {$$('wizard_city_required_label')}
                                </div>
                            </div>
                        </div></>}
                    </div>
                    {!this.props.profilePage &&
                    <div className='form-group' style={{'marginTop': '5.25rem'}}>
                        <div className='row flex-end-justify' style={{'float': 'right', 'margin': '0rem'}}>
                            <button type='button' onClick={this.onBackClick} className='btn btn-secondary'
                                    style={{'marginRight': '0.3125rem'}}>{$$('wizard_back_label')}</button>
                            <button type='button' onClick={this.onContinueClick}
                                    className='btn btn-success'>{$$('wizard_continue_label')}</button>
                        </div>
                    </div>}
                    {this.props.profilePage &&
                    <div className='form-group' style={{'marginTop': '5.25rem'}}>
                        <div className='row flex-end-justify' style={{'float': 'right', 'margin': '0rem'}}>
                            <button type='button' onClick={() => {
                                if (this.form.checkValidity() !== true) {
                                    if (this.form['phone_numbers'].checkValidity() === false) {
                                        this.setState({validPhoneNumbersField: false});
                                    }
                                    if (this.form['specialties'].checkValidity() === false) {
                                        this.setState({validSpecialtiesField: false});
                                    }
                                    this.displayValidity();
                                } else {
                                    this.props.submitForm(false);
                                }
                            }}
                                    className='btn btn-success'>{$$('update')}</button>
                        </div>
                    </div>}
                </form>
                <CenteredModal title={$$('wizard_back_modal_title')}
                               show={this.state.showModal}
                               onHide={() => this.setState({showModal: false})}
                               onConfirm={this.onConfirmModal}
                               confirmBtnLabel={$$('OK')}
                               cancelBtnLabel={$$('cancel_btn')}>
                    {$$('wizard_back_modal_message')}
                </CenteredModal>
            </div>
        )
    }
}

PersonalInformation.propTypes = {
    clearRegistrationData: PropTypes.func,
    i18n: PropTypes.object,
    isStepCompleted: PropTypes.bool,
    profilePage: PropTypes.bool,
    onWizardStepChange: PropTypes.func,
    dataChanged: PropTypes.func,
    submitForm: PropTypes.func,
    registration: PropTypes.object,
    registrationFields: PropTypes.object,
    specialties: PropTypes.array,
    wizardStep: PropTypes.number
}

const mapStateToProps = (state) => ({
    registration: state.register
});

const mapDispatchToProps = {
    clearRegistrationData
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformation)