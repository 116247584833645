import {generalPractitionerService} from '../service/generalPractitionerService'
import {
    DUMMY,
    FETCH_SELECTED_USER_GENERAL_PRACTITIONER_ERROR,
    FETCH_SELECTED_USER_GENERAL_PRACTITIONER_SUCCESS
} from './actions';

/**
 * Fetch selected users general practitioner
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchSelectedUserGeneralPractitioner(userId, params) {
    return (dispatch, getState) => {
        return generalPractitionerService.fetchSelectedUserGeneralPractitioner(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_SELECTED_USER_GENERAL_PRACTITIONER_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_GENERAL_PRACTITIONER_ERROR, result: err});
        })
    }
}
