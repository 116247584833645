import {fetchHelper} from '../helpers/request_helper';
import {GET_USER_CARDIAC_STATS_URL} from '../constants/api_paths';


export const cardiacStatsService = {
    fetchSelectedUserCardiacStatsResult
};


/**
 * Fetch the selected user Cardiac stats
 *
 * @param {string} userId id of the user to fetch stats for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
function fetchSelectedUserCardiacStatsResult(userId, params) {
    return fetchHelper.callGet(GET_USER_CARDIAC_STATS_URL.replace('{targetUserId}', userId), params);
}