import React, {useEffect, useState} from "react"
import {$$, _$$} from "../../helpers/localization";
import {clinicService} from "../../service/clinic_service";
import CenteredModal from "../shared/CenteredModal";
import BackButton from "../shared/BackButton";

export default function ActiveInvitationList({onClose, orgId}) {
    const [invitationList, setInvitationList] = useState([]);

    useEffect(() => {
        clinicService.fetchActiveInvitations(orgId).then((res) => {
            setInvitationList(res)
        })
    }, [])

    const onDelete = (invId) => {
        clinicService.deleteInvitation(invId, orgId).then(
            clinicService.fetchActiveInvitations(orgId).then((res) => {
                setInvitationList(res)
            })
        )
    }

    return <div className="patient-card">
        <div className="patient-card-header">
            <h2 className="card-title card-header-title"><BackButton onClick={onClose} title={$$("back")}/>
                &nbsp;&nbsp;{$$("staff_management.buttons.active_invitations")}</h2>
        </div>
        <div className="card-body">
            {invitationList.length === 0 && <p>{$$("staff_management.no_active_invitations")}</p>}
            <table className="table table-striped medrec-default-text-color" style={{maxWidth:"600px"}}>
            <tbody>
            {
                invitationList.map((inv) => {
                    return <Invitation key={inv.id} inv={inv} onDelete={onDelete} />
                })
            }
            </tbody>
            </table>
        </div>
    </div>
}

function Invitation({inv, onDelete}) {
    const [data, setData] = useState();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setData(JSON.parse(inv.json_data));
    }, [inv])

    if (!data) {
        return null;
    }

    return <tr className="">
        <td>{data.fullname}</td>
        <td>{inv.email}</td>
        <td><button className="btn btn-danger btn-sm" onClick={()=>{setShowModal(true)}}>{$$("delete_label")}</button></td>
        {showModal && <CenteredModal title={$$('staff_management.invitation_delete_modal_title')}
                        dialogClassName='doc-delete-modal'
                        show={true}
                        onHide={()=>{setShowModal(false)}}
                        onConfirm={()=>{ onDelete(inv.id) }}
                        cancelBtnLabel={$$('cancel_btn')}
                        id="delete-modal-title"
                        className="center-delete-modal"
                        idFooter="footer-delete-modal"
                        confirmBtnLabel={$$('delete_label')}
                        confirmBtnClass="danger"
                        idBtnPrimary="btn-danger"
                        idBtnSecondary="btn-secondary">

            {_$$('staff_management.invitation_delete_body', data.fullname, inv.email)}<br/>

            <div className="bin-align">
                <i className="fas fa-trash-alt fa-3x"/>
            </div>
        </CenteredModal>}
    </tr>
}