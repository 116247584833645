import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    clearNotifications,
    getAllNotifications,
    markNotificationAsSeen
} from '../../actions/notifications_actions'
import NotificationsHeader from './NotificationsHeader';
import NotificationsPanel from './NotificationsPanel'
import PropTypes from "prop-types";


class AllNotifications extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        this.props.getAllNotifications(10, 0, false, false);
    }

    render() {
        return (
            <div className='patient-card' style={{"marginLeft": "20px", "marginRight": "20px"}}>
                <NotificationsHeader/>
                <div className='notifications-list'>
                    <div className='notifications-list-body'>
                        <NotificationsPanel notifications={this.props.notifications}
                                            getAllNotifications={this.props.getAllNotifications}
                                            markNotificationAsSeen={this.props.markNotificationAsSeen}
                                            clearNotifications={this.props.clearNotifications}
                                            limit={10}
                                            notSeenOnly={false}/>
                    </div>
                </div>
            </div>
        )
    }
}

AllNotifications.propTypes = {
    getAllNotifications:  PropTypes.func,
    markNotificationAsSeen:  PropTypes.func,
    clearNotifications:  PropTypes.func,
    i18n:  PropTypes.object,
    notifications:  PropTypes.any,

}

function mapStateToProps(state) {
    return {
        i18n: state.language,
        notifications: state.notifications.data
    }
}

export default connect(mapStateToProps, {
    getAllNotifications,
    markNotificationAsSeen,
    clearNotifications
})(AllNotifications);
