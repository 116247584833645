import {
    CHANGE_PROVIDER_CONFIGURATION_ERROR,
    CHANGE_PROVIDER_CONFIGURATION_SUCCESS,
    GET_PROVIDER_CONFIGURATION,
    GET_PROVIDER_CONFIGURATION_ERROR,
    LOGOUT,
    REQUEST_ERROR,
    REQUEST_SUCCESS
} from "../actions/actions";
import {requestStatus} from './requests_reducers';

const initialState = {data: {}, request: requestStatus(undefined, {})}

/**
 * Provider configuration reducer. Reduce state based on action type.
 *
 * @param {object} state the state of settings
 * @param {action} action the action to execute on the state
 * @returns {object} the new state
 */
export function provider_configuration(state = initialState, action) {
    switch (action.type) {
        case GET_PROVIDER_CONFIGURATION:
        case CHANGE_PROVIDER_CONFIGURATION_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})};
        }
        case GET_PROVIDER_CONFIGURATION_ERROR:
        case CHANGE_PROVIDER_CONFIGURATION_ERROR: {
            return {
                ...state, ...initialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.response})
            };
        }
        case LOGOUT: {
            return {...initialState};
        }
        default: {
            return state
        }
    }
}