import React, {Component} from 'react'
import {connect} from 'react-redux'
import {$$} from '../../helpers/localization';
import TabsContainer from "../main/TabsContainer";
import PatientVerticalCard from '../patient-vertical-card/PatientVerticalCard'
import PropTypes from "prop-types";


export class History extends Component {
    state = {
        events: []
    }

    constructor(props) {
        super(props);
    }


    getMedicalHistory() {
        return (
            <div className={"col-margin-left col-margin-right"}>
                <MedHistoryTitle i18n={this.props.i18n} name={this.props.medicalHistory.fullname}/>
                <TabsContainer keepSelectedUserAndOpenAppointment={this.props.keepSelectedUserAndOpenAppointment}
                               videoBackButton = {this.props.videoBackButton}
                               i18n={this.props.i18n} selectedUser={this.props.medicalHistory}/>
            </div>
        );
    }

    render() {
        return (
            <div className="history d-flex flex-container flex-row">
                <div className="main-col">
                    {this.getMedicalHistory()}
                </div>
                <div>
                    <PatientVerticalCard hide={false}
                                         i18n={this.props.i18n}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    medicalHistory: state.selectedUser.data
})

export default connect(mapStateToProps)(History)

class MedHistoryTitle extends React.PureComponent {
    render() {
        return (
            <div className="d-flex">
                <h2>{$$("medical_history")}</h2>
                <span className="med-history-title-name">{this.props.name}</span>
            </div>
        );
    }
}


MedHistoryTitle.propTypes = {
    name: PropTypes.string
}

History.propTypes = {
    dispatch:  PropTypes.func,
    i18n: PropTypes.object,
    name: PropTypes.string,
    keepSelectedUserAndOpenAppointment:  PropTypes.func,
    medicalHistory:  PropTypes.object
}
