import {symptomsService} from '../service/symptoms_service'
import {
    DUMMY,
    FETCH_SELECTED_USER_SYMPTOMS_ERROR,
    FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT,
    FETCH_SELECTED_USER_SYMPTOMS_SUCCESS,
    GET_NOMENCLATURE_ERROR,
    GET_NOMENCLATURE_REQUEST_SENT,
    GET_NOMENCLATURE_SUCCESS,
    NO_MORE_SYMPTOMS,
    RESET_SELECTED_USER_SYMPTOMS_SUCCESS
} from './actions';
import {SYMPTOMS_ENTRY} from '../constants/information-retrieval-types';

/**
 * Get nomencalture action.
 *
 * @returns {function} dispatch function
 */
export function getNomencalture() {
    return (dispatch) => {
        dispatch({type: GET_NOMENCLATURE_REQUEST_SENT});
        symptomsService.getNomencalture().then((res) => {
            if (res) {
                dispatch({type: GET_NOMENCLATURE_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: GET_NOMENCLATURE_ERROR, result: err});
        });
    }
}

/**
 * Fetch symptoms logs for a given user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @param {boolean} resetList - flag, if set to true reset the symptoms list
 * @returns {function} dispatch function
 */
export function fetchSelectedUserSymptomsLog(userId, params, resetList) {
    return (dispatch, getState) => {
        dispatch({type: FETCH_SELECTED_USER_SYMPTOMS_REQUEST_SENT});
        return symptomsService.fetchSelectedUserSymptoms(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            res = res.data[SYMPTOMS_ENTRY];
            if (res && res.length > 0) {
                if (resetList) {
                    dispatch({type: RESET_SELECTED_USER_SYMPTOMS_SUCCESS, result: res});
                } else {
                    dispatch({type: FETCH_SELECTED_USER_SYMPTOMS_SUCCESS, result: res});
                }
            } else {
                dispatch({type: NO_MORE_SYMPTOMS});
                if (resetList) {
                    dispatch({type: RESET_SELECTED_USER_SYMPTOMS_SUCCESS, result: res});
                }
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_SYMPTOMS_ERROR, result: err});
        })
    }
}