import React, {useEffect, useRef, useState} from 'react'
import {$$} from '../../helpers/localization'
import {downloadUtils} from '../../utils/downloadUtils'
import {MAXIMUM_FILE_SIZE, MAXIMUM_FILE_SIZE_UNIT} from '../../constants/files'
import CenteredModal from '../shared/CenteredModal';

// eslint-disable-next-line react/prop-types
const Dropzone = ({documents, onDocumentsChange, errors}) => {
    const [selectedFiles, setSelectedFiles] = useState(documents);
    const [errorMessage, setErrorMessage] = useState('');
    const [validFiles, setValidFiles] = useState(documents);
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);

    const fileInputRef = useRef();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [documentToBeDeleted, setDocumentToBeDeleted] = useState('')

    useEffect(() => {
        //duplicates are removed and the individual values are being added into the new array
        let filteredArray = selectedFiles.reduce((file, current) => {
            const x = file.find(item => item.name === current.name);
            if (!x) {
                return file.concat([current]);
            } else {
                return file;
            }
        }, []);
        setValidFiles([...filteredArray]);
        onDocumentsChange(filteredArray.filter(f => !f.hasOwnProperty('invalid')));
    }, [selectedFiles]);

    /**
     * Handler for drag over
     *
     * @param {object} e - the event object
     */
    const dragOver = (e) => {
        e.preventDefault();
    }

    /**
     * Handler for entering the drag zone
     *
     * @param {object} e - the event object
     */
    const dragEnter = (e) => {
        e.preventDefault();
    }

    /**
     * Handler for leaving the drag zone
     *
     * @param {object} e - the event object
     */
    const dragLeave = (e) => {
        e.preventDefault();
    }

    /**
     * Handler for dropping the file
     *
     * @param {object} e - the event object
     */
    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
    }

    /**
     * Handler for selecting a file
     */
    const fileInputClicked = () => {
        fileInputRef.current.value = null;
        fileInputRef.current.click();
    }

    /**
     * Iterates the list of files and if they're valid it updates the selected files
     * by adding the correct ones, otherwise it sets unsupported files and prepares
     * an error message to display
     *
     * @param {FileList} files - the list of selected files
     */
    const handleFiles = (files) => {
        for (let i = 0; i < files.length; i++) {
            if (validateFile(files[i]) && isFileSizeValid(files[i].size)) {
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
            } else {
                files[i]['invalid'] = true;
                setSelectedFiles(prevArray => [...prevArray, files[i]]);
                setErrorMessage($$('wizard_upload_documents_file_error_message'));
                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);
            }
        }
    }

    /**
     * Validates the file based on its type
     *
     * @param {File} file - the file to be validated
     */
    const validateFile = (file) => {
        const validTypes = downloadUtils.fileTypes();
        return validTypes.indexOf(file.type) !== -1;

    }

    /**
     * Method that handles file removal. It checks valid files, selected files and
     * unsupported file lists and if the file is part of them, it removes the file
     *
     * @param {string} name - the file name
     */
    const removeFile = (name) => {
        const validFileIndex = validFiles.findIndex(e => e.name === name);
        validFiles.splice(validFileIndex, 1);
        setValidFiles([...validFiles]);

        const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
        selectedFiles.splice(selectedFileIndex, 1);
        setSelectedFiles([...selectedFiles]);

        const unsupportedFileIndex = unsupportedFiles.findIndex(e => e.name === name);
        if (unsupportedFileIndex !== -1) {
            unsupportedFiles.splice(unsupportedFileIndex, 1);
            setUnsupportedFiles([...unsupportedFiles]);
        }
        setDocumentToBeDeleted('')
        setShowDeleteModal(false)
    }

    /**
     * Method that obtains the selected files
     */
    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
        }
    }

    const showModal = (show, documentToBeDeleted) => {
        setShowDeleteModal(show);
        setDocumentToBeDeleted(documentToBeDeleted)
    }

    /**
     * Handles the check for the file size and for the unit
     *
     * @param {number} size - the size of the uploaded file
     * @returns {boolean} - a boolean that says if the file size is valid or not
     */
    const isFileSizeValid = (size) => {
        const k = 1024;
        const sizes = downloadUtils.fileSizeUnits();
        const i = Math.floor(Math.log(size) / Math.log(k));

        let lessThanMaximumUnits = [...sizes].splice(0, sizes.indexOf(MAXIMUM_FILE_SIZE_UNIT));
        return (sizes[i] === MAXIMUM_FILE_SIZE_UNIT && parseFloat((size / Math.pow(k, i)).toFixed(2)) <= MAXIMUM_FILE_SIZE) ||
            (lessThanMaximumUnits.indexOf(sizes[i]) >= 0);
    }

    return (
        <div className={"full-width"}>
            <div className='dropzone-file-display-container'>
                {validFiles.map((data, i) =>
                    <div className='row dropzone-file-status-bar' key={i}>
                        <div className={"dropzone-file-remove-box"} onClick={data.invalid ? () => removeFile(data.name) : () => {
                        }}>
                            <span
                                className={`dropzone-file-name ${data.invalid ? 'dropzone-file-error' : ''}`}>{data.name}</span>&nbsp;{data.invalid &&
                        <span className='dropzone-file-error-message'>({errorMessage})</span>}
                        </div>
                        <div className='dropzone-file-remove' onClick={() => showModal(true, documentToBeDeleted)}><i
                            className='kt-menu__link-icon flaticon2-cancel'/></div>
                    </div>
                )}
            </div>
            <div className="dropzone-title">{$$('wizard_upload_documents_title')}</div>
            <div>
                {unsupportedFiles.length ? <div
                    className='dropzone-validation'>{$$('wizard_upload_documents_unsupported_file_message')}</div> : ''}
                <div className={errors ? 'dropzone-validation' : ''}>
                    {errors ? $$(errors) : ''}
                </div>
                <div className='dropzone-drop-container'
                     onDragOver={dragOver}
                     onDragEnter={dragEnter}
                     onDragLeave={dragLeave}
                     onDrop={fileDrop}
                     onClick={fileInputClicked}>
                    <div className='dropzone-drop-message'>
                        <div className='dropzone-upload-icon'/>
                        {$$('wizard_upload_documents_dropzone_label')}
                    </div>
                    <input ref={fileInputRef}
                           className="dropzone-file-input"
                           type="file"
                           multiple
                           onChange={filesSelected}
                    />
                </div>
            </div>
            <CenteredModal title={$$('delete_document_entry_modal_title')}
                           show={showDeleteModal}
                           onHide={() => showModal(false)}
                           onConfirm={() => removeFile(documentToBeDeleted)}
                           confirmBtnLabel={$$('OK')}
                           cancelBtnLabel={$$('cancel_btn')}
            >
                {$$('delete_document_entry_modal_message')}
            </CenteredModal>
        </div>
    )
}
export default Dropzone;