import React, {Component} from 'react'
import $ from "jquery";
import CornerstoneViewport from "react-cornerstone-viewport";
import {Link} from "react-router-dom";
import {ButtonToolbar} from "react-bootstrap";
import classNames from 'classnames';
import cornerstone from 'cornerstone-core'
import cornerstoneTools from 'cornerstone-tools'
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader'
import InvertIcon from '../../resources/images/icons/invert.png'
import RotateLeftIcon from '../../resources/images/icons/rotate_left.png'
import RotateRightIcon from '../../resources/images/icons/rotate_right.png'
import FlipXIcon from '../../resources/images/icons/flip_horizontal.png'
import FlipYIcon from '../../resources/images/icons/flip_vertical.png'
import AngleIcon from '../../resources/images/icons/angle.png'
import AngleDetachedIcon from '../../resources/images/icons/angle_detached.png'
import LevelsIcon from '../../resources/images/icons/levels.png'
import ZoomIcon from '../../resources/images/icons/zoom.png'
import PanIcon from '../../resources/images/icons/pan.png'
import ArrowAnnotateIcon from '../../resources/images/icons/annotate.png'
import LengthIcon from '../../resources/images/icons/length_ruler.png'
import BidirectionalIcon from '../../resources/images/icons/bidirectional.png'
import CircleRoiIcon from '../../resources/images/icons/ROI_circle.png'
import EllipseRoiIcon from '../../resources/images/icons/ROI_elipse.png'
import FreehandRoiIcon from '../../resources/images/icons/ROI_freehand.png'
import RotateIcon from '../../resources/images/icons/rotate_freehand.png'
import ProbeIcon from '../../resources/images/icons/probe.png'
import RectangleRoiIcon from '../../resources/images/icons/ROI_rectangle.png'
import ClearIcon from '../../resources/images/icons/clear.png'
import ResetIcon from '../../resources/images/icons/reset.png'
import _ from 'lodash';
import PropTypes from "prop-types";



const toolToDisplay = {
    Wwwc: 'WW/WC',
    Zoom: 'Zoom',
    Pan: 'Pan',
    Angle: 'Angle',
    ArrowAnnotate: 'Arrow',
    Bidirectional: 'Bidirectional',
    CircleRoi: 'Circle',
    CobbAngle: 'Detached Angle',
    EllipticalRoi: 'Ellipse',
    FreehandRoi: 'Freehand',
    Length: 'Length',
    Rotate: 'Rotate',
    Probe: 'Probe',
    RectangleRoi: 'Rectangle'
};

export default class DicomViewer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stackLoading: {
                loading: false
            },
            processedStacks: [],
            activeTool: 'Wwwc',
            tools: [
                // Mouse
                {
                    name: 'Wwwc',
                    mode: 'active',
                    modeOptions: {mouseButtonMask: 1},
                    createButton: true,
                    icon: LevelsIcon
                },
                {
                    name: 'Zoom',
                    mode: 'active',
                    modeOptions: {mouseButtonMask: 2},
                    createButton: true,
                    icon: ZoomIcon
                },
                {
                    name: 'Pan',
                    mode: 'active',
                    modeOptions: {mouseButtonMask: 4},
                    createButton: true,
                    icon: PanIcon
                },
                {name: 'Angle', createButton: true, icon: AngleIcon},
                {name: 'ArrowAnnotate', createButton: true, icon: ArrowAnnotateIcon},
                {name: 'Bidirectional', createButton: true, icon: BidirectionalIcon},
                {name: 'CircleRoi', createButton: true, icon: CircleRoiIcon},
                {name: 'CobbAngle', createButton: true, icon: AngleDetachedIcon},
                {name: 'EllipticalRoi', createButton: true, icon: EllipseRoiIcon},
                {name: 'FreehandRoi', createButton: true, icon: FreehandRoiIcon},
                {name: 'Length', createButton: true, icon: LengthIcon},
                {name: 'Rotate', createButton: true, icon: RotateIcon},
                {name: 'Probe', createButton: true, icon: ProbeIcon},
                {name: 'RectangleRoi', createButton: true, icon: RectangleRoiIcon},
                {name: 'TextMarker', createButton: false}, //this is not working for some reason

                // Scroll
                {name: 'StackScrollMouseWheel', mode: 'active'},
                // Touch
                {name: 'PanMultiTouch', mode: 'active'},
                {name: 'ZoomTouchPinch', mode: 'active'},
                {name: 'StackScrollMultiTouch', mode: 'active'},
            ]
        }


    }

    componentDidMount() {
        $("#root").css("display", "none");
        let ownPropertyNames = Object.getOwnPropertyNames(this.props.imageIds);
        if (ownPropertyNames.length === 1 && this.props.imageIds[ownPropertyNames[0]].length === 1) {
            let url = this.props.imageIds[ownPropertyNames[0]][0];
            if (url.startsWith('wadouri:')) {
                url = url.replace("wadouri:", "");
            }
            cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.load(url).then((dataSet) => {
                // dataset is now loaded, get the # of frames so we can build the array of imageIds
                try {

                    var numFrames = dataSet.intString('x00280008');
                    if (!numFrames) {
                        this.setState({imageIds: ['wadouri:' + url]});
                        return;
                    }

                    var imageIds = [];
                    var imageIdRoot = 'wadouri:' + url;

                    for (var i = 0; i < numFrames; i++) {
                        var imageId = imageIdRoot + "?frame=" + i;
                        imageIds.push(imageId);
                    }
                    this.setState({imageIds: imageIds})
                } catch (e) {
                    console.log(e)
                }
            });
        } else {
            let firstStack = ownPropertyNames[0];
            this.setState({stacks: this.props.imageIds}, () => this.selectStack(firstStack));
        }
    }

    selectStack = (stackKey) => {
        if (this.state.processedStacks[stackKey]) {
            this.setState({selectedStack: stackKey, imageIds: this.state.stacks[stackKey]});
        } else {

            let stack = this.state.stacks[stackKey];
            let stackLength = stack.length;
            let instanceNumbers = [];
            let unprocessed = stackLength;
            this.setState({stackLoading: {loading: true, no: (stackLength - unprocessed + 1), of: stackLength}});
            stack.forEach((link) => {
                let url = link.replace("wadouri:", "");
                cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.load(url).then((dataSet) => {

                    instanceNumbers.push({key: link, idx: dataSet.intString('x00200013') - 1});
                    unprocessed = unprocessed - 1;
                    this.setState({stackLoading: {loading: true, no: (stackLength - unprocessed), of: stackLength}});
                    if (unprocessed === 0) { //final image in the stack. sort and update the component state

                        //sort
                        let sorted = instanceNumbers.sort((a, b) => {
                            return a.idx - b.idx;
                        }).map((o) => o.key);

                        //clone stacks
                        let newStacks = _.cloneDeep(this.state.stacks);

                        //change list
                        newStacks[stackKey] = sorted;

                        //update state
                        let processedStacks = [...this.state.processedStacks];
                        processedStacks[stackKey] = true;
                        this.setState(
                            {
                                stackLoading: {loading: false},
                                stacks: newStacks,
                                selectedStack: stackKey,
                                imageIds: newStacks[stackKey],
                                processedStacks: processedStacks
                            }
                        );
                    }
                });
            });
        }


    }

    onClose = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClose();
    }

    componentWillUnmount() {
        $("#root").css("display", "flex");
        cornerstone.imageCache.purgeCache();
    }

    getToolbarButtons = () => {
        let btnClasses = "btn btn-sm btn-dark";
        return <div className="position-relative">
            <div className="btn-group-vertical">
                {
                    this.state.tools.filter(o => o.createButton === true).map((t, i) => {
                            let classes = classNames(btnClasses, {"active": this.state.activeTool === t.name});
                            return <button key={i} className={classes} onClick={() => {
                                this.setState({activeTool: t.name})
                            }}>
                                <img className="dicom-toolbar-button-icon" src={t.icon} alt={toolToDisplay[t.name]}/>
                                {toolToDisplay[t.name]}
                            </button>
                        }
                    )
                }

                <button className={classNames(btnClasses)} onClick={() => {
                    const viewport = cornerstone.getViewport(this.state.cornerstoneElement);
                    viewport.invert = !viewport.invert;
                    cornerstone.setViewport(this.state.cornerstoneElement, viewport);
                }}>
                    <img className="dicom-toolbar-button-icon" src={InvertIcon} alt="Invert"/>
                    Invert
                </button>
                <button className={classNames(btnClasses)} onClick={() => {
                    const viewport = cornerstone.getViewport(this.state.cornerstoneElement);
                    viewport.rotation -= 90;
                    cornerstone.setViewport(this.state.cornerstoneElement, viewport);
                }}><img className="dicom-toolbar-button-icon" src={RotateLeftIcon} alt="Rotate Left"/>
                    Rotate Left
                </button>
                <button className={classNames(btnClasses)} onClick={() => {
                    const viewport = cornerstone.getViewport(this.state.cornerstoneElement);
                    viewport.rotation += 90;
                    cornerstone.setViewport(this.state.cornerstoneElement, viewport);
                }}><img className="dicom-toolbar-button-icon" src={RotateRightIcon} alt="Rotate Right"/>
                    Rotate Right
                </button>
                <button className={classNames(btnClasses)} onClick={() => {
                    const viewport = cornerstone.getViewport(this.state.cornerstoneElement);
                    viewport.hflip = !viewport.hflip;
                    cornerstone.setViewport(this.state.cornerstoneElement, viewport);
                }}><img className="dicom-toolbar-button-icon" src={FlipXIcon} alt="Flip X"/>
                    Flip H
                </button>
                <button className={classNames(btnClasses)} onClick={() => {
                    const viewport = cornerstone.getViewport(this.state.cornerstoneElement);
                    viewport.vflip = !viewport.vflip;
                    cornerstone.setViewport(this.state.cornerstoneElement, viewport);
                }}><img className="dicom-toolbar-button-icon" src={FlipYIcon} alt="Flip Y"/>
                    Flip V
                </button>
                <button className={classNames(btnClasses)} onClick={() => {
                    cornerstone.reset(this.state.cornerstoneElement);
                }}><img className="dicom-toolbar-button-icon" src={ResetIcon} alt="Reset"/>
                    Reset
                </button>
                <button className={classNames(btnClasses)} onClick={() => {
                    const canvas = this.state.cornerstoneElement;
                    this.state.tools.forEach((t) => {
                        cornerstoneTools.clearToolState(canvas, t.name);
                    })
                    cornerstone.setViewport(canvas, cornerstone.getViewport(canvas, cornerstone.getImage(canvas)));
                }}><img className="dicom-toolbar-button-icon" src={ClearIcon} alt="Clear"/>
                    Clear
                </button>
            </div>
        </div>
    }

    render() {
        return (
            <div className="dicom-viewer-container">
                <div className="dicom-viewer-wrap">
                    <div className="dicom-viewer-header kt-header">
                        <div className="kt-aside__brand">
                            <div className="kt-aside__brand-logo">
                                <Link to="" onClick={this.onClose}>
                                    <div className="main-logo-label">MEDREC : M</div>
                                    <div className="sub-logo-label">clinic</div>
                                </Link>
                            </div>
                        </div>
                        <div className="kt-header__topbar-item align-self-center mr-3">{this.props.document.title}</div>
                        <div className="kt-header__topbar">
                            <div
                                className="kt-header__topbar-item align-self-center mr-3">{this.props.selectedUser.fullname}</div>
                            <button className="btn btn-primary btn-sm kt-header__topbar-item align-self-center"
                                    onClick={this.onClose}>Close
                            </button>
                        </div>
                    </div>
                    <div className="dicom-viewer-header kt-header-mobile">
                        <div className="kt-header-mobile__logo">
                            <Link to="" onClick={this.onClose}>
                                <div className="main-logo-label">MEDREC : M</div>
                                <div className="sub-logo-label">clinic</div>
                            </Link>
                        </div>
                        <div className="text-white align-self-center mr-3">{this.props.document.title}</div>
                        <div className="kt-header-mobile__toolbar">
                            <div className="text-white align-self-center mr-3">{this.props.selectedUser.fullname}</div>
                            <button className="btn btn-outline-secondary btn-sm align-self-center"
                                    onClick={this.onClose}>Close
                            </button>
                        </div>
                    </div>

                    <ButtonToolbar className="bg-dark p-2">
                        {this.getToolbarButtons()}
                    </ButtonToolbar>
                    <div className="dicom-viewer-viewport-stack-wrap">
                        {this.state.imageIds && !this.state.stackLoading.loading &&
                        <CornerstoneViewport enableResizeDetector={false} imageIds={this.state.imageIds}
                                             onElementEnabled={(e) => {
                                                 this.setState({cornerstoneElement: e.detail.element})
                                             }}
                                             tools={this.state.tools}
                                             activeTool={this.state.activeTool}
                                             style={{ overflow: 'hidden', flex: '1'}}
                        />}
                        {
                            this.state.stackLoading.loading && <div className="dicom-viewer-loading-container">
                                <h2>Loading stack....
                                    <div
                                        className="mt-2 small text-center">image {this.state.stackLoading.no} of {this.state.stackLoading.of}</div>
                                </h2>
                            </div>
                        }
                        {
                            this.state.stacks && <ul className="list-group dicom-viewer-stack-list bg-dark">
                                {
                                    Object.keys(this.state.stacks).map((a, b) => {
                                        return <button key={b}
                                                       className={classNames("list-group-item list-group-item-action btn-dark", {active: a === this.state.selectedStack})}
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           e.stopPropagation();
                                                           this.selectStack(a);
                                                       }}>{a}</button>

                                    })
                                }
                            </ul>
                        }
                    </div>
                </div>
            </div>
        )
    }
}


DicomViewer.propTypes = {
    appointmentStats: PropTypes.object,
    document: PropTypes.object,
    imageIds: PropTypes.object,
    onClose:  PropTypes.func,
    selectedUser: PropTypes.object
}