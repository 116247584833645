import {
    LOGIN_REQUEST_CLEAR_STATUS,
    LOGIN_REQUEST_ERROR,
    LOGIN_REQUEST_SENT,
    LOGIN_REQUEST_SUCCESS,
    LOGOUT,
    TOKEN_REFRESH_REQUEST_ERROR,
    TOKEN_REFRESH_REQUEST_SENT,
    TOKEN_REFRESH_REQUEST_SUCCESS
} from "./actions";
import {authService} from '../service/auth_service'
import {getNomencalture} from "./symptoms_actions";
import history from '../helpers/history'
import {Routes} from '../constants/routes';
import {getUserInfo} from "./users_actions";

/**
 * Login action, dispatch login, store user date in localstorage
 *
 * @param {string} email - E-mail used for login
 * @param {string} password - Password used for login
 * @param {boolean} linkLogin - flag that if set means that the login is from a temporary account link
 * @param {string} code - 2FA code
 * @returns {function} dispatch function
 */
export function login(email, password, linkLogin, code) {
    return (dispatch) => {
        dispatch({type: LOGIN_REQUEST_SENT});
        authService.login(email, password, code)
            .then(res => {
                if (res.access_token && res.refresh_token) {
                    dispatch({type: LOGIN_REQUEST_SUCCESS, response: res});
                    dispatch(getUserInfo(true)).then(r => {
                        if (r.internal) {
                            history.push(Routes.SELECT_PRACTICE);
                        } else {
                            history.push(Routes.DASHBOARD);
                        }
                    });
                    dispatch(getNomencalture());

                }
            })
            .catch((error) => {
                if (linkLogin) {
                    history.push(Routes.DASHBOARD);
                } else {
                    dispatch({type: LOGIN_REQUEST_ERROR, response: error});
                }
            });
    }
}

export function managementLogin(email, password, linkLogin) {
    return (dispatch) => {
        dispatch({type: LOGIN_REQUEST_SENT});
        authService.login(email, password)
            .then(res => {
                if (res.access_token && res.refresh_token) {
                    dispatch({type: LOGIN_REQUEST_SUCCESS, management_login: true, response: res});
                    dispatch(getUserInfo(true))
                    dispatch(getNomencalture());
                    history.push(Routes.SELECT_PRACTICE);
                }
            })
            .catch((error) => {
                if (linkLogin) {
                    history.push(Routes.SELECT_PRACTICE);
                } else {
                    dispatch({type: LOGIN_REQUEST_ERROR, response: error});
                }
            });
    }
}

/**
 * Refresh token action, dispatch action.
 *
 * @param {string} token - the refresh token
 * @returns {function} dispatch function
 */
export function refreshToken(token) {
    return (dispatch) => {
        dispatch({type: TOKEN_REFRESH_REQUEST_SENT});
        authService.refreshToken(token)
            .then(res => {
                if (res.access_token && res.refresh_token) {
                    dispatch({type: TOKEN_REFRESH_REQUEST_SUCCESS, response: res});
                }
            })
            .catch((error) => {
                dispatch({type: LOGOUT});
                dispatch({type: TOKEN_REFRESH_REQUEST_ERROR, response: error});
                history.push(Routes.LOGIN);
            });
    }
}

/**
 * Logout action, clear user data and tokens from storage.
 */
export function logout(username, password) {

    try {
        if (window.twilioRoom) {
            window.twilioRoom.disconnect();
        }
    } catch (e) {

    }

    return (dispatch) => {
        try {
            authService.logout();
        } catch (e) {
            console.log(e)
            dispatch({type: LOGOUT});
            history.push(Routes.LOGIN);
        }
        dispatch({type: LOGOUT});
        history.push(Routes.LOGIN);

        if (username && password) {
            history.push(Routes.LOGIN + `?username=${username}&password=${password}`);
        } else {
            history.push(Routes.LOGIN);
        }
    }
}

export function logoutWithRoute(route) {

    try {
        if (window.twilioRoom) {
            window.twilioRoom.disconnect();
        }
    } catch (e) {

    }

    return (dispatch) => {
        try {
            authService.logout();
        } catch (e) {
            console.log(e)
            dispatch({type: LOGOUT});
        }
        dispatch({type: LOGOUT});
        history.push(route);
    }
}

/**
 * Clear the request status.
 * @return {function(...[*]=)}
 */
export function clearLoginResponse() {
    return (dispatch) => {
        dispatch({type: LOGIN_REQUEST_CLEAR_STATUS})
    }
}