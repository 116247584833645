import React from 'react'
import PersonalInformation from './PersonalInformation'
import Biography from './Biography'
import PracticeDetails from './PracticeDetails'
import UploadDocuments from './UploadDocuments'
import PropTypes from "prop-types";


export class WizardSteps extends React.Component {
    state = {
        formclass: '',
        errors: {}
    }

    constructor(props) {
        super(props)
    }



    render() {
        return (
            <div>
                {this.props.wizardStep === 0 && <PersonalInformation onWizardStepChange={this.props.onWizardStepChange}
                                                                     wizardStep={this.props.wizardStep}
                                                                     isStepCompleted={this.props.completedSteps.firstStep}
                                                                     registrationFields={this.props.registrationFields}
                                                                     specialties={this.props.specialties}
                                                                     isInternal={this.props.isInternal}
                                                                     i18n={this.props.i18n}/>}
                {this.props.wizardStep === 1 ? !this.props.isInternal ? <Biography onWizardStepChange={this.props.onWizardStepChange}
                                                           wizardStep={this.props.wizardStep}
                                                           isStepCompleted={this.props.completedSteps.secondStep}
                                                           registrationFields={this.props.registrationFields}
                                                           i18n={this.props.i18n}/> : <PracticeDetails onWizardStepChange={this.props.onWizardStepChange}
                                                                                                       wizardStep={this.props.wizardStep}
                                                                                                       isStepCompleted={this.props.completedSteps.thirdStep}
                                                                                                       registrationFields={this.props.registrationFields}
                                                                                                       isInternal={this.props.isInternal}
                                                                                                       i18n={this.props.i18n}/> : null
                }
                {this.props.wizardStep === 2 && <PracticeDetails onWizardStepChange={this.props.onWizardStepChange}
                                                                 wizardStep={this.props.wizardStep}
                                                                 isStepCompleted={this.props.completedSteps.thirdStep}
                                                                 registrationFields={this.props.registrationFields}
                                                                 i18n={this.props.i18n}/>}
                {this.props.wizardStep === 3 && <UploadDocuments onWizardStepChange={this.props.onWizardStepChange}
                                                                 wizardStep={this.props.wizardStep}
                                                                 isStepCompleted={this.props.completedSteps.fourthStep}
                                                                 registrationFields={this.props.registrationFields}
                                                                 i18n={this.props.i18n}/>}
            </div>
        )
    }
}

WizardSteps.propTypes = {
    completedSteps: PropTypes.object,
    i18n:  PropTypes.object,
    onWizardStepChange:  PropTypes.func,
    registrationFields: PropTypes.object,
    specialties:  PropTypes.array,
    wizardStep:  PropTypes.number
}

export default WizardSteps
