import React, {Component} from "react";
import {Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import * as classnames from "classnames";
import PropTypes from "prop-types";

export default class RoundButton extends Component {
    constructor(props, context) {
        super(props, context);
    }

    renderTooltip = props => (
        <Tooltip {...props} show={props.show.toString()}>{this.props.title}</Tooltip>
    );

    render() {
        let iconClasses = classnames("centered-text", {[this.props.icon_classes]: true});
        let buttonClasses = classnames("btn btn-icon btn-icon-sm btn-circle btn-sm", {[this.props.btn_classes]: true, "disabled": this.props.disabled})
        return <OverlayTrigger
            placement={"top"}
            popperConfig={{
                modifiers: {
                    preventOverflow: {
                        enabled: false
                    }
                }
            }}
            overlay={this.renderTooltip}
        >
            <div {...this.props} className={buttonClasses}>
                <i className={iconClasses}/>
            </div>
        </OverlayTrigger>
    }
}

RoundButton.propTypes = {
    title: PropTypes.string,
    icon_classes: PropTypes.any,
    btn_classes: PropTypes.any,
    disabled: PropTypes.bool,
};