import {fetchHelper} from '../helpers/request_helper';
import {CREATE_EREFERRAL, DELETE_EREFERRAL, UPDATE_EREFERRAL} from "../constants/api_paths";


export const eReferralService = {
    createEreferral,
    updateEreferral,
    deleteEreferral
}


function createEreferral(encounterId, body) {
    return fetchHelper.callPost(CREATE_EREFERRAL.replace("{encounterId}", encounterId), body);
}

function updateEreferral(encounterId, body) {
    return fetchHelper.callPut(UPDATE_EREFERRAL.replace("{encounterId}", encounterId), body);
}

function deleteEreferral(encounterId, lrn) {
    return fetchHelper.callDelete(DELETE_EREFERRAL.replace("{encounterId}", encounterId)
        .replace("{lrn}", lrn))
}