import {
    LOGIN_REQUEST_ERROR,
    LOGIN_REQUEST_SUCCESS,
    REGISTER_CLEAR,
    REGISTER_ERROR,
    REGISTER_SUCCESS
} from './actions';
import {authService} from '../service/auth_service'
import {usersService} from '../service/users_service'
import {getNomencalture} from './symptoms_actions';
import {getUserInfo} from './users_actions';
import {uploadLoggedUserDocument} from './documents_actions'

/**
 * Register user, call the service and dispatch the appropiate reducer method
 *
 * @param {object} user - The user data to persist
 * @param {Array} documents - the user uploaded documents that need to be saved
 * @returns {function} dispatch function
 */
export function register(user, documents) {
    return (dispatch, getState) => {
        authService.register(user)
            .then(res => {
                if (res.id) {
                    dispatch({type: REGISTER_SUCCESS, response: res});
                    authService.login(user.email, user.password).then(res => {
                        if (res.access_token && res.refresh_token) {
                            dispatch({type: LOGIN_REQUEST_SUCCESS, response: res});
                            dispatch(getUserInfo(true));
                            dispatch(getNomencalture());

                            if (getState().providerImage.image) {
                                usersService.uploadImage(getState().providerImage.image);
                            }

                            for (let i = 0; i < documents.length; ++i) {
                                dispatch(uploadLoggedUserDocument(documents[i]));
                            }
                        }
                    })
                        .catch((error) => {
                            dispatch({type: LOGIN_REQUEST_ERROR, response: error});
                        });
                }
            })
            .catch((error) => {
                dispatch({type: REGISTER_ERROR, response: error});
            });
    }
}

/**
 * Clear registration data action
 *
 * @returns {function} dispatch function
 */
export function clearRegistrationData() {
    return (dispatch) => {
        dispatch({type: REGISTER_CLEAR});
    }
}