/**
 * Patient Register reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the register
 * @param {action} action the action to execute on the state
 */

import {LOGOUT, PATIENT_REGISTER_CLEAR, PATIENT_REGISTER_ERROR, PATIENT_REGISTER_SUCCESS} from "../actions/actions";

export function patient_register(state = {}, action) {
    switch (action.type) {
        case PATIENT_REGISTER_SUCCESS:
        case PATIENT_REGISTER_ERROR: {
            return {...state, response: action.response};
        }
        case PATIENT_REGISTER_CLEAR: {
            // eslint-disable-next-line no-unused-vars
            const {response, ...rest} = state;
            return rest;
        }
        case LOGOUT : {
            return {};
        }
        default: {
            return state
        }
    }
}