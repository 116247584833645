import {authService} from "../service/auth_service";
import {
    ACTION_2FA_CHANGED,
    ACTION_2FA_CLEAR_RESULT,
    ACTION_2FA_LOADED,
    ACTION_TOTP_LOADED, ACTION_TOTP_VERIFY_RESP,
    ACTION_TOTP_VERIFY_RESP_CLEAR
} from "./actions";

/**
 * Load the 2FA method from the server
 * @return {function(*): *}
 */
export function load2FAMethod() {
    return (dispatch) =>  authService.get2FAMethod().then(r => {
        dispatch({type: ACTION_2FA_LOADED, settings: { method: r.method }})
    })
}

/**
 * Clear both the
 * @return {function(...[*]=)}
 */
export function clear2FAResult() {
    return (dispatch) => {
        dispatch({type: ACTION_2FA_CLEAR_RESULT})
    }
}

export function clearTotpVerifyResp() {
    return (dispatch) => {
        dispatch({type: ACTION_TOTP_VERIFY_RESP_CLEAR})
    }
}

export function generateTotp() {
    return (dispatch) => {
        authService.generateTotp().then(r => {
            dispatch({type: ACTION_TOTP_LOADED, totp: r})
        })
    }
}

export function verifyTotp(req) {
    return (dispatch) => {
        authService.verifyTotp(req).then((r) => {
            dispatch({
                type: ACTION_TOTP_VERIFY_RESP,
                result: {status: r.result}
            })
        })
    }
}

export function update2FA(e) {
    return (dispatch) => {
        authService.update2FA(e).then(r => {
            dispatch({type: ACTION_2FA_CHANGED, settings: {method: e.method}})
        })
    }
}