import React, {Component} from 'react';
import {connect} from 'react-redux';
import {clinicService} from "../../service/clinic_service";
import {$$} from "../../helpers/localization";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import {appointmentUtils} from "../../utils/appointmentUtils";
import {COMPLETED} from "../../constants/appointment_payment_status";
import {formatUtils} from "../../utils/formatUtils";
import moment from "moment";
import classNames from "classnames";
import {APPOINTMENTS_FILTER, getColorForOption} from "../../constants/select_options";
import no_appointments from "../../resources/images/no_appointments.png";
import {infoUtils} from "../../utils/infoUtils";
import {medicalHistoryHelper} from "../../helpers/medical_history_helper";
import {DASHBOARD_NEXT_PATIENT_MED_HISTORY, PATIENTS_PATIENT_MED_HISTORY} from "../../constants/pages";
import {Routes} from "../../constants/routes";
import PropTypes from "prop-types";
import {getSelectedDateTimeFormat} from "../../utils/converter";
import MedicalHistoryButton from "../History/MedicalHistoryButton";
import PaginationComponent from "../common/Pagination";
import {addMonths, addWeeks, endOfWeek, format, startOfWeek, subMonths, subWeeks} from "date-fns";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import {bg, enGB} from 'date-fns/esm/locale'
import UserImageWrap from "../shared/UserImageWrap";
import Email from "../common/Email";
import Phone from "../common/Phone";
import {getEmail, getPhone} from "../../utils/userUtils";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import {getUserInfo} from "../../actions/users_actions";


const PAGE_SIZE = 5;

function filterByName(arr, name) {
    if (name) {
        return arr.filter((a) => {
            let p = a.participants.find(p => p.participant_role === 'PATIENT');
            return p &&
                (p.fullname.toLowerCase().indexOf(name.toLowerCase()) !== -1
                    || getEmail(p, "").toLowerCase().indexOf(name.toLowerCase()) !== -1
                    || getPhone(p, "").toLowerCase().indexOf(name.toLowerCase()) !== -1
                );
        });
    }
    return arr;
}

class ManagementDashboard extends Component {


    constructor(props, context) {
        super(props, context);
        moment.locale(props.i18n.selected.lang);
    }

    state =
        {
            day: true,
            week: false,
            month: false,
            latest_appointments: [],
            latest_registrations: [],
            filtered_latest_appointments: [],
            date: new Date(),
            page: 0,
            showDate: false,
            middleButtonLabel: "today",
            nameFilter: ""
        }
    fetchData = (start, end) => {
        clinicService.fetchAppointments(null, this.props.orgId, null, {
            org_id: this.props.orgId,
            after: start,
            before: end
        }).then(res => {
            res.sort((a, b) => a.starts_at - b.starts_at);
            let filteredLatestAppointments = filterByName(res, this.state.nameFilter).slice(0, PAGE_SIZE);
            this.setState({latest_appointments: res, filtered_latest_appointments: filteredLatestAppointments})
            if (res.length === 0 || filteredLatestAppointments.length === 0) {
                this.setState({
                    no_appointments: {
                        imgClass: '',
                        primaryLabelClass: 'no-lab-results-primary-label',
                        secondaryLabelClass: '',
                        src: no_appointments,
                        primaryLabel: $$('no_pending_appointments_primary_label'),
                        secondaryLabel: ''
                    }
                })
            } else {
                this.setState({
                    no_appointments: null
                })
            }
        });

        clinicService.fetchLatestRegistrations(this.props.orgId, 10).then(res => {
            this.setState({latest_registrations: res});
        })
    }
    handleBackClick = () => {
        if (this.state.day) {
            let date = moment(this.state.date).subtract(1, 'd').toDate();
            let end = endOfDay(date).getTime();
            let start = startOfDay(date).getTime();
            this.fetchData(start, end);
            this.setState({date: date, page: 0})
        }

        if (this.state.week) {
            let date = subWeeks(this.state.date, 1)
            let weekStartDate = startOfWeek(date, {
                locale: this.props.i18n.selected.lang, weekStartsOn: this.props.i18n.selected.lang === "bg" ? 1 : 0
            });
            let weekEndDate = endOfWeek(date, {
                locale: this.props.i18n.selected.lang, weekStartsOn: this.props.i18n.selected.lang === "bg" ? 1 : 0
            });
            let end = endOfDay(weekEndDate).getTime();
            let start = startOfDay(weekStartDate).getTime();
            this.fetchData(start, end);
            this.setState({date: date, page: 0})
        }

        if (this.state.month) {
            let date = subMonths(this.state.date, 1)
            let monthStartDate = startOfMonth(date);
            let monthEndDate = endOfMonth(date);
            let end = endOfDay(monthEndDate).getTime();
            let start = startOfDay(monthStartDate).getTime();
            this.fetchData(start, end);
            this.setState({date: date, page: 0})
        }
    }

    handleMiddleClick = () => {
        if (this.state.day) {
            let date = new Date();
            let end = endOfDay(date).getTime();
            let start = startOfDay(date).getTime();
            this.fetchData(start, end);
            this.setState({date: date, page: 0})
        }

        if (this.state.week) {
            let date = new Date();
            let weekStartDate = startOfWeek(date, {
                locale: this.props.i18n.selected.lang, weekStartsOn: this.props.i18n.selected.lang === "bg" ? 1 : 0
            });
            let weekEndDate = endOfWeek(date, {
                locale: this.props.i18n.selected.lang, weekStartsOn: this.props.i18n.selected.lang === "bg" ? 1 : 0
            });
            ;
            let end = endOfDay(weekEndDate).getTime();
            let start = startOfDay(weekStartDate).getTime();
            this.fetchData(start, end);
            this.setState({date: date, page: 0})
        }

        if (this.state.month) {
            let date = new Date();
            let monthStartDate = startOfMonth(date);
            let monthEndDate = endOfMonth(date);
            let end = endOfDay(monthEndDate).getTime();
            let start = startOfDay(monthStartDate).getTime();
            this.fetchData(start, end);
            this.setState({date: date, page: 0})
        }
    }

    handleForwardClick = () => {

        if (this.state.day) {
            let date = moment(this.state.date).add(1, 'd').toDate();
            let end = endOfDay(date).getTime();
            let start = startOfDay(date).getTime();
            this.fetchData(start, end);
            this.setState({date: date, page: 0})
        }

        if (this.state.week) {
            let date = addWeeks(this.state.date, 1)
            let weekStartDate = startOfWeek(date, {
                locale: this.props.i18n.selected.lang, weekStartsOn: this.props.i18n.selected.lang === "bg" ? 1 : 0
            });
            let weekEndDate = endOfWeek(date, {
                locale: this.props.i18n.selected.lang, weekStartsOn: this.props.i18n.selected.lang === "bg" ? 1 : 0
            });
            ;
            let end = endOfDay(weekEndDate).getTime();
            let start = startOfDay(weekStartDate).getTime();
            this.fetchData(start, end);
            this.setState({date: date, page: 0})
        }

        if (this.state.month) {
            let date = addMonths(this.state.date, 1)
            let monthStartDate = startOfMonth(date);
            let monthEndDate = endOfMonth(date);
            let end = endOfDay(monthEndDate).getTime();
            let start = startOfDay(monthStartDate).getTime();
            this.fetchData(start, end);
            this.setState({date: date, page: 0})
        }
    }

    handleDayClick = () => {
        this.setState({
                day: true,
                week: false,
                showDate: false,
                month: false,
                middleButtonLabel: "today",
                page: 0
            }, () => {
                let date = new Date();
                let end = endOfDay(date).getTime();
                let start = startOfDay(date).getTime();
                this.fetchData(start, end);
                this.setState({date: date})
            }
        )
    }

    handleWeekClick = () => {
        this.setState({
                day: false,
                week: true,
                showDate: true,
                month: false,
                middleButtonLabel: "this_week",
                page: 0
            }, () => {
                let date = new Date();
                let weekStartDate = startOfWeek(date, {
                    locale: this.props.i18n.selected.lang, weekStartsOn: this.props.i18n.selected.lang === "bg" ? 1 : 0
                });
                let weekEndDate = endOfWeek(date, {
                    locale: this.props.i18n.selected.lang, weekStartsOn: this.props.i18n.selected.lang === "bg" ? 1 : 0
                });
                let end = endOfDay(weekEndDate).getTime();
                let start = startOfDay(weekStartDate).getTime();
                this.fetchData(start, end);
                this.setState({date: date})
            }
        )
    }

    handleMonthClick = () => {
        this.setState({
                day: false,
                week: false,
                month: true,
                showDate: true,
                middleButtonLabel: "this_month",
                page: 0
            }, () => {
                let date = new Date();
                let monthStartDate = startOfMonth(date);
                let monthEndDate = endOfMonth(date);
                let end = endOfDay(monthEndDate).getTime();
                let start = startOfDay(monthStartDate).getTime();
                this.fetchData(start, end);
                this.setState({date: date})
            }
        )
    }

    componentDidMount() {
        let date = new Date();
        let end = endOfDay(date).getTime();
        let start = startOfDay(date).getTime();
        this.fetchData(start, end);
        this.setState({date: date})
        this.props.getUserInfo(false);
    }

    componentDidUpdate(oldProps) {
        if (this.props.i18n !== oldProps.i18n) {
            moment.locale(this.props.i18n.selected.lang);
            if (this.state.week) {
                let date = this.state.date;
                let weekStartDate = startOfWeek(date, {
                    locale: this.props.i18n.selected.lang, weekStartsOn: this.props.i18n.selected.lang === "bg" ? 1 : 0
                });
                let weekEndDate = endOfWeek(date, {
                    locale: this.props.i18n.selected.lang, weekStartsOn: this.props.i18n.selected.lang === "bg" ? 1 : 0
                });
                let end = endOfDay(weekEndDate).getTime();
                let start = startOfDay(weekStartDate).getTime();
                this.fetchData(start, end);
            }
        }
    }

    getLabelForOtherDate = () => {
        if (this.state.day) {
            return dateTimeUtils.getFormattedDateWithTime(this.state.date)
        }

        if (this.state.week) {
            let start = moment(startOfWeek(this.state.date, {
                locale: this.props.i18n.selected.lang, weekStartsOn: this.props.i18n.selected.lang === "bg" ? 1 : 0
            })).format('DD MMM');
            let end = moment(endOfWeek(this.state.date, {
                locale: this.props.i18n.selected.lang, weekStartsOn: this.props.i18n.selected.lang === "bg" ? 1 : 0
            })).format('DD MMM');

            return start.toString() + " - " + end.toString()
        }

        if (this.state.month) {
            return format(
                this.state.date, 'LLLL yyyy',
                {locale: this.props.i18n.selected.lang === "bg" ? bg : enGB})
        }
    }

    search = () => {
        this.setState({
            filtered_latest_appointments: filterByName(this.state.latest_appointments, this.state.nameFilter).slice((this.state.page * PAGE_SIZE), ((this.state.page * PAGE_SIZE) + PAGE_SIZE))
        })
    }


    render() {
        let noPatients = {
            imgClass: 'no-pending-appointments-img',
            primaryLabelClass: 'no-pending-appointments-primary-label',
            secondaryLabelClass: '',
            src: no_appointments,
            primaryLabel: $$('no_records_found'),
            secondaryLabel: ''
        }

        return (
            <div className="management-dashboard-page">
                <h2>{$$("Dashboard")}</h2>
                <div className="d-flex med-history-page">
                    <div className="main-col m-2">
                        <div className={"management-header-buttons mb-2"}>
                            <div className="d-flex flex-wrap justify-content-start align-items-center">
                                <div className="btn-group dashboard-date-pick-button mb-2 mr-2">
                                    <button type="`button" className="fc-prev-button-custom btn btn-primary"
                                            aria-label="prev"
                                            onClick={this.handleBackClick}>
                                        <span className="fa fa-chevron-left"></span>
                                    </button>
                                    <button type="button"
                                            className="fc-today-button-custom management-page-date-button btn btn-primary"
                                            onClick={this.handleMiddleClick}>
                                        {this.state.date.toDateString() === new Date().toDateString() ? $$(this.state.middleButtonLabel) : this.getLabelForOtherDate()}
                                    </button>
                                    <button type="button" className="fc-next-button-custom btn btn-primary"
                                            aria-label="next"
                                            onClick={this.handleForwardClick}>
                                        <span className="fa fa-chevron-right"></span>
                                    </button>
                                </div>
                                <div className="mb-2 mr-2">
                                    <input type="text" className="form-control" value={this.state.nameFilter}
                                           placeholder={$$("search_placeholder_label")}
                                           onChange={(e) => {
                                               let filtered = filterByName(this.state.latest_appointments, e.target.value);
                                               this.setState({
                                                   nameFilter: e.target.value, page: 0,
                                                   no_appointments: filtered.length === 0 ? {
                                                       imgClass: '',
                                                       primaryLabelClass: 'no-lab-results-primary-label',
                                                       secondaryLabelClass: '',
                                                       src: no_appointments,
                                                       primaryLabel: $$('no_pending_appointments_primary_label'),
                                                       secondaryLabel: ''
                                                   } : null,
                                                   filtered_latest_appointments: filtered.slice(0, PAGE_SIZE)
                                               })
                                           }
                                           }/>
                                </div>
                            </div>
                            <div className="btn-group dashboard-date-pick-button btn-group-toggle mb-2">
                                <button type="button"
                                        className={this.state.day ? "btn btn-primary active" : "btn btn-secondary"}
                                        aria-label="day"
                                        onClick={this.handleDayClick}>

                                    <span>{$$("day")}</span>
                                </button>
                                <button type="button"
                                        className={this.state.week ? "btn btn-primary active" : "btn btn-secondary"}
                                        aria-label="week"
                                        onClick={this.handleWeekClick}>

                                    <span>{$$("week")}</span>
                                </button>
                                <button type="button"
                                        className={this.state.month ? "btn btn-primary active" : "btn btn-secondary"}
                                        aria-label="month"
                                        onClick={this.handleMonthClick}>

                                    <span>{$$("month")}</span>
                                </button>
                            </div>
                        </div>

                        <div className="pl-1 pr-2 low-shadow-container">
                            {
                                this.state.filtered_latest_appointments.map(a => {
                                    return <AppointmentListItem showDate={this.state.showDate} key={a.id}
                                                                appointment={a}/>
                                })
                            }


                            {
                                this.state.latest_appointments?.length > 0 &&
                                <div className="pt-3 ml-3">
                                    <PaginationComponent alwaysShown={false}
                                                         itemsCount={filterByName(this.state.latest_appointments, this.state.nameFilter).length}
                                                         itemsPerPage={PAGE_SIZE}
                                                         currentPage={this.state.page + 1}
                                                         setCurrentPage={(p) => {
                                                             this.setState({page: p - 1}, this.search);
                                                         }}
                                    />
                                </div>
                            }
                            {
                                this.state.no_appointments && infoUtils.noData(this.state.no_appointments)
                            }
                        </div>
                    </div>
                    <div className="flex-shrink-0 flex-grow-0 m-2" style={{width: "333px"}}>
                        <div className="pt-2 pb-2">
                            {$$("latest_registrations")}
                        </div>
                        <div className="low-shadow-container">
                            {
                                this.state.latest_registrations.map(u => {
                                    return <div key={u.id} className={"d-flex"} id='appointment'
                                                style={{padding: "10px"}} onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        medicalHistoryHelper.prepareMedicalHistoryData(PATIENTS_PATIENT_MED_HISTORY, u, null, Routes.MANAGE_PATIENT_PAGE, Routes.MANAGEMENT_DASHBOARD);
                                    }}>
                                        <div>
                                            <UserImageWrap userID={u.id} show_red_dot={u.has_not_seen_patient_documents}
                                                           classnames="user-info-image patient-img"/>
                                        </div>
                                        <div className="ml-2">
                                            <div>
                                                <div>{u.fullname}</div>
                                            </div>
                                            <div>
                                                <b>{moment(u.date_registered).format(getSelectedDateTimeFormat(true))}</b>
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            {this.state.latest_registrations.length === 0 &&
                                infoUtils.noData(noPatients)
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        orgId: state.management.selectedOrganisation.id,
        i18n: state.language,
        settings: state.settings.data
    }
}

export default connect(mapStateToProps, {getUserInfo})(ManagementDashboard);


class AppointmentListItem extends React.Component {
    render() {
        if (appointmentUtils.isGroupEventParent(this.props.appointment)) {
            return <AppointmentListItemGroupEvent showDate={this.props.showDate} {...this.props} />
        }
        return <AppointmentListItemRegular showDate={this.props.showDate} {...this.props}  />
    }
}


class AppointmentListItemRegular extends React.Component {
    render() {
        let patientImageClass = 'patient-img';
        const activeClass = classNames('appointment-containter', {
            'appointment-active': (this.props.selectedAppointmentId === this.props.appointment.id)
        });

        let patient = this.props.appointment.participants.find(p => p.participant_role === 'PATIENT');
        let clinician = this.props.appointment.participants.find(p => p.participant_role === 'PROVIDER');

        return <div className={activeClass} onClick={this.onAppointmentClick}>

            <div className="d-flex flex-nowrap">
                <div className='d-flex appointment-container-row'>
                    <div className="text-nowrap ml-0 mr-2">
                        <i className='appointments-year-label kt-font-lg kt-menu__link-icon medrec-grey-2 far fa-clock'/> &nbsp;
                        <b className={" appointments-year-label kt-font-lg"}>{moment(this.props.appointment.starts_at).format('HH:mm')}</b>
                        {this.props.showDate && <>
                            <br/>
                            <b className={" appointments-year-label kt-font-lg"}>{moment(this.props.appointment.starts_at).format(getSelectedDateTimeFormat(true))}</b>
                        </>}
                    </div>
                </div>
                <div className='justify-content-center appointment-img-container appointment-container-row'>
                    <UserImageWrap userID={patient.user_id}
                                   show_red_dot={this.props.appointment.has_not_seen_patient_documents}
                                   classnames={patientImageClass}/>
                </div>
                <div className="flex-fill">
                    <div className='row appointment-container-row flex-nowrap space-between-justify'>
                        <div className="d-flex pl-2 pr-2">


                            <div className={"management-dashboard-patient-info"}>
                                <a onClick={event => {
                                    event.preventDefault();
                                    event.stopPropagation();
                                    medicalHistoryHelper.prepareMedicalHistoryData(DASHBOARD_NEXT_PATIENT_MED_HISTORY, patient, null, Routes.MANAGE_PATIENT_PAGE, Routes.MANAGEMENT_DASHBOARD);
                                }}>{patient.fullname}
                                </a>

                                <div className="lighter-font pt-1"><Email object={patient}/>
                                    <Phone object={patient}/>
                                </div>
                            </div>

                        </div>


                        <div className='d-flex pl-2 pr-2'>
                            <div>
                                <div className='medrec-grey-2'>
                                    {$$("appointment_table_provider_label")}
                                </div>
                                <div>
                                    {clinician.fullname}
                                </div>
                            </div>
                        </div>

                        <div className="flex-fill"></div>

                        <div className='pl-2 pr-2'>
                            <MedicalHistoryButton title={$$('medical_history')}
                                                  onClick={() => {
                                                      medicalHistoryHelper.prepareMedicalHistoryData(DASHBOARD_NEXT_PATIENT_MED_HISTORY, patient, null, Routes.MANAGE_PATIENT_PAGE, Routes.MANAGEMENT_DASHBOARD);
                                                  }}/>

                        </div>
                    </div>
                    <div className='card-body bg-light p-0'>
                        <div className="d-flex flex-nowrap space-between-justify">
                            <div className='p-2'>
                                <div>
                                    <div className='medrec-grey-2'>
                                        {appointmentUtils.appointmentType(this.props.appointment)}
                                    </div>
                                </div>
                            </div>
                            <div className="p-2">
                                <div
                                    className={this.props.appointment.payment_status === COMPLETED ? "medrec-green-1" : ""}>
                                    {formatUtils.currencyFormat(this.props.appointment.appointment_price.price_cents, this.props.appointment.appointment_price.currency)}
                                </div>
                            </div>
                            <div className="p-2"
                                 style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, this.props.appointment.status)}}>
                                {$$(this.props.appointment.status.toLowerCase())}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
        </div>
    }
}

class AppointmentListItemGroupEvent extends Component {
    render() {
        const activeClass = classNames('appointment-containter', {
            'appointment-active': (this.props.selectedAppointmentId === this.props.appointment.id)
        });
        let patientImageClass = 'patient-img';
        let clinician = this.props.appointment.participants.find(p => p.participant_role === 'PROVIDER');

        return <div /*id='appointment'*/ className={activeClass} onClick={this.onAppointmentClick}>
            <div className="d-flex flex-nowrap">
                <div className='d-flex appointment-container-row'>
                    <div className="text-nowrap ml-0 mr-2">
                        <i className='appointments-year-label kt-font-lg kt-menu__link-icon medrec-grey-2 far fa-clock'/> &nbsp;
                        <b className={" appointments-year-label kt-font-lg"}>{moment(this.props.appointment.starts_at).format('HH:mm')}</b>
                        {this.props.showDate && <>
                            <br/>
                            <b className={" appointments-year-label kt-font-lg"}>{moment(this.props.appointment.starts_at).format(getSelectedDateTimeFormat(true))}</b>
                        </>}
                    </div>
                </div>

                <div className='justify-content-center appointment-img-container appointment-container-row'>
                    <div className="patient-img"></div>
                </div>

                <div className="flex-fill">
                    <div className='row appointment-container-row flex-nowrap space-between-justify'>
                        <div className="d-flex pl-2 pr-2">
                            <div className={"management-dashboard-patient-info"}>
                                <span>{appointmentUtils.appointmentType(this.props.appointment)}</span>
                            </div>
                        </div>
                        <div className='d-flex pl-2 pr-2'>
                            <div>
                                <div className='medrec-grey-2'>
                                    {$$("appointment_table_provider_label")}
                                </div>
                                <div>
                                    {clinician.fullname}
                                </div>
                            </div>
                        </div>
                        <div className="flex-fill"></div>
                    </div>

                    <div className='card-body bg-light p-0'>
                        <div className="d-flex flex-nowrap space-between-justify">
                            <div className='p-2'>
                                <div>
                                    <span className='medrec-grey-2'>
                                        {$$("occupied_seats")}:
                                    </span>
                                    <span>{this.props.appointment.used_slots} / {this.props.appointment.max_patients}</span>
                                </div>
                            </div>
                            <div className="p-2">
                                <div
                                    className={this.props.appointment.payment_status === COMPLETED ? "medrec-green-1" : ""}>
                                    {formatUtils.currencyFormat(this.props.appointment.appointment_price.price_cents, this.props.appointment.appointment_price.currency)}
                                </div>
                            </div>
                            <div className="p-2"
                                 style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, this.props.appointment.status)}}>
                                {$$(this.props.appointment.status.toLowerCase())}
                            </div>
                        </div>
                    </div>

                </div>

                {/*<div className="d-flex col-3">
                    <div className='justify-content-center appointment-img-container'>
                        <UserImage userID={undefined}
                                   classnames={patientImageClass}/>
                    </div>
                    <a onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        // eslint-disable-next-line no-undef
                        medicalHistoryHelper.prepareMedicalHistoryData(DASHBOARD_NEXT_PATIENT_MED_HISTORY, patient, null, Routes.MANAGE_PATIENT_PAGE, Routes.MANAGEMENT_DASHBOARD);
                    }}>{appointmentUtils.appointmentType(this.props.appointment)}</a>
                </div>

                <div className='d-flex col-2'>
                    <div
                        className={this.props.appointment.payment_status === COMPLETED ? "medrec-green-1" : ""}>
                        {formatUtils.currencyFormat(this.props.appointment.appointment_price.price_cents, this.props.appointment.appointment_price.currency)}
                    </div>
                </div>

                <div className='d-flex col-2'>
                    <div>
                        <div className='medrec-grey-2'>
                            {$$("appointment_table_provider_label")}
                        </div>
                        <div>
                            {clinician.fullname}
                        </div>
                    </div>
                </div>

                <div className='d-flex col-2'>
                    <div>
                        <div className='medrec-grey-2'>
                            {$$("occupied_seats")}
                        </div>
                        <div>
                            {this.props.appointment.used_slots} / {this.props.appointment.max_patients}
                        </div>
                    </div>
                </div>
                <div className="d-flex col-2"/>*/}
            </div>
            <hr/>
        </div>
    }
}

AppointmentListItemRegular.propTypes = {
    appointment: PropTypes.object,
    showDate: PropTypes.bool,
    selectedAppointmentId: PropTypes.any,
}

AppointmentListItemGroupEvent.propTypes = {
    appointment: PropTypes.object,
    showDate: PropTypes.bool,
    selectedAppointmentId: PropTypes.any,
}

AppointmentListItem.propTypes = {
    appointment: PropTypes.object,
    showDate: PropTypes.bool,
    selectedAppointmentId: PropTypes.object
}

ManagementDashboard.propTypes = {
    i18n: PropTypes.object,
    settings: PropTypes.any,
    orgId: PropTypes.string
}

