import React, {Component} from 'react'
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import moment from 'moment';
import noImage from '../../../../public/media/profile/no_image.png'
import {getUserImage} from '../../../service/users_service';
import {$$} from '../../../helpers/localization'
import {Routes} from '../../../constants/routes'
import {medicalHistoryHelper} from '../../../helpers/medical_history_helper'
import {MESSAGES_PATIENT_MED_HISTORY} from '../../../constants/pages'
import MedicalHistoryButton from '../../History/MedicalHistoryButton';
import PropTypes from "prop-types";
import BlockingOptions from "./BlockingOptions";
import Search from "../../shared/Search";

export class ChatWithMessages extends Component {

    state = {
        searchTerm: "",
        selectedMessages: [],
        index: 0,
        showSearch: false
    }

    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
    }

    /**
     * Fetch the user image if the user changes.
     *
     * @param {object} prevProps - the previous component properties object
     */
    componentDidUpdate(prevProps) {
        if (this.imgRef.current && this.imgRef.current.data && prevProps.selectedUserId !== this.props.selectedUserId) {
            this.imgRef.current.src = "";
        }
        if (prevProps.selectedUserId !== this.props.selectedUserId) {
            this.fetchUserImage();
            this.setState({
                searchTerm: "",
                selectedMessages: [],
                index: 0,
                showSearch: false
            })
        }
    }

    /**
     * Fetch the user image when the component mounts.
     */
    componentDidMount() {
        if (this.imgRef.current && this.imgRef.current.data) {
            this.imgRef.current.src = "";
        }
        if (this.props.selectedUserId) {
            this.fetchUserImage();
        }
    }

    /**
     * Fetch selected user image, if there is not found, display placeholder
     */
    fetchUserImage = () => {
        try {
            getUserImage(this.props.selectedUserId).then((res) => {
                this.imgRef.current.type = "image/jpg"
                this.imgRef.current.src = res;
            }).catch(() => {
            });
        } finally {
            if (this.imgRef && this.imgRef.current) {
                this.imgRef.current.type = "image/png"
                this.imgRef.current.src = noImage;
            }
        }
    }

    /**
     * Send message to other user in chat
     *
     * @param {object} msg - the message to send
     */
    onSendMessage = (msg) => {
        const message = {
            message: msg,
            from_user_id: this.props.loggedUser.id,
            to_user_id: this.props.selectedUserId,
            dateTime: moment().valueOf()
        };
        this.props.sendMessage(message);
    }

    medicalHistoryButton = () => {
        if (!this.props.hideMedicalHistoryButton) {
            return (
                <MedicalHistoryButton title={$$('medical_history')} style={{'marginLeft': '0.625rem'}} onClick={() => {
                    let selectedUserId = this.props.childId ? this.props.childId : this.props.selectedUserId;
                    let patient = this.props.users.find(u => u.id === selectedUserId);
                    medicalHistoryHelper.prepareMedicalHistoryData(MESSAGES_PATIENT_MED_HISTORY, patient, null, Routes.HISTORY);
                    return true;
                }}/>
            )
        }
    }

    scrollSearchIntoView = (searchTerm) => {
        let element = document.getElementById(searchTerm);
        element.scrollIntoView({ behavior: 'smooth', block: 'nearest'});
    }

    goToNext = () => {
        if (this.state.selectedMessages.length > 0) {
            if (this.state.index < this.state.selectedMessages.length) {
                this.scrollSearchIntoView(this.state.selectedMessages[this.state.index].id)
                this.setState({index: this.state.index + 1})
            } else {
                this.scrollSearchIntoView(this.state.selectedMessages[0].id)
                this.setState({index: 1})
            }
        }
    }

    goToPrevious = () => {
        if (this.state.selectedMessages.length > 0) {
            if (this.state.index > 1) {
                this.scrollSearchIntoView(this.state.selectedMessages[this.state.index - 2].id)
                this.setState({index: this.state.index - 1})
            } else {
                this.scrollSearchIntoView(this.state.selectedMessages[this.state.selectedMessages.length + -1].id)
                this.setState({index: this.state.selectedMessages.length})
            }
        }
    }

    onSearchChange = (searchTerm) => {
        this.setState({
            searchTerm: searchTerm,
            index: 0
        })

        if (searchTerm !== "") {
            this.searchMessagesForTerm(searchTerm)
        } else {
            this.setState({
                selectedMessages: [],
                index: 0,
            })
        }
    }

    searchMessagesForTerm = (term) => {

        let MessagesThatContainTerm = []

        for (let i in this.props.messages) {
            if (this.props.messages[i].message?.includes(term)) {
                MessagesThatContainTerm.push(this.props.messages[i])
            }
        }

        this.setState({selectedMessages: MessagesThatContainTerm})
    }

    render() {
        if (!this.props.selectedUserId) {
            return '';
        }
        return (
            <div className={`card chat-card chat-safari `}
                 style={{"width": this.props.smallMessages || this.props.messagesSmall ? "100%" : "calc(100% - 345px)"}}>
                <div className="" style={{"background": "white"}}>
                    <div className="chat-card-title align-items-start">
                        <div className={"dropdown-link"}>
                            {this.props.smallMessages &&
                                <button className="btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm"
                                        style={{marginBottom: "0.25rem"}}
                                        onClick={() => {
                                            this.setState(this.props.deSelectUser)
                                        }}>
                                    <i className='fas fa-arrow-left centered-text'/>
                                </button>}
                            <div className='d-flex'
                                 style={{
                                     "marginLeft": "0",
                                     "marginRight": "0",
                                     "paddingRight": this.props.messagesSmall ? "0px" : "10px"
                                 }}>
                                <div className='d-flex flex-column align-items-center justify-content-between'>
                                    <img className="user-info-image patient-img"
                                         ref={this.imgRef}
                                         style={{"marginLeft": "0", "zIndex": "9001"}}
                                         alt="User chat image"/>
                                </div>
                                <div className=''
                                     style={{'marginLeft': '5px'}}>
                                    <div className={"dropdown-link"}>
                                        <div style={{'fontSize': '1.2rem'}}>{this.props.selectedUserFullName}
                                        </div>
                                        {this.props.childName && <div>({this.props.childName})</div>}
                                        <div
                                            className='lighter-font break-word'>{this.props.selectedUserEmail.includes("@") ? this.props.selectedUserEmail : ""}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="med-history-button">
                            {!this.props.hideMedicalHistoryButton && <button
                                className={this.state.showSearch ? "btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm btn-secondary" : "btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm"}
                                onClick={() => {
                                    this.setState({showSearch: !this.state.showSearch})
                                }}>
                                <i className='fas fa-search centered-text'/>
                            </button>}
                            {this.medicalHistoryButton()}
                        </div>
                    </div>
                    {this.props.hideMedicalHistoryButton && <div className="d-flex flex-row align-items-center justify-content-between mt-2">
                        {!this.state.showSearch && <BlockingOptions selectedUserId={this.props.selectedUserId}
                                                                    hideMedicalHistoryButton={this.props.hideMedicalHistoryButton} />}
                        {this.state.showSearch &&
                            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">

                                <Search handleSearchChange={this.onSearchChange}
                                        searchValue={this.state.searchTerm}
                                        placeholder={$$('search_within_selected_messages_label')}/>
                            </div>
                        }
                        <div className="text-right" style={{minWidth:"50px"}}><button
                            className={this.state.showSearch ? "btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm btn-secondary" : "btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm"}
                            onClick={() => {
                                this.setState({showSearch: !this.state.showSearch})
                            }}>
                            <i className='fas fa-search centered-text'/>
                        </button></div>
                    </div>}
                    {this.props.hideMedicalHistoryButton && this.state.searchTerm && this.state.selectedMessages.length > 0 &&
                            <div className="btn-group align-self-center align-items-center mt-1" role="group"
                                 aria-label="Basic example">
                                <button onClick={this.goToPrevious} className='btn btn-xs btn-secondary'><span
                                    className="fa fa-angle-left"/></button>
                                <div className="pl-2 pr-2 text-center" style={{minWidth:"6rem"}}>{this.state.index} {$$("of")} {this.state.selectedMessages.length}</div>
                                <button onClick={this.goToNext} className='btn btn-xs btn-secondary'><span
                                    className="fa fa-angle-right"/></button>
                            </div>}
                    {!this.props.hideMedicalHistoryButton && <div className="d-flex flex-row align-items-center justify-content-between mt-2">
                        {!this.state.showSearch && <BlockingOptions selectedUserId={this.props.selectedUserId}
                                         hideMedicalHistoryButton={this.props.hideMedicalHistoryButton} />}
                        {this.state.showSearch && <div className="w-100">
                            <div className="d-flex flex-row flex-wrap justify-content-between align-items-center w-100">

                                <Search handleSearchChange={this.onSearchChange}
                                            searchValue={this.state.searchTerm}
                                            placeholder={$$('search_within_selected_messages_label')}/>
                                {this.state.searchTerm && this.props.hideMedicalHistoryButton && this.state.selectedMessages.length > 0 &&
                                    <div className="btn-group align-self-center align-items-center mr-2 mt-2" role="group"
                                         aria-label="Basic example">
                                        <button onClick={this.goToPrevious} className='btn btn-xs btn-secondary'><span
                                            className="fa fa-angle-left"/></button>
                                        <div className="pl-2 pr-2 text-center" style={{minWidth:"6rem"}}>{this.state.index} {$$("of")} {this.state.selectedMessages.length}</div>
                                        <button onClick={this.goToNext} className='btn btn-xs btn-secondary'><span
                                            className="fa fa-angle-right"/></button>
                                    </div>}
                            </div>
                        </div>}
                        {this.state.searchTerm && <div className="d-flex align-items-center">
                            {this.state.selectedMessages.length > 0 &&
                            <div className="btn-group align-self-center align-items-center mr-2 ml-2" role="group"
                                 aria-label="Basic example">
                                <button onClick={this.goToPrevious} className='btn btn-xs btn-secondary'><span
                                    className="fa fa-angle-left"/></button>
                                <div className="pl-2 pr-2 text-center" style={{minWidth:"6rem"}}>{this.state.index} {$$("of")} {this.state.selectedMessages.length}</div>
                                <button onClick={this.goToNext} className='btn btn-xs btn-secondary'><span
                                    className="fa fa-angle-right"/></button>
                            </div>}

                        </div>}
                    </div>}
                </div>
                <div id="chat-card-safari" className="card-body chat-card-body">
                    <div className="conversation h-100" >
                        <ChatMessages messages={this.props.messages} loggedUser={this.props.loggedUser}
                                      selectedMessages={this.state.selectedMessages}/>
                    </div>
                </div>
                <ChatInput sendMessage={this.onSendMessage}
                           messagesSmall={this.props.messagesSmall}
                />
            </div>
        )
    }
}

ChatWithMessages.propTypes = {
    loggedUser: PropTypes.object,
    messages: PropTypes.array,
    smallMessages: PropTypes.bool,
    deSelectUser: PropTypes.func,
    selectedUserEmail: PropTypes.string,
    selectedUserFullName: PropTypes.string,
    selectedUserId: PropTypes.string,
    messagesSmall: PropTypes.bool,
    hideMedicalHistoryButton: PropTypes.bool,
    sendMessage: PropTypes.func,
    startVideoCall: PropTypes.func,
    unreadMessages: PropTypes.array,
    users: PropTypes.array
}

export default ChatWithMessages
