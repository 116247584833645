import {SYMPTOMS} from '../constants/symptoms';


export function getTreeViewSymptoms(language){
    let lang = getLanguage(SYMPTOMS, language);
    return SYMPTOMS[lang].All;
}

export function getLanguage(obj, language) {
    // eslint-disable-next-line no-prototype-builtins
    return obj.hasOwnProperty(language) ? language : 'en';
}

export function getSymptomName(s, nomenclature, language) {

        let symptomsTree = getTreeViewSymptoms(language)
        let nameFromTree = ""
        symptomsTree.map((symptom) => {
                if (s.toUpperCase() === symptom.type) {
                    nameFromTree = symptom.description.substring(0, symptom.description.length)
                }
            }
        )
        return nameFromTree !== ""? nameFromTree : "N/A";
}
