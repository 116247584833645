import React, {Component} from 'react'
import {$$, _$$} from '../../helpers/localization'
import CenteredModal2 from '../shared/CenteredModal2';
import {getMedInstructionsOptions, getResolvedOptions, UNIT_TYPES} from '../../constants/select_options'
import Select from '../shared/Select'
import {Form} from 'react-bootstrap'
import {
    CYCLE,
    DAILY,
    DURATION_FOR_X_DAYS,
    DURATION_ONGOING,
    EVERY_X_DAYS,
    TAKE_EVERY_X_HOURS,
    TAKE_X_TIMES,
    WHEN_NEEDED,
} from '../../constants/medications_constants';
import NotWhenNeededForms from './NotWhenNeededForms'
import PropTypes from "prop-types";
import * as classnames from "classnames";
import Search from "../shared/Search";
import {searchForMedication} from "../../actions/medications_actions";
import _ from "underscore";
import no_data from "../../resources/images/no_data.png";
import {infoUtils} from "../../utils/infoUtils";
import NoPill from "./NoPill";
import {getMedicationSource} from "../../utils/medicationUtils";
import CustomMultiselect from "../shared/CustomMultiSelect";
import {QuestionMarkTooltip} from "../common/tooltips";


export class PrescriptionForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showProccessingModal: false,
            name: this.props.selectedEntry && this.props.selectedEntry.name ? this.props.selectedEntry.name : '',
            notes: this.props.selectedEntry && this.props.selectedEntry.notes ? this.props.selectedEntry.notes : "",
            formclass: "",
            errors: {},
            createTemplate: false,
            id: this.props.selectedEntry && this.props.selectedEntry.id ? this.props.selectedEntry.id : null,
            unit: this.props.selectedEntry && this.props.selectedEntry.unit ? this.props.selectedEntry.unit : 'TABLET',
            dose: this.props.selectedEntry && this.props.selectedEntry.dose ? this.props.selectedEntry.dose : 1,
            scheduleType: this.props.selectedEntry && this.props.selectedEntry.scheduleType ? this.props.selectedEntry.scheduleType : WHEN_NEEDED,
            durationType: this.props.selectedEntry && this.props.selectedEntry.durationType ? this.props.selectedEntry.durationType : DURATION_ONGOING,
            howToTake: this.props.selectedEntry && this.props.selectedEntry.howToTake ? this.props.selectedEntry.howToTake : 'TAKE_X_TIMES',
            scheduleTimesPerDay: this.props.selectedEntry && this.props.selectedEntry.scheduleTimesPerDay ? this.props.selectedEntry.scheduleTimesPerDay : 1,
            scheduleDaysInactive: this.props.selectedEntry && this.props.selectedEntry.scheduleDaysInactive ?
                (this.props.selectedEntry.scheduleType === EVERY_X_DAYS ? this.props.selectedEntry.scheduleDaysInactive + 1 : this.props.selectedEntry.scheduleDaysInactive) : 2,
            scheduleDaysActive: this.props.selectedEntry && this.props.selectedEntry.scheduleDaysActive ? this.props.selectedEntry.scheduleDaysActive : 21,
            durationInDays: this.props.selectedEntry && this.props.selectedEntry.durationInDays ? this.props.selectedEntry.durationInDays : 5,
            scheduleHours: this.props.selectedEntry && this.props.selectedEntry.scheduleHours ? this.props.selectedEntry.scheduleHours : 8,
            instructions: this.props.selectedEntry && this.props.selectedEntry.instructions ? this.props.selectedEntry.instructions : "",
            quantity: this.props.selectedEntry ? this.props.selectedEntry.quantity : 1,
            medication_form: this.getMedicationFormInitialValue(this.props.selectedEntry),
            quantity_by_form: this.props.selectedEntry && this.props.selectedEntry.quantity_by_form ? this.props.selectedEntry.quantity_by_form : false,
            template_name: '',
            loadingComplete: false,
            showMedSearch: false,
            medSearchValue: "",
            formClass: "",
            medicationSearchResults: []
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.onSaveAddUpdateModal = this.onSaveAddUpdateModal.bind(this)
    }

    getMedicationFormInitialValue(object) {
        if (object) {
            return object.medication_form ? object.medication_form : $$(object.quantity > 1 ? "packs" : "pack")
        }
        return $$("pack");
    }


    componentDidMount() {
        this.setState({
            pillShape: 9001
        }, () => {
            let s = this.fromTemplate();
            if (s) {
                this.setState(s)
            } else {
                this.setStandardState();
            }
        })
    }


    setStandardState = () => {
        let quantity = this.props.selectedEntry ? this.props.selectedEntry.quantity : 1;
        this.setState({
            name: this.props.selectedEntry && this.props.selectedEntry.name ? this.props.selectedEntry.name : '',
            notes: this.props.selectedEntry && this.props.selectedEntry.notes ? this.props.selectedEntry.notes : "",
            formclass: "",
            errors: {},
            id: this.props.selectedEntry && this.props.selectedEntry.id ? this.props.selectedEntry.id : null,
            unit: this.props.selectedEntry && this.props.selectedEntry.unit ? this.props.selectedEntry.unit : 'TABLET',
            dose: this.props.selectedEntry && this.props.selectedEntry.dose ? this.props.selectedEntry.dose : 1,
            scheduleType: this.props.selectedEntry && this.props.selectedEntry.scheduleType ? this.props.selectedEntry.scheduleType : WHEN_NEEDED,
            durationType: this.props.selectedEntry && this.props.selectedEntry.durationType ? this.props.selectedEntry.durationType : DURATION_ONGOING,
            howToTake: this.props.selectedEntry && this.props.selectedEntry.howToTake ? this.props.selectedEntry.howToTake : 'TAKE_X_TIMES',
            scheduleTimesPerDay: this.props.selectedEntry && this.props.selectedEntry.scheduleTimesPerDay ? this.props.selectedEntry.scheduleTimesPerDay : 1,
            scheduleDaysInactive: this.props.selectedEntry && this.props.selectedEntry.scheduleDaysInactive ?
                (this.props.selectedEntry.scheduleType === EVERY_X_DAYS ? this.props.selectedEntry.scheduleDaysInactive + 1 : this.props.selectedEntry.scheduleDaysInactive) : 2,
            scheduleDaysActive: this.props.selectedEntry && this.props.selectedEntry.scheduleDaysActive ? this.props.selectedEntry.scheduleDaysActive : 21,
            durationInDays: this.props.selectedEntry && this.props.selectedEntry.durationInDays ? this.props.selectedEntry.durationInDays : 5,
            scheduleHours: this.props.selectedEntry && this.props.selectedEntry.scheduleHours ? this.props.selectedEntry.scheduleHours : 8,
            instructions: this.props.selectedEntry && this.props.selectedEntry.instructions ? this.props.selectedEntry.instructions : "",
            quantity: quantity,
            medication_form: this.getMedicationFormInitialValue(this.props.selectedEntry),
            quantity_by_form: this.props.selectedEntry && this.props.selectedEntry.quantity_by_form ? this.props.selectedEntry.quantity_by_form : false,
            template_name: '',
            createTemplate: false
        });
    }

    fromTemplate = () => {
        if (this.props.template) {
            return {
                name: this.props.template.name,
                notes: this.props.template.notes,
                id: null,
                unit: this.props.template.unit,
                dose: this.props.template.dose,
                scheduleType: this.props.template.scheduleType,
                durationType: this.props.template.durationType,
                howToTake: this.props.template.howToTake,
                scheduleTimesPerDay: this.props.template.scheduleTimesPerDay,
                scheduleDaysInactive: this.props.template.scheduleType === EVERY_X_DAYS ? this.props.template.scheduleDaysInactive + 1 : this.props.template.scheduleDaysInactive,
                scheduleDaysActive: this.props.template.scheduleDaysActive,
                durationInDays: this.props.template.durationInDays,
                scheduleHours: this.props.template.scheduleHours,
                instructions: this.props.template.instructions,
                quantity: this.props.template.quantity,
                quantity_by_form: this.props.template.quantity_by_form,
                medication_form: this.getMedicationFormInitialValue(this.props.template)
            }
        }
    }

    /**
     * On component update change the view if selected entry has changed
     */
    componentDidUpdate = () => {
        if (this.props.selectedEntry && this.state.id !== this.props.selectedEntry.id) {
            this.setStandardState();
        }
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        if (evt.target) {
            fields[evt.target.name] = evt.target.value;
        } else {
            fields[evt.name] = evt.value;
        }
        this.setState(fields, () => {
            this.validate()
        });
    }

    /**
     * Handle quantity input change
     *
     * @param {object} evt - The event handler argument
     */
    onQuantityInputChange = (evt) => {
        let quantity = evt.target.value;
        let state = {
            quantity: quantity
        };

        if (!this.state.quantity_by_form) {
            state.medication_form = quantity > 1 ? $$("packs") : $$("pack")
        }
        this.setState(state, () => {
            this.validate()
        });
    }

    /**
     * Sets the value when the schedule type is selected
     * @param {event} e
     */
    onScheduleTypeChange = (e) => {
        if (e.value === WHEN_NEEDED) {
            this.setState({
                scheduleType: e.value,
            }, () => {
                this.validate()
            })
        } else {
            this.setState({
                scheduleType: e.value,
            }, () => {
                this.validate()
            })
        }
    }

    /**
     * This sets the state based on the object it comes from <HowToTake />
     * @param {*} obj - it can be an object created based on which input changed : the one determining X times a day , the one determining every X hours , or the howToTake select element
     */
    onChange = (obj) => {
        this.setState({
            howToTake: obj.howToTake ? obj.howToTake : this.state.howToTake,
            scheduleHours: obj.scheduleHours,  //default value,
            scheduleTimesPerDay: obj.scheduleTimesPerDay, //clean the other input since its not in use
        }, () => {
            this.validate()
        })
    }

    /**
     * Hide the modal
     */
    onHideAddUpdateModal = () => {
        this.setState({
            name: '',
            notes: '',
            formclass: "",
            errors: {},
            unit: 'TABLET',
            dose: 1,
            scheduleType: WHEN_NEEDED,
            durationType: DURATION_ONGOING,
            howToTake: '',
            scheduleTimesPerDay: 1,
            scheduleDaysInactive: 2,
            scheduleDaysActive: 21,
            durationInDays: 5,
            scheduleHours: 8,
            instructions: "",
            quantity: 1,
            quantity_by_form: false,
            medication_form: $$("pack"),
            id: undefined,
            template_name: '',
            temphide: false,
            createTemplate: false
        });
        this.props.onHide();
    }

    validate() {
        const errors = {};
        if (!this.state.name) {
            errors.name = 'health_issue_title_required_message';
        }
        if (this.state.createTemplate && !this.state.template_name.trim()) {
            errors.name = 'template_name_required';
        }
        if (!(this.state.dose > 0)) {
            errors.dose = 'dose_is_mandatory';
            if (this.doseField) {
                this.doseField.setCustomValidity("Invalid");
                this.doseField.checkValidity();
            }
        } else {
            if (this.doseField) {
                this.doseField.setCustomValidity("");
                this.doseField.checkValidity();
            }
        }

        if (this.state.quantity_by_form) {
            if (!this.state.medication_form) {
                errors.medication_form = "quantity_by_form_is_mandatory";
            }
        }

        if (this.state.scheduleType === EVERY_X_DAYS) {
            if (!(this.state.scheduleDaysInactive > 1)) {
                errors.scheduleDaysInactive = $$("invalid_value");
            }
        }

        if (this.state.scheduleType === CYCLE) {
            if (!(this.state.scheduleDaysInactive > 0)) {
                errors.scheduleDaysInactive = $$("invalid_value");
            }
            if (!(this.state.scheduleDaysActive > 0)) {
                errors.scheduleDaysActive = $$("invalid_value");
            }
        }

        if (this.state.durationType === DURATION_FOR_X_DAYS) {
            if (!(this.state.durationInDays > 0)) {
                errors.durationInDays = $$("invalid_value");
            }
        }

        if (this.state.howToTake === TAKE_X_TIMES) {
            if (!(this.state.scheduleTimesPerDay > 0)) {
                errors.scheduleTimesPerDay = $$("invalid_value");
            }
        }

        if (this.state.howToTake === TAKE_EVERY_X_HOURS) {
            if (!(this.state.scheduleHours > 0)) {
                errors.scheduleHours = $$("invalid_value");
            }
        }
        this.setState({errors})
        return errors;
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validateFormData = () => {
        let errors = this.validate();
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length === 0;
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call create health issue entry action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
    }

    /**
     * Handle form creation or update based on the selected entry
     * @param {Object} form - request object to be sent to the server
     */
    handleCreateOrUpdate = async (form) => {
        if (!this.props.selectedEntry) {
            this.props.createPrescription(form);
        } else {
            this.props.updatePrescription(form);
        }
        this.onHideAddUpdateModal();
    }

    /**
     * Create new entry or update existing entry from list
     */
    onSaveAddUpdateModal = async () => {
        if (this.validateFormData()) {
            let data = {};

            if (this.props.selectedEntry) {
                data = {...this.props.selectedEntry}
            }

            let form = {
                patient_id: this.props.selectedUser.id,
                provider_id: this.props.provider_id,
                encounter_id: this.props.encounter_id,
                notes: this.state.notes,
                deleted: false,
                name: this.state.name,
                unit: this.state.unit,
                dose: this.state.dose,
                scheduleType: this.state.scheduleType,
                durationType: this.state.durationType,
                durationInDays: this.state.durationInDays,
                howToTake: this.state.howToTake,
                scheduleTimesPerDay: this.state.scheduleTimesPerDay,
                scheduleHours: this.state.scheduleHours,
                scheduleDaysActive: this.state.scheduleDaysActive,
                scheduleDaysInactive: this.state.scheduleDaysInactive,
                instructions: this.state.instructions,
                quantity: this.state.quantity,
                quantity_by_form: this.state.quantity_by_form,
                medication_form: this.state.medication_form,
                template_name: this.state.template_name
            };

            if (!form.quantity_by_form) {
                form.medication_form = "";
            }

            if (form.scheduleType === EVERY_X_DAYS) {
                form.scheduleDaysInactive = Number(form.scheduleDaysInactive) - 1;
                form.scheduleDaysActive = 1;
            }

            data = {...data, ...form};
            await this.handleCreateOrUpdate(data)
        }
    }

    /**
     * Get the formatted medication duration string
     *
     * @returns {string} medication plan duration
     */
    getMedicationDurationString = () => {
        const durationTypes = [];
        durationTypes.push({
            text: _$$('ongoing'),
            value: DURATION_ONGOING
        })
        durationTypes.push({
            text: _$$('for_x_days_until'),
            value: DURATION_FOR_X_DAYS
        })
        return durationTypes;
    }


    /**
     * Get the formatted medication schedule string
     *
     * @returns {[{text: string, value: string}]} medication plan schedule
     */
    getMedicationScheduleString = () => {
        const scheduleTypes = [{text: $$('when_needed'), value: WHEN_NEEDED}];
        scheduleTypes.push({text: $$('daily'), value: DAILY})
        scheduleTypes.push({text: _$$('every_x_days'), value: EVERY_X_DAYS})
        scheduleTypes.push({text: _$$('take_x_rest_x'), value: CYCLE})

        return scheduleTypes;
    }

    /**
     * Get the formatted medication frequency string
     *
     * @returns {string} medication plan frequency
     */
    getMedicationFrequencyString = () => {
        const howToTakes = [{text: _$$('frequency_times_day'), value: 'TAKE_X_TIMES'}];
        howToTakes.push({text: _$$('frequency_every_hours'), value: 'TAKE_EVERY_X_HOURS'});
        return howToTakes;

    }
    setMedicationFromSearch = (medication) => {
        this.setState({
            name: medication.name + (medication.strength && medication.strength.trim() !== "" ? ` ${medication.strength}` : ""),
            unit: medication.unit,
            showMedSearch: false,
            medicationSearchResults: [],
            medSearchValue: ""
        })
    }

    medSearchResults = () => {
        let results = []
        for (let i in this.state.medicationSearchResults) {
            results.push(<div onClick={() => {
                this.setMedicationFromSearch(this.state.medicationSearchResults[i])
            }} key={i} className={"med-search-result"}>
                <NoPill/>
                <span className={"medication-result"} onClick={() => {
                    this.setMedicationFromSearch(this.state.medicationSearchResults[i])
                }} key={i}> {this.state.medicationSearchResults[i].name}
                    <br/> {this.state.medicationSearchResults[i].form ?? this.state.medicationSearchResults[i].form} {this.state.medicationSearchResults[i].strength}
                    <br/>
                    <span
                        className={this.state.medicationSearchResults[i].needsPrescription ? "text-danger" : "text-success"}>{this.state.medicationSearchResults[i].needsPrescription ? $$('needs_prescription') : $$('doesnt_need_prescription')} </span>
                </span>
            </div>)
        }
        return results;
    }

    searchForMedication = async (searchTerm) => {
        let res = await searchForMedication({
            searchText: searchTerm, source: getMedicationSource(this.props.selectedUser.country, this.props.i18n.lang
            )
        })
        this.setState({
            loadingComplete: true, medicationSearchResults: res
        })
    }

    searchForMedications = async (searchTerm) => {
        this.setState({medSearchValue: searchTerm})
        if (searchTerm.length > 2) {
            this.setState({showMedSearch: true, loadingComplete: false, medicationSearchResults: []})
            _.throttle(this.searchForMedication(searchTerm), 100);
        } else {
            this.setState({showMedSearch: false, loadingComplete: false, medicationSearchResults: []})
        }
    }

    onSelectInstruction = (v) => {
        let instructions = this.state.instructions ? this.state.instructions.split(",") : [];
        instructions.push(v);
        this.setState({instructions: instructions.join(",")})
    };

    onRemoveInstruction = (v) => {
        let instructions = this.state.instructions ? this.state.instructions.split(",") : [];
        instructions.splice(instructions.indexOf(v), 1);
        this.setState({instructions: instructions.join(",")})
    };

    render() {

        let noMedications = {
            imgClass: 'no-entry-image-landing',
            objClass: 'no-data-object-landing',
            primaryLabelClass: 'no-medications-landing-label',
            src: no_data,
            primaryLabel: $$('no_results_found'),
            nobreak: true
        }
        return (
            <>
                <CenteredModal2
                    header={this.props.selectedEntry ? $$('update_prescription') : $$('add_prescription')}
                    dialogClassName='prescription-modal'
                    show={this.props.show}
                    onHide={this.state.showMedSearch ? () => {
                        this.setState({showMedSearch: false, loadingComplete: false, medicationSearchResults: []})
                    } : this.onHideAddUpdateModal}
                    confirmBtnLabel={$$('save_btn_label')}
                    cancelBtnLabel={$$('cancel_btn')}
                    onConfirm={this.onSaveAddUpdateModal}
                    headerClassName="prescription-modal-title h4"
                >

                    <div className="d-flex form-group flex-space-between">
                        <Search
                            handleSearchChange={this.searchForMedications}
                            searchValue={this.state.medSearchValue}
                            externalSearchValue={true}
                            placeholder={$$('search_for_medications')}
                        />
                    </div>

                    {this.state.showMedSearch && <>
                        <div className={"med-search-title mb-3"}>
                            {$$("medications_found")}
                            <a style={{paddingTop: "5px"}} className={"la la-close"} onClick={() => {
                                this.setState({
                                    showMedSearch: false,
                                    medicationSearchResults: []
                                })
                            }}/>
                        </div>

                        {!this.state.loadingComplete && <>
                            <div className="med-search-loader"/>
                            <br/></>}

                        {this.medSearchResults()}
                        {this.state.medicationSearchResults.length === 0 && this.state.loadingComplete && infoUtils.noData(noMedications)}
                    </>}

                    {!this.state.showMedSearch && <>
                        <form id="documentForm" onSubmit={this.onSubmit} className={this.state.formclass}>
                            <div className="mt-3">
                                <div className="row form-group prescription-form-label">
                                    <div className="col-sm-6">
                                        {<label>{$$("medication_name_label")}</label>}
                                        <input type="text" className="form-control" value={this.state.name}
                                               placeholder={$$('medication_name_label')} name="name"
                                               onChange={this.onInputChange} required/>
                                    </div>
                                </div>
                                <div className="d-flex form-group prescription-form-label flex-space-between">
                                    <div>
                                        <label>{$$('dose_label')}</label>
                                        <div className="d-flex dose-unit-med-form">
                                            <div className="dose-med-form"><input className="form-control" type='text'
                                                                                  name="dose"
                                                                                  value={this.state.dose}
                                                                                  ref={(ref) => {
                                                                                      this.doseField = ref
                                                                                  }}
                                                                                  onChange={this.onInputChange}/>
                                            </div>
                                            <div className="ml-3">
                                                <Select
                                                    name="unit"
                                                    options={getResolvedOptions(UNIT_TYPES.TYPE)}
                                                    value={this.state.unit}
                                                    onChange={this.onInputChange}
                                                    placeHolder={$$('unit_label')}
                                                    hasNoLabel={true}
                                                    style={{
                                                        'minWidth': '100%',
                                                        'maxWidth': '100%',
                                                        'zIndex': '1',
                                                        'opacity': '70%'
                                                    }}/>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="form-group prescription-form-label">
                                    <Select
                                        label={$$("schedule")}
                                        name="scheduleType"
                                        options={this.getMedicationScheduleString()}
                                        value={this.state.scheduleType}
                                        onChange={this.onScheduleTypeChange}
                                        placeHolder={$$('schedule')}
                                        style={{
                                            'minWidth': '100%',
                                            'maxWidth': '100%',
                                            'zIndex': '1',
                                            'opacity': '70%'
                                        }}/>
                                </div>
                                {this.state.scheduleType && this.state.scheduleType !== WHEN_NEEDED &&
                                    <NotWhenNeededForms scheduleType={this.state.scheduleType}
                                                        scheduleDaysInactive={this.state.scheduleDaysInactive}
                                                        onInputChange={this.onInputChange}
                                                        scheduleDaysActive={this.state.scheduleDaysActive}
                                                        getMedicationFrequencyString={this.getMedicationFrequencyString}
                                                        howToTake={this.state.howToTake}
                                                        scheduleTimesPerDay={this.state.scheduleTimesPerDay}
                                                        onChange={this.onChange}
                                                        scheduleHours={this.state.scheduleHours}
                                                        getMedicationDurationString={this.getMedicationDurationString}
                                                        durationType={this.state.durationType}
                                                        durationInDays={this.state.durationInDays}
                                                        errors={this.state.errors}
                                    />}


                                <div className="d-flex align-items-end">
                                    <div className="form-group prescription-form-label flex-1">
                                        {this.state.quantity !== "" && <label>{$$('quantity')}</label>}
                                        <input type="text" className="form-control" value={this.state.quantity}
                                               placeholder={$$('quantity')} name="quantity"
                                               onChange={this.onQuantityInputChange}/>
                                    </div>
                                    <div className="form-group prescription-form-label ml-2">
                                        {this.state.medication_form &&
                                            <label>{!this.state.quantity_by_form ? '' : $$('medication_form')}</label>}
                                        <input type="text" className="form-control" value={this.state.medication_form}
                                               readOnly={!this.state.quantity_by_form}
                                               placeholder={$$('medication_form')} name="medication_form"
                                               onChange={this.onInputChange} required={this.state.quantity_by_form}/>
                                    </div>
                                    <div className="form-group prescription-form-label pb-2">
                                        <Form.Check
                                            className={'custom-control-inline flex-grow-1 flex-shrink-1 ml-2'}
                                            id={'quantity_by_form'}
                                            checked={this.state.quantity_by_form}
                                            value={this.state.quantity_by_form}
                                            type={"checkbox"}
                                            custom
                                            label={$$("quantity_by_form")}
                                            onChange={() => {
                                                this.setState({
                                                    quantity_by_form: !this.state.quantity_by_form,
                                                    medication_form: !this.state.quantity_by_form ? "" : (this.state.quantity > 1 ? $$("packs") : $$("pack"))
                                                })
                                            }}/>
                                    </div>
                                </div>

                                <input type='text' className='form-control'
                                       value={this.state.instructions} name='instructions'
                                       readOnly
                                       hidden/>

                                <div className="form-group prescription-form-label">
                                    {this.state.instructions && <label>{$$('instructions')}</label>}
                                    <CustomMultiselect options={getMedInstructionsOptions()}
                                                       selectedValues={this.state.instructions ? this.state.instructions.split(",") : []}
                                                       onSelect={this.onSelectInstruction}
                                                       onRemove={this.onRemoveInstruction}
                                                       displayValue="text"
                                                       placeholder={$$('instructions')}
                                                       closeIcon='cancel'
                                                       avoidHighlightFirstOption={true}
                                                       isFieldValid={true}
                                                       formClass={""}/>
                                </div>

                                <div className="form-group prescription-form-label">
                                    {this.state.notes && <label>{$$('notes')}</label>}
                                    <textarea className="form-control" value={this.state.notes || ''}
                                              placeholder={$$('notes')} name="notes" onChange={this.onInputChange}/>
                                </div>

                                <div className="d-flex align-items-center flex-wrap">
                                    <div style={{justifyContent: "start"}}>
                                        <Form.Check
                                            className={'custom-control-inline flex-grow-1 flex-shrink-1'}
                                            id={'create-template-check'}
                                            checked={this.state.createTemplate}
                                            value={this.state.createTemplate}
                                            type={"checkbox"}
                                            custom
                                            label={$$("create_template")}
                                            onChange={() => {
                                                this.setState({
                                                    createTemplate: !this.state.createTemplate,
                                                    template_name: this.state.createTemplate ? '' : this.state.template_name
                                                })
                                            }}/>
                                        <QuestionMarkTooltip>
                                            {$$('template_info_text')}
                                        </QuestionMarkTooltip>
                                    </div>
                                    <input required
                                           className={classnames("form-control custom-control-inline flex-shrink-0", {"invisible": !this.state.createTemplate})}
                                           style={{flexBasis: "180px", flexGrow: "4"}}
                                           type={"text"} name="template_name"
                                           value={this.state.template_name}
                                           placeholder={$$("template_name_label")}
                                           pattern=".*\S+.*" title={$$("template_name_label")} onChange={(e) => {
                                        this.setState({template_name: e.target.value})
                                    }}/>
                                </div>
                            </div>
                        </form>
                    </>}
                </CenteredModal2>
            </>
        )
    }
}

PrescriptionForm.propTypes = {
    i18n: PropTypes.object,
    template: PropTypes.any,
    encounter_id: PropTypes.any,
    provider_id: PropTypes.any,
    selectedEntry: PropTypes.object,
    onHide: PropTypes.func,
    createPrescription: PropTypes.func,
    updatePrescription: PropTypes.func,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    show: PropTypes.bool
}

export default PrescriptionForm