import React, {Component} from 'react'
import {SpeechButton} from '../notes/SpeechButton';
import {PUNCTUATION} from '../../constants/select_options'
import {$$} from '../../helpers/localization';
import PropTypes from "prop-types";


export class EditFormHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notes: '',
            recording: false,
            edited: false,
            lang: this.getLanguage(),
            supported: false,
        };
    }

    componentDidMount() {
        this.dictate();
    }

    dictate = () => {
        this.notes = React.createRef();
        this.recognition = {};

        if (window.SpeechRecognition) {
            // eslint-disable-next-line no-undef
            this.recognition = new SpeechRecognition();
            this.setState({supported: true})
        } else if (window.webkitSpeechRecognition) {
            // eslint-disable-next-line no-undef
            this.recognition = new webkitSpeechRecognition();
            this.setState({supported: true})
        }
        this.recognition.continuous = true;
        this.recognition.interimResults = true;

        this.recognition.onresult = function (event) {
            if (event.results.length > 0) {
                let text = "";
                for (let i = 0; i < event.results.length; i++) {
                    text += event.results[i][0].transcript
                }
                let theText = this.props.activeInputText + " " + text;
                PUNCTUATION.WORDS.map(w => {
                    theText = theText.replaceAll(w.text, w.value)
                })
                this.props.onNoteSpeechChange(theText);
            }
        }.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.state.recording) {
            if (prevProps.activeInput !== this.props.activeInput) {
                this.recognition.abort();
                this.dictate();
                this.triggerTheDictation();
            }
            if (prevProps.activeInput !== this.props.activeInput && this.props.activeInput === undefined) {
                this.recognition.abort();
            }
        }
    }

    triggerTheDictation = () => {
        this.recognition.start()
    }

    getLanguage() {
        return this.props.i18n.lang + "-" + this.props.i18n.key.toUpperCase();
    }

    previousAppointmentHasData = () => {
        return this.props.previousCompletedAppointment !== "";
    }


    render() {
        return (
            <div>
                <div className={"row mb-2 mt-1"} style={{justifyContent: "space-between", alignItems: "center"}}>
                    <div>
                        <SpeechButton title={this.state.recording ? $$('stop_dictating') : $$('dictate')}
                                      recording={this.state.recording}
                                      onClick={() => {
                                          if (this.state.supported) {
                                              if (this.state.recording) {
                                                  this.recognition.abort();
                                                  this.props.onRecognitionStop();
                                              } else {
                                                  this.recognition.lang = this.getLanguage();
                                                  this.recognition.start();
                                                  this.props.onRecognitionStart();
                                              }
                                              this.setState({recording: !this.state.recording});
                                          }
                                      }}/>
                        <span
                            className="med-history-title-name">{this.state.recording ? $$('stop_dictating') : $$('dictate')}</span>
                    </div>
                    <div className={"mt-1"}>
                        <button type="button" onClick={this.props.onClearClick}
                                className="btn btn-secondary mr-1 mb-2">{$$('clear_form')}</button>
                        {this.previousAppointmentHasData() &&
                            <button type="button" onClick={this.props.onCopyClick}
                                    className="btn btn-secondary mr-1 mb-2">{$$('copy_from_last')}</button>}
                    </div>

                    <div className={"mt-1 mb-2"}>

                        <button type="button"
                                className="btn btn-primary ml-2"
                                onClick={this.props.saveAndShowComplete}>
                            {$$("save_btn_label")}
                        </button>

                        {(this.props.encounter || this.props.isNhis) &&
                            <button type="button"
                                    className="btn btn-secondary ml-2"
                                    onClick={this.props.onCancel}>
                                {$$("cancel_btn")}
                            </button>}

                    </div>


                </div>
            </div>
        )
    }
}

EditFormHeader.propTypes = {
    activeInput: PropTypes.string,
    activeInputText: PropTypes.string,
    i18n: PropTypes.object,
    onInputChange: PropTypes.func,
    onClearClick: PropTypes.func,
    previousCompletedAppointment: PropTypes.any,
    onCopyClick: PropTypes.func,
    onNoteSpeechChange: PropTypes.func,
    onRecognitionStart: PropTypes.func,
    onRecognitionStop: PropTypes.func
}

export default EditFormHeader