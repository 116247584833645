import React, {Component} from 'react';
import {$$} from '../../helpers/localization'
import SymptomEntry from './SymptomEntry';
import PropTypes from "prop-types";


export class Symptoms extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
    }

    /**
     * Get symptoms entries
     *
     * @returns {Array} of symptoms entries
     */
    getSymptomsEntries = () => {
        let symptomEntries = [];

        if (this.props.symptoms && this.props.symptoms.length > 0) {
            this.props.symptoms.map(s => {
                return s.symptoms.map(symptom => {
                    symptomEntries.push(
                        <SymptomEntry key={++this.index}
                                      entry={symptom}
                                      datetime={s.date_time}
                                      nomenclature={this.props.nomenclature}
                                      i18n={this.props.i18n}/>
                    );
                });
            });
        }

        return symptomEntries;
    }

    render() {
        return (
            <div>
                <div key={++this.index} className='row title-row'>
                    <div className='symptom-icon align-items-center'>
                        <i className='kt-menu__link-icon vitals-logbook-icon-label far fa-clock'/>
                    </div>
                    &nbsp;
                    <div className='medrec-blue-1 vitals-logbook-icon-label dropdown-link'>
                        {$$('Symptoms')}
                    </div>
                </div>
                <br/>
                <div className='vitals-logbook-entry-padding vitals-logbook-entry-margin'>
                    {this.getSymptomsEntries()}
                </div>
            </div>
        )
    }
}

Symptoms.propTypes = {
    datetime: PropTypes.number,
    entry: PropTypes.object,
    symptoms: PropTypes.any,
    i18n: PropTypes.object,
    nomenclature: PropTypes.object
}

export default Symptoms