import React, {Component}  from 'react'
import {connect} from "react-redux";
import {
    fetchProviderChatSettings,
    updateProviderChatSettings
} from "../../actions/settings_actions";
import {$$} from "../../helpers/localization";
import FormWithLoading from "../shared/FormWithLoading";
import {CHAT_SETTINGS} from "../../actions/actions";
import classnames from "classnames";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enGB from "date-fns/locale/en-GB";
import bg from "date-fns/locale/bg";
import sq from "date-fns/locale/sq";
import moment from "moment";
import {QuestionMarkTooltip} from "../common/tooltips";

const daysOfTheWeekKeys = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

class ChatSettingsForm extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            formclass: '',
            daysCheckboxes: daysOfTheWeekKeys.reduce(
                (options, option) => ({
                    ...options,
                    [option]: false
                }),
                {}
            ),
            errors: {}
        };
    }

    resetChatSettings = () => {
        this.setState({
            ...this.props.chat_settings,
            formclass: '',
            errors: {},
            successfulUpdate:false
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.chat_settings !== this.props.chat_settings && this.props.chat_settings_result.success) {
            let state = {...this.props.chat_settings};
            state.daysCheckboxes = {
                    ["mon"]: this.props.chat_settings.days.includes(1),
                    ["tue"]: this.props.chat_settings.days.includes(2),
                    ["wed"]: this.props.chat_settings.days.includes(3),
                    ["thu"]: this.props.chat_settings.days.includes(4),
                    ["fri"]: this.props.chat_settings.days.includes(5),
                    ["sat"]: this.props.chat_settings.days.includes(6),
                    ["sun"]: this.props.chat_settings.days.includes(7),
            }
            this.setState(state)
        }

        if (prevProps.chat_settings.server_updated_timestamp < this.props.chat_settings.server_updated_timestamp) {
            this.setState({successfulUpdate: true});
        }
    }

    componentDidMount() {
        this.props.fetchProviderChatSettings();
    }

    isCheckedSchedule = () => {
        return this.state.state === 'SCHEDULE';
    }

    getLocale = () => {
        switch(this.props.i18n.lang) {
            case "en":
                return enGB
            case "bg":
                return bg
            case "sq":
                return sq
            default:
                return enGB
        }
    }

    onStartTimeChange = (time) => {
        this.setState({starts:moment(time).format("HH:mm:00")})
    }

    onEndTimeChange = (time) => {
        this.setState({ends:moment(time).format("HH:mm:00")})
    }

    timeToDate = (time) => {
        const [hours, minutes] = time.split(":");
        let date = new Date();
        date.setHours(hours, minutes, 59);
        return date;
    }

    onCheckboxChanged = (evt) => {
        this.setState({state:evt.target.value})
    }

    createDaysCheckboxes = () => daysOfTheWeekKeys.map(this.createDaysCheckbox);

    onLabelClick = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.htmlFor] = !fields[evt.target.htmlFor];
        this.setState(fields);
    }

    createDaysCheckbox = option => {
        let checked = this.state.daysCheckboxes[option];
        return <div className="form-check" key={option}>
            <input className="form-check-input"
                   type="checkbox"
                   name={option}
                   id={option}
                   checked={checked}
                   onChange={this.handleDaysCheckboxChange}/>
            <label className="form-check-label" htmlFor={option}>{$$(option)}</label>
        </div>
    };

    handleDaysCheckboxChange = changeEvent => {
        const {name} = changeEvent.target;
        this.setState(prevState => ({
            daysCheckboxes: {
                ...prevState.daysCheckboxes,
                [name]: !prevState.daysCheckboxes[name]
            }
        }));
    };

    onSubmit = (evt) => {
        evt.preventDefault();
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        if (evt.target.checkValidity() === true) {
            const {formclass, daysCheckboxes, errors, successfulUpdate, ...req} = this.state;
            let days = [];
            for (let i = 0; i < daysOfTheWeekKeys.length; i++) {
                const day = daysOfTheWeekKeys[i];
                if (this.state.daysCheckboxes[day]) {
                    days.push(i+1);
                }
            }
            req.days = days;
            req.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            this.props.updateProviderChatSettings(req);
            this.setState({formclass: ""});
        }
    }

    render() {
        let alert = '';
        if (this.state.successfulUpdate) {
            alert = (
                <div className="alert alert-success">
                    {$$('settings_updated_message')}
                </div>
            );
        }
        return (
            <div className="centered-form">
                <h2 className="text-center card-title">{$$("chat_configuration")}</h2>
                <div>
                    {alert}
                    <FormWithLoading
                        formDisabled={this.props.formDisabled}
                        currentForm={CHAT_SETTINGS}
                        marginTop="30%"
                        marginLeft="20%"
                        onSubmit={(evt) => this.onSubmit(evt)}
                        className={classnames(this.state.formclass)}
                        noValidate={true}>
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" name="state2" id="AUTO"
                                   checked={this.state.state == "AUTO"}
                                   onChange={this.onCheckboxChanged}
                                   value={'AUTO'}
                            />
                            <label className="form-check-label" htmlFor="AUTO" >
                                {$$('auto_chat_input_label')}
                                &nbsp;
                                <QuestionMarkTooltip>
                                    {$$('auto_chat_info_text')}
                                </QuestionMarkTooltip>
                            </label>
                        </div>
                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" name="state1" id="DISABLED"
                                   checked={this.state.state == "DISABLED"}
                                   onChange={this.onCheckboxChanged}
                                   value={'DISABLED'}
                            />
                            <label className={classnames("form-check-label", {"text-danger":this.state.state == "DISABLED"})} htmlFor="DISABLED" >
                                {$$('lock_chat_input_label')}
                                &nbsp;
                                <QuestionMarkTooltip>
                                    {$$('lock_chat_info_text')}
                                </QuestionMarkTooltip>
                            </label>
                        </div>

                        <div className="form-check mb-3">
                            <input className="form-check-input" type="checkbox" name="state3" id="SCHEDULE"
                                   checked={this.state.state == "SCHEDULE"}
                                   onChange={this.onCheckboxChanged}
                                   value={'SCHEDULE'}
                            />
                            <label className="form-check-label" htmlFor="SCHEDULE" >
                                {$$('schedule_chat_input_label')}
                                &nbsp;
                                <QuestionMarkTooltip>
                                    {$$('schedule_unlock_info_text')}
                                </QuestionMarkTooltip>
                            </label>
                        </div>
                        <div className={"mb-2"}>
                            {/*<h5 className="mb-2">{$$("end_time_for_appointment_booking")}</h5>*/}
                            {this.isCheckedSchedule() && <div>
                                <div className="d-flex justify-content-between pl-3 pr-3">
                                    {this.createDaysCheckboxes()}
                                </div>
                                <div className={""}>
                                    <div className='time-picker-configuration-form'>
                                        <MuiPickersUtilsProvider locale={this.getLocale()}
                                                                 utils={DateFnsUtils}>
                                            <div className="d-flex space-between-justify">
                                            <div className="p-3">
                                                <KeyboardTimePicker
                                                    ampm={!this.props.settings.time24hour}
                                                    margin="normal"
                                                    id="time-picker"
                                                    okLabel={$$("OK")}
                                                    cancelLabel={$$("cancel_btn")}
                                                    label={$$("start_time_input_label")}
                                                    value={this.timeToDate(this.state.starts)}
                                                    KeyboardButtonProps={{'aria-label': 'change time'}}
                                                    onChange={this.onStartTimeChange}
                                                    required
                                                    invalidDateMessage={$$('invalid_time_format')}
                                                />
                                            </div>
                                                <div className="p-3">
                                                    <KeyboardTimePicker
                                                        ampm={!this.props.settings.time24hour}
                                                        margin="normal"
                                                        id="time-picker1"
                                                        okLabel={$$("OK")}
                                                        cancelLabel={$$("cancel_btn")}
                                                        label={$$("end_time_input_label")}
                                                        value={this.timeToDate(this.state.ends)}
                                                        KeyboardButtonProps={{'aria-label': 'change time'}}
                                                        onChange={this.onEndTimeChange}
                                                        required
                                                        invalidDateMessage={$$('invalid_time_format')}
                                                    />
                                                </div>
                                            </div>

                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>}
                        </div>
                        <div className="form-group settings-form">
                            <div className="d-flex settings-form-buttons">
                                <button type="button" onClick={this.resetChatSettings}
                                        className="btn btn-secondary">{$$("reset_btn_label")}</button>
                                <button type="submit" className="btn btn-primary settings-form-submit">
                                    {$$("save_btn_label")}</button>
                            </div>
                        </div>
                    </FormWithLoading>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    chat_settings: state.chat_settings.data,
    chat_settings_result: state.chat_settings.request,
    i18n: state.language.selected,
    formDisabled: state.formInteractions,
    settings: state.settings.data
})

export default connect(mapStateToProps, {updateProviderChatSettings, fetchProviderChatSettings})(ChatSettingsForm)