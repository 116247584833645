import React, {Component} from 'react'
import {$$} from '../../helpers/localization'

class MessagesHeader extends Component {
    render() {
        return (
            <div className='price-settings-card-header'>
                <div className='row space-between-justify'>
                    <h2 className='card-title card-header-title'>{$$('recent_messages_title')}</h2>
                </div>
            </div>
        )
    }
}

export default MessagesHeader
