import React, {Component} from 'react'
import {connect} from 'react-redux'
import SettingsForm from './SettingsForm'
import {updateUserSettings} from '../../actions/settings_actions'
import {
    fetchChartsData,
    fetchDashboardChartsData
} from '../../actions/users_actions'
import PropTypes from "prop-types";
import ConfigurationForm from "./ConfigurationForm";
import ChatSettingsForm from "./ChatSettingsForm";

export class SettingsPage extends Component {
    state = {
        successfulUpdate: false
    }

    constructor(props) {
        super(props);
    }

    /**
     * Check if update opeartin is done, if so display successful update message. Also if the settings are changed,
     * fetch the charts data based on the new settings.
     *
     * @param {object} prevProps - the previous props
     */
    componentDidUpdate(prevProps) {
        const prevSettings = prevProps.settings.data;
        const currentSettings = this.props.settings.data
        if (prevSettings) {
            if (prevSettings.timestamp < currentSettings.timestamp) {
                this.setState({successfulUpdate: true});
            }
            if (this.props.selectedUser.id &&
                (prevSettings.cholesterolUnits !== currentSettings.cholesterolUnits) ||
                (prevSettings.unitsOfMeasurement !== currentSettings.unitsOfMeasurement)) {
                this.props.fetchChartsData(this.props.selectedUser.id);
                this.props.fetchDashboardChartsData(this.props.selectedUser.id);
            }
        }
    }

    render() {
        return (
            <>
            <SettingsForm
                showSuccessfulAlert={this.state.successfulUpdate}
                settings={this.props.settings.data}
                updateUserSettings={this.props.updateUserSettings}
                userInfo={this.props.userInfo}
                i18n={this.props.i18n}
                formDisabled={this.props.formDisabled}
            />
            <ConfigurationForm />
            <ChatSettingsForm />
            </>
        )
    }
}

SettingsPage.propTypes = {
    fetchChartsData: PropTypes.func,
    fetchDashboardChartsData: PropTypes.func,
    formDisabled: PropTypes.any,
    userInfo: PropTypes.object,
    history: PropTypes.object,
    i18n: PropTypes.object,
    selectedUser: PropTypes.object,
    settings: PropTypes.object,
    updateUserSettings: PropTypes.func
};

const mapStateToProps = (state) => ({
    settings: state.settings,
    selectedUser: state.selectedUser.data,
    userInfo: state.userInfo.data,
    i18n: state.language.selected,
    userSelections: state.userSelections.settings,
    formDisabled: state.formInteractions
})

export default connect(mapStateToProps, {updateUserSettings, fetchChartsData, fetchDashboardChartsData})(SettingsPage)
