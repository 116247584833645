import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import CenteredModal from '../shared/CenteredModal';
import {
    getResolvedOptions,
    NOTE_VISIBILITY
} from '../../constants/select_options'
import Select from '../shared/Select'
import PropTypes from "prop-types";

export class NoteVisibilityForm extends Component {
    state = {
        value: '',
        visibility: '',
        formclass: '',
        errors: {}
    }

    constructor(props) {
        super(props);
    }

    /**
     * If on update, set current visibility value
     */
    componentDidUpdate = (prevProps) => {
        if (this.props.selectedNote && ((prevProps.selectedNote !== this.props.selectedNote) ||
            (prevProps.selectedNote === this.props.selectedNote && (!this.state.value && this.props.selectedNote.visibility)))) {
            this.setState({value: this.props.selectedNote.visibility});
            this.setState({visibility: this.props.selectedNote.visibility});
        }
    }

    /**
     * Hide the modal
     */
    onHideVisibilityModal = () => {
        this.setState({
            value: '',
            visibility: '',
            formclass: '',
            errors: {}
        });
        this.props.onHide();
    }

    /**
     * Create new entry or update existing entry from list
     */
    onSaveVisibilityModal = () => {
        if (this.validate()) {
            let note = this.props.selectedNote;
            note.visibility = this.state.visibility;
            this.props.updateNote(note);
            this.onHideVisibilityModal();
        }
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call create logbook entry action.
     *
     * @param {object} evt - The event handler argument
     */
    onSubmit = (evt) => {
        evt.preventDefault();
    }

    /**
     * Validate form data.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        this.setState({errors: ''});
        if (!this.state.visibility) {
            errors.visibility = 'visibility_required_message';
        }
        this.setState({errors});
        if (this.state.formclass !== "was-validated") {
            this.setState({formclass: "was-validated"});
        }
        return Object.keys(errors).length == 0;

    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        if (evt.target) {
            fields[evt.target.name] = evt.target.value;
        } else {
            fields[evt.name] = evt.value;
        }
        this.setState(fields);
    }

    getOptions = () => {
        return getResolvedOptions(NOTE_VISIBILITY.TYPE).filter(option => {
            return !this.props.hidePatientOptions || (option.value !== 'PATIENT_ONLY' && option.value !== 'ALL')
        });
    }

    render() {
        return (
            <CenteredModal title={$$('update_visibility')}
                           show={this.props.show}
                           onHide={this.onHideVisibilityModal}
                           onConfirm={this.onSaveVisibilityModal}
                           confirmBtnLabel={$$('save_btn_label')}
            >
                <form id="logbookForm" onSubmit={this.onSubmit} className={this.state.formclass} noValidate={true}
                      style={{"marginBottom": "-30px"}}>
                    <div className="form-group">
                        <Select
                            name="visibility"
                            options={this.getOptions()}
                            value={this.state.visibility}
                            onChange={this.onInputChange}
                            placeHolder={$$('visibility_label')}/>
                        <div className="invalid-feedback">
                            {$$('visibility_required_message')}
                        </div>
                    </div>
                </form>

                {(this.state.visibility === 'PATIENT_ONLY' || this.state.visibility === 'ALL') && <p className={"text-danger"}>{$$('note_visible_to_patient_visibility_form_warning')}</p>}
            </CenteredModal>
        )
    }
}

NoteVisibilityForm.propTypes = {
    i18n: PropTypes.object,
    updateNote: PropTypes.func,
    onHide: PropTypes.func,
    show: PropTypes.bool,
    hidePatientOptions: PropTypes.bool,
    selectedNote: PropTypes.any
};

export default NoteVisibilityForm;
