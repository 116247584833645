import React from "react";
import {connect} from 'react-redux';
import {$$} from '../../../helpers/localization';
import PropTypes from "prop-types";
import CenteredModal from "../../../components/shared/CenteredModal";
import {Select} from "../../../components/shared/Select";
import {updateUserSettings} from '../../../actions/settings_actions';
import {SETTING_OPTIONS} from '../../../constants/select_options';

class SettingsOption extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            dateFormat: this.props.settings.dateFormat,
            time24hour: this.props.settings.time24hour
        }
    }

    updateSettings = () => {
        let settings = {
            unitsOfMeasurement: this.props.settings.unitsOfMeasurement,
            cholesterolUnits: this.props.settings.cholesterolUnits,
            classificationMethod: this.props.settings.classificationMethod,
            dateFormat: this.state.dateFormat,
            time24hour: this.state.time24hour,
            glucoseUnits: this.props.settings.glucoseUnits,
            ketonesUnits: this.props.settings.ketonesUnits,
            hba1cUnits: this.props.settings.hba1cUnits,
            morningTime: this.props.settings.morningTime,
            noonTime: this.props.settings.noonTime,
            eveningTime: this.props.settings.eveningTime,
            bedTime: this.props.settings.bedTime
        }
        this.props.updateUserSettings(this.props.user.data.id, settings)
        this.props.closeSettingsModal()
    }
    onCheckboxChanged = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.checked;
        this.setState(fields);
    };

    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields);
    };


    render() {
        return (
            <CenteredModal title={$$('settings_label')}
                           show={this.props.settingsModalOpen}
                           onHide={this.props.closeSettingsModal}
                           onConfirm={() => {
                               this.updateSettings()
                           }}
                           confirmBtnLabel={$$('save_btn_label')}
                           cancelBtnLabel={$$('cancel_btn')}
            >
                <div>
                    <div className="form-group">
                        <Select
                            label={$$('date_format_label')}
                            name="dateFormat"
                            options={SETTING_OPTIONS.DATE_FORMATS}
                            value={this.state.dateFormat}
                            onChange={this.onSelectChange}
                            class="settings-input-color"
                        />
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="time24hour"
                               checked={this.state.time24hour}
                               onChange={this.onCheckboxChanged}
                        />
                        <label className="form-check-label" htmlFor="time24hour" onClick={this.onLabelClick}>
                            {$$('time_24hour_label')}
                        </label>
                    </div>
                </div>
            </CenteredModal>
        );
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.language,
        user: state.userInfo,
        settings: state.settings.data,
    }
}

export default connect(mapStateToProps, {
    updateUserSettings,
})(SettingsOption);


SettingsOption.propTypes = {
    i18n: PropTypes.any,
    updateUserSettings: PropTypes.func,
    closeSettingsModal: PropTypes.func,
    settingsModalOpen: PropTypes.bool,
    settings: PropTypes.any,
    user: PropTypes.any
}