import React, {Component} from "react";
import {Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PropTypes from "prop-types";

export default class CreateRoundButton extends Component {
    constructor(props, context) {
        super(props, context);
    }

    renderTooltip = props => (
        <Tooltip {...props} show={props.show.toString()}>{this.props.title}</Tooltip>
    );

    render() {
        return <OverlayTrigger
            placement={"top"}
            overlay={this.renderTooltip}
            popperConfig={{
                modifiers: {
                    preventOverflow: {
                        enabled: false
                    }
                }
            }}
        >
            <div {...this.props} className="btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm">
                {this.props.children}
            </div>
        </OverlayTrigger>
    }
}


CreateRoundButton.propTypes = {
    title: PropTypes.string
}