import React, {Component} from 'react'
import {connect} from 'react-redux'
import {fetchSpecialties, getUserInfo, updateUserInfo} from '../../actions/users_actions'
import UserProfileForm from './UserProfileForm';
import moment from 'moment'
import {
    deleteLoggedUserDocument,
    fetchLoggedUserDocuments,
    uploadLoggedUserDocument
} from "../../actions/documents_actions";
import PropTypes from "prop-types";
import {EditStaffProfile} from "../staff/UpdateStaff";
import history from "../../helpers/history";
import {usersService} from "../../service/users_service";
import {UPDATE_USER_PROFILE} from "../../actions/actions";
import store from "../../store";



class UserProfile extends Component {
    state = {
        successfulUpdate: false
    }


    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchLoggedUserDocuments(null, true);
        this.props.fetchSpecialties();
    }


    /**
     * Check if update opeartin is done, if so display successful update message.
     *
     * @param {object} prevProps - the previous props
     */
    componentDidUpdate(prevProps) {
        const prevUserInfo = prevProps.userInfo;
        const currentUserInfo = this.props.userInfo
        if (prevUserInfo) {
            if (moment(prevUserInfo.date_modified).valueOf() < moment(currentUserInfo.date_modified).valueOf()) {
                this.setState({successfulUpdate: true});
            }
        }
    }

    render() {
        if (this.props.userInfo.internal) {
            return <EditStaffProfile
                selectedOrg={this.props.selectedOrg}
                user={this.props.userInfo}
                lang={this.props.i18n.selected.lang}
                onCancel={() => { history.goBack() }}
                onSave={(u) => {
                    return usersService.putUserInfo({...this.props.userInfo, ...u})
                        .then((res) => {
                            if (res) {
                                return store.dispatch({type: UPDATE_USER_PROFILE, result: res});
                            }
                            return null;
                        })
                        .then(()=>{
                            history.goBack()
                        });
                }}
            />
        }
        return (
            <UserProfileForm
                showSuccessfulAlert={this.state.successfulUpdate}
                userInfo={this.props.userInfo}
                updateUserInfo={this.props.updateUserInfo}
                registration={this.props.registration}
                specialties={this.props.specialties}
                documents={this.props.documents}
                i18n={this.props.i18n}
                formDisabled={this.props.formDisabled}
                uploadLoggedUserDocument={this.props.uploadLoggedUserDocument}
                deleteLoggedUserDocument={this.props.deleteLoggedUserDocument}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        documents: state.documents.loggedUser.entries,
        userInfo: state.userInfo.data,
        i18n: state.language,
        registration: state.register,
        specialties: state.specialties,
        formDisabled: state.formInteractions,
        selectedOrg: state.management.selectedOrganisation
    }
}

UserProfile.propTypes = {
    deleteLoggedUserDocument: PropTypes.func,
    documents: PropTypes.array,
    fetchLoggedUserDocuments: PropTypes.func,
    fetchSpecialties: PropTypes.func,
    formDisabled: PropTypes.any,
    getUserInfo: PropTypes.func,
    history: PropTypes.object,
    i18n: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    registration: PropTypes.object,
    specialties: PropTypes.object,
    updateUserInfo: PropTypes.func,
    uploadLoggedUserDocument: PropTypes.func,
    userInfo: PropTypes.object
}


const mapDispatchToProps = {
    getUserInfo,
    updateUserInfo,
    fetchSpecialties,
    fetchLoggedUserDocuments,
    uploadLoggedUserDocument,
    deleteLoggedUserDocument

}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
