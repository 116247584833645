import React from "react";
import {
    Link,
    Route,
    Switch
} from "react-router-dom";
import "../../_metronic/_assets/sass/pages/login/login-1.scss";
import Login from "./LoginPage";
import Registration from "./../register/Register";
import LanguageSelector from "../layout/LanguageSelector";
import {connect} from "react-redux";
import {changeLanguage} from "../../actions/language_actions";
import {$$} from "../../helpers/localization";
import {version, internal_version} from "../../../package"
import ProviderImage from "../register/wizard/ProviderImage"
import PropTypes from "prop-types";

class AuthPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { width: window.innerWidth };
    }

    handleResize = () => {
        this.setState({ width: window.innerWidth });
    };

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);

    }

    notOnSmallScreen = () => {
        return this.state.width >= 1025;
    }

    render() {
        return (
            <>
                <div className="kt-grid kt-grid--ver kt-grid--root">
                    <div
                        id="kt_login"
                        className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
                    >
                        <div
                            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                            <div
                                className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                            >
                                <div className="kt-grid__item">
                                    <Link to="">
                                        <div className="main-logo-label">MEDREC : M</div>
                                        <div className="sub-logo-label">clinic</div>
                                    </Link>
                                </div>
                                <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver center-justify">
                                    <div className="kt-grid__item upload-img">
                                        {this.props.location.pathname === '/register' &&
                                        this.props.providerImage &&
                                        this.props.providerImage.shouldDisplayProviderImage &&
                                            this.notOnSmallScreen() &&
                                        <ProviderImage i18n={this.props.i18n}
                                                       userInfo={""}/>
                                        }
                                    </div>
                                </div>

                                <div className="kt-grid__item">
                                    <div className="kt-login__info small">
                                        <div className="kt-login__copyright">
                                            &copy; {new Date().getFullYear().toString()} Sirma Medical Systems
                                            <div className="small">{$$("version")} {version} ({internal_version})</div>
                                        </div>
                                        <div className="kt-login__menu">
                                            <a
                                                href="https://www.medrec-m.com/#!/remote"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="kt-link"
                                            >
                                                {$$("about")}
                                            </a>
                                            <a
                                                href="https://medrec-m.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="kt-link"
                                            >
                                                {$$("contact")}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="kt-grid__item kt-grid__item--fluid  kt-grid__item--order-tablet-and-mobile-1  kt-login__wrapper">
                                <div className="kt-login__head">
                                    <div className="flex-container flex-row-reverse"><LanguageSelector className="show"
                                                                                                       changeLanguage={this.props.changeLanguage}/>
                                    </div>
                                </div>
                                <Switch>
                                    <Route path="/login" component={Login}/>
                                    <Route path="/register" component={Registration}/>
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

AuthPage.propTypes = {
    changeLanguage: PropTypes.func,
    providerImage: PropTypes.object,
    history: PropTypes.object,
    i18n: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object
}

function mapStateToProps(state) {
    return {
        providerImage: state.providerImageVisibility,
        i18n: state.language
    }
}

export default connect(mapStateToProps, {changeLanguage})(AuthPage);
