import React, {Component} from 'react'
import classnames from 'classnames'
import {changeLanguage} from '../../actions/language_actions'
import {connect} from 'react-redux'
import blank_gif from '../../resources/images/blank.gif'
import PropTypes from "prop-types";


class LanguageSelector extends Component {

    constructor(props) {
        super(props);
        this._selectLanguage = this._selectLanguage.bind(this);
    }

    _selectLanguage(e, language) {
        e.preventDefault();
        e.stopPropagation();
        this.props.changeLanguage(language);
    }

    render() {
        let languages = this.props.languages.available;
        let language = this.props.languages.selected;
        return (

            <div
                className={classnames("dropdown", "kt-header__topbar-item", "kt-header__topbar-item--langs", "align-self-center", this.props.className)}>
                <a className={classnames("btn btn-icon btn-outline-secondary btn-sm", this.props.textClass)} href='#'
                   onClick={e => e.preventDefault()} data-toggle="dropdown" data-flip="false" aria-expanded="false">
                    <img src={blank_gif}
                         className={classnames(['flag', 'flag-' + language.key])} alt={language.alt}/>
                </a>
                <div
                    className={classnames("dropdown-menu-fit", "dropdown-menu-right", "dropdown-menu-anim", "dropdown-menu-top-unround", "dropdown-menu", "dropdown-menu-right", this.props.listClassName)}>
                    {languages.map(function (_lang, idx) {
                        return (

                            <a key={idx} className={classnames(this.props.textClass, {
                                "dropdown-item": true
                            })} href="#" onClick={(e) => this._selectLanguage(e, _lang)}>
                                <img src={blank_gif}
                                     className={classnames(['flag', 'flag-box', 'flag-' + _lang.key])} alt={_lang.alt}
                                />
                                <span>&nbsp;{_lang.title}</span>
                            </a>

                        )
                    }.bind(this))}
                </div>
            </div>

        )
    }
}

LanguageSelector.propTypes = {
    changeLanguage: PropTypes.func,
    className: PropTypes.string,
    textClass: PropTypes.string,
    listClassName: PropTypes.string,
    languages: PropTypes.object
}

function mapStateToProps(state) {
    return {
        languages: state.language
    }
}

export default connect(mapStateToProps, {changeLanguage})(LanguageSelector)
