import React from 'react'
import {connect} from 'react-redux'
import {clearLoginResponse, login, logout, managementLogin} from '../../actions/auth_actions'
import LoginForm from './LoginForm'
import {useLocation} from 'react-router-dom'
import PropTypes from "prop-types";
import {UpdateMenu} from "../../actions/menu_actions";


export const LoginPage = (props) => {
    let query = new URLSearchParams(useLocation().search);
    return (
        <LoginForm
            onLogin={props.login}
            onManagementLogin={props.managementLogin}
            UpdateMenu={props.UpdateMenu}
            auth_data={props.auth_data}
            email={query.get('username')}
            password={query.get('password')}
            clearLoginResponse={props.clearLoginResponse}
            logout={props.logout}
            i18n={props.i18n}/>
    )
}

LoginPage.propTypes = {
    login: PropTypes.func,
    managementLogin: PropTypes.func,
    clearLoginResponse: PropTypes.func,
    logout: PropTypes.func,
    UpdateMenu: PropTypes.func,
    auth_data: PropTypes.object,
    i18n: PropTypes.object,
}


function mapStateToProps(state) {
    return {
        auth_data: state.authentication,
        i18n: state.language
    }
}

export default connect(mapStateToProps, {login, logout, managementLogin, UpdateMenu, clearLoginResponse})(LoginPage)

