import store from "../store";
import {Permissions} from "../utils/permissions/types"

export {
    getEmail,
    getPhone,
    getFirstContactInfo,
    getFirstContactInfoName,
    isChild,
    canEditInfo
}

function getEmail(object, defaultValue) {
    if (object.email && object.email.includes("@")) {
        return object.email;
    }
    if (object.parentContactInfos?.length > 0) {
        let m = object.parentContactInfos[0].email;
        if (m.includes("@")) {
            return m;
        }
    }
    return defaultValue;
}

function getFirstContactInfo(object) {
    return object.parentContactInfos?.length > 0 ? object.parentContactInfos[0] : null;
}

function getFirstContactInfoName(object) {
    return getFirstContactInfo(object)?.fullname || null;
}

function getPhone(object, defaultValue) {
    if (object.phone) {
        return object.phone;
    }
    if (object.parentContactInfos?.length > 0) {
        return object.parentContactInfos[0].phone;
    }
    return defaultValue;
}

function canEditInfo(u) {
    if (u.disabled) {
        return false;
    }

    let state = store.getState();
    let inAdminPart = state.menu.selected === 2 && state.management.selectedOrganisation.id;
    let canUpdate = !inAdminPart;
    if (inAdminPart) {
        let permissions = Permissions.fromOrg(state.userInfo.data.organizations.find(o => o.id === state.management.selectedOrganisation.id));
        canUpdate = permissions.canUpdatePatient()
    }
    if (isChild(u)) {
        return getFirstContactInfo(u).never_logged_in && canUpdate;
    }

    return u.never_logged_in && canUpdate;
}

function isChild(u) {
    return u.parentContactInfos?.length > 0 && !u.email.includes('@');
}

