import React, {Component} from "react";
import PropTypes from "prop-types";

class PracticeItem extends Component {
    constructor(props) {
        super(props);
    }

    selected = () => {
        if (this.props.selected) {
            return "selected-message"
        }
        return ""
    }

    render() {
        return (
            <>
                <div className="select-practice-cell">
                    <div className="row">
                        <a href="#"
                           className={"list-group-item list-group-item-action flex-column align-items-start " + this.selected()}
                           onClick={(e) => {
                               e.preventDefault();
                               this.props.onItemClick(this.props.id, this.props.name);
                           }}>
                            <div className="patient-fullname organisation-selection">
                                <b>{this.props.name}</b>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="select-practice-line"/>
            </>
        )
    }
}

PracticeItem.propTypes = {
    onItemClick: PropTypes.func,
    id: PropTypes.string,
    name: PropTypes.string,
    selected: PropTypes.bool

}

export default PracticeItem;