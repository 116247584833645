import React, {Component} from 'react'
import {$$, _$$} from '../../helpers/localization';
import {CONVERTER, getUnitValuefromKey} from '../../utils/converter';
import moment from 'moment'
import {
    CYCLE,
    DAILY,
    DURATION_FOR_X_DAYS,
    DURATION_UNTIL_DATE,
    EVERY_X_DAYS,
    SPECIFIC_DAYS_OF_WEEK,
    TAKE_EVERY_X_HOURS,
    TAKE_X_TIMES,
    WHEN_NEEDED
} from '../../constants/medications_constants';
import {ONGOING} from '../../constants/call_status';
import {DASH} from '../../constants/dash'
import classNames from 'classnames'
import PropTypes from "prop-types";

/* eslint-disable */
export class MedicationPlanV2 extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Get list of medication plan items.
     *
     * @returns list of medication plan items
     */
    getMedicationPlanList = () => {
        const activeMedicationPlan = this.props.medicationPlan && this.props.medicationPlan.entries ?
            this.props.medicationPlan.entries.filter(m => m.ends === 0 || m.ends > moment().valueOf()) : [];
        return activeMedicationPlan.length > 0
            ? activeMedicationPlan.map((m, idx) => {
                return <MedicationPlanItem key={idx} medication={m} i18n={this.props.i18n}/>
            })
            : [];
    }

    render() {
        let medicationPlanList = this.getMedicationPlanList();
        return (
            <ul className='list-group list-group-flush'>
                {medicationPlanList.length > 0 ? medicationPlanList : DASH}
            </ul>
        )
    }
}

const MedicationPlanItem = (props) => {
    const {medication, i18n} = props;
    const [isCollapsed, setIsCollapsed] = React.useState(false);

    const collapseClick = React.useCallback(
        () => setIsCollapsed(!isCollapsed),
        [isCollapsed, setIsCollapsed],
    );

    /**
     * Get the formatted medication frequency string
     *
     * @returns {string} medication plan frequency
     */
    const getMedicationFrequencyString = () => {
        if (medication.scheduleType === WHEN_NEEDED) {
            return `${medication.dose} ${getUnitValuefromKey(medication.unit, medication.dose)}`;
        }
        switch (medication.howToTake) {
            case TAKE_X_TIMES:
                return _$$(medication.scheduleTimesPerDay > 1 ? 'times_per_day_plural' : "times_per_day", medication.dose, getUnitValuefromKey(medication.unit, medication.dose), medication.scheduleTimesPerDay);
            case TAKE_EVERY_X_HOURS:
                return _$$(medication.scheduleHours > 1 ? 'every_x_hours_plural' : "every_x_hours", medication.dose, getUnitValuefromKey(medication.unit, medication.dose), medication.scheduleHours);
            default:
                return '';
        }
    }

    /**
     * Get the formatted medication schedule string
     *
     * @returns {string} medication plan schedule
     */
    const getMedicationScheduleString = () => {
        switch (medication.scheduleType) {
            case WHEN_NEEDED:
                return $$('when_needed');
            case DAILY:
                return $$('daily');
            case EVERY_X_DAYS:
                return _$$('every_x_days', medication.scheduleDaysInactive + 1);
            case SPECIFIC_DAYS_OF_WEEK: {
                let days = [];
                (medication.scheduleWeekdays >>> 0).toString(2).split('').forEach((bit, idx) => {
                    if (bit === '1') {
                        days.push(moment().locale(i18n.selected.lang).day(idx + 1).format('ddd'));
                    }
                });
                return days.join(', ');
            }
            case CYCLE:
                return _$$('take_x_rest_x', medication.scheduleDaysActive, medication.scheduleDaysInactive);
            default:
                return '';
        }
    }

    /**
     * Get the formatted medication duration string
     *
     * @returns {string} medication plan duration
     */
    const getMedicationDurationString = () => {
        switch (medication.durationType) {
            case ONGOING:
                return $$('ongoing');
            case DURATION_FOR_X_DAYS:
                return _$$('for_x_days_until', medication.durationInDays, CONVERTER.formatDate(medication.ends));
            case DURATION_UNTIL_DATE:
                return _$$('until_date', CONVERTER.formatDate(medication.ends, true))
            default:
                return '';
        }
    }

    const collapseClass = classNames('medication-plan-info', 'lighter-font',
        {
            'hide-medicaiton-info': !isCollapsed,
            'show-medication-info': isCollapsed
        });

    return (
        <li onClick={collapseClick} className='list-group-item medication-plan-item'>
            <div className={"space-between-justify"} style={{'display': 'flex'}}>
                <div style={{'marginRight': '0.3125rem'}}>
                    {medication.name}, {getMedicationFrequencyString()}
                </div>
                <div>
                    {!isCollapsed &&
                        <i className='kt-nav__link-icon fas fa-chevron-down medrec-blue-1 icon-thin'/>
                    }
                    {isCollapsed &&
                        <i className='kt-nav__link-icon fas fa-chevron-up medrec-blue-1 icon-thin'/>
                    }
                </div>
            </div>
            <div className={collapseClass}>
                <div>{getMedicationScheduleString()}</div>
                <div>{getMedicationDurationString()}</div>
                <div>{_$$('effective_from', CONVERTER.formatDate(medication.starts))}</div>
            </div>
        </li>
    )
}

MedicationPlanV2.propTypes = {
    i18n: PropTypes.object,
    medicationPlan: PropTypes.object
}

export default MedicationPlanV2
