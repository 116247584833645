import {getLanguage} from "../../../../helpers/localization";

export function sortByLabel(a, b) {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
        return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
        return 1;
    }
    return 0;
}

export function sortByProp(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return -1;
        }
        if (a[prop] > b[prop]) {
            return 1;
        }
        return 0;
    }
}

export function convertToOptions(nomenclature, sortFunction = sortByLabel) {
    const items = nomenclature.items;
    let propNames = Object.getOwnPropertyNames(items);
    let map = propNames.map(prop => ({
        value: prop,
        label: ["CL011"].includes(nomenclature.nomenclatureId)  ? prop + " " + items[prop].description : items[prop].description,
        text: items[prop].description,
        item: items[prop]
    }));
    if (sortFunction) {
        return map.sort(sortFunction);
    }
    return map;
}

export function getLabel(options, value) {
    const opt = options?.find(o => o.value == value);
    //console.log(opt)
    //console.log(value)
    if (!opt) {
        return "";
    }

    return getLanguage() === 'en' ? opt.item?.meta.DescriptionEn : opt.label;
}