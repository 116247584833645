import React from "react";
import {$$} from "../../helpers/localization";

// eslint-disable-next-line react/prop-types
const SingleLineCheckbox = ({label, isSelected, onCheckboxChange}) => (
    <div className="custom-control custom-checkbox custom-control-inline">
        <input className="custom-control-input"
               type="checkbox"
               name={label}
               id={label}
               checked={isSelected}
               onChange={onCheckboxChange}/>
        <label className="custom-control-label" htmlFor={label}>{$$(label)}</label>
    </div>
);

export default SingleLineCheckbox;
