import React from "react";
import objectPath from "object-path";
import {Link} from "react-router-dom";
import MenuItemText from "./MenuItemText";
import MenuSubmenu from "./MenuSubmenu";
import clsx from "clsx";
import {connect} from 'react-redux'
import {logout} from '../../../actions/auth_actions'
import {Routes} from '../../../constants/routes'
import {$$} from "../../../helpers/localization";

class MenuItem extends React.Component {
    asideLeftLIRef = React.createRef();
    isDropdown = document.body.classList.contains("kt-aside-menu--dropdown");

    submenuToggle =
        this.props.item.toggle === "click"
            ? "click"
            : objectPath.get(this.props.item, "submenu.type") === "tabs"
                ? "tabs"
                : "hover";

    /**
     * Use for fixed left aside menu, to show menu on mouseenter event.
     * @param event Event
     */
    mouseEnter = event => {
        if (!this.isDropdown) {
            return;
        }

        if (this.props.item.submenu) {
            this.asideLeftLIRef.current.classList.add("kt-menu__item--hover");
            this.asideLeftLIRef.current.setAttribute(
                "data-ktmenu-submenu-toggle",
                this.submenuToggle
            );
        }
    };

    /**
     * Mouse Leave event
     * @param event: MouseEvent
     */
    mouseLeave = event => {
        if (!this.isDropdown) {
            return;
        }

        if (this.props.item.submenu && this.submenuToggle === "hover") {
            this.asideLeftLIRef.current.classList.remove("kt-menu__item--hover");
            this.asideLeftLIRef.current.removeAttribute("data-ktmenu-submenu-toggle");
        }
    };

    isMenuItemIsActive = item => {
        if (item.submenu) {
            return this.isMenuRootItemIsActive(item);
        }

        if (!item.page) {
            return false;
        }

        return this.props.currentUrl.indexOf(item.page) !== -1;
    };

    isMenuRootItemIsActive = item => {
        for (const subItem of item.submenu) {
            if (this.isMenuItemIsActive(subItem)) {
                return true;
            }
        }

        return false;
    };

    render() {
        const {item, currentUrl, parentItem, layoutConfig, selectedUser, selectUser, unreadChatMessages} = this.props;
        const isActive = this.isMenuItemIsActive(item);
        const unreadMessagesCount = unreadChatMessages ? unreadChatMessages.reduce((sum, m) => sum + m.count, 0) : 0;

        return (
            <>
                {((this.props.fixedButtons && item.fixedButton) || (!this.props.fixedButtons && !item.fixedButton)) &&
                    <li
                        ref={this.asideLeftLIRef}
                        aria-haspopup="true"
                        data-placement="right"
                        data-ktmenu-submenu-mode={item.mode}
                        onMouseEnter={this.mouseEnter}
                        onMouseLeave={this.mouseLeave}
                        className={clsx(
                            "kt-menu__item",
                            {
                                "kt-menu__item--submenu": item.submenu,
                                "kt-menu__item--open kt-menu__item--here": isActive && item.submenu,
                                "kt-menu__item--active kt-menu__item--here":
                                    (isActive && !item.submenu),
                                "kt-menu__item--icon-only": item["icon-only"]
                            },
                            item["custom-class"]
                        )}
                        data-ktmenu-dropdown-toggle-class={item["dropdown-toggle-class"]}
                    >
                        {(!item.submenu && !item.hidden) && (
                            item.page == "logout" ? (
                                <Link to={Routes.LOGIN} onClick={() => this.props.logout()} title={item.translate ? $$(item.translate) : item.title}
                                      className="kt-menu__link kt-menu__toggle">
                                    <MenuItemText item={item} parentItem={parentItem}
                                                  unreadMessagesCount={unreadMessagesCount}/>
                                </Link>
                            ) : item.externalLink ? (
                                <a href={item.externalLink} className="kt-menu__link" target="_blank" title={item.translate ? $$(item.translate) : item.title}>
                                    <MenuItemText item={item} parentItem={parentItem}
                                                  unreadMessagesCount={unreadMessagesCount}/>
                                </a>
                            ) : (
                                <Link to={item.page !== null && item.page !== undefined ? `/${item.page}` : '#'} title={item.translate ? $$(item.translate) : item.title}
                                      onClick={(e) => {item.onClick ? item.onClick() : true}}
                                      className="kt-menu__link kt-menu__toggle">
                                    <MenuItemText item={item} parentItem={parentItem}
                                                  unreadMessagesCount={unreadMessagesCount}/>
                                </Link>
                            )
                        )}

                        {(item.submenu && !item.hidden) && (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a className="kt-menu__link kt-menu__toggle">
                                <MenuItemText item={item} parentItem={parentItem}/>
                            </a>
                        )}

                        {(item.submenu && !item.hidden) && (
                            <div className="kt-menu__submenu">
                                <span className="kt-menu__arrow"/>

                                {item["custom-class"] === "kt-menu__item--submenu-fullheight" && (
                                    <div className="kt-menu__wrapper">
                                        <MenuSubmenu
                                            item={item}
                                            parentItem={item}
                                            currentUrl={currentUrl}
                                        />
                                    </div>
                                )}

                                {item["custom-class"] !== "kt-menu__item--submenu-fullheight" && (
                                    <MenuSubmenu
                                        item={item}
                                        parentItem={item}
                                        currentUrl={currentUrl}
                                        layoutConfig={layoutConfig}
                                        selectUser={selectUser}
                                        selectedUser={selectedUser}
                                    />
                                )}
                            </div>
                        )}
                    </li>
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.language
    }
}

export default connect(mapStateToProps, {logout})(MenuItem);
