import {
    CLEAR_CHAT,
    DUMMY,
    FETCH_CHAT_MESSAGES_REQUEST_ERROR,
    FETCH_CHAT_MESSAGES_REQUEST_SENT,
    FETCH_CHAT_MESSAGES_REQUEST_SUCCESS,
    FETCH_CHAT_UNREAD_MESSAGES_COUNT_ERROR,
    FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS,
    NEW_MESSAGE,
    NEW_MESSAGE_FROM_SOCKET_ARRIVED,
    NO_MORE_MESSAGES,
    SET_MESSAGE_AS_SEEN
} from './actions';
import {chatService} from '../service/chat_service';
import moment from 'moment';
import _ from 'underscore'

/**
 * Fetch conversation between users, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchChatMessages(userId, params, reset) {
    return (dispatch, getState) => {
        if (!params) {
            params = {
                before_date_time: moment().valueOf(),
                size: 500
            };
        }
        return chatService.getChatWithUser(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res && res.length > 0) {
                let messages = res;
                messages = _.chain(res).reverse().value();
                dispatch({type: FETCH_CHAT_MESSAGES_REQUEST_SUCCESS, result: messages, reset: reset})
            } else {
                dispatch({type: NO_MORE_MESSAGES})
            }
        }).catch((err) => {
            dispatch({type: FETCH_CHAT_MESSAGES_REQUEST_ERROR, result: err});
        })
    }
}

/**
 * Send message.
 *
 * @param {object} message - the message to send to user
 * @returns {function} dispatch function
 */
export function sendMessage(message) {
    return (dispatch) => {
        dispatch({type: FETCH_CHAT_MESSAGES_REQUEST_SENT});
        return chatService.sendMessage(message).catch((err) => {
            dispatch({type: FETCH_CHAT_MESSAGES_REQUEST_ERROR, result: err});
        });
    }
}

/**
 * Replace message with new updated version
 *
 * @param {object} message - the message to update
 */
export function updateMessage(message) {
    return (dispatch) => {
        dispatch({type: SET_MESSAGE_AS_SEEN, message: message});
    }
}

/**
 * Attach new message to the messages list
 *
 * @param {object} message - the message to attach
 * @returns {function} dispatch function
 */
export function attachNewMessage(message) {
    return (dispatch) => {
        dispatch({type: NEW_MESSAGE, message: message});
    }
}

/**
 * Mark message as read.
 *
 * @param {number} messageId - the message to mark as read
 * @returns {function} dispatch function
 */
export function markAsRead(messageId) {
    return () => {
        return chatService.markAsRead(messageId)
    }
}

/**
 * Clear the stored chat messages
 *
 * @returns {function} dispatch function
 */
export function clearChat() {
    return (dispatch) => {
        dispatch({type: CLEAR_CHAT});
    }
}

/**
 * Get unread messages from server.
 *
 * @returns {function} dispatch function
 */
export function getUnreadMessages() {
    return (dispatch) => {
        return chatService.getUnreadMessages().then((res) => {
            if (res !== null) {
                dispatch({type: FETCH_CHAT_UNREAD_MESSAGES_COUNT_SUCCESS, result: res});
            }
        }).catch((err) => {
            dispatch({type: FETCH_CHAT_UNREAD_MESSAGES_COUNT_ERROR, result: err});
        })
    }
}

/**
 * Stores the new message arriving in the redux store
 * @param {*} message
 * @returns
 */
export function storeMessageInReduxStore(message) {
    return (dispatch) => {
        dispatch({type: NEW_MESSAGE_FROM_SOCKET_ARRIVED, message: message});
    }
}