import React, {Component} from "react";
import {$$} from "../../helpers/localization";
import PropTypes from "prop-types";
import CardiacStats from "../cardiac_stats/CardiacStats";
import MedicationsTab from "../medications_tab/MedicationsTab";
import TherapyTab from "../therapy_tab/TherapyTab";
import PeakFlowTab from "../peak-flow_tab/PeakFlowTab";
import {dummyActionDirectDispatch} from "../../actions/clinic_actions";
import {TimeSpanPicker} from "./TimeSpanPicker";
import {MenstruationStatsTab} from "../menstruation_stats/MenstruationStatsTab";
import {cloneDeep} from "lodash";



export class Other extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardiac : true,
            medications : false,
            therapy : false,
            peakFlow : false,
            menstruationStats: false
        };
    }

    componentDidMount() {
        if(this.props.selectedFilters.otherTab?.selectedStartYrs &&
            this.props.selectedFilters.otherTab?.selectedStart &&
            this.props.selectedFilters.otherTab?.selectedEndYrs &&
            this.props.selectedFilters.otherTab?.selectedEnd
        ) {
            this.setState({
                selectedStartYrs: this.props.selectedFilters.otherTab.selectedStartYrs,
                selectedEndYrs: this.props.selectedFilters.otherTab.selectedEndYrs,
                selectedStart: this.props.selectedFilters.otherTab.selectedStart,
                selectedEnd: this.props.selectedFilters.otherTab.selectedEnd
            })
        }

        dummyActionDirectDispatch();
    }

    cardiacButtonClicked = () => {
        this.setState({
            cardiac : true,
            medications : false,
            therapy : false,
            peakFlow : false,
            menstruationStats: false
        })
        dummyActionDirectDispatch();
    }

    medicationsButtonClicked = () => {
        this.setState({
            cardiac : false,
            medications : true,
            therapy : false,
            peakFlow : false,
            menstruationStats: false
        })
        dummyActionDirectDispatch();
    }

    therapyButtonClicked = () => {
        this.setState({
            cardiac : false,
            medications : false,
            therapy : true,
            peakFlow : false,
            menstruationStats: false
        })
        dummyActionDirectDispatch();
    }

    peakFlowButtonClicked = () => {
        this.setState({
            cardiac : false,
            medications : false,
            therapy : false,
            peakFlow : true,
            menstruationStats: false
        })
        dummyActionDirectDispatch();
    }

    menstruationButtonClicked = () => {
        this.setState({
            cardiac : false,
            medications : false,
            therapy : false,
            peakFlow : false,
            menstruationStats: true
        })
        dummyActionDirectDispatch();
    }

    onFilter = (dateRange, years, timeFrameSelection) => {
        if (dateRange.start && dateRange.end) {
            let start = parseInt((dateRange.start.getTime()).toFixed(0));
            let end = parseInt((dateRange.end.getTime()).toFixed(0));

            if (years) {
                this.setState({selectedStartYrs: start, selectedEndYrs: end})
                let filters = cloneDeep(this.props.selectedFilters)
                filters.otherTab.selectedStartYrs = start;
                filters.otherTab.selectedEndYrs = end;
                filters.otherTab.timeFrameSelectionYrs = timeFrameSelection;
                this.props.updateFilters(filters);

            } else if (!years){
                this.setState({selectedStart: start, selectedEnd: end})
                let filters = cloneDeep(this.props.selectedFilters)
                filters.otherTab.selectedStart = start;
                filters.otherTab.selectedEnd = end;
                filters.otherTab.timeFrameSelection = timeFrameSelection;
                this.props.updateFilters(filters);

            }

        }
    }

    render() {
        return (
            <>
                <div className={"btn-group btn-group-sm btn-group-medium-screen"}>
                    <button onClick={this.cardiacButtonClicked} type="button"
                            className={this.state.cardiac ? "btn btn-primary active" : "btn btn-secondary"}>{$$('cardiac_tab_label')}</button>
                    <button onClick={this.medicationsButtonClicked} type="button"
                            className={this.state.medications ? "btn btn-primary active" : "btn btn-secondary"}>{$$('medications_tab_label')}</button>
                    <button onClick={this.therapyButtonClicked} type="button"
                            className={this.state.therapy ? "btn btn-primary active" : "btn btn-secondary"}>{$$('therapy_tab_label')}</button>
                    <button onClick={this.peakFlowButtonClicked} type="button"
                            className={this.state.peakFlow ? "btn btn-primary active" : "btn btn-secondary"}>{$$('peak_flow')}</button>
                    {this.props.selectedUser.gender !== 'MALE' && <button onClick={this.menstruationButtonClicked} type="button"
                            className={this.state.menstruationStats ? "btn btn-primary active" : "btn btn-secondary"}>{$$('Menstruation')}</button>}
                </div>

                <br/>

                <span className={this.state.menstruationStats ? "hidden" : ""}><TimeSpanPicker
                    onFilter={this.onFilter}
                    timeFrameSelection={this.props.selectedFilters.otherTab.timeFrameSelection}
                    settings={this.props.settings}
                /></span>
                <span className={!this.state.menstruationStats ? "hidden" : ""}><TimeSpanPicker
                    onFilter={this.onFilter}
                    settings={this.props.settings}
                    timeFrameSelectionYrs={this.props.selectedFilters.otherTab.timeFrameSelectionYrs}
                    years={true}
                /></span>

                {this.state.cardiac && <CardiacStats
                    selectedUser={this.props.selectedUser}
                    fetchStats={this.props.fetchSelectedUserCardiacStats}
                    i18n={this.props.i18n.lang}
                    stats={this.props.cardiacStats}
                    selectedStart={this.state.selectedStart}
                    selectedEnd={this.state.selectedEnd}
                />}

                {this.state.medications && <MedicationsTab
                    i18n={this.props.i18n.lang}
                    selectedUser={this.props.selectedUser}
                    selectedStart={this.state.selectedStart}
                    selectedEnd={this.state.selectedEnd}
                />}

                {this.state.therapy && <TherapyTab
                    i18n={this.props.i18n.lang}
                    selectedUser={this.props.selectedUser}
                    selectedStart={this.state.selectedStart}
                    selectedEnd={this.state.selectedEnd}
                />}

                {this.state.peakFlow && <PeakFlowTab
                    selectedUser={this.props.selectedUser}
                    i18n={this.props.i18n.lang}
                    selectedStart={this.state.selectedStart}
                    selectedEnd={this.state.selectedEnd}
                />}

                {this.state.menstruationStats && this.props.selectedUser.gender !== 'MALE' && <MenstruationStatsTab
                    selectedUser={this.props.selectedUser}
                    i18n={this.props.i18n.lang}
                    selectedStart={this.state.selectedStartYrs}
                    selectedEnd={this.state.selectedEndYrs}
                    settings={this.props.settings}
                />}

            </>
        )
    }
}

Other.propTypes = {
    i18n: PropTypes.any,
    selectedUser: PropTypes.any,
    settings: PropTypes.any,
    selectedFilters: PropTypes.object,
    updateFilters: PropTypes.func,
    cardiacStats: PropTypes.any,
    fetchSelectedUserCardiacStats: PropTypes.func,
}

export default Other;