import React, {Component} from "react";
import {canReceiveMessageFrom} from "../../../service/chat_service";
import {blockUserFromChat} from "../../../service/chat_service";
import {ExplicitUnblockUserFromChat} from "../../../service/chat_service";
import {resetChatBlockingStatus} from "../../../service/chat_service";
import PropTypes from "prop-types";
import {$$} from "../../../helpers/localization";
import {Select} from "../../shared/Select";


export default class BlockingOptions extends Component {

    state = {
        default: true,
        blocked: false,
        unblocked: false
    }

    constructor(props) {
        super(props);
    }

    setBlockingStatus = () => {
        if (this.props.selectedUserId) {
            canReceiveMessageFrom(this.props.selectedUserId).then((res) => {
                if (res.default) {
                    this.setState({
                        default: res.default,
                        unblocked: false,
                        blocked: false
                    })
                } else {
                    if (res.canReceiveMessage) {
                        this.setState({
                            default: res.default,
                            unblocked: true,
                            blocked: false,
                        })
                    } else {
                        this.setState({
                            default: res.default,
                            unblocked: false,
                            blocked: true,
                        })
                    }
                }
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    componentDidMount() {
        this.setBlockingStatus();
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedUserId !== this.props.selectedUserId) {
            this.setBlockingStatus();
        }
    }

    onBlockedClicked = () => {
        if (!this.state.blocked) {
            blockUserFromChat(this.props.selectedUserId).then((res) => {
                    if (res.senderId === this.props.selectedUserId) {
                        this.setState({
                            default: res.default,
                            unblocked: false,
                            blocked: true,
                        })
                    }
                }
            ).catch((err) => {
                console.log(err);
            });
        }
    }

    onUnblockedClicked = () => {
        if (!this.state.unblocked) {
            ExplicitUnblockUserFromChat(this.props.selectedUserId).then((res) => {
                    if (res.senderId === this.props.selectedUserId) {
                        this.setState({
                            default: res.default,
                            unblocked: true,
                            blocked: false,
                        })
                    }
                }
            ).catch((err) => {
                console.log(err);
            });
        }
    }

    onDefaultClicked = () => {
        if (!this.state.default) {
            resetChatBlockingStatus(this.props.selectedUserId).then((res) => {
                    if (res.senderId === this.props.selectedUserId) {
                        this.setState({
                            default: res.default,
                            unblocked: false,
                            blocked: false,
                        })
                    }
                }
            ).catch((err) => {
                console.log(err);
            });
        }
    }

    render() {
        let value = this.state.default ? "1" : (this.state.unblocked ? "2" : "3");

        return <Select class={""}
            options={[
                {text:$$("default"), value:"1"},
                {text:$$("unblocked"), value:"2"},
                {text:$$("blocked"), value:"3"},
                ]}
            onChange={({name, value})=>{
                 switch (value) {
                     case "1": this.onDefaultClicked(); return;
                     case "2": this.onUnblockedClicked(); return;
                     case "3": this.onBlockedClicked(); return;
                 }
            }}
            value={value}
        />
    }
}

BlockingOptions.propTypes = {
    selectedUserId: PropTypes.string,
    hideMedicalHistoryButton: PropTypes.bool
}
