import React, {useMemo, useRef, useState} from 'react'
import {connect} from "react-redux";
import Search from "../shared/Search";
import {fetchHelper} from "../../helpers/request_helper";
import {
    PATIENT_REGISTER_EXISTING_URL,
    PATIENT_UIN_URL,
    PATIENT_UPDATE_CONTACT_INFO_URL
} from "../../constants/api_paths";
import {$$} from "../../helpers/localization";
import {
    clearPatientRegistrationData,
    clearPatients,
    createNewPatient,
    loadPatients
} from "../../actions/clinic_actions";
import CreatePatientRegistrationForm from "./CreatePatientRegistrationForm";
import no_appointments from "../../resources/images/no_appointments.png";
import {infoUtils} from "../../utils/infoUtils";
import {Routes} from "../../constants/routes";
import PatientInfoButton from "../patient-vertical-card/PatientInfoButton";
import {medicalHistoryHelper} from "../../helpers/medical_history_helper";
import {PATIENTS_PATIENT_MED_HISTORY} from "../../constants/pages";
import PropTypes from "prop-types";
import CreateRoundButton from "../shared/CreateRoundButton";
import StaffPicker from "../staff/StaffPicker";
import PaginationComponent from "../common/Pagination";
import UserImageWrap from "../shared/UserImageWrap";
import Email from "../common/Email";
import Phone from "../common/Phone";
import {canEditInfo, getEmail, getFirstContactInfo, getPhone, isChild} from "../../utils/userUtils";
import Modal from "react-bootstrap/Modal";
import {validators} from "../../helpers/validators";
import {Permissions} from "../../utils/permissions/types"


const PAGE_SIZE = 10;

class ManagerPatients extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            filtered_list: [],
            unregistered_user: [],
            selected: this.props.selected,
            page: 0,
            filter: ""
        }

        this.debouncedSearch = _.debounce(() => {
            this.search();
        }, 250, {leading: true});
    }

    componentDidMount() {
        if (!this.state.selected) {
            this.props.loadPatients(this.props.orgId, {page: this.state.page, size: PAGE_SIZE, search: ""})
        }
    }

    search = () => {
        this.props.loadPatients(this.props.orgId, {page: this.state.page, size: PAGE_SIZE, search: this.state.filter})
    };

    componentWillUnmount() {
        this.props.clearPatients()
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.filter !== this.state.filter) {
            this.debouncedSearch();
        }
        if (this.props.users.list !== prevProps.users.list) {
            this.setState({filtered_list: this.props.users.list})
            if (this.state.filter.length === 10 && this.props.users.list.length === 0) {
                fetchHelper.callGet(PATIENT_UIN_URL.replace("{org_uuid}", this.props.orgId).replace("{patient_uin}", this.state.filter))
                    .then(e => {
                        if (e && this.props.permissions.canCreatePatient()) {
                            this.setState({unregistered_user: [e]})
                        } else {
                            this.setState({unregistered_user: []})
                        }
                    });
            } else {
                this.setState({unregistered_user: []})
            }
        }
    }

    onRegisterExisting = () => {
        let url = PATIENT_REGISTER_EXISTING_URL.replace("{org_id}", this.props.orgId).replace("{user_uin}", this.state.unregistered_user[0].user_uin);
        fetchHelper.callPut(url, {}, null)
            // eslint-disable-next-line no-unused-vars
            .then(() => {
                this.setState({unregistered_user: [], filter: "", page: 0}, this.search)
            })
    }

    editContactInfo = (u) => {
        this.setState({showEditContactInfo: true, editContactInfo: u, editContactInfoAdult: !isChild(u)});
    }

    onUpdateContactInfo = async (data) => {
        let url = PATIENT_UPDATE_CONTACT_INFO_URL.replace("{org_id}", this.props.orgId).replace("{user_uin}", data.user.user_uin);
        await fetchHelper.callPut(url, data.contactInfo, {adult: false}, false)
            // eslint-disable-next-line no-unused-vars
            .then(() => {
                this.setState({
                    showEditContactInfo: false,
                    editContactInfo: undefined,
                    editContactInfoError: undefined
                }, this.search)
            }).catch((e) => {
                this.setState({editContactInfoError: e})
            });
    }

    onUpdateContactInfoAdult = async (data) => {
        let url = PATIENT_UPDATE_CONTACT_INFO_URL.replace("{org_id}", this.props.orgId).replace("{user_uin}", data.user.user_uin);
        await fetchHelper.callPut(url, data.contactInfo, {adult: true}, false)
            // eslint-disable-next-line no-unused-vars
            .then(() => {
                this.setState({
                    showEditContactInfo: false,
                    editContactInfo: undefined,
                    editContactInfoError: undefined,
                    editContactInfoAdult: undefined
                }, this.search)
            }).catch((e) => {
                this.setState({editContactInfoError: e})
            });
    }

    onBook = (u) => {
        this.setState({createAppointmentForUser: u})
    }

    render() {
        let noPatients = {
            imgClass: 'no-lab-results-img',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: '',
            src: no_appointments,
            primaryLabel: $$('no_records_found'),
            secondaryLabel: ''
        }

        return <div className="patient-card">
            <div className="patient-card-header"><h2>{$$("patients_management_label")}</h2>
            </div>
            {this.state.createAppointmentForUser &&
                <StaffPicker show={true} canRemove={true} patient={this.state.createAppointmentForUser}
                             onHide={() => {
                                 this.setState({createAppointmentForUser: undefined})
                             }}
                />}
            {this.state.show_reg_form ?
                <CreatePatientRegistrationForm
                    register={this.props.createNewPatient}
                    clearRegistrationData={this.props.clearPatientRegistrationData}
                    onHide={() => {
                        this.setState({show_reg_form: false, preselectedContactInfo: undefined, page: 0}, this.search);
                    }}
                    registration={this.props.registration}
                    orgId={this.props.orgId}
                    onBook={this.onBook}
                    preselectedContactInfo={this.state.preselectedContactInfo}
                    i18n={this.props.i18n}/>
                :
                <div className="patient-card-header">
                    <div className="flex-container space-between-justify">
                        <Search placeholder={$$("search_for_patients_label")} searchValue={this.state.filter}
                                filterUsers={(e) => {
                                    if (e) {
                                        this.setState({filter: e, page: 0});
                                    } else {
                                        this.setState({
                                            unregistered_user: [],
                                            filter: "",
                                            page: 0
                                        });
                                    }
                                }}/>
                        <div>
                            {this.props.permissions.canCreatePatient() && <button className="btn btn-primary" type="button"
                                    onClick={() => this.setState({show_reg_form: true})}>{$$("create_new_patient_btn")}</button>}
                        </div>
                    </div>
                    <div className="patient-table-container mt-3">
                        <div className="w-100 d-table management-patients-table">
                            {this.state.unregistered_user.map(u => {
                                return <div className="patient-table-container" key={u.user_uin}>
                                    <div className="form-group">
                                        <label>{$$('patient_pin')}</label>
                                        <input type="text" className="form-control" value={u.user_uin} readOnly={true}/>
                                    </div>
                                    <div className="form-group">
                                        <label>{$$("fullname_label")}</label>
                                        <input type="text" className="form-control" value={u.fullname} readOnly={true}/>
                                    </div>
                                    <div className="form-group">
                                        <label>{$$("email_label")}</label>
                                        <input type="text" className="form-control" value={getEmail(u, '')}
                                               readOnly={true}/>
                                    </div>
                                    <div className="form-group">
                                        <label>{$$("phone_label")}</label>
                                        <input type="text" className="form-control" value={getPhone(u, '')}
                                               readOnly={true}/>
                                    </div>
                                    <div className="form-group">
                                        <label>{$$("notes")}</label>
                                        <textarea id="register_patient_notes" className="form-control"/>
                                    </div>
                                    <div className="form-group">
                                        {!u.registered_date &&
                                            <div><input className="btn btn-primary" value={$$("register_label")}
                                                        type="button"
                                                        onClick={this.onRegisterExisting}/></div>}
                                    </div>
                                </div>
                            })}
                            {
                                this.state.unregistered_user.length === 0 && this.state.filtered_list.map(u => {
                                    return <div key={u.user_uin} className="d-table-row">
                                        <div className="d-table-cell p-2">
                                            <UserImageWrap userID={u.id}
                                                           show_red_dot={u.has_not_seen_patient_documents}
                                                           classnames="patient-img"/>
                                        </div>
                                        <div className="d-table-cell p-2">
                                            <a href="#" onClick={(e) => e.preventDefault()}>
                                                <h5 onClick={() => {
                                                    //select patient and open patient details page
                                                    medicalHistoryHelper.prepareMedicalHistoryData(PATIENTS_PATIENT_MED_HISTORY, u, null, Routes.MANAGE_PATIENT_PAGE);
                                                }}>
                                                    {u.fullname}
                                                </h5>
                                            </a>
                                            <div
                                                className="medrec-grey-2 small">{$$('patient_pin') + ":" + u.user_uin}</div>

                                        </div>
                                        <div className="d-table-cell p-2">
                                            <div className="d-flex">
                                                <div><Email object={u}/>
                                                    <Phone object={u}/>
                                                    {u.disabled &&
                                                        <div className="text-danger">{$$("account_closed")}</div>}
                                                </div>
                                                {canEditInfo(u) && this.props.permissions.canUpdatePatient() &&
                                                    <a className="ml-2" href="#" title={$$("edit_contact_information_btn")}
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           e.stopPropagation();
                                                           this.editContactInfo(u)
                                                       }}><i className="fa fa-edit"/></a>
                                                }

                                                {canEditInfo(u) && this.props.permissions.canUpdatePatient() &&
                                                    <a title={$$("add_another_child_btn")} className="ml-2" href="#"
                                                       onClick={(e) => {
                                                           e.preventDefault();
                                                           e.stopPropagation();
                                                           this.setState({
                                                               show_reg_form: true,
                                                               preselectedContactInfo: isChild(u) ? getFirstContactInfo(u) : u
                                                           })
                                                       }}>
                                                        <i className="fa fa-plus"/>
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                        <div className="d-table-cell p-2 text-right">
                                            {this.props.permissions.canSeeAppointments() && <PatientInfoButton title={$$('patient_information_label')}
                                                               style={{'marginLeft': '0.625rem'}}
                                                               onClick={() => {
                                                                   //select patient and open patient details page
                                                                   medicalHistoryHelper.prepareMedicalHistoryData(PATIENTS_PATIENT_MED_HISTORY, u, null, Routes.MANAGE_PATIENT_PAGE);
                                                               }}/>}

                                            {!u.disabled && this.props.permissions.canCreateAppointment()
                                                        && <CreateRoundButton title={$$('create_appointment')}
                                                                               style={{'marginLeft': '0.625rem'}}
                                                                               onClick={() => {
                                                                                   //select patient and open patient details page
                                                                                   this.setState({createAppointmentForUser: u})
                                                                               }}>
                                                <i className='fas fa-plus centered-text'/>
                                            </CreateRoundButton>
                                            }
                                        </div>
                                    </div>
                                })
                            }
                            {this.state.filtered_list.length === 0 && this.state.unregistered_user.length === 0 && this.props.users.request.finished &&
                                infoUtils.noData(noPatients)}
                        </div>
                        {
                            this.state.filtered_list?.length > 0 &&
                            <div className="pt-3"><PaginationComponent alwaysShown={false}
                                                                       itemsCount={this.props.users.totalElements}
                                                                       itemsPerPage={PAGE_SIZE}
                                                                       currentPage={this.state.page + 1}
                                                                       setCurrentPage={(p) => {
                                                                           this.setState({page: p - 1}, this.search);
                                                                       }}
                            /></div>
                        }
                    </div>
                    {this.state.showEditContactInfo && !this.state.editContactInfoAdult &&
                        <EditContactInfo user={this.state.editContactInfo}
                                         onCancel={() => this.setState({
                                             showEditContactInfo: false,
                                             editContactInfo: undefined,
                                             editContactInfoError: undefined
                                         })}
                                         onSave={this.onUpdateContactInfo}
                                         saveRes={this.state.editContactInfoError}
                        />}
                    {this.state.showEditContactInfo && this.state.editContactInfoAdult &&
                        <EditContactInfoAdult user={this.state.editContactInfo}
                                              onCancel={() => this.setState({
                                                  showEditContactInfo: false,
                                                  editContactInfo: undefined,
                                                  editContactInfoError: undefined,
                                                  editContactInfoAdult: undefined
                                              })}
                                              onSave={this.onUpdateContactInfoAdult}
                                              saveRes={this.state.editContactInfoError}
                        />}
                </div>
            }
        </div>;
    }
}

ManagerPatients.propTypes = {
    clearPatientRegistrationData: PropTypes.func,
    createNewPatient: PropTypes.func,
    selected: PropTypes.any,
    loadPatients: PropTypes.func,
    clearPatients: PropTypes.func,
    i18n: PropTypes.object,
    registration: PropTypes.object,
    orgId: PropTypes.string,
    org: PropTypes.object,
    users: PropTypes.object,
    permissions: PropTypes.object
};

function mapStateToProps(state) {
    return {
        orgId: state.management.selectedOrganisation.id,
        users: state.users,
        i18n: state.language,
        registration: state.patient_register,
        org: state.management.selectedOrganisation,
        permissions: Permissions.fromOrg(state.userInfo.data.organizations.find(o => o.id === state.management.selectedOrganisation.id))
    }
}

const mapDispatchToProps = {
    loadPatients,
    clearPatients,
    createNewPatient,
    clearPatientRegistrationData
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagerPatients);


function EditContactInfo({user, onCancel, onSave, saveRes}) {
    const ci = useMemo(() => getFirstContactInfo(user), [user]);
    const [errors, setErrors] = useState({});
    const [fullname, setFullname] = useState(ci.fullname);
    const [email, setEmail] = useState(ci.email?.includes('@') ? ci.email : "");
    const [phone, setPhone] = useState(ci.phone || "");
    const [formclass, setFormclass] = useState("");
    const emailInput = useRef(null);
    const phoneInput = useRef(null);

    const onSubmit = (e) => {
        e.preventDefault();
        setFormclass("was-validated");
        const errors = validate()
        if (Object.keys(errors).length) {
            return;
        }

        if (e.target.checkValidity()) {
            onSave({
                user: user,
                contactInfo: {
                    id: ci.id,
                    fullname: fullname,
                    email: email ? email : ci.email,
                    phone: phone
                }
            })
        }
    }

    const validate = () => {
        const errors = {};
        if (email && !validators.validateEmail(email)) {
            errors.email = 'register_form_email_not_correct_message';
        }
        if (!email && !phone) {
            errors.email_or_phone_required = true;
            emailInput.current.setCustomValidity($$("email_or_phone_required"));
            phoneInput.current.setCustomValidity($$("email_or_phone_required"));
        } else if (ci.email?.includes('@') && !email) {
            emailInput.current.setCustomValidity("email_required_message_patient_registration");
            errors.email = "email_required_message_patient_registration";
        } else {
            emailInput.current.setCustomValidity("");
            phoneInput.current.setCustomValidity("");
        }
        setErrors(errors);
        return errors;
    }

    return <Modal show={true}>
        <Modal.Body>
            <form onSubmit={onSubmit} className={formclass} noValidate={true}>
                <p>{$$("contact_information_title")}</p>
                {saveRes?.status > 201 &&
                    <p className="text-danger mt-2 mb-2">{saveRes.status === 403 ? $$("register_form_email_exists_message") : saveRes.message}</p>}
                <div className="form-group">
                    {fullname && <label>{$$('wizard_personal_information_fullname_label')}</label>}
                    <input
                        type="text"
                        className="form-control"
                        value={fullname}
                        placeholder={$$('wizard_personal_information_fullname_label')}
                        name="fullname"
                        onChange={(e) => setFullname(e.target.value)}
                        required
                        onBlur={validate}
                    />
                    <div className="invalid-feedback">
                        {$$('wizard_personal_information_fullname_required_label')}
                    </div>
                </div>
                <div className="form-group">
                    {email && <label>{$$('email_label')}</label>}
                    <input ref={emailInput}
                           type="text"
                           className={errors.email ? "custom-error form-control" : "form-control"}
                           value={email}
                           placeholder={$$('email_label')}
                           name="email"
                           onChange={(e) => setEmail(e.target.value)}
                           onBlur={validate}
                    />
                    <div
                        className={errors.email ? "custom-invalid-feedback" : "invalid-feedback"}>
                        {errors.email ? $$(errors.email) : $$('email_or_phone_required')}
                    </div>
                </div>
                <div className="form-group">
                    {phone && <label>{$$('phone_label')}</label>}
                    <input ref={phoneInput}
                           type="text"
                           className="form-control"
                           value={phone}
                           placeholder={$$('phone_label')}
                           name="phone"
                           onChange={(e) => setPhone(e.target.value)}
                           onBlur={validate}
                    />
                    <div className="invalid-feedback">
                        {$$('email_or_phone_required')}
                    </div>
                </div>
                <div className="form-group">
                    <div className='row flex-space-between register-btn-back-container'>
                        <div className="col-xs-12 col-md-6 mx-auto mt-2">
                            <button type='button' onClick={onCancel}
                                    className='btn btn-secondary register-btn-back'>
                                {$$('cancel_btn')}
                            </button>
                        </div>
                        <div className="col-xs-12 col-md-6 mx-auto mt-2">
                            <button type='submit' className='btn btn-primary full-width'>
                                {$$('update')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
}

function EditContactInfoAdult({user, onCancel, onSave, saveRes}) {
    const [errors, setErrors] = useState({});
    const [fullname, setFullname] = useState(user.fullname);
    const [email, setEmail] = useState(user.email?.includes('@') ? user.email : "");
    const [phone, setPhone] = useState(user.phone);
    const [formclass, setFormclass] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        setFormclass("was-validated");
        const errors = validate()
        if (Object.keys(errors).length) {
            return;
        }

        if (e.target.checkValidity()) {
            onSave({
                user: user,
                contactInfo: {
                    id: user.id,
                    fullname: fullname,
                    email: email,
                    phone: phone
                }
            })
        }
    }

    const validate = () => {
        const errors = {};
        if (email && !validators.validateEmail(email)) {
            errors.email = 'register_form_email_not_correct_message';
        }
        setErrors(errors);
        return errors;
    }

    return <Modal show={true}>
        <Modal.Body>
            <form onSubmit={onSubmit} className={formclass} noValidate={true}>
                <p>{$$("contact_information_title")}</p>
                {saveRes?.status > 201 &&
                    <p className="text-danger mt-2 mb-2">{saveRes.status === 403 ? $$("register_form_email_exists_message") : saveRes.message}</p>}
                <div className="form-group">
                    {fullname && <label>{$$('wizard_personal_information_fullname_label')}</label>}
                    <input
                        type="text"
                        className="form-control"
                        value={fullname}
                        placeholder={$$('wizard_personal_information_fullname_label')}
                        name="fullname"
                        onChange={(e) => setFullname(e.target.value)}
                        required
                    />
                    <div className="invalid-feedback">
                        {$$('wizard_personal_information_fullname_required_label')}
                    </div>
                </div>
                <div className="form-group">
                    {email && <label>{$$('email_label')}</label>}
                    <input
                        type="text"
                        className={errors.email ? "custom-error form-control" : "form-control"}
                        value={email}
                        placeholder={$$('email_label')}
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div
                        className={errors.email ? "custom-invalid-feedback" : "invalid-feedback"}>
                        {errors.email && $$(errors.email)}
                    </div>
                </div>
                <div className="form-group">
                    {phone && <label>{$$('phone_label')}</label>}
                    <input
                        type="text"
                        className="form-control"
                        value={phone}
                        placeholder={$$('phone_label')}
                        name="phone"
                        onChange={(e) => setPhone(e.target.value)}
                        required
                    />
                    <div className="invalid-feedback">
                        {$$('phone_number_required_error')}
                    </div>
                </div>
                <div className="form-group">
                    <div className='row flex-space-between register-btn-back-container'>
                        <div className="col-xs-12 col-md-6 mx-auto mt-2">
                            <button type='button' onClick={onCancel}
                                    className='btn btn-secondary register-btn-back'>
                                {$$('cancel_btn')}
                            </button>
                        </div>
                        <div className="col-xs-12 col-md-6 mx-auto mt-2">
                            <button type='submit' className='btn btn-primary full-width'>
                                {$$('update')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </Modal.Body>
    </Modal>
}
