import {settingsService} from '../service/settings_service'
import {
    CHANGE_PROVIDER_CHAT_SETTINGS_ERROR,
    CHANGE_PROVIDER_CHAT_SETTINGS_SUCCESS,
    CHANGE_PROVIDER_CONFIGURATION_ERROR,
    CHANGE_PROVIDER_CONFIGURATION_SUCCESS,
    CHANGE_USER_SETTINGS_ERROR,
    CHANGE_USER_SETTINGS_SUCCESS,
    CHAT_SETTINGS,
    CONFIGURATION,
    GET_PROVIDER_CHAT_SETTINGS,
    GET_PROVIDER_CHAT_SETTINGS_ERROR,
    GET_PROVIDER_CONFIGURATION,
    GET_PROVIDER_CONFIGURATION_ERROR,
    GET_USER_SETTINGS,
    GET_USER_SETTINGS_ERROR,
    SET_FORM_TO_DISABLED,
    SET_FORM_TO_ENABLED,
    SETTINGS
} from './actions';

/**
 * Get the user settings action, fetch the settings and dispatch action
 *
 * @param {string} targetUserId id of the user to fetch settings for
 * @returns {function} dispatch function
 */
export function getUserSettings(targetUserId) {
    return (dispatch) => {
        settingsService.getUserSettings(targetUserId).then((res) => {
            if (res) {
                dispatch({type: GET_USER_SETTINGS, result: res});
            }
        }).catch((err) => {
            dispatch({type: GET_USER_SETTINGS_ERROR, result: err});
        });
    }
}

/**
 * Update the settings action, call the service and dipatch the correct action
 *
 * @param {string} targetUserId id of the user to fetch settings for
 * @param {object} settings - the settings to save
 * @returns {function} dispatch function
 */
export function updateUserSettings(targetUserId, settings) {
    return (dispatch) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: SETTINGS});
        settingsService.updateUserSettings(targetUserId, settings).then((res) => {
            if (res) {
                dispatch({type: CHANGE_USER_SETTINGS_SUCCESS, result: res});
                dispatch({type: SET_FORM_TO_ENABLED});
            }
        }).catch((err) => {
            dispatch({type: CHANGE_USER_SETTINGS_ERROR, result: err});
            dispatch({type: SET_FORM_TO_ENABLED});
        });
    }
}

/**
 * Get the provider configuration action, fetch the provider configuration  and dispatch action
 *
 * @returns {function} dispatch function
 */
export function fetchConfiguration() {
    return (dispatch) => {
        settingsService.getProviderConfiguration().then((res) => {
            if (res) {
                dispatch({type: GET_PROVIDER_CONFIGURATION, result: res});
            }
        }).catch((err) => {
            dispatch({type: GET_PROVIDER_CONFIGURATION_ERROR, result: err});
        });
    }
}

/**
 * Update the configuration action, call the service and dispatch the correct action
 *
 * @param {object} configuration - the configuration to save
 * @returns {function} dispatch function
 */
export function updateConfiguration( configuration) {
    return (dispatch) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: CONFIGURATION});
        settingsService.updateProviderConfiguration(configuration).then((res) => {
            if (res) {
                dispatch({type: CHANGE_PROVIDER_CONFIGURATION_SUCCESS, result: res});
                dispatch({type: SET_FORM_TO_ENABLED});
            }
        }).catch((err) => {
            dispatch({type: CHANGE_PROVIDER_CONFIGURATION_ERROR, result: err});
            dispatch({type: SET_FORM_TO_ENABLED});
        });
    }
}

/**
 * Get the provider chat settings action, fetch the provider chat settings and dispatch an action
 *
 * @returns {function} dispatch function
 */
export function fetchProviderChatSettings() {
    return (dispatch) => {
        settingsService.getProviderChatSettings().then((res) => {
            if (res) {
                dispatch({type: GET_PROVIDER_CHAT_SETTINGS, result: res});
            }
        }).catch((err) => {
            dispatch({type: GET_PROVIDER_CHAT_SETTINGS_ERROR, result: err});
        });
    }
}

/**
 * Update the chat settings action, call the service and dispatch the correct action
 *
 * @param {object} chatSettings - the configuration to save
 * @returns {function} dispatch function
 */
export function updateProviderChatSettings( chatSettings) {
    return (dispatch) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: CHAT_SETTINGS});
        settingsService.updateProviderChatSettings(chatSettings).then((res) => {
            if (res) {
                dispatch({type: CHANGE_PROVIDER_CHAT_SETTINGS_SUCCESS, result: res});
                dispatch({type: SET_FORM_TO_ENABLED});
            }
        }).catch((err) => {
            dispatch({type: CHANGE_PROVIDER_CHAT_SETTINGS_ERROR, result: err});
            dispatch({type: SET_FORM_TO_ENABLED});
        });
    }
}