import React, {useEffect, useMemo, useState} from "react";
import EditFormHeader from "./EditFormHeader";
import {$$} from "../../helpers/localization";
import {Multiselect} from "multiselect-react-dropdown";
import CenteredModal from "../shared/CenteredModal";
import {connect} from "react-redux";
import {createEncounter, updateEncounter} from "../../actions/users_actions";
import {updateAppointmentStatus} from "../../actions/users_actions";

class EditCreateExaminationForm extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = this.createStateObjectFromEncounter(this.props.encounter);
    }


    onSubmit = (e) => {
        e.preventDefault();
    }

    onCopyFromLastClick = () => {
        let ConcomitantDiseases = [];

        for (let i in this.props.previousCompletedAppointment.concomitant_diseases_codes) {
            ConcomitantDiseases.push({
                value: this.props.previousCompletedAppointment.concomitant_diseases_codes[i],
                text: this.props.previousCompletedAppointment.concomitant_diseases[i]
            })
        }

        ConcomitantDiseases = this.state.ConcomitantDiseasesArray.length === 0 ? ConcomitantDiseases : this.state.ConcomitantDiseasesArray;


        let mainDiag = this.props.previousCompletedAppointment.main_diagnosis_code ? [{
            value: this.props.previousCompletedAppointment.main_diagnosis_code,
            text: this.props.previousCompletedAppointment.main_diagnosis
        }] : [];

        mainDiag = this.state.MainDiagnosisArray.length === 0 ? mainDiag : this.state.MainDiagnosisArray;


        this.setState({
            subjectiveData: this.state.subjectiveData === '' && this.props.previousCompletedAppointment.subjective_data !== null ? this.props.previousCompletedAppointment.subjective_data : this.state.subjectiveData,
            conclusion: this.state.conclusion === "" && this.props.previousCompletedAppointment.conclusion !== null ? this.props.previousCompletedAppointment.conclusion : this.state.conclusion,
            objectiveData: this.state.objectiveData === '' && this.props.previousCompletedAppointment.objective_data !== null ? this.props.previousCompletedAppointment.objective_data : this.state.objectiveData,
            additional_info: this.state.additional_info === '' && this.props.previousCompletedAppointment.additional_info !== null ? this.props.previousCompletedAppointment.additional_info : this.state.additional_info,
            therapy: this.state.therapy === '' && this.props.previousCompletedAppointment.therapy !== null ? this.props.previousCompletedAppointment.therapy : this.state.therapy,
            diseasesCodes: this.state.diseasesCodes === [] && this.props.previousCompletedAppointment.concomitant_diseases_codes !== null ? this.props.previousCompletedAppointment.concomitant_diseases_codes : this.state.diseasesCodes,
            concomitantDiseases: this.state.diseasesCodes === '' && this.props.previousCompletedAppointment.concomitant_diseases !== null ? this.props.previousCompletedAppointment.concomitant_diseases.join(", ") : this.state.diseasesCodes,
            tests: this.state.tests === '' && this.props.previousCompletedAppointment.tests !== null ? this.props.previousCompletedAppointment.tests.join("\n ") : this.state.tests,
            ConcomitantDiseasesArray: ConcomitantDiseases,
            MainDiagnosisArray: mainDiag,
        });
    }

    getIcdOptions(options) {
        if (options) return options.map(o => {
            if (o.label) {
                let text = o.label;
                return {value: o.id, text: text}
            } else return {value: o.id, text: o.label ? o.label : ""}
        });
    }

    onIcdChange = async (e) => {
        let lang = this.props.i18n.lang === "bg" ? this.props.i18n.lang : "en";
        await (this.props.searchIcd(lang, e))
    }

    onSelectDiagnosis = (e) => {
        this.setState({
            MainDiagnosisArray: [e[e.length - 1]], hasNotMainDiagnosis: false
        })
    }

    onRemoveDiagnosis = () => {
        this.setState({
            MainDiagnosisArray: []
        })
        this.props.clearIcd()
    }

    onSelectIcd = (e) => {
        this.setState({
            ConcomitantDiseasesArray: e
        })
    }

    onRemoveIcd = (e) => {
        this.setState({
            ConcomitantDiseasesArray: e
        })
        this.props.clearIcd()
    }

    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    }

    onNoteSpeechChange = (text) => {
        if (this.state.activeInput === 'additional_info') {
            this.setState({
                additional_info: text
            });
        } else if (this.state.activeInput === 'objectiveData') {
            this.setState({
                objectiveData: text
            });
        } else if (this.state.activeInput === 'subjectiveData') {
            this.setState({
                subjectiveData: text
            });
        } else if (this.state.activeInput === 'therapy') {
            this.setState({
                therapy: text
            });
        } else if (this.state.activeInput === 'tests') {
            this.setState({
                tests: text
            });
        }
    };

    onRecognitionStart = () => {
        this.setState({
            recognitionIsOn: true
        })
    }

    onRecognitionStop = () => {
        this.setState({
            recognitionIsOn: false
        })
    }

    handlePreliminaryDiagnosisChanged = () => {
        this.setState({preliminary_diagnosis: !this.state.preliminary_diagnosis});
    }

    onInputFocus = (evt) => {
        let name = evt.target.name
        this.setState({
            activeInput: evt.target.name,
            activeInputText: name === 'additional_info' ? this.state.additional_info : name === 'subjectiveData' ? this.state.subjectiveData : name === 'objectiveData' ? this.state.objectiveData : name === 'therapy' ? this.state.therapy : name === 'tests' ? this.state.tests : ''
        })
    }

    saveEncounter = () => {
        let codes = [];
        for (let i in this.state.ConcomitantDiseasesArray) {
            codes.push(this.state.ConcomitantDiseasesArray[i].value)
        }

        let conditionsLabel = [];
        for (let i in this.state.ConcomitantDiseasesArray) {
            conditionsLabel.push(this.state.ConcomitantDiseasesArray[i].text)
        }

        let encounter = this.state.encounter != null ? this.state.encounter : {};
        encounter.subjective_data = this.state.subjectiveData ? this.state.subjectiveData : "";
        encounter.objective_data = this.state.objectiveData ? this.state.objectiveData : "";
        encounter.preliminary_diagnosis = this.state.preliminary_diagnosis ? this.state.preliminary_diagnosis : false;
        encounter.conclusion = this.state.conclusion ? this.state.conclusion : "";
        encounter.main_diagnosis = this.state.MainDiagnosisArray && this.state.MainDiagnosisArray[0] ? this.state.MainDiagnosisArray[0].text : null;
        encounter.main_diagnosis_code = this.state.MainDiagnosisArray && this.state.MainDiagnosisArray[0] ? this.state.MainDiagnosisArray[0].value : null;
        encounter.therapy = this.state.therapy ? this.state.therapy : "";
        encounter.concomitant_diseases_codes = codes ? codes : "";
        encounter.concomitant_diseases = conditionsLabel ? conditionsLabel : "";
        encounter.additional_info = this.state.additional_info ? this.state.additional_info : "";
        encounter.tests = this.state.tests ? this.state.tests.replace(/\s*\n\s*/g, "\n").replace(/\n\s*$/, "").split("\n") : [];

        if (this.state.encounter) {
            return this.props.updateEncounter(this.props.selectedUser.id, encounter);
        } else {
            encounter.subject = this.props.selectedUser.id;
            return this.props.createEncounter(this.props.selectedUser.id, this.props.appointment.id, encounter);
        }
    }

    saveAndShowComplete = async () => {
        await this.saveEncounter().then(() => {
            const isFree = this.props.appointment.appointment_price.price_cents === 0;
            const canBeCompleted = this.props.appointment.status !== 'COMPLETED' && (isFree || this.props.appointment.payment_status === 'COMPLETED')
            if (canBeCompleted) {
                this.setState({
                    showCompleteAppointment: true,
                })
            } else {
                this.props.onCancel();
            }
        })
    }

    onConfirmClear = () => {
        this.setState({
            ConcomitantDiseasesArray: [],
            MainDiagnosisArray: [],
            preliminary_diagnosis: false,
            objectiveData: '',
            subjectiveData: '',
            therapy: '',
            tests: '',
            conclusion: "",
            additional_info: '',
            clearFormModal: false
        });
        this.props.clearIcd();
    }

    onInputBlur = () => {
        if (this.state.activeInput) {
            this.setState({
                activeInput: undefined
            })
        }
    }

    onHideCompleteAppointmentModal = () => {
        this.setState({
            showCompleteAppointment: false
        });
        this.props.onCancel();
    }

    completeAppointment = () => {
        this.setState({
            showCompleteAppointment: false
        });
        this.props.updateAppointmentStatus(this.props.appointment.id, "COMPLETED").then(() => {
            this.props.onCancel()
        });
    }

    createStateObjectFromEncounter = (enc) => {
        let ConcomitantDiseases = []

        let encounter = enc || {};

        for (let i in encounter.concomitant_diseases_codes) {
            ConcomitantDiseases.push({
                value: encounter.concomitant_diseases_codes[i], text: encounter.concomitant_diseases[i]
            })
        }

        let mainDiag = encounter.main_diagnosis_code ? [{
            value: encounter.main_diagnosis_code, text: encounter.main_diagnosis
        }] : [];

        return {
            formclass: '',
            subjectiveData: encounter.subjective_data || "",
            objectiveData: encounter.objective_data || "",
            additional_info: encounter.additional_info || "",
            preliminary_diagnosis: encounter.preliminary_diagnosis || false,
            clearFormModal: false,
            conclusion: encounter.conclusion || "",
            therapy: encounter.therapy || "",
            tests: encounter.tests ? encounter.tests.join("\n") : "",
            errors: {},
            ConcomitantDiseasesArray: ConcomitantDiseases,
            MainDiagnosisArray: mainDiag,
        }
    }

    componentDidMount() {
        this._isMounted = true;
        window.onclick = e => {
            if (this._isMounted && e.target.name !== 'additional_info' && e.target.name !== 'objectiveData' && e.target.name !== 'subjectiveData' && e.target.name !== 'therapy' && e.target.name !== 'tests') {
                this.onInputBlur()
            }
        }
    }

    componentWillUnmount() {
        this.props.clearIcd();
        this._isMounted = false;
    }

    render() {

        const isTextConsultation = this.props.appointment.text_only;

        return (
            <div className="text-left">
                <form onSubmit={this.onSubmit} className={this.state.formclass}
                      noValidate={true}>
                    <div className="pt-3">
                        <EditFormHeader
                            onClearClick={() => {
                                this.setState({clearFormModal: true})
                            }}
                            previousCompletedAppointment={this.props.previousCompletedAppointment}
                            onCopyClick={this.onCopyFromLastClick}
                            onInputChange={this.onInputChange}
                            onNoteSpeechChange={this.onNoteSpeechChange}
                            i18n={this.props.i18n}
                            activeInput={this.state.activeInput}
                            activeInputText={this.state.activeInputText}
                            onRecognitionStart={this.onRecognitionStart}
                            onRecognitionStop={this.onRecognitionStop}

                            encounter = {this.props.encounter}
                            isNhis = {this.props.isNhis}
                            onCancel = {this.props.onCancel}
                            saveAndShowComplete = {this.saveAndShowComplete}

                        />


                        <FG label={$$('main_diagnosis_label')} value={this.state.MainDiagnosisArray}>
                            <Multiselect
                                style={{
                                    searchBox: {
                                        "display": "flex", "flexWrap": "wrap"
                                    }
                                }}
                                options={this.getIcdOptions(this.props.icdEntries)}
                                onSearch={this.onIcdChange}
                                selectedValues={this.state.MainDiagnosisArray ? this.state.MainDiagnosisArray : []}
                                displayValue="text"
                                placeholder={$$("main_diagnosis_label")}
                                emptyRecordMsg={$$("no_records_found")}
                                avoidHighlightFirstOption={true}
                                onSelect={this.onSelectDiagnosis}
                                onRemove={this.onRemoveDiagnosis}
                                hidePlaceholder={this.state.selectedCode !== ""}
                                id={this.state.hasNotMainDiagnosis ? "_2iA8p44d0WZ-WqRBGcAuEV" : ""}
                            />
                        </FG>

                        <FG label={$$('concomitant_and_diseases_complications_label')}
                            value={this.state.ConcomitantDiseasesArray}>
                            <Multiselect
                                style={{
                                    searchBox: {
                                        "display": "flex", "flexWrap": "wrap"
                                    }
                                }}
                                options={this.getIcdOptions(this.props.icdEntries)}
                                onSearch={this.onIcdChange}
                                selectedValues={this.state.ConcomitantDiseasesArray ? this.state.ConcomitantDiseasesArray : []}
                                displayValue="text"
                                placeholder={$$("concomitant_and_diseases_complications_label")}
                                emptyRecordMsg={$$("no_records_found")}
                                avoidHighlightFirstOption={true}
                                onSelect={this.onSelectIcd}
                                onRemove={this.onRemoveIcd}
                                hidePlaceholder={this.state.selectedCode !== ""}
                            />
                        </FG>

                        <span
                            className="day-picker-popup-line form-check justify-content-start ml-0 pl-0 align-items-center">
                                                            <input className="big-checkbox mr-2" type="checkbox"
                                                                   name="is_preliminary_diagnosis"
                                                                   checked={this.state.preliminary_diagnosis}
                                                                   style={{cursor: "pointer"}}
                                                                   onChange={() => {
                                                                       this.handlePreliminaryDiagnosisChanged(!this.state.preliminary_diagnosis)
                                                                   }}
                                                            />
                                                            <label className="form-check-label"
                                                                   htmlFor="is_preliminary_diagnosis"
                                                                   style={{cursor: "pointer"}}
                                                                   onClick={() => {
                                                                       this.handlePreliminaryDiagnosisChanged(!this.state.preliminary_diagnosis)
                                                                   }}>
                                                                {$$('preliminary_diagnosis')}
                                                            </label>
                                                        </span>

                        <FG label={$$('subjective_data_label')} value={this.state.subjectiveData}>
                        <textarea className="form-control" rows="4"
                                  value={this.state.subjectiveData}
                                  placeholder={$$('subjective_data_label')}
                                  name="subjectiveData" onFocus={this.onInputFocus}
                                  onChange={this.onInputChange}
                                  disabled={this.state.recognitionIsOn && this.state.activeInput === 'subjectiveData'}
                        />
                        </FG>

                        <FG label={$$('objective_data_label')} value={this.state.objectiveData}>
                        <textarea className="form-control" rows="4"
                                  value={this.state.objectiveData}
                                  placeholder={$$('objective_data_label')}
                                  name="objectiveData" onFocus={this.onInputFocus}
                                  onChange={this.onInputChange}
                                  disabled={this.state.recognitionIsOn && this.state.activeInput === 'objectiveData'}
                        />
                        </FG>

                        <FG label={$$('tests_label')} value={this.state.tests}>
                         <textarea className="form-control" rows="4"
                                   value={this.state.tests}
                                   placeholder={$$('tests_label')}
                                   name="tests" onFocus={this.onInputFocus}
                                   onChange={this.onInputChange}
                                   disabled={this.state.recognitionIsOn && this.state.activeInput === 'tests'}/>
                            <small className="form-text text-muted text-left"><span
                                className="fa fa-info-circle"/> {$$("hint_examination_tests")}
                            </small>
                        </FG>

                        <FG label={$$('therapy_label')} value={this.state.therapy}>
                         <textarea rows="4" className="form-control"
                                   value={this.state.therapy}
                                   placeholder={$$('therapy_label')}
                                   name="therapy" onFocus={this.onInputFocus}
                                   onChange={this.onInputChange}
                                   disabled={this.state.recognitionIsOn && this.state.activeInput === 'therapy'}
                         />
                        </FG>

                        <FG label={$$('additional_info')} value={this.state.additional_info}>
                         <textarea rows="4" className="form-control"
                                   value={this.state.additional_info}
                                   placeholder={$$('additional_info')}
                                   name="additional_info" onFocus={this.onInputFocus}
                                   onChange={this.onInputChange}
                                   disabled={this.state.recognitionIsOn && this.state.activeInput === 'additional_info'}/>
                        </FG>

                        <FG label={$$('conclusion')} value={this.state.conclusion}>
                         <textarea rows="4" className="form-control"
                                   value={this.state.conclusion}
                                   placeholder={$$('conclusion')}
                                   name="conclusion" onFocus={this.onInputFocus}
                                   onChange={this.onInputChange}
                                   disabled={this.state.recognitionIsOn && this.state.activeInput === 'conclusion'}/>
                        </FG>

                        {!this.props.encounter?.id &&
                        <p className="mt-3">{$$("save_prescription_prerequisite_description")}</p>}

                        <div className="d-flex justify-content-end mt-2 align-items-center">
                            <button type="button"
                                    className="btn btn-primary ml-2"
                                    onClick={this.saveAndShowComplete}>
                                {$$("save_btn_label")}
                            </button>

                            {(this.props.encounter || this.props.isNhis) &&
                                <button type="button"
                                        className="btn btn-secondary ml-2"
                                        onClick={this.props.onCancel}>
                                    {$$("cancel_btn")}
                                </button>}

                        </div>
                    </div>
                </form>
                <CenteredModal title={$$('clear_form')}
                               show={this.state.clearFormModal}
                               onHide={() => {
                                   this.setState({clearFormModal: false})
                               }}
                               onConfirm={this.onConfirmClear}
                               confirmBtnLabel={$$("clear_form")}
                               confirmBtnClass="danger">
                    {$$("confirm_clear_warning")}
                </CenteredModal>
                <CompleteAppointmentModal encounter={this.props.encounter}
                                          show={this.state.showCompleteAppointment}
                                          onHide={this.onHideCompleteAppointmentModal}
                                          fromSave={true}
                                          isTextConsultation={isTextConsultation}
                                          onComplete={this.completeAppointment} />
            </div>

        )
    }
}

function FG({label, value, children}) {
    const showLabel = useMemo(() => {
        if (Array.isArray(value)) {
            return value.length > 0;
        } else return !!value;
    }, [value])

    return <div className="form-group form-group-sm">
        {showLabel && <label className="mb-0 ml-3 encounter-label-color">{label}</label>}
        {children}
    </div>
}

const mapDispatchToProps = {
    createEncounter,
    updateEncounter,
    updateAppointmentStatus
}

function mapStateToProps(state) {
    return {
        selectedUser: state.selectedUser.data,
        loggedInUser: state.userInfo.data,
        icdEntries: state.icd.entries,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCreateExaminationForm);


export function CompleteAppointmentModal({show, encounter, isTextConsultation, onHide, onComplete, fromSave}) {
    const [missingFields, setMissingFields] = useState();

    useEffect(() => {
        if (!encounter) return;

        let result = {};
        if (!encounter.subjective_data) {
            result.subjectiveData = 'subjective_data_required_message';
        }
        if (!encounter.main_diagnosis) {
            result.mainDiagnosis = 'diagnosis_required_message';
        }
        if (!encounter.objective_data) {
            result.objectiveData = 'objective_data_required_message';
        }
        if (!encounter.therapy) {
            result.therapy = 'therapy_required_message';
        }

        if (Object.keys(result).length > 0) {
            setMissingFields(result)
        } else {
            setMissingFields(null);
        }

    }, [encounter])


    if (!encounter) {
        return null;
    }

    return <CenteredModal title={$$("complete_appointment_modal_header")}
                          show={show}
                          onHide={onHide}
                          onConfirm={onComplete}
                          confirmBtnLabel={isTextConsultation ? $$('complete_consultation_button_label') : $$('complete_appointment_button_label')}
                          confirmBtnClass="success"
                          cancelBtnLabel={$$("wizard_continue_label")}
    >
        {missingFields && $$('appointment_details_missing_header')}
        {missingFields && <ul>
            {missingFields.mainDiagnosis && <li>{$$("main_diagnosis_label")}</li>}
            {missingFields.subjectiveData && <li>{$$("subjective_data_label")}</li>}
            {missingFields.objectiveData && <li>{$$("objective_data_label")}</li>}
            {missingFields.therapy && <li>{$$("therapy_label")}</li>}
        </ul>}

        {fromSave && <p> {isTextConsultation ? $$('consultation_results_are_saved') : $$('results_are_saved')} </p>}
        <p>{isTextConsultation ? $$('complete_consultation_modal_body') : $$('complete_appointment_modal_body')}</p>
        <p>{isTextConsultation ? $$('consultation_details_missing_question') : $$('appointment_details_missing_question')}</p>
    </CenteredModal>

}