/* eslint-disable */
import React from 'react'
import {CONVERTER} from '../../../utils/converter'
import {$$} from "../../../helpers/localization";

const mapSymptomToLabelKey = {
    "INHALER_IS_USED":"peak_flow_inhaler_label",
    "HAD_ASTHMA_SYMPTOMS":"peak_flow_asthma_symptoms_label",
    "HAD_NIGHT_ASTHMA_SYMPTOMS":"peak_flow_night_asthma_symptoms_label",
    "CANNOT_KEEP_DAILY_ACTIVITIES":"peak_flow_daily_activity_label"
}


export default function PeakFlowEntry(props) {
    return (
        <div className="card logbook-entry-card">
            <div className='row zero-margin-row flex-nowrap'>
                <div className='medrec-grey-2 vitals-logbook-time logbook-entry-time-icon flex-0-0-auto'>
                    <i className='kt-menu__link-icon far fa-clock'/> &nbsp;
                    {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}
                </div>
                &nbsp;&nbsp;
                <div>
                    <div>{props.entry.peak_flow} {$$("liters_per_minute")}</div>
                    {props.entry.peak_flow_symptoms && <div className="font-size-09rem lighter-font">
                        {props.entry.peak_flow_symptoms.split(",").map((s, i)=>{
                            return <div key={i}><i className="fa fa-check font-size-smaller"/> {$$(mapSymptomToLabelKey[s])}</div>
                        })}
                    </div>}
                </div>
            </div>
        </div>
    )
}
