import {useEffect, useRef, useState} from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';

type RoomStateType = 'disconnected' | 'connected' | 'reconnecting';

export default function useRoomState() {
  const { room } = useVideoContext();
  const [state, setState] = useState<RoomStateType>((room?.state as RoomStateType) ?? 'disconnected');
  const ref = useRef(false)
  useEffect(() => {
    ref.current = true;
    if (room) {
      const setRoomState = () => {
        if (ref.current) {
          setState(room.state as RoomStateType);
        }
      }
      setRoomState();
      room
        .on('disconnected', setRoomState)
        .on('reconnected', setRoomState)
        .on('reconnecting', setRoomState);
      return () => {
        ref.current = false;
        room
          .off('disconnected', setRoomState)
          .off('reconnected', setRoomState)
          .off('reconnecting', setRoomState);
      };
    }
  }, [room]);

  return state;
}
