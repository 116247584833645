import React, {Component} from 'react'
import {Button} from "react-bootstrap";
import {$$} from "../../helpers/localization";
import Mexp from "math-expression-evaluator"
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

export default class TestsResultEdit extends Component {


    constructor(props, context) {
        super(props, context);
        let values = {};
        let fields = {};
        this.props.template.tests.forEach(test => {
            test.groups.forEach(group=>{
                group.rows.forEach(row=>{
                    row.fields.forEach(field=> {
                        if (field.value && field.type !== 'calculate') {
                            values[field.id] = field.value;
                        } else {
                            let v = { template_id:this.props.template.template_id, encounter_id:this.props.encounter_id, user_id:this.props.patient_id, field_id:field.id };
                            switch (field.type) {
                                case 'number': v.f_value = ""; break;
                                case 'boolean': v.b_value = ""; break;
                                case 'date': v.date_value = ""; break;
                                case 'string': v.s_value = ""; break;
                                case 'calculate': v.f_value = ""; break;
                            }
                            field.value = v;
                            values[field.id] = v;
                        }
                        fields[field.id] = field
                    })
                })
            })
        })
        this.state = {
            values : values,
            fields: fields
        }
        this.calculateValues(values, fields);

    }

    calculateValues = (values, fields) => {
        this.props.template.tests.forEach(test => {
            test.groups.forEach(group=>{
                group.rows.forEach(row=>{
                    row.fields.forEach(field=> {
                        if (field.type === 'calculate') {
                            const array = [...field.formula.matchAll(/_(.+?)_/g)];
                            let formula = field.formula;
                            for (let i = 0; i < array.length; i++) {
                                const arrayElement = array[i];
                                if (arrayElement[1]) {
                                    let value = this.getValue(fields[arrayElement[1]]);
                                    if (value === "") {
                                        value = NaN;
                                    }
                                    formula = formula.replace(arrayElement[0], value)
                                }
                            }
                            let fValue = "";
                            try {
                                fValue = Mexp.eval(formula);
                                if (!isFinite(fValue) || isNaN(fValue)) {
                                    fValue = "";
                                } else {
                                    fValue = round(fValue, 1)
                                }
                            } catch (e) {
                                //nop;
                            }

                            field.value.f_value = fValue;
                        }
                    })
                })
            })
        })
    }

    onSave = () => {
        this.props.template.tests.forEach(test => {
            test.groups.forEach(group=>{
                group.rows.forEach(row=>{
                    row.fields.forEach(field=> {
                        field.value = this.state.values[field.id];
                        if (field.type === 'boolean' && field.value === "") {
                            field.value.b_value = null
                        }
                    })})})});
        this.props.onSave();
    }

    getValue = (field) => {
        let value = this.state.values[field.id];
        switch (field.type) {
            case 'calculate':
            case 'number':
                return value.f_value !== null ? value.f_value : "";
            case 'boolean':
                return value.b_value === null ? "" : value.b_value;
            case 'date':
                return value.date_value ? value.date_value : "";
            case 'string':
                return value.s_value ? value.s_value : "";
            default: return "";
        }
    }

    setValue = (field, val) => {
        let value = this.state.values[field.id];
        switch (field.type) {
            case 'number': value.f_value = val;
                break;
            case 'boolean': value.b_value = val === "" ? null : val;
                break;
            case 'date': value.date_value = val;
                break;
            case 'string': value.s_value = val;
                break;
        }
        this.calculateValues(this.state.values, this.state.fields)
        this.setState({... this.state.values})
    }

    fillRest = (l1, l2) => {
        let r = [];
        for (; l2 < l1; l2 ++) {
            r.push(<td key={`l_${l2}`}></td>)
        }
        return r;
    }

    renderInput = (f) => {
        switch (f.type) {
            case "calculate":
                return <span>{this.getValue(f)}</span>
            case "number": return <input type="text" size={f.size > 0 ? f.size : 4} id={f.id} value={this.getValue(f)} onChange={(e)=>{
                this.setValue(f, e.target.value)
            }}/>
            case "string": return <input type="text" size={f.size > 0 ? f.size : 4} id={f.id} value={this.getValue(f)} onChange={(e)=>{
                this.setValue(f, e.target.value)
            }}/>
            case "date": return <input type="date" size={f.size > 0 ? f.size : 12} id={f.id} value={this.getValue(f)} onChange={(e)=>{
                this.setValue(f, e.target.value)
            }}/>
            case "boolean": return <select id={f.id} value={this.getValue(f)} onChange={(e)=>{
                this.setValue(f, e.target.value)
                }}>
                    <option value={""}>{$$("not_selected_value_label")}</option>
                    <option value={true}>{$$("yes_value_label")}</option>
                    <option value={false}>{$$("no_value_label")}</option>
                </select>
        }
    }

    componentDidMount() {
        // eslint-disable-next-line react/no-find-dom-node
        ReactDOM.findDOMNode(this).offsetParent.scrollIntoView(true);
    }

    render() {
        return <div className={"tests_result_edit"}>
            <h4>{this.props.template.name}</h4>
            <table className={"tre_outer_table table table-bordered"}>
                <tbody>
                {
                    this.props.template.tests.map((test, tindex) => {
                        return <tr key={test.id}><td className={"td-wrap"}>
                            {test.name && <div className={"test-name"}>{tindex+1}. {test.name}</div>}
                            <table>
                                <tbody>
                                {test.groups.map((g,gindex) => {
                                    return <React.Fragment key={gindex}>
                                            {gindex === 0 && <tr className={"headers"}>
                                                {test.description_headers.map((dh, i) => {
                                                    return <td key={i}>{dh}</td>
                                                })}
                                                {test.field_headers.map((fh, i) => {
                                                    return <td key={i}>{fh}</td>
                                                })}
                                            </tr>}
                                            {!g.hide_name && <tr className="tr-group-name"><td></td><td className={"group-name"}>{g.name}</td><td></td><td colSpan={test.description_headers.length + test.field_headers.length - 3}></td></tr>}
                                            {g.rows.map(r => {
                                                return <tr className={"td-values"} key={r.id}>
                                                    {r.description?.values.map((value, i) => {
                                                        return <td key={i} className={r.description?.bold ? "medrec-blue-1" : ""}>{value}</td>
                                                    })}
                                                    {r.fields.map(f => {
                                                        return <td key={f.id} className={f.type === 'calculate' ? "calculated-field" : ""}>{this.renderInput(f)}</td>
                                                    })}
                                                    {this.fillRest(test.description_headers.length + test.field_headers.length, r.description.values.length + r.fields.length)}
                                                </tr>
                                            })}
                                    </React.Fragment>
                                })}
                                </tbody>
                            </table>
                                {test.notes && <div className="pt-3 whitespace-pre-wrap">
                                    {test.notes}
                                </div>}
                        </td></tr>
                    })
                }
                </tbody>
            </table>
            <div className={"mt-3"}>
                <Button type={"button"} variant={"primary"} onClick={this.onSave} >{$$("save_btn_label")}</Button>
                <Button type={"button"} variant={"secondary"} className="ml-3" onClick={this.props.onCancel} >{$$("cancel")}</Button>
            </div>
        </div>
    }
}

TestsResultEdit.propTypes = {
    onCancel:  PropTypes.func,
    onSave:  PropTypes.func,
    template:  PropTypes.any,
    encounter_id:  PropTypes.any,
}


function round(value, precision) {
    let multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}