import {
    LOGOUT,
    SET_FORM_TO_DISABLED,
    SET_FORM_TO_ENABLED
} from "../actions/actions";

export function formInteractions(state = {formsDisabled: "", expiry: new Date()}, action) {
    switch (action.type) {
        case SET_FORM_TO_DISABLED: {
            let t = new Date();
            t.setSeconds(t.getSeconds() + 5);
            return {formsDisabled: action.formName, expiry: t}
        }
        case SET_FORM_TO_ENABLED: {
            return {state}
        }
        case LOGOUT: {
            return {state}
        }
        default: {
            return state
        }
    }
}