import React, {Component} from 'react'
import DatePicker from "react-datepicker";
import {$$} from '../../helpers/localization';
import PropTypes from "prop-types";


export default class DateRangePicker extends Component {
    state = {
        startDate: this.props.startDate,
        endDate: this.props.endDate
    }

    constructor(props) {
        super(props);
    }

    /**
     * Check if the props have changed, if so update state.
     *
     * @param {object} prevProps the previous props object
     */
    componentDidUpdate(prevProps) {
        if (this.props.startDate !== prevProps.startDate && this.props.endDate !== prevProps.endDate) {
            this.setState({
                startDate: this.props.startDate,
                endDate: this.props.endDate
            });
        }
    }

    /**
     * Set the start date and trigger the range selection function
     *
     * @param {date} date the selected start date
     */
    setStartDate = (date) => {
        this.setState({
            startDate: date
        }, this.triggerRangeSelection);
    }

    /**
     * Set the end date and trigger the range selection function
     *
     * @param {date} date the selected end date
     */
    setEndDate = (date) => {
        this.setState({
            endDate: date
        }, this.triggerRangeSelection);
    }

    /**
     * Trigger range selected if both dates are set
     */
    triggerRangeSelection = () => {
        // if ((this.state.startDate && this.state.endDate) || (!this.state.startDate && !this.state.endDate)) {
        this.props.onRangeSelected(this.state);
        // }
    }

    render() {
        return (
            <div className="flex-container">
                <div>
                    <DatePicker
                        selected={this.state.startDate}
                        onChange={date => this.setStartDate(date)}
                        selectsStart
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        isClearable
                        dateFormat={this.props.settings.data?.dateFormat ?? "dd/MM/yyyy"}
                        placeholderText={$$('start_date')}
                        className="form-control"
                    />
                </div>
                <div className={"small-margin"}>
                    <DatePicker
                        selected={this.state.endDate}
                        onChange={date => this.setEndDate(date)}
                        selectsEnd
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        minDate={this.state.startDate}
                        isClearable
                        dateFormat={this.props.settings.data?.dateFormat ?? "dd/MM/yyyy"}
                        placeholderText={$$('end_date')}
                        className="form-control"
                    />
                </div>
            </div>
        );
    }
}

DateRangePicker.propTypes = {
    endDate: PropTypes.number,
    onRangeSelected: PropTypes.func,
    settings: PropTypes.any,
    startDate: PropTypes.number
};

