import React, {Component} from 'react'
import {connect} from 'react-redux'
import {$$} from '../../helpers/localization'
import {Routes} from '../../constants/routes'
import {
    fetchLastAppointment,
    updateAppointmentStatus
} from '../../actions/users_actions'
import {infoUtils} from '../../utils/infoUtils'
import {appointmentUtils} from '../../utils/appointmentUtils'
import {medicalHistoryHelper} from '../../helpers/medical_history_helper'
import UserImage from '../shared/UserImage'
import {DASH} from '../../constants/dash'
import no_appointments from '../../resources/images/no_appointments.png'
import {
    DASHBOARD_NEXT_PATIENT_CHAT,
    DASHBOARD_NEXT_PATIENT_MED_HISTORY,
    DASHBOARD_NEXT_PATIENT_VIDEO,
} from '../../constants/pages'
import moment from 'moment'
import _ from 'underscore'
import StatusIcon from "./StatusIcon";
import CancelAppointmentButton from './CancelAppointmentButton'
import OpenAppointmentButton from './OpenAppointmentButton'
import MedicalHistoryButton from '../History/MedicalHistoryButton'
import MessagesButton from '../shared/MessagesButton'
import CenteredModal from '../shared/CenteredModal'
import PropTypes from "prop-types";
import {formatTime, getSelectedDateTimeFormat} from "../../utils/converter";
import isSameDay from "date-fns/isSameDay"
import {getEmail} from "../../utils/userUtils";
import Email from "../common/Email";
import Phone from "../common/Phone";
import {APPOINTMENTS_FILTER, getColorForOption} from "../../constants/select_options";
import CancelAppointmentModal from "../appointment/CancelAppointmentModal";


class NextPatient extends Component {
    state = {
        cancelAppointmentModalOpened: false
    }

    constructor(props) {
        super(props);
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.appointment !== this.props.appointment) {
            if (!_.isEmpty(this.props.appointment) && !appointmentUtils.isGroupEventParent(this.props.appointment)) {
                this.props.fetchLastAppointment(this.props.appointment.participants.find(p=>p.participant_role === 'PATIENT').user_id, moment().valueOf());
            }
        }
    }

    /**
     * Prepares the next patient card, prepares the needed information and returns the content
     *
     * @returns {React.Element} - the card content
     */
    getNextPatientView = () => {
        if (_.isEmpty(this.props.appointment)) {
            let noAppointmentssObj = {
                imgClass: 'no-pending-appointments-img',
                primaryLabelClass: 'no-pending-appointments-primary-label',
                secondaryLabelClass: '',
                src: no_appointments,
                primaryLabel: $$('no_pending_appointments_primary_label_for_today'),
                secondaryLabel: ''
            }
            return <div>{infoUtils.noData(noAppointmentssObj)}
                {this.props.existsNextDatesAppointments && <div className={"text-center"}><div className={"pointer btn-link"} onClick={this.props.goToNext} >{$$("go_to_next_date_with_appointment")}</div></div>}
            </div>;
        } else {
            return this.getNextPatientCard();
        }
    }

    /**
     * Prepares the patient's card to be shown in the dashboard
     *
     * @returns {React.Element} - the card content
     */
    getNextPatientCard = () => {
        moment.locale(this.props.lang)
        if (appointmentUtils.isGroupEventParent(this.props.appointment)) {
            return this.getGroupAppointmentCardContent();
        }

        let dateFormat = getSelectedDateTimeFormat(true);
        let lastAppointmentDate = this.props.lastAppointment &&
        this.props.lastAppointment.data &&
        this.props.lastAppointment.data.length > 0
            ? moment(this.props.lastAppointment.data[0].starts_at).format(dateFormat)
            : '';
        let data = {};
        data.patientImageClass = 'next-patient-img';

        let patient = this.props.appointment.participants.find(p=>p.participant_role == 'PATIENT');
        data.secondRowLabels = [$$('gender_label'), $$('birthday_label'), $$("last_appointment_label")];
        data.secondRowValues = [(patient.gender ? $$(patient.gender.toLowerCase()) : patient.gender), moment(patient.birthday).format(dateFormat), lastAppointmentDate];
        data.firstRowLabels = ["", ""];

        let today = isSameDay(this.props.appointment.starts_at, new Date());
        let s = today ? <div>
                        <div>{`${formatTime(this.props.appointment.starts_at)}, ${$$("today")}`}</div>
                        <div className="">{`${appointmentUtils.appointmentType(this.props.appointment)}, ${appointmentUtils.location(this.props.appointment)}`}</div>
            </div>
            : <div>
                <div>{`${formatTime(this.props.appointment.starts_at)}, ${moment(this.props.appointment.starts_at).format('dddd, MMM Do')}`}</div>
                <div>{`${appointmentUtils.appointmentType(this.props.appointment)}, ${appointmentUtils.location(this.props.appointment)}`}</div>
            </div>
        data.firstRowValues = [s];

        data.healthIssueList = this.props.appointment.health_issues.map((h, idx) => {
            return <div key={idx} className='patient-health-issue-list'>{h.name}</div>
        });

        data.status = this.props.appointment.status;
        data.patient = patient;

        return this.getNextPatientCardContent(data);
    }

    getGroupAppointmentCardContent = () => {
        return <div>
            <div className='row space-around-justify'>
                <div className='col-sm-1 col-md-1 next-patient-img-container'>
                    <div className={"next-patient-img-container-img"}>
                        <UserImage userID={undefined}
                                   classnames={'next-patient-img'}/>
                    </div>
                </div>
                <div className='col-sm-10 col-md-10 zero-padding'>
                    <div className='row space-between-justify zero-margin-row'>
                        <div className={"dropdown-link"}>
                            <div className={"full-name"}>{appointmentUtils.appointmentType(this.props.appointment)} <StatusIcon className="ml-1"
                                                                                             status={this.props.appointment.status}/>
                            </div>
                        </div>
                        <div className='row next-patient-details-cell-container'>
                            {appointmentUtils.canCancel(this.props.appointment) &&
                            <CancelAppointmentButton title={$$('cancel_appointment_label')}
                                                     style={{'marginLeft': '0.625rem'}} onClick={() => {
                                this.setState({cancelAppointmentModalOpened: true});
                            }}/>}
                            { ! appointmentUtils.isAppointmentCanceled(this.props.appointment.status) &&
                            <OpenAppointmentButton title={$$('open_appointment_label')}
                                                   style={{'marginLeft': '0.625rem'}} onClick={() => {
                                medicalHistoryHelper.openGroupAppointmentPage(this.props.appointment);
                            }}/>}
                        </div>
                    </div>
                    <hr/>
                    <div>
                        {this.getNextPatientData([$$("appointment_label"), $$("occupied_seats"), $$("max_number_participants_label")],
                            [moment(this.props.appointment.starts_at).format(getSelectedDateTimeFormat(false)),
                                this.props.appointment.used_slots, this.props.appointment.max_patients])}
                    </div>
                </div>
            </div>
            <CancelAppointmentModal
                appointment={this.props.appointment}
                show={this.state.cancelAppointmentModalOpened}
                onHide={() => {
                    this.setState({cancelAppointmentModalOpened: false})
                }}
                onConfirm={this.cancelAppointment}
            />
        </div>
    }

    /**
     * Cancel appointment on confirmation
     */
    cancelAppointment = (app, note) => {
        this.props.updateAppointmentStatus(this.props.appointment.id, "CANCELED_BY_PROVIDER", note, true);
        this.setState({cancelAppointmentModalOpened: false});
    }

    /**
     * Prepares the contents of the next patient card
     *
     * @param {object} data - the patient's data
     * @returns {React.Element} - the card content
     */
    getNextPatientCardContent = (data) => {
        return <div>
            <div className='row space-around-justify'>
                <div className='col-sm-1 col-md-1 next-patient-img-container'>
                    <div className={"next-patient-img-container-img"}>
                        <UserImage userID={data.patient.user_id}
                                   classnames={data.patientImageClass}/>
                    </div>
                </div>
                <div className='col-sm-10 col-md-10 zero-padding'>
                    <div className='row space-between-justify zero-margin-row'>
                        <div className={"dropdown-link"}>
                            <div className={"full-name"}>{data.patient.fullname} <StatusIcon className="ml-1"
                                                                                                    status={data.status}/>
                            </div>
                            <div
                                className='lighter-font'><Email object={data.patient}/>
                            </div>
                            <div
                                className='lighter-font'><Phone object={data.patient}/>
                            </div>
                        </div>
                        <div className='row next-patient-details-cell-container'>
                            <MessagesButton title={$$('messages_label')} style={{'marginLeft': '0.625rem'}}
                                            onClick={() => {
                                                medicalHistoryHelper.prepareMessagesData(DASHBOARD_NEXT_PATIENT_CHAT, data.patient, Routes.MESSAGES);
                                            }}/>
                            <MedicalHistoryButton title={$$('medical_history')} style={{'marginLeft': '0.625rem'}}
                                                  onClick={() => {
                                                      medicalHistoryHelper.prepareMedicalHistoryData(DASHBOARD_NEXT_PATIENT_MED_HISTORY, data.patient, null, Routes.HISTORY);
                                                      return true;
                                                  }}/>
                            {appointmentUtils.canCancel(this.props.appointment) &&
                            <CancelAppointmentButton title={$$('cancel_appointment_label')}
                                                     style={{'marginLeft': '0.625rem'}} onClick={() => {
                                this.setState({cancelAppointmentModalOpened: true});
                            }}/>}
                            {! appointmentUtils.isAppointmentCanceled(this.props.appointment.status) &&
                            <OpenAppointmentButton title={$$('open_appointment_label')}
                                                   style={{'marginLeft': '0.625rem'}} onClick={() => {
                                                           medicalHistoryHelper.prepareMedicalHistoryData(DASHBOARD_NEXT_PATIENT_VIDEO, data.patient, this.props.appointment, Routes.APPOINTMENT);
                                                       }
                            }/>}
                        </div>
                    </div>
                    <div className={`mt-2`}>
                        {data.firstRowValues.map((t, i) => {
                            return <div className="h4" key={i}>{t}</div>
                        })}
                        <div className="mt-2 h4"
                            style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, this.props.appointment.status)}}>
                            {$$(this.props.appointment.status.toLowerCase())}
                        </div>
                    </div>
                    <hr/>
                    <div>

                        {this.getNextPatientData(data.secondRowLabels, data.secondRowValues)}
                        <div className='row zero-margin-row'>{data.healthIssueList}</div>
                    </div>
                </div>
            </div>
            <CancelAppointmentModal
                appointment={this.props.appointment}
                show={this.state.cancelAppointmentModalOpened}
                onHide={() => {
                    this.setState({cancelAppointmentModalOpened: false})
                }}
                onConfirm={this.cancelAppointment}
            />
        </div>
    }

    /**
     * Prepares the rows filled with patient data
     *
     * @param {Array} labels - the labels to be used
     * @param {Array} values - the values to be displayed
     * @returns {Array} - list of rows filled with patient data
     */
    getNextPatientData = (labels, values) => {
        return <div className='row next-patient-row'>
            {values.map((v, i) => {
                return <div key={i} className='col-sm-4 col-md-4'>
                    <div className='medrec-grey-2 next-patient-row-content'>
                        {labels.find((l, j) => j === i)}</div>
                    <div className={""}>{v !== undefined && v !== '' ? v : DASH}</div>
                </div>
            })}
        </div>
    }

    render() {
        return (
            <div>
                {this.getNextPatientView()}
            </div>
        )
    }
}

NextPatient.propTypes = {
    appointment: PropTypes.object,
    appointments: PropTypes.array,
    chosenDate: PropTypes.any,
    existsNextDatesAppointments: PropTypes.any,
    lang: PropTypes.any,
    clearSelectedAppointment: PropTypes.func,
    fetchLastAppointment: PropTypes.func,
    goToNext: PropTypes.func,
    lastAppointment:  PropTypes.object,
    updateAppointmentStatus: PropTypes.func
}

function mapStateToProps(state) {
    return {
        lastAppointment: state.lastAppointment,
        lang: state.language.selected.lang
    }
}

export default connect(mapStateToProps, {fetchLastAppointment, updateAppointmentStatus})(NextPatient);