import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import LabResultTable from './LabResultTable';
import LabResultMicrobiologyTable from './LabResultMicrobiologyTable';
import {formatUtils} from '../../utils/formatUtils';
import moment from 'moment';
import classNames from 'classnames'
import PropTypes from "prop-types";


export class LabResultEntry extends Component {
    state = {
        expandTable: false,
        result: null,
        items: [],
        microbiologyTableWidth: null
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setItemsInState();
    }

    setItemsInState = () => {
        const result = formatUtils.formatAsJSON(this.props.entry.result);
        let items = [];
        for (let  i = 0; i < result.tests.length; ++i) {
            for (let j = 0; j < result.tests[i].groups.length; ++j) {
                let item = {};
                item.group = result.tests[i].groups[j].label;
                item.tests = result.tests[i].groups[j].tests.map(t => t.label);
                items.push(item);
            }
        }

        for (let i = 0; i < result.microbiology.length; ++i) {
            let item = {};
            item.group = result.microbiology[i].label;
            item.tests = [].concat(result.microbiology[i].test);
            items.push(item);
        }

        this.setState({items: items});
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filters.laboratory !== this.props.filters.laboratory ||
            prevProps.filters.before_date_time !== this.props.filters.before_date_time ||
            prevProps.filters.after_date_time !== this.props.filters.after_date_time) {
            this.setState({expandTable: false, result: null}, function () {
                this.props.resetLabResult();
                this.setItemsInState();
            });
        }

        if (this.props.selectedLabResultId &&
            this.props.entry.id &&
            this.props.selectedLabResultId !== this.props.entry.id &&
            this.state.expandTable) {
            this.setState({expandTable: false});
        }
    }

    componentWillUnmount() {
        this.props.resetLabResult();
    }

    /**
     * Handles click event on a laboratory result
     *
     * @param {object} e the event object
     */
    onLaboratoryResultClick = (e) => {
        e.preventDefault();
        const shouldExpandTable = !this.state.expandTable;
        if (shouldExpandTable) {
            this.setState({expandTable: shouldExpandTable, result: formatUtils.formatAsJSON(this.props.entry.result)});
        } else {
            this.setState({expandTable: shouldExpandTable, result: null});
        }
    }

    /**
     * Returns the laboratory name of the current lab result entry
     *
     * @returns {string} the laboratory name name of the current entry
     */
    getLaboratoryName = () => {
        if (this.props.labs) {
            return this.props.labs.find(l => l.lab === this.props.entry.laboratory).name;
        }
    }

    /**
     * Returns the DOM elements representing the group and the tests of the particular lab result entry
     *
     * @returns {Array} the created DOM elements based on the lab entry
     */
    getGroupsAndTests = () => {
        if (this.state.items) {
            return this.state.items.map((item, index) => {
                return <div key={index}>
                    <b>{formatUtils.format(item.group)}</b>
                    <br/>
                    {item.tests.map((t) => {
                        return <div key={t} className={'non-test-cells'}>
                            <span>{t}</span>
                            <br/>
                        </div>
                    })}
                </div>
            });
        }
    }

    /**
     * Sets the microbiology table width equal to the tests table's width
     *
     * @param {number} width the value of the width in the tests table
     */
    handleTestTableWidthChange = (width) => (
        this.setState({microbiologyTableWidth: width})
    )

    /**
     * Selects a laboratory result
     *
     */
    selectLabResult = () => {
        if (this.props.selectedLabResultId === '' || this.props.selectedLabResultId !== this.props.entry.id) {
            this.props.selectLabResult(this.props.entry.id);
        }
    }

    render() {
        let iconClasses = classNames('kt-menu__link-icon patient-details-icon', {
            'fas fa-chevron-down': !this.state.expandTable,
            'fas fa-chevron-up': this.state.expandTable
        });

        const activeClass = classNames('lab-entry-container', {
            'lab-entry-active': (this.props.selectedLabResultId === this.props.entry.id)
        });

        return (
            <div id='lab-entry' className={activeClass} onClick={this.selectLabResult}>
                <div className={"lab-entry"}>
                    <div className='row'>
                        <div className='col-xs-2 col-md-2 text-center'>
                            <div
                                className='medrec-grey-2 medical-history-entry-label'>{$$('lab_results_date_label')}</div>
                            <div>
                                <b>{moment(this.props.entry.result_date).locale(this.props.i18n.lang).format('D MMM')}</b>
                            </div>
                            <div
                                className='lab-entry-year'>{moment(this.props.entry.result_date).locale(this.props.i18n.lang).format('YYYY')}</div>
                        </div>

                        <div className='col-xs-9 col-md-9'>
                            <div
                                className='medrec-grey-2 medical-history-entry-label'>{$$('lab_results_tests_label')}</div>
                            <div>{this.getGroupsAndTests()}</div>
                            <br/>
                            {this.props.entry.notes && !this.props.entry.notes !== '' &&
                            <div>
                                {$$('notes')}: <i>{formatUtils.format(this.props.entry.notes)}</i>
                                <br/><br/>
                            </div>
                            }
                        </div>

                        <div className='col-xs-1 col-md-1'>
                            <div className='icons border-0 float-right'
                                 onClick={this.onLaboratoryResultClick}>
                                <i className={iconClasses + " centered-text"}/>
                            </div>
                        </div>
                    </div>
                    {this.state.expandTable &&
                    <div>
                        <br/>
                        <LabResultTable resize={this.handleTestTableWidthChange} result={this.state.result}
                                        laboratory={this.props.entry.laboratory}/>
                        <br/>
                        <LabResultMicrobiologyTable width={this.state.microbiologyTableWidth}
                                                    result={this.state.result}/>
                        <br/>
                    </div>
                    }
                    <h6 className='small text-right'>
                        <i>{this.getLaboratoryName()}</i>
                    </h6>
                </div>
                <hr className={"zero-margin-row"}/>
            </div>
        )
    }
}

LabResultEntry.propTypes = {
    entry: PropTypes.object,
    i18n: PropTypes.object,
    fetchSelectedUserHealthIssues: PropTypes.func,
    selectLabResult: PropTypes.func,
    resetLabResult: PropTypes.func,
    filters: PropTypes.object,
    selectedLabResultId: PropTypes.string,
    healthIssues: PropTypes.object,
    index: PropTypes.number,
    isSelected: PropTypes.func,
    labs:  PropTypes.array,
    onLabResultChange: PropTypes.func,
    showDeletedConfirmationModal: PropTypes.func
}

export default LabResultEntry