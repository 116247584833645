import {fetchHelper} from '../helpers/request_helper';
import {parseStringPromise, processors} from 'xml2js'
import {
    CREATE_NHIS_PRESCRIPTION,
    UPDATE_NHIS_PRESCRIPTION,
    DELETE_NHIS_PRESCRIPTION_BY_NRN,
    NHIS_CREATE_PRESCRIPTION,
    NHIS_GET_NOMENCLATURES,
    NHIS_LOGIN,
    NHIS_PREPARE_LOGIN,
    NHIS_PRESCRIPTION_JSON_TO_MED_PLANS,
    NHIS_PRESCRIPTION_PREPARE_FOR_SIGN,
    NHIS_PRESCRIPTION_XML_TO_MED_PLANS,
    CREATE_NHIS_PRESCRIPTION_TEMPLATE,
    LIST_NHIS_PRESCRIPTION_TEMPLATES,
    DELETE_NHIS_PRESCRIPTION_TEMPLATE,
    CREATE_NHIS_MEDICATION_TEMPLATE,
    LIST_NHIS_MEDICATION_TEMPLATES,
    DELETE_NHIS_MEDICATION_TEMPLATE,
    NHIS_PREPARE_TO_CANCEL_PRESCRIPTION,
    NHIS_CANCEL_PRESCRIPTION,
    GET_NHIS_PRESCRIPTION_BY_NRN,
    SIGN_SERVER_URL,
    NHIS_EXAM_PREPARE_FOR_SIGN,
    NHIS_CREATE_EXAMINATION,
    NHIS_EXAM_UPDATE_PREPARE_FOR_SIGN,
    NHIS_UPDATE_EXAMINATION,
    NHIS_ANNUL_EXAM_PREPARE_FOR_SIGN,
    NHIS_ANNUL_EXAMINATION,
    NHIS_MEDICAL_NOTICE_CREATE_PREPARE_FOR_SIGN,
    NHIS_MEDICAL_NOTICE_CANCEL_PREPARE_FOR_SIGN,
    NHIS_CANCEL_MEDICAL_NOTICE,
    NHIS_CREATE_MEDICAL_NOTICE,
    NHIS_SEARCH_PRESCRIPTION_PREPARE_FOR_SIGN,
    NHIS_SEARCH_PRESCRIPTION,
    NHIS_SEARCH_EXAM_PREPARE_FOR_SIGN,
    NHIS_SEARCH_EXAM,
    NHIS_EREFERRAL_CREATE_PREPARE_FOR_SIGN,
    NHIS_CREATE_EREFERRAL,
    NHIS_EREFERRAL_CANCEL_PREPARE_FOR_SIGN,
    NHIS_CANCEL_EREFERRAL,
    NHIS_EREFERRAL_CHECK_PREPARE_FOR_SIGN, NHIS_CHECK_EREFERRAL
} from "../constants/api_paths";
import {$$} from "../helpers/localization";

const websocketUrl = SIGN_SERVER_URL;

export function createNhisMedicationTemplateInDB(template) {
    return fetchHelper.callPost(CREATE_NHIS_MEDICATION_TEMPLATE, template);
}

export function listNhisMedicationTemplates() {
    return fetchHelper.callGet(LIST_NHIS_MEDICATION_TEMPLATES);
}

export function deleteNhisMedicationTemplate(templateId) {
    return fetchHelper.callDelete(DELETE_NHIS_MEDICATION_TEMPLATE.replace('{nhisMedicationTemplateId}', templateId));
}

export function deleteNhisTemplate(templateId) {
    return fetchHelper.callDelete(DELETE_NHIS_PRESCRIPTION_TEMPLATE.replace('{nhisPrescriptionTemplateId}', templateId));
}

export function listNhisTemplates() {
    return fetchHelper.callGet(LIST_NHIS_PRESCRIPTION_TEMPLATES);
}

export function createNhisPrescriptionInDB(prescription) {
    return fetchHelper.callPost(CREATE_NHIS_PRESCRIPTION, prescription);
}

export function updateNhisPrescriptionInDB(prescription) {
    return fetchHelper.callPut(UPDATE_NHIS_PRESCRIPTION, prescription);
}

export function deleteNhisPrescriptionFromDbByNrn(nrn) {
    return fetchHelper.callDelete(DELETE_NHIS_PRESCRIPTION_BY_NRN.replace('{nrn}', nrn));
}

export function getNhisPrescriptionFromDB(nrn) {
    return fetchHelper.callGet(GET_NHIS_PRESCRIPTION_BY_NRN, {nrn:nrn});
}

export function createNhisPrescriptionTemplateInDB(template) {
    return fetchHelper.callPost(CREATE_NHIS_PRESCRIPTION_TEMPLATE, template);
}

export function nhisCreatePrescription(accessToken, signedPrescription) {
    return fetchHelper.callPostText(NHIS_CREATE_PRESCRIPTION, signedPrescription, {"nhisToken": accessToken}).then((res) => {
        return textXmlToJson(res);
    });
}

export function nhisCreateOrUpdateExamination(accessToken, signedExamination, update) {
    return fetchHelper.callPostText(update ? NHIS_UPDATE_EXAMINATION : NHIS_CREATE_EXAMINATION, signedExamination, {"nhisToken": accessToken}).then((res) => {
        return textXmlToJson(res);
    });
}

export function nhisPrescriptionJsonToMedPlans(obj) {
    return fetchHelper.callPost(NHIS_PRESCRIPTION_JSON_TO_MED_PLANS, obj, {timezone: Intl.DateTimeFormat().resolvedOptions().timeZone}).then((res) => {
        return res;
    })
}

export function nhisPrescriptionXmlToMedPlans(obj) {
    return fetchHelper.callPostText(NHIS_PRESCRIPTION_XML_TO_MED_PLANS, obj, false, {"Content-Type": "text/plain"}).then((res) => {
        return res;
    })
}

function textXmlToJson(res) {
    return parseStringPromise(res, {mergeAttrs: true, explicitArray: false}).then(function (result) {
        return result;
    });
}

export function textXmlToJsonStripNamespaces(res) {
    return parseStringPromise(res, {mergeAttrs: true, explicitArray: false, trim:true, tagNameProcessors: [processors.stripPrefix]}).then(function (result) {
        return result;
    });
}

export function nhisSignDocument(doc, onSuccess, onError) {
    return fetchHelper.callPost(NHIS_PRESCRIPTION_PREPARE_FOR_SIGN, doc).then((res) => {
        sendForSign(res, onSuccess, onError)
    });
}

export function nhisSignExam(doc, update, onSuccess, onError) {
    return fetchHelper.callPost(update ? NHIS_EXAM_UPDATE_PREPARE_FOR_SIGN : NHIS_EXAM_PREPARE_FOR_SIGN, doc).then((res) => {
        sendForSign(res, onSuccess, onError)
    });
}

export function nhisSignCreateMedicalNotice(doc, onSuccess, onError) {
    return fetchHelper.callPost(NHIS_MEDICAL_NOTICE_CREATE_PREPARE_FOR_SIGN, doc).then((res) => {
        sendForSign(res, onSuccess, onError)
    });
}

export function nhisSignCancelMedicalNotice(doc, onSuccess, onError) {
    return fetchHelper.callPost(NHIS_MEDICAL_NOTICE_CANCEL_PREPARE_FOR_SIGN, doc).then((res) => {
        sendForSign(res, onSuccess, onError)
    });
}

export function nhisCancelMedicalNotice(accessToken, signedExamination) {
    return fetchHelper.callPostText(NHIS_CANCEL_MEDICAL_NOTICE, signedExamination, {"nhisToken": accessToken}).then((res) => {
        return textXmlToJson(res);
    });
}

export function nhisCreateMedicalNotice(accessToken, signedExamination) {
    return fetchHelper.callPostText(NHIS_CREATE_MEDICAL_NOTICE, signedExamination, {"nhisToken": accessToken}).then((res) => {
        return textXmlToJson(res);
    });
}

export function nhisSignCancelEreferral(doc, onSuccess, onError) {
    return fetchHelper.callPost(NHIS_EREFERRAL_CANCEL_PREPARE_FOR_SIGN, doc).then((res) => {
        sendForSign(res, onSuccess, onError)
    });
}

export function nhisCancelEreferral(accessToken, signedExamination) {
    return fetchHelper.callPostText(NHIS_CANCEL_EREFERRAL, signedExamination, {"nhisToken": accessToken}).then((res) => {
        return textXmlToJson(res);
    });
}

export function nhisSignCheckEreferral(doc, onSuccess, onError) {
    return fetchHelper.callPost(NHIS_EREFERRAL_CHECK_PREPARE_FOR_SIGN, doc).then((res) => {
        sendForSign(res, onSuccess, onError)
    });
}

export function nhisCheckEreferral(accessToken, signedExamination) {
    return fetchHelper.callPostText(NHIS_CHECK_EREFERRAL, signedExamination, {"nhisToken": accessToken});
}

export function nhisSignEReferral(doc, onSuccess, onError) {
    return fetchHelper.callPost(NHIS_EREFERRAL_CREATE_PREPARE_FOR_SIGN, doc).then((res) => {
        sendForSign(res, onSuccess, onError)
    });
}

export function nhisCreateEReferral(accessToken, signedEReferral) {
    return fetchHelper.callPostText(NHIS_CREATE_EREFERRAL, signedEReferral, {"nhisToken": accessToken}).then((res) => {
        return textXmlToJson(res);
    });
}


export function nhisSignAnnulRequest(doc, onSuccess, onError) {
    return fetchHelper.callPost(NHIS_ANNUL_EXAM_PREPARE_FOR_SIGN, doc).then((res) => {
        sendForSign(res, onSuccess, onError)
    });
}

export function nhisAnnulExamination(accessToken, signedExamination) {
    return fetchHelper.callPostText(NHIS_ANNUL_EXAMINATION, signedExamination, {"nhisToken": accessToken}).then((res) => {
        return textXmlToJson(res);
    });
}

export function nhisGetNomenclatures(nomenclatureIds) {
    return fetchHelper.callGet(NHIS_GET_NOMENCLATURES, {nomenclatureIds: nomenclatureIds})
}

export function nhisCancelPrescription(nrn, uin, revokeReason) {
    return fetchHelper.callGet(NHIS_PREPARE_LOGIN)
        .then(loginPrepared => sendForSignPromise(loginPrepared))
        .then(signed => {
            return fetchHelper.callPostText(NHIS_LOGIN, signed, {}, false, {"Content-Type": "text/plain"}).then((res) => {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(res, "text/xml");
                let elements = xmlDoc.getElementsByTagNameNS("https://www.his.bg", "contents");
                let toJson = {};
                if (elements.length > 0) {
                    const element = elements[0];
                    for (let i = 0; i < element.childNodes.length; i++) {
                        let childNode = element.childNodes[i];
                        toJson[childNode.localName] = childNode.attributes["value"].nodeValue;
                    }
                }
                if (toJson.accessToken) {
                    return toJson.accessToken
                } else {
                    let elements = xmlDoc.getElementsByTagName("ProblemDetails");
                    if (elements.length === 0) {
                        elements = xmlDoc.getElementsByTagName("ValidationProblemDetails");
                    }
                    if (elements.length > 0) {
                        let element = elements[0];
                        for (let i = 0; i < element.childNodes.length; i++) {
                            let childNode = element.childNodes[i];
                            toJson[childNode.localName] = childNode.firstChild?.nodeValue;
                        }
                    }
                    throw (toJson);
                }
            });
        })
        .then(accessToken => fetchHelper.callPost(NHIS_PREPARE_TO_CANCEL_PRESCRIPTION, {
            senderId: uin,
            nrnPrescription: nrn,
            revokeReason: revokeReason
        }, {"nhisToken": accessToken})
            .then(cancelPrepared => sendForSignPromise(cancelPrepared))
            .then(data => fetchHelper.callPostText(NHIS_CANCEL_PRESCRIPTION, data, {"nhisToken": accessToken}, false, {"Content-Type": "text/plain"}))
            .then(result => {
                return textXmlToJson(result);;
            })
            .then(jsonResult => {
                let error = getError(jsonResult);
                if (error) {
                    throw error;
                }
            })
        )
}

function getError(jsonResult) {
    let contents = jsonResult["nhis:message"]["nhis:contents"];
    let error = contents["nhis:error"];
    if (error) {
        if (Array.isArray(error)) {
            return error[0]["nhis:reason"]["value"];
        } else {
            return error["nhis:reason"]["value"];
        }
    }
    return null;
}

export function nhisLogin(onLoginSuccess, onLoginError) {
    const onSuccess = (data) => {
        fetchHelper.callPostText(NHIS_LOGIN, data, {}, false, {"Content-Type": "text/plain"}).then((res) => {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(res, "text/xml");
            let elements = xmlDoc.getElementsByTagNameNS("https://www.his.bg", "contents");
            let toJson = {};
            if (elements.length > 0) {
                const element = elements[0];
                for (let i = 0; i < element.childNodes.length; i++) {
                    let childNode = element.childNodes[i];
                    toJson[childNode.localName] = childNode.attributes["value"].nodeValue;
                }
            }
            if (toJson.accessToken) {
                if (onLoginSuccess) {
                    onLoginSuccess(toJson)
                }
            } else {
                let elements = xmlDoc.getElementsByTagName("ProblemDetails");
                if (elements.length === 0) {
                    elements = xmlDoc.getElementsByTagName("ValidationProblemDetails");
                }
                if (elements.length > 0) {
                    let element = elements[0];
                    for (let i = 0; i < element.childNodes.length; i++) {
                        let childNode = element.childNodes[i];
                        toJson[childNode.localName] = childNode.firstChild?.nodeValue;
                    }
                }
                if (onLoginError) {
                    onLoginError(toJson)
                }
            }
        });
    }

    return fetchHelper.callGet(NHIS_PREPARE_LOGIN).then((res) => {
        sendForSign(res, onSuccess, onLoginError)
    });
}

let socket;

function sendForSign(msg, onSuccess, onError) {
    socket = new WebSocket(websocketUrl);
    socket.onmessage = function (m) {
        if (m.data) {
            try {
                onSuccess(m.data);
            } catch (error) {
                onError($$("nhis.service_error"));
            }
        } else {
            onError($$("nhis.sign_error"))
        }
        socket.close();
    };
    socket.onopen = function () {
        socket.send(JSON.stringify({
            "type": "XmlString",
            "xml": msg
        }));
    }
    socket.onclose = function (e) {
        console.log("socket closed", e)
    }
    socket.onerror = function (e) {
        onError($$("nhis.sign_error"))
    }
}

function sendForSignPromise(msg) {
    return new Promise((resolve, reject) => {
        socket = new WebSocket(websocketUrl);
        socket.onmessage = function (m) {
            socket.close();
            if (m.data) {
                resolve(m.data);
            } else {
                reject($$("nhis.sign_error"))
            }
        };
        socket.onopen = function () {
            socket.send(JSON.stringify({
                "type": "XmlString",
                "xml": msg
            }));
        }
        socket.onclose = function (e) {
            console.log("socket closed", e)
        }
        socket.onerror = function (e) {
            reject($$("nhis.sign_error"))
        }
    })
}


export function nhisSignFetchNhisPrescriptions(doc, onSuccess, onError) {
    return fetchHelper.callPost(NHIS_SEARCH_PRESCRIPTION_PREPARE_FOR_SIGN, doc).then((res) => {
        sendForSign(res, onSuccess, onError)
    });
}

export function nhisFetchNhisPrescriptions(accessToken, doc) {
    return fetchHelper.callPostText(NHIS_SEARCH_PRESCRIPTION, doc, {"nhisToken": accessToken}).then((res) => {
        return textXmlToJson(res);
    });
}

export function nhisSignFetchNhisExam(doc, onSuccess, onError) {
    return fetchHelper.callPost(NHIS_SEARCH_EXAM_PREPARE_FOR_SIGN, doc).then((res) => {
        sendForSign(res, onSuccess, onError)
    });
}

export function nhisFetchNhisExam(accessToken, doc) {
    return fetchHelper.callPostText(NHIS_SEARCH_EXAM, doc, {"nhisToken": accessToken}).then((res) => {
        return res
    }).catch(e => {
        throw textXmlToJson(e);
    });
}
