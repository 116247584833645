import {
    FETCH_FIRST_AVAILABLE_ERROR,
    FETCH_FIRST_AVAILABLE_REQUEST_SENT,
    FETCH_FIRST_AVAILABLE_SUCCESS,
    FETCH_PROVIDER_TIMETABLE_ERROR,
    FETCH_PROVIDER_TIMETABLE_REQUEST_SENT,
    FETCH_PROVIDER_TIMETABLE_SUCCESS,
    GET_STAFF_LIST_ERROR,
    GET_STAFF_LIST_SUCCESS,
    LOGOUT,
    MANAGEMENT_CLEAR_DATE_RANGE,
    MANAGEMENT_GET_DATE_RANGE_ERROR,
    MANAGEMENT_GET_DATE_RANGE_SUCCESS,
    REQUEST_SUCCESS,
    SELECT_ORGANISATION_TO_MANAGE,
    REQUEST_ERROR,
    REQUEST_SENT, FETCH_USER_APPOINTMENTS_SUCCESS, FETCH_USER_APPOINTMENTS_ERROR
} from "../actions/actions";
import {requestStatus} from "./requests_reducers";

const initialState = {
    selectedOrganisation: {id: "", name: ""},
    staff: [],
    internalStaff: [],
    selectedClinicianAppointment:[],
    selectedDateRanges: {}
}

export function management(state = initialState, action) {
    switch (action.type) {
        case SELECT_ORGANISATION_TO_MANAGE: {
            return {...state, selectedOrganisation: action.payload};
        }
        case GET_STAFF_LIST_SUCCESS: {
            return {...state, staff: action.payload.filter(a => !a.internal), internalStaff: action.payload.filter(a => a.internal)};
        }
        case GET_STAFF_LIST_ERROR: {
            return {...state, staff: initialState.staff, internalStaff: initialState.internalStaff};
        }
        case MANAGEMENT_GET_DATE_RANGE_SUCCESS: {
            return {...state, selectedDateRanges: {
                    data: action.payload, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}};
        }
        case MANAGEMENT_GET_DATE_RANGE_ERROR: {
            return {...state, selectedDateRanges: initialState.selectedDateRanges};
        }
        case MANAGEMENT_CLEAR_DATE_RANGE: {
            return {...state, selectedDateRanges: initialState.selectedDateRanges};
        }
        case FETCH_USER_APPOINTMENTS_SUCCESS: {
            return {...state, selectedClinicianAppointment: action.result}
        }
        case FETCH_USER_APPOINTMENTS_ERROR: {
            return {...state, selectedClinicianAppointment: initialState.selectedClinicianAppointment};
        }
        case LOGOUT : {
            return {...initialState};

        }
        default: {
            return state
        }
    }
}


const firstHourInitialState = {isLastPage: false, request: requestStatus(undefined, {}), data: {}}

export function firstAvailable(state = firstHourInitialState, action) {
    switch (action.type) {
        case FETCH_FIRST_AVAILABLE_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, data, ...rest} = state;
            return {...rest, data: {}, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case FETCH_FIRST_AVAILABLE_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_FIRST_AVAILABLE_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, data, isLastPage, ...rest} = state;
            return {...rest, data: {}, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case LOGOUT: {
            return {...state, ...firstHourInitialState};
        }
        default: {
            return state;
        }
    }
}

const providerTimetableInitialState = {isLastPage: false, request: requestStatus(undefined, {}), entries: []}

export function providerTimetable(state = providerTimetableInitialState, action) {
    switch (action.type) {
        case FETCH_PROVIDER_TIMETABLE_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, data, ...rest} = state;
            return {...rest, entries: [], request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case FETCH_PROVIDER_TIMETABLE_SUCCESS: {
            return {...state, entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_PROVIDER_TIMETABLE_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {...rest, entries:[], request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case LOGOUT: {
            return {...state, ...providerTimetableInitialState};
        }
        default: {
            return state;
        }
    }
}