import {
    REGISTER_CLEAR,
    REGISTER_ERROR,
    REGISTER_SUCCESS
} from '../actions/actions'

/**
 * Register reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the register
 * @param {action} action the action to execute on the state
 */
export function register(state = {}, action) {
    switch (action.type) {
        case REGISTER_SUCCESS:
        case REGISTER_ERROR: {
            return {...state, response: action.response};
        }
        case REGISTER_CLEAR: {
            // eslint-disable-next-line no-unused-vars
            const {response, ...rest} = state;
            return rest;
        }
        default: {
            return state
        }
    }
}
