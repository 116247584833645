import {
    getPrescriptionDetailsString,
    getPrescriptionInstructions,
    isNhisMedication,
    isNonMedicinalProduct
} from "./medicationUtils";
import {$$, _$$} from "../helpers/localization";
import {testsUtils} from "./testsUtils";
import {appointmentUtils} from "./appointmentUtils";
import {getClinicianNameWithTitle} from "./getClinicianNameWithTitle";
import {getUnitValuefromKey} from "./converter";
import {
    CYCLE,
    DAILY,
    DURATION_FOR_X_DAYS,
    DURATION_ONGOING,
    EVERY_X_DAYS,
    TAKE_EVERY_X_HOURS,
    TAKE_X_TIMES,
    WHEN_NEEDED
} from "../constants/medications_constants";
import {getResolvedText, PATIENT_ID_TYPE} from "../constants/select_options";
import {dateTimeUtils} from "./dateTimeUtils";

export function getFormattedPrescriptions(prescriptions) {
    let res = [];

    let sorted = sortPrescriptions(prescriptions);

    for (let i in sorted) {
        let prescription = sorted[i];
        if (!isNonMedicinalProduct(prescription)) {
            if (isNhisMedication(prescription)) {
                let prescriptionText = prescription.name + ", " + replaceAllNewLines(prescription.notes, "; ");
                res.push(prescriptionText)
            } else {
                let prescriptionText = prescription.name + ", " + getPrescriptionDetailsString(prescription);
                res.push(prescriptionText)
            }
        } else {
            let prescriptionText = $$("nhis.supplement") + ": " + replaceAllNewLines(prescription.notes, "; ").trim();
            res.push(prescriptionText)
        }
    }
    return res
}

export function replaceAllNewLines(text, replacement) {
    return text.replace(/(?:\n\r|\r\n|\r|\n)/g, replacement)
}

export function replaceFirstNewLine(text, replacement) {
    return text.replace(/(?:\n\r|\r\n|\r|\n)/, replacement)
}

function sortPrescriptions(prescriptions) {
    let regular = [];
    let nonMedicinal = [];
    prescriptions.forEach(p => {
        if (isNonMedicinalProduct(p)) {
            nonMedicinal.push(p);
            return;
        }
        regular.push(p);
    });

    regular.sort((a, b) => {
        let result = compareByProp("name")(a,b);
        if (result === 0) {
            result = compareByProp("nhis_prescription_nrn")(a,b);
            if (result === 0) {
                result = compareByProp("nhis_prescription_medication_sequence_id")(a,b);
                if (result === 0) {
                    return compareByProp("nhis_prescription_dosage_instruction_sequence")(a,b);
                }
                return result;
            }
            return result;
        }
        return result;
    })
    nonMedicinal.sort(compareByProp("notes"))
    return [...regular, ...nonMedicinal];
}

const compareByProp = (prop) => {
    return function (a, b) {
        if (a[prop] == null && b[prop] != null) {
            return 1
        }

        if (b[prop] == null && a[prop] != null) {
            return -1
        }

        if (a[prop] < b[prop]) {
            return -1;
        }
        if (a[prop] > b[prop]) {
            return 1;
        }
        return 0;
    }
}

export function prepareReport(reportType, encounter, settings, patientData, organization) {
    let patientDataForReport = {...patientData};
    if (patientDataForReport.patientIDType) {
        patientDataForReport.patientIDType = getResolvedText(PATIENT_ID_TYPE.TYPE_OPTIONS, patientData.patientIDType);
    }
    switch (reportType) {
        case "EncounterOutcome":
            return prepareEncounterOutcomeReport(encounter, settings, patientDataForReport, organization);
        case "Prescription":
            return preparePrescription(encounter, settings, patientDataForReport, organization);
        case "Epicrisis":
            return prepareEpicrisis(encounter, settings, patientDataForReport, organization);
        case "MedicalReport":
            return prepareMedicalReport(encounter, settings, patientDataForReport, organization);
    }
}

export function prepareEncounterOutcomeReport(encounter, settings, patientData, organization) {
    let examDetails = {
        reportTitle: $$("document_type_ambulance_sheet"),
        appointmentDateTime: dateTimeUtils.getFormattedDateWithTime(new Date()),
        datetime: dateTimeUtils.getFormattedDateWithTime(new Date()),
        mainDiagnosis: encounter.main_diagnosis ? encounter.main_diagnosis : "",
        concomitantDiseases: encounter.concomitant_diseases ? encounter.concomitant_diseases : "",
        objectiveData: encounter.objective_data ? encounter.objective_data : "",
        subjectiveData: encounter.subjective_data ? encounter.subjective_data : "",
        therapy: encounter.therapy ? encounter.therapy : "",
        tests: encounter.tests ? encounter.tests : "",
        notes: encounter.additional_info ? encounter.additional_info : "",
        conclusion: encounter.conclusion ? encounter.conclusion : "",
        prescriptions: getFormattedPrescriptions(encounter.prescriptions),
        tests_results: testsUtils.prepareTestResults(encounter.tests_results),
        isPreliminaryDiagnosis: encounter.preliminary_diagnosis || false
    }
    examDetails = {...examDetails, ...patientData, ...prepareOrganizationData(organization), ...prepareClinicianData(encounter)}
    return {examDetails, encounter, providerId: appointmentUtils.getProviderId(encounter)};
}

export function prepareMedicalReport(encounter, settings, patientData, organization) {
    let examDetails = {
        reportTitle: $$("document_type_medical_report"),
        appointmentDateTime: dateTimeUtils.getFormattedDateWithTime(new Date()),
        datetime: dateTimeUtils.getFormattedDateWithTime(new Date()),
        mainDiagnosis: encounter.main_diagnosis ? encounter.main_diagnosis : "",
        concomitantDiseases: encounter.concomitant_diseases ? encounter.concomitant_diseases : "",
        objectiveData: encounter.objective_data ? encounter.objective_data : "",
        subjectiveData: encounter.subjective_data ? encounter.subjective_data : "",
        therapy: encounter.therapy ? encounter.therapy : "",
        tests: encounter.tests ? encounter.tests : "",
        notes: encounter.additional_info ? encounter.additional_info : "",
        conclusion: encounter.conclusion ? encounter.conclusion : "",
        prescriptions: getFormattedPrescriptions(encounter.prescriptions),
        reportSubject: patientData.reportSubject,
        tests_results: testsUtils.prepareTestResults(encounter.tests_results),
        isPreliminaryDiagnosis: encounter.preliminary_diagnosis || false
    }
    examDetails = {...examDetails, ...patientData, ...prepareOrganizationData(organization), ...prepareClinicianData(encounter)}
    return {examDetails, encounter, providerId: appointmentUtils.getProviderId(encounter)};
}

export function prepareEpicrisis(encounter, settings, patientData, organization) {
    let examDetails = {
        reportTitle: $$("document_type_epicrisis"),
        appointmentDateTime: dateTimeUtils.getFormattedDateWithTime(new Date()),
        datetime: dateTimeUtils.getFormattedDateWithTime(new Date()),
        mainDiagnosis: encounter.main_diagnosis ? encounter.main_diagnosis : "",
        concomitantDiseases: encounter.concomitant_diseases ? encounter.concomitant_diseases : "",
        objectiveData: encounter.objective_data ? encounter.objective_data : "",
        subjectiveData: encounter.subjective_data ? encounter.subjective_data : "",
        therapy: encounter.therapy ? encounter.therapy : "",
        tests: encounter.tests ? encounter.tests : "",
        notes: encounter.additional_info ? encounter.additional_info : "",
        conclusion: encounter.conclusion ? encounter.conclusion : "",
        prescriptions: getFormattedPrescriptions(encounter.prescriptions),
        tests_results: testsUtils.prepareTestResults(encounter.tests_results),
        isPreliminaryDiagnosis: encounter.preliminary_diagnosis || false
    }
    examDetails = {...examDetails, ...patientData, ...prepareOrganizationData(organization), ...prepareClinicianData(encounter)}
    return {examDetails, encounter, providerId: appointmentUtils.getProviderId(encounter)};
}

export function preparePrescription(encounter, settings, patientData, organization) {
    let nonmedicinal = encounter.prescriptions.filter(p => isNonMedicinalProduct(p)).map(p => p.notes).join("\n");
    let notes = nonmedicinal ? nonmedicinal : "";
    if (encounter.additional_info) {
        notes = notes ? notes + "\n" + encounter.additional_info : encounter.additional_info;
    }
    let examDetails = {
        reportTitle: $$("document_type_prescription"),
        prescriptionDateTime: dateTimeUtils.getFormattedDateWithTime(new Date()),
        datetime: dateTimeUtils.getFormattedDateWithTime(new Date()),
        notes: notes,
        medications: getMedications(encounter.prescriptions.filter(p => !p.nhis_prescription_nrn))
    }
    examDetails = {...examDetails, ...patientData, ...prepareOrganizationData(organization), ...prepareClinicianData(encounter)}
    return {examDetails, encounter, providerId: appointmentUtils.getProviderId(encounter)};
}

function getMedications(medications) {
    return medications.map(med => {
        return {
            "name": med.name,
            "dose": `${med.dose} ${getUnitValuefromKey(med.unit, med.dose)}`,
            "freq": getMedicationFrequencyString(med),
            "duration": getDuration(med),
            "instructions": getPrescriptionInstructions(med),
            "quantity": getMedicationQuantity(med),
            "notes": med.notes
        }
    })
}

function getMedicationQuantity(med) {
    if (med.quantity) {
        if (med.quantity_by_form) {
            return `${med.quantity} ${med.medication_form}`;
        }
        return `${med.quantity} ${med.quantity > 1 ? $$("packs") : $$("pack")}`;
    }
    return null;
}

function getMedicationFrequencyString(medication) {
    if (medication.scheduleType === WHEN_NEEDED) return $$("when_needed");
    switch (medication.howToTake) {
        case TAKE_X_TIMES:
            return medication.scheduleTimesPerDay === 0 || medication.scheduleTimesPerDay > 1 ?
                _$$('times_per_day_plural_intakes_only', medication.scheduleTimesPerDay) :
                _$$('times_per_day_intakes_only', medication.scheduleTimesPerDay);
        case TAKE_EVERY_X_HOURS:
            return medication.scheduleHours === 0 || medication.scheduleTimesPerDay > 1 ?
                _$$('every_x_hours_plural_intakes_only', medication.scheduleHours) :
                _$$('every_x_hours_intakes_only', medication.scheduleHours);
        default:
            return '';
    }
}

function getMedicationDuration(med) {
    switch (med.durationType) {
        case DURATION_ONGOING:
            return $$('ongoing')
        case DURATION_FOR_X_DAYS:
            return _$$(med.durationInDays > 1 ? 'for_days_until_plural' : 'for_days_until', [med.durationInDays])
    }
}

function getDuration(med) {
    let prescriptionScheduleString = getMedicationScheduleString(med);
    if (prescriptionScheduleString) {
        return `${prescriptionScheduleString.toLowerCase()}, ${getMedicationDuration(med).toLowerCase()}`
    }
    return getMedicationDuration(med).toLowerCase();
}

function getMedicationScheduleString(medication) {
    switch (medication.scheduleType) {
        case DAILY:
            return $$('daily');
        case EVERY_X_DAYS:
            return _$$(medication.scheduleDaysInactive > 0 ? 'every_days_plural' : 'every_days', medication.scheduleDaysInactive + 1);
        case CYCLE:
            let key;
            if (medication.scheduleDaysActive > 1 && medication.scheduleDaysInactive > 1) {
                key = 'take_rest_xy_plural'
            } else if (medication.scheduleDaysActive == 1 && medication.scheduleDaysInactive > 1) {
                key = 'take_rest_y_plural'
            } else if (medication.scheduleDaysActive > 1 && medication.scheduleDaysInactive == 1) {
                key = 'take_rest_x_plural'
            } else {
                key = 'take_rest'
            }
            return _$$(key, medication.scheduleDaysActive, medication.scheduleDaysInactive);
        default:
            return '';
    }
}

export function prepareClinicianData(encounter) {
    let provider = appointmentUtils.getProvider(encounter);
    let providerDetails = {
        clinicianName: getClinicianNameWithTitle(provider),
        clinicianUIN: provider.uin,
    }
    return providerDetails;
}

export function prepareOrganizationData(organization) {
    let orgData = {
        organizationName: organization.name,
    }

    if (organization.address) {
        orgData.organizationAddress = organization.address;
    }

    if (organization.uin) {
        orgData.organizationRegNo = organization.uin
    }

    if (organization.phone_numbers && organization.phone_numbers.length > 0) {
        orgData.organizationPhone = organization.phone_numbers.join(", ");
    }
    return orgData;
}


