import {fetchHelper} from '../helpers/request_helper';
import {USER_MEDICATIONS_URL,
    SEARCH_FOR_MEDICATIONS_URL
} from '../constants/api_paths';

export const medicationsService = {
    fetchSelectedUserMedicationPlan,
    createPrescription,
    searchForMedication
};

/**
 * Get the medication plan for the selected user
 *
 * @param {string} userId id of the user to fetch the medication plan for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserMedicationPlan(userId, params) {
    return fetchHelper.callGet(USER_MEDICATIONS_URL.replace('{userId}', userId), params);
}

/**
 * Get for medication information
 *
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function searchForMedication(params) {
    return fetchHelper.callGet(SEARCH_FOR_MEDICATIONS_URL, params);
}

/**
 * Creates prescription plan entry
 * @param {formData} entry The entry to be created
 */
export function createPrescription(form) {
    // Missing URL THIS IS NOT IMPLEMENTED ON THE BACKEND AND NEEDS TO BE ADDED ONCE IMPLEMENTED
    return fetchHelper.callPost(CREATE_UPDATE_PRESCRIPTION, form, false);
}
