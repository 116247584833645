import React, {Component} from 'react'
import {connect} from 'react-redux'
import Calendar from "../work-time/Calendar";
import HolidayBooking from "../work-time/HolidayBooking";
import SchedulePopup from "../work-time/SchedulePopup";
import EditPopup from "../work-time/EditPopup";
import {Modal} from "react-bootstrap";
import startOfDay from 'date-fns/startOfDay'
import {setUserSelections} from "../../actions/user_selections_actions";
import PropTypes from "prop-types";
import {
    clearDates,
    managementDeleteTimetable,
    managementFetchTimeTable,
    managementPostNewTimetable, managementUpdateTimetable
} from "../../actions/management_actions";
import NewSchedule from "../work-time/NewSchedule";

class StaffWorkTimePage extends Component {
    state = {
        events: [],
        timeTables: {},
        isOpen: false,
        editOpen: false,
        selectedDate: "",
        selectedEvent: null,
        selectedCalendars: [],
        refreshList: false,
        timeOff:false,
        selectedOrganisationName: ''
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.organisationId) {
            let isLoggedInUser = this.props.selectedStaffMember.id === this.props.loggedInUserId;
            let canEditWorktime = isLoggedInUser ? this.props.permissions.canUpdateOwnWorktime() : this.props.permissions.canUpdateOthersWorktime();

            this.props.managementFetchTimeTable(this.props.organisationId, this.props.selectedStaffMember.id)
            let selectedCalendar = [{
                colour: "#f2c84c",
                id: this.props.organisationId,
                name: this.props.organisationName,
                value: true
            }]
            this.setState({selectedCalendars: selectedCalendar, canEditWorktime:canEditWorktime})
        }
    }

    componentWillUnmount() {
        this.props.clearDates();
    }

    newScheduleClick = (timeOff) => {
        this.setState({
            isOpen: true,
            timeTable: {not_available : false},
            timeOff: timeOff,
            selectedDate: startOfDay(new Date())
        });
    }

    // eslint-disable-next-line no-unused-vars
    dayClick = (calEvent, jsEvent, view) => {
        if (!this.state.canEditWorktime) {
            return;
        }
        this.setState({
            isOpen: true,
            editOpen: false,
            selectedDate: startOfDay(new Date(calEvent.format()))
        });
    }

    eventClick = (e) => {
        if (!this.state.canEditWorktime) {
            return;
        }

        let organizationName;
        this.state.selectedCalendars.forEach(element => {
            if (element.id === e.timeTable.organization_id) {
                organizationName = element.name;
            }
        });

        if(e.timeTable.organization_id !== null){
        this.setState({
            editOpen: true,
            isOpen: false,
            timeTable: e.timeTable,
            selectedOrganisationName: organizationName
        });
        }
    }

    setScheduleClick = (form) => {
        if (form) {
            form.organization_id = this.props.organisationId;
            form.provider_id = this.props.selectedStaffMember.id;

            this.props.managementPostNewTimetable(form).then(() => {this.props.managementFetchTimeTable(this.props.organisationId, this.props.selectedStaffMember.id)});
        }
        this.setState({isOpen: false});
    }

    updateScheduleClick = (form) => {
        if (form) {
            form.organization_id = this.props.organisationId;
            form.provider_id = this.props.selectedStaffMember.id;
            this.props.managementUpdateTimetable(form).then(() => {this.props.managementFetchTimeTable(this.props.organisationId, this.props.selectedStaffMember.id)});
        }
        this.closeEditPopup();
    }

    deleteDateRange = (id) => {
        this.props.managementDeleteTimetable(id).then(() => {this.props.managementFetchTimeTable(this.props.organisationId, this.props.selectedStaffMember.id)});
        this.setState({editOpen: false});
    }

    closeEditPopup = () => {
        this.setState({editOpen: false});
    }

    closePopup = () => {
        this.setState({isOpen: false, timeOff: false});
    }

    fetchTimeTable = () => {
        this.props.managementFetchTimeTable(this.props.organisationId, this.props.selectedStaffMember.id);
    }

    editHoliday = (holiday) => {
        this.setState({
            timeTable: holiday,
            editOpen:true
        })
    }

    getWorkTime() {
        return (
            <div className={"patient-card"}>
                <br/>
                <Calendar
                    i18n={this.props.i18n}
                    dayClick={this.dayClick}
                    eventClick={this.eventClick}
                    fetchTimeTable={this.fetchTimeTable}
                    timeTables={this.props.timeTables}
                    selectedCalendars={this.state.selectedCalendars}
                    userSelections={this.props.userSelections}
                    organizations={this.props.organizations}
                    setUserSelections={this.props.setUserSelections}
                    prices = {this.props.selectedStaffMember.prices || []}
                />
                <Modal show={this.state.isOpen} onHide={this.closePopup} dialogClassName="w-100 day-picker-popup"
                       backdrop="static"
                >
                    <Modal.Body>
                        {this.state.isOpen && (
                            <SchedulePopup
                                i18n={this.props.i18n}
                                setScheduleClick={this.setScheduleClick}
                                closePopup={this.closePopup}
                                timeOff = {this.state.timeOff}
                                settings={this.props.settings}
                                selectedDate={this.state.selectedDate}
                                organizations={this.props.organizations}
                                managementView = {true}
                                providerId={this.props.providerId}
                                prices = {this.props.selectedStaffMember.prices || []}
                            />
                        )}
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.editOpen} onHide={this.closeEditPopup} dialogClassName="w-100 day-picker-popup"
                       backdrop="static"
                >
                    <Modal.Body>
                        {this.state.editOpen && (
                            <div>
                            <EditPopup
                                timeTable={this.state.timeTable}
                                deleteEvent={this.deleteDateRange}
                                closeEditPopup={this.closeEditPopup}
                                updateTimetable={this.updateScheduleClick}
                                selectedDate={this.state.selectedDate}
                                organizations={this.props.organizations}
                                providerId={this.props.providerId}
                                settings={this.props.settings}
                                managementView = {true}
                                i18n={this.props.i18n}
                                selectedOrganisationName={this.state.selectedOrganisationName}
                                prices = {this.props.selectedStaffMember.prices || []}
                            />
                            </div>
                        )}
                    </Modal.Body>
                </Modal>
            </div>
        );
    }

    render() {
        return (
            <div className="work-time-page">
                <div className="work-time d-flex flex-container flex-row">
                    <div className="main-col main-col-collapse-on-small">
                        {this.getWorkTime()}
                    </div>
                    <div className="work-time-small">
                        {this.state.canEditWorktime && <NewSchedule
                            i18n={this.props.i18n}
                            newScheduleClick = {this.newScheduleClick}
                        />}
                        <HolidayBooking
                            i18n={this.props.i18n}
                            editHoliday = {this.editHoliday}
                            organizations={this.props.organizations}
                            providerId={this.props.providerId}
                            settings ={this.props.settings}
                            bookTimeOff={this.setScheduleClick}
                            management={true}
                            disableEdit={!this.state.canEditWorktime}
                            timeTables={this.props.timeTables}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        providerId: state.userInfo.data.id,
        settings: state.settings,
        organizations: state.userInfo.data.organizations,
        i18n: state.language,
        providerAppointments: state.providerAppointments,
        timeTables: state.management.selectedDateRanges,
        userSelections: state.userSelections,
    }
}

const mapDispatchToProps = {
    managementFetchTimeTable,
    managementPostNewTimetable,
    managementUpdateTimetable,
    managementDeleteTimetable,
    setUserSelections,
    clearDates
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffWorkTimePage);

StaffWorkTimePage.propTypes = {
    managementDeleteTimetable: PropTypes.func,
    managementFetchTimeTable: PropTypes.func,
    history: PropTypes.object,
    organisationId: PropTypes.string,
    organisationName: PropTypes.string,
    i18n: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    organizations: PropTypes.array,
    managementPostNewTimetable: PropTypes.func,
    providerAppointments: PropTypes.object,
    providerId: PropTypes.string,
    settings: PropTypes.any,
    clearDates: PropTypes.func,
    selectedStaffMember: PropTypes.any,
    setUserSelections: PropTypes.func,
    timeTables: PropTypes.object,
    managementUpdateTimetable: PropTypes.func,
    userSelections: PropTypes.object
}