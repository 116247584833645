import React, {Component} from "react";
import {$$} from "../../helpers/localization";
import PropTypes from "prop-types";



export class DeleteConfirmationModal extends Component {


    constructor(props) {
        super(props);
        this.state = {};

    }

    closeEditPopup = () => {
        this.props.closePopup();
    }


    handleSubmit = () => {
        this.props.delete(this.props.item.id);
        this.props.closePopup();
    }

    render() {
        return (
            <div>
                <h4 className="day-picker-popup-line">
                    {$$('delete_modal_header').replace("{name}", this.props.name)}
                    &nbsp;

                </h4>

                <span className="day-picker-popup-line">
                    {$$('delete_modal_body')}
                    &nbsp;

                </span>


                <span className="day-picker-popup-line-end">
                    <input
                        type="button"
                        className="btn btn-secondary date-picker-button"
                        value={$$("cancel")}
                        onClick={this.closeEditPopup}
                    />
                    <input
                        type="button"
                        className="btn btn-primary date-picker-button"
                        value={$$("delete")}
                        onClick={this.handleSubmit}
                    />
                </span>
            </div>
        )
    }
}

DeleteConfirmationModal.propTypes = {
    closePopup: PropTypes.func,
    delete:  PropTypes.func,
    item: PropTypes.object,
    name: PropTypes.string
}

export default DeleteConfirmationModal;
