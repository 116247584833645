import {
    CHANGE_DOCUMENTS_FILTERS,
    DELETE_LOGGED_USER_DOCUMENT_ERROR,
    DELETE_LOGGED_USER_DOCUMENT_REQUEST_SENT,
    DELETE_LOGGED_USER_DOCUMENT_SUCCESS,
    DUMMY,
    FETCH_LOGGED_USER_DOCUMENTS_ERROR,
    FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT,
    FETCH_LOGGED_USER_DOCUMENTS_SUCCESS,
    FETCH_SELECTED_USER_DOCUMENTS_ERROR,
    FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT,
    FETCH_SELECTED_USER_DOCUMENTS_SUCCESS,
    NO_MORE_LOGGED_USER_DOCUMENTS,
    NO_MORE_SELECTED_USER_DOCUMENTS,
    RESET_LOGGED_USER_DOCUMENTS_SUCCESS,
    RESET_SELECTED_USER_DOCUMENTS_SUCCESS,
    UPLOAD_LOGGED_USER_DOCUMENT_ERROR,
    UPLOAD_LOGGED_USER_DOCUMENT_REQUEST_SENT,
    UPLOAD_LOGGED_USER_DOCUMENT_SUCCESS
} from './actions';
import {documentsService} from '../service/docments_service';
import {DOCUMENT} from '../constants/information-retrieval-types';

/**
 * Fetch the uploaded documents of the selected user, dispatch the appropriate action.
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @param {boolean} resetList - flag, if set to true reset the documents list
 * @returns {function} dispatch function
 */
export function fetchSelectedUserDocuments(userId, params, resetList) {
    return (dispatch, getState) => {
        dispatch({type: FETCH_SELECTED_USER_DOCUMENTS_REQUEST_SENT});
        return documentsService.fetchSelectedUserDocuments(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            res = res.data[DOCUMENT];
            if (res && res.length > 0) {
                if (resetList) {
                    dispatch({type: RESET_SELECTED_USER_DOCUMENTS_SUCCESS, result: res});
                } else {
                    dispatch({type: FETCH_SELECTED_USER_DOCUMENTS_SUCCESS, result: res});
                }
            } else {
                dispatch({type: NO_MORE_SELECTED_USER_DOCUMENTS});
                if (resetList) {
                    dispatch({type: RESET_SELECTED_USER_DOCUMENTS_SUCCESS, result: res});
                }
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_DOCUMENTS_ERROR, result: err});
        })
    }
}

/**
 * Fetch the uploaded documents of the currently logged user, dispatch the appropriate action.
 *
 * @param {object} params - params to send with the request
 * @param {boolean} resetList - boolean value to check if the reset must take place
 * @returns {function} dispatch function
 */
export function fetchLoggedUserDocuments(params, resetList) {
    return (dispatch) => {
        dispatch({type: FETCH_LOGGED_USER_DOCUMENTS_REQUEST_SENT});
        return documentsService.fetchLoggedUserDocuments(params).then((res) => {
            if (res && res.length > 0) {
                if (resetList) {
                    dispatch({type: RESET_LOGGED_USER_DOCUMENTS_SUCCESS, result: res});
                } else {
                    dispatch({type: FETCH_LOGGED_USER_DOCUMENTS_SUCCESS, result: res});
                }
            } else {
                dispatch({type: NO_MORE_LOGGED_USER_DOCUMENTS});
                if (resetList) {
                    dispatch({type: RESET_LOGGED_USER_DOCUMENTS_SUCCESS, result: res});
                }
            }
        }).catch((err) => {
            dispatch({type: FETCH_LOGGED_USER_DOCUMENTS_ERROR, result: err});
        })
    }
}

/**
 * Upload the documents of the current logged user, dispatch the appropriate action.
 *
 * @param {object} file - the file to be uploaded
 * @returns {function} dispatch function
 */
export function uploadLoggedUserDocument(file) {
    return (dispatch) => {
        dispatch({type: UPLOAD_LOGGED_USER_DOCUMENT_REQUEST_SENT});
        return documentsService.uploadLoggedUserDocument(file).then((res) => {
            dispatch({type: UPLOAD_LOGGED_USER_DOCUMENT_SUCCESS, result: res});
            dispatch(fetchLoggedUserDocuments(null, true));
        }).catch((err) => {
            dispatch({type: UPLOAD_LOGGED_USER_DOCUMENT_ERROR, result: err});
        });
    }
}

/**
 * Delete the specified document of the currently logged user, dispatch the appropriate action.
 *
 * @param {object} body - the body of the request
 * @returns {function} dispatch function
 */
export function deleteLoggedUserDocument(body) {
    return (dispatch) => {
        dispatch({type: DELETE_LOGGED_USER_DOCUMENT_REQUEST_SENT});
        return documentsService.deleteLoggedUserDocument(body).then((res) => {
            dispatch({type: DELETE_LOGGED_USER_DOCUMENT_SUCCESS, result: res});
        }).catch((err) => {
            dispatch({type: DELETE_LOGGED_USER_DOCUMENT_ERROR, result: err});
        });
    }
}

/**
 * Change the filters to apply to chart requests.
 *
 * @param {object} filters - the new set of filters
 * @returns {function} dispatch function
 */
export function changeDocumentsFilters(filters) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_DOCUMENTS_FILTERS,
            documentType: filters.type,
            category: filters.category,
            beforeDateTime: filters.beforeDateTime,
            afterDateTime: filters.afterDateTime
        });
    }
}
