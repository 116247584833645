import React, {Component, useEffect, useState} from 'react'
import SubHeader from './SubHeader';
import StatusIcon from "../appointments/StatusIcon";
import moment from 'moment'
import {APPOINTMENTS_FILTER, getColorForOption} from '../../constants/select_options'
import {appointmentUtils} from '../../utils/appointmentUtils'
import {$$} from '../../helpers/localization';
import {formatUtils} from '../../utils/formatUtils'
import {COMPLETED, IN_PROGRESS, INITIAL} from '../../constants/appointment_payment_status'
import {REJECTED} from '../../constants/video_message_status';
import {connect} from 'react-redux'
import CenteredModal from '../shared/CenteredModal';
import {
    clearSelectedAppointment,
    clearSelectedAppointmentInProgress, markAppointmentAsPaid,
    updateAppointmentStatus
} from '../../actions/users_actions'
import {getSelectedDateTimeFormat} from "../../utils/converter";
import history from '../../helpers/history'
import PropTypes from "prop-types";
import {paymentStatusUtils} from "../../utils/paymentStatusUtils";
import CancelAppointmentModal from "../appointment/CancelAppointmentModal";


export class Tabs extends Component {

    state = {
        activeTabIndex: this.props.defaultActiveTabIndex,
        activeSubTabIndex: null,
        acceptAppointmentModalOpened: false,
        paidAppointmentModalOpened: false,
        cancelAppointmentModalOpened: false,
        appointmentToAccept: null,
        appointmentToPaid: null,
        appointmentToCancel: null
    }

    constructor(props) {
        super(props);
    }

    /**
     * Handle tab click, set active tab index to state
     *
     * @param {number} tabIndex the index of the tab to be set to active
     */
    handleTabClick = (tabIndex) => {
        this.setState({
            activeTabIndex: tabIndex ? tabIndex : this.props.defaultActiveTabIndex,
            activeSubTabIndex: null
        });
    }

    /**
     * Handle sub tab click, set active sub tab index to state
     *
     * @param {number} subTabIndex the index of the sub tab to be set to active
     */
    handleSubTabClick = (subTabIndex) => {
        this.setState({
            activeSubTabIndex: subTabIndex ? subTabIndex : this.props.defaultActiveSubTabIndex,
        });
    }

    /**
     * Render tabs, add properties
     *
     * @returns {Array} array of React elements
     */
    renderTabs() {
        return React.Children.map(this.props.children, (child, index) => {
            if (child) {
                return React.cloneElement(child, {
                    onClick: this.handleTabClick,
                    tabIndex: index,
                    isActive: index === this.state.activeTabIndex,
                });
            }
            return '';
        });
    }

    /**
     * Render sub header. If sub header contains tabs,
     * pass the necesary properties to the sub header component(activeSubHeaderIndex is mandatory in the Tabs component).
     * If not filter only SubHeader elements.
     *
     * @returns {Array} array of React elements
     */
    renderSubHeader() {
        const {children} = this.props;
        const {activeTabIndex} = this.state;
        const activeChild = Array.isArray(children) ? children[activeTabIndex] : children;
        const activeSubTabIndex = this.getActiveSubTabIndex(children, activeTabIndex);
        if (activeChild && activeChild.props && activeChild.props.children) {
            const subChild = activeSubTabIndex !== null && activeSubTabIndex !== undefined ? activeChild.props.children : null;
            if (subChild && subChild.props.children && subChild.props.children[activeSubTabIndex]) {
                return React.cloneElement(subChild, {
                    onClick: this.handleSubTabClick,
                    activeSubTabIndex: activeSubTabIndex,
                });
            } else if (activeChild.props.children.length > 0 && activeChild.props.children.some(c => c.type === SubHeader)) {
                return activeChild.props.children.filter(c => c.type === SubHeader);
            }
        }
    }

    /**
     * Render the active tab content. If tab has subtabs render active subtab content.
     *
     * @returns {Array} array of React elements
     */
    renderTabContent() {
        const {children} = this.props;
        const {activeTabIndex} = this.state;
        const activeChild = Array.isArray(children) ? children[activeTabIndex] : children;
        const activeSubTabIndex = this.getActiveSubTabIndex(children, activeTabIndex);
        if (activeChild && activeChild.props.children) {
            if (activeSubTabIndex !== null && activeSubTabIndex !== undefined) {
                return activeChild.props.children.props.children[activeSubTabIndex].props.children;
            } else if (Array.isArray(activeChild.props.children) && activeChild.props.children.length > 0) {
                return activeChild.props.children.filter(c => c.type !== SubHeader);
            } else if (activeChild.props.children.type !== SubHeader) {
                return activeChild.props.children;
            }
        }
    }

    /**
     * Get the active sub tab index if there is any
     *
     * @param {object} children - children of the tabs component
     * @param {number} activeTabIndex - index of the active tab
     * @returns {Array} array of React elements
     */
    getActiveSubTabIndex(children, activeTabIndex) {
        let activeSubTabIndex = null;
        if (this.state.activeSubTabIndex) {
            activeSubTabIndex = this.state.activeSubTabIndex;
        } else if (children[activeTabIndex] && children[activeTabIndex].props) {
            activeSubTabIndex = children[activeTabIndex].props.defaultActiveSubTabIndex;
        }
        return activeSubTabIndex;
    }

    /**
     * Accept appointment on confirmation
     */
    acceptAppointment = () => {
        this.props.updateAppointmentStatus(this.state.appointmentToAccept.id, "ACCEPTED", null);
        this.setState({acceptAppointmentModalOpened: false, appointmentToAccept:null});
    }

    /**
     * Accept appointment on confirmation
     */
    markAppointmentAsPaid = () => {
        this.props.markAppointmentAsPaid(this.state.appointmentToPaid.id);
        this.setState({appointmentToPaid: null, paidAppointmentModalOpened: false});
    }


    /**
     * Cance; appointment on confirmation
     */
    cancelAppointment = (app, note) => {
        this.props.updateAppointmentStatus(this.state.appointmentToCancel.id, "CANCELED_BY_PROVIDER", note)
            .then(()=>{
                if (this.props.clearOnCancel) {
                    clearSelectedAppointment();
                    clearSelectedAppointmentInProgress();
                }
            }).then(()=>{
            this.setState({cancelAppointmentModalOpened: false});
            history.goBack();
        });
    }

    render() {
        return (
            <div>
                <AppointmentHeader lang={this.props.lang} appointment={this.props.appointment}
                                   onAcceptAppointment={(a)=>{
                                       this.setState({
                                           acceptAppointmentModalOpened: true,
                                           appointmentToAccept: a
                                       });
                                   }}
                                   onCancelAppointment={(a) => {
                                       this.setState({
                                           cancelAppointmentModalOpened: true,
                                           appointmentToCancel: a
                                       })
                                   }}
                                   onMarkAsPaid={(a) => {
                                       this.setState({
                                           paidAppointmentModalOpened: true,
                                           appointmentToPaid: a
                                       });
                                   }}
                />
                <ul className="nav nav-tabs nav-tabs-line position-relative mr-2" role="group">
                    {this.renderTabs()}
                    {this.props.appointment.id &&
                    <StatusIcon className="status-icon" status={this.props.appointment.status}/>}
                </ul>
                <div>
                    {this.renderSubHeader()}
                </div>
                <div className="card-body main-card">
                    {this.renderTabContent()}
                </div>
                {this.state.appointmentToPaid &&
                <CenteredModal title={$$('mark_as_paid_appointment_confirmation_header')
                    .replace('{fullname}', this.state.appointmentToPaid.participants.filter(a => a.participant_role === 'PATIENT')[0].fullname)
                    .replace('{date}', moment(this.state.appointmentToPaid.starts_at).format('D MMMM HH:mm'))}
                               show={this.state.paidAppointmentModalOpened}
                               onHide={() => {
                                   this.setState({paidAppointmentModalOpened: false})
                               }}
                               onConfirm={this.markAppointmentAsPaid}
                               confirmBtnLabel={$$('mark_appointment_as_paid_btn')}
                >
                    <p>{$$('mark_as_paid__appointment_confirmation_body')}</p>
                    <p className={"text-danger"}>
                        {$$("irreversible_action")}
                    </p>
                </CenteredModal>}
                {this.state.appointmentToAccept &&
                <CenteredModal title={$$('accept_appointment_confirmation_header')
                    .replace('{fullname}', this.state.appointmentToAccept.participants.filter(a => a.participant_role === 'PATIENT')[0].fullname)
                    .replace('{date}', moment(this.state.appointmentToAccept.starts_at).format('D MMMM HH:mm'))}
                               show={this.state.acceptAppointmentModalOpened}
                               onHide={() => {
                                   this.setState({acceptAppointmentModalOpened: false, appointmentToAccept:null})
                               }}
                               onConfirm={this.acceptAppointment}
                               confirmBtnLabel={$$('accept_appointment_label')}
                >
                    {$$('accept_appointment_confirmation_body')}
                </CenteredModal>}
                {this.state.appointmentToCancel &&
                    <CancelAppointmentModal
                        appointment={this.state.appointmentToCancel}
                        show={this.state.cancelAppointmentModalOpened}
                        onHide={() => {
                            this.setState({cancelAppointmentModalOpened: false})
                        }}
                        onConfirm={this.cancelAppointment}
                    />
                }
            </div>
        )
    }
}

Tabs.propTypes = {
    defaultActiveTabIndex: PropTypes.number,
    updateAppointmentStatus: PropTypes.func,
    clearOnCancel: PropTypes.bool,
    markAppointmentAsPaid: PropTypes.func,
    defaultActiveSubTabIndex: PropTypes.number,
    children: PropTypes.any,
    appointment: PropTypes.object
}

export default connect(null, {updateAppointmentStatus, markAppointmentAsPaid})(Tabs);

function AppointmentHeader({appointment, lang, onMarkAsPaid, onAcceptAppointment, onCancelAppointment}) {
    const [time, setTime] = useState();
    const [name, setName] = useState();
    const [appointmentType, setAppointmentType] = useState();
    const [statusColor, setStatusColor] = useState();
    const [status, setAppointmentStatus] = useState();
    const [price, setPrice] = useState();
    const [paymentStatus, setPaymentStatus] = useState();
    const [paymentStatusStyle, setPaymentStatusStyle] = useState();
    const [canBeMarkedAsPaid, setCanBeMarkedAsPaid] = useState();
    const [canAcceptAppointment, setCanAcceptAppointment] = useState();
    const [canCancelAppointment, setCanCancelAppointment] = useState();

    useEffect(()=>{
        if (!appointment.id) {
            setTime(null);
            setName(null);
            setAppointmentType(null);
            setStatusColor(null);
            setAppointmentStatus(null);
            setPrice(null);
            setPaymentStatus(null);
            setPaymentStatusStyle(null);
            setCanBeMarkedAsPaid(null);
            setCanAcceptAppointment(null);
            setCanCancelAppointment(null);
            return;
        }
        setTime(moment(appointment.starts_at).format(getSelectedDateTimeFormat()));
        setAppointmentType(appointment.text_only ? $$("text_consultation") : appointmentUtils.location(appointment));
        setName(appointmentUtils.getExaminationName(appointment.appointment_price));

        let appointment_status = "";

        switch (appointment.status) {
            case 'WAITING_FOR_DOCTOR_APPROVAL':
            case 'ACCEPTED':
            case 'COMPLETED':
                appointment_status = appointment.status;
        }

        setStatusColor(getColorForOption(APPOINTMENTS_FILTER.COLOR, appointment_status));
        setAppointmentStatus($$(appointment_status.toLowerCase()));
        setPrice(formatUtils.currencyFormat(appointment.appointment_price.price_cents, appointment.appointment_price.currency));

        let paymentStatus = paymentStatusUtils.paymentStatus(appointment)
        setPaymentStatusStyle(paymentStatus.colour);
        setPaymentStatus(paymentStatus);

        setCanBeMarkedAsPaid(
            !appointmentUtils.isAppointmentCanceled(appointment.status)
            && appointment.payment_status !== COMPLETED
            && appointment.appointment_price.location_type === 'ON_SITE'
            && appointment.appointment_price.price_cents > 0
            && appointment.appointment_price.billing_type !== 'ONLINE'
        );


        setCanAcceptAppointment(appointment.status === 'WAITING_FOR_DOCTOR_APPROVAL');
        setCanCancelAppointment(
            appointment.encounter_id == null &&
            (
                appointment.status === 'WAITING_FOR_DOCTOR_APPROVAL' ||
                appointment.status === 'WAITING_FOR_PATIENT_APPROVAL' ||
                appointment.status === 'NEED_MORE_INFO' ||
                appointment.status === 'ACCEPTED'
            )
        );

    }, [appointment, lang])

    if (!appointment || !time) {
        return null;
    }

    return <div className="mr-2 rounded-borders" style={{boxShadow: "rgba(218, 219, 222, 0.17) 0px 0px 32px -3px inset"}}>
        <div className="d-flex">
            <div className="p-2">
                <div className="p-2">
                    <div className='appointments-tab-column-label medrec-grey-2'>{$$("date_label")}</div>
                    <div className='appointments-date-label no-wrap'>
                        {moment(appointment.starts_at).format('D MMM')}
                        <span className="appointments-year-label">&nbsp;
                            {moment(appointment.starts_at).format('YYYY')}
                                    </span>
                    </div>
                    <div className="text-left appointments-year-label kt-font-lg">
                        <i className="flaticon2-time medrec-grey-2"/>
                        {moment(appointment.starts_at).format('LT')}
                    </div>
                </div>
            </div>
            <div style={{borderRight:"1px solid #9BABB6", width:"0", flex:"0 0 auto"}} className="ml-2 mr-2 mt-2 mb-2">
            </div>
            <div className="card-body p-2 mr-2">
                <div className="d-flex space-between-justify">
                    <div className="p-2 flex-fill w-50">
                        <div className="text-left">
                            <div className="appointments-tab-column-label medrec-grey-2">{appointment.text_only ? $$("text_consultation") : $$("appointment_label")}</div>
                            <div className="">&nbsp;&bull;&nbsp;{appointmentType}</div>
                            {appointment.appointment_price?.price_cents > 0 && <div className="">&nbsp;&bull;&nbsp;{price}</div>}
                        </div>
                    </div>
                    <div className="p-2 flex-fill w-50 ml-2">
                        <div className="text-left">
                            <div className="appointments-tab-column-label medrec-grey-2">{$$("status_label")}</div>
                            <div className="patient-age" style={{color: statusColor}}>&nbsp;&bull;&nbsp;{status}
                                <div className={paymentStatusStyle}>&nbsp;&bull;&nbsp;{appointment.appointment_price?.price_cents > 0 ? `${paymentStatus.label}` : $$("free_label")}</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        {(canAcceptAppointment || canCancelAppointment || canBeMarkedAsPaid) &&
            <div className='padded-cell pt-0 w-100 d-flex'>
                <div>
                    {canAcceptAppointment && <button className="btn btn-sm btn-primary mr-2"  onClick={() => {
                        onAcceptAppointment(appointment);
                    }}>{$$('accept_appointment_label')}</button>}
                    {canCancelAppointment && <button className="btn btn-sm btn-danger mr-2"  onClick={() => {
                        onCancelAppointment(appointment);
                    }}>{$$('cancel_btn')}</button>}
                </div>
                {canBeMarkedAsPaid && <button className="btn btn-sm btn-primary ml-auto"
                                              onClick={() => {
                                                  onMarkAsPaid(appointment);
                                              }}>
                    {$$('mark_appointment_as_paid_btn')}
                </button>}
            </div>}
    </div>
}