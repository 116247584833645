import React, {Component} from 'react'
import {connect} from 'react-redux'
import PatientHeader from './PatientHeader'
import Patient from './Patient'
import _ from 'underscore'
import {updateAppointmentStatus} from '../../actions/users_actions'
import PropTypes from "prop-types";
import ChatBox from "../messages/chatBoxWithSelectedUser/ChatBox";
import {
    clearMedProfileRequestState,
    clearPersonalInfoRequestState,
    updatePatientMedicalProfile,
    updatePatientUserInfo
} from "../../actions/clinic_actions";


export class PatientVerticalCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            infoIsActive: true
        }
    }

    handleSelect = (infoIsActiveValue) => {
        this.setState({infoIsActive: infoIsActiveValue});
    }

    patientInfo = () => {
        let medicalProfile = !_.isEmpty(this.props.medicalProfile)
            ? this.props.medicalProfile
            : (this.props.selectedUser &&
            this.props.selectedUser.health_issues &&
            this.props.selectedUser.health_issues.length > 0
                ? this.props.selectedUser.health_issues[0].medicalProfile
                : {});

        if (this.state.infoIsActive) {
            return (
                <div className='vertical-patient-card-body'>
                    <Patient patient={this.props.selectedUser}
                             medicalProfile={medicalProfile}
                             medication={this.props.medication}
                             orgId={this.props.orgId}
                             i18n={this.props.i18n}
                             updateMedicalProfile={this.props.updatePatientMedicalProfile}
                             updateMedicalProfileResult={this.props.updateMedicalProfileResult}
                             updatePatientInfo={this.props.updatePatientUserInfo}
                             updatePatientUserInfoResult={this.props.updatePatientUserInfoResult}
                             clearPersonalInfoRequestState={this.props.clearPersonalInfoRequestState}
                             clearMedProfileRequestState={this.props.clearMedProfileRequestState}
                    />
                </div>
            )
        }
    }
    chat = () => {
        if (!this.state.infoIsActive) {
            let patient = this.props.selectedUser;
            let childName = null;
            if (patient) {
                let parent = patient.parentContactInfos && patient.parentContactInfos[0];
                if (parent) {
                    patient = parent;
                    childName = this.props.selectedUser.fullname;
                }
            }
            return (
                <div className='vertical-patient-card-body'>
                    <ChatBox
                        messagesSmall={true}
                        hideMedicalHistoryButton={true}
                        patient={patient}
                        childName={childName}
                    />
                </div>
            )
        }
    }

    render() {
        if (!this.props.hide) {
            let patientInfo = true;


            return (
                <div className='right-column dashboard-card'>
                    <PatientHeader
                        patientInfo={patientInfo}
                        onSelect={this.handleSelect}
                        appointmentsView={this.props.appointmentsView}
                        appointment={this.props.appointment}
                        selectedUser={this.props.selectedUser}
                        updateAppointmentStatus={this.props.updateAppointmentStatus}
                        cancelAppointment={this.props.cancelAppointment}
                        i18n={this.props.i18n}
                    />
                    {this.patientInfo()}
                    {this.chat()}
                </div>
            )
        } else {
            return null;
        }
    }
}

PatientVerticalCard.propTypes = {
    appointment: PropTypes.object,
    hide: PropTypes.bool,
    i18n: PropTypes.object,
    medicalProfile: PropTypes.object,
    medication: PropTypes.object,
    selectedUser: PropTypes.object,
    appointmentsView: PropTypes.any,
    updateAppointmentStatus: PropTypes.func,
    cancelAppointment: PropTypes.func
}

const mapStateToProps = (state, props) => ({
    selectedUser: props.selectedUser || state.selectedUser.data,
    medicalProfile: state.medicalProfile.data,
    medication: state.medication,
    appointment: props.appointment || state.selectedAppointmentInProgress,
    orgId: state.management.selectedOrganisation?.id,
    updateMedicalProfileResult: state.medicalProfile.request,
    updatePatientUserInfoResult: state.selectedUser.request
});

export default connect(mapStateToProps, {updateAppointmentStatus, updatePatientMedicalProfile, updatePatientUserInfo, clearPersonalInfoRequestState, clearMedProfileRequestState})(PatientVerticalCard)