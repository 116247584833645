/* eslint-disable */
import React, {Component} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faVideo} from '@fortawesome/free-solid-svg-icons'

export class Video extends Component {
    render() {
        return (
            <div className="videocall-contianer">
                <div className={"qb-video-logo"}>
                    <FontAwesomeIcon icon={faVideo} className="fas fa-camera fa-7x"/>
                </div>
            </div>
        )
    }
}

Video.propTypes = {
};

export default Video
