import React, {Component} from 'react'
import '../../_metronic'
import * as classnames from "classnames"
import history from '../../helpers/history'
import PropTypes from "prop-types";
import UserImage from "./UserImage";


export default class UserImageWrap extends Component {
    render() {
        const {show_red_dot, ...rest} = this.props;
        return (
            <div className={classnames({"show-red-dot":this.props.show_red_dot})}>
                <UserImage {...rest} />
            </div>
        )
    }
}

UserImage.propTypes = {
    classnames: PropTypes.string,
    userInfo: PropTypes.object,
};