import React, {Component} from 'react'
import moment from 'moment';
import PropTypes from "prop-types";



class FreeSlotItem extends Component {
    state = {}

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <button
                onClick={
                    () => {
                        this.props.onSelect(this.props.slot)
                    }}
                className="btn slot-item"
                disabled={this.props.slot.status === "TAKEN" && this.props.slot.start !== this.props.originalStart || this.props.currentStart === this.props.slot.start}
            >
                <div className="d-flex">
                    <span>
                        <i className="far fa-clock" style={{color: "#BBCDD9"}}/>
                    </span>
                    <span>
                            {moment(this.props.slot.start).format('HH:mm')}
                    </span>
                </div>
            </button>
        )
    }
}

FreeSlotItem.propTypes = {
    clinician:  PropTypes.object,
    onSelect:  PropTypes.func,
    selectedPrice:  PropTypes.object,
    slot:  PropTypes.object
}

export default FreeSlotItem;
