import React, {createContext, useContext, useEffect, useState} from "react";
import {nhisGetNomenclatures} from "../../../../service/nhis_service";
import {sortByLabel, sortByProp, convertToOptions} from "./utils";

export const NhisMedicalNoticeContext = createContext(null);

const nomenclatureCodes = [
    "CL116",
    "CL117",
    "CL006",
    "CL041",
];


export function NhisMedicalNoticeProvider({children}) {
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const [nomenclatures, setNomenclatures] = useState();
    const [options, setOptions] = useState();

    const [reasonOptions, setReasonOptions] = useState();
    const [locationOptions, setLocationOptions] = useState();
    const [specialtyOptions, setSpecialtyOptions] = useState();
    const [countyOptions, setCountyOptions] = useState();

    useEffect(() => {
        load();
    }, []);

    const load = () => nhisGetNomenclatures(nomenclatureCodes).then((res) => {
        const object = res.reduce(
            (obj, item) => Object.assign(obj, {[item.nomenclatureId]: item}), {});
        setNomenclatures(object);
        const items = Object.getOwnPropertyNames(object);
        const opt = {};
        const toSortbyValue = [];
        const unsorted = [];
        for (const item of items) {
            opt[item] = toSortbyValue.includes(item) ? convertToOptions(object[item], sortByProp("value")) : (unsorted.includes(item) ? convertToOptions(object[item], null) : convertToOptions(object[item], sortByProp("label")));
        }

        setOptions(opt);
        setReasonOptions(opt["CL116"]);
        setLocationOptions(opt["CL117"])
        setSpecialtyOptions(opt["CL006"].filter(o => o.item.meta.role?.includes('Лекари') || o.value === "0000"))
        setCountyOptions(opt["CL041"]);
        setLoading(false);
        setError(false);
    }).catch(e => {
        setError(true);
        setLoading(false);
    });

    const reload = () => {
        setLoading(true);
        load()
    }


    return <NhisMedicalNoticeContext.Provider
        value={{
            nomenclatures,
            options,
            reasonOptions,
            locationOptions,
            specialtyOptions,
            countyOptions,
            isLoading,
            isError,
            reload
        }}
    >{children}</NhisMedicalNoticeContext.Provider>
}

export function useNhisMedicalNoticeContext() {
    const context = useContext(NhisMedicalNoticeContext);
    if (!context) {
        throw new Error('NhisMedicalNoticeContext must be used within a NhisMedicalNoticeProvider');
    }
    return context;
}