import React, {Component} from 'react'
import PropTypes from "prop-types";
import {$$} from "../../../helpers/localization";
import {connect} from "react-redux";
import ExamLinkIcon from "../ExamLinkIcon";
class AddDocumentButton extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return <>
            {this.props.show &&
                <div className="mt-2 pt-2 ml-3 text-right">
                        <div className='medrec-grey-2 text-center'>{$$("provider_documents")}</div>
                        {!this.props.isAdmin && <ExamLinkIcon onClick={this.props.ShowDocumentAddEditModal} labelKey="add_new_document"/>}
                </div>
            }
        </>
    }
}

AddDocumentButton.propTypes = {
    show: PropTypes.bool,
    isAdmin: PropTypes.bool,
    ShowDocumentAddEditModal: PropTypes.func,
}

function mapStateToProps(state) {
    return {
        i18n: state.language.selected,
        settings: state.settings,
        formDisabled: state.formInteractions,
        userInfo: state.userInfo.data
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentButton);
