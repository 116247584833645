import _ from 'underscore'
import {
    FOLLOW_UP_EXAM, OTHER,
    PRIMARY_EXAM
} from '../constants/appointment_types'
import {$$} from '../helpers/localization'
import store from '../store';
import {getLocalizedCountryName} from "../constants/countries";
import moment from "moment";
import {addDays} from 'date-fns';

const FUTURE_DAYS_TO_FETCH = 365;

export const appointmentUtils = {
    markClass,
    appointmentType,
    cityCountryField,
    calculatePayBeforeTimestamp,
    isGroupEventParent,
    isGroupEvent,
    isGroupEventChild,
    isRegular,
    isPriceForGroupEvent,
    isPriceForConsultation,
    isPriceForRegularAppointment,
    getExaminationName,
    location,
    isAppointmentCanceled,
    isCompleted,
    getClassByStatus,
    isOverdueTextConsultation,
    statusText,
    getProvider,
    getProviderId,
    getPatient,
    getPatientId,
    calculateMaxFetchAppointmentsTimestamp,
    isAppointmentActive,
    overridenLocation,
    canCancel,
    FUTURE_DAYS_TO_FETCH
}

/**
 * Returns the class name of the mark
 *
 * @returns {string} the classname
 */
function markClass() {
    return 'dot';
}

/**
 * Returns the appointment type
 *
 * @returns {object} the appointment
 */
function appointmentType(appointment) {
    if (!_.isEmpty(appointment) && _.contains([PRIMARY_EXAM, FOLLOW_UP_EXAM], appointment.appointment_price.encounter_type)) {
        return $$(appointment.appointment_price.encounter_type.toLowerCase())
    } else {
        return appointment.appointment_price.name;
    }
}


/**
 * Returns the appointment location
 *
 * @returns {object} the appointment
 */
function location(appointment) {
    if (appointment.override_location_to_online) {
        return overridenLocation();
    }
    return $$(appointment.appointment_price.location_type.toLowerCase())
}

function overridenLocation() {
    return $$("online");
}


/**
 * Returns the City or Country label based on conditions
 *
 * @returns {object} the patient
 */
function cityCountryField(patient) {
    let result = {};
    if (!_.isEmpty(patient) && patient.city && patient.city !== '') {
        result.label = $$('country_city_label');
        result.value = patient.country ? getLocalizedCountryName(patient.country.toUpperCase(), store.getState().language.selected.lang) + ', ' + patient.city : patient.city;
        return result;
    } else {

        result.label = $$('country_label');
        result.value = patient.country ? patient.country.toUpperCase() : "";
        return result;
    }
}

/**
 * Calculates the timestamp to be set for pay_before_timestamp when creating appointment
 * @param start
 * @param price
 * @return {number}
 */
function calculatePayBeforeTimestamp(start, price) {
    if (price == 0) return 0;
    let startsAt = moment(start);
    let dayBefore = startsAt.add(-1, "day");
    let dayBeforeMinus30 = moment(dayBefore).add(-30, "minute");
    return dayBeforeMinus30.isAfter(new Date(), "minute") ? dayBefore.valueOf() : moment().add(30, "minute").valueOf();
}

function isGroupEventParent(appointment) {
    return isPriceForGroupEvent(appointment.appointment_price) && !appointment.parent_appointment_id;
}

function isGroupEvent(appointment) {
    return isPriceForGroupEvent(appointment.appointment_price)
}

function isGroupEventChild(appointment) {
    return isPriceForGroupEvent(appointment.appointment_price) && appointment.parent_appointment_id;
}

function isRegular(appointment) {
    return isPriceForRegularAppointment(appointment.appointment_price)
}

function isPriceForGroupEvent(encounter_price) {
    return encounter_price.encounter_price_type === 'GROUP_EVENT'
}

function isPriceForConsultation(encounter_price) {
    return encounter_price.encounter_price_type === 'TEXT_ONLY'
}


function isPriceForRegularAppointment(encounter_price) {
    return encounter_price.encounter_price_type === 'REGULAR'
}

function getExaminationName(e) {
    return e.encounter_type === OTHER ? e.name : $$(e.encounter_type.toLowerCase());
}

function isAppointmentCanceled(appointmentStatus) {
    switch (appointmentStatus) {
        case 'CANCELED_RESPONSE_OVERDUE':
        case 'CANCELED_NOT_PAID':
        case 'CANCELED_BY_PATIENT':
        case 'CANCELED_BY_PROVIDER':
            return true;
        default:
            return false;
    }
}

function isCompleted(appointmentStatus) {
    switch(appointmentStatus) {
        case 'COMPLETED':
            return true;
        default:
            return false;       
    }
}


function isAppointmentActive(appointmentStatus) {
    switch (appointmentStatus) {
        case 'WAITING_FOR_DOCTOR_APPROVAL':
        case 'WAITING_FOR_PATIENT_APPROVAL':
        case 'NEED_MORE_INFO':
        case 'ACCEPTED':
            return true;
        default:
            return false;
    }
}

function getClassByStatus(status) {
    switch (status) {
        case 'COMPLETED':
            return "medrec-green-1";
        case 'CANCELED_RESPONSE_OVERDUE':
        case 'CANCELED_NOT_PAID':
        case 'CANCELED_BY_PATIENT':
        case 'CANCELED_BY_PROVIDER':
            return "medrec-red-2";
        default:
            return "medrec-blue-1";
    }
}

function statusText(a) {
    return a.text_only ? $$(a.status.toLowerCase() + "_consultation") : $$(a.status.toLowerCase());
}

function isOverdueTextConsultation(c) {
    return c.text_only && c.first_response_time === 0 && c.status === 'ACCEPTED' && new Date().getTime() > c.ends_at;
}

/**
 * Return provider id from appointment or encounter
 * @param object - encounter or appointment
 * @return string - provider id
 */
function getProviderId(object) {
    return getProvider(object).user_id;
}

/**
 * Return provider from appointment or encounter
 * @param object - encounter or appointment
 * @return {*}
 */
function getProvider(object) {
    return object.participants.find(participant => participant.participant_role === 'PROVIDER');
}

/**
 * Return patient id from appointment or encounter
 * @param object - encounter or appointment
 * @return string - provider id
 */
function getPatientId(object) {
    return getPatient(object).user_id;
}

/**
 * Return provider from appointment or encounter
 * @param object - encounter or appointment
 * @return {*}
 */
function getPatient(object) {
    return object.participants.find(participant => participant.participant_role === 'PATIENT');
}

function calculateMaxFetchAppointmentsTimestamp() {
    return addDays(new Date(), FUTURE_DAYS_TO_FETCH).getTime();
}

function canCancel(appointment) {
    return appointment.encounter_id == null &&
               (
                   appointment.status === 'WAITING_FOR_DOCTOR_APPROVAL' ||
                   appointment.status === 'WAITING_FOR_PATIENT_APPROVAL' ||
                   appointment.status === 'NEED_MORE_INFO' ||
                   appointment.status === 'ACCEPTED'
               );

}