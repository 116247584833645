import store from '../store'
import {usersService} from '../service/users_service'
import {
    fetchSelectedUserMedicalProfile,
    selectAppointmentInProgress, selectGroupAppointment
} from '../actions/users_actions'
import {fetchSelectedUserMedicationPlan} from '../actions/medications_actions'
import history from '../helpers/history'
import {
    APPOINTMENTS_APPOINTMENT_CHAT,
    APPOINTMENTS_APPOINTMENT_EVENT,
    APPOINTMENTS_APPOINTMENT_EVENT_PRELOAD,
    APPOINTMENTS_APPOINTMENT_VIDEO,
    DASHBOARD_NEXT_PATIENT_CHAT,
    DASHBOARD_NEXT_PATIENT_MED_HISTORY,
    DASHBOARD_NEXT_PATIENT_VIDEO,
    MESSAGES_PATIENT_MED_HISTORY,
    PATIENTS_PATIENT_CHAT,
    PATIENTS_PATIENT_MED_HISTORY, RETURN_TO_PATIENT_MED_HISTORY
} from '../constants/pages'
import {Routes} from "../constants/routes";

export const medicalHistoryHelper = {
    prepareMedicalHistoryData,
    prepareMessagesData,
    openGroupAppointmentPage
};

/**
 * Prepares the medical history data based on the page you are
 *
 * @param {string} page - the action performed in the page you are at
 * @param {object} patient - the patient object
 * @param {object} appointment - the appointment object
 * @param {string} followingPage - the page where we will go next
 */
export function prepareMedicalHistoryData(page, patient, appointment, followingPage, returnTo) {
    switch (page) {
        case APPOINTMENTS_APPOINTMENT_EVENT: {
            store.dispatch(selectAppointmentInProgress(appointment));
            if (followingPage) {
                history.push(followingPage);
            }
        }
            break;
        case APPOINTMENTS_APPOINTMENT_EVENT_PRELOAD: {
            const {user_id, ...rest} = patient;
            store.dispatch({
                type: "SELECT_USER",
                "user": {...rest, id: user_id, health_issues: [...appointment.health_issues]}
            });
            if (appointment.health_issues && appointment.health_issues.length === 0) {
                fetchMedicationsAndMedicalProfile(user_id);
            } else {
                store.dispatch(fetchSelectedUserMedicationPlan(user_id));
            }
        }
            break;
        case DASHBOARD_NEXT_PATIENT_VIDEO:
        case APPOINTMENTS_APPOINTMENT_VIDEO:
            // eslint-disable-next-line no-case-declarations
            const {user_id, ...rest} = patient;
            store.dispatch({
                type: "SELECT_USER",
                "user": {...rest, id: user_id, health_issues: [...appointment.health_issues]}
            });
            store.dispatch(selectAppointmentInProgress(appointment));
            fetchMedicationsAndMedicalProfile(user_id);
            if (followingPage) {
                history.replace({state:{videoBackButton: true}})
                history.push(followingPage, {fromPage: history.location.pathname});
            }
            break;
        case RETURN_TO_PATIENT_MED_HISTORY:
            usersService.getUserHealthIssues(patient.id)
                .then((res) => {
                    const {id, ...rest} = patient;
                    store.dispatch({type: "SELECT_USER", "user": {...rest, id: id, health_issues: res}});
                    fetchMedicationsAndMedicalProfile(id);
                    history.push(followingPage, {returnTo:returnTo});
                });
            break;
        case DASHBOARD_NEXT_PATIENT_MED_HISTORY:
            usersService.getUserHealthIssues(patient.user_id)
                .then((res) => {
                    const {user_id, ...rest} = patient;
                    store.dispatch({type: "SELECT_USER", "user": {...rest, id: user_id, health_issues: res}});
                    fetchMedicationsAndMedicalProfile(user_id);
                    history.push(followingPage, {returnTo:returnTo});
                });
            break;
        case PATIENTS_PATIENT_MED_HISTORY:
        case MESSAGES_PATIENT_MED_HISTORY:
            // eslint-disable-next-line no-case-declarations
            const {healthIssueLiteList, ...other} = patient;
            store.dispatch({type: "SELECT_USER", "user": {...other, health_issues: [...healthIssueLiteList]}});
            fetchMedicationsAndMedicalProfile(patient.id);
            if (followingPage) {
                history.push(followingPage, {returnTo:returnTo});
            }
            break;
    }
}

/**
 * Prepares the messages page data
 *
 * @param {string} page - the action performed in the page you are at
 * @param {object} patient - the patient object
 * @param {string} followingPage - the page where we will go next
 */
export function prepareMessagesData(page, patient, followingPage) {
    switch (page) {
        case DASHBOARD_NEXT_PATIENT_CHAT:
        case APPOINTMENTS_APPOINTMENT_CHAT:
            // eslint-disable-next-line no-case-declarations
            const {user_id, ...rest} = patient;
            store.dispatch({type: "SELECT_USER", "user": {...rest, id: user_id}});
            history.push(followingPage);
            break;
        case PATIENTS_PATIENT_CHAT:
            store.dispatch({type: "SELECT_USER", "user": {...patient}});
            history.push(followingPage);
            break;
    }
}

/**
 * Fetches medication plans and the medical profile of the user
 *
 * @param {string} id - the id used as part of the request
 */
function fetchMedicationsAndMedicalProfile(id) {
    store.dispatch(fetchSelectedUserMedicationPlan(id));
    store.dispatch(fetchSelectedUserMedicalProfile(id));
}

function openGroupAppointmentPage(appointment) {
    store.dispatch(selectGroupAppointment(appointment));
    history.push(Routes.GROUP_APPOINTMENT_PAGE);
}