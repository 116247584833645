import React, {Component} from 'react'
import classNames from 'classnames'
import PropTypes from "prop-types";


export default class StatusIcon extends Component {

    render() {
        let component = null;
        const { className, ...rest} = this.props;
        switch (this.props.status) {
            case 'COMPLETED':
                component = <span className={classNames(className, "fa fa-check medrec-green-1")} {...rest} />;
                break;
            case 'CANCELED_BY_PATIENT':
                component = <span className={classNames(className, "fa fa-times medrec-red-2")} {...rest} />;
                break;
            case 'CANCELED_BY_PROVIDER':
                component = <span className={classNames(className, "fa fa-times medrec-red-2")} {...rest} />;
                break;
            default:
                return null;
        }

        return component;
    }
}

StatusIcon.propTypes = {
    className: PropTypes.string,
    status: PropTypes.string
}