import React, {Component} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowLeft,
    faMicrophone,
    faMicrophoneSlash,
    faPhoneSlash,
    faSyncAlt,
    faVideo,
    faVideoSlash
} from "@fortawesome/free-solid-svg-icons";
import {VideoManager} from "./video_manager";
import {CameraDevices} from './CameraDevices';
import PropTypes from "prop-types";
import {VideoContext} from "./VideoProvider";


export class VideoControlsOverlay extends Component {
    static contextType = VideoContext;

    state = {
        cameraNo: 0
    }

    constructor(props) {
        super(props);
        this.index = 0;
    }

    componentDidMount() {
        // eslint-disable-next-line no-unused-vars
        this.props.cameraDevices && this.props.cameraDevices.map((device, idx) => {
            this.index++;
            if (this.props.deviceId && this.props.deviceId.exact == device.deviceId) {
                this.setState({
                    cameraNo: this.index < 2 ? this.index : 0
                })
            }
        })
    }


    onHangupCall = () => {
        let room = this.context.room;
        if (room) {
            room.disconnect();
        }
        if(this.props.onHangup) {
            this.props.onHangup();
        }
    }

    getAudioTrack = () => {
        return this.context.localTracks.find(track => track.kind === 'audio')
    }

    getVideoTrack = () => {
        return this.context.localTracks.find(track => track.kind === 'video')
    }

    /**
     * On mic btn press. Mute or unmute
     */
    onMicBtnClick = () => {
        if (this.props.audioMuted) {
            this.getAudioTrack().enable();
        } else {
            this.getAudioTrack().disable();
        }
    }

    /**
     * On video btn press. Hold or continue video
     */
    onVideoBtnClick = () => {
        if (this.props.videoMuted) {
            this.getVideoTrack().enable();
        } else {
            this.getVideoTrack().disable();
        }
    }

    /**
     * The case with 2 cameras
     */
    onSwitchCamera = () => {
        /*if (this.state.cameraNo == 0) {
            VideoManager.getVideoCameras(0);
            this.setState({cameraNo: 1})
        } else if (this.state.cameraNo == 1) {
            VideoManager.getVideoCameras(1);
            this.setState({cameraNo: 0})
        }*/
    }

    /**
     * The case with more cameras
     */
    selectCamera = () => {
        /*if (this.state.cameraNo == 0) {
            VideoManager.onSwitchVideoCamera(0, this.props.cameraDevices);
            this.setState({cameraNo: 1})
        } else if (this.state.cameraNo == 1) {
            VideoManager.onSwitchVideoCamera(1, this.props.cameraDevices);
            this.setState({cameraNo: 0})
        }*/
    }


    render() {
        // if (!VideoManager.session || VideoManager.session.state !== 2) {
        //     return null;
        // }

        return <div className={this.props.modal ? "buttons video-contols" : "buttons video-controls1"}>
            {this.props.onOpen && <button className="video-control-btn" onClick={this.props.onOpen}>
                <FontAwesomeIcon icon={faArrowLeft}/>
            </button>
            }
            <button className="video-control-btn" onClick={this.onMicBtnClick}>
                <FontAwesomeIcon icon={this.props.audioMuted ? faMicrophoneSlash : faMicrophone}/>
            </button>
            <button className="video-hangup-btn" data-target="video" onClick={this.onHangupCall}>
                <FontAwesomeIcon icon={faPhoneSlash}/>
            </button>
            <button className="video-control-btn" onClick={this.onVideoBtnClick}>
                <FontAwesomeIcon icon={this.props.videoMuted ? faVideoSlash : faVideo}/>
            </button>
            {/*{this.props.cameraDevices && this.props.cameraDevices.length == 2 &&
            <button className={this.props.modal ? "video-control-btn" : "video-control-btn1"}
                    onClick={this.onSwitchCamera}>
                <FontAwesomeIcon icon={this.props.facingMode ? faSyncAlt : faSyncAlt}/>
            </button>}
            {this.props.cameraDevices && this.props.cameraDevices.length > 2 &&
            <CameraDevices deviceId={this.props.deviceId} modal={this.props.modal}
                           cameraDevices={this.props.cameraDevices}/>}*/}
        </div>
    }
}

VideoControlsOverlay.propTypes = {
    audioMuted: PropTypes.bool,
    onHangup: PropTypes.func,
    cameraDevices:  PropTypes.array,
    deviceId:  PropTypes.string,
    facingMode:  PropTypes.string,
    modal:  PropTypes.bool,
    modalShow:  PropTypes.bool,
    videoMuted:  PropTypes.bool
};