import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {$$} from "../../../../helpers/localization";
import {clinicService} from "../../../../service/clinic_service";
import {
    nhisFetchNhisPrescriptions,
    nhisLogin,
    nhisSignFetchNhisPrescriptions
} from "../../../../service/nhis_service";
import DatePicker from "react-datepicker";
import {dateAsStr} from "./NhisPrescription";

export default function TestFetchPrescriptions({patient, provider, orgId}) {
    const [pmi, setPmi] = useState(provider.uin);
    const [egn, setEgn] = useState();
    const [results, setResults] = useState([])
    const [error, setError] = useState()
    const [sendingInProgress, setSendingInProgress] = useState(false);
    const [success, setSuccess] = useState(false);
    const [d, setD] = useState(new Date())

    const fromUserInfo = () => {
        clinicService.fetchEncPatientInfo(patient.id, orgId).then(patient => {
            if (patient && patient.patientID) {
                setEgn(patient.patientID);
            }
        })
    }

    useEffect(() => {
        fromUserInfo();
    }, [patient])

    if (!pmi || !egn) {
        return null;
    }

    const onFetch = () => {
        let message = {
            senderId: pmi,
            search_identifier_authoredOn: ["1", egn, dateAsStr(d)]
        };

        setSendingInProgress(true);
        setError(undefined);
        setResults([]);
        nhisLogin((loginSuccess) => {
            nhisSignFetchNhisPrescriptions(message, (signedRequest) => {
                nhisFetchNhisPrescriptions(loginSuccess.accessToken, signedRequest)
                    .then(async res => {
                        let contents = res["nhis:message"]["nhis:contents"];
                        let error = contents["nhis:error"];
                        if (error) {
                            if (Array.isArray(error)) {
                                setError(error[0]["nhis:reason"]["value"]);
                            } else {
                                setError(error["nhis:reason"]["value"]);
                            }
                        } else {
                            //let status = contents["nhis:status"]["value"];
                            let results = contents["nhis:results"];
                            if (!Array.isArray(results)) {
                                results = [results]
                            }
                            console.log(results)
                            setResults(results)
                        }
                        setSendingInProgress(false)
                    })
            }, (e) => {
                setError(e);
                setSendingInProgress(false);
            })
        }, (e) => {
            setSendingInProgress(false), setError(e)
        })
    }

    return <div className="p-2">
        <h6 className="pl-2">Други електронни рецепти:</h6>
        <div className="p-2 d-flex">
            <DatePicker
                locale={"bg"}
                selected={d}
                onChange={date => {
                    setD(date)
                }}
                selectsStart
                startDate={d}
                isClearable
                dateFormat="dd/MM/yyyy"
                placeholderText={$$('nhis.medication.onsetDateTime')}
                className="form-control form-control-sm w-100"/>
            <Button size={"sm"} variant={"primary"} form={"patient_provider_form"} className="ml-2"
                    type={"button"} disabled={sendingInProgress} onClick={onFetch}>Изтегли</Button>
            {results.length > 0 && <Button size={"sm"} disabled={!d} variant={"outline-primary"} type={"button"} className="ml-2"
                    onClick={() => {
                        setResults([]); setError(null);
                    }}>Изтрий</Button>}
        </div>
        {sendingInProgress && <div className="p-2 mt-1">Изтегляне, моля изчакайте!</div>}
        {error && <div className="p-2 mt-1">{error}</div>}
        {results.length > 0 && <div className="p-2">
            <table className="table table-striped medrec-default-text-color">
                <thead>
                    <tr>
                        <th>Статус</th>
                        <th>NRN</th>
                        <th>Издадена от</th>
                        <th>УИН</th>
                    </tr>
                </thead>
                <tbody>
                {results.map((r, i) => {
                    return <tr key={i}>
                        <td>{statusToStr[r["nhis:prescription"]["nhis:status"].value]}</td>
                        <td>{r["nhis:prescription"]["nhis:nrnPrescription"].value}</td>
                        <td>{r["nhis:requester"]["nhis:name"]["nhis:given"].value} {r["nhis:requester"]["nhis:name"]["nhis:family"].value}</td>
                        <td>{r["nhis:requester"]["nhis:pmi"].value}</td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
        }
    </div>
}

const statusToStr = {
    "1": "Активна",
    "2": "Частично изпълнена",
    "3": "Изпълнена",
    "4": "Отказана",
    "5": "Анулирана",
    "6": "Частично анулирана",
    "7": "Изтекла",
}
