import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import useVideoContext from '../hooks/useVideoContext/useVideoContext';

export enum Steps {
  deviceSelectionStep,
}

// @ts-ignore
export default function PreJoinScreens({name, roomName, groupEvent, displayName, onCancel, error}) {
  const { getAudioAndVideoTracks } = useVideoContext();
  const [mediaError, setMediaError] = useState<Error>(error);
  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, mediaError]);


  // @ts-ignore
  return (
    <div>
      <MediaErrorSnackbar error={mediaError ?? error} />
        <DeviceSelectionScreen name={name} roomName={roomName} groupEvent={groupEvent} displayName={displayName} onCancel={onCancel}/>
    </div>
  );
}
