import {$$} from '../helpers/localization'

export const CONFIGURATION_OPTIONS = {
    SLOT_DURATION: [
        {
            value: 15,
            text: "min15"
        },
        {
            value: 20,
            text: "min20"
        },
        {
            value: 30,
            text: "min30"
        },
        {
            value: 40,
            text: "min40"
        },
        {
            value: 45,
            text: "min45"
        },
        {
            value: 60,
            text: "hour1"
        },
        {
            value: 90,
            text: "hour1_30"
        },
        {
            value: 120,
            text: "hour2"
        }
    ]
}

export const SETTING_OPTIONS = {
    UNITS_OF_MEASUREMENTS: [
        {
            value: "US",
            text: "US"
        },
        {
            value: "METRIC",
            text: "metric"
        }
    ],
    CHOLESTEROL_UNITS: [
        {
            value: "MG_DL",
            text: "milligram_per_decilitre"
        },
        {
            value: "MMOL_L",
            text: "millimol_per_litre"
        }
    ],
    CLASSIFICATION_METHODS: [
        {
            value: "ACC_AHA",
            text: "ACC/AHA"
        },
        {
            value: "ESC_ESH",
            text: "ESC/ESH"
        },
        {
            value: "JNC",
            text: "JNC8"
        },
        {
            value: "NICE",
            text: "NICE 2019"
        },
        {
            value: "CANADA",
            text: "Hypertension Canada"
        }
    ],
    DATE_FORMATS: [
        {
            value: "dd.MM.yyyy",
            text: "dd.MM.yyyy"
        },
        {
            value: "dd/MM/yyyy",
            text: "dd/MM/yyyy"
        },
        {
            value: "dd-MM-yyyy",
            text: "dd-MM-yyyy"
        },
        {
            value: "MM/dd/yyyy",
            text: "MM/dd/yyyy"
        },
        {
            value: "yyyy-MM-dd",
            text: "yyyy-MM-dd"
        },
        {
            value: "yyyy.MM.dd",
            text: "yyyy.MM.dd"
        },
        {
            value: "yyyy/MM/dd",
            text: "yyyy/MM/dd"
        }
    ],
    GLUCOSE_UNITS: [
        {
            value: "MG_DL",
            text: "milligram_per_decilitre"
        },
        {
            value: "MMOL_L",
            text: "millimol_per_litre"
        }
    ],
    KETONES_UNITS: [
        {
            value: "MG_DL",
            text: "milligram_per_decilitre"
        },
        {
            value: "MMOL_L",
            text: "millimol_per_litre"
        }
    ],
    HBA1C_UNITS: [
        {
            value: "PERCENT",
            text: "percent"
        },
        {
            value: "MMOL_MOL",
            text: "millimol_per_mol"
        }
    ],
}

export const CHART_FILTERS = {
    PERIOD_OPTIONS: [
        {
            value: "WEEK",
            text: "week_label"
        },
        {
            value: "FORTNIGHT",
            text: "fortnight_label"
        },
        {
            value: "MONTH",
            text: "month_label"
        },
        {
            value: "QUARTER",
            text: "quarter_label"
        },
        {
            value: "CUSTOM",
            text: "custom_label"
        }
    ]
}

export const GENDER = {
    OPTIONS: [
        {
            value: "",
            text: "select_gender"
        },
        {
            value: "MALE",
            text: "male"
        },
        {
            value: "FEMALE",
            text: "female"
        }
    ]
}

export const BLOOD_TYPE = {
    /*
     NONE, //(display None)
     A_RhD_P, //(display A+)
     A_RhD_N, //(display A-)
     B_RhD_P, //(display B+)
     B_RhD_N, //(display B-)
     O_RhD_P, //(display 0+)
     O_RhD_N, //(display 0-)
     AB_RhD_P, //(display AB+)
     AB_RhD_N //(display AB-)
     */
    OPTIONS: [
        {
            value: "NONE",
            text: "-"
        },
        {
            value: "A_RhD_P",
            text: "A+"
        },
        {
            value: "A_RhD_N",
            text: "A-"
        },
        {
            value: "B_RhD_P",
            text: "B+"
        },
        {
            value: "O_RhD_P",
            text: "0+"
        },
        {
            value: "O_RhD_N",
            text: "0-"
        },
        {
            value: "AB_RhD_P",
            text: "AB+"
        },
        {
            value: "AB_RhD_N",
            text: "AB-"
        }
    ]
}

export const DOCUMENTS_TYPES = {
    TYPE: [
        {
            value: "EPICRISIS",
            text: "document_type_epicrisis"
        },
        {
            value: "PRESCRIPTION",
            text: "document_type_prescription"
        },
        {
            value: "LAB_RESULTS",
            text: "document_type_lab_results"
        },
        {
            value: "AMBULANCE_SHEET",
            text: "document_type_ambulance_sheet"
        },
        {
            value: "REFERRAL",
            text: "document_type_referral"
        },
        {
            value: "DIAGNOSTIC_IMAGING",
            text: "document_type_diagnostic_imaging"
        },
        {
            value: "OTHER",
            text: "document_type_other"
        },
    ]
}


export const DOCUMENTS_FILTER = {
    TYPE: [
        {
            value: "",
            text: "all_label"
        },
        {
            value: "EPICRISIS",
            text: "document_type_epicrisis"
        },
        {
            value: "PRESCRIPTION",
            text: "document_type_prescription"
        },
        {
            value: "LAB_RESULTS",
            text: "document_type_lab_results"
        },
        {
            value: "AMBULANCE_SHEET",
            text: "document_type_ambulance_sheet"
        },
        {
            value: "REFERRAL",
            text: "document_type_referral"
        },
        {
            value: "DIAGNOSTIC_IMAGING",
            text: "document_type_diagnostic_imaging"
        },
        {
            value: "OTHER",
            text: "document_type_other"
        },
    ]
}

export const MENSTRUATION = {
    FLOW: [
        {
            value: 0,
            text: "no_flow"
        },
        {
            value: 1,
            text: "light"
        },
        {
            value: 2,
            text: "medium"
        },
        {
            value: 3,
            text: "heavy"
        },
        {
            value: 4,
            text: "unexpected"
        }
    ],
    COLOR: [
        {
            value: 0,
            color: "",
            text: "none"
        },
        {
            value: 1,
            color: "#000000",
            text: "black"
        },
        {
            value: 2,
            color: "#BF6918",
            text: "brown"
        },
        {
            value: 3,
            color: "#c03200",
            text: "dark_red"
        },
        {
            value: 4,
            color: "#E30613",
            text: "bright_red"
        },
        {
            value: 5,
            color: "#F44AAA",
            text: "pink"
        },
        {
            value: 6,
            color: "#F48A2A",
            text: "orange"
        },
        {
            value: 7,
            color: "#B4B4B4",
            text: "gray"
        }
    ],
    CONSISTENCY: [
        {
            value: 0,
            text: "none"
        },
        {
            value: 1,
            text: "waterly"
        },
        {
            value: 2,
            text: "clots"
        }
    ],
}

export const APPOINTMENTS_FILTER = {
    TYPE: [
        {
            value: "",
            text: "all_label"
        },
        {
            value: "NEED_MORE_INFO",
            text: "need_more_info"
        },
        {
            value: "ACCEPTED",
            text: "accepted"
        },
        {
            value: "COMPLETED",
            text: "completed"
        },
        {
            value: "REJECTED_BY_PATIENT",
            text: "rejected_by_patient"
        },
        {
            value: "CANCELED_BY_PATIENT",
            text: "canceled_by_patient"
        },
        {
            value: "CANCELED_BY_PROVIDER",
            text: "canceled_by_provider"
        },
        {
            value: "REJECTED_BY_PATIENT",
            text: "rejected_by_patient"
        },
        {
            value: "REJECTED_BY_PROVIDER",
            text: "rejected_by_provider"
        },
        {
            value: "CANCELED_NOT_PAID",
            text: "canceled_not_paid"
        },
        {
            value: "CANCELED_RESPONSE_OVERDUE",
            text: "canceled_response_overdue"
        },
        {
            value: "WAITING_FOR_DOCTOR_APPROVAL",
            text: "waiting_for_doctor_approval"
        },
        {
            value: "WAITING_FOR_PATIENT_APPROVAL",
            text: "waiting_for_patient_approval"
        }
    ],
    COLOR: [
        {
            value: "WAITING_FOR_DOCTOR_APPROVAL",
            color: "#FFC300",
            text: "waiting_for_doctor_approval"
        },
        {
            value: "WAITING_FOR_PATIENT_APPROVAL",
            color: "#FF8100",
            text: "waiting_for_patient_approval"
        },
        {
            value: "NEED_MORE_INFO",
            color: "#9A9D9E",
            text: "need_more_info"
        },
        {
            value: "ACCEPTED",
            color: "#1479FF",
            text: "accepted"
        },
        {
            value: "COMPLETED",
            color: "#3E9979",
            text: "completed"
        },
        {
            value: "REJECTED_BY_PATIENT",
            color: "#C70039",
            text: "rejected_by_patient"
        },
        {
            value: "CANCELED_BY_PATIENT",
            color: "#C70039",
            text: "canceled_by_patient"
        },
        {
            value: "CANCELED_BY_PROVIDER",
            color: "#C70039",
            text: "canceled_by_provider"
        },
        {
            value: "REJECTED_BY_PATIENT",
            color: "#C70039",
            text: "rejected_by_patient"
        },
        {
            value: "REJECTED_BY_PROVIDER",
            color: "#C70039",
            text: "rejected_by_provider"
        },
        {
            value: "CANCELED_RESPONSE_OVERDUE",
            color: "#C70039",
            text: "canceled_response_overdue"
        },
        {
            value: "CANCELED_NOT_PAID",
            color: "#C70039",
            text: "canceled_not_paid"
        }
    ]
}

export const NOTE_VISIBILITY = {
    TYPE: [
        {
            value: "PRIVATE",
            text: "private_note_label"
        },
        {
            value: "ALL",
            text: "to_all_note_label"
        },
        {
            value: "PROVIDER_ONLY",
            text: "provider_only_note_label"
        },
        {
            value: "PATIENT_ONLY",
            text: "patient_only_note_label"
        }
    ],
    COLOR: [
        {
            value: "PRIVATE",
            color: "#C70039",
            text: "private_note_label"
        },
        {
            value: "ALL",
            color: "#129E7A",
            text: "to_all_note_label"
        },
        {
            value: "PROVIDER_ONLY",
            color: "#FFC300",
            text: "provider_only_note_label"
        },
        {
            value: "PATIENT_ONLY",
            color: "#FFC300",
            text: "patient_only_note_label"
        }
    ]
}

export const LOCATION_TYPE = {
    LOCATION_OPTIONS: [
        {
            value: "ONLINE",
            text: "online"
        },
        {
            value: "ON_SITE",
            text: "on_site"
        }
    ]
}

export const PUNCTUATION = {
    WORDS: [
        {
            value: ".",
            text: "full stop"
        },
        {
            value: ",",
            text: "comma"
        },
        {
            value: "new line",
            text: "\\n"
        }
    ]
}

export const UNIT_TYPES = {
    TYPE: [
        {
            value: "AMPULE",
            text: "unit_type_ampule"
        },
        {
            value: "APPLICATION",
            text: "unit_type_application"
        },
        {
            value: "CAPSULE",
            text: "unit_type_capsule"
        },
        {
            value: "DROP",
            text: "unit_type_drop"
        },
        {
            value: "GRAM",
            text: "unit_type_gram"
        },
        {
            value: "INJECTION",
            text: "unit_type_injection"
        },
        {
            value: "MG",
            text: "unit_type_mg"
        },
        {
            value: "ML",
            text: "unit_type_ml"
        },
        {
            value: "PACKET",
            text: "unit_type_packet"
        },
        {
            value: "PATCH",
            text: "unit_type_patch"
        },
        {
            value: "PIECE",
            text: "unit_type_piece"
        },
        {
            value: "TABLET",
            text: "unit_type_tablet"
        },
        {
            value: "PUFF",
            text: "unit_type_puff"
        },
        {
            value: "SPRAY",
            text: "unit_type_spray"
        },
        {
            value: "SUPPOSITORY",
            text: "unit_type_suppository"
        },
        {
            value: "TBSP",
            text: "unit_type_tbsp"
        },
        {
            value: "TSP",
            text: "unit_type_tsp"
        },
        {
            value: "UNIT",
            text: "unit_type_unit"
        }
    ]
}

export const PATIENT_ID_TYPE = {
    TYPE_OPTIONS: [
        {value: "EGN", text: "id_type_egn"},
        {value: "LNZ", text: "id_type_lnz"},
        {value: "SSN", text: "id_type_ssn"},
        {value: "PASS", text: "id_type_pass"},
        {value: "OTHER", text: "id_type_other"},
        {value: "NEWBORN", text: "id_type_newborn"}
    ]
}

const MED_INSTRUCTION_KEYS = ["MORNING",
    "EARLY_MORNING",
    "LATE_MORNING",
    "NOON",
    "AFTERNOON",
    "EARLY_AFTERNOON",
    "LATE_AFTERNOON",
    "EVENING",
    "EARLY_EVENING",
    "LATE_EVENING",
    "NIGHT",
    "AFTER_SLEEP",
    "BEFORE_SLEEP",
    "UPON_WAKING",
    "WITH_MEAL",
    "WITH_BREAKFAST",
    "WITH_LUNCH",
    "WITH_DINNER",
    "BEFORE_MEAL",
    "BEFORE_BREAKFAST",
    "BEFORE_LUNCH",
    "BEFORE_DINNER",
    "AFTER_MEAL",
    "AFTER_BREAKFAST",
    "AFTER_LUNCH",
    "AFTER_DINNER"];


export function getMedInstructionsOptions() {
    return MED_INSTRUCTION_KEYS.map(key => {
        return {value: key, text: $$(key)}
    });
}

export function getBloodTypeOptions() {
    BLOOD_TYPE.OPTIONS[0].text = $$("not_selected_value_feminine_noun_gender_label");
    return BLOOD_TYPE.OPTIONS;
}

/**
 * Return select options with the text resolved to the selected language
 *
 * @param {Array} options - the list of options
 */
export function getResolvedOptions(options) {
    return options.map(o => {
        return {value: o.value, text: o.text ? $$(o.text) : o.text}
    });
}

export function getHealthIssueOptions(options) {
    return options && options.map(o => {
        return {value: o, text: o.name.toLowerCase()}
    });
}

/**
 * Return text for the selected option
 *
 * @param {Array} options - the list of options
 * @param {string} value - the selected value
 */
export function getResolvedText(options, value) {
    if (options && options.length > 0 && value !== null && value !== undefined) {
        const option = options.find(o => o.value === value);
        if (option) {
            return $$(option.text);
        }
    }
    return '';
}

/**
 * Return color for the selected option
 *
 * @param {Array} options - the list of options
 * @param {string} value - the selected value
 */
export function getColorForOption(options, value) {
    if (options && options.length > 0 && value !== null && value !== undefined) {
        const option = options.find(o => o.value === value);
        if (option) {
            return option.color;
        }
    }
    return '';
}

export const APPOINTMENT_FILTER_OPTIONS = {
    TYPE: [
        {
            value: 0,
            text: "appointment_filter_all"
        },
        {
            value: 1,
            text: "appointment_filter_pending"
        },
        {
            value: 2,
            text: "appointment_filter_completed"
        },
        {
            value: 3,
            text: "appointment_filter_active_consults"
        }
    ]
}

export const ALLERGY_OPTIONS = {
    TYPE: [
        {
            value: "NONE",
            text: "None"
        },
        {
            value: "DUST_ALLERGY",
            text: "dust_allergy"
        },
        {
            value: "FOOD_ALLERGY",
            text: "food_allergy"
        },
        {
            value: "INSECT_ALLERGY",
            text: "insect_allergy"
        },
        {
            value: "LATEX_ALLERGY",
            text: "latex_allergy"
        },
        {
            value: "MOLD_ALLERGY",
            text: "mold_allergy"
        },
        {
            value: "PET_ALLERGY",
            text: "pet_allergy"
        },
        {
            value: "POLLEN_ALLERGY",
            text: "pollen_allergy"
        },
        {
            value: "DRUG_ALLERGY",
            text: "drug_allergy"
        },
        {
            value: "OTHER_ALLERGY",
            text: "other_allergy"
        },

    ],
    SEVERITY: [
        {
            value: 0,
            text: "mild"
        },
        {
            value: 1,
            text: "mild_to_moderate"
        },
        {
            value: 2,
            text: "moderate"
        },
        {
            value: 3,
            text: "moderate_to_severe"
        },
        {
            value: 4,
            text: "severe"
        },
        {
            value: 5,
            text: "life_threatening"
        }
    ]
}