import React, {Component} from 'react'
import {connect} from "react-redux";
import {$$} from "../../helpers/localization";
import {clearSelectedUserData, clearUserAppointments} from "../../actions/users_actions";
import PropTypes from "prop-types";
import {createNote, deleteNote, GetStaffList, updateNote} from "../../actions/management_actions";
import PatientVerticalCard from "../patient-vertical-card/PatientVerticalCard";
import {
    fetchAppointments,
    markAppointmentAsPaid,
    updateAppointment,
    updateAppointmentStatus
} from "../../actions/clinic_actions";
import no_data from "../../resources/images/no_data.png";
import {infoUtils} from "../../utils/infoUtils";
import OkRoundButton from "../shared/OkRoundButton";
import CancelRoundButton from "../shared/CancelRoundButton";
import CenteredModal from "../shared/CenteredModal";
import moment from "moment";
import {
    APPOINTMENTS_FILTER,
    getColorForOption
} from "../../constants/select_options";
import {clinicService} from "../../service/clinic_service";
import classNames from "classnames";
import _ from "underscore";
import {Collapse} from "react-collapse";
import {Routes} from "../../constants/routes";
import {paymentStatusUtils} from "../../utils/paymentStatusUtils";
import NotesRoundButton from "../shared/NotesRoundButton";
import {NotesTab} from "../notes/NotesTab";
import {getClinicianNameWithTitle} from "../../utils/getClinicianNameWithTitle";
import EncounterDetails from "../appointments/EncounterDetails";
import {appointmentUtils} from "../../utils/appointmentUtils";
import {COMPLETED} from "../../constants/appointment_payment_status";
import RoundButton from "../shared/RoundButton";
import BackButton from "../shared/BackButton";
import CenteredModal2 from "../shared/CenteredModal2";
import TemplateSelector from "../examination/TemplateSelector";
import {cloneDeep} from "lodash";
import {usersService} from "../../service/users_service";
import TestsResultEdit from "../examination/TestsResultEdit";
import ProviderEncounterDocuments from "../examination/documents/ProviderEncounterDocuments";
import {EditAppointmentModal} from "../appointment/AppointmentsManagement";
import enGB from "date-fns/locale/en-GB";
import bg from "date-fns/locale/bg";
import sq from "date-fns/locale/sq";
import {isSameDay} from 'date-fns'
import UserImageWrap from "../shared/UserImageWrap";
import ExamLinkIcon from "../examination/ExamLinkIcon";
import AppointmentFilters from "../shared/AppointmentFilters";
import {Permissions} from "../../utils/permissions/types";


class PatientPage extends Component {
    state = {
        showAppointments: false,
        showNotes: false,
        selectedAppointment: {},
        loading:true
    }

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.fetchPatientAppointments().then(()=>{
            this.setState({showAppointments:true, loading:false});
        });
        this.props.GetStaffList(this.props.orgId);
    }

    fetchPatientAppointments = () => {
        return this.props.fetchAppointments(this.props.selectedUser.id, this.props.orgId, null);
    }

    componentWillUnmount() {
        this.props.clearSelectedUserData();
        this.props.clearUserAppointments();
    }

    noAppointments = () => {
        let emptyStatsResultObj = {
            imgClass: 'no-lab-results-img',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: 'no-lab-results-secondary-label',
            src: no_data,
            primaryLabel: $$('no_results_found'),
            secondaryLabel: ''
        }
        return infoUtils.noData(emptyStatsResultObj);
    }

    /**
     * Accept appointment on confirmation
     */
    acceptAppointment = (id) => {
        this.props.updateAppointmentStatus(id, "ACCEPTED", null);
    }


    /**
     * Cancel appointment on confirmation
     */
    cancelAppointment = (id, status, note) => {
        this.props.updateAppointmentStatus(id, status, note);
    }

    markAppointmentAsPaid = (id) => {
        this.props.markAppointmentAsPaid(id);
    }

    onNotesClick = (appointment) => {
        this.setState({
            showAppointments: false, showNotes: true,
            selectedAppointment: appointment
        })
        document.scrollingElement.scrollTo({top:0, behavior:'instant'});
    }


    createNote = (selectedUserId, note, selectedAppointmentId, params) => {
        this.props.createNote(selectedUserId, note, selectedAppointmentId, params).then(
            () => {
                this.fetchPatientAppointments();
            }
        );
    }

    deleteNote = (selectedUserId, selectedNoteId, selectedAppointmentId) => {
        this.props.deleteNote(selectedUserId, selectedNoteId, selectedAppointmentId).then(
            () => {
                this.fetchPatientAppointments();
            }
        );
    }

    updateNote = (selectedUserId, note, selectedAppointmentId, params) => {
        this.props.updateNote(selectedUserId, note, selectedAppointmentId, params).then(
            () => {
                this.fetchPatientAppointments();
            }
        );
    }


    getSelectedAppointment = () => {
        return this.props.userAppointments.find((element) => {
            return element.id === this.state.selectedAppointment.id;
        })
    }

    getSelectedAppointmentNotes = () => {
        let appointment = this.props.userAppointments.find((element) => {
            return element.id === this.state.selectedAppointment.id;
        })

        if (appointment) {
            let notes = [];
            for (let i in appointment.notes) {
                for (let j in this.props.staffMembers) {
                    if (appointment.notes[i].owner_id === this.props.staffMembers[j].id) {
                        appointment.notes[i].owner_fullname = this.props.staffMembers[j].fullname
                    }
                }
                for (let j in this.props.internalStaffMembers) {
                    if (appointment.notes[i].owner_id === this.props.internalStaffMembers[j].id) {
                        appointment.notes[i].owner_fullname = this.props.internalStaffMembers[j].fullname
                    }
                }

                if (!appointment.notes[i].owner_fullname) {
                    if (this.props.selectedUser.id === appointment.notes[i].owner_id) {
                        appointment.notes[i].owner_fullname = this.props.selectedUser.fullname
                    }
                }

                if (appointment.notes[i].owner_fullname !== null) {
                    notes.push(appointment.notes[i])
                }
            }
            return notes;
        }
    }

    onEditAppointment = (appointment) => {
        this.setState({appointmentToEdit:appointment})
    }

    clearAppointmentToEdit = () => {
        this.setState({appointmentToEdit:undefined})
    }

    getLocale = () => {
        switch (this.props.i18n.selected.lang) {
            case "en":
                return enGB;
            case "bg":
                return bg;
            case "sq":
                return sq;
            default:
                return enGB;
        }
    }

    onEditAppointmentSave = (app, forceUpdateAppointment) => {
        return this.props.updateAppointment(app, forceUpdateAppointment).then(()=>{
            this.clearAppointmentToEdit();
        });
    }

    render() {
        return <div className="med-history-page">
            <div className="history d-flex flex-container flex-row">
                <div className="main-col">
                    <div className="patient-card-header"><h2>{$$("patient_information_label")}</h2></div>
                    <div className="patient-card-header"><h3><BackButton onClick={(e) => {
                        if (this.state.showNotes) {
                            this.setState({showAppointments: true, showNotes: false})
                        } else {
                            e.preventDefault();
                            e.stopPropagation();
                            if (this.props.location.state && this.props.location.state.returnTo) {
                                this.props.history.push(this.props.location.state.returnTo)
                            } else {
                                this.props.history.push(Routes.MANAGE_PATIENTS_PAGE)
                            }
                        }
                    }} title={$$("back")}/> {$$("appointments_label")}</h3></div>
                    {this.state.loading && <div className="report-loader" />}
                    {this.state.showAppointments && <>
                        {this.props.userAppointments.length > 0 ?
                            <div className="patient-card-header">
                                <Appointments appointments={this.props.userAppointments}
                                              i18n={this.props.i18n}
                                              settings={this.props.settings}
                                              staffList={this.props.staffMembers}
                                              onNotesClick={this.onNotesClick}
                                              selectedUser={this.props.selectedUser}
                                              orgId={this.props.orgId}
                                              loggedInUserId={this.props.userId}
                                              organisation={this.props.organisation}
                                              loggedInUser={this.props.user}
                                              permissions={this.props.permissions}
                                              customActions={<CustomActions permissions={this.props.permissions}
                                                                            loggedInUserId={this.props.userId}
                                                                            onCancelAppointment={this.cancelAppointment}
                                                                            onAcceptAppointment={this.acceptAppointment}
                                                                            onMarkAppointmentAsPaid={this.markAppointmentAsPaid}
                                                                            onEditAppointment={this.onEditAppointment}
                                              />}/>
                            </div>
                            :
                            this.noAppointments()}
                    </>
                    }
                    {this.state.showNotes && <div className="patient-card-header">
                        <NotesTab userId={this.props.userId}
                                  selectedAppointment={this.getSelectedAppointment()}
                                  userAppointments={this.props.userAppointments}
                                  selectedUserId={this.state.selectedAppointment.appointment_price.provider_id}
                                  notes={this.getSelectedAppointmentNotes()}
                                  createNote={this.createNote}
                                  updateNote={this.updateNote}
                                  deleteNote={this.deleteNote}
                                  formDisabled={this.props.formDisabled}
                                  i18n={this.props.i18n.selected}/>
                    </div>}
                    {this.state.appointmentToEdit && <EditAppointmentModal
                                    appointment={this.state.appointmentToEdit}
                                    onClose={()=>{this.clearAppointmentToEdit()}}
                                    onSave={this.onEditAppointmentSave}
                                    organisationName={this.props.organisation.name}
                                    locale={this.getLocale()}
                                    permissions={this.props.permissions}
                                    loggedInUserId={this.props.userId}
                    />}
                </div>
                <div>
                    <PatientVerticalCard hide={false}
                                         i18n={this.props.i18n}/>
                </div>
            </div>
        </div>
    }
}

export class CustomActions extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {cancelStatus: "CANCELED_BY_PROVIDER", note:""};
    }

    // eslint-disable-next-line no-unused-vars
    updateAppointmentStatus = () => {
        let noteObj = null
        if (this.state.note && this.state.note.trim() !== "") {
            noteObj = {
                value: this.state.note.trim(),
                visibility: "ALL"
            }
        }
        this.props.onCancelAppointment(this.props.appointment.id, this.state.cancelStatus, noteObj);
        this.setState({cancelAppointmentModalOpened: false, cancelStatus: "CANCELED_BY_PROVIDER", note:""});
    }

    markAppointmentAsPaid = () => {
        this.props.onMarkAppointmentAsPaid(this.props.appointment.id);
        this.setState({paidAppointmentModalOpened: false, appointmentToPaid: null});
    }

    acceptAppointment = () => {
        this.props.onAcceptAppointment(this.props.appointment.id);
        this.setState({acceptAppointmentModalOpened: false});
    }

    // eslint-disable-next-line no-unused-vars
    onChangeCancelStatusValue = () => {
        if (event.target.value === "On") {
            this.setState({cancelStatus: "CANCELED_BY_PROVIDER"});
        } else {
            this.setState({cancelStatus: "CANCELED_BY_PATIENT"});
        }
    }

    render() {
        let isOwn = appointmentUtils.getProviderId(this.props.appointment) === this.props.loggedInUserId;
        let canChangeState = isOwn ? this.props.permissions.canChangeAppointmentStatusOwn() : this.props.permissions.canChangeAppointmentStatusOthers();
        let canChangePaymentStatus = isOwn ? this.props.permissions.canChangePaymentStatusOwn(): this.props.permissions.canChangePaymentStatusOthers();
        let canEditAppointment = isOwn ? this.props.permissions.canEditAppointmentOwn() : this.props.permissions.canEditAppointmentOthers();
        let canReadAppointmentNotes = this.props.permissions.canReadAppointmentNotes();
        let canCreateAppointmentNote = isOwn ? this.props.permissions.canCreateAppointmentNoteOwn() : this.props.permissions.canCreateAppointmentNoteOther();

        return <div className="">
            <div className="d-flex justify-content-end mt-2">
                {this.state.appointmentToAccept &&
                <CenteredModal title={$$('accept_appointment_confirmation_header')
                    .replace('{fullname}', appointmentUtils.getPatient(this.state.appointmentToAccept).fullname)
                    .replace('{date}', moment(this.state.appointmentToAccept.starts_at).format('D MMMM HH:mm'))}
                               show={this.state.acceptAppointmentModalOpened}
                               onHide={() => {
                                   this.setState({acceptAppointmentModalOpened: false})
                               }}
                               onConfirm={this.acceptAppointment}
                               confirmBtnLabel={$$('accept_appointment_label')}
                >
                    {$$('accept_appointment_confirmation_body')}
                </CenteredModal>}
                {this.state.appointmentToCancel &&
                <CenteredModal title={$$('cancel_appointment_confirmation_header')
                    .replace('{fullname}', appointmentUtils.getPatient(this.state.appointmentToCancel).fullname)
                    .replace('{date}', moment(this.state.appointmentToCancel.starts_at).format('D MMMM HH:mm'))}
                               show={this.state.cancelAppointmentModalOpened}
                               onHide={() => {
                                   this.setState({cancelAppointmentModalOpened: false, note:""})
                               }}
                               onConfirm={this.updateAppointmentStatus}
                               confirmBtnLabel={$$('cancel_appointment_label')}
                >
                    {$$('cancel_appointment_confirmation_body')}
                    <div onChange={this.onChangeCancelStatusValue} className="day-picker-popup-line-start">
                        <div className="custom-control custom-radio custom-control-inline">
                            <input className="custom-control-input" type="radio" name="CancelStatus"
                                   id="inlineRadio1"
                                   checked={this.state.cancelStatus === "CANCELED_BY_PROVIDER"}
                                   value="On"/>
                            <label className="custom-control-label"
                                   htmlFor="inlineRadio1">{$$("CANCELED_BY_PROVIDER".toLowerCase())}</label>
                        </div>
                        <div className="custom-control custom-radio custom-control-inline">
                            <input className="custom-control-input" type="radio" name="CancelStatus"
                                   id="inlineRadio2"
                                   checked={this.state.cancelStatus === "CANCELED_BY_PATIENT"}
                                   value="Off"/>
                            <label className="custom-control-label"
                                   htmlFor="inlineRadio2">{$$("CANCELED_BY_PATIENT".toLowerCase())}</label>
                        </div>
                    </div>
                    {canCreateAppointmentNote && <div className={"pt-2"}>
                        <textarea className="form-control" rows="4"
                                  value={this.state.note}
                                  placeholder={$$('note_label')}
                                  name="note"
                                  onChange={(e)=>{this.setState({note:e.target.value})}}
                        />
                    </div>}
                </CenteredModal>
                }
                {this.state.appointmentToPaid &&
                <CenteredModal title={$$('mark_as_paid_appointment_confirmation_header')
                    .replace('{fullname}', appointmentUtils.getPatient(this.state.appointmentToPaid).fullname)
                    .replace('{date}', moment(this.state.appointmentToPaid.starts_at).format('D MMMM HH:mm'))}
                               show={this.state.paidAppointmentModalOpened}
                               onHide={() => {
                                   this.setState({paidAppointmentModalOpened: false})
                               }}
                               onConfirm={this.markAppointmentAsPaid}
                               confirmBtnLabel={$$('mark_appointment_as_paid_btn')}
                >
                    <p>{$$('mark_as_paid__appointment_confirmation_body')}</p>
                    <p className={"text-danger"}>
                        {$$("irreversible_action")}
                    </p>
                </CenteredModal>}

                {!appointmentUtils.isAppointmentCanceled(this.props.appointment.status)
                && this.props.appointment.payment_status !== COMPLETED
                && this.props.appointment.appointment_price.location_type === 'ON_SITE'
                && this.props.appointment.appointment_price.billing_type !== 'ONLINE'
                && this.props.appointment.appointment_price.price_cents > 0
                && canChangePaymentStatus
                && <RoundButton title={$$("mark_appointment_as_paid_btn")} btn_classes="btn-outline-primary mr-2"
                                icon_classes={"far fa-credit-card"} onClick={() => {
                    this.setState({
                        paidAppointmentModalOpened: true,
                        appointmentToPaid: this.props.appointment
                    });
                }}>-</RoundButton>}

                {this.props.onEditAppointment && canEditAppointment && !this.props.appointment.text_only && appointmentUtils.isAppointmentActive(this.props.appointment.status)
                    && <RoundButton title={$$("reschedule")}
                                    btn_classes="btn-outline-primary mr-2"
                                    icon_classes="la la-edit"
                                    onClick={()=>this.props.onEditAppointment(this.props.appointment)}
                            />
                }

                {canReadAppointmentNotes && <NotesRoundButton title={$$("notes")} onClick={() => {
                    this.props.onNotesClick(this.props.appointment)
                }}/>}

                {this.props.appointment.status === 'WAITING_FOR_DOCTOR_APPROVAL' && canChangeState &&
                <OkRoundButton title={$$("accept_appointment_label")} style={{'marginLeft': '0.625rem'}}
                               onClick={() => {
                                   this.setState({
                                       acceptAppointmentModalOpened: true,
                                       appointmentToAccept: this.props.appointment
                                   });
                               }}/>}
                {appointmentUtils.canCancel(this.props.appointment) &&
                canChangeState &&
                <CancelRoundButton title={$$('cancel_btn')} style={{'marginLeft': '0.625rem'}} onClick={() => {
                    this.setState({
                        cancelAppointmentModalOpened: true,
                        appointmentToCancel: this.props.appointment
                    });
                }}/>}
            </div>
        </div>
    }
}

CustomActions.propTypes = {
    appointment: PropTypes.any,
    onAcceptAppointment: PropTypes.func,
    onNotesClick: PropTypes.func,
    onMarkAppointmentAsPaid: PropTypes.func,
    onCancelAppointment: PropTypes.func,
    permissions: PropTypes.object,
    loggedInUserId: PropTypes.string
};

PatientPage.propTypes = {
    clearPatientRegistrationData: PropTypes.func,
    userId: PropTypes.string,
    settings: PropTypes.object,
    formDisabled: PropTypes.any,
    updateAppointmentStatus: PropTypes.func,
    clearSelectedUserData: PropTypes.func,
    markAppointmentAsPaid: PropTypes.func,
    clearUserAppointments: PropTypes.func,
    createNote: PropTypes.func,
    updateNote: PropTypes.func,
    deleteNote: PropTypes.func,
    history: PropTypes.any,
    location: PropTypes.any,
    selectedUser: PropTypes.any,
    fetchAppointments: PropTypes.func,
    createNewPatient: PropTypes.func,
    GetStaffList: PropTypes.func,
    loadPatients: PropTypes.func,
    i18n: PropTypes.object,
    staffMembers: PropTypes.any,
    internalStaffMembers: PropTypes.any,
    registration: PropTypes.object,
    orgId: PropTypes.string,
    user: PropTypes.object,
    userAppointments: PropTypes.array,
    organisation: PropTypes.object,
    permissions: PropTypes.object
};

function mapStateToProps(state) {
    return {
        staffMembers: state.management.staff,
        internalStaffMembers: state.management.internalStaff,
        i18n: state.language,
        settings: state.settings,
        userAppointments: state.userAppointments.data,
        selectedUser: state.selectedUser.data,
        orgId: state.management.selectedOrganisation.id,
        user: state.userInfo.data,
        userId: state.userInfo.data.id,
        formDisabled: state.formInteractions,
        organisation: state.management.selectedOrganisation,
        permissions: Permissions.fromOrg(state.userInfo.data.organizations.find(o => o.id === state.management.selectedOrganisation.id))
    }
}

export default connect(mapStateToProps, {
    clearSelectedUserData,
    fetchAppointments,
    GetStaffList,
    clearUserAppointments,
    updateAppointmentStatus,
    updateAppointment,
    createNote,
    updateNote,
    deleteNote,
    markAppointmentAsPaid
})(PatientPage)


export class Appointments extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {selectedAppointmentId: '', range:'ALL', selectedType: "0"};
        this.state.appointments = this.applyRange(this.props.appointments)
    }

    /**
     * An event handler triggered when a new appointment is choosen
     * from the list of appointments
     *
     * @param {object} appointment - the appointment object
     */
    onAppointmentChange = (appointment) => {
        let selectedAppointmentId = appointment.id;
        this.setState({selectedAppointmentId});
    }


    getFilteredAppointments = () => {
        return this.props.appointments.filter((appointment) => {
            switch (this.state.selectedType) {
                case "0":
                    return true;

                case "2":
                    return appointment.status === "COMPLETED";

                case "1":
                    return !appointment.text_only && appointment.status !== "COMPLETED" && !appointment.status.includes("CANCELED");

                case "3":
                    return !!(appointment.text_only && appointment.status !== "COMPLETED" && !appointment.status.includes("CANCELED"));

                default:
                    return true;

            }
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.appointments !== this.props.appointments) {
            this.applyFilter();
        }
    }

     applyRange = (appointments) => {
         return appointments.filter((appointment) => {
             if (this.state.range === 'TODAY') {
                return isSameDay(appointment.starts_at, Date.now());
             }
             return true;
         });
     }


    render() {
        return (
            <div className={"minus-twenty-margin-top"}>
                {!this.props.staffPage &&
                    <AppointmentFilters
                        rangeChanged = {this.rangeChanged}
                        getOnChange = {this.getOnChange}
                        selectedType ={this.state.selectedType}
                        range ={this.state.range}
                    />}
                <div className='row zero-margin-row'>
                    <div className='col-xs-12 col-md-12'>
                        <div className='row'>
                            <div className='w-100 appointments-tab'>
                                {this.state.appointments.map(a => {
                                    return <Appointment key={a.id}
                                                        appointment={a}
                                                        settings={this.props.settings}
                                                        staffList={this.props.staffList}
                                                        onNotesClick={this.props.onNotesClick}
                                                        staffPage={this.props.staffPage}
                                                        selectedAppointmentId={this.state.selectedAppointmentId}
                                                        onAppointmentChange={this.onAppointmentChange}
                                                        customActions={this.props.customActions}
                                                        selectedUser={this.props.selectedUser}
                                                        orgId={this.props.orgId}
                                                        loggedInUserId={this.props.loggedInUserId}
                                                        loggedInUser={this.props.loggedInUser}
                                                        organisation={this.props.organisation}
                                                        permissions={this.props.permissions}
                                                        i18n={this.props.i18n}/>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getOnChange = ({name, value}) => {
        this.setState({selectedType: value}, this.applyFilter);
    }

    rangeChanged = (r) => {
        this.setState({range:r}, this.applyFilter);
    }

    applyFilter = () => {
        this.setState({appointments:this.applyRange(this.getFilteredAppointments()), selectedAppointmentId:''})
    }
}

Appointments.propTypes = {
    appointments: PropTypes.any,
    selectedUser: PropTypes.any,
    i18n: PropTypes.any,
    onNotesClick: PropTypes.func,
    settings: PropTypes.object,
    staffPage: PropTypes.bool,
    staffList: PropTypes.array,
    customActions: PropTypes.any,
    permissions: PropTypes.object,
    loggedInUserId: PropTypes.string,
    loggedInUser: PropTypes.object
};

class Appointment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            encounter: null,
            doctor_tests_templates: []
        }
        this.ref = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedAppointmentId !== prevProps.selectedAppointmentId && this.props.appointment.id === this.props.selectedAppointmentId) {

            const _this = this;
            if (snapshot) {
                window.requestAnimationFrame(function () {
                    let node = _this.ref.current;
                    if (node && document.scrollingElement) {
                        let offsetTop = node.offsetTop;
                        if (Math.round(offsetTop) !== Math.round(snapshot.offsetTop)) {
                            let diff = offsetTop - snapshot.offsetTop;
                            let top = Math.max(snapshot.scrollTop + diff, 0);
                            document.scrollingElement.scrollTo({top: top, behavior: "instant"});
                        }
                    }
                });
            }
        }
    }

    getSnapshotBeforeUpdate(prevProps, prevState){
        if (this.props.selectedAppointmentId !== prevProps.selectedAppointmentId && this.props.appointment.id === this.props.selectedAppointmentId) {
            return { offsetTop: this.ref.current.offsetTop, scrollTop: document.scrollingElement.scrollTop}
        }
        return null;
    }

    /**
     * Creates the time cell of the table
     *
     * @returns {ReactElement} the created table cell
     */
    getTimeCell = () => {
        const redDot = classNames({"red-dot": this.props.appointment.has_not_seen_patient_documents});
        return <div className='d-flex text-left full-width justify-content-center'>
            <div className="d-flex align-items-center pt-0 pr-2 pl-2 pb-2 ">
                <span className={redDot}></span>
            </div>
            <div>
                <div className='appointments-tab-column-label medrec-grey-2'>{$$("date_label")}</div>
                <div className='appointments-date-label no-wrap'>
                    {moment(this.props.appointment.starts_at).format('D MMM')}
                    <span className="appointments-year-label">&nbsp;
                        {moment(this.props.appointment.starts_at).format('YYYY')}
                            </span>
                </div>
                <div className="text-left appointments-year-label kt-font-lg">
                    <i className="flaticon2-time medrec-grey-2"/>
                    {moment(this.props.appointment.starts_at).format('LT')}
                </div>
            </div>
        </div>;
    }

    /**
     * Creates the status cell of the table
     *
     * @returns {ReactElement} the created table cell
     */
    getStatusCell = () => {
        let paymentStatus = paymentStatusUtils.paymentStatus(this.props.appointment);

        return <div className='text-left'>
                    <div className='appointments-tab-column-label medrec-grey-2'>{$$("status_label")}</div>
                    <div className="patient-age"
                         style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, this.props.appointment.status)}}>
                        &nbsp;&bull;&nbsp;{appointmentUtils.statusText(this.props.appointment)}
                        <div className={paymentStatus.colour}>
                            &nbsp;&bull;&nbsp;{this.props.appointment.appointment_price.price_cents > 0 ? paymentStatus.label : $$("free_label")}
                        </div>
                    </div>
                </div>
    }

    /**
     * An event handler triggered when an appointment is clicked
     */
    onAppointmentClick = () => {
        this.props.onAppointmentChange(this.props.appointment);
        if (this.props.appointment.encounter_id && this.state.encounter == null) {
            clinicService.fetchAppointmentEncounter(this.props.appointment.encounter_id, null).then((encounter) => {
                if (encounter) {
                    this.setState({encounter});
                }
            });
        }

    }

    getStaffFullNameWithTitle = (staffMember) => {
        for (let i in this.props.staffList) {
            if (staffMember.user_id === this.props.staffList[i].id) {
                return getClinicianNameWithTitle(this.props.staffList[i])
            }
        }
    }

    getProviderCell = () => {
        let name = this.props.staffPage ? appointmentUtils.getPatient(this.props.appointment).fullname : this.getStaffFullNameWithTitle(appointmentUtils.getProvider(this.props.appointment));
        let label = this.props.staffPage ? $$("patient_label") : $$("clinician_label");
        return <div>
            <div className='appointments-tab-column-label medrec-grey-2'>{
                label
            }</div>
            <div className="">{name}</div>
        </div>
    }

    createEmptyEncounter = async (patientId, providerId) => {
        let encounter = {};
        encounter.subjective_data = "";
        encounter.objective_data = "";
        encounter.preliminary_diagnosis = false;
        encounter.conclusion = "";
        encounter.main_diagnosis = null;
        encounter.main_diagnosis_code = null;
        encounter.therapy = "";
        encounter.concomitant_diseases_codes = [];
        encounter.concomitant_diseases = [];
        encounter.additional_info = "";
        encounter.tests = [];
        encounter.subject = patientId;
        if (providerId === this.props.loggedInUserId) {
            return await usersService.createEncounter(this.props.appointment.id, encounter).then((encounter) => {
                this.props.appointment.encounter_id = encounter.id;
                this.setState({encounter})
                return encounter
            });
        } else {
            return await clinicService.createEncounter(this.props.appointment.id, providerId, encounter).then((encounter) => {
                this.props.appointment.encounter_id = encounter.id;
                this.setState({encounter})
                return encounter
            });
        }
    }

    deleteTestResult = () => {
        if (appointmentUtils.getProviderId(this.state.encounter) === this.props.loggedInUserId) {
            usersService.deleteTestsResults(this.state.encounter.id, this.state.selectedTestResultToDelete.template_id)
                .then(() => {
                    usersService.fetchAppointmentEncounter(this.state.encounter.id, null).then((encounter) => {
                        if (encounter) {
                            this.setState({encounter});
                            this.setState({
                                showDeleteTestResultsModal: false, selectedTestResultToDelete: undefined
                            });
                        }
                    });
                })
        } else {
            clinicService.deleteTestsResults(this.state.encounter.id, this.state.selectedTestResultToDelete.template_id)
                .then(() => {
                    clinicService.fetchAppointmentEncounter(this.state.encounter.id, null).then((encounter) => {
                        if (encounter) {
                            this.setState({encounter});
                            this.setState({
                                showDeleteTestResultsModal: false, selectedTestResultToDelete: undefined
                            });
                        }
                    });
                })
        }
    }

    appointmentNameCell = () => {
        let h = this.props.appointment.appointment_price;
        return <div className='text-left'>
            <div
                className='appointments-tab-column-label medrec-grey-2'>{this.props.appointment.text_only ? $$("text_consultation") : $$("appointment_label")}</div>
            <div
                className="">&nbsp;&bull;&nbsp;{h.encounter_type === 'OTHER' ? h.name.toLowerCase() : $$(h.encounter_type.toLowerCase())}</div>
            {!this.props.appointment.text_only && <div className="">&nbsp;&bull;&nbsp;{appointmentUtils.location(this.props.appointment)}</div>}
        </div>
    }

    updateEncounter = (encounterId) => {
        clinicService.fetchAppointmentEncounter(encounterId ? encounterId : this.state.encounter.id, null).then((encounter) => {
            if (encounter) {
                this.setState({encounter});
            }
        });
    }

    render() {
        let isSelectedAppointment = this.props.selectedAppointmentId === this.props.appointment.id;
        const activeClass = classNames('appointment-containter', {
            'appointment-active': isSelectedAppointment
        });

        let lang = _.contains(['en', 'sq'], this.props.i18n.selected.lang) ? 'en' : this.props.i18n.selected.lang;
        moment.locale(lang);

        if (appointmentUtils.isGroupEventParent(this.props.appointment)) {
            return <div ref={this.ref}>
                <div className={activeClass} onClick={this.onAppointmentClick}>
                    <div className="d-flex">
                        <div className='d-flex appointment-container-row'>
                            <div className="text-nowrap ml-0 mr-2">
                                {this.getTimeCell()}
                            </div>
                        </div>
                        <div className="flex-fill">
                            <div className="pl-2 pr-2 appointment-container-row">
                                <div className='appointments-tab-column-label medrec-grey-2'>{$$("appointment_label")}
                                </div>
                                <div className="">{this.props.appointment.appointment_price.encounter_type === 'OTHER' ? this.props.appointment.appointment_price.name.toLowerCase() : $$(this.props.appointment.appointment_price.encounter_type.toLowerCase())}
                                </div>
                            </div>
                            <div className='card-body bg-light p-0 mb-2'>
                                <div className="d-flex space-between-justify">
                                    <div className='p-2 flex-fill w-50'>
                                        <div className='text-left'>
                                            <div className='appointments-tab-column-label medrec-grey-2'>{$$("occupied_seats")}</div>
                                            <div
                                                className="">&nbsp;&nbsp;{this.props.appointment.used_slots} / {this.props.appointment.max_patients}</div>
                                        </div>
                                    </div>
                                    <div className="p-2 flex-fill w-50">
                                        <div className='text-left'>
                                            <div
                                                className='appointments-tab-column-label medrec-grey-2'>{$$("status_label")}</div>
                                            <div className="patient-age"
                                                 style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, this.props.appointment.status)}}>
                                                &nbsp;&bull;&nbsp;{$$(this.props.appointment.status.toLowerCase())}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
            </div>
        }

        let patient = appointmentUtils.getPatient(this.props.appointment);
        let selectedUser = this.props.selectedUser ? this.props.selectedUser : patient;
        let providerId = appointmentUtils.getProviderId(this.props.appointment);
        let clinician = appointmentUtils.getProvider(this.props.appointment);
        let showTestsResultEdit = this.state.tests_template && this.state.encounter;
        let userDetails = this.props.staffPage ? patient : clinician;

        let isOwn = this.props.loggedInUserId === providerId;
        let canShowAddProviderDocuments =  isOwn ? this.props.permissions.canCreateOwnProviderDocument() : this.props.permissions.canCreateOwnProviderDocumentForOtherEncounter();
        let canEditTestResults = isOwn ? this.props.permissions.canEditTestResultsOwnEncounter() : this.props.permissions.canEditTestResultsOthersEncounter();
        let canPickTemplate = isOwn ? this.props.permissions.canCreateTestResultsOwnEncounter() : this.props.permissions.canCreateTestResultsOthersEncounter();
        let canDeleteTestResults = isOwn ? this.props.permissions.canDeleteTestResultsOwnEncounter() : this.props.permissions.canDeleteTestResultsOthersEncounter();

        return (
            <div ref={this.ref}>
                <div className={activeClass} onClick={this.onAppointmentClick}>
                    <div className="d-flex ">
                        <div className='d-flex appointment-container-row'>
                            <div className="text-nowrap ml-0 mr-2">
                                {this.getTimeCell()}
                            </div>
                        </div>
                        <div className="flex-fill">
                            <div className='row appointment-container-row space-between-justify'>
                                <div className="d-flex pl-2 pr-2">
                                        <div className="mr-2">
                                        <UserImageWrap userID={userDetails.user_id}
                                                       show_red_dot={this.props.appointment.has_not_seen_patient_documents}
                                                       classnames='patient-img'/>
                                        </div>
                                    {this.getProviderCell()}
                                </div>
                                <div className='pl-2 pr-2'>
                                    {React.cloneElement(this.props.customActions, {
                                        appointment: this.props.appointment,
                                        onNotesClick: this.props.onNotesClick
                                    })}
                                </div>
                            </div>
                            <div className='card-body bg-light p-0'>
                                <div className="d-flex space-between-justify">
                                    <div className='p-2 flex-fill w-50'>
                                        {this.appointmentNameCell()}
                                    </div>
                                    <div className="p-2 flex-fill w-50">
                                        {this.getStatusCell()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     {showTestsResultEdit && <div className="p-3"><TestsResultEdit template={this.state.tests_template} encounter_id={this.state.encounter.id}
                                                                  patient_id={this.state.encounter.subject}
                                                                  onSave={() => {
                                                                      if (this.props.loggedInUserId === providerId) {
                                                                          usersService.saveTestsResults(this.state.encounter.id, [this.state.tests_template]).then(() => {
                                                                              usersService.fetchAppointmentEncounter(this.state.encounter.id, null).then((encounter) => {
                                                                                  if (encounter) {
                                                                                      this.setState({encounter});
                                                                                      this.setState({tests_template: undefined})
                                                                                  }
                                                                              })
                                                                          })
                                                                      } else {
                                                                          clinicService.saveTestsResults(this.state.encounter.id, [this.state.tests_template]).then(() => {
                                                                              clinicService.fetchAppointmentEncounter(this.state.encounter.id, null).then((encounter) => {
                                                                                  if (encounter) {
                                                                                      this.setState({encounter});
                                                                                      this.setState({tests_template: undefined})
                                                                                  }
                                                                              })
                                                                          })
                                                                      }
                                                                  }} onCancel={() => {
                        this.setState({tests_template: undefined})
                    }}/></div>}
                    {isSelectedAppointment
                        && canPickTemplate
                        && !showTestsResultEdit
                        && !appointmentUtils.isAppointmentCanceled(this.props.appointment.status)
                        && !appointmentUtils.isOverdueTextConsultation(this.props.appointment)
                        && <TestTemplatesPicker providerId={providerId} orgId={this.props.orgId}
                                                                   loggedInUserId={this.props.loggedInUserId}
                                                                   onTemplateSelected={(test_template) => {
                                                                       this.setState({tests_template: test_template})
                                                                       if (!this.state.encounter) {
                                                                           this.createEmptyEncounter(patient.user_id, providerId);
                                                                       }
                                                                   }}/>
                    }

                    {isSelectedAppointment
                        && canShowAddProviderDocuments
                        && !showTestsResultEdit
                        && !appointmentUtils.isAppointmentCanceled(this.props.appointment.status)
                        && !appointmentUtils.isOverdueTextConsultation(this.props.appointment)
                        && <AddProviderDocuments patient={patient}
                                                 providerId={providerId}
                                                 settings={this.props.settings}
                                                 i18n={this.props.i18n.selected}
                                                 formDisabled={false}
                                                 selectedUser={selectedUser}
                                                 updateParent={(encounterId) => {
                                                     this.updateEncounter(encounterId);
                                                 }}
                                                 encounter = {this.state.encounter}
                                                 createEmptyEncounter={this.createEmptyEncounter}/>
                    }
                    {this.state.encounter != null && !showTestsResultEdit &&
                    <Collapse isOpened={isSelectedAppointment}>
                        <EncounterDetails settings={this.props.settings} encounter={this.state.encounter}
                                          i18n={this.props.i18n.selected} selectedUser={selectedUser}
                                          appointment={this.props.appointment}
                                          isAdmin={true}
                                          isNotProvider={providerId !== this.props.loggedInUserId}
                                          updateEncounter={() => {
                                              this.updateEncounter();
                                          }}
                                          editTestResults={(result)=>{this.setState({tests_template:result})}}
                                          deleteTestResults={(result) => {
                                              this.setState({
                                                  showDeleteTestResultsModal: true, selectedTestResultToDelete: result
                                              })
                                          }}
                                          hideCreateDocumentToEncounter={!canShowAddProviderDocuments}
                                          hideEditTestResults={!canEditTestResults}
                                          hideDeleteTestResults={!canDeleteTestResults}
                                          organisation={this.props.organisation}
                                          loggedInUser={this.props.loggedInUser}
                        />
                    </Collapse>}
                    {this.state.encounter == null && !showTestsResultEdit &&
                    <Collapse isOpened={isSelectedAppointment}>
                        <hr className="hr-text" data-content={$$("eccounter_data_separator")}/>
                        <br/>
                        <div className='medrec-grey-2 centered-text'>
                            {$$("no_encounter_found_label")}</div>
                        <br/>
                    </Collapse>}
                </div>
                <hr/>


                <CenteredModal2
                    title={$$('delete_test_results')}
                    headerClassName="modal-header-class"
                    body={$$('delete_test_results_message')}
                    dialogClassName='delete-modal'
                    show={this.state.showDeleteTestResultsModal}
                    onHide={() => this.setState({
                        showDeleteTestResultsModal: false, selectedTestResultToDelete: undefined
                    })}
                    cancelBtnLabel={$$('cancel_btn')}
                    onConfirm={this.deleteTestResult}
                    id="delete-modal-test-results"
                    className="delete-modal-body"
                    idFooter="delete-modal-footer"
                    confirmBtnLabel={$$('delete_label')}
                    confirmBtnClass="danger"
                    idBtnPrimary="btn-danger"
                    idBtnSecondary="btn-secondary">
                    <h5 className={"bold"}>
                        {this.state.selectedTestResultToDelete ? this.state.selectedTestResultToDelete.name : ''}
                    </h5>
                    <div className="bin-align">
                        <i className="fas fa-trash-alt fa-3x"/>
                    </div>
                </CenteredModal2>
            </div>
        )
    }
}


Appointment.propTypes = {
    appointment: PropTypes.any,
    selectedUser: PropTypes.any,
    i18n: PropTypes.any,
    userId: PropTypes.string,
    staffPage: PropTypes.bool,
    onAppointmentChange: PropTypes.func,
    staffList: PropTypes.array,
    onNotesClick: PropTypes.func,
    settings: PropTypes.object,
    selectedAppointmentId: PropTypes.string,
    customActions: PropTypes.any,
    permissions: PropTypes.object,
    loggedInUserId: PropTypes.string,
    loggedInUser: PropTypes.object
};


class TestTemplatesPicker extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            doctor_tests_templates: []
        }
    }

    componentDidMount() {
        if (this.props.loggedInUserId === this.props.providerId) {
            usersService.fetchTestsTemplates().then(results => {
                this.setState({doctor_tests_templates: results})
            })
        } else {
            clinicService.fetchProviderTestTemplates(this.props.orgId, this.props.providerId).then(results => {
                this.setState({doctor_tests_templates: results})
            })
        }
    }

    render() {
        return <div>
            <CenteredModal2 header={$$('pick_test_template')}
                            dialogClassName='delete-modal'
                            show={this.state.show_pick_test_template_modal}
                            onHide={() => this.setState({show_pick_test_template_modal: false})}
                            cancelBtnLabel={$$('cancel_btn')}
                            onConfirm={() => {
                            }}
                            id="prescription-templates-modal-title"
                            className="delete-modal-body"
                            idFooter="delete-modal-footer"
                            confirmBtnLabel={$$('ok')}
                            confirmBtnClass="success"
                            idBtnPrimary="btn-success"
                            headerClassName="h5 align-items-center"
                            primary={true}
                            idBtnSecondary="btn-secondary">
                <TemplateSelector templates={this.state.doctor_tests_templates} onSelect={(t) => {
                    this.props.onTemplateSelected(cloneDeep(t));
                    this.setState({show_pick_test_template_modal: false});
                }}/>
            </CenteredModal2>

            {this.state.doctor_tests_templates.length > 0 &&
                <ExamLinkIcon onClick={() => {
                    this.setState({show_pick_test_template_modal: true})
                }} labelKey="pick_test_results_template"/>
            }
        </div>
    }
}


class AddProviderDocuments extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showAdd: false
        }
    }

    createDocument = () => {
        this.setState({showAdd: true})
    }


    closeAddModal = () => {
        this.setState({showAdd: false})
    }


    render() {



        return <div>
            <ExamLinkIcon onClick={this.createDocument} labelKey="add_new_document"/>
            <ProviderEncounterDocuments
                encounterDocuments={true}
                noList={true}
                isAdmin={true}
                showAdd = {this.state.showAdd}
                closeAddModal = {this.closeAddModal}
                patient={this.props.patient}
                providerId={this.props.providerId}
                createEmptyEncounter={this.props.createEmptyEncounter}
                encounter={this.props.encounter}
                onUpdate={this.props.updateParent}
                selectedUser={this.props.selectedUser}
            />
        </div>
    }
}

AddProviderDocuments.propTypes = {
    patient: PropTypes.any,
    providerId: PropTypes.any,
    createEmptyEncounter: PropTypes.func,
    encounter: PropTypes.any,
    updateParent: PropTypes.func,
    selectedUser: PropTypes.any,
};