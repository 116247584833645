import React from 'react'
import {$$} from '../../helpers/localization';
import {getPrescriptionDetailsString, isNhisMedication, isNonMedicinalProduct} from "../../utils/medicationUtils";
import {documentsService} from "../../service/docments_service";
import EncounterDocuments from "./EncounterDocuments";
import TestsResultDisplay from "../examination/TestsResultDisplay";
import PropTypes from "prop-types";
import DownloadEditDeleteButtons from "../shared/DownloadEditDeleteButtons";
import {ListGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {ReactComponent as DeleteIcon} from "../../../public/media/icons/Delete.svg";
import {ReactComponent as EditIcon} from "../../../public/media/icons/Edit.svg";
import ProviderEncounterDocuments from "../examination/documents/ProviderEncounterDocuments";
import {ReportsWizard} from "../examination/ReportComponents";
import {prepareReport, replaceAllNewLines, replaceFirstNewLine} from "../../utils/reportUtils";
import {QuestionMarkTooltip} from "../common/tooltips";
import {usersService} from "../../service/users_service";
import {ReportLinkComponent} from "../examination/ReportLinkComponent";
import EncounterDetailsMedicalNoticeList from "../examination/EncounterDetailsMedicalNoticeList";
import EncounterDetailsEreferralsList from "../examination/EncounterDetailsEreferralsList";

export default class EncounterDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            documents: []
        }
    }

    editTooltip = props => (
        <Tooltip {...props} show={"true"}>{$$("edit_label")}</Tooltip>
    );

    deleteTooltip = props => (
        <Tooltip {...props} show={"true"}>{$$("delete_label")}</Tooltip>
    );

    componentDidMount() {
        if (this.props.encounter.document_ids && this.props.encounter.document_ids.length > 0) {
            documentsService.fetchEncounterDocuments(this.props.encounter.id)
                .then(async r => {
                    this.setState({documents: r});

                    let params = {
                        documentIds: this.props.encounter.document_ids.filter(id => !r.find(document => document.id === id))
                    }

                    if(params.documentIds && params.documentIds.length > 0 ) {
                        await documentsService.removeMissingUnseenDocuments(this.props.encounter.id, params).then(() => {
                            usersService.fetchAppointmentEncounter(this.props.encounter.id, null).then((encounter) => {
                                if (encounter) {
                                    this.setState({encounter});
                                }
                            });
                        })
                    }
                })
        }
    }

    /**
     * Get concomitant diseases for the encounter
     */
    getConcomitantDiseases = () => {
        let concomitantDiseases = "";
        for (let i = 0; i < this.props.encounter.concomitant_diseases.length; i++) {
            if (i === 0) {
                concomitantDiseases += this.props.encounter.concomitant_diseases[i];
            } else {
                concomitantDiseases += "    |    " + this.props.encounter.concomitant_diseases[i];
            }
        }

        return concomitantDiseases;
    }

    /**
     * Get tests for the encounter
     */
    getTests = () => {
        let tests = "";
        for (let i = 0; i < this.props.encounter.tests.length; i++) {
            if (i === 0) {
                tests += this.props.encounter.tests[i];
            } else {
                tests += "\n" + this.props.encounter.tests[i];
            }
        }

        return tests;
    }

    getPrescriptions = () => {
        return <div>
            {this.props.encounter.prescriptions.filter(p => !isNonMedicinalProduct(p)).map(p => {
                if (isNhisMedication(p)) {
                    return <div key={p.id}>&bull; {p.name + " - " + replaceAllNewLines(p.notes, "; ")}</div>
                }
                return <div key={p.id}>&bull; {p.name + " - " + getPrescriptionDetailsString(p)}</div>
            })}
        </div>
    }

    getNonMedicinalProductPrescriptions = () => {
        return <div>
            {this.props.encounter.prescriptions.filter(p => isNonMedicinalProduct(p)).map(p =>
                <div key={p.id}>&bull; {p.notes}</div>)}
        </div>
    }

    getAdditionalResults = () => {
        if (this.props.isNotProvider && this.props.encounter.tests_results.length > 0) {
            return <ListGroup className={"shadow-boxes-list"}>
                {this.props.encounter.tests_results.map((entry, idx) => {
                    return <ListGroup.Item key={idx}>
                        <div style={{marginRight: '0.5rem', marginTop: '0.5rem'}}>
                            <h6 className="card-title mb-4 pointer btn-link" onClick={() => {
                                this.setState({displayTestResult: entry})
                            }}>{entry.name}</h6>
                            <div className={"smaller-text medrec-grey-2"}>{}</div>
                        </div>
                        {!this.props.hideDeleteTestResults && <span onClick={() => {
                            this.props.deleteTestResults(entry)
                        }}
                              className="pointer prescription-delete-icon">
                            <OverlayTrigger
                                placement={"top"}
                                overlay={this.deleteTooltip}
                                popperConfig={{
                                    modifiers: {
                                        preventOverflow: {
                                            enabled: false
                                        },
                                        hide: {
                                            enabled: false
                                        }
                                    }
                                }}
                            >
                        <DeleteIcon className="svg-blue" height="17px" width="17px"/>
                    </OverlayTrigger>
                            </span>}
                        {!this.props.hideEditTestResults &&
                        <span onClick={() => {
                            this.props.editTestResults(entry)
                        }}
                              className="pointer prescription-edit-icon">
                            <OverlayTrigger
                                placement={"top"}
                                overlay={this.editTooltip}
                                popperConfig={{
                                    modifiers: {
                                        preventOverflow: {
                                            enabled: false
                                        },
                                        hide: {
                                            enabled: false
                                        }
                                    }
                                }}
                            >
                        <EditIcon className="svg-blue" height="17px" width="17px"/>
                    </OverlayTrigger>
                            </span>}
                    </ListGroup.Item>
                })}
                {this.props.children}
            </ListGroup>
        }


        return this.props.encounter.tests_results.map((entry, idx) => {
            return <React.Fragment key={idx}>
                {idx > 0 && <span className="ml-3 mr-3 medrec-grey-2">|</span>}
                <span key={idx} className="card-title pointer btn-link h6" onClick={() => {
                    this.setState({displayTestResult: entry})
                }}>{entry.name}</span>
            </React.Fragment>
        })
    }

    isMainEncounterEmpty = () => {
        return !this.props.encounter.main_diagnosis &&
            !this.props.encounter.objective_data &&
            !this.props.encounter.subjective_data &&
            !this.props.encounter.therapy;
    }

    getReportData = (reportType, patientData) => {
        let org = this.props.organisation ? this.props.organisation : this.props.loggedInUser.organizations.find(o => o.id === this.props.appointment.organization_id);
        return prepareReport(reportType, this.props.encounter, this.props.settings, patientData, org)
    }

    render() {

        if (this.state.displayTestResult) {

            return <div className={"p-5"}><TestsResultDisplay result={this.state.displayTestResult} onCancel={() => {
                this.setState({displayTestResult: undefined})
            }}/></div>
        }

        return (
            <div>
                {!this.isMainEncounterEmpty() &&
                    <div className="medrec-grey-2 mt-1 mb-1 text-center">{$$("eccounter_data_separator")}</div>}
                <div className="card-body">
                    {this.isMainEncounterEmpty() && <div
                        className="text-center medrec-red-2 mb-4">{$$("main_examination_result_fields_are_empty")}</div>}
                    {!this.props.hideCreateDocumentToEncounter && <div className={"p-2 pb-3"}>
                        <ReportLinkComponent onClick={() => {
                            this.setState({showReportsWizard: true})
                        }}/>
                    </div>}
                    {this.state.showReportsWizard && <ReportsWizard updateEncounter={() => {
                        this.props.updateEncounter()
                    }}
                                                                    lang={this.props.i18n.lang}
                                                                    patient={this.props.selectedUser}
                                                                    orgId={this.props.appointment.organization_id}
                                                                    getData={this.getReportData}
                                                                    onClose={() => {
                                                                        this.setState({showReportsWizard: false})
                                                                    }}/>}
                    {this.props.encounter.nhis_examination_nrn && <div className="row full-width">
                        <div className='medrec-grey-2 appointment-row-left'>
                            {$$("nhis.examination.nrn_number")}</div>
                        <div className='appointment-row-right'>
                            {this.props.encounter.nhis_examination_nrn}
                        </div>
                    </div>}
                    {this.props.encounter.main_diagnosis && <hr/>}
                    {this.props.encounter.main_diagnosis && <div className="row full-width">
                        <div className='medrec-grey-2 appointment-row-left'>
                            {$$("main_diagnosis_label")}</div>
                        <div className='appointment-row-right'>
                            {this.props.encounter.main_diagnosis}
                        </div>
                    </div>}
                    {this.props.encounter.main_diagnosis && <hr/>}
                    {this.props.encounter.concomitant_diseases && this.props.encounter.concomitant_diseases.length > 0 &&
                        <div className="row full-width">
                            <div className='medrec-grey-2 appointment-row-left'>
                                {$$("concomitant_and_diseases_complications_label")}</div>
                            <div className='appointment-row-right'>
                                {this.getConcomitantDiseases()}
                            </div>
                        </div>}
                    {this.props.encounter.concomitant_diseases && this.props.encounter.concomitant_diseases.length > 0 &&
                        <hr/>}

                    {this.props.encounter.preliminary_diagnosis && <div className="row full-width">
                        <div className='medrec-grey-2 appointment-row-left'>
                            {$$("preliminary_diagnosis")}</div>
                        <div className='appointment-row-right'>

                            <input className="big-checkbox" type="checkbox"
                                   name="is_preliminary_diagnosis"
                                   checked={this.props.encounter.preliminary_diagnosis}
                                   readOnly={true}

                            />

                        </div>
                    </div>}
                    {this.props.encounter.preliminary_diagnosis && <hr/>}

                    {this.props.encounter.subjective_data && <div className="row full-width">
                        <div className='medrec-grey-2 appointment-row-left'>
                            {$$("subjective_data_label")}</div>
                        <div className='appointment-row-right'>
                            {this.props.encounter.subjective_data}
                        </div>
                    </div>}
                    {this.props.encounter.subjective_data && <hr/>}

                    {this.props.encounter.objective_data && <div className="row full-width">
                        <div
                            className='medrec-grey-2 appointment-row-left'>{$$("objective_data_label")}</div>
                        <div className='appointment-row-right'>
                            {this.props.encounter.objective_data}
                        </div>
                    </div>}
                    {this.props.encounter.objective_data && <hr/>}

                    {this.props.encounter.tests && this.props.encounter.tests.length > 0 &&
                        <div className="row full-width">
                            <div className='medrec-grey-2 appointment-row-left'>
                                {$$("tests_label")}</div>
                            <div className='appointment-row-right'>
                                {this.getTests()}
                            </div>
                        </div>}
                    {this.props.encounter.tests && this.props.encounter.tests.length > 0 && <hr/>}


                    {this.props.encounter.therapy && <div className="row full-width">
                        <div className='medrec-grey-2 appointment-row-left'>
                            {$$("therapy_label")}</div>
                        <div className='appointment-row-right'>
                            {this.props.encounter.therapy}
                        </div>
                    </div>}
                    {this.props.encounter.therapy && <hr/>}

                    {this.props.encounter.prescriptions && this.props.encounter.prescriptions.filter(p=> !isNonMedicinalProduct(p)).length > 0 &&
                        <div className="row full-width">
                            <div className='medrec-grey-2 appointment-row-left'>
                                {$$("prescriptions")}</div>
                            <div className='appointment-row-right'>
                                {this.getPrescriptions()}
                            </div>
                        </div>}
                    {this.props.encounter.prescriptions && this.props.encounter.prescriptions.filter(p=> !isNonMedicinalProduct(p)).length > 0 && <hr/>}
                    {this.props.encounter.prescriptions && this.props.encounter.prescriptions.filter(p=> isNonMedicinalProduct(p)).length > 0 &&
                    <div className="row full-width">
                        <div className='medrec-grey-2 appointment-row-left'>
                            {$$("nhis.supplement")}</div>
                        <div className='appointment-row-right'>
                            {this.getNonMedicinalProductPrescriptions()}
                        </div>
                    </div>}
                    {this.props.encounter.prescriptions && this.props.encounter.prescriptions.filter(p=> isNonMedicinalProduct(p)).length > 0 && <hr/>}
                    {this.state.documents && this.state.documents.length > 0 &&
                        <EncounterDocuments documents={this.state.documents}
                                            encounter={this.props.encounter}
                                            selectedUser={this.props.selectedUser}
                                            appointment={this.props.appointment}
                                            updateEncounter={() => {
                                                this.props.updateEncounter();
                                            }}
                                            i18n={this.props.i18n}/>}

                    <ProviderEncounterDocuments
                        encounterDocuments={true}
                        encounter={this.props.encounter}
                        isAdmin={this.props.isAdmin}
                        appointment={this.props.appointment}
                        selectedUser={this.props.selectedUser}
                        onUpdate={() => {
                            this.props.updateEncounter();
                        }}
                    />

                    {this.props.encounter.tests_results && this.props.encounter.tests_results.length > 0 &&
                        <div className="row full-width">
                            <div className='medrec-grey-2 appointment-row-left'>
                                {$$("additional_results")}</div>
                            <div className='appointment-row-right'>
                                {this.getAdditionalResults()}
                            </div>
                        </div>}
                    {this.props.encounter.tests_results && this.props.encounter.tests_results.length > 0 && <hr/>}
                    {this.props.encounter.additional_info && <div className="row full-width">
                        <div className='medrec-grey-2 appointment-row-left'>
                            {$$("additional_info")}</div>
                        <div className='appointment-row-right'>
                            {this.props.encounter.additional_info}
                        </div>
                    </div>}
                    {this.props.encounter.additional_info && <hr/>}
                    {this.props.encounter.conclusion && <div className="row full-width">
                        <div className='medrec-grey-2 appointment-row-left'>
                            {$$("conclusion")}</div>
                        <div className='appointment-row-right'>
                            {this.props.encounter.conclusion}
                        </div>
                    </div>}
                    {this.props.encounter.conclusion && <hr/>}
                    {this.props.encounter.nhis_medical_notice_jsons && <div>
                        <div className='medrec-grey-2 text-center mb-3'>
                            {$$("nhis.medicalNotice.medical_notices")}</div>
                        <EncounterDetailsMedicalNoticeList lang={this.props.i18n.lang} encounter={this.props.encounter}/>
                    </div>}
                    {this.props.encounter.nhis_medical_notice_jsons && <hr/>}
                    {this.props.encounter.ereferrals && this.props.encounter.ereferrals.length > 0 && <div>
                        <div className='medrec-grey-2 text-center mb-3'>
                            {$$("nhis.ereferral.ereferrals")}</div>
                        <EncounterDetailsEreferralsList lang={this.props.i18n.lang} encounter={this.props.encounter}/>
                    </div>}
                </div>
            </div>
        )
    }
}

EncounterDetails.propTypes = {
    i18n: PropTypes.object,
    appointment: PropTypes.object,
    organisation: PropTypes.any,
    children: PropTypes.any,
    loggedInUser: PropTypes.any,
    isNotProvider: PropTypes.bool,
    updateParent: PropTypes.func,
    deleteTestResults: PropTypes.func,
    editTestResults: PropTypes.func,
    updateEncounter: PropTypes.func,
    isAdmin: PropTypes.bool,
    selectedUser: PropTypes.object,
    encounter: PropTypes.object,
    settings: PropTypes.object,
}