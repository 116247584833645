import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    deleteConsultationMenuOption,
    fetchPriceList,
    postNewConsultationMenuOption,
    updateConsultationMenuOption
} from '../../actions/users_actions'
import PriceSettingsHeader from './PriceSettingsHeader'
import PriceSettingsListView from './PriceSettingsListView'
import no_appointments from "../../resources/images/no_appointments.png";
import {$$} from "../../helpers/localization";
import {infoUtils} from "../../utils/infoUtils";
import {Modal} from "react-bootstrap";
import CreateConsultationOptionPopup from "./CreateConsultationOptionPopup";
import EditConsultationOptionPopup from "./EditConsultationOptionPopup";
import PropTypes from "prop-types";


class PriceSettings extends Component {
    state = {
        createOpen: false,
        editOpen: false,
        textOnly: false,
        selectedMenuItem: {}
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchPriceList()
    }

    closePopup = () => {
        this.setState({editOpen: false});
        this.setState({createOpen: false});
    }

    openCreatePopup = (textOnly) => {
        this.setState({createOpen: true, textOnly: textOnly});
    }

    openEditPopup = (MenuItem) => {
        this.setState({selectedMenuItem: MenuItem});
        this.setState({editOpen: true});
    }

    renderBody = () => {
        if (this.props.price_list.result && this.props.price_list.result.length > 0) {
            return <PriceSettingsListView
                priceList={this.props.price_list}
                openEdit={(MenuItem) => this.openEditPopup(MenuItem)}
                deleteConsultationMenuOption={this.deleteMenuItem}
                organisationNames={this.getOrganisationNames()}
                lang={this.props.i18n.selected.lang}
            />

        } else if (this.props.price_list.priceListRequest.finished) {
            let noPatients = {
                imgClass: 'no-vitals-logbook-img',
                primaryLabelClass: 'no-lab-results-primary-label',
                secondaryLabelClass: 'no-lab-results-secondary-label',
                src: no_appointments,
                primaryLabel: $$('your_price_list_is_empty'),
                secondaryLabel: ''
            }
            return infoUtils.noData(noPatients);
        }
    }

    submitNewMenuItem = (form) => {
        if (form) {
            this.props.postNewConsultationMenuOption(form).then(this.props.fetchPriceList)
        }
    }

    editMenuItem = (form) => {
        if (form) {
            this.props.updateConsultationMenuOption(form).then(this.props.fetchPriceList)
        }
    }

    deleteMenuItem = (id) => {
        this.props.deleteConsultationMenuOption(id).then(this.props.fetchPriceList)
    }

    getOrganisationNames = () => {
        let allOrganisationOptions = [];

        for (let i in this.props.organisations) {
            allOrganisationOptions.push({name: this.props.organisations[i].name, value: this.props.organisations[i].id})
        }

        return allOrganisationOptions
    }

    render() {
        return (
            <div>
                <div className='price-settings-card'>
                    <PriceSettingsHeader
                        openCreate={this.openCreatePopup}
                    />
                    {this.renderBody()}
                </div>

                <Modal show={this.state.createOpen}
                       scrollable={true}
                       onHide={this.closeEditPopup}
                       size={"xl"}
                       dialogClassName=""
                       backdrop="static">
                    <Modal.Body>
                        {this.state.createOpen && (
                            <CreateConsultationOptionPopup
                                closePopup={this.closePopup}
                                textOnly={this.state.textOnly}
                                createMenuOption={this.submitNewMenuItem}
                                formDisabled={this.props.formDisabled}
                                organisations={this.props.organisations}
                                lang={this.props.i18n.selected.lang}
                            />
                        )}
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.editOpen}
                       onHide={this.closeEditPopup}
                       scrollable={true}
                       size={"xl"}
                       dialogClassName=""
                       backdrop="static">
                    <Modal.Body>
                        {this.state.editOpen && (
                            <EditConsultationOptionPopup
                                closePopup={this.closePopup}
                                upDateMenuOption={this.editMenuItem}
                                selectedMenuItem={this.state.selectedMenuItem}
                                organisationNames={this.getOrganisationNames()}
                                organisations={this.props.organisations}
                                formDisabled={this.props.formDisabled}
                                lang={this.props.i18n.selected.lang}
                            />
                        )}
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

PriceSettings.propTypes = {
    deleteConsultationMenuOption: PropTypes.func,
    fetchPriceList: PropTypes.func,
    formDisabled: PropTypes.any,
    i18n: PropTypes.any,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    organisations: PropTypes.array,
    postNewConsultationMenuOption: PropTypes.func,
    price_list: PropTypes.object,
    updateConsultationMenuOption: PropTypes.func
}


function mapStateToProps(store) {
    return {
        price_list: store.priceList,
        organisations: store.userInfo.data.organizations,
        formDisabled: store.formInteractions,
        i18n: store.language
    }
}


const mapDispatchToProps = {
    fetchPriceList,
    deleteConsultationMenuOption,
    postNewConsultationMenuOption,
    updateConsultationMenuOption
}

export default connect(mapStateToProps, mapDispatchToProps)(PriceSettings);

