import {fetchHelper} from "../helpers/request_helper";
import {
    CLINIC_FETCH_APPOINTMENTS,
    GET_STAFF_LIST,
    MANAGEMENT_ADD_CONSULTATION_MENU_OPTION, MANAGEMENT_DELETE_APPOINTMENT_TIMETABLE_RANGE,
    MANAGEMENT_DELETE_CONSULTATION_MENU_OPTION,
    MANAGEMENT_GET_APPOINTMENT_TIMETABLE_RANGES, MANAGEMENT_POST_APPOINTMENT_TIMETABLE_RANGE
} from "../constants/api_paths";

export const managementService = {
    fetchStaffList,
    managementDeleteConsultationMenuOption,
    managementUpdateConsultationMenuOption,
    managementCreateConsultationMenuOption,
    managementGetDateRanges,
    managementCreateNewDateRange,
    managementUpdateDateRange,
    managementDeleteDateRange
}

export function fetchStaffList(organisationId) {
    return fetchHelper.callGet(GET_STAFF_LIST, {organizationId: organisationId})
}

export function managementDeleteConsultationMenuOption(id) {
    return fetchHelper.callDelete(MANAGEMENT_DELETE_CONSULTATION_MENU_OPTION.replace('{encounterPriceId}', id), null, null);
}

export function managementUpdateConsultationMenuOption(data) {
    return fetchHelper.callPut(MANAGEMENT_ADD_CONSULTATION_MENU_OPTION, data, null);
}

export function managementCreateConsultationMenuOption(data) {
    return fetchHelper.callPost(MANAGEMENT_ADD_CONSULTATION_MENU_OPTION, data, null);
}

export function managementGetDateRanges(org_id , providerId) {
    return fetchHelper.callGet(MANAGEMENT_GET_APPOINTMENT_TIMETABLE_RANGES, {org_id: org_id, providerId: providerId });
}

export function managementCreateNewDateRange(data) {
    return fetchHelper.callPost(MANAGEMENT_POST_APPOINTMENT_TIMETABLE_RANGE, data);
}

export function  managementUpdateDateRange(data) {
    return fetchHelper.callPut(MANAGEMENT_POST_APPOINTMENT_TIMETABLE_RANGE, data, null);
}

export function  managementDeleteDateRange(id) {
    return fetchHelper.callDelete(MANAGEMENT_DELETE_APPOINTMENT_TIMETABLE_RANGE.replace('{timetableRangeId}', id), null, null);
}