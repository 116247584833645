import React, {Component} from 'react'
import {connect} from 'react-redux'
import {$$} from "../../helpers/localization";
import PropTypes from "prop-types";



export default class NumberOfAppointmentsCard extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Get Appointment Stats.
     *
     * @returns Appointment Stats
     */
    getAppointmentStats = () => {
        return (
            typeof this.props.appointmentStats.today !== "undefined" ? this.props.appointmentStats : ({
                today: 0,
                thisWeek: 0,
                thisMonth: 0
            }))
    }

    getAppointmentNumber = () => {
        let appointmentStats = this.getAppointmentStats()

        if (this.props.type === "day") {
            return (appointmentStats.today)
        } else if (this.props.type === "week") {
            return (appointmentStats.thisWeek)
        } else if (this.props.type === "month") {
            return (appointmentStats.thisMonth)
        } else {
            return (0)
        }
    }

    render() {
        return (
            <div className="d-flex">
                <span className='now-next-later-icons'>
                          <i className='kt-menu__link-icon now-next-later-icon flaticon-calendar-3'/>
                    </span>
                <span className='now-next-later-text'>
                    <h1>{this.getAppointmentNumber()}</h1>
                    {this.getAppointmentNumber() === 1 ? $$('appointment_label') : $$('appointments_label')}
                </span>
            </div>
        )
    }
}

NumberOfAppointmentsCard.propTypes = {
    appointmentStats: PropTypes.object,
    dispatch: PropTypes.func,
    i18n:  PropTypes.string,
    providerAppointments: PropTypes.object,
    type: PropTypes.string
}