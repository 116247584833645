import React, {Component} from "react";
import {Link} from "react-router-dom";
import {version} from "../../../package.json";
import {$$} from "../../helpers/localization";
import {SelectOrganisationToManage} from "../../actions/management_actions";
import {changeLanguage} from "../../actions/language_actions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import PracticeList from "./PracticeList";
import {UpdateMenu} from "../../actions/menu_actions";

class SelectPracticePage extends Component {

    constructor(props) {
        super(props);
        if (props.organizations && this.props.organizations.length === 1) {
            this.onSelectPractice(this.props.organizations[0].id, this.props.organizations[0].name);
        }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.organizations !== this.props.organizations) {
            if (this.props.organizations.length === 1) {
                this.onSelectPractice(this.props.organizations[0].id, this.props.organizations[0].name);
            }
        }
    }

    onSelectPractice = (selectedId, selectedName) => {
        let organisation = {
            id: selectedId,
            name: selectedName
        }
        this.props.UpdateMenu(2)
        this.props.SelectOrganisationToManage(organisation);
    }

    showOrganisationSelection = () => {
        return !!(this.props.organizations && this.props.organizations.length !== 1);
    }

    render() {
        return (
            <>
                {this.showOrganisationSelection() && <div className="kt-grid kt-grid--ver kt-grid--root">
                    <div
                        id="kt_login"
                        className="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
                    >
                        <div
                            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
                            <div
                                className="kt-grid__item kt-grid__item--order-tablet-and-mobile-2 kt-grid kt-grid--hor kt-login__aside"
                            >
                                <div className="kt-grid__item">
                                    <Link to="">
                                        <div className="main-logo-label-select-practice">MEDREC : M</div>
                                        <div className="sub-logo-label">clinic</div>
                                    </Link>
                                </div>
                                <div
                                    className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver center-justify"/>

                                <div className="kt-grid__item">
                                    <div className="kt-login__info small">
                                        <div className="kt-login__copyright">
                                            &copy; {new Date().getFullYear().toString()} Sirma Medical Systems
                                            <div className="small">{$$("version")} {version}</div>
                                        </div>
                                        <div className="kt-login__menu">
                                            <a
                                                href="https://www.medrec-m.com/#!/remote"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="kt-link"
                                            >
                                                {$$("about")}
                                            </a>
                                            <a
                                                href="https://medrec-m.com"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="kt-link"
                                            >
                                                {$$("contact")}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <PracticeList
                                onSelectPractice={this.onSelectPractice}
                                userInfo={this.props.userInfo}
                                i18n={this.props.i18n}
                            />
                        </div>
                    </div>
                </div>}
            </>
        )

    }
}

SelectPracticePage.propTypes = {
    organizations: PropTypes.any,
    userInfo: PropTypes.any,
    UpdateMenu: PropTypes.func,
    SelectOrganisationToManage: PropTypes.func,
    i18n: PropTypes.object
}

function mapStateToProps(state) {
    return {
        userInfo: state.userInfo,
        organizations: state.userInfo.data.organizations,
        i18n: state.language.selected
    }
}

const mapDispatchToProps = {
    SelectOrganisationToManage,
    changeLanguage,
    UpdateMenu
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectPracticePage)
