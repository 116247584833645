import React, {Component, useMemo} from 'react'
import classNames from 'classnames'
import {$$, _$$} from "../../helpers/localization";
import {updateAppointmentStatus, usersService} from "../../service/users_service";
import {appointmentUtils} from "../../utils/appointmentUtils";
import MedicalHistoryButton from "../History/MedicalHistoryButton";
import {medicalHistoryHelper} from "../../helpers/medical_history_helper";
import {DASHBOARD_NEXT_PATIENT_MED_HISTORY, DASHBOARD_NEXT_PATIENT_VIDEO} from "../../constants/pages";
import {Routes} from "../../constants/routes";
import CancelAppointmentButton from "../appointments/CancelAppointmentButton";
import OpenAppointmentButton from "../appointments/OpenAppointmentButton";
import PaginationComponent from "../common/Pagination";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import isToday from "date-fns/isToday";
import moment from 'moment';
import _ from "underscore";
import {connect} from "react-redux";
import CenteredModal from "../shared/CenteredModal";
import no_appointments from "../../resources/images/no_appointments.png";
import {infoUtils} from "../../utils/infoUtils";
import PropTypes from "prop-types";
import UserImageWrap from "../shared/UserImageWrap";
import CancelAppointmentModal from "../appointment/CancelAppointmentModal";

class Consultations extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            tab: 'ACTIVE',
            consultations: [],
            activeConsultations: {
                urgent: [],
                not_completed: [],
                completed: []
            },
            page:0,
            page_size:5,
            total_pages:0,
            total_items:0,
        }
    }

    setTab = (tab) => {
        if (tab !== this.state.tab) {
            this.setState({consultations: [], loading:true}, this.fetchData)
        }

        this.setState({tab: tab})
    }

    fetchData = () => {
        if (this.state.tab === 'ACTIVE') {
            usersService.fetchProviderActiveTextConsultations().then(res => {
                if (res) {
                    this.setState({activeConsultations: {...this.split(res)}})
                }
                this.setState({loading: false, err: undefined});
            }).catch((err) => {
                this.setState({loading: false, err: err});
            });
        } else {
            let date = new Date();
            date.setFullYear(date.getFullYear() + 1);
            usersService.fetchProviderTextConsultations({page: this.state.page, page_size: this.state.page_size} ).then(res => {
                if (res) {
                    this.setState({consultations: res.content, total_pages: res.totalPages, total_items:res.totalElements})
                }
                this.setState({loading: false, err: undefined});
            }).catch((err) => {
                this.setState({loading: false, err: err});
            });
        }
    }

    split(res) {
        let stats = {
            urgent:[],
            not_completed:[],
            completed: []
        }

        for (let i = 0; i < res.length; i++) {
            const re = res[i];
            if (re.first_response_time === 0) {
                stats.urgent.push(re);
            } else if (re.status !== "COMPLETED") {
                stats.not_completed.push(re);
            } else {
                stats.completed.push(re);
            }
        }
        return stats;
    }

    componentDidMount() {
        this.fetchData();
    }

    cancelConsultation = (app, note) => {
        // eslint-disable-next-line no-unused-vars
        updateAppointmentStatus(this.state.consultationToCancel.id, "CANCELED_BY_PROVIDER", note).then(r => {
            this.setState({showCancelConsultationModal: false, consultationToCancel: undefined})
            this.fetchData();
        });
    }

    noConsultations = (label) => {
        let noConsultationsObj = {
            imgClass: 'no-pending-consultations-img',
            primaryLabelClass: 'no-pending-consultations-primary-label',
            secondaryLabelClass: '',
            src: no_appointments,
            primaryLabel: $$(label),
            secondaryLabel: ''
        }

        return infoUtils.noData(noConsultationsObj);
    }

    render() {
        let lang = _.contains(['en', 'sq'], this.props.i18n) ? 'en' : this.props.i18n;
        moment.locale(lang.lang);
        return <div className="patient-card">
            <div className="patient-card-header">
                <h2 className="card-title card-header-title">{$$("consultations_page_title")}</h2>
            </div>
            <div className="pl-4 pr-4 card-header-title">
                <SwitchTabs activeTab={this.state.tab} setTab={this.setTab}/>
            </div>
            <div className="card-body card-header-title">
                {this.state.loading && <div className={"report-loader"}/>}
                {this.state.tab !== 'ACTIVE' && <div>
                    {this.state.consultations.length === 0 && !this.state.loading &&
                        this.noConsultations("no_consultations")
                    }

                    <ConsTable data={this.state.consultations} showStatus={true} onCancel={(c) => {this.setState({consultationToCancel:c, showCancelConsultationModal:true})}}/>
                    <div className="mt-3"></div>
                    {this.state.total_items > 0 && !this.state.loading && <PaginationComponent alwaysShown={false} itemsCount={this.state.total_items} currentPage={this.state.page+1} itemsPerPage={this.state.page_size} setCurrentPage={(p)=>{
                        this.setState({page:p-1}, this.fetchData)
                    }} />}
                </div>}
                {this.state.tab === 'ACTIVE' &&
                 <div>
                     {this.state.activeConsultations.urgent.length === 0 &&
                         this.state.activeConsultations.not_completed.length === 0 &&
                         this.state.activeConsultations.completed.length === 0 &&
                         !this.state.loading &&
                         this.noConsultations("no_active_consultations")
                     }

                     <ConsTable title={<TableTitle classNames="medrec-red-2">{$$("urgent_consultations")}</TableTitle>}
                                data={this.state.activeConsultations.urgent}
                                onCancel={(c) => {this.setState({consultationToCancel:c, showCancelConsultationModal:true})}}
                    />

                     <ConsTable title={<TableTitle>{$$("uncompleted_consultations")}</TableTitle>}
                                data={this.state.activeConsultations.not_completed}
                                onCancel={(c) => {this.setState({consultationToCancel:c, showCancelConsultationModal:true})}}
                    />

                     <ConsTable title={<TableTitle>{$$("completed_consultations")}</TableTitle>}
                                data={this.state.activeConsultations.completed}
                                onCancel={(c) => {this.setState({consultationToCancel:c, showCancelConsultationModal:true})}}
                     />
                 </div>
                }
                {this.state.consultationToCancel &&
                    <CancelAppointmentModal
                        appointment={this.state.consultationToCancel}
                        show={this.state.showCancelConsultationModal}
                        onHide={() => {
                            this.setState({showCancelConsultationModal: false, consultationToCancel: undefined})
                        }}
                        onConfirm={this.cancelConsultation}
                    />}
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        i18n:state.language.selected
    }
}

export default connect(mapStateToProps, {})(Consultations)

/* eslint-disable */
function ConsTable({data, title, onCancel, showStatus}) {
    if (!data || data.length < 1) {
        return null;
    }
    return <React.Fragment>
                {title}
                <div className="high-shadow-container">
                    <table className="table table-borderless medrec-default-text-color consultations-page-table">

                        {data.map(c=>{
                                return <Consultation c={c} key={c.id} onCancel={onCancel} showStatus={showStatus} />
                            })}

                    </table>
                </div>
    </React.Fragment>
}
/* eslint-enable */

// eslint-disable-next-line react/prop-types
function TableTitle({children, classNames = ""}) {
    return <div className={`mt-3 mb-3 ${classNames}`}>
        {children}
    </div>
}

function timeLeft(timestamp) {
    let start = moment();
    let end = moment(timestamp);
    let diff = end.diff(start);

    let days = Math.floor(moment.duration(diff).asDays());
    let hours = Math.floor(moment.duration(diff).subtract(days, "days").asHours())
    let minutes = moment.utc(diff).format("m")

    if (days > 0) {
        return _$$("duration_d_h_m", days, hours ,minutes);
    }
    if (hours > 0) {
        return _$$("duration_h_m",hours ,minutes);
    } else {
        return _$$("duration_m", minutes);
    }
}

/* eslint-disable */
export function Consultation({c, onCancel, showStatus}) {
    let patient = useMemo(()=> c.participants.find(p => p.participant_role === 'PATIENT'), [c]);
    return <React.Fragment key={c.id}>
        <tbody>
    <tr className="fr">
        <TableCell>
                <span className="d-flex">
                                <div className="mr-1">
                                    <UserImageWrap userID={patient.user_id}
                                                   show_red_dot={c.has_not_seen_patient_documents}
                                               classnames="patient-img"/>
                                </div>
                    <div className="d-flex flex-column justify-content-center">
                        <div className={"patient-name"}>
                        {patient.fullname}
                        </div>
                        {getDetails(c)}
                    </div>
                </span>
        </TableCell>
        <TableCell>
            {appointmentUtils.getExaminationName(c.appointment_price)}
        </TableCell>
        {showStatus && <TableCell>
            <span className={appointmentUtils.getClassByStatus(c.status)}>{$$(c.status.toLowerCase() + "_consultation")}</span>
        </TableCell>}
        <TableCell>
            <div className={"text-right"}>
            <MedicalHistoryButton title={$$('medical_history')} style={{'marginLeft': '0.625rem', 'marginBottom': '0.625rem' }}
                                  onClick={() => {
                                      medicalHistoryHelper.prepareMedicalHistoryData(DASHBOARD_NEXT_PATIENT_MED_HISTORY, patient, null, Routes.HISTORY);
                                      return true;
                                  }}/>
            {(c.status === 'WAITING_FOR_DOCTOR_APPROVAL' ||
                c.status === 'WAITING_FOR_PATIENT_APPROVAL' ||
                c.status === 'NEED_MORE_INFO' ||
                c.status === 'ACCEPTED') &&
            c.encounter_id == null &&
            !appointmentUtils.isOverdueTextConsultation(c) &&
            <CancelAppointmentButton title={$$('cancel_consultation_label')}
                                     style={{'marginLeft': '0.625rem', 'marginBottom': '0.625rem'}} onClick={() => {
                onCancel(c);
            }}/>}
            {! appointmentUtils.isAppointmentCanceled(c.status) &&
            !appointmentUtils.isOverdueTextConsultation(c) &&
            <OpenAppointmentButton title={$$('open_consultation_label')}
                                   style={{'marginLeft': '0.625rem', 'marginBottom': '0.625rem'}} onClick={() => {
                medicalHistoryHelper.prepareMedicalHistoryData(DASHBOARD_NEXT_PATIENT_VIDEO, patient, c, Routes.APPOINTMENT);
            }
            }/>}
            </div>
        </TableCell>
    </tr>
        <tr className="sr">
            <td colSpan={showStatus ? 4 : 3} style={{paddingLeft:"4rem", width:"100%"}}>
                {c.notes.length > 0 &&
                    <div className="d-flex justify-content-between">
                        <div className="medrec-grey-2 smaller-text pb-1">{formatTime(c.notes[0])} - {messageLabel(c.notes[0], patient)}:</div>
                    </div>
                }
                {c.notes.length > 0 &&
                    <div className={classNames("consultation-item-message-content", {"patient": isFromPatient(c.notes[0], patient)})}>{c.notes.length > 0 ? c.notes[0].value : ""}</div>}
            </td>
        </tr>
        </tbody>
        <tbody className="spacer">
            <tr>
                <th></th>
            </tr>
        </tbody>
    </React.Fragment>
}
/* eslint-enable */
function getDetails(c) {
    if (isUrgent(c)) {
        if (appointmentUtils.isOverdueTextConsultation(c)) {
            return <div><span className="text-danger">{$$("overdue_will_be_canceled")}</span></div>
        }
        return <div className={""}>
            {$$("expires_on")} <span className="text-danger">{formatTimeAsString(c.ends_at).toLowerCase()}</span>
            <br/>
            {$$("remaining")} <span className="text-danger">{timeLeft(c.ends_at)}</span>
        </div>
    }
    return <div className={""}>
        {$$("last_modified")} {lastModified(c)}
    </div>
}

function isUrgent(c) {
    return c.first_response_time === 0 && c.status === 'ACCEPTED';
}

function formatTime(message) {
   let date = message.server_modified_timestamp > 0 ? message.server_modified_timestamp : message.server_created_timestamp;
   return formatTimeAsString(date);
}

function formatTimeAsString(date) {
    return isToday(date) ? `${$$("today")},  ${dateTimeUtils.getFormattedTime(date)}` : dateTimeUtils.getFormattedDateWithTime(date);
}

function lastModified(c) {
    let max = Math.max(c.server_modified_timestamp, c.server_modified_timestamp)
    if (c.notes.length > 0) {
        let maxNote = 0;
        c.notes.forEach(note => {
            maxNote = Math.max(Math.max(note.server_modified_timestamp, note.server_modified_timestamp), maxNote);
        })
        max = Math.max(max, maxNote);
    }
    return formatTimeAsString(max);
}

function messageLabel(message, patient) {
    let parentContactInfo = patient.parentContactInfos.find(p => p.id === message.owner_id)
    if (patient.user_id === message.owner_id || parentContactInfo) {
        return _$$("last_message", message.owner_fullname)
    }
    return $$("you_wrote")
}

function isFromPatient(message, patient) {
    return patient.user_id === message.owner_id || patient.parentContactInfos.find(p => p.id === message.owner_id)
}

// eslint-disable-next-line react/prop-types
function TableCell({children}) {
    return <td className="d-table-cell align-middle">
        {children}
    </td>
}

// eslint-disable-next-line react/prop-types
function SwitchTabs({activeTab, setTab}) {
    return <div className="switch-tab-buttons">
        <span className={classNames("mr-3 pointer tab-button", {"active": activeTab === 'ACTIVE'})} onClick={()=>{setTab('ACTIVE')}}>
            {$$("active_consultations_label")}
        </span>
        <span className={classNames("pointer tab-button", {"active": activeTab === 'HISTORY'})} onClick={()=>{setTab('HISTORY')}}>
            {$$("all_consultations_label")}
        </span>
     </div>
}

Consultations.propTypes = {
    onSelect:  PropTypes.func,
    notes:  PropTypes.any,
    i18n:  PropTypes.any,
}