import React from "react";

// eslint-disable-next-line react/prop-types
const Checkbox = ({label, isSelected, onCheckboxChange, colour}) => (
    <div>
        <div className="work-time-checkbox">
            <input type="checkbox"
                   name={label}
                   id={label}
                   checked={isSelected}
                   onChange={onCheckboxChange}
            />
            <label style={{background: colour}} htmlFor={label}/>
        </div>
        <label style={{marginTop: "0.25rem"}} className="label-text">{label}</label>
    </div>
);

export default Checkbox;
