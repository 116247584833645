/* eslint-disable react/jsx-no-duplicate-props */
import React, {createRef} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

class Search extends React.Component {
    inputRef = createRef();
    state = {open: false, data: null, searchValue: this.props.searchValue ? this.props.searchValue : ""};

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.searchValue !== prevProps.searchValue) {
            this.setState({searchValue: this.props.searchValue})
        }
    }
    handleSearchChange = event => {
        this.setState({data: null, searchValue: event.target.value}, function () {
            if (this.props.filterUsers) {
                this.props.filterUsers(this.state.searchValue);
            }
        });

        if (this.props.handleSearchChange) {
            this.props.handleSearchChange(event.target.value)
        }


        this.setState({open: !this.state.open});
    };

    clear = () => {
        this.setState({searchValue: ""}, function () {
            if (this.props.filterUsers) {
                this.props.filterUsers(this.state.searchValue);
            }
        });
        if (this.props.handleSearchChange) {
            this.props.handleSearchChange("")
        }
        this.setState({open: !this.state.open});
    };

    render() {
        const {data, searchValue} = this.state;

        return (
            <div className="kt-header-toolbar">
                <div
                    id="kt_quick_search_default1"
                    className={clsx("kt-quick-search1 ", {
                        "kt-quick-search--has-result1": data && data.length
                    })}
                >
                    <form className="kt-quick-search__form1" onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>                        <div
                            className="input-group"
                        >
                            <input
                                type="text"
                                ref={this.inputRef}
                                placeholder={this.props.placeholder}
                                value={searchValue}                                onChange={this.handleSearchChange}
                                className="form-control kt-quick-search__input1 latest-measurements-container"
                            />
                            {(searchValue) && (
                                <div className="input-group-append" onClick={this.clear}>
                                    <span className="input-group-text">
                                        <i className="la la-close kt-quick-search__close flex flex-container"/>
                                    </span>
                                </div>
                            )}
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i className="flaticon2-search-1"/>
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

Search.propTypes = {
    handleSearchChange: PropTypes.func,
    externalSearchValue: PropTypes.bool,
    searchValue: PropTypes.any,
    filterUsers: PropTypes.func,
    placeholder: PropTypes.string,
    style: PropTypes.any
};

export default Search;
