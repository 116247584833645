import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import moment from 'moment'
import CenteredModal from '../shared/CenteredModal';
import {documentsService} from '../../service/docments_service';
import {downloadUtils} from '../../utils/downloadUtils';

import {DOCUMENT} from '../../constants/information-retrieval-types'
import no_data from '../../resources/images/no_data.png'
import DocumentEntry from "./DocumentEntry";
import {infoUtils} from "../../utils/infoUtils";
import ReactDOM from "react-dom";
import DicomViewer from "../dicom/DicomViewer";
import {
    DOWNLOAD_ARCHIVE_ENTRY_FROM_DOCUMENT_URL,
    DOWNLOAD_DOCUMENT_URL,
    GET_DOCUMENT_ARCHIVE_CONTENTS_LIST_URL
} from "../../constants/api_paths";
import {fetchHelper} from "../../helpers/request_helper";
import PropTypes from "prop-types";


export class Documents extends Component {
    state = {
        showModal: false,
        documentEntryId: null,
        documentName: '',
        selectedDocumentId: "",
        loading:true

    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let params = {
            before_date_time: moment().valueOf(),
            size: 500
        };

        if (this.props.selectedUser.id) {
            this.prepareParams(params, true);
        }
    }

    /**
     * On confirm modal, download the file.
     */
    onConfirmModal = () => {
        const fileName = this.state.documentName;
        let healthIssueIds = this.props.selectedUser.health_issues.map(h => h.id);
        documentsService.downloadFile(this.state.documentEntryId, healthIssueIds).then((file) => {
            downloadUtils.download(file, fileName);
        });
        this.setState({showModal: false, documentEntryId: null});
    }

    /**
     * Set state for the selected document entry when showing the modal
     */
    showModal = (documentEntryId, documentName) => {
        this.setState({showModal: true, documentEntryId: documentEntryId, documentName: documentName});
    }

    /**
     * Fetch selected user documents
     */
    onLoadMore = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let params = {
            before_date_time: moment().valueOf(),
            size: 500
        };
        if (this.props.selectedUser.id && (this.props.documents.request.finished === undefined || this.props.documents.request.finished)) {
            if (this.props.documents.entries && this.props.documents.entries.length > 0) {
                const entiresLength = this.props.documents.entries.length;
                params.before_date_time = this.props.documents.entries[entiresLength - 1].date_time;
            }
            this.prepareParams(params, false);
        }
    }
    /**
     * Prepares the params to be sent along with the request of fetching documents
     *
     * @param {params} params - the params object
     * @param  reset - reset
     */
    prepareParams = async (params, reset) => {
        let healthIssueIds = this.props.selectedUser.health_issues.map(h => h.id);
        params.healthIssueIds = healthIssueIds;
        params.types = [DOCUMENT];
        this.setState({loading:true})
        await this.props.fetchEntries(this.props.selectedUser.id, params, reset).then(() => {
            this.setState({loading:false})
        });
    }

    /**
     * Selects a specific document
     *
     * @param {string} id - the id of the selected lab result
     */
    selectDocument = (id) => {
        let selectedDocumentId = id;
        this.setState({selectedDocumentId});
    }

    /**
     * Resets the selected lab result
     */
    resetDocument = () => {
        let selectedDocumentID = "";
        this.setState({selectedDocumentID});
    }

    openDocumentInViewer = (v) => {
        if (v.mime_type === "application/zip" || v.mime_type === "application/x-zip-compressed") {
            fetchHelper.callGet(
                GET_DOCUMENT_ARCHIVE_CONTENTS_LIST_URL
                    .replace('{documentId}', v.id)
                    .replace('{healthIssuesIds}', this.props.selectedUser.health_issues.map(h => h.id).join(",")))
                .then((res) => {
                    this.setState({openInViewer: v, imageIds: this.toArchiveDocumentURLs(v, res)});
                });
        } else {
            this.setState({openInViewer: v, imageIds: this.toNormalDocumentURL(v)})
        }
    }

    toArchiveDocumentURLs = (doc, list) => {
        let res = {};
        for (const [key, value] of Object.entries(list)) {
            res[key] = value.map((o) => {
                return `wadouri:${DOWNLOAD_ARCHIVE_ENTRY_FROM_DOCUMENT_URL
                    .replace('{documentId}', doc.id)
                    .replace('{healthIssuesIds}', this.props.selectedUser.health_issues.map(h => h.id).join(","))
                    .replace('{archiveEntry}', o)}`
            });
        }
        return res;
    }

    toNormalDocumentURL = (document) => {
        return {
            "": [
                `${DOWNLOAD_DOCUMENT_URL.replace('{documentId}', document.id).replace('{healthIssuesIds}', this.props.selectedUser.health_issues.map(h => h.id).join(","))}`
            ]
        };
    }


    /**
     * Get the documents to show. Filter the documents based on the given input.
     *
     * @returns {HTMLElement} array of GroupedDocuments
     */
    getDocuments = () => {
        const filters = this.props.filters;
        let noDocumentObj = {
            imgClass: 'mt-3',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: 'no-lab-results-secondary-label',
            src: no_data,
            primaryLabel: $$('documents_no_data_primary_label'),
            secondaryLabel: $$('documents_no_data_secondary_label')
        }
        if (!this.state.loading && this.props.documents && this.props.documents.entries && this.props.documents.entries.length > 0) {
            const filteredDocuments = this.props.documents.entries.filter(doc => {
                return (!filters.type || filters.type === doc.type) &&
                    (!filters.category || (doc.category && filters.category.toLowerCase() === doc.category.toLowerCase())) &&
                    (!filters.after_date_time || doc.date_time > filters.after_date_time);
            });
            return filteredDocuments && filteredDocuments.length > 0 ? filteredDocuments.map((entry, idx) => {
                return <DocumentEntry key={idx}
                                      index={idx}
                                      entry={entry}
                                      selectedDocumenttId={this.state.selectedDocumentId}
                                      selectDocument={this.selectDocument}
                                      showModal={this.showModal}
                                      resetDocument={this.resetDocument}
                                      filters={this.props.filters}
                                      i18n={this.props.i18n}
                                      onOpenInViewer={this.openDocumentInViewer}/>
            }, this) : infoUtils.noData(noDocumentObj);

        } else if ((this.props.documents.request.finished || this.props.selectedUser.health_issues.length === 0) && !this.state.loading) {
            return infoUtils.noData(noDocumentObj);
        }
    }

    render() {
        let documents = this.getDocuments();
        const hasMore = !this.props.documents.isLastPage &&
            (this.props.documents.request.finished === undefined || this.props.documents.request.finished) &&
            !this.props.documents.request.error &&
            this.props.selectedUser.health_issues.length > 0 &&
            documents &&
            documents.length !== undefined &&
            documents.length > 0;

        return (
            <div className="card documents-inner-card border-bottom-0">
                {this.state.loading && <div className={"report-loader"}/>}
                <div className="card-body inner-card-body pt-0">
                    <div ref={(ref) => this.listRef = ref}>
                        <div className="list-group">
                            {documents}
                        </div>
                        {hasMore &&
                        <div className="text-center" style={{"marginTop": "10px"}}>
                            <a href="#" onClick={this.onLoadMore}>{$$('load_more')}</a>
                        </div>
                        }
                    </div>
                </div>
                <CenteredModal title={$$('document_download_dialog_title')}
                               show={this.state.showModal}
                               onHide={() => this.setState({showModal: false, documentEntryId: null})}
                               onConfirm={this.onConfirmModal}
                               confirmBtnLabel={$$('download_btn')}
                               cancelBtnLabel={$$('cancel_btn')}
                >
                    <p className={"format-file-name"}>{$$('confirmation_dialog_message').replace('{fileName}', this.state.documentName).replace('{owner}', this.props.selectedUser.fullname)} </p>
                </CenteredModal>
                {this.state.openInViewer && ReactDOM.createPortal(
                    <DicomViewer document={this.state.openInViewer} onClose={() => {
                        this.setState({openInViewer: undefined, imageIds: undefined})
                    }}
                                 imageIds={this.state.imageIds} selectedUser={this.props.selectedUser}
                    />,
                    document.getElementById('dicom-viewer')
                )}
            </div>
        )
    }
}

Documents.propTypes = {
    documents: PropTypes.object,
    fetchEntries: PropTypes.func,
    filters: PropTypes.object,
    i18n: PropTypes.object,
    selectedUser: PropTypes.object
}

export default Documents
