import {medicationsService} from '../service/medications_service'
import {
    DUMMY,
    FETCH_SELECTED_USER_MEDICATIONS_ERROR,
    FETCH_SELECTED_USER_MEDICATIONS_SUCCESS
} from './actions';


/**
 * Search for a medication
 * @param {object} params - params to send with the request
 */
export function searchForMedication(params){
    return medicationsService.searchForMedication(params)
}

/**
 * Fetch selected user medication plan
 *
 * @param {object} userId - id of the selected user
 * @param {object} params - params to send with the request
 * @returns {function} dispatch function
 */
export function fetchSelectedUserMedicationPlan(userId, params) {
    return (dispatch, getState) => {
        return medicationsService.fetchSelectedUserMedicationPlan(userId, params).then((res) => {
            if (getState().selectedUser.data.id !== userId) {
                dispatch({type: DUMMY});
            }
            if (res) {
                dispatch({type: FETCH_SELECTED_USER_MEDICATIONS_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: FETCH_SELECTED_USER_MEDICATIONS_ERROR, result: err});
        })
    }
}
