import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {CONVERTER} from '../../utils/converter'
import MessagesButton from '../shared/MessagesButton';
import EmailButton from '../shared/EmailButton';
import MedicalHistoryButton from '../History/MedicalHistoryButton';
import history from '../../helpers/history'
import {Routes} from '../../constants/routes';
import PropTypes from "prop-types";
import UserImageWrap from "../shared/UserImageWrap";
import Email from "../common/Email";
import Phone from "../common/Phone";

class PatientsListView extends Component {
    constructor(props) {
        super(props);
    }

    /**
     * Creates the health issues list cell of the table
     *
     * @param {object} patient - the patient object
     * @returns {ReactElement} the created table cell
     */
    getHealthIssueListCell = (patient) => {
        let healthIssueList = patient.healthIssueLiteList.map((h, idx) => {
            return <div key={idx} className='patient-health-issue-list'>{h.name}</div>
        });

        return <div style={{maxWidth: "375px", marginBottom: "1rem"}}
                    className='row left-justify'>{healthIssueList}</div>;
    }

    /**
     * Filters the patients based on the search value
     *
     * @returns {Array} the list of filtered patients
     */
    getFilteredPatients = () => {
        return this.props.users?.list ? this.props.users.list : [];
    }

    render() {
        return (
            <div>
                {this.getFilteredPatients().length > 0 &&
                    <div className='card-body patient-list-view'>
                        <div className='row zero-margin-row'>
                            <div className='col-xs-12 col-md-12'>
                                <div className='row'>
                                    <div className="patient-table-container mt-3">
                                        <div className="w-100 d-table management-patients-table">
                                            {this.getFilteredPatients().map(u => {
                                                return <React.Fragment key={u.id}>
                                                    <div key={u.user_uin} className="d-table-row">
                                                        <div
                                                            className={u.healthIssueLiteList.length > 0 ? "d-table-cell p-2 no-border" : "d-table-cell p-2"}>
                                                            <UserImageWrap userID={u.id}
                                                                           show_red_dot={u.has_not_seen_patient_documents}
                                                                           classnames="patient-img"/>
                                                        </div>
                                                        <div
                                                            className={u.healthIssueLiteList.length > 0 ? "d-table-cell p-2 no-border" : "d-table-cell p-2"}>
                                                            <a href="#" onClick={(e) => e.preventDefault()}>
                                                                <h5 onClick={() => {
                                                                    history.push(Routes.HISTORY);
                                                                    return this.props.onMedicalHistoryClick(u);
                                                                }}>{u.fullname}</h5>
                                                            </a>
                                                            {u.birthday !== 0 && <span className="medrec-grey-2 small">
                                                                {CONVERTER.millisecondsToAge(u.birthday)}&nbsp;{CONVERTER.millisecondsToAge(u.birthday) === 1 ? $$('year_old') : $$('years_old')}
                                                            </span>}
                                                        </div>
                                                        <div
                                                            className={u.healthIssueLiteList.length > 0 ? "d-table-cell p-2 no-border" : "d-table-cell p-2"}>
                                                            <div className="d-flex">
                                                                <div><Email object={u}/>
                                                                    <Phone object={u}/>
                                                                    {u.disabled && <div
                                                                        className="text-danger">{$$("account_closed")}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={u.healthIssueLiteList.length > 0 ? "d-table-cell p-2 text-right no-border" : "d-table-cell p-2 text-right"}>
                                                            <MessagesButton title={$$('messages_label')}
                                                                            style={{
                                                                                'marginLeft': '0.625rem',
                                                                                'marginBottom': '0.625rem'
                                                                            }}
                                                                            onClick={() => {
                                                                                this.props.onMessagesClick(u);
                                                                            }}/>

                                                            <EmailButton title={$$('email_label')}
                                                                         style={{
                                                                             'marginLeft': '0.625rem',
                                                                             'marginBottom': '0.625rem'
                                                                         }}
                                                                         onClick={() => {
                                                                             window.location = "mailto:" + u.email;
                                                                         }}/>

                                                            <MedicalHistoryButton title={$$('medical_history')}
                                                                                  style={{
                                                                                      'marginLeft': '0.625rem',
                                                                                      'marginBottom': '0.625rem'
                                                                                  }}
                                                                                  onClick={() => {
                                                                                      history.push(Routes.HISTORY);
                                                                                      return this.props.onMedicalHistoryClick(u);
                                                                                  }}/>
                                                        </div>
                                                    </div>

                                                    {u.healthIssueLiteList.length > 0 &&
                                                        <div key={"h-issues" + u.user_uin} className="d-table-row">
                                                            <div className="d-table-cell p-2"/>
                                                            <div className="d-table-cell ">
                                                                {this.getHealthIssueListCell(u)}
                                                            </div>
                                                            <div className="d-table-cell p-2"/>
                                                            <div className="d-table-cell p-2"/>

                                                        </div>}
                                                </React.Fragment>
                                            })}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

PatientsListView.propTypes = {
    onMedicalHistoryClick: PropTypes.func,
    onMessagesClick: PropTypes.func,
    users: PropTypes.any,
}

export default PatientsListView;
