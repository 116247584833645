import React, {Component} from 'react'
import {$$} from '../../../helpers/localization';
import PropTypes from "prop-types";

class ChatInput extends Component {

    state = {
        message: ''
    }

    constructor(props) {
        super(props);
    }

    /**
     * Set the state to the latest change in the input value.
     *
     * @param {object} evt - The event handler argument
     */
    onInputChange = (evt) => {
        const fields = Object.assign({}, this.state);
        fields[evt.target.name] = evt.target.value;
        this.setState(fields);
    };

    /**
     * Send message to other user in chat
     *
     * @param {object} e - the event object
     */
    onSendMessage = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.state.message.trim()) {
            this.props.sendMessage(this.state.message.trim());
            this.setState({message: ''});
        }
    }

    render() {
        return (
            <form className="d-flex ml-2" onSubmit={this.onSendMessage}>
                <textarea type="text" rows={2}
                       className="form-control input chat-input"
                       value={this.state.message}
                       placeholder={$$('type_message')}
                       name="message"
                       onKeyDown={(event) => {
                           if (event.keyCode === 13 && event.shiftKey) {
                               event.preventDefault();
                               event.stopPropagation();
                               this.onSendMessage();
                           }
                       }}
                       onChange={this.onInputChange}/>
                <button className="send">
                    <div className="circle">
                        <i className="flaticon2-paperplane"/>
                    </div>
                </button>
            </form>
        )
    }
}

ChatInput.propTypes = {
    sendMessage: PropTypes.func,
}


export default ChatInput
