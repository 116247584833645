import React, {useCallback, useEffect, useState} from "react"
import {ListGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {ReactComponent as DeleteIcon} from "../../../public/media/icons/Delete.svg";
import {$$} from "../../helpers/localization";
import {dateTimeUtils} from "../../utils/dateTimeUtils";
import {
    nhisCancelMedicalNotice,
    nhisLogin,
    nhisSignCancelMedicalNotice
} from "../../service/nhis_service";
import Modal from "react-bootstrap/Modal";
import FormGroup from "react-bootstrap/FormGroup";

export default function MedicalNoticeList({encounter, onDelete, lang}) {
    const [medicalNotices, setMedicalNotices] = useState();

    useEffect(() => {
        setMedicalNotices(encounter.nhis_medical_notice_jsons.map(n => {
            return JSON.parse(n).viewModel;
        }))
    }, [encounter.nhis_medical_notice_jsons]);


    if (!medicalNotices) {
        return null;
    }

    return <ListGroup className={"shadow-boxes-list"}>
        {medicalNotices.map(n => {
            return <MedicalNoticeListItem key={n.nrnMedicalNotice} notice={n} onDelete={onDelete} lang={lang}/>
        })}
    </ListGroup>
}

function MedicalNoticeListItem({notice, onDelete, lang}) {

    const deleteTooltip = useCallback(props => (
        <Tooltip {...props} show={"true"}>{$$("delete_label")}</Tooltip>
    ), [lang]);

    return <div className="list-group-item">
                    <ItemBody notice={notice} lang={lang}/>
        <span onClick={() => {
                    onDelete(notice)
                }}
                className="pointer prescription-delete-icon">
                    <OverlayTrigger
                        placement={"top"}
                        overlay={deleteTooltip}
                        popperConfig={{
                            modifiers: {
                                preventOverflow: {
                                    enabled: false
                                },
                                hide: {
                                    enabled: false
                                }
                            }
                        }}
                    >
                        <DeleteIcon className="svg-blue" height="17px" width="17px"/>
                    </OverlayTrigger>
        </span>

    </div>
}

function localizedDate(date) {
    if (!date) {
        return ""
    }
    return dateTimeUtils.getFormattedDate(new Date(date));
}

export function ItemBody({notice, lang}) {
    return <div style={{marginRight: '0.5rem', marginTop: '0.5rem'}}>
            <table className="table table-sm table-borderless medrec-default-text-color">
                <tbody>
                    <TableRow labelKey={"nhis.medicalNotice.nrn_number"} value={notice.nrnMedicalNotice}/>
                    <TableRow labelKey={"nhis.medicalNotice.issued_on_label"} value={localizedDate(notice.authoredOn)}/>
                    <TableRow labelKey={"nhis.medicalNotice.reason_label"} value={notice.reason}/>
                    {notice.diagnosis && <TableRow labelKey={"nhis.medicalNotice.code_label"} value={notice.diagnosis}/>}
                    <TableRow labelKey={"nhis.medicalNotice.location_view_label"} value={notice.location}/>
                    {!notice.toDate && <TableRow labelKey={"from"} value={`${localizedDate(notice.fromDate)}`}/>}
                    {notice.toDate && <TableRow labelKey={"nhis.medicalNotice.for_period"} value={`${localizedDate(notice.fromDate)} - ${localizedDate(notice.toDate)}`}/>}
                    <TableRow labelKey={"nhis.medicalNotice.institution_label"} value={notice.institution}/>
                    <TableRow labelKey={"nhis.medicalNotice.note_label"} value={notice.note}/>
                </tbody>
            </table>
    </div>
}

function TableRow({labelKey, value}) {
    if (value) {
        return <tr><td className="medrec-grey-2 text-right medical-notice-item-label">{$$(labelKey)}:</td><td className="whitespace-pre-line w-100 align-bottom">{value}</td></tr>
    }
    return null;
}

export function NhisCancelMedicalNoticeDialog({nrn, senderId, onClose, onSuccess}) {
    const [reason, setReason] = useState("");
    const [formClass, setFormClass] = useState("");
    const [sending, setSending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            setFormClass("was-validated")
            return;
        }

        setSending(true);

        let message = {
            senderId : senderId,
            nrnMedicalNotice: nrn,
            cancelReason: reason.trim()
        }

        nhisLogin((loginSuccess) => {
            nhisSignCancelMedicalNotice(message, (signedRequest) => {
                nhisCancelMedicalNotice(loginSuccess.accessToken, signedRequest)
                    .then(async res => {
                        let contents = res["nhis:message"]["nhis:contents"];
                        let error = contents["nhis:error"];
                        if (error) {
                            if (Array.isArray(error)) {
                                setError(error[0]["nhis:reason"]["value"]);
                            } else {
                                setError(error["nhis:reason"]["value"]);
                            }
                        } else {
                            setSuccess(true);
                            onSuccess(nrn)
                        }
                        setSending(false)
                    })
            }, (e) => {
                setError(e);
                setSending(false);
            })
        }, (e) => {
            setSending(false);
            setError(e)
        }).catch(error => {
            console.log(error);
            setSending(false);
            setError($$("nhis.service_error"));
        })
    }

    return <Modal backdrop={"static"} show={true} >
        <Modal.Header className={"h5"}>
            {$$("nhis.medicalNotice.modal_header_cancel")}
        </Modal.Header>
        <Modal.Body>
            {success && <p className="p-2">{$$("nhis.medicalNotice.annul_success")}</p> }
            {!success && <form noValidate={true} className={formClass} onSubmit={onSubmit} id="form_cancel_medical_notice">
                {error && <p className="text-danger p-2">{error}</p> }
                <p>{$$("nhis.medicalNotice.modal_body_cancel")}</p>
                <FormGroup>
                    <label>{$$("nhis.medicalNotice.cancellation_reason")}*</label>
                    <textarea className="form-control"
                              rows={4}
                              value={reason}
                              onChange={(e)=>{setReason(e.target.value)}}
                              maxLength={2000}
                              required={true}/>
                </FormGroup>
            </form>}

        </Modal.Body>
        {!success && <Modal.Footer>
            <button type="submit" className="btn btn-danger" form={"form_cancel_medical_notice"} disabled={sending}
            >{$$("nhis.annul_nhis")}</button>
            <button type="button" className="btn btn-secondary" disabled={sending}
                    onClick={onClose}>{$$("nhis.btn.cancel")}</button>
        </Modal.Footer>
        }
        {success && <Modal.Footer>
            <button type="button" className="btn btn-primary" disabled={sending}
                    onClick={onClose}>{$$("close_btn_label")}</button>
        </Modal.Footer>}
    </Modal>

}