import {
    ALL_PERMISSIONS_REQUEST_ERROR,
    ALL_PERMISSIONS_REQUEST_SENT,
    ALL_PERMISSIONS_REQUEST_SUCCESS,
    CLEAR_PERMISSIONS,
    LOGOUT,
    PERMISSION_APPROVE_REQUEST_ERROR,
    PERMISSION_APPROVE_REQUEST_SUCCESS,
    PERMISSION_DENY_REQUEST_ERROR,
    PERMISSION_DENY_REQUEST_SUCCESS,
    REQUEST_ERROR,
    REQUEST_PERMISSION_ERROR,
    REQUEST_PERMISSION_SUCCESS,
    REQUEST_SENT,
    REQUEST_SUCCESS
} from "../actions/actions";
import {requestStatus} from './requests_reducers';

const initialState = {
    entries: [],
    requestPermissionSuccess: null,
    requestPermissionError: null,
    permissionOperationError: null,
    request: requestStatus(undefined, {})
}

/**
 * Permission reducer. Reduce state based on action type.
 *
 * @param {object} state the state of permissions
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
export function permissions(state = initialState, action) {
    switch (action.type) {
        case ALL_PERMISSIONS_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {requestPermissionError, requestPermissionSuccess, permissionOperationError, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case ALL_PERMISSIONS_REQUEST_SUCCESS: {
            return {...state, entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case ALL_PERMISSIONS_REQUEST_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case PERMISSION_DENY_REQUEST_SUCCESS:
        case PERMISSION_APPROVE_REQUEST_SUCCESS: {
            return {...state, permissionOperationError: initialState.permissionOperationError}
        }
        case PERMISSION_DENY_REQUEST_ERROR:
        case PERMISSION_APPROVE_REQUEST_ERROR: {
            return {...state, permissionOperationError: action.result}
        }
        case REQUEST_PERMISSION_SUCCESS: {
            return {
                ...state,
                requestPermissionSuccess: true,
                requestPermissionError: initialState.requestPermissionError
            }
        }
        case REQUEST_PERMISSION_ERROR: {
            return {
                ...state,
                requestPermissionError: action.result,
                requestPermissionSuccess: initialState.requestPermissionSuccess
            }
        }
        case CLEAR_PERMISSIONS:
        case LOGOUT: {
            return {...state, ...initialState};
        }
        default: {
            return state;
        }
    }
}