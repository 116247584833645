import {fetchHelper} from '../helpers/request_helper';
import {
    ADD_CONSULTATION_MENU_OPTION,
    ALL_LOGBOOK_ENTIRES_FOR_USER,
    ALL_NOTES_FOR_USER,
    ALL_NOTES_FOR_USER_AND_APPOINTMENT,
    ALL_USERS_URL,
    CREATE_ENCOUNTER,
    CREATE_NEW_NOTE,
    DELETE_APPOINTMENT_TIMETABLE_RANGE,
    DELETE_CONSULTATION_MENU_OPTION,
    DELETE_NOTE,
    DELETE_TEST_FIELD_VALUES_URL,
    DELETE_USER_IMAGE_URL,
    EDIT_NOTE,
    FETCH_TEST_TEMPLATES_URL,
    GET_APPOINTMENT,
    GET_APPOINTMENT_TIMETABLE_RANGES,
    GET_ENCOUNTER_OF_THE_APPOINTMENT,
    GET_HEALTH_ISSUES_PER_USER_URL,
    GET_LAST_COMPLETED_APPOINTMENT,
    GET_PRICE_LISTS,
    GET_PROVIDER_ACTIVE_TEXT_CONSULTATIONS,
    GET_PROVIDER_APPOINTMENTS,
    GET_PROVIDER_TEXT_CONSULTATIONS,
    GET_QUESTIONNAIRES_FOR_ORG_URL,
    GET_SPECIALTIES_URL,
    GET_SUB_APPOINTMENTS_FOR_APPOINTMENT_URL,
    GET_USER_INFO_URL,
    LATEST_MEASUREMENtS_FOR_USER,
    MEDICAL_PROFILE_FOR_USER,
    POST_APPOINTMENT_TIMETABLE_RANGE,
    PROVIDER_APPOINTMENTS_STATS_URL,
    SAVE_TEST_RESULTS_URL,
    UPDATE_APPOINTMENT,
    UPDATE_APPOINTMENT_PAYMENT_STATUS_TO_PAID_URL,
    UPDATE_APPOINTMENT_STATUS_URL,
    UPDATE_ENCOUNTER,
    UPDATE_USER_INFO_URL,
    UPDATE_USER_PASSWORD_URL,
    UPLOAD_USER_IMAGE_URL,
    USER_CHART_DATA_URL,
    USER_PICTURE_URL
} from '../constants/api_paths';

import noImage from '../../public/media/profile/no_image.png'
import {loggedUserId} from "../helpers/auth_helper";


export const usersService = {
    getAllPatients,
    fetchSelectedUserLogbookEntries,
    fetchSelectedUserChartsData,
    fetchSelectedUserLatestMeasurements,
    fetchSelectedUserMedicalProfile,
    getUserImage,
    getUserInfo,
    getAppointmentsStats,
    getAppointmentInfo,
    getDateRanges,
    updateUserInfo,
    putUserInfo,
    updateUserPassword,
    uploadImage,
    deleteImage,
    fetchProviderAppointments,
    fetchUserAppointments,
    fetchAppointmentEncounter,
    updateAppointment,
    createNote,
    editNote,
    deleteNote,
    fetchUserNotes,
    fetchUserNotesForAppointment,
    createEncounter,
    createNewDateRange,
    deleteDateRange,
    updateDateRange,
    updateEncounter,
    getUserHealthIssues,
    updateAppointmentStatus,
    markAppointmentAsPaid,
    fetchSpecialties,
    getPriceList,
    createNewConsultationMenuOption,
    updateConsultationMenuOption,
    deleteConsultationMenuOption,
    fetchQuestionnairesForOrg,
    fetchSubAppointmentsForAppointment,
    fetchTestsTemplates,
    saveTestsResults,
    deleteTestsResults,
    lastCompletedAppointment,
    fetchProviderActiveTextConsultations,
    fetchProviderTextConsultations
};

/**
 * Get all users method
 *
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function getAllPatients(params) {
    return fetchHelper.callGet(ALL_USERS_URL, params);
}

/**
 * Get logged user info
 *
 * @returns {object} promise object
 */
export function getUserInfo() {
    return fetchHelper.callGet(GET_USER_INFO_URL);
}

/**
 * Get appointment info
 *
 * @returns {object} promise object
 */
export function getAppointmentInfo(id) {
    return fetchHelper.callGet(GET_APPOINTMENT.replace('{appointmentId}', id));
}


/**
 * Get Price List info
 *
 * @returns {object} promise object
 */
export function getPriceList() {
    return fetchHelper.callGet(GET_PRICE_LISTS);
}

/**
 * Post new Consultation Menu Option
 *
 * @param {object} data t
 * @returns {object} promise object
 */
export function createNewConsultationMenuOption(data) {
    return fetchHelper.callPost(ADD_CONSULTATION_MENU_OPTION, data);
}

/**
 * update  Consultation Menu Option
 *
 * @param {object} data
 * @returns {object} promise object
 */
export function updateConsultationMenuOption(data) {
    return fetchHelper.callPut(ADD_CONSULTATION_MENU_OPTION, data, null);
}

/**
 * Delete Consultation Menu Option
 *
 * @param {string} id the id to delete
 * @returns {object} promise object
 */
export function deleteConsultationMenuOption(id) {
    return fetchHelper.callDelete(DELETE_CONSULTATION_MENU_OPTION.replace('{encounterPriceId}', id), null, null);
}


/**
 * Get appointment stats
 *
 * @returns {object} promise object
 */
export function getAppointmentsStats(timeZone, params) {
    return fetchHelper.callGet(PROVIDER_APPOINTMENTS_STATS_URL.replace('{timeZone}', timeZone), params);
}

/**
 * Post new timetable for GP
 *
 * @param {object} data the user info to save
 * @returns {object} promise object
 */
export function createNewDateRange(data) {
    return fetchHelper.callPost(POST_APPOINTMENT_TIMETABLE_RANGE, data);
}

/**
 * update timetable for GP
 *
 * @param {object} data the user info to save
 * @returns {object} promise object
 */
export function updateDateRange(data) {
    return fetchHelper.callPut(POST_APPOINTMENT_TIMETABLE_RANGE, data, null);
}

/**
 * Delete timetable for GP
 *
 * @param {object} data the user info to save
 * @returns {object} promise object
 */
export function deleteDateRange(id) {
    return fetchHelper.callDelete(DELETE_APPOINTMENT_TIMETABLE_RANGE.replace('{timetableRangeId}', id), null, null);
}

/**
 * get timetables for GP
 *
 * @returns {object} promise object
 */
export function getDateRanges(params) {
    return fetchHelper.callGet(GET_APPOINTMENT_TIMETABLE_RANGES, params);
}


/**
 * Update logged user info
 *
 * @param {object} data the user info to save
 * @returns {object} promise object
 */
export function updateUserInfo(data) {
    return fetchHelper.callPut(UPDATE_USER_INFO_URL, data);
}

/**
 * Update user info
 *
 */
export function putUserInfo(data) {
    return fetchHelper.callPut(GET_USER_INFO_URL, data);
}


/**
 * Update logged user password
 *
 * @param {object} data the user password to save
 * @returns {object} promise object
 */
export function updateUserPassword(data) {
    return fetchHelper.callPut(UPDATE_USER_PASSWORD_URL, data);
}


var imageCacheStatus = {};
var imageCache = {};

/**
 * Get user image
 *
 * @param {string} userId Id of the user to get image for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function getUserImage(userId, params) {
    if (imageCache[userId]) {
        return new Promise(resolve => {
                resolve(imageCache[userId])
            }
        )
    }
    if (imageCacheStatus[userId] !== 'in_progress') {
        imageCacheStatus[userId] = 'in_progress';
        return fetchHelper.getBlob(USER_PICTURE_URL.replace('{userId}', userId), params).then((res) => {
            imageCache[userId] = res;
            imageCacheStatus[userId] = 'completed';
            return res;
        }, (error) => {
            imageCache[userId] = noImage;
            imageCacheStatus[userId] = 'completed';
            return noImage;
        });
    } else {
        return new Promise(resolve => {
            const timeout = () => {
                setTimeout(() => {
                    if (imageCacheStatus[userId] === 'completed') {
                        resolve(imageCache[userId])
                    } else timeout();
                }, 500)
            };
            timeout();
        })
    }
}

/**
 * Upload user image
 *
 * @param {object} file the image to be uploaded
 * @returns {object} promise object
 */
export function uploadImage(file) {
    const userId = loggedUserId();
    return fetchHelper.uploadFile(UPLOAD_USER_IMAGE_URL, file).then((res) => {
        imageCacheStatus[userId] = undefined;
        imageCache[userId] = undefined;
        getUserImage(userId, {});
        return res;
    });
}

/**
 * Delete user image
 *
 * @returns {object} promise object
 */
export function deleteImage() {
    const userId = loggedUserId();
    imageCacheStatus[userId] = undefined;
    imageCache[userId] = undefined;
    return fetchHelper.callPost(DELETE_USER_IMAGE_URL);
}

/**
 * Fetch the logbook entries method
 *
 * @param {number} userId id of the user to fetch entries for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserLogbookEntries(userId, params) {
    return fetchHelper.callGet(ALL_LOGBOOK_ENTIRES_FOR_USER.replace('{targetUserId}', userId), params);
}

/**
 * Fetch the selected user chart data
 *
 * @param {number} userId id of the user to fetch the chart data
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserChartsData(userId, params) {
    return fetchHelper.callGet(USER_CHART_DATA_URL.replace('{userId}', userId), params);
}

/**
 * Fetch the latest logged measurements of the given user
 *
 * @param {number} userId id of the user to fetch entries for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserLatestMeasurements(userId, params) {
    return fetchHelper.callGet(LATEST_MEASUREMENtS_FOR_USER.replace('{userId}', userId), params);
}

/**
 * Fetch the medical profile of the given user
 *
 * @param {number} userId id of the user to fetch the profile for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSelectedUserMedicalProfile(userId, params) {
    return fetchHelper.callGet(MEDICAL_PROFILE_FOR_USER.replace('{userId}', userId), params);
}

/**
 * Fetch the appointments of the given user
 *
 * @param {number} userId id of the user to fetch the appointments for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchUserAppointments(userId, params) {
    params.with_consultations = true;
    return fetchHelper.callGet(GET_PROVIDER_APPOINTMENTS, params);
}

/**
 * Fetch the encounter of the given appointment
 *
 * @param {number} encounterId id of the encounter
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchAppointmentEncounter(encounterId, params) {
    return fetchHelper.callGet(GET_ENCOUNTER_OF_THE_APPOINTMENT.replace('{encounterId}', encounterId), params);
}

/**
 * Fetch the provider appointments
 *
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchProviderAppointments(params) {
    return fetchHelper.callGet(GET_PROVIDER_APPOINTMENTS, params);
}

/**
 * Fetch the provider active text consultations
 *
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchProviderActiveTextConsultations() {
    return fetchHelper.callGet(GET_PROVIDER_ACTIVE_TEXT_CONSULTATIONS, null);
}


/**
 * Fetch the provider text consultations
 *
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchProviderTextConsultations(params) {
    return fetchHelper.callGet(GET_PROVIDER_TEXT_CONSULTATIONS, params);
}

/**
 * Update the appointment
 *
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function updateAppointment(params) {
    return fetchHelper.callPut(UPDATE_APPOINTMENT, params);
}

/**
 * Create new note for given user
 *
 * @param {object} params params sent together with the request
 * @param {string} appointmentId id of the appointment to create note for
 * @param {string} encounterId id of the encounter to create note for
 * @returns {object} promise object
 */
export function createNote(params, appointmentId, encounterId) {
    let path = "";
    if (appointmentId || encounterId) {
        path += "?";
        if (appointmentId) {
            path += "appointmentId=" + appointmentId;
        }
        if (appointmentId && encounterId) {
            path += "&encounterId=" + encounterId;
        } else if (appointmentId == null && encounterId) {
            path += "encounterId=" + encounterId;
        }
    }
    return fetchHelper.callPost(CREATE_NEW_NOTE + path, params);
}

/**
 * Edit existing note
 *
 * @returns {object} promise object
 */
export function editNote(params) {
    return fetchHelper.callPut(EDIT_NOTE, params);
}

/**
 * Delete note with given id
 *
 * @param {string} noteId id of the note to be deleted
 * @returns {object} promise object
 */
export function deleteNote(noteId) {
    return fetchHelper.callDelete(DELETE_NOTE.replace('{noteId}', noteId));
}

/**
 * Fetch the given user notes
 *
 * @param {string} userId id of the user to fetch notes for
 * @returns {object} promise object
 */
export function fetchUserNotes(userId) {
    return fetchHelper.callGet(ALL_NOTES_FOR_USER.replace('{userId}', userId));
}

/**
 * Fetch the given user notes for appointment
 *
 * @param {string} userId id of the user to fetch notes for
 * @param {string} appointmentId id of the appointment to fetch notes for
 * @returns {object} promise object
 */
export function fetchUserNotesForAppointment(userId, appointmentId) {
    return fetchHelper.callGet(ALL_NOTES_FOR_USER_AND_APPOINTMENT.replace('{userId}', userId).replace('{appointmentId}', appointmentId));
}

/**
 * Create new encounter for selected appointment
 *
 * @param {string} appointmentId id of the appointment to create encounter for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function createEncounter(appointmentId, params) {
    return fetchHelper.callPost(CREATE_ENCOUNTER.replace('{appointmentId}', appointmentId), params);
}

/**
 * Update existing encounter
 *
 * @returns {object} promise object
 */
export function updateEncounter(params) {
    return fetchHelper.callPut(UPDATE_ENCOUNTER, params);
}

/**
 * Update status for selected appointment
 *
 * @param {object} appointmentId - id of the selected appointment
 * @param {object} status - new status for selected appointment
 * @param {object} note - note to be sent with the appointment
 * @returns {object} promise object
 */
export function updateAppointmentStatus(appointmentId, status, note) {
    return fetchHelper.callPut(UPDATE_APPOINTMENT_STATUS_URL.replace('{appointmentId}', appointmentId).replace('{status}', status), note);
}

/**
 * Mark selected appointment as paid
 *
 * @param {object} appointmentId - id of the selected appointment
 * @returns {object} promise object
 */
export function markAppointmentAsPaid(appointmentId) {
    return fetchHelper.callPut(UPDATE_APPOINTMENT_PAYMENT_STATUS_TO_PAID_URL.replace('{appointmentId}', appointmentId), {});
}

/**
 * Get health issues per user
 *
 * @param {string} userId - id of the user to get health issues for
 * @param {object} params - params sent together with the request
 * @returns {object} promise object
 */
export function getUserHealthIssues(userId, params) {
    return fetchHelper.callGet(GET_HEALTH_ISSUES_PER_USER_URL.replace('{userId}', userId), params);
}

/**
 * Fetch all the available specialties
 *
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function fetchSpecialties(params) {
    return fetchHelper.callGet(GET_SPECIALTIES_URL, params);
}


export function fetchQuestionnairesForOrg(orgId) {
    return fetchHelper.callGet(GET_QUESTIONNAIRES_FOR_ORG_URL.replace("{orgId}", orgId), {});
}

export function fetchSubAppointmentsForAppointment(appointmentId) {
    return fetchHelper.callGet(GET_SUB_APPOINTMENTS_FOR_APPOINTMENT_URL.replace("{appointmentId}", appointmentId), {});
}

/**
 * Fetch the tests templates
 *
 * @returns {object} promise object
 */
export function fetchTestsTemplates() {
    return fetchHelper.callGet(FETCH_TEST_TEMPLATES_URL, {});
}

/**
 * Save tests results
 *
 * @returns {object} promise object
 */
export function saveTestsResults(encounterId, test_results) {
    return fetchHelper.callPut(SAVE_TEST_RESULTS_URL.replace("{encounter_id}", encounterId), test_results);
}

/**
 * Delete tests results
 *
 * @returns {object} promise object
 */
export function deleteTestsResults(encounterId, templateId) {
    return fetchHelper.callDelete(DELETE_TEST_FIELD_VALUES_URL.replace("{encounter_id}", encounterId).replace("{template_id}", templateId));
}

/**
 * Get last completed appointment for user
 *
 * @param {string} userId id of the provider to fetch appointments for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function lastCompletedAppointment(params) {
    return fetchHelper.callGet(GET_LAST_COMPLETED_APPOINTMENT, params);
}