import React, {Component} from 'react'
import UserImage from '../shared/UserImage'
import moment from 'moment'
import classNames from 'classnames'
import {appointmentUtils} from '../../utils/appointmentUtils'
import {formatUtils} from '../../utils/formatUtils'
import StatusIcon from "./StatusIcon";
import {COMPLETED} from '../../constants/appointment_payment_status';
import PropTypes from "prop-types";
import {$$} from "../../helpers/localization";
import {APPOINTMENTS_FILTER, getColorForOption} from "../../constants/select_options";


class AppointmentsListItem extends Component {
    constructor(props) {
        super(props);
        this.participant = {};
    }

    /**
     * An event handler triggered when an appointment is clicked
     */
    onAppointmentClick = () => {
        this.props.onAppointmentChange(this.props.appointment);
    }

    render() {
        let patientImageClass = 'patient-img';
        const activeClass = classNames('appointment-containter', {
            'appointment-active': (this.props.selectedAppointmentId === this.props.appointment.id)
        });

        this.participant = this.props.appointment.participants.find(p => p.participant_role === 'PATIENT');

        return (
            <div id='appointment' className={activeClass} onClick={this.onAppointmentClick}>
                <div className='appointment-container-row'>
                    <StatusIcon className="status-icon" status={this.props.appointment.status}/>
                    <div className={"d-flex"}>
                        <div className='justify-content-center appointment-img-container'>
                            <UserImage userID={this.participant?.user_id}
                                       classnames={patientImageClass}/>
                        </div>
                        <div className={"dropdown-link"}>
                            <div>{this.participant?.fullname}</div>
                            <div className='medrec-grey-2'>
                                {appointmentUtils.appointmentType(this.props.appointment)}
                            </div>
                            {!appointmentUtils.isGroupEventParent(this.props.appointment) ? <div
                                className={this.props.appointment.payment_status === COMPLETED ? "medrec-green-1" : "medrec-grey-2"}>
                                {formatUtils.currencyFormat(this.props.appointment.appointment_price.price_cents, this.props.appointment.appointment_price.currency)}
                            </div> : <div>
                                {this.props.appointment.used_slots} / {this.props.appointment.max_patients}
                            </div>}
                            <div>{appointmentUtils.location(this.props.appointment)}</div>

                            <div
                                style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, this.props.appointment.status)}}>
                                {$$(this.props.appointment.status.toLowerCase())}
                            </div>

                        </div>
                        <div className={"dropdown-link format-date flex-fill text-right"} style={{minWidth: "4.5rem"}}>
                            <i className='kt-menu__link-icon medrec-grey-2 far fa-clock'/> &nbsp;
                            <b>{moment(this.props.appointment.starts_at).format('HH:mm')}</b>
                        </div>
                    </div>
                </div>

                <hr/>
            </div>
        )
    }
}

AppointmentsListItem.propTypes = {
    appointment:  PropTypes.object,
    onAppointmentChange: PropTypes.func,
    selectedAppointmentId: PropTypes.string
}

export default AppointmentsListItem;
