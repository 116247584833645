import React, {Component} from 'react'
import {connect} from 'react-redux'
import ChatWithMessages from './ChatWithMessages'
import {
    clearChat,
    fetchChatMessages,
    getUnreadMessages,
    markAsRead,
    sendMessage
} from '../../../actions/chat_actions'

import {chatService} from '../../../service/chat_service';
import PropTypes from "prop-types";
import {getFirstContactInfoName} from "../../../utils/userUtils";

class ChatBox extends Component {

    state = {
        showChat: true,
        showLastMessages: true,
        userToChatId: this.props.userToChatId,
        userToChatFullName: this.props.userToChatFullName,
        userToChatEmail: this.props.userToChatEmail,
        getLatestMessages: null,
    }

    constructor(props) {
        super(props);
        this._ismounted = true;
    }

    /**
     * Get the user to chat from the properties
     *
     * @param {object} props - the properties object
     * @param {object} state - the state object
     */
    static getDerivedStateFromProps(props, state) {
        if (props.userToChatId && props.userToChatFullName) {
            return {
                userToChatId: props.userToChatId,
                userToChatFullName: props.userToChatFullName
            }
        }
        return state;
    }

    /**
     * Check the current path, if the component is in communication menu or tab to set a flag. Clear chat messages,
     * fetch new ones and set interval
     */
    componentDidMount() {
        if (this.state.userToChatId) {
            this.refreshMessagesAndMarkAsRead();
        }
        this.showChat(this.props.patient.id, this.props.patient.fullname, this.props.patient.email);

    }

    /**
     * Clear chat messages, fetch new ones and set interval
     *
     * @param {object} prevProps - the previous properties object
     * @param {object} prevState - the previous state object
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.props.patient.id !== prevProps.patient.id) {
            this.refreshMessagesAndMarkAsRead().then(() => {
                if(this._ismounted){
                    this.showChat(this.props.patient.id, this.props.patient.fullname, this.props.patient.email);
                }
            });
        }

        if ((!prevState.userToChatId && this.state.userToChatId) || (prevState.userToChatId && this.state.userToChatId && prevState.userToChatId !== this.state.userToChatId)) {
            this.refreshMessagesAndMarkAsRead();
        }

        if (prevProps.chat.messages.entries &&
            this.props.chat.messages.entries &&
            prevProps.chat.messages.entries.length !== this.props.chat.messages.entries.length) {
            this.markMessagesAsRead();
        }

        if (prevProps.chat?.new_message !== this.props.chat?.new_message) {
            let newMessage = this.props.chat.new_message;
            if (newMessage) {
                if (newMessage.to_user_id === this.props.loggedUser.id && newMessage.from_user_id === this.state.userToChatId) {
                    if (!newMessage.seen) {
                        this.props.markAsRead(newMessage.id).then(()=>{this.updateChat(); this.props.getUnreadMessages()});
                    } else {
                        this.updateChat();
                    }
                } else if (newMessage.from_user_id === this.props.loggedUser.id && newMessage.to_user_id === this.state.userToChatId) {
                    this.updateChat();
                } else {
                    this.props.getUnreadMessages();
                }

            }
        }
    }

    updateChat = () => {
        if (this.state.userToChatId) {
            this.props.fetchChatMessages(this.state.userToChatId, null, true);
        }
    }

    /**
     * Clear chat messages, fetch new ones and set interval. Mark unread messages as seen.
     */
    refreshMessagesAndMarkAsRead = async () => {
        if (this.state.userToChatId) {
            this.props.clearChat();
            await this.props.fetchChatMessages(this.state.userToChatId, null, true);
            await this.markMessagesAsRead();
            this.props.getUnreadMessages();
        }
    }

    /**
     * Mark unread messages as read
     */
    markMessagesAsRead = async () => {
        if (this.props.chat && this.props.chat.messages && this.props.chat.messages.entries && this.props.chat.messages.entries.length > 0) {
            for (let message of this.props.chat.messages.entries) {
                if (message.to_user_id === this.props.loggedUser.id && !message.seen) {
                    await this.props.markAsRead(message.id);
                }
            }
        }
    }

    /**
     * Clear the interval when the component unmounts
     */
    componentWillUnmount() {
        this._ismounted = false;
    }

    /**
     * Find the uset to chat and open chat.
     *
     * @param {string} userToChatId - the id of the user to chat
     * @param {string} userToChatFullName - Full name of the user to chat
     * @param {string} userToChatEmail - email of the user to chat
     */
    showChat = (userToChatId, userToChatFullName, userToChatEmail) => {
        this.setState({
            showChat: true,
            showLastMessages: true,
            userToChatId: userToChatId,
            userToChatFullName: userToChatFullName,
            userToChatEmail: userToChatEmail
        });
    }

    /**
     * Set flags to show the latest messages component
     */
    showLastMessages = () => {
        this.setState({showChat: false, showLastMessages: true, userToChatId: null, userToChatFullName: null});
    }

    render() {
        return (<ChatWithMessages
                messages={this.props.chat.messages.entries}
                loggedUser={this.props.loggedUser}
                smallMessages={this.props.smallMessages}
                deSelectUser={this.props.deSelectUser}
                selectedUserId={this.state.userToChatId}
                selectedUserFullName={this.state.userToChatFullName}
                childName={this.props.childName}
                childId={this.props.childId}
                selectedUserEmail={this.state.userToChatEmail}
                sendMessage={this.props.sendMessage}
                auth={this.props.auth}
                users={this.props.users}
                messagesSmall={this.props.messagesSmall}
                hideMedicalHistoryButton={this.props.hideMedicalHistoryButton}
                showLastMessages={this.showLastMessages}/>
        )
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.language.selected,
        users: state.users.list,
        selectedUser: state.selectedUser.data,
        loggedUser: state.userInfo.data,
        chat: state.chat,
        auth: state.authentication
    }
}

const mapDispatchToProps = {
    sendMessage,
    markAsRead,
    clearChat,
    fetchChatMessages,
    getUnreadMessages
}

ChatBox.propTypes = {
    loggedUser: PropTypes.object,
    smallMessages: PropTypes.bool,
    deSelectUser: PropTypes.func,
    messagesSmall: PropTypes.bool,
    hideMedicalHistoryButton: PropTypes.bool,
    userToChatFullName: PropTypes.string,
    userToChatEmail: PropTypes.string,
    userToChatId: PropTypes.string,
    patient: PropTypes.object,
    sendMessage: PropTypes.func,
    updateMessage: PropTypes.func,
    markAsRead: PropTypes.func,
    getUnreadMessages: PropTypes.func,
    fetchChatMessages: PropTypes.func,
    attachNewMessage: PropTypes.func,
    clearChat: PropTypes.func,
    chat: PropTypes.object,
    users: PropTypes.array,
    auth: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatBox)
