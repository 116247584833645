import React, {Component} from "react";
import {appointmentUtils} from "../../utils/appointmentUtils";
import {connect} from "react-redux";
import {clearSelectedAppointmentInProgress, updateAppointmentStatus} from "../../actions/users_actions";
import {usersService} from "../../service/users_service";
import {medicalHistoryHelper} from "../../helpers/medical_history_helper";
import {
    APPOINTMENTS_APPOINTMENT_CHAT,
    APPOINTMENTS_APPOINTMENT_VIDEO,
    DASHBOARD_NEXT_PATIENT_MED_HISTORY
} from "../../constants/pages";
import {Routes} from "../../constants/routes";
import {$$} from "../../helpers/localization";
import moment from "moment";
import CenteredModal from "../shared/CenteredModal";
import {formatUtils} from "../../utils/formatUtils";
import {CONVERTER} from "../../utils/converter";
import UserImage from "../shared/UserImage";
import {APPOINTMENTS_FILTER, getColorForOption} from "../../constants/select_options";
import {paymentStatusUtils} from "../../utils/paymentStatusUtils";
import MessagesButton from "../shared/MessagesButton";
import MedicalHistoryButton from "../History/MedicalHistoryButton";
import CancelAppointmentButton from "../appointments/CancelAppointmentButton";
import OpenAppointmentButton from "../appointments/OpenAppointmentButton";
import history from '../../helpers/history'
import {updateVideoData} from "../../actions/video_actions";
import {VideoApp} from "./VideoApp";
import RoundButton from "../shared/RoundButton";
import PropTypes from "prop-types";
import CancelAppointmentModal from "./CancelAppointmentModal";

class GroupAppointmentPage extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            appointments: [],
            selectedAppointmentId: ""
        }
    }

    componentDidMount() {
        //fetch sub appointments
        this.loadSubAppointments();
    }

    loadSubAppointments = () => {
        usersService.fetchSubAppointmentsForAppointment(this.props.group_appointment.id)
            .then((res) => {
                this.setState({appointments: res});
            })
    }

    componentWillUnmount() {

    }

    openMessages = (patient) => {
        medicalHistoryHelper.prepareMessagesData(APPOINTMENTS_APPOINTMENT_CHAT, patient, Routes.MESSAGES);
    }

    openMedicalHistory = (patient) => {
        medicalHistoryHelper.prepareMedicalHistoryData(DASHBOARD_NEXT_PATIENT_MED_HISTORY, patient, null, Routes.HISTORY);
    }

    cancelAppointment = (appointment, note) => {
        this.props.updateAppointmentStatus(appointment.id, "CANCELED_BY_PROVIDER", note)
            .then(() => {
                this.loadSubAppointments();
            });
    }

    openAppointment = (appointment) => {
        let patient = appointment.participants.find(a => a.participant_role === 'PATIENT');
        medicalHistoryHelper.prepareMedicalHistoryData(APPOINTMENTS_APPOINTMENT_VIDEO, patient, appointment, Routes.APPOINTMENT);
    }

    cancelGroupAppointment = () => {
        this.props.updateAppointmentStatus(this.props.group_appointment.id, "CANCELED_BY_PROVIDER", null)
            .then(() => {
                history.push(Routes.DASHBOARD)
            });
    }

    render() {

        if (this.props.videoData.initiate_call) {
            return <div className={"content group-appointment-page"}>
                <div className={"group-appointment-video-wrapper"}>
                    <VideoApp name={this.props.userInfo.id + ":" + this.props.userInfo.fullname}
                              roomName={this.props.group_appointment.id}
                              groupEvent={true}
                              onHangup={() => this.props.updateVideoData({initiate_call: false})}
                              hideIfInAnotherCall={true}
                    />
                </div>
            </div>
        }

        return <div className={"content group-appointment-page"}>
            <div>
                <h2>{$$("group_appointment")}</h2>
                <div className="d-flex low-shadow-container space-between-justify group_appointment-container">
                    <div className="p-3">
                        <div className='medrec-grey-2'>{$$("date_label")}</div>
                        <span className="appointments-date-label kt-font-lg">
                                {moment(this.props.group_appointment.starts_at).format('D MMM')}
                            <span className="appointments-year-label kt-font-lg">&nbsp;
                                {moment(this.props.group_appointment.starts_at).format('YYYY')}
                            </span>
                        </span>
                        <span className="ml-2 appointments-year-label kt-font-lg">
                            <i className="flaticon2-time medrec-grey-2"/>
                            <span
                                className="text-uppercase">{CONVERTER.formatTime(this.props.group_appointment.starts_at)}</span>
                        </span>
                        {/*{CONVERTER.formatDate(this.props.group_appointment.starts_at, false)}*/}
                    </div>
                    <div className="p-3">
                        <div className='medrec-grey-2'>{$$("fullname_label")}</div>
                        <h5>{appointmentUtils.appointmentType(this.props.group_appointment)}</h5>
                    </div>
                    <div className="p-3">
                        <div className='medrec-grey-2'>{$$("price")}</div>
                        <h5>{formatUtils.currencyFormat(this.props.group_appointment.appointment_price.price_cents, this.props.group_appointment.appointment_price.currency)}</h5>
                    </div>
                    <div className="p-3">
                        <div className='medrec-grey-2'>{$$("occupied_seats")}</div>
                        <h5>{this.props.group_appointment.used_slots}</h5>
                    </div>
                    <div className="p-3">
                        <div className='medrec-grey-2'>{$$("max_number_participants_label")}</div>
                        <h5>{this.props.group_appointment.max_patients}</h5>
                    </div>
                    <div className="flex-grow-1 flex-shrink-1">

                    </div>
                    <div className="flex-grow-0 flex-shrink-0">
                        <CancelAppointmentButton title={$$('cancel_appointment_label')}
                                                 onClick={() => {
                                                     this.setState({showDeleteGroupAppointment: true})
                                                 }}/>
                        {!this.props.videoData.initiate_call && <RoundButton onClick={() => {
                            this.props.updateVideoData({initiate_call: true});
                        }}
                                                                             btn_classes="btn-outline-primary ml-2"
                                                                             icon_classes="fas fa-video"
                                                                             title={$$("start_video_call")}
                        />
                        }
                    </div>
                </div>

                <CancelAppointmentModal
                    hideNote={true}
                    appointment={this.props.group_appointment}
                    show={this.state.showDeleteGroupAppointment}
                    onHide={() => {
                        this.setState({showDeleteGroupAppointment: false})
                    }}
                    onConfirm={this.cancelGroupAppointment}
                />


                <h4>{$$("patients_label")}</h4>
                <div>
                    <GroupEventList
                        appointments={this.state.appointments}
                        onMessagesClick={this.openMessages}
                        onOpenAppointment={this.openAppointment}
                        onOpenMedicalHistory={this.openMedicalHistory}
                        cancelAppointment={this.cancelAppointment}
                        lang={this.props.i18n.selected.lang}
                    />
                </div>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        i18n: state.language,
        selectedUser: state.selectedUser,
        group_appointment: state.groupAppointment,
        videoData: state.video.data,
        userInfo: state.userInfo.data,
    }
}

export default connect(mapStateToProps, {
    clearSelectedAppointmentInProgress,
    updateAppointmentStatus,
    updateVideoData
})(GroupAppointmentPage)

GroupAppointmentPage.propTypes = {
    i18n: PropTypes.any,
    videoData: PropTypes.any,
    userInfo: PropTypes.any,
    updateVideoData: PropTypes.func,
    updateAppointmentStatus: PropTypes.func,
    group_appointment: PropTypes.any,
}

class GroupEventList extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            appointments: this.sort(this.props.appointments)
        }
    }

    sort = (appointments) => {
        return appointments.sort((a, b) => {
            if (a.status === b.status) {
                return a.server_modified_timestamp - b.server_modified_timestamp;
            }
            return a.status === 'ACCEPTED' ? -1 : 1;
        });
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.appointments !== prevProps.appointments) {
            this.setState({appointments: this.sort(this.props.appointments)})
        }
    }

    onCancelAppointment = (appointment) => {
        this.setState({appointmentToCancel: appointment, showCancelDialog: true})
    }

    render() {
        return <div className="low-shadow-container sub-appointments-table-container">
            <div className="d-table sub-appointments-table w-100">
                {this.state.appointments.map((a, i) => {
                    return <AppointmentListItem key={i}
                                                appointment={a}
                                                onMessagesClick={this.props.onMessagesClick}
                                                onOpenAppointment={this.props.onOpenAppointment}
                                                onCancelAppointment={this.onCancelAppointment}
                                                onOpenMedicalHistory={this.props.onOpenMedicalHistory}
                                                lang={this.props.lang}/>

                })}
            </div>
            {this.state.showCancelDialog && <CancelAppointmentModal
                appointment={this.state.appointmentToCancel}
                show={this.state.cancelAppointmentModalOpened}
                onHide={() => {
                    this.setState({showCancelDialog: false})
                }}
                onConfirm={(app, note) => {
                    this.props.cancelAppointment(app, note);
                    this.setState({
                        appointmentToCancel: null,
                        showCancelDialog: false
                    })
                }}
            />}
        </div>
    }

}

GroupEventList.propTypes = {
    cancelAppointment: PropTypes.func,
    onOpenAppointment: PropTypes.func,
    onOpenMedicalHistory: PropTypes.func,
    onMessagesClick: PropTypes.func,
    lang: PropTypes.any,
    appointments: PropTypes.any,
}

class AppointmentListItem extends Component {

    render() {
        let appointment = this.props.appointment;
        let patient = appointment.participants.find(p => p.participant_role === 'PATIENT');
        let age = CONVERTER.millisecondsToAge(patient.birthday);
        let paymentStatus = paymentStatusUtils.paymentStatus(this.props.appointment)
        let isFree = appointment.appointment_price.price_cents === 0;

        return <div className="d-table-row">
            <div className="table-cell">
                <div className='d-flex '>
                    <div className='p-3'>
                        <UserImage userID={patient.user_id}
                                   classnames="patient-img"/>
                    </div>
                    <div className={"dropdown-link"}>
                        <span className='patient-fullname' style={{'cursor': 'default'}}>{patient.fullname}</span>
                        <br/>
                        {patient.birthday !== 0 && <span
                            className="medrec-blue-1 patient-age">{age}&nbsp;{age === 1 ? $$('year_old') : $$('years_old')}</span>}
                    </div>
                </div>
            </div>
            <div className="table-cell">
                <div className="p-2">
                    <div className='text-center full-width '>
                        <div className='smaller-text medrec-grey-2'>{$$("status_label")}</div>
                        <div className="patient-age"
                             style={{"color": getColorForOption(APPOINTMENTS_FILTER.COLOR, this.props.appointment.status)}}>
                            {$$(appointment.status.toLowerCase())}
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-cell">
                <div className="p-2">
                    <div className='text-center full-width '>
                        <div className='smaller-text medrec-grey-2'>{$$("payment_label")}</div>
                        <div className="patient-age">
                            <div className={paymentStatus.colour}>
                                {isFree ? "-" : paymentStatus.label}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-cell w-100 p-0">
            </div>
            <div className="table-cell">
                <div className='d-flex justify-content-end'>
                    <MessagesButton title={$$('messages_label')} style={{'marginLeft': '0.625rem'}}
                                    onClick={() => {
                                        this.props.onMessagesClick(patient);
                                    }}/>
                    <MedicalHistoryButton title={$$('medical_history')} style={{'marginLeft': '0.625rem'}}
                                          onClick={() => {
                                              this.props.onOpenMedicalHistory(patient);
                                          }}/>
                    {(appointment.status === 'WAITING_FOR_DOCTOR_APPROVAL' ||
                        appointment.status === 'WAITING_FOR_PATIENT_APPROVAL' ||
                        appointment.status === 'NEED_MORE_INFO' ||
                        appointment.status === 'ACCEPTED') &&
                    appointment.encounter_id == null &&
                    <CancelAppointmentButton title={$$('cancel_appointment_label')}
                                             style={{'marginLeft': '0.625rem'}} onClick={() => {
                        this.props.onCancelAppointment(appointment);
                    }}/>}
                    {! appointmentUtils.isAppointmentCanceled(appointment.status) &&
                    <OpenAppointmentButton title={$$('open_appointment_label')}
                                           style={{'marginLeft': '0.625rem'}} onClick={() => {
                        this.props.onOpenAppointment(appointment)
                    }
                    }/>}
                </div>
            </div>

        </div>
    }
}
AppointmentListItem.propTypes = {
    onOpenAppointment: PropTypes.func,
    onOpenMedicalHistory: PropTypes.func,
    onMessagesClick: PropTypes.func,
    onCancelAppointment: PropTypes.func,
    appointment: PropTypes.any,
}