import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    createNhisMedicationTemplateInDB,
    createNhisPrescriptionInDB, createNhisPrescriptionTemplateInDB,
    nhisCreatePrescription,
    nhisLogin, nhisPrescriptionJsonToMedPlans, nhisPrescriptionXmlToMedPlans,
    nhisSignDocument, updateNhisPrescriptionInDB
} from "../../../../service/nhis_service";
import {Button, ButtonGroup, ModalBody} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {$$} from "../../../../helpers/localization";
import FormGroup from "react-bootstrap/FormGroup";
import Select from "../../../shared/Select";
import {v4} from "uuid";
import {NhisPrescriptionProvider, useNhisPrescriptionContext} from "./NhisPrescriptionProvider";
import {dosageParser} from "./nhis_dosage_parser";
import DatePicker from "react-datepicker";
import {clinicService} from "../../../../service/clinic_service";
import {getResolvedOptions} from "../../../../constants/select_options";
import {countryOptions} from "../../../../constants/countries";
import {CONVERTER} from "../../../../utils/converter";
import SearchableSelect, {SearchableSelectAsync} from "./SearchableSelect";
import DownloadEditDeleteButtons from "../../../shared/DownloadEditDeleteButtons";
import {getClinicianNameWithTitle} from "../../../../utils/getClinicianNameWithTitle";
import {components} from "react-select";
import {DUMMY} from "../../../../actions/actions";
import store from "../../../../store";
import * as classnames from "classnames";
import {NhisMedicationTemplates} from "./NhisMedicationTemplates";
import CenteredModal from "../../../shared/CenteredModal";
import {BoolSwitch} from "./BoolSwitch";
import ExamLinkIcon from "../../ExamLinkIcon";
import moment from "moment";

function Success({p, onClose}) {
    const [templateName, setTemplateName] = useState("");
    const [createTemplate, setCreateTemplate] = useState(false);
    const [formClass, setFormClass] = useState("");

    return <div className="text-left">
        <p className="text-center">{$$("nhis.successfully_created_and_sent_to_nhis")}</p>
        <h5 className="text-center">{p.nrnPrescription}</h5>

        <form noValidate={true} className={formClass} onSubmit={(e) => {
            e.preventDefault();
            if (!e.target.checkValidity()) {
                setFormClass("was-validated");
                return;
            }
            onClose(createTemplate ? templateName.trim() : "");
        }}>
            <FormGroup className="form-group-sm">
                <label>{$$('nhis.create_template_label')}</label>
                <BoolSwitch checked={createTemplate} onChange={() => {
                    setCreateTemplate(!createTemplate);
                }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
            </FormGroup>
            {createTemplate && <FormGroup className="form-group-sm">
                <label>{$$('nhis.template_name_label')}</label>
                <input className="form-control form-control-sm" type="text" value={templateName}
                       required={createTemplate}
                       onChange={(e) => {
                           setTemplateName(e.target.value)
                       }}/>
            </FormGroup>}
            <br/>
            <div className="modal-footer">
                <Button type="submit" size="sm" variant="primary">{$$("close_btn_label")}</Button>
            </div>
        </form>
    </div>
}

export function NhisPrescription(props) {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [nhisPrescription, setNhisPrescription] = useState();
    const [nhisPrescriptionEntity, setNhisPrescriptionEntity] = useState();
    const [sendingInProgress, setSendingInProgress] = useState(false);

    useEffect(() => {
        if (props.template) {
            setShowModal(true);
        }
    }, [props.template])

    const createPrescription = (p) => {
        const body = {};
        body.lrn = p.prescription.lrn;
        body.prescription_data_json = JSON.stringify(p);
        body.patient_id = props.selectedUser.id;
        return createNhisPrescriptionInDB(body)
    }

    const updatePrescription = (entity, nrn, data) => {
        const body = {...entity, nrn}
        if (data) {
            body.prescription_data_json = JSON.stringify(data);
        }
        if (nrn) {
            body.sent_to_nhis = true;
        }
        return updateNhisPrescriptionInDB(body);
    }

    if (!showModal) {
        return <ExamLinkIcon onClick={() => {
            setShowModal(true);
        }} labelKey="nhis.create_prescription_label"/>
    }

    return <NhisPrescriptionProvider>
        <Modal backdrop="static" show={showModal} size={"lg"}>
            <ModalBody className="nhis-prescription-modal-body">
                {success && <Success p={nhisPrescription} onClose={async (templateName) => {
                    if (templateName) {
                        //save prescriptionTemplate
                        const data = {
                            template_name: templateName,
                            template_data_json: JSON.stringify(nhisPrescription.prescription)
                        }
                        await createNhisPrescriptionTemplateInDB(data);
                        props.onTemplateAdded();
                    }

                    setSuccess(false);
                    setShowModal(false);
                    setNhisPrescription(null);
                    setNhisPrescriptionEntity(null);
                    setSendingInProgress(false);
                }}/>}
                {!success && <div>
                    <NhisPrescriptionWizard icdCodes={props.icdCodes} selectedUser={props.selectedUser}
                                            provider={props.provider}
                                            orgId={props.orgId}
                                            onCancel={() => {
                                                setShowModal(false);
                                                setNhisPrescription(null);
                                                props.clearTemplate();
                                            }}
                                            lang={props.lang}
                                            nrnExamination={props.nrnExamination || ""}
                                            nhisSubmitError={error}
                                            onCleanError={() => setError("")}
                                            template={props.template}
                                            clearTemplate={props.clearTemplate}
                                            sendingInProgress={sendingInProgress}
                                            onSubmitToNhis={async (p) => {
                                                setError("");
                                                setSuccess(false);
                                                setSendingInProgress(true)
                                                let pEntity;
                                                if (!nhisPrescriptionEntity) {
                                                    pEntity = await createPrescription(p);
                                                    setNhisPrescriptionEntity(pEntity);
                                                } else {
                                                    pEntity = await updatePrescription(nhisPrescriptionEntity, null, p);
                                                    setNhisPrescriptionEntity(pEntity);
                                                }
                                                nhisLogin((loginSuccess) => {
                                                    nhisSignDocument(p, (signedPrescription) => {
                                                        nhisCreatePrescription(loginSuccess.accessToken, signedPrescription)
                                                            .then(async res => {
                                                                let contents = res["nhis:message"]["nhis:contents"];
                                                                let error = contents["nhis:error"];
                                                                if (error) {
                                                                    if (Array.isArray(error)) {
                                                                        setError(error[0]["nhis:reason"]["value"]);
                                                                    } else {
                                                                        setError(error["nhis:reason"]["value"]);
                                                                    }
                                                                } else {
                                                                    let status = contents["nhis:status"]["value"];
                                                                    if (status === "1") {
                                                                        let nrn = contents["nhis:nrnPrescription"]["value"];
                                                                        p.nrnPrescription = nrn;

                                                                        //update prescription in DB
                                                                        let entity = await updatePrescription(pEntity, nrn);

                                                                        const medPlans = await nhisPrescriptionJsonToMedPlans(p.prescription);
                                                                        //save plans
                                                                        for (const medPlan of medPlans) {
                                                                            //save med plan
                                                                            medPlan.template_name = "";
                                                                            medPlan.nhis_prescription_lrn = p.prescription.lrn;
                                                                            medPlan.nhis_prescription_nrn = nrn;
                                                                            await props.createPrescription(medPlan);
                                                                        }

                                                                        setNhisPrescriptionEntity(entity);
                                                                        setNhisPrescription(p);
                                                                        setSuccess(true);
                                                                    } else {
                                                                        let err = "Рецептата е "
                                                                        switch (status) {
                                                                            case "2":
                                                                                err += "частично изпълнена";
                                                                                break;
                                                                            case "3":
                                                                                err += "изпълнена";
                                                                                break;
                                                                            case "4":
                                                                                err += "отказана";
                                                                                break;
                                                                            case "5":
                                                                                err += "анулирана";
                                                                                break;
                                                                            case "6":
                                                                                err += "частично анулирана";
                                                                                break;
                                                                            case "7":
                                                                                err += "изтекла";
                                                                                break;
                                                                        }
                                                                        setError(err);
                                                                        setSendingInProgress(false);
                                                                    }
                                                                }
                                                                setSendingInProgress(false);
                                                            })
                                                    }, (e) => {
                                                        setError(e);
                                                        setSendingInProgress(false);
                                                    })
                                                }, (e) => {
                                                    setError(e);
                                                    setSendingInProgress(false);
                                                }).catch(error => {
                                                    console.log(error);
                                                    setSendingInProgress(false);
                                                    setError($$("nhis.service_error"));
                                                });
                                            }}
                    />

                </div>
                }
            </ModalBody>
        </Modal>
    </NhisPrescriptionProvider>
}


function NhisPrescriptionWizard({template, icdCodes, onCancel, selectedUser, orgId, provider, onSubmitToNhis, nhisSubmitError, onCleanError, sendingInProgress, clearTemplate, nrnExamination, lang}) {
    const {isLoading, isError, reload} = useNhisPrescriptionContext();
    if (isLoading) {
        return <div><p className="text-center">{$$("nhis.processing_body")}</p></div>;
    }
    if (isError) {
        return <div>
                <div className="mt-4 mb-4">
                    <p className={"text-center text-danger"}>{$$("nhis.error_loading_nomenclatures")}</p>
                </div>
            <Modal.Footer>
                <Button type="button" variant="primary" onClick={reload}>{$$("nhis.try_again")}</Button>
                <Button variant={"outline-primary"} type={"button"} className="ml-2"
                onClick={onCancel}>{$$("cancel_btn")}</Button>
            </Modal.Footer>
        </div>
    }
    return <NhisPrescriptionWizardContent
        template={template}
        icdCodes={icdCodes}
        onCancel={onCancel}
        selectedUser={selectedUser}
        orgId={orgId} provider={provider}
        onSubmitToNhis={onSubmitToNhis}
        nhisSubmitError={nhisSubmitError}
        onCleanError={onCleanError}
        sendingInProgress={sendingInProgress}
        clearTemplate={clearTemplate}
        nrnExamination={nrnExamination}
        lang={lang}
    />
}


function NhisPrescriptionWizardContent(
    {
        template,
        icdCodes,
        onCancel,
        selectedUser,
        orgId,
        provider,
        onSubmitToNhis,
        nhisSubmitError,
        onCleanError,
        sendingInProgress,
        clearTemplate,
        nrnExamination,
        lang
    }
) {
    const [prescription, setPrescription] = useState({
        category: "T1",
        allowedRepeatsNumber: 0,
        dispensationType: 2,
        groups: [{}]
    });
    const [selectedTab, setSelectedTab] = useState({key: "Preview"});
    const [icdCodeOptions, setIcdCodeOptions] = useState([]);
    const [editSubject, setEditSubject] = useState(!prescription.subject);
    const [editRequester, setEditRequester] = useState(!prescription.requester && !editSubject);
    const {options, nhifMedicationCodeOptions, medicationCodeOptions} = useNhisPrescriptionContext();

    useEffect(() => {
        if (template) {
            let t = JSON.parse(template.template_data_json);
            t.authoredOn = null;
            t.lrn = null;
            const isByNhif = ['T2', 'T3', 'T6', 'T7'].includes(t.category);
            const opts = isByNhif ? nhifMedicationCodeOptions : medicationCodeOptions;
            for (const group of t.groups) {
                group.medications = group.medications.map((templateMed => {
                    templateMed.mkb = null;
                    if (icdCodes.length > 0) {
                        template.mkb = icdCodes[0];
                    }
                    const found = opts.find(opt => opt.value === (isByNhif ? templateMed.nhifCode : templateMed.medicationCode))
                    const copy = _.cloneDeep(found)
                    copy.data = templateMed;
                    return copy;
                }))
            }
            setPrescription(t)
            clearTemplate();
        }
    }, [template])

    useEffect(() => {
        //todo: T2, T3 => in all groups
        //in other cases:
        let filteredIcdCodeOptions = icdCodes
            .map(code => options["CL011"].find((o) => o.value === code))
            .filter(o => !!o)
            .map(o => {
                o.text = o.label;
                return o;
            });
        if (filteredIcdCodeOptions.length > 0) {
            setIcdCodeOptions(filteredIcdCodeOptions);
        }
    }, [icdCodes])

    useEffect(() => {
        if (!editRequester) {
            setEditSubject(!prescription.subject);
            if (!prescription.requester && prescription.subject) {
                setEditRequester(true);
            }
        }
    }, [prescription.subject]);

    useEffect(() => {
        if (!editSubject) {
            setEditRequester(!prescription.requester)
        }
    }, [prescription.requester]);

    const onUpdatePrescriptionPart = (p) => {
        let tab = {key: "Group", value: undefined}
        if (p.category === 'T3') {
            if (p.groups.length === 1 && prescription.category !== p.category) {
                p.groups[0].groupIdentifier = "A";
                p.groups.push({groupIdentifier: "B"});
                p.groups.push({groupIdentifier: "C"});
            }
            tab.value = "A";
        } else {
            if (p.groups.length > 1 && prescription.category !== p.category) {
                p.groups.splice(1)
                p.groups[0].groupIdentifier = undefined;
            }
        }
        if (p.category !== prescription.category) {
            p.groups.forEach(g => g.medications = []);
        }
        setPrescription(p);
        setSelectedTab(tab);
        store.dispatch({type: DUMMY});
    }

    const onUpdateMedicationGroup = (group) => {
        let p = _.cloneDeep(prescription);
        if (!group.groupIdentifier) {
            p.groups[0] = group;
        } else {
            switch (group.groupIdentifier) {
                case "A":
                    p.groups[0] = group;
                    break;
                case "B":
                    p.groups[1] = group;
                    break;
                case "C":
                    p.groups[2] = group;
                    break;
            }
        }
        setPrescription(p);
        store.dispatch({type: DUMMY});
    }

    const onUpdateSupplement = (supplements) => {
        let p = _.cloneDeep(prescription);
        p.supplements = supplements ? supplements : undefined;
        setPrescription(p);
        store.dispatch({type: DUMMY});
    }

    const onUpdatePatientInfo = (patientInfo) => {
        let p = _.cloneDeep(prescription);
        p.subject = patientInfo;
        setPrescription(p);
        store.dispatch({type: DUMMY});
    }

    const onUpdateRequester = (requester) => {
        let p = _.cloneDeep(prescription);
        p.requester = requester;
        setPrescription(p);
        store.dispatch({type: DUMMY});
    }

    const onPrescriptionComplete = () => {
        const p = _.cloneDeep(prescription)
        const {subject, requester, ...rest} = p;
        const forSign = {
            senderId: requester.pmi,
            prescription: rest,
            subject,
            requester
        }
        if (rest.financingSource !== 2) {
            forSign.requester.qualification_nhifCode = null;
            forSign.requester.qualification = null;
        }
        forSign.prescription.groups.forEach(group => {
            group.medications = group.medications.map((med, i) => {
                const medication = med.data;
                medication.sequenceId = i + 1;
                return medication;
            });
        })
        store.dispatch({type: DUMMY});
        onSubmitToNhis(forSign);
    }

    //console.log("Prescription", prescription)

    if (sendingInProgress) {
        return <div><p className="text-center">{$$("nhis.processing_body")}</p>
        </div>
    }

    if (nhisSubmitError) {
        return <div><p className="text-danger">{nhisSubmitError}</p>
            <div className="modal-footer mt-4">
                {/*<CloseAndExitBtn onCancelPrescription={onCancel}/>*/}
                <button type="button" className="btn btn-primary btn-sm"
                        onClick={onCleanError}>{$$("close_btn_label")}</button>
            </div>
        </div>
    }

    return <div>
        <TabList prescription={prescription} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
        {selectedTab.key === "Pr" &&
        <PrescriptionPart prescription={prescription} onUpdate={onUpdatePrescriptionPart} icdCodes={icdCodes}
                          onCancelPrescription={onCancel} nrnExamination={nrnExamination}/>}
        {selectedTab.key === "Group" &&
        <MedicationGroup supplement={prescription.supplements} onUpdate={onUpdateMedicationGroup}
                         onUpdateSupplement={onUpdateSupplement}
                         prescription={prescription}
                         icdCodeOptions={icdCodeOptions}
                         medicationGroup={prescription.groups.find((g) => !g.groupIdentifier || g.groupIdentifier === selectedTab.value)}
                         onCancelPrescription={onCancel}
                         onPrescriptionComplete={onPrescriptionComplete}
        />}
        {selectedTab.key === "Preview" && <>
            {!editRequester && <Subject subject={selectedUser} orgId={orgId} prescription={prescription}
                                        setEdit={setEditSubject} edit={editSubject} lang={lang}
                                        onUpdatePatientInfo={onUpdatePatientInfo} onCancelPrescription={onCancel}/>}
            {prescription.subject && !editSubject && <Requester
                setEdit={setEditRequester} edit={editRequester}
                provider={provider} orgId={orgId} prescription={prescription} onUpdateRequester={onUpdateRequester}
                onCancelPrescription={onCancel}/>}
        </>}
        {selectedTab.key === "Preview" && !editRequester && !editSubject &&
        <div className="modal-footer mt-4">
            <CloseAndExitBtn onCancelPrescription={onCancel}/>
            <button type="button" className="btn btn-primary btn-sm" onClick={() => {
                setSelectedTab({key: "Pr"})
            }}>{$$("nhis.btn.next")}</button>
        </div>}
    </div>
}

function TabList({prescription, selectedTab, setSelectedTab}) {
    return <ul className="nav nav-tabs">
        <TabItem onClick={() => {
            //if (prescription.lrn && prescription.groups && prescription.groups[0] && prescription.groups[0].medications?.length > 0) {
            setSelectedTab({key: "Preview"})
            //}
        }} label={$$("nhis.tab.patient")} isSelected={selectedTab.key === "Preview"}/>
        <TabItem onClick={() => {
            if (prescription.subject && prescription.requester) {
                setSelectedTab({key: "Pr"})
            }
        }} label={$$("nhis.tab.prescription")} isSelected={selectedTab.key === "Pr"}
                 disabled={!(prescription.subject && prescription.requester)}/>
        {prescription?.groups && prescription.groups.map((g, i) => {
            return <TabItem
                key={i}
                onClick={() => {
                    if (prescription.lrn) {
                        setSelectedTab({key: "Group", value: g.groupIdentifier})
                    }
                }}
                label={g.groupIdentifier ? `${$$("nhis.tab.group")} ${g.groupIdentifier}` : $$("nhis.tab.medications")}
                isSelected={selectedTab.key === "Group" && selectedTab.value == g.groupIdentifier}
                disabled={!prescription.lrn}
            />
        })}
    </ul>
}

function TabItem({onClick, label, isSelected, disabled}) {
    let className = `nav-link pointer ${isSelected ? "active" : ""} ${disabled ? "disabled" : ""}`
    return <li className="nav-item" onClick={() => onClick()}>
        <span className={className}>{label}</span>
    </li>
}

function Subject({prescription, subject, orgId, onUpdatePatientInfo, onCancelPrescription, setEdit, edit, lang}) {

    return <div><h5>{$$("nhis.patient.title")}</h5>
        {!edit && <div className="low-shadow-container ml-2 p-3 mb-3">
            {prescription.subject.givenName} {prescription.subject.familyName} <DownloadEditDeleteButtons
            handleEditClick={() => {
                setEdit(true)
            }}/>
        </div>}
        {edit && <PatientForm subject={subject} orgId={orgId} prescription={prescription}
                              onCancelPrescription={onCancelPrescription}
                              onUpdatePatientInfo={onUpdatePatientInfo}
                              cancelEdit={() => {
                                  setEdit(false)
                              }}
                              lang={lang}
        />
        }
    </div>
}

function Requester({provider, orgId, prescription, onUpdateRequester, onCancelPrescription, edit, setEdit}) {

    return <div><h5>{$$("nhis.provider.medical_practitioner_practice")}</h5>
        {!edit && <div className="low-shadow-container ml-2 p-3 mb-3">
            {getClinicianNameWithTitle(provider)} <DownloadEditDeleteButtons handleEditClick={() => {
            setEdit(true)
        }}/>
        </div>}
        {edit && <ClinicianForm prescription={prescription} provider={provider} orgId={orgId}
                                onUpdateRequester={onUpdateRequester}
                                onCancelPrescription={onCancelPrescription}
                                cancelEdit={() => {
                                    setEdit(false)
                                }}/>
        }
    </div>
}

function currentDateAsStr() {
    return dateAsStr(new Date());
}

export function dateAsStr(date) {
    return moment(date).format("YYYY-MM-DD")
}

function PrescriptionPart({prescription, icdCodes, onUpdate, onCancelPrescription, lang, nrnExamination}) {
    const [formClass, setFormClass] = useState("");
    const [lrn, setLrn] = useState(prescription.lrn || v4());
    const [authoredOn, setAuthoredOn] = useState(prescription.authoredOn || currentDateAsStr());
    const [category, setCategory] = useState(prescription.category);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [rhifNumberOptions, setRhifNumberOptions] = useState([]);
    const [isProtocolBased, setIsProtocolBased] = useState(prescription.isProtocolBased);
    const [protocolNumber, setProtocolNumber] = useState(prescription.protocolNumber);
    const [protocolDate, setProtocolDate] = useState(prescription.protocolDate);
    const [rhifNumber, setRhifNumber] = useState(prescription.rhifNumber);
    const [basedOn, setBasedOn] = useState(prescription.basedOn || nrnExamination);
    const [financingSource, setFinancingSource] = useState(prescription.financingSource || "4");
    const [dispensationType, setDispensationType] = useState(prescription.dispensationType);
    const [allowedRepeatsNumber, setAllowedRepeatsNumber] = useState(prescription.allowedRepeatsNumber);

    const {options} = useNhisPrescriptionContext();

    const cl008 = useMemo(() => [
        {value: "0", text: $$("nhis.unlimited")},
        {value: "1", text: "1"},
        {value: "2", text: "2"},
        {value: "3", text: "3"},
        {value: "4", text: "4"},
        {value: "5", text: "5"},
        {value: "6", text: "6"},
        {value: "7", text: "7"},
        {value: "8", text: "8"},
        {value: "9", text: "9"},
    ], [lang]);

    useEffect(() => {
        setCategoryOptions(options["CL007"])
        setRhifNumberOptions(options["CL015"]);
    }, [options])

    return <form noValidate={true} className={`has-custom-validation ${formClass}`} onSubmit={(e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            setFormClass("was-validated")
            return;
        }
        let p = _.cloneDeep(prescription);
        p.category = category;
        p.basedOn = basedOn;
        p.allowedRepeatsNumber = allowedRepeatsNumber;
        p.dispensationType = dispensationType;
        p.isProtocolBased = isProtocolBased;
        p.protocolNumber = protocolNumber;
        p.protocolDate = protocolDate;
        p.authoredOn = authoredOn;
        p.lrn = lrn;
        p.rhifNumber = rhifNumber;
        p.financingSource = financingSource;
        onUpdate(p);
    }}>
        {/*<FormGroup className="form-group-sm">
            <label>{$$("nhis.prescription_category_label")}</label>
            <Select options={categoryOptions} class="custom-select-sm"
                    value={category}
                    onChange={({value}) => {
                        setCategory(value);
                        if (value !== 'T1') {
                            setAllowedRepeatsNumber(undefined);
                            setDispensationType(undefined);
                        } else {
                            setAllowedRepeatsNumber(0);
                            setDispensationType(2);
                            setIsProtocolBased(undefined);
                        }

                        if (value === 'T2' || value === 'T3') {
                            setIsProtocolBased(false);
                            setFinancingSource("2");
                        } else {
                            setProtocolNumber(undefined);
                            setProtocolDate(undefined);
                            setRhifNumber(undefined);
                            setFinancingSource("4");
                        }
                    }}
            />
        </FormGroup>*/}
        {financingSource == 2 && <FormGroup className="form-group-sm">
            <label>{$$("nhis.prescription_rhif_number_label")}</label>
            <Select placeHolder={$$("nhis.select")}
                    emptyOption={true}
                    options={rhifNumberOptions}
                    class="custom-select-sm"
                    value={rhifNumber || ""}
                    required={true}
                    onChange={({value}) => {
                        setRhifNumber(value);
                    }}
            />
        </FormGroup>}
        {category === 'T1' &&
        <FormGroup className="form-group-sm">
            <label>{$$("nhis.prescription_number_of_executions_label")}</label>
            <Select class="custom-select-sm"
                    value={allowedRepeatsNumber}
                    options={cl008}
                    onChange={({value}) => {
                        setAllowedRepeatsNumber(value)
                        if (value == 1) {
                            setDispensationType(value == 1 ? 1 : 2)
                        }
                    }}/>
        </FormGroup>
        }

        {(category === 'T2' || category === 'T3') &&
        <FormGroup className="form-group-sm">
            <label>{$$('nhis.is_protocol_based_label')}</label>
            <BoolSwitch checked={isProtocolBased || false} onChange={() => {
                let b = !isProtocolBased;
                setIsProtocolBased(b);
                if (!b) {
                    setProtocolNumber(undefined)
                    setProtocolDate(undefined)
                }
            }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
        </FormGroup>
        }

        {isProtocolBased && <FormGroup className="form-group-sm">
            <label>{$$("nhis.prescription_protocol_number_label")}</label>
            <input className="form-control form-control-sm" type="text" value={protocolNumber || ""}
                   required={isProtocolBased}
                   onChange={(e) => {
                       setProtocolNumber(e.target.value)
                   }}/>
        </FormGroup>}

        {isProtocolBased && <FormGroup className="form-group-sm">
            <label>{$$("nhis.prescription_protocol_date_label")}</label>
            <input className="form-control form-control-sm" type="date" value={protocolDate || ""}
                   required={isProtocolBased}
                   onChange={(e) => {
                       setProtocolDate(e.target.value)
                   }}/>
        </FormGroup>}

        <FormGroup className="form-group-sm">
            <label>{$$("nhis.prescription_based_on_label")}</label>
            <input className="form-control form-control-sm" type="text" value={basedOn || ""}
                   required={['T2', 'T3', 'T6', 'T7'].includes(category)}
                   onChange={(e) => {
                       setBasedOn(e.target.value)
                   }}/>
        </FormGroup>

        {/*<SearchWithDropdown items={icdCodeOptions} value={icdCodeOption} onSearch={(v) => {
                                                if (v && v.length >= 3) {
                                                const searchString = v.toLowerCase().trim();
                                                const searchStrings = searchString.split(" ");
                                                const filterFunction = (item) => {
                                                return searchStrings.reduce((a, term) => a && item.label.toLowerCase().includes(term), true)
                                                };
                                                setIcdCodeOptions(options["CL011"].filter(filterFunction));
                                                } else {
                                                setIcdCodeOptions([]);
                                                }
                                                }} onChange={setIcdCodeOption}
                                                required={isByNhif}
                                                />*/}


        <div className="modal-footer mt-4">
            <CloseAndExitBtn onCancelPrescription={onCancelPrescription}/>
            <button className="btn btn-primary btn-sm">{$$("nhis.btn.save")}</button>
        </div>
    </form>
}

function MedicationGroup({medicationGroup, prescription, supplement, icdCodeOptions, onUpdate, onUpdateSupplement, onCancelPrescription, onPrescriptionComplete}) {

    if (!medicationGroup) {
        return null;
    }

    return <div>
        <NhisMedicationGroupForm prescription={prescription}
                                 medicationGroup={medicationGroup} onUpdate={onUpdate}
                                 icdCodeOptions={icdCodeOptions}
                                 onUpdateSupplement={onUpdateSupplement} supplement={supplement}
                                 onCancelPrescription={onCancelPrescription}
                                 onPrescriptionComplete={onPrescriptionComplete}/>
    </div>
}


function NhisMedicationGroupForm({medicationGroup, prescription, supplement, icdCodeOptions, onUpdate, onUpdateSupplement, onCancelPrescription, onPrescriptionComplete}) {
    const [selectedMeds, setSelectedMeds] = useState([]);
    const [forEdit, setForEdit] = useState();
    const [addItem, setAddItem] = useState();
    const [reloadTemplates, setReloadTemplates] = useState(true);

    useEffect(() => {
        if (medicationGroup.medications) {
            setSelectedMeds([...medicationGroup.medications])
        } else {
            setSelectedMeds([]);
        }
    }, [medicationGroup])

    return <div>
        {!forEdit && !addItem && <MedList selectedMeds={selectedMeds}
                                          onDelete={(med) => {
                                              let arr = [...selectedMeds];
                                              arr.splice(arr.indexOf(med), 1)
                                              setSelectedMeds(arr);
                                              const group = _.cloneDeep(medicationGroup);
                                              group.medications = arr;
                                              onUpdate(group);
                                          }}
                                          onEdit={(a) => {
                                              setForEdit(a);
                                              setAddItem("medication")
                                          }}/>}

        {!forEdit && !addItem && supplement && <Supplement supplement={supplement} onSelect={() => {
            setAddItem("supplement")
        }} onDelete={() => {
            onUpdateSupplement(null)
        }}/>}

        {!addItem && <div><FormGroup>
            <div className="d-flex space-around-justify">
                <button type="button" className="btn btn-primary" onClick={() => {
                    setAddItem("medication")
                }}>{$$("nhis.btn.add_medication")}
                </button>

                <NhisMedicationTemplates onSelect={(medTemplate) => {
                    const group = _.cloneDeep(medicationGroup);
                    const item = JSON.parse(medTemplate.template_data_json);
                    item.mkb = null;
                    if (icdCodeOptions.length > 0) {
                        item.mkb = icdCodeOptions[0].value
                    }
                    let newMeds = [...selectedMeds, item];
                    setSelectedMeds(newMeds);
                    group.medications = newMeds;
                    onUpdate(group);
                    /*setForEdit(item);
                    setAddItem("medication")*/
                }} onReloaded={() => {
                    setReloadTemplates(false)
                }} reload={reloadTemplates}/>

                {!supplement && <button type="button" className="btn btn-primary" onClick={() => {
                    setAddItem("supplement")
                }}>{$$("nhis.btn.add_supplement")}
                </button>}
            </div>
        </FormGroup>
            {!((medicationGroup.groupIdentifier === 'C' || !medicationGroup.groupIdentifier) && medicationGroup.medications) &&
            <div className="modal-footer mt-4">
                <CloseAndExitBtn onCancelPrescription={onCancelPrescription}/>
            </div>}
        </div>}


        {addItem === 'medication' &&
        <NhisEditMedication med={forEdit}
                            prescription={prescription}
                            medication={forEdit?.data}
                            onCancelPrescription={onCancelPrescription}
                            icdCodeOptions={icdCodeOptions}
                            onComplete={(item, templateToCreate) => {
                                const group = _.cloneDeep(medicationGroup);
                                if (!forEdit) {
                                    if (!selectedMeds.includes(item)) {
                                        let newMeds = [...selectedMeds, item];
                                        setSelectedMeds(newMeds);
                                        group.medications = newMeds;
                                    }
                                } else {
                                    let arr = [...selectedMeds];
                                    arr[arr.indexOf(forEdit)] = item
                                    setSelectedMeds(arr);
                                    group.medications = arr;
                                }
                                if (templateToCreate) {
                                    //create med template
                                    const data = {...templateToCreate}
                                    const itemClone = _.cloneDeep(item);
                                    itemClone.data.effectiveDosePeriodStart = null;
                                    data.template_data_json = JSON.stringify(itemClone)
                                    data.template_description = itemClone.data.dosageInstructions.map(di => di.interpretation).join("\n\r");
                                    createNhisMedicationTemplateInDB(data).then(() => setReloadTemplates(true))
                                }
                                onUpdate(group);
                                setForEdit(null);
                                setAddItem(null);
                            }}
                            onCancel={() => {
                                setForEdit(null);
                                setAddItem(null);
                                setReloadTemplates(true);
                            }}/>}

        {addItem === 'supplement' && <div>
            <EditSupplement initialSupplement={supplement}
                            onCancelPrescription={onCancelPrescription}
                            onCancel={() => setAddItem(null)}
                            onUpdate={(s) => {
                                onUpdateSupplement(s);
                                setAddItem(null)
                            }}/>

        </div>}
        {!forEdit && !addItem && (medicationGroup.groupIdentifier === 'C' || !medicationGroup.groupIdentifier) && medicationGroup.medications &&
        <div className="modal-footer mt-4">
            <CloseAndExitBtn onCancelPrescription={onCancelPrescription}/>
            {medicationGroup.medications.length > 0 && <button type={"button"} className="btn btn-primary btn-sm"
                                                               onClick={onPrescriptionComplete}>{$$("nhis.btn.complete")}</button>
            }
        </div>
        }
    </div>
}

function Supplement({supplement, onSelect, onDelete}) {
    const [forDelete, setForDelete] = useState(false);

    return <div className="mb-3">
        <h5>{$$("nhis.supplement")}</h5>
        {forDelete && <CenteredModal title={$$('delete_note_modal_header')}
                                     show={true}
                                     onHide={() => {
                                         setForDelete(null)
                                     }}
                                     onConfirm={() => {
                                         onDelete(forDelete);
                                         setForDelete(null)
                                     }
                                     }
                                     backdropClassName={"confirm-over-other-modal"}
        >
            {$$('nhis.remove_supplement_modal_message')}
        </CenteredModal>}
        <div className="low-shadow-container p-3 mb-3 d-flex space-between-justify">
            <div className="whitespace-pre-line flex-1 pointer" onClick={onSelect}>{supplement}</div>
            <div className="btn btn-outline-danger btn-icon btn-icon-sm btn-circle btn-sm"
                 style={{width: "1.2rem", height: "1.2rem", flex: "0 0 auto"}}
                 onClick={() => setForDelete(true)}>
                <i className="fas fa-times centered-text" style={{
                    width: "100%", height: "100%", fontSize: "0.75rem", marginTop: "0.25rem"
                }}/>
            </div>
        </div>
    </div>
}

function EditSupplement({initialSupplement, onUpdate, onCancelPrescription, onCancel}) {
    const [supplement, setSupplement] = useState(initialSupplement || "");
    const [formClass, setFormClass] = useState("")
    return <div>
        <form noValidate={true} className={formClass} onSubmit={(e) => {
            e.preventDefault();
            if (!e.target.checkValidity()) {
                setFormClass("was-validated")
                return;
            }
            onUpdate(supplement);
        }}>
            <FormGroup className="form-group-sm">
                <label>{$$("nhis.supplement_label")}</label>
                <textarea className="form-control" rows={4} value={supplement} required={true} maxLength={2000}
                          onChange={(e) => setSupplement(e.target.value)}/>
            </FormGroup>
            <div className="modal-footer mt-4">
                <CloseAndExitBtn onCancelPrescription={onCancelPrescription}/>
                <button className="btn btn-primary btn-sm">{$$("nhis.btn.add")}</button>
                <button className="btn btn-secondary btn-sm" type="button"
                        onClick={onCancel}>{$$("nhis.btn.cancel")}</button>
            </div>
        </form>
    </div>

}

function MedList({selectedMeds, onEdit, onDelete}) {
    const [forDelete, setForDelete] = useState(null);
    if (!selectedMeds || selectedMeds.length === 0) {
        return null;
    }
    return <div>
        <h5>{$$("Medications")}</h5>
        {forDelete && <CenteredModal title={$$('delete_note_modal_header')}
                                     show={true}
                                     onHide={() => {
                                         setForDelete(null)
                                     }}
                                     onConfirm={() => {
                                         onDelete(forDelete);
                                         setForDelete(null)
                                     }
                                     }
                                     backdropClassName={"confirm-over-other-modal"}
        >
            {$$('nhis.remove_medication_modal_message')}
        </CenteredModal>}
        <div>{
            selectedMeds.map((a, i) => {
                return <div className="low-shadow-container p-3 mb-3 d-flex space-between-justify" key={i}>
                    <div><a href="#" onClick={(e) => {
                        e.preventDefault();
                        onEdit(a)
                    }}>{a?.label}</a>
                        {a.data?.dosageInstructions?.map(di => {
                            return <div className="medrec-grey-2 small whitespace-pre-wrap" key={di.sequence}>
                                {di.interpretation}
                                <br/>
                            </div>
                        })}
                    </div>
                    <div className="btn btn-outline-danger btn-icon btn-icon-sm btn-circle btn-sm"
                         style={{width: "1.2rem", height: "1.2rem", flex: "0 0 auto"}}
                         onClick={() => setForDelete(a)}>
                        <i className="fas fa-times centered-text" style={{
                            width: "100%", height: "100%", fontSize: "0.75rem", marginTop: "0.25rem"
                        }}/>
                    </div>
                </div>
            })
        }</div>
    </div>
}

function NhisEditMedication({medication, med, prescription, icdCodeOptions, onComplete, onCancel, onCancelPrescription}) {
    const [selectedMed, setSelectedMed] = useState(med || "");
    const [priority, setPriority] = useState(1);
    const [quantity, setQuantity] = useState(1);
    const [effectiveDosePeriodStart, setEffectiveDosePeriodStart] = useState();
    const [noteForPharmacist, setNoteForPharmacist] = useState("");
    const [isQuantityByForm, setIsQuantityByForm] = useState(false);
    const [isSubstitutionAllowed, setIsSubstitutionAllowed] = useState(false);
    const [nhifOption, setNhifOption] = useState("");
    const [nhifOptions, setNhifOptions] = useState([]);
    const [form, setForm] = useState();
    const [formFromCL009, setFormFromCL009] = useState(false);
    const [selectedDosageInstrIdx, setSelectedDosageInstrIdx] = useState(1);
    const [icdCodeOption, setIcdCodeOption] = useState(icdCodeOptions && icdCodeOptions.length > 0 ? icdCodeOptions[0].value : "");
    const [createTemplate, setCreateTemplate] = useState(false);
    const [templateName, setTemplateName] = useState("");

    const [dosageInstructions, setDosageInstructions] = useState([{
        sequence: 1,
        asNeeded: false,
        periodUnit: 'd',
    }]);
    const [dIsWithErrors, setDIsWithErrors] = useState([]);

    const [formClass, setFormClass] = useState("");
    const {nomenclatures, priorityOptions, medicationCodeOptions, nhifMedicationCodeOptions, medicationFormCodeOptions} = useNhisPrescriptionContext();

    useEffect(() => {
        if (medication) {
            setPriority(medication.priority);
            setQuantity(medication.quantityValue);
            setNoteForPharmacist(medication.note || "");
            setIsQuantityByForm(medication.isQuantityByForm);
            setIsSubstitutionAllowed(medication.isSubstitutionAllowed);
            setEffectiveDosePeriodStart(medication.effectiveDosePeriodStart ? new Date(medication.effectiveDosePeriodStart) : null)
            if (medication.nhifCode) {
                let opt = nhifMedicationCodeOptions.find((o) => o.value === medication.nhifCode);
                setNhifOption(opt)
            }
            if (medication.dosageInstructions) {
                let instr = _.cloneDeep(medication.dosageInstructions);
                setDosageInstructions(instr);
            }
        }
    }, [medication])

    /*useEffect(() => {
    //todo: T2, T3 => in all groups
    //todo: in other cases
    let filteredIcdCodeOptions = options["CL011"].filter((o) => icdCodes.includes(o.value)).map(o => {
    o.text = o.label;
    return o;
    });
    setIcdCodeOptions(filteredIcdCodeOptions);
    setIcdCodeOption(filteredIcdCodeOptions[0].value)
    }, [icdCodes])
    */

    useEffect(() => {
        if (medication?.mkb) {
            setIcdCodeOption(medication.mkb)
        }
    }, [medication?.mkb]);

    useEffect(() => {
        if (!selectedMed.value) {
            setForm(null);
            setFormFromCL009(false);
        } else {
            let formId = selectedMed.item.meta["form id"];
            if (formId) {
                let value = medicationFormCodeOptions.find(o => o.value === formId).value;
                setForm(value);
                setFormFromCL009(true);
            } else {
                setForm(medication?.form);
                setFormFromCL009(false);
            }
        }
    }, [selectedMed])

    const isByNhif = useMemo(() => ['T2', 'T3', 'T6', 'T7'].includes(prescription.category), [prescription.category]);

    useEffect(() => {
        if (isByNhif) {
            let filteredOptions = nhifMedicationCodeOptions.filter((option) => {
                let inTypes = true;
                let inMkbs = true;
                const types = option.item.meta["prescription type"]?.split(";").filter(o => o !== "");
                if (types) {
                    inTypes = types.includes(prescription.category);
                }
                const mkbs = option.item.meta["target disease"]?.split(";").filter(o => o !== "");
                if (mkbs && icdCodeOption) {
                    inMkbs = mkbs.includes(icdCodeOption)
                }
                return inTypes && inMkbs;
            });
            setNhifOptions(filteredOptions)
        }
    }, [prescription.category, icdCodeOption])

    const getMedicationCodeOptions = useCallback((query, callback) => {
        callback(medicationCodeOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1));
    }, [medicationCodeOptions])

    const getNhifOptions = useCallback((query, callback) => {
        callback(nhifOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1));
    }, [nhifOptions])

    if (isByNhif && medication && (!nhifOption.value || nhifOptions.length === 0)) {
        return null;
    }

    const selectDI = (i) => {
        if (!dosageInstructions[i - 1]) {
            let prevDI = _.cloneDeep(dosageInstructions[i - 2]);
            prevDI.sequence = prevDI.sequence + 1;
            setDosageInstructions([...dosageInstructions, prevDI])
        }
        setSelectedDosageInstrIdx(i);
    }

    const diLabel = (index) => {
        if (index === dosageInstructions.length + 1) {
            return "+"
        }
        return index;
    }


    return <form noValidate={true} className={`has-custom-validation ${formClass}`} onSubmit={(e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            setFormClass("was-validated");
            //check fieldsets!
            const fieldsets = e.target.getElementsByTagName("fieldset");
            const fieldsetsWithErrors = []
            for (let fieldset of fieldsets) {
                if (fieldset.querySelectorAll("input:invalid").length !== 0) {
                    fieldsetsWithErrors.push(fieldset);
                }
            }
            let numbers = fieldsetsWithErrors.map(fieldset => Number(fieldset.attributes["data-sequence"].value));
            setDIsWithErrors(numbers);
            if (numbers.length !== 0) {
                if (selectedDosageInstrIdx !== numbers[0]) {
                    setSelectedDosageInstrIdx(numbers[0]);
                }
                document.getElementById("dosage-instructions-selector").scrollIntoView({
                    block: "start",
                    behavior: "smooth"
                })
            }
            return;
        }
        setDIsWithErrors([])

        let medicationCode = selectedMed.value;
        let medicationName = selectedMed.item.description;

        if (isByNhif) {
            medicationCode = selectedMed.item.meta.CL009 ? selectedMed.item.meta.CL009 : 0;
            if (medicationCode !== 0) {
                medicationName = medicationCodeOptions.find(o => o.value === medicationCode).item.description;
            } else {
                medicationName = null;
            }
        }
        dosageInstructions.forEach(di => {
            di.interpretation = dosageParser(di, {
                loadNomenclatures: () => {
                    return [
                        nomenclatures["CL013"],
                        nomenclatures["CL020"],
                        nomenclatures["CL034"],
                        nomenclatures["CL035"],
                    ]
                }
            })
        })

        let template = null;
        if (createTemplate) {
            template = {
                template_name: templateName.trim()
            }
        }

        onComplete({
            ...selectedMed, data: {
                medicationCode: medicationCode,
                medicationName: medicationName,
                priority: priority,
                quantityValue: quantity,
                note: noteForPharmacist,
                mkb: icdCodeOption ? icdCodeOption : null,
                isQuantityByForm: isQuantityByForm,
                isSubstitutionAllowed: isSubstitutionAllowed,
                nhifCode: nhifOption.value ? nhifOption.value : null,
                form: form,
                dosageInstructions: dosageInstructions
            }
        }, template);
    }}>
        <FormGroup className="form-group-sm">
            <label>{$$("nhis.medication.icb_label")}</label>
            <Select value={icdCodeOption}
                    onChange={({value}) => {
                        setIcdCodeOption(value);
                        if (isByNhif) {
                            setSelectedMed("")
                            setNhifOption("")
                        }
                    }}
                    required={isByNhif}
                    options={icdCodeOptions}
                    class="custom-select-sm"
            />
        </FormGroup>
        <FormGroup className="form-group-sm">
            <label>{$$("nhis.medication.medication")}</label>
            {isByNhif ?
                <SearchableSelectAsync options={nhifOptions}
                                       loadOptions={getNhifOptions}
                                       value={nhifOption}
                                       onSelect={(nhifOption) => {
                                           setNhifOption(nhifOption);
                                           setSelectedMed(nhifOption)
                                       }}
                                       placeholder={$$("nhis.search_placeholder")}
                                       required={true}
                />
                :
                <SearchableSelectAsync options={medicationCodeOptions}
                                       loadOptions={getMedicationCodeOptions}
                                       required={true}
                                       value={selectedMed}
                                       placeholder={$$("nhis.search_placeholder")}
                                       onSelect={setSelectedMed}/>
            }
            <input type="text" hidden={true} required={true}
                   value={isByNhif ? (nhifOption.value ? nhifOption.value : "") : selectedMed.value ? selectedMed.value : ""}
                   onChange={() => {
                   }}/>
        </FormGroup>
        {selectedMed.value && <FormGroup className="form-group-sm">
            {!formFromCL009 && <label>{$$("nhis.medication.form_label")}</label>}
            {!formFromCL009 ?
                <Select options={medicationFormCodeOptions} class="custom-select-sm"
                        value={form || ""}
                        onChange={({value}) => {
                            setForm(value);
                        }}
                        required={true}
                        emptyOption={true}
                        placeHolder={$$("nhis.select")}
                /> : <div><small>{nomenclatures["CL010"].items[form].description}</small></div>
            }
        </FormGroup>}
        <FormGroup className="form-group-sm">
            <label>{$$("nhis.medication.quantity_label")}</label>
            <input className="form-control form-control-sm" type="number"
                   onWheel={ event => event.currentTarget.blur() }
                   value={quantity}
                   required={true}
                   min={1}
                   onChange={(e) => {
                       setQuantity(e.target.value)
                   }}/>
        </FormGroup>
        <FormGroup className="form-group-sm">
            <BoolSwitch checked={isQuantityByForm} onChange={() => {
                setIsQuantityByForm(!isQuantityByForm)
            }} labelOff={$$("nhis.medication.is_quantity_by_form_off_label")}
                        labelOn={$$("nhis.medication.is_quantity_by_form_on_label")} offFirst={true}/>
        </FormGroup>
        <FormGroup className="form-group-sm">
            <label>{$$("nhis.medication.is_substitution_allowed_label")}</label><br/>
            <BoolSwitch checked={isSubstitutionAllowed} onChange={() => {
                setIsSubstitutionAllowed(!isSubstitutionAllowed)
            }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
        </FormGroup>
        <FormGroup className="form-group-sm">
            <label>{$$("nhis.medication.priority_label")}</label>
            <Select options={priorityOptions} class="custom-select-sm"
                    value={priority}
                    onChange={({value}) => {
                        setPriority(value)
                    }}
            />
        </FormGroup>
        {/*<FormGroup className="form-group-sm">
            <label>{$$("nhis.medication.effective_dose_period_start")}</label>
            <div>
                <DatePicker
                    selected={effectiveDosePeriodStart}
                    onChange={date => setEffectiveDosePeriodStart(date)}
                    selectsStart
                    startDate={effectiveDosePeriodStart}
                    isClearable
                    dateFormat="dd/MM/yyyy"
                    placeholderText={$$('nhis.medication.effective_dose_period_start')}
                    className="form-control form-control-sm"
                />
            </div>
        </FormGroup>*/}
        <FormGroup className="form-group-sm">
            <label>{$$("nhis.medication.note_for_pharmacists_label")}</label>
            <textarea className="form-control form-control-sm" rows={4} maxLength={2000} onChange={e => {
                setNoteForPharmacist(e.target.value)
            }} value={noteForPharmacist}/>
        </FormGroup>

        {
            selectedMed.value && <FormGroup className="form-group-sm" id={"dosage-instructions-selector"}>
                <h5>{$$("nhis.dosage_instr.title")}</h5>
                <ButtonGroup size="sm">
                    <Button type="button" variant={`outline-${dIsWithErrors.includes(1) ? "danger" : "primary"}`}
                            active={selectedDosageInstrIdx === 1}
                            onClick={() => selectDI(1)}>{diLabel(1)}</Button>
                    <Button type="button" variant={`outline-${dIsWithErrors.includes(2) ? "danger" : "primary"}`}
                            active={selectedDosageInstrIdx === 2}
                            onClick={() => selectDI(2)}>{diLabel(2)}</Button>
                    <Button type="button" variant={`outline-${dIsWithErrors.includes(3) ? "danger" : "primary"}`}
                            active={selectedDosageInstrIdx === 3} disabled={dosageInstructions.length < 2}
                            onClick={() => selectDI(3)}>{diLabel(3)}</Button>
                    <Button type="button" variant={`outline-${dIsWithErrors.includes(4) ? "danger" : "primary"}`}
                            active={selectedDosageInstrIdx === 4} disabled={dosageInstructions.length < 3}
                            onClick={() => selectDI(4)}>{diLabel(4)}</Button>
                    <Button type="button" variant={`outline-${dIsWithErrors.includes(5) ? "danger" : "primary"}`}
                            active={selectedDosageInstrIdx === 5} disabled={dosageInstructions.length < 4}
                            onClick={() => selectDI(5)}>{diLabel(5)}</Button>
                </ButtonGroup>
            </FormGroup>
        }
        {
            selectedMed.value && dosageInstructions.map(di => {
                return <div key={di.sequence} className={selectedDosageInstrIdx === di.sequence ? "" : "d-none"}>
                    <DosageInstructions
                        dosageInstructions={di}
                        onRemove={(sequence) => {
                            setDosageInstructions(dosageInstructions.filter(dosageInstruction => dosageInstruction.sequence !== sequence));
                            setSelectedDosageInstrIdx(selectedDosageInstrIdx - 1)
                            setDIsWithErrors([])
                        }}
                        onUpdate={(dosageInstruction) => {
                            setDosageInstructions(dosageInstructions.map(di => di.sequence === dosageInstruction.sequence ? dosageInstruction : di))
                            setDIsWithErrors([])
                        }}
                        last={di.sequence === dosageInstructions.length}
                        prescription={prescription}
                    /></div>
            })
        }
        {selectedMed.value && <div>
            <FormGroup className="form-group-sm">
                <label>{$$("nhis.medication.create_template_label")}</label><br/>
                <BoolSwitch checked={createTemplate} onChange={() => {
                    setCreateTemplate(!createTemplate)
                }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
            </FormGroup>
            {createTemplate && <FormGroup className="form-group-sm">
                <label>{$$("nhis.medication.template_name_label")}</label>
                <input className="form-control form-control-sm" type="text" value={templateName} required={true}
                       onChange={(e) => {
                           setTemplateName(e.target.value)
                       }}/>
            </FormGroup>
            }
        </div>}

        <div className="modal-footer mt-4">
            <CloseAndExitBtn onCancelPrescription={onCancelPrescription}/>
            <button className="btn btn-primary btn-sm"
                    onClick={() => {
                    }}>{$$("nhis.btn.add")}</button>
            <button type="button" className="btn btn-secondary btn-sm"
                    onClick={onCancel}>{$$("nhis.btn.cancel")}</button>
        </div>
    </form>
}

function CloseAndExitBtn(props) {
    const [showConfirm, setShowConfirm] = useState(false);

    if (showConfirm) {
        return <Modal show={showConfirm} backdropClassName={"confirm-over-other-modal"}>
            <Modal.Header>
                <h5 className={"text-danger"}>{$$("warning_title")}</h5>
            </Modal.Header>
            <ModalBody>
                <p>{$$("nhis.confirm_exit_body")}</p>
            </ModalBody>
            <Modal.Footer>
                <Button type="button" size="sm" variant={"danger"}
                        onClick={props.onCancelPrescription}>{$$("nhis.btn.confirm")}</Button>
                <Button type="button" size="sm" variant={"secondary"} onClick={() => {
                    setShowConfirm(false)
                }}>{$$("nhis.btn.cancel")}</Button>
            </Modal.Footer>
        </Modal>
    }

    return <button className="btn btn-danger btn-sm mr-auto" type="button"
                   onClick={() => setShowConfirm(true)}>{$$("nhis.btn.leave_prescription")}</button>
}

function DosageInstructions({dosageInstructions, last, onRemove, onUpdate, prescription}) {
    const [showDelete, setShowDelete] = useState(false);
    const {
        doseQuantityCodesOptions,
        periodUnitOptions,
        boundsUnitOptions,
        intakeTimeOptions,
        intakeRouteOptions
    } = useNhisPrescriptionContext();

    const isByNhif = useMemo(() => ['T2', 'T3', 'T6', 'T7'].includes(prescription.category), [prescription.category]);

    const canShowOffset = useMemo(() => {
        if (dosageInstructions.when) {
            let opt = intakeTimeOptions.find(it => it.value === dosageInstructions.when[0]);
            return opt.item.meta["offset_allowed"] === "true";
        }
    }, [dosageInstructions.when]);

    return <fieldset data-sequence={dosageInstructions.sequence}>
        {dosageInstructions.sequence > 1 && last && <FormGroup className="form-group-sm">
            <Button variant="danger" size={"sm"} onClick={() => {
                setShowDelete(true)
            }}>{$$("nhis.btn.delete_dosage_instruction")}</Button>
            {showDelete && <CenteredModal title={$$('delete_note_modal_header')}
                                         show={true}
                                         onHide={() => {
                                             setShowDelete(false)
                                         }}
                                         onConfirm={() => {
                                             onRemove(dosageInstructions.sequence)
                                             setShowDelete(false)
                                         }
                                         }
                                         backdropClassName={"confirm-over-other-modal"}
            >
                {$$('nhis.confirm_delete_dosage_instruction')}
            </CenteredModal>}
        </FormGroup>}
        <FormGroup className="form-group-sm">
            <label>{$$('nhis.dosage_instr.is_as_needed_label')}</label>
            <BoolSwitch checked={dosageInstructions.asNeeded || false} onChange={() => {
                let di = _.cloneDeep(dosageInstructions);
                di.asNeeded = !dosageInstructions.asNeeded;
                onUpdate(di);
            }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false}/>
        </FormGroup>
        <div className="d-flex">
            <FormGroup className="form-group-sm w-50 mr-1">
                <label>{$$("nhis.dosage_instr.intake_frequency_label")}</label>
                <input className="form-control form-control-sm" type="number"
                       onWheel={ event => event.currentTarget.blur() }
                       value={dosageInstructions.frequency || ""}
                       required={true} min={0}
                       onChange={(e) => {
                           let di = _.cloneDeep(dosageInstructions);
                           di.frequency = e.target.value;
                           onUpdate(di);
                       }}/>
            </FormGroup>
            <FormGroup className="form-group-sm w-50 ml-1">
                <label>{$$("nhis.dosage_instr.intake_period_label")}</label>
                <div className="input-group">
                    <input className="form-control form-control-sm" type="number"
                           onWheel={ event => event.currentTarget.blur() }
                           value={dosageInstructions.period >= 0 ? dosageInstructions.period : ""}
                           required={true} min={0} step="any"
                           onChange={(e) => {
                               let di = _.cloneDeep(dosageInstructions);
                               di.period = e.target.value;
                               onUpdate(di);
                           }}/>
                    <div className="input-group-append">
                        <Select placeHolder={$$("nhis.select")}
                                emptyOption={true}
                                options={periodUnitOptions}
                                class="custom-select-sm"
                                value={dosageInstructions.periodUnit || ""}
                                required={true}
                                onChange={({value}) => {
                                    let di = _.cloneDeep(dosageInstructions);
                                    di.periodUnit = value;
                                    onUpdate(di);
                                }}
                        />
                    </div>
                </div>
            </FormGroup>
        </div>
        <FormGroup className="form-group-sm">
            <label>{$$('nhis.dosage_instr.dose_quantity_code_label')}</label>
            <BoolSwitch checked={dosageInstructions.doseQuantityCodeType || false} onChange={() => {
                let di = _.cloneDeep(dosageInstructions);
                di.doseQuantityCodeType = !dosageInstructions.doseQuantityCodeType;
                di.doseQuantityCode = undefined;
                onUpdate(di);
            }} labelOff={$$("nhis.dosage_instr.form_label")} labelOn={$$("nhis.dosage_instr.unit_label")}
                        offFirst={true}/>
        </FormGroup>
            <FormGroup className="form-group-sm">
                <label>{$$("nhis.dosage_instr.quantity_for_single_intake_label")}</label>
                <div className="input-group">
                    <input className="form-control form-control-sm w-50" type="number"
                           onWheel={ event => event.currentTarget.blur() }
                           value={dosageInstructions.doseQuantityValue || ""}
                           required={true} min={1} step="any"
                           onChange={(e) => {
                               let di = _.cloneDeep(dosageInstructions);
                               di.doseQuantityValue = e.target.value;
                               onUpdate(di);
                           }}/>
                    <div className="input-group-append w-50">
                        {dosageInstructions.doseQuantityCodeType &&
                        <input className="form-control form-control-sm" type="text"
                               value={dosageInstructions.doseQuantityCode || ""}
                               placeholder={$$("nhis.dosage_instr.doseQuantityCodeInputPlaceholder")}
                               required={true} minLength={1}
                               onChange={(e) => {
                                   let di = _.cloneDeep(dosageInstructions);
                                   di.doseQuantityCode = e.target.value;
                                   onUpdate(di);
                               }}/>}
                        {!dosageInstructions.doseQuantityCodeType &&
                        <Select placeHolder={$$("nhis.select")}
                                emptyOption={true}
                                options={doseQuantityCodesOptions}
                                class="custom-select-sm"
                                containerClass="w-100"
                                value={dosageInstructions.doseQuantityCode || ""}
                                required={true}
                                onChange={({value}) => {
                                    let di = _.cloneDeep(dosageInstructions);
                                    di.doseQuantityCode = value;
                                    onUpdate(di);
                                }}
                        />}
                    </div>
                </div>
            </FormGroup>
        <FormGroup className="form-group-sm">
            <label>{$$("nhis.dosage_instr.intake_route_label")}</label>
            <Select placeHolder={$$("nhis.select")}
                    emptyOption={true}
                    enableClear={true}
                    options={intakeRouteOptions}
                    class="custom-select-sm"
                    value={dosageInstructions.route || ""}
                    required={false}
                    onChange={({value}) => {
                        let di = _.cloneDeep(dosageInstructions);
                        di.route = value ? value : null;
                        onUpdate(di);
                    }}
            />
        </FormGroup>
        <FormGroup className="form-group-sm">
            <label>{$$("nhis.dosage_instr.intake_when_label")}</label>
            <Select placeHolder={$$("nhis.select")}
                    emptyOption={true}
                    enableClear={true}
                    options={intakeTimeOptions}
                    class="custom-select-sm"
                    value={dosageInstructions.when ? dosageInstructions.when[0] : ""}
                    required={false}
                    onChange={({value}) => {
                        let di = _.cloneDeep(dosageInstructions);
                        if (value) {
                            di.when = [value];
                            let opt = intakeTimeOptions.find(i => i.value === value);
                            if (opt.item.meta["offset_allowed"] === "false") {
                                di.offset = null;
                            }
                        } else {
                            di.when = null;
                            di.offset = null;
                        }
                        onUpdate(di);
                    }}
            />
        </FormGroup>
        {canShowOffset && <FormGroup className="form-group-sm">
            <label>{$$("nhis.dosage_instr.intake_offset_in_minutes_label")}</label>
            <input className="form-control form-control-sm" type="number"
                   onWheel={ event => event.currentTarget.blur() }
                   value={dosageInstructions.offset || ""}
                   required={true} min={0}
                   onChange={(e) => {
                       let di = _.cloneDeep(dosageInstructions);
                       di.offset = e.target.value;
                       onUpdate(di);
                   }}/>
        </FormGroup>}
        <div className="d-flex">
            <FormGroup className="form-group-sm w-50 mr-1">
                <label>{$$("nhis.dosage_instr.intake_bounds_duration_label")}</label>
                <input className="form-control form-control-sm" type="number"
                       onWheel={ event => event.currentTarget.blur() }
                       value={dosageInstructions.boundsDuration || ""}
                       required={isByNhif || dosageInstructions.boundsDurationUnit || !last} min={1} step="any"
                       onChange={(e) => {
                           let di = _.cloneDeep(dosageInstructions);
                           di.boundsDuration = e.target.value;
                           onUpdate(di);
                       }}/>
            </FormGroup>
            <FormGroup className="form-group-sm w-50 ml-1">
                <label>{$$("nhis.dosage_instr.bounds_duration_unit_label")}</label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        options={boundsUnitOptions}
                        class="custom-select-sm"
                        value={dosageInstructions.boundsDurationUnit || ""}
                        required={isByNhif || dosageInstructions.boundsDuration > 0 || !last}
                        enableClear={!isByNhif && !Number(dosageInstructions.boundsDuration > 0)}
                        onChange={({value}) => {
                            let di = _.cloneDeep(dosageInstructions);
                            di.boundsDurationUnit = value ? value : null;
                            onUpdate(di);
                        }}
                />
            </FormGroup>
        </div>
        <FormGroup className="form-group-sm">
            <label>{$$("nhis.dosage_instr.note_label")}</label>
            <textarea className="form-control form-control-sm" rows={4} maxLength={2000} onChange={e => {
                let di = _.cloneDeep(dosageInstructions);
                di.text = e.target.value;
                onUpdate(di);
            }} value={dosageInstructions.text || ""}/>
        </FormGroup>
    </fieldset>
}




export const NHIS_ID_TYPE_OPTIONS = [
    {value: "1", text: "id_type_egn"},
    {value: "2", text: "id_type_lnz"},
    {value: "3", text: "id_type_ssn"},
    {value: "4", text: "id_type_pass"},
    {value: "5", text: "id_type_other"},
    {value: "6", text: "id_type_newborn"}
]

export const NHIS_GENDER_OPTIONS = [
    {
        value: "1",
        text: "male"
    },
    {
        value: "2",
        text: "female"
    },
    {
        value: "3",
        text: "other"
    },
    {
        value: "4",
        text: "gender_undefined"
    }
]


function PatientForm({prescription, subject, orgId, onUpdatePatientInfo, onCancelPrescription, cancelEdit, lang}) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [idType, setIdType] = useState("1");
    const [personalId, setPersonalId] = useState("");
    const [birthDate, setBirthDate] = useState();
    const [gender, setGender] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [personalInfo, setPersonalInfo] = useState();
    const [formClass, setFormClass] = useState("");

    const populateMissingFromSelectedUser = (patient) => {
        if (subject.city && !patient.patientCity) {
            setCity(subject.city);
        }
        if (subject.country && !patient.patientCountry) {
            setCountry(subject.country.toUpperCase());
        }
        if (subject.gender && !patient.patientGender) {
            switch (subject.gender) {
                case 'MALE':
                    setGender("1");
                    break;
                case 'FEMALE':
                    setGender("2");
                    break;
            }
        }
        if (subject.birthday && !patient.patientBirthDate) {
            setBirthDate(new Date(subject.birthday))
        }
        if (subject.fullname && !patient.patientName) {
            let names = subject.fullname.split(" ").map(f => f.trim());
            if (names.length > 0) {
                setFirstName(names[0]);
            }
            if (names.length > 1) {
                setLastName(names[names.length - 1]);
            }
        }
    }

    const toPatientIdType = (patient) => {
        switch (patient.patientIDType) {
            case "EGN":
                return "1";
            case "LNZ":
                return "2";
            case "SSN":
                return "3";
            case "PASS":
                return "4";
            case "OTHER":
                return "5";
            case "NEWBORN":
                return "6";
        }
    }

    const fromPatientIdType = () => {
        switch (idType) {
            case "1":
                return "EGN";
            case "2":
                return "LNZ";
            case "3":
                return "SSN";
            case "4":
                return "PASS";
            case "5":
                return "OTHER";
            case "6":
                return "NEWBORN";
        }
    }

    useEffect(() => {

        if (prescription.subject) {
            setFirstName(prescription.subject.givenName);
            setLastName(prescription.subject.familyName);
            setPersonalId(prescription.subject.identifier);
            setIdType(prescription.subject.identifierType);
            setBirthDate(new Date(prescription.subject.birthDate));
            setGender(prescription.subject.gender);
            setCity(prescription.subject.address.city);
            setCountry(prescription.subject.address.country);
            clinicService.fetchEncPatientInfo(subject.id, orgId).then(patient => {
                if (patient) {
                    setPersonalInfo(patient);
                }
            });
        } else {
            clinicService.fetchEncPatientInfo(subject.id, orgId).then(patient => {
                if (patient) {
                    setPersonalInfo(patient);
                    if (patient) {
                        if (patient.patientName) {
                            let names = patient.patientName.split(" ").map(f => f.trim());
                            if (names.length > 0) {
                                setFirstName(names[0]);
                            }
                            if (names.length > 1) {
                                setLastName(names[names.length - 1]);
                            }
                        }
                        if (patient.patientIDType) {
                            setIdType(toPatientIdType(patient));
                        }
                        if (patient.patientID) {
                            setPersonalId(patient.patientID);
                        }
                        if (patient.patientBirthDate) {
                            setBirthDate(new Date(patient.patientBirthDate));
                        }
                        if (patient.patientGender) {
                            setGender(patient.patientGender);
                        }
                        if (patient.patientCity) {
                            setCity(patient.patientCity);
                        }
                        if (patient.patientCountry) {
                            setCountry(patient.patientCountry);
                        }
                    }
                }
                populateMissingFromSelectedUser(patient ? patient : {});
            })
        }
    }, [prescription])

    return <form className={formClass} noValidate={true} onSubmit={(e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            setFormClass("was-validated");
            return;
        }

        let age = CONVERTER.millisecondsToAge(new Date(birthDate).getTime());
        const userInfo = {
            patientName: firstName + " " + lastName,
            patientID: personalId,
            patientIDType: fromPatientIdType(),
            patientBirthDate: birthDate,
            patientGender: gender,
            patientCity: city,
            patientCountry: country.toUpperCase(),
        }

        const pi = personalInfo ? {...personalInfo, ...userInfo} : userInfo;

        clinicService.updateEncPatientInfo(subject.id, orgId, pi).then(patient => {
            const prescriptionSubject = {
                "givenName": firstName.trim(),
                "familyName": lastName.trim(),
                "identifierType": idType,
                "identifier": personalId,
                "birthDate": dateAsStr(birthDate),
                "gender": gender,
                "age": age,
                "address": {
                    "city": city.trim(),
                    "country": country.toUpperCase()
                }
            }
            setPersonalInfo(patient);
            onUpdatePatientInfo(prescriptionSubject);
        });

    }}>
        <div className="d-flex">
            <FormGroup className="form-group-sm w-50 mr-1">
                <label>{$$("nhis.patient.first_name_label")}</label>
                <input className="form-control form-control-sm" type="text" value={firstName}
                       required={true}
                       onChange={(e) => {
                           setFirstName(e.target.value)
                       }}/>
            </FormGroup>
            <FormGroup className="form-group-sm w-50 ml-1">
                <label>{$$("nhis.patient.last_name_label")}</label>
                <input className="form-control form-control-sm" type="text" value={lastName}
                       required={true}
                       onChange={(e) => {
                           setLastName(e.target.value)
                       }}/>
            </FormGroup>
        </div>
        <div className="d-flex">
            <FormGroup className="form-group-sm w-50 mr-1">
                <label>{$$("birthday_label")}</label>
                <div>
                    <DatePicker
                        locale={lang || "en"}
                        required={true}
                        selected={birthDate}
                        onChange={date => setBirthDate(date)}
                        selectsStart
                        startDate={birthDate}
                        isClearable
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        showMonthDropdown
                        dropdownMode="select"
                        placeholderText={$$('birthday_label')}
                        className="form-control form-control-sm"
                    />
                </div>
            </FormGroup>
            <FormGroup className="form-group-sm w-50 ml-1">
                <label>{$$("gender_label")}</label>
                <Select name="gender"
                        class="custom-select-sm"
                        options={getResolvedOptions(NHIS_GENDER_OPTIONS)}
                        value={gender}
                        onChange={({value}) => setGender(value)}
                        emptyOption={true}
                        required={true}
                        placeHolder={$$('gender_label')}>
                </Select>
            </FormGroup>
        </div>
        <div className="d-flex">
            <FormGroup className="form-group-sm w-50 mr-1">
                <label>{$$("nhis.patient.idType")}</label>
                <Select name="patientIDType"
                        options={getResolvedOptions(NHIS_ID_TYPE_OPTIONS)}
                        value={idType}
                        class="custom-select-sm"
                        required={true}
                        onChange={({value}) => setIdType(value)}
                        placeHolder={$$('nhis.patient.idType')}>
                </Select>
            </FormGroup>
            <FormGroup className="form-group-sm w-50 ml-1">
                <label>{$$("nhis.patient.personal_id")}</label>
                <input className="form-control form-control-sm" type="text" value={personalId}
                       required={true}
                       onChange={(e) => {
                           setPersonalId(e.target.value)
                       }}/>
            </FormGroup>
        </div>
        <div className="d-flex">
            <FormGroup className="form-group-sm w-50 mr-1">
                <Select
                    class="custom-select-sm"
                    label={$$('country_label')}
                    name="country"
                    options={countryOptions("bg")}
                    value={country.toLowerCase()}
                    emptyOption={true}
                    required={true}
                    onChange={({value}) => setCountry(value.toUpperCase())}/>
            </FormGroup>
            <FormGroup className="form-group-sm w-50 ml-1">
                <label>{$$("city_label")}</label>
                <input className="form-control form-control-sm" type="text" value={city}
                       required={true}
                       onChange={(e) => {
                           setCity(e.target.value)
                       }}/>
            </FormGroup>
        </div>
        <div className="modal-footer mt-4">
            <CloseAndExitBtn onCancelPrescription={onCancelPrescription}/>
            <Button type="submit" size="sm" variant="primary">{$$("nhis.btn.save")}</Button>
            {prescription.subject &&
            <Button type="button" size="sm" variant="secondary" onClick={cancelEdit}>{$$("nhis.btn.cancel")}</Button>}
        </div>
    </form>
}

const SpecialtyOption = (props) => {
    return (
        <components.Option {...props}>{props.children}
            <div className="small medrec-grey-2">{props.data.item.meta?.role}</div>
        </components.Option>
    );
};

function ClinicianForm({prescription, provider, orgId, onUpdateRequester, onCancelPrescription, cancelEdit}) {
    /**
     * "pmi": "2300005302",
     "phone": "2300005302",
     // "qualification": "1043",
     "qualification_nhifCode": "00",
     "practiceNumber": "1111111111"
     */
    const {
        specialtyOptions
    } = useNhisPrescriptionContext();
    const [pmi, setPmi] = useState("");
    const [qualification, setQualification] = useState("");
    const [qualification_nhifCode, setQualification_nhifCode] = useState("");
    const [practiceNumber, setPracticeNumber] = useState("");
    const [phone, setPhone] = useState("");
    const [qualificationOptions, setQualificationOptions] = useState(specialtyOptions)

    const [formClass, setFormClass] = useState("");

    useEffect(() => {
        if (prescription.requester) {
            setPmi(prescription.requester.pmi)
            if (provider.nhis_specialties && provider.nhis_specialties.length > 0) {
                let filteredOptions = qualificationOptions.filter(opt => provider.nhis_specialties.includes(opt.value));
                setQualificationOptions(filteredOptions)
            }
            setQualification(qualificationOptions.find(o => o.value === prescription.requester.qualification));
            setQualification_nhifCode(prescription.requester.qualification_nhifCode)
            setPracticeNumber(prescription.requester.practiceNumber)
            setPhone(prescription.requester.phone)

        } else {
            setPmi(provider.uin || "");
            let phoneNumber = provider.phone_numbers && provider.phone_numbers.length > 0 ? provider.phone_numbers[0] : "";
            phoneNumber = phoneNumber.replaceAll(/[-\s/]/gi, "");
            setPhone(phoneNumber)
            const org = provider.organizations.find(org => org.id === orgId)
            setPracticeNumber(org.uin || "")
            if (provider.nhis_specialties && provider.nhis_specialties.length > 0) {
                let filteredOptions = qualificationOptions.filter(opt => provider.nhis_specialties.includes(opt.value));
                setQualificationOptions(filteredOptions)
                let option = filteredOptions.length === 1 ? filteredOptions[0] : null;
                setQualification(option);
                if (option && option.item?.meta["nhif code"]) {
                    setQualification_nhifCode(option.item.meta["nhif code"])
                } else {
                    setQualification_nhifCode(null);
                }
            }
        }
    }, [prescription])

    return <form className={`has-custom-validation ${formClass}`} noValidate={true} onSubmit={(e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            setFormClass("was-validated");
            return;
        }
        const requester = {
            pmi: pmi,
            qualification: qualification.value,
            qualification_nhifCode: qualification_nhifCode,
            practiceNumber: practiceNumber,
            phone: phone
        }
        onUpdateRequester(requester);
    }}>
        <div className="d-flex">
            <FormGroup className="form-group-sm w-50 mr-1">
                <label>{$$("nhis.provider.pmi")}</label>
                <input className="form-control form-control-sm" type="text" value={pmi} pattern=".{10,10}"
                       required={true}
                       onChange={(e) => {
                           setPmi(e.target.value)
                       }}/>
            </FormGroup>
            <FormGroup className="form-group-sm w-50 ml-1">
                <label>{$$("nhis.provider.practiceNumber")}</label>
                <input className="form-control form-control-sm" type="text" value={practiceNumber} pattern=".{10,10}"
                       required={true}
                       onChange={(e) => {
                           setPracticeNumber(e.target.value)
                       }}/>
            </FormGroup>
        </div>
        <div className="d-flex">
            <FormGroup className="form-group-sm w-50 mr-1">
                <label>{$$("nhis.provider.qualification")}</label>
                <SearchableSelect optionComponent={SpecialtyOption}
                                  options={qualificationOptions}
                                  value={qualification}
                                  placeholder={$$("nhis.search_placeholder")}
                                  onSelect={(qualification) => {
                                      setQualification(qualification);
                                      if (qualification.item.meta["nhif code"]) {
                                          setQualification_nhifCode(qualification.item.meta["nhif code"])
                                      } else {
                                          setQualification_nhifCode(null);
                                      }
                                  }}
                                  required={true}
                                  defaultMenuIsOpen={false}
                />
            </FormGroup>
            <FormGroup className="form-group-sm w-50 ml-1">
                <label>{$$("nhis.provider.phone")}</label>
                <input className="form-control form-control-sm" type="text" value={phone}
                       required={true}
                       onChange={(e) => {
                           setPhone(e.target.value)
                       }}/>
            </FormGroup>
        </div>
        <div className="modal-footer mt-4">
            <CloseAndExitBtn onCancelPrescription={onCancelPrescription}/>
            <Button type="submit" size="sm" variant="primary">{$$("nhis.btn.save")}</Button>
            {prescription.requester &&
            <Button type="button" size="sm" variant="secondary" onClick={cancelEdit}>{$$("nhis.btn.cancel")}</Button>}
        </div>
    </form>
}