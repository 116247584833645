import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import UserInfo from "./UserInfo";
import ProviderImage from "../register/wizard/ProviderImage";
import PropTypes from "prop-types";

export class UserProfileForm extends Component {

    state = {
        hasImage: true,
        hasProfilePicture: true,

    }

    constructor(props) {
        super(props);
    }

    setStateForProfilePicture = (hasPicture) => {
        if (this.state.hasProfilePicture !== hasPicture) {
            this.setState({hasProfilePicture: hasPicture});
        }
    }


    render() {
        return (
            <div>

                <div className="">
                    <h2 className="ml-3">{$$("edit_profile_label")}</h2>
                    <div className="row">
                        <div className="col-xs-8 col-md-8 personal-info">

                            <UserInfo registration={this.props.registration}
                                      specialties={this.props.specialties.specialties}
                                      updateUserInfo={this.props.updateUserInfo}
                                      i18n={this.props.i18n}
                                      documents={this.props.documents}
                                      userInfo={this.props.userInfo}
                                      formDisabled={this.props.formDisabled}
                                      uploadLoggedUserDocument={this.props.uploadLoggedUserDocument}
                                      deleteLoggedUserDocument={this.props.deleteLoggedUserDocument}
                            />

                        </div>
                        <div className="col-xs-4 col-md-4">
                            <div className="row">
                                <div className="text-center margin-auto">
                                    <ProviderImage
                                        isLoggedUserProfile={true}
                                        userID={this.props.userInfo.id}
                                        setProfilePictureState={this.setStateForProfilePicture}
                                        hasProfilePicture={this.state.hasProfilePicture}
                                        hasImage={this.state.hasImage}
                                        userInfo={this.props.userInfo}
                                    />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

UserProfileForm.propTypes = {
    deleteLoggedUserDocument: PropTypes.func,
    documents: PropTypes.array,
    formDisabled: PropTypes.any,
    i18n: PropTypes.object,
    registration: PropTypes.object,
    showSuccessfulAlert: PropTypes.bool,
    specialties: PropTypes.object,
    updateUserInfo: PropTypes.func,
    uploadLoggedUserDocument: PropTypes.func,
    userInfo: PropTypes.object
}

export default UserProfileForm
