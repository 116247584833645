import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import LabResultEntry from './LabResultEntry';
import {infoUtils} from '../../utils/infoUtils'
import {LABORATORY_RESULT} from '../../constants/information-retrieval-types'
import no_lab_results from '../../resources/images/no_lab_results.png'
import moment from 'moment'
import PropTypes from "prop-types";


export class LabResults extends Component {
    state = {
        selectedLabResultId: "",
        loading:true
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let params = {
            before_date_time: moment().valueOf(),
            size: 500
        };

        if (this.props.selectedUser && this.props.selectedUser.id) {
            this.prepareParams(params, true);
        }
    }

    /**
     * Fetch selected user lab results
     *
     * @param {object} e  - the event
     */
    onLoadMore = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let params = {
            before_date_time: moment().valueOf(),
            size: 500
        };
        if (this.props.selectedUser.id && (this.props.labResults.request.finished === undefined || this.props.labResults.request.finished)) {
            if (this.props.labResults.entries && this.props.labResults.entries.length > 0) {
                const entriesLength = this.props.labResults.entries.length;
                params.before_date_time = new Date(this.props.labResults.entries[entriesLength - 1].result_date).getTime();
            }

            this.prepareParams(params, false);
        }
    }

    /**
     * Prepares the params to be sent along with the request of fetching lab results entries
     *
     * @param {object} params - the params object
     * @param {boolean} resetList - variable
     */
    prepareParams = async (params, resetList) => {
        let healthIssueIds = this.props.selectedUser.health_issues.map(h => h.id);
        params.healthIssueIds = healthIssueIds;
        params.types = [LABORATORY_RESULT];
        this.setState({loading:true})
        await this.props.fetchEntries(this.props.selectedUser.id, params, resetList).then(() => {
            this.setState({loading:false})
        });
    }

    /**
     * Selects a specific lab result
     *
     * @param {string} id - the id of the selected lab result
     */
    selectLabResult = (id) => {
        let selectedLabResultId = id;
        this.setState({selectedLabResultId});
    }

    /**
     * Resets the selected lab result
     */
    resetLabResult = () => {
        let selectedLabResultId = "";
        this.setState({selectedLabResultId});
    }

    /**
     * Get the lab results to show. Filter the lab results based on the given input.
     *
     * @returns {array} array of GroupedLabResults
     */
    getLabResults = () => {
        const filters = this.props.filters;
        let noLabResultsObj = {
            imgClass: 'no-lab-results-img',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: 'no-lab-results-secondary-label',
            src: no_lab_results,
            primaryLabel: $$('lab_results_no_data_primary_label'),
            secondaryLabel: $$('lab_results_no_data_secondary_label')
        }


        if (!this.state.loading && this.props.labResults && this.props.labResults.entries && this.props.labResults.entries.length > 0) {
            const filteredLabResults = this.props.labResults.entries.filter(labResult => {
                return (!filters.laboratory || (labResult.laboratory && filters.laboratory.toLowerCase() === labResult.laboratory.toLowerCase())) &&
                    (!filters.after_date_time || new Date(labResult.result_date).getTime() > filters.after_date_time);
            });

            return filteredLabResults && filteredLabResults.length > 0 ? filteredLabResults.map((entry, idx) => {
                return <LabResultEntry key={idx}
                                       index={idx}
                                       labs={this.props.labs}
                                       entry={entry}
                                       selectedLabResultId={this.state.selectedLabResultId}
                                       selectLabResult={this.selectLabResult}
                                       resetLabResult={this.resetLabResult}
                                       filters={this.props.filters}
                                       i18n={this.props.i18n}/>
            }, this) : infoUtils.noData(noLabResultsObj);
        } else if ((this.props.labResults.request.finished || this.props.selectedUser.health_issues.length === 0) && !this.state.loading) {
            return infoUtils.noData(noLabResultsObj);
        }
    }

    render() {
        let labResults = this.getLabResults();
        const hasMore = !this.props.labResults.isLastPage &&
            (this.props.labResults.request.finished === undefined || this.props.labResults.request.finished) &&
            !this.props.labResults.request.error &&
            this.props.selectedUser.health_issues.length > 0 &&
            labResults &&
            labResults.length !== undefined &&
            labResults.length > 0;
        return (
            <div className="card documents-inner-car no-border">
                {this.state.loading && <div className={"report-loader"}/>}
                <div className="card-body inner-card-body zero-padding">
                    <div ref={(ref) => this.listRef = ref}>
                        <div className="list-group">
                            {labResults}
                        </div>
                        {hasMore &&
                        <div className="text-center margin-top-ten">
                            <a href="#" onClick={this.onLoadMore}>{$$('load_more')}</a>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

LabResults.propTypes = {
    changeLabResultsFilters: PropTypes.func,
    clearLabDates: PropTypes.func,
    fetchEntries: PropTypes.func,
    fetchSelectedUserDocuments: PropTypes.func,
    fetchSelectedUserHealthIssues: PropTypes.func,
    filters: PropTypes.object,
    healthIssues: PropTypes.object,
    i18n: PropTypes.object,
    labDates: PropTypes.object,
    labResults: PropTypes.object,
    laboratoryOptions:PropTypes.array,
    labs: PropTypes.array,
    selectedUser: PropTypes.object
}

export default LabResults
