import {
    ALL_USERS_ERROR,
    ALL_USERS_REQUEST_SENT,
    ALL_USERS_SUCCESS,
    CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST,
    CHANGE_APPOINTMENT_IN_USER_APPOINTMENTS_LIST,
    CHANGE_CHARTS_FILTERS,
    CLEAR_GROUP_APPOINTMENT,
    CLEAR_PATIENTS,
    CLEAR_PROVIDER_APPOINTMENTS,
    CLEAR_SELECTED_APPOINTMENT,
    CLEAR_SELECTED_APPOINTMENT_IN_PROGRESS,
    CLEAR_SELECTED_ENCOUNTER,
    CLEAR_SELECTED_USER,
    CLEAR_USER_APPOINTMENTS,
    CLEAR_USER_PASSWORD,
    CREATE_ENCOUNTER_REQUEST_ERROR,
    CREATE_ENCOUNTER_REQUEST_SENT,
    CREATE_ENCOUNTER_REQUEST_SUCCESS,
    CREATE_NOTE_REQUEST_ERROR,
    CREATE_NOTE_REQUEST_SENT,
    DELETE_NOTE_REQUEST_ERROR,
    DELETE_NOTE_REQUEST_SENT,
    DISPLAY_PROVIDER_IMAGE,
    FETCH_APPOINTMENT_USER_ERROR,
    FETCH_APPOINTMENT_USER_REQUEST_SENT,
    FETCH_APPOINTMENT_USER_SUCCESS,
    FETCH_APPOINTMENTS_ERROR,
    FETCH_APPOINTMENTS_REQUEST_SENT,
    FETCH_APPOINTMENTS_SUCCESS,
    FETCH_CHARTS_DATA_ERROR,
    FETCH_CHARTS_DATA_SUCCESS,
    FETCH_DASHBOARD_CHARTS_DATA_ERROR,
    FETCH_DASHBOARD_CHARTS_DATA_SUCCESS,
    FETCH_LAST_APPOINTMENT_ERROR,
    FETCH_LAST_APPOINTMENT_REQUEST_SENT,
    FETCH_LAST_APPOINTMENT_SUCCESS,
    FETCH_PROVIDER_APPOINTMENTS_STATS_ERROR,
    FETCH_PROVIDER_APPOINTMENTS_STATS_SUCCESS,
    FETCH_SELECTED_CARDIAC_STATS_ERROR,
    FETCH_SELECTED_CARDIAC_STATS_SUCCESS,
    FETCH_SELECTED_USER_CARDIAC_STATS_REQUEST_SENT,
    FETCH_SELECTED_USER_ENTRIES_ERROR,
    FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT,
    FETCH_SELECTED_USER_ENTRIES_SUCCESS,
    FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR,
    FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS,
    FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR,
    FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS,
    FETCH_SPECIALTIES_ERROR,
    FETCH_SPECIALTIES_REQUEST_SENT,
    FETCH_SPECIALTIES_SUCCESS,
    FETCH_USER_APPOINTMENTS_ERROR,
    FETCH_USER_APPOINTMENTS_REQUEST_SENT,
    FETCH_USER_APPOINTMENTS_SUCCESS,
    FETCH_USER_NOTES_ERROR,
    FETCH_USER_NOTES_REQUEST_SENT,
    FETCH_USER_NOTES_SUCCESS,
    GET_DATE_RANGE_ERROR,
    GET_DATE_RANGE_SUCCESS,
    GET_PRICE_LIST_SUCCESS,
    GET_USER_PROFILE,
    GET_USER_PROFILE_ERROR,
    LOGOUT,
    NO_MORE_ENTRIES,
    PROCESS_PROVIDER_IMAGE,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS,
    RESET_SELECTED_CARDIAC_STATS_SUCCESS,
    RESET_SELECTED_USER_ENTRIES,
    RESET_SELECTED_USER_ENTRIES_SUCCESS,
    SELECT_APPOINTMENT,
    SELECT_APPOINTMENT_IN_PROGRESS,
    SELECT_GROUP_APPOINTMENT,
    SELECT_USER,
    UPDATE_ENCOUNTER_REQUEST_ERROR,
    UPDATE_ENCOUNTER_REQUEST_SENT,
    UPDATE_ENCOUNTER_REQUEST_SUCCESS,
    UPDATE_NOTE_REQUEST_ERROR,
    UPDATE_NOTE_REQUEST_SENT, UPDATE_PATIENT_MED_PROFILE_CLEAR_REQUEST_STATUS,
    UPDATE_PATIENT_MED_PROFILE_REQUEST_ERROR,
    UPDATE_PATIENT_MED_PROFILE_REQUEST_SENT,
    UPDATE_PATIENT_MED_PROFILE_REQUEST_SUCCESS,
    UPDATE_PATIENT_PERSONAL_INFO_CLEAR_REQUEST_STATUS,
    UPDATE_PATIENT_PERSONAL_INFO_REQUEST_ERROR,
    UPDATE_PATIENT_PERSONAL_INFO_REQUEST_SENT,
    UPDATE_PATIENT_PERSONAL_INFO_REQUEST_SUCCESS,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PASSWORD_ERROR,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_ERROR
} from "../actions/actions";
import {requestStatus} from './requests_reducers';

const initialState = {list: [], filter: null, request: requestStatus(undefined, {})}

/**
 * Users reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the users
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
export function users(state = initialState, action) {
    switch (action.type) {
        case ALL_USERS_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case ALL_USERS_SUCCESS: {
            // eslint-disable-next-line no-unused-vars
            const {error, ...rest} = state;
            if (action.reset) {
                return {
                    ...rest,
                    list: action.result.content,
                    isLastPage: action.result.last,
                    totalElements: action.result.totalElements,
                    request: requestStatus(rest.request, {type: REQUEST_SUCCESS})
                }
            }
            return {
                ...rest,
                list: [...rest.list, ...action.result.content],
                isLastPage: action.result.last,
                totalElements: action.result.totalElements,
                request: requestStatus(rest.request, {type: REQUEST_SUCCESS})
            }
        }
        case ALL_USERS_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {isLastPage, ...rest} = state;
            return {
                ...rest,
                error: action.result,
                request: requestStatus(rest.request, {type: REQUEST_ERROR, response: action.result})
            };
        }
        case CLEAR_PATIENTS:
        case LOGOUT: {
            return {...state, ...initialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * User profile info reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const userInfoInitialState = {data: {}, request: requestStatus(undefined, {})}

export function userInfo(state = userInfoInitialState, action) {
    switch (action.type) {
        case GET_USER_PROFILE:
        case UPDATE_USER_PROFILE: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})};
        }
        case GET_USER_PROFILE_ERROR: {
            return {
                ...state, ...userInfoInitialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.response})
            };
        }
        case UPDATE_USER_PROFILE_ERROR: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.response})};
        }
        case LOGOUT: {
            return {...state, ...userInfoInitialState};
        }
        default: {
            return state
        }
    }
}

/**
 * Appointment Stats  reducer. Reduce state based on action type.
 *
 * @param {object} state the state of provider appointments
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const appointmentStatsInitialState = {data: {}, request: requestStatus(undefined, {})}

export function appointmentStats(state = appointmentStatsInitialState, action) {
    switch (action.type) {
        case FETCH_PROVIDER_APPOINTMENTS_STATS_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})};
        }
        case FETCH_PROVIDER_APPOINTMENTS_STATS_ERROR: {
            return {
                ...state, ...appointmentStatsInitialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.response})
            };
        }
        default: {
            return state
        }
    }
}

/**
 * User password reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const userPasswordInitialState = {request: requestStatus(undefined, {}),}

export function userPassword(state = userPasswordInitialState, action) {
    switch (action.type) {
        case REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})};
        }
        case UPDATE_USER_PASSWORD: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SUCCESS})};
        }
        case UPDATE_USER_PASSWORD_ERROR: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})};
        }
        case CLEAR_USER_PASSWORD:
        case LOGOUT: {
            return {...state, ...userPasswordInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Selected user reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const selectedUserInitialState = {data: {}, request: requestStatus(undefined, {})}

export function selectedUser(state = selectedUserInitialState, action) {
    switch (action.type) {
        case UPDATE_PATIENT_PERSONAL_INFO_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})};
        }
        case UPDATE_PATIENT_PERSONAL_INFO_REQUEST_SUCCESS: {
            const {data, request, ...rest} = state;
            return {...rest, data: {...action.user, health_issues:data.health_issues}, request:  requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case UPDATE_PATIENT_PERSONAL_INFO_REQUEST_ERROR: {
            const {request, ...rest} = state;
            return {...rest, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case UPDATE_PATIENT_PERSONAL_INFO_CLEAR_REQUEST_STATUS: {
            const {request, ...rest} = state;
            return {...rest, request:  requestStatus(undefined, {})}
        }
        case SELECT_USER: {
            // eslint-disable-next-line no-unused-vars
            const {data, ...rest} = state;
            return {...rest, data: action.user}
        }
        case CLEAR_SELECTED_USER: {
            return {...state, ...selectedUserInitialState};
        }
        case LOGOUT: {
            return {...selectedUserInitialState};
        }
        default: {
            return state;
        }
    }
}

const selectedAppointmentInProgressInitialState = {}

export function selectedAppointmentInProgress(state = selectedAppointmentInProgressInitialState, action) {
    switch (action.type) {
        case SELECT_APPOINTMENT_IN_PROGRESS: {
            return {...action.appointment}
        }
        case CLEAR_SELECTED_APPOINTMENT_IN_PROGRESS: {
            return {};
        }
        case LOGOUT: {
            return {};
        }
        default: {
            return state;
        }
    }
}

export function groupAppointment(state = {}, action) {
    switch (action.type) {
        case SELECT_GROUP_APPOINTMENT:
            return {...action.appointment};
        case LOGOUT:
        case CLEAR_GROUP_APPOINTMENT:
            return {};
        default: {
            return state;
        }
    }
}

/**
 * Logbook entries reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const logBookEntriesInitialState = {entries: [], isLastPage: false, request: requestStatus(undefined, {})}

export function logBook(state = logBookEntriesInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_ENTRIES_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, isLastPage, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case FETCH_SELECTED_USER_ENTRIES_SUCCESS: {
            return {
                ...state,
                entries: [...state.entries, ...action.result],
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case FETCH_SELECTED_USER_ENTRIES_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, isLastPage, ...rest} = state;
            return {...rest, entries: [], request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case RESET_SELECTED_USER_ENTRIES_SUCCESS: {
            return {...state, entries: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case NO_MORE_ENTRIES: {
            return {...state, isLastPage: true}
        }
        case RESET_SELECTED_USER_ENTRIES:
        case SELECT_USER:
        case CLEAR_PATIENTS:
        case LOGOUT: {
            return {...state, ...logBookEntriesInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Cardiac Stats reducer.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const cardiacStatsInitialState = {stats: {}, request: requestStatus(undefined, {})}

export function cardiacStats(state = cardiacStatsInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_CARDIAC_STATS_REQUEST_SENT: {
            // eslint-disable-next-line no-unused-vars
            const {error, ...rest} = state;
            return {...rest, request: requestStatus(rest.request, {type: REQUEST_SENT})}
        }
        case FETCH_SELECTED_CARDIAC_STATS_SUCCESS: {
            return {...state, stats: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_SELECTED_CARDIAC_STATS_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {stats, ...rest} = state;
            return {...rest, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case RESET_SELECTED_CARDIAC_STATS_SUCCESS: {
            return {...state, stats: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case SELECT_USER:
        case CLEAR_PATIENTS:
        case LOGOUT: {
            return {...state, ...cardiacStatsInitialState};
        }
        default: {
            return state;
        }
    }
}


/**
 *Latest user logs reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const latestUserMeasurementsInitialState = {entries: [], request: requestStatus(undefined, {})}

export function latestUserMeasurements(state = latestUserMeasurementsInitialState, action) {
    switch (action.type) {
        case FETCH_SELECTED_USER_LATEST_MEASUREMENTS_SUCCESS: {
            return {
                ...state,
                entries: [...state.entries, ...action.result],
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case FETCH_SELECTED_USER_LATEST_MEASUREMENTS_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {entries, ...rest} = state;
            return {...rest, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case SELECT_USER:
        case CLEAR_PATIENTS:
        case LOGOUT: {
            return {...state, ...latestUserMeasurementsInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Medical profile reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const medicalProfileInitialState = {data: {}, request: requestStatus(undefined, {})}

export function medicalProfile(state = medicalProfileInitialState, action) {
    switch (action.type) {
        case UPDATE_PATIENT_MED_PROFILE_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case UPDATE_PATIENT_MED_PROFILE_REQUEST_SUCCESS:
        case FETCH_SELECTED_USER_MEDICAL_PROFILE_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case UPDATE_PATIENT_MED_PROFILE_REQUEST_ERROR:
        case FETCH_SELECTED_USER_MEDICAL_PROFILE_ERROR: {
            return {
                ...state, ...initialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case UPDATE_PATIENT_MED_PROFILE_CLEAR_REQUEST_STATUS: {
            return {...state, request: requestStatus(undefined, {})}
        }
        case SELECT_USER:
        case CLEAR_SELECTED_USER:
        case LOGOUT: {
            return {...state, ...medicalProfileInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Chart data reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const chartsInitialState = {
    userId: null,
    filters: {
        period: "WEEK",
        before_date_time: null,
        after_date_time: null
    },
    chartsData: {
        chartsDataClassificationChart: {},
        chartsDataAveragesPerDayInterval: {},
        chartsDataAveragesPerDayOfWeek: {},
        chartsDataAveragesPerHour: {},
        chartsDataBPTimeline: {},
        chartsDataWeightTimeline: {},
        chartsDataCholesterolTimeline: {},
        chartsDataTemperatureTimeline: {},
        chartsDataHydrationTimeline: {},
        chartsDataSaturationTimeline: {},
        chartsDataRespiratoryRateTimeline: {},
        chartsDataHydrationAveragesPerHour: {}
    }, request: requestStatus(undefined, {})
}

export function charts(state = chartsInitialState, action) {
    switch (action.type) {
        case FETCH_CHARTS_DATA_SUCCESS: {
            addPropertiesToChartDatasets(action.result);
            return {
                ...state,
                userId: action.userId,
                chartsData: action.result,
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case FETCH_CHARTS_DATA_ERROR: {
            const rest = {...state, chartsData: chartsInitialState.chartsData};
            return {...rest, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case CHANGE_CHARTS_FILTERS: {
            return {
                ...state,
                filters: {
                    period: action.period,
                    before_date_time: action.beforeDateTime,
                    after_date_time: action.afterDateTime
                }
            }
        }
        case CLEAR_PATIENTS:
        case SELECT_USER: {
            return {...state, chartsData: chartsInitialState.chartsData, request: chartsInitialState.request};
        }
        case LOGOUT: {
            return {...state, ...chartsInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Dashboard chart data reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const dashboardChartsInitialState = {
    userId: null,
    chartsData: {
        chartsDataClassificationChart: {},
        chartsDataAveragesPerDayInterval: {},
        chartsDataAveragesPerDayOfWeek: {},
        chartsDataAveragesPerHour: {},
        chartsDataBPTimeline: {},
        chartsDataWeightTimeline: {},
        chartsDataCholesterolTimeline: {},
        chartsDataTemperatureTimeline: {},
        chartsDataHydrationTimeline: {},
        chartsDataSaturationTimeline: {},
        chartsDataRespiratoryRateTimeline: {},
        chartsDataHydrationAveragesPerHour: {}
    }, request: requestStatus(undefined, {})
}

export function dashboardCharts(state = dashboardChartsInitialState, action) {
    switch (action.type) {
        case FETCH_DASHBOARD_CHARTS_DATA_SUCCESS: {
            addPropertiesToChartDatasets(action.result);
            return {
                ...state,
                userId: action.userId,
                chartsData: action.result,
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case FETCH_DASHBOARD_CHARTS_DATA_ERROR: {
            const rest = {...state, chartsData: dashboardChartsInitialState.chartsData};
            return {...rest, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case CLEAR_PATIENTS:
        case SELECT_USER: {
            return {
                ...state,
                chartsData: dashboardChartsInitialState.chartsData,
                request: dashboardChartsInitialState.request
            };
        }
        case LOGOUT: {
            return {...state, ...dashboardChartsInitialState};
        }
        default: {
            return state;
        }
    }
}


/**
 * Add properties to chart datasets
 *
 * @param {object} chartsData object containing all the charts data
 */
function addPropertiesToChartDatasets(chartsData) {
    chartsData.chartsDataAveragesPerDayInterval.data.datasets.forEach(d => {
        setBarProperties(d);
    });

    chartsData.chartsDataAveragesPerDayOfWeek.data.datasets.forEach(d => {
        setBarProperties(d);
    });

    chartsData.chartsDataAveragesPerHour.data.datasets.forEach(d => {
        setBarProperties(d);
    });

    chartsData.chartsDataHydrationAveragesPerHour.data.datasets.forEach(d => {
        setBarProperties(d);
    });

    chartsData.chartsDataBPTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
        d.spanGaps = true
    });

    chartsData.chartsDataWeightTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataCholesterolTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataTemperatureTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataHydrationTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataSaturationTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    chartsData.chartsDataRespiratoryRateTimeline.data.datasets.forEach(d => {
        setLineProperties(d);
    });

    function setLineProperties(dataset) {
        dataset.borderDash = [5, 5];
        dataset.borderWidth = 2;
        dataset.pointRadius = 3;
        dataset.pointHoverRadius = 3;
    }

    function setBarProperties(dataset) {
        dataset.barThickness = 15;
        dataset.maxBarThickness = 25;
    }
}

/**
 * Provider appointments reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const providerAppointmentsInitialState = {data: {}, request: requestStatus(undefined, {})}

export function providerAppointments(state = providerAppointmentsInitialState, action) {
    switch (action.type) {
        case FETCH_APPOINTMENTS_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case FETCH_APPOINTMENTS_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_APPOINTMENTS_ERROR: {
            return {
                ...state, ...initialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case CHANGE_APPOINTMENT_IN_APPOINTMENTS_LIST: {
            let appointments = state.data;
            for (let i = 0; i < appointments.length; i++) {
                if (appointments[i].id === action.result.id) {
                    appointments[i] = action.result;
                }
            }
            return {...state, data: appointments, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case CLEAR_PROVIDER_APPOINTMENTS:
        case LOGOUT: {
            return {...state, ...providerAppointmentsInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * User appointments reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const userAppointmentsInitialState = {data: [], request: requestStatus(undefined, {})}

export function userAppointments(state = userAppointmentsInitialState, action) {
    switch (action.type) {
        case FETCH_USER_APPOINTMENTS_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case FETCH_USER_APPOINTMENTS_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_USER_APPOINTMENTS_ERROR: {
            return {
                ...state, ...initialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case CHANGE_APPOINTMENT_IN_USER_APPOINTMENTS_LIST:
            let appointments = state.data;
            for (let i = 0; i < appointments.length; i++) {
                if (appointments[i].id === action.result.id) {
                    appointments[i] = action.result;
                }
            }
            return {...state, data: [...appointments], request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        case CLEAR_USER_APPOINTMENTS:
        case LOGOUT: {
            return {...state, ...userAppointmentsInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * User notes reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const userNotesInitialState = {data: {}, request: requestStatus(undefined, {})}

export function selectedUserNotes(state = userNotesInitialState, action) {
    switch (action.type) {
        case FETCH_USER_NOTES_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case FETCH_USER_NOTES_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_USER_NOTES_ERROR: {
            return {
                ...state, ...initialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case CREATE_NOTE_REQUEST_SENT: {
            return {...state, createRequest: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case CREATE_NOTE_REQUEST_ERROR: {
            return {
                ...state,
                createRequest: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case UPDATE_NOTE_REQUEST_SENT: {
            return {...state, updateRequest: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case UPDATE_NOTE_REQUEST_ERROR: {
            return {
                ...state,
                updateRequest: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case DELETE_NOTE_REQUEST_SENT: {
            return {...state, deleteRequest: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case DELETE_NOTE_REQUEST_ERROR: {
            return {
                ...state,
                deleteRequest: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case LOGOUT: {
            return {...state, ...userNotesInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Last appointment reducer. Reduce state based on action type
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const lastAppointmentInitialState = {data: {}, request: requestStatus(undefined, {})}

export function lastAppointment(state = lastAppointmentInitialState, action) {
    switch (action.type) {
        case FETCH_LAST_APPOINTMENT_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case FETCH_LAST_APPOINTMENT_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_LAST_APPOINTMENT_ERROR: {
            return {
                ...state, ...lastAppointmentInitialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case LOGOUT: {
            return {...state, ...lastAppointmentInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Appointment encounter reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const appointmentEncounterInitialState = {data: {}, request: requestStatus(undefined, {})}

export function appointmentEncounter(state = appointmentEncounterInitialState, action) {
    switch (action.type) {
        case FETCH_APPOINTMENT_USER_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case FETCH_APPOINTMENT_USER_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case FETCH_APPOINTMENT_USER_ERROR: {
            return {
                ...state, ...initialState,
                request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case LOGOUT: {
            return {...state, ...appointmentEncounterInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Selected appointment reducer. Reduce state based on action type
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const selectedAppointmentInitialState = {data: {}, request: requestStatus(undefined, {})}

export function selectedAppointment(state = selectedAppointmentInitialState, action) {
    switch (action.type) {
        case SELECT_APPOINTMENT: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case CLEAR_SELECTED_APPOINTMENT:
        case LOGOUT: {
            return {...state, ...selectedAppointmentInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Date Range reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the symptoms logs
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const dateRangeInitialState = {request: requestStatus(undefined, {})}

export function timeTables(state = dateRangeInitialState, action) {
    switch (action.type) {
        case GET_DATE_RANGE_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case GET_DATE_RANGE_ERROR: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.response})}
        }
        case LOGOUT: {
            return {...state, ...dateRangeInitialState};
        }
        default: {
            return state;
        }
    }
}


/**
 * Price List reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the symptoms logs
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const priceListInitialState = {priceListRequest: requestStatus(undefined, {})}

export function priceList(state = priceListInitialState, action) {
    switch (action.type) {
        case GET_PRICE_LIST_SUCCESS: {
            return {
                ...state,
                result: action.result,
                priceListRequest: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case LOGOUT: {
            return {...state, ...priceListInitialState};
        }
        default: {
            return state;
        }
    }
}


/**
 * Appointment encounter reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the selected user
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const selectedEncounterInitialState = {data: {}, request: requestStatus(undefined, {})}

export function selectedEncounter(state = selectedEncounterInitialState, action) {
    switch (action.type) {
        case CREATE_ENCOUNTER_REQUEST_SENT: {
            return {...state, createRequest: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case CREATE_ENCOUNTER_REQUEST_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case CREATE_ENCOUNTER_REQUEST_ERROR: {
            return {
                ...state,
                createRequest: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case UPDATE_ENCOUNTER_REQUEST_SENT: {
            return {...state, updateRequest: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case UPDATE_ENCOUNTER_REQUEST_SUCCESS: {
            return {...state, data: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case UPDATE_ENCOUNTER_REQUEST_ERROR: {
            return {
                ...state,
                updateRequest: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})
            }
        }
        case CLEAR_SELECTED_ENCOUNTER:
        case LOGOUT: {
            return {...state, ...selectedEncounterInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Specialties reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the specialties
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const specialtiesInitialState = {
    specialties: [],
    request: requestStatus(undefined, {}),
}

export function specialties(state = specialtiesInitialState, action) {
    switch (action.type) {
        case FETCH_SPECIALTIES_REQUEST_SENT: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_SENT})}
        }
        case FETCH_SPECIALTIES_SUCCESS: {
            return {
                ...state,
                specialties: action.result,
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case FETCH_SPECIALTIES_ERROR: {
            return {...state, request: requestStatus(state.request, {type: REQUEST_ERROR, response: action.result})}
        }
        case LOGOUT: {
            return {...state, ...specialtiesInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Provider image reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the provider image
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const providerImageInitialState = {
    image: null,
    request: requestStatus(undefined, {}),
}

export function providerImage(state = providerImageInitialState, action) {
    switch (action.type) {
        case PROCESS_PROVIDER_IMAGE: {
            return {...state, image: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case LOGOUT: {
            return {...state, ...providerImageInitialState};
        }
        default: {
            return state;
        }
    }
}

/**
 * Display provider image reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the display provider image field
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
const displayProviderImageInitialState = {
    shouldDisplayProviderImage: true,
    request: requestStatus(undefined, {}),
}

export function providerImageVisibility(state = displayProviderImageInitialState, action) {
    switch (action.type) {
        case DISPLAY_PROVIDER_IMAGE: {
            return {
                ...state,
                shouldDisplayProviderImage: action.result,
                request: requestStatus(state.request, {type: REQUEST_SUCCESS})
            }
        }
        case LOGOUT: {
            return {...state, ...displayProviderImageInitialState};
        }
        default: {
            return state;
        }
    }
}
