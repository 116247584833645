import React, {Component} from 'react';
import GroupedLogbookEntry from './GroupedLogbookEntry';
import _ from 'underscore';
import PropTypes from "prop-types";

export class Logbook extends Component {
    constructor(props) {
        super(props);this.index = 0;
    }

    /**
     * Get the logbook entries grouped by entry type
     *
     * @returns {Array} of logbook entries
     */
    getGroupedLogbookEntries = () => {
        let groupedLogbook = _.groupBy(this.props.logbook, 'entry_type');
        let result = [];

        for (let type in groupedLogbook) {
            result.push(this.getGroupedLogbookEntry(type, groupedLogbook[type]));
        }

        return result;
    }

    /**
     * Prepares the logbook entries
     *
     * @param {string} type - the logbook entry type
     * @param {Array} entries - an array of logbook entries
     * @returns {ReactComponent} - the grouped logbook entry
     */
    getGroupedLogbookEntry = (type, entries) => {
        return <GroupedLogbookEntry key={++this.index} type={type} entries={entries} settings={this.props.settings}/>;
    }

    render() {
        return (
            <div className='vitals-logbook-entries-container'>
                {this.getGroupedLogbookEntries()}
            </div>
        )
    }
}

Logbook.propTypes = {
    logbook: PropTypes.array,
    settings: PropTypes.object
}

export default Logbook