import React, { Component } from "react";
import Toast from "react-bootstrap/Toast";
import {$$} from "../../../helpers/localization";
import PropTypes from "prop-types";

class MessageNotifications extends Component {
    constructor() {
        super();
    }

    displayNotification() {
        new Notification( $$('new_message_from') + ": " + this.props.message.from ,{
            body:  this.props.message.message,
        })

        this.props.closeNotification();

    }


    render() {
        return (
            <div>
                {!this.props.desktopNotifications && this.props.showNotification && <Toast
                    style={{
                        position: 'absolute',
                        top: 80,
                        right: 0,
                        width: '30rem',
                    }}
                    onClose={this.props.closeNotification}
                    show={this.props.showNotification}
                    delay={7000}
                    autohide
                >

                    <Toast.Header>
                        <img className="user-info-image patient-img"
                             ref={this.props.imgRef}
                             style={{"marginLeft": "0", "zIndex": "9001", "width":"30px", "height":"30px"}}
                        />
                        <strong className="mr-auto" style={{
                            marginLeft: '10px'
                        }}>{this.props.message && this.props.message.from}</strong>
                    </Toast.Header>
                    <Toast.Body>{this.props.message && this.props.message.message}</Toast.Body>
                </Toast>}
                {this.props.desktopNotifications && this.displayNotification()}
            </div>
        );
    }
}

MessageNotifications.propTypes = {
    i18n: PropTypes.any,
    imgRef: PropTypes.any,
    closeNotification: PropTypes.func,
    showNotification: PropTypes.bool,
    desktopNotifications:PropTypes.bool,
    message: PropTypes.any
}

export default MessageNotifications;