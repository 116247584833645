import React, {Component, useCallback, useMemo, useState} from 'react'
import {$$} from '../../helpers/localization'
import {Modal} from "react-bootstrap";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import {formatUtils} from "../../utils/formatUtils";
import PropTypes from "prop-types";
import {FOLLOW_UP_EXAM, PRIMARY_EXAM} from "../../constants/appointment_types";
import {appointmentUtils} from "../../utils/appointmentUtils";
import Toast from "react-bootstrap/Toast";

const MEDREC_APP_URL = process.env.REACT_APP_MEDREC_APP_URL;


class PriceSettingsListView extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
        this.state = {
            deleteOpen: false,
            selectedMenuItem: {}
        }
    }

    getEncounterType = (menuItem) => {
        switch (menuItem.encounter_type) {
            case 'PRIMARY_EXAM':
                return $$('primary_exam');
            case 'FOLLOW_UP_EXAM':
                return $$('follow_up_exam');
            case'OTHER':
                return menuItem.name
        }
    }

    getLocationType = (menuItem) => {
        switch (menuItem.location_type) {
            case 'ONLINE':
                return menuItem.encounter_price_type === 'TEXT_ONLY' ? $$('text_consultation') : $$('online');
            case 'ON_SITE':
                return $$('on_site');
        }
    }

    /**
     * Creates the name cell of the table
     *
     * @param {object} menuItem - the menuItem object
     * @returns {ReactElement} the created table cell
     */
    getNameAndLocationCell = (menuItem) => {
        return <td key={++this.index} className='patient-name-cell'>
            <div className='row'>
                <div className={"dropdown-link"}>
                    <span className='patient-fullname'>{this.getEncounterType(menuItem)}</span>
                    <br/>
                    <span
                        className='medrec-grey-2'>{this.getSelectedOrganisation(menuItem) + this.getLocationType(menuItem)}</span>
                </div>
            </div>
        </td>;
    }

    /**
     *
     * @param {object} MenuItem - the MenuItem object
     * @returns {ReactElement} the created table cell
     */
    getPriceCell = (MenuItem) => {
        return <td key={++this.index} className='patient-name-cell'>
            <div className='row'>
                <div className={"dropdown-link"}>
                    <span
                        className='patient-fullname'>{formatUtils.currencyFormat(MenuItem.price_cents, MenuItem.currency)}</span>
                </div>
            </div>
        </td>;
    }

    deleteOption = (id) => {
        this.props.deleteConsultationMenuOption(id)
    }

    handleEditClick = (MenuItem) => {
        this.props.openEdit(MenuItem)
    }
    closePopup = () => {
        this.setState({
            deleteOpen: false,
        })
    }

    handleDeleteClick = (SelectedMenuItem) => {
        this.setState({
            deleteOpen: true,
            selectedMenuItem: SelectedMenuItem
        })
    }

    /**
     * Creates the details cell of the table
     *
     * @param {object} MenuItem - the MenuItem object
     * @returns {ReactElement} the created table cell
     */
    getDetailsCell = (MenuItem) => {
        return <td key={++this.index} className='text-right'>
            <input
                type="button"
                className="btn btn-secondary date-picker-button"
                value={$$("edit")}
                style={{minWidth:"120px"}}
                onClick={() => this.handleEditClick(MenuItem)}
            />
            <input
                type="button"
                className="btn btn-secondary date-picker-button ml-2"
                style={{minWidth:"120px"}}
                value={$$("delete")}
                onClick={() => this.handleDeleteClick(MenuItem)}
            />

        </td>;
    }

    getLinkCell = (MenuItem) => {
        return <td key={MenuItem.id} className='text-center'>
            <PriceDirectLink price={MenuItem} lang={this.props.lang} />
        </td>;
    }

    /**
     * Creates the rows of the table
     *
     * @param {object} menuItem - the menuItem object
     * @param {number} idx - the index
     * @returns {Array} the table rows created
     */
    getRow = (menuItem, idx) => {
        let cells = [];
        cells.push(this.getNameAndLocationCell(menuItem));
        cells.push(this.getPriceCell(menuItem));
        cells.push(this.getLinkCell(menuItem));
        cells.push(this.getDetailsCell(menuItem));

        return <tr key={idx}>{cells}</tr>;
    }

    /**
     * Creates the full body of the table
     *
     * @returns {Array} the table rows
     */
    getBody = () => {
        let allRows = [];
        let menu = this.props.priceList.result;

        for (let i = 0; i < menu.length; ++i) {
            allRows.push(this.getRow(menu[i], i))
        }

        return [].concat.apply([], allRows);
    }

    getSelectedOrganisation = (menuItem) => {
        let currentOrganisation = "";
        for (let i in this.props.organisationNames) {
            if (menuItem.organization_id === this.props.organisationNames[i].value) {
                currentOrganisation = this.props.organisationNames[i].name;
            }
        }
        if (currentOrganisation !== "") {
            return currentOrganisation + " - "
        } else {
            return currentOrganisation
        }
    }


    render() {
        return (
            <div>
                {this.props.priceList.result.length > 0 &&
                <div className='card-body patient-list-view'>
                    <div className='row' style={{'marginLeft': '0px', 'marginRight': '0px'}}>
                        <div className='col-xs-12 col-md-12'>
                            <div className='row'>
                                <div className='patient-table-container'>
                                    <table id='patients' style={{'width': '100%'}}>
                                        <tbody>
                                        {this.getBody()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }


                <Modal show={this.state.deleteOpen} onHide={this.closeEditPopup}
                       dialogClassName="w-100 day-picker-popup" backdrop="static">
                    <Modal.Body>
                        {this.state.deleteOpen && (
                            <DeleteConfirmationModal
                                closePopup={this.closePopup}
                                delete={this.deleteOption}
                                item={this.state.selectedMenuItem}
                                name={this.getEncounterType(this.state.selectedMenuItem)}
                            />
                        )}
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

PriceSettingsListView.propTypes = {
    deleteConsultationMenuOption: PropTypes.func,
    openEdit:  PropTypes.func,
    organisationNames:  PropTypes.array,
    priceList:  PropTypes.object
}

export default PriceSettingsListView;


export function PriceDirectLink({price, lang}) {

    const [showLinkModal, setShowLinkModal] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const link = useMemo(()=>{
        return `${MEDREC_APP_URL}/clinician?clinician=${price.provider_id}&pid=${price.id}&lang=${lang}`;
    }, [price, lang])


    const copyToClipboard = useCallback(()=>{
        navigator.clipboard.writeText(link).then(()=>{
            setShowLinkModal(false);
            setShowToast(true)
        });

    }, [])

    return <div>
        {!showToast && <button className="btn btn-sm btn-outline-primary" onClick={()=>{
            setShowLinkModal(true)
    }}><i className="fa fa-copy mr-2"/>{$$("permanent_link_button")}</button>}

        {showLinkModal && <Modal show={true} onHide={()=>{setShowLinkModal(false)}}>
            <Modal.Header>
                <h4>{$$("permanent_link_button")}</h4>
            </Modal.Header>
            <Modal.Body>
                <p>{$$("permanent_link_explanation")}</p>
                <div className="well p-2">
                    {link}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={()=>{setShowLinkModal(false)}}>{$$("close_btn_label")}</button>
                <button className="btn btn-primary" onClick={copyToClipboard}>{$$("copy_to_clipboard")}</button>
            </Modal.Footer>
        </Modal>}

        {showToast && <Toast show={showToast} onClose={()=>{setShowToast(false)}} autohide={true} delay={2000}>
            <Toast.Body>
                {$$("link_copied_toast")}
            </Toast.Body>
        </Toast>}
    </div>
}