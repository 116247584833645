/* File types */
export const IMAGE_JPEG = 'image/jpeg';
export const IMAGE_JPG = 'image/jpg';
export const IMAGE_PNG = 'image/png';
export const HTML = 'text/html';
export const TXT = 'text/plain';
export const APPLICATION_MS_WORD = 'application/msword';
export const APPLICATION_MS_EXCEL = 'application/vnd.ms-excel';
export const APPLICATION_SPREADSHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const APPLICATION_MS_POWER_POINT = 'application/vnd.ms-powerpoint';
export const APPLICATION_PDF = 'application/pdf';

/* File size units */
export const BYTES = 'BYTES';
export const KB = 'KB';
export const MB = 'MB';
export const GB = 'GB';
export const TB = 'TB';

/* Maximum file size and unit */
export const MAXIMUM_FILE_SIZE = 10;
export const MAXIMUM_FILE_SIZE_UNIT = 'MB';