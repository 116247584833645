import React, {useCallback, useEffect, useMemo, useState} from "react";
import {$$} from "../../../../helpers/localization";
import {ListGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {ReactComponent as DeleteIcon} from "../../../../../public/media/icons/Delete.svg";
import {dateTimeUtils} from "../../../../utils/dateTimeUtils";

export function NhisEreferralListItemView({eReferral, onDelete, onSubmitToNhis, onCheckResult, nrn, lang}) {

    const deleteTooltip = useCallback(props => (
        <Tooltip {...props} show={"true"}>{$$("delete_label")}</Tooltip>
    ), [lang]);

    return <div className="list-group-item">
        <EreferralBody eReferral={eReferral} lang={lang} nrn={nrn}/>
        <span className="pointer prescription-delete-icon"
              onClick={() => {
                  onDelete(eReferral)
              }}>
                <OverlayTrigger
                    placement={"top"}
                    overlay={deleteTooltip}
                    popperConfig={{
                        modifiers: {
                            preventOverflow: {
                                enabled: false
                            },
                            hide: {
                                enabled: false
                            }
                        }
                    }}
                >
                    <DeleteIcon className="svg-blue" height="17px" width="17px"/>
                </OverlayTrigger>
            </span>
        {!nrn && <button type={"button"} className={"btn btn-primary btn-sm mr-2 mb-2"} onClick={() => {
            onSubmitToNhis()
        }}>{$$("nhis.btn.complete_ereferral")}</button>}
        {nrn && <button type={"button"} className={"btn btn-primary btn-sm mr-2 mb-2"} onClick={() => {
            onCheckResult();
        }}>{$$("nhis.btn.check_ereferral_results")}</button>}
    </div>
}

export function EreferralBody({eReferral, nrn}) {
    const component = useMemo(function getBody() {
        switch (eReferral.contents.category) {
            case "R1":
                return LabView({eReferral})
            case "R2":
                return ConsultationView({eReferral})
            case "R3":
                return SpecializedActivitiesView({eReferral})
            case "R4":
                return HospitalizationView({eReferral})
            case "R5":
                return MedicalExpertiseView({eReferral})
            case "R8":
                return WorkIncapacityView({eReferral})
            default:
                return null;
        }
    }, [eReferral])

    return <table className="table table-sm table-borderless medrec-default-text-color">
        <tbody>
        <TableRow name={$$("nhis.ereferral.nrn_number")} value={nrn || ""}/>
        <TableRow name={$$("nhis.ereferral.category")} value={eReferral.viewModel.category}/>
        <TableRow name={$$("nhis.ereferral.authoredOn")} value={localizedDate(eReferral.contents.authoredOn)}/>
        {component}
        </tbody>
    </table>
}

function LabView({eReferral}) {
    return (
        <TableRow name={$$("nhis.ereferral.activity")} value={eReferral.viewModel.laboratory}/>
    )
}

function ConsultationView({eReferral}) {
    return (
        <TableRow name={$$("nhis.ereferral.qualificationTarget")} value={eReferral.viewModel.consultation}/>
    )
}

function SpecializedActivitiesView({eReferral}) {
    return (<>
        <TableRow name={$$("nhis.ereferral.qualificationTarget")}
                  value={eReferral.viewModel.specializedActivities.qualification}/>
        <TableRow name={$$("nhis.ereferral.specializedActivityCode")}
                  value={eReferral.viewModel.specializedActivities.code}/>
    </>)
}

function HospitalizationView({eReferral}) {
    return <>
        <TableRow name={$$("nhis.ereferral.admissionType")} value={eReferral.viewModel.hospitalization.admissionType}/>
        <TableRow name={$$("nhis.ereferral.directedBy")} value={eReferral.viewModel.hospitalization.directedBy}/>
        <TableRow name={$$("nhis.ereferral.clinicalPathway")}
                  value={eReferral.viewModel.hospitalization.clinicalPathway}/>
        <TableRow name={$$("nhis.ereferral.outpatientProcedure")}
                  value={eReferral.viewModel.hospitalization.outpatientProcedure}/>
    </>
}

function MedicalExpertiseView({eReferral}) {
    return <>
        <TableRow name={$$("nhis.ereferral.qualificationTargets")}
                  value={eReferral.viewModel.medicalExpertise.qualification}/>
        <TableRow name={$$("nhis.ereferral.examType")} value={eReferral.viewModel.medicalExpertise.examType}/>
    </>
}

function WorkIncapacityView({eReferral}) {
    return <>
        <TableRow name={$$("nhis.ereferral.workIncapacityReason")} value={eReferral.viewModel.workIncapacity.reason}/>
    </>
}

function TableRow({name, value}) {
    if (!value || (Array.isArray(value) && value.length === 0)) {
        return null;
    }

    let isArray = Array.isArray(value);
    return <tr>
        <td className="medrec-grey-2 text-right medical-notice-item-label">{name}:</td>
        <td className={"whitespace-pre-line w-100 align-bottom"}>{isArray ? value.join("; ") : value}</td>
    </tr>
}

function localizedDate(date) {
    if (!date) {
        return ""
    }
    return dateTimeUtils.getFormattedDate(new Date(date));
}

export function NhisReferralList({encounter, onDelete, onSubmitToNhis, onCheckResult, lang}) {
    const [eReferrals, setEReferrals] = useState([]);

    useEffect(() => {
        setEReferrals(encounter.ereferrals.map(e => {
            return {nrn: e.nrn, lrn: e.lrn, content: JSON.parse(e.content_json)}
        }))
    }, [encounter.ereferrals])


    return <ListGroup className={"shadow-boxes-list"}>
        {eReferrals.map(e => {
            return <NhisEreferralListItemView key={e.lrn} eReferral={e.content} nrn={e.nrn}
                                              onDelete={() => onDelete(
                                                  { nrn:e.nrn, lrn: e.lrn, senderId: e.content.requester.pmi}
                                              )}
                                              onSubmitToNhis={() => {
                                                  onSubmitToNhis(encounter.ereferrals.find(r => r.lrn === e.lrn))
                                              }}
                                              onCheckResult={() => {
                                                  onCheckResult(encounter.ereferrals.find(r => r.lrn === e.lrn))
                                              }}
                                              lang={lang}/>
        })}
    </ListGroup>
}