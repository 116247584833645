import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    clearSelectedAppointment,
    fetchProviderAppointments,
    selectAppointment
} from '../../actions/users_actions'
import {$$} from '../../helpers/localization'
import moment from 'moment'
import ReactHorizontalDatePicker from '../shared/ReactHorizontalDatePicker'
import AppointmentsList from '../appointments/AppointmentsList'
import NextPatient from '../appointments/NextPatient'
import _ from 'underscore';
import NowNextLater from "../now-next-later/NowNextLater";
import NotificationsPanel from '../notifications/NotificationsPanel'
import {
    clearNotifications,
    getAllNotifications,
    markNotificationAsSeen
} from '../../actions/notifications_actions'
import {Routes} from '../../constants/routes'
import {Link} from 'react-router-dom'
import {fetchLoggedUserDocuments} from "../../actions/documents_actions";
import PropTypes from "prop-types";
import {appointmentUtils} from "../../utils/appointmentUtils";
import ActiveTextConsultations from "./ActiveTextConsultations";


class Dashboard extends Component {
    state = {
        chosenDate: new Date(),
        triggeredFromBtn: false
    }

    constructor(props) {
        super(props);
        this._isMounted = false;
    }

    componentDidMount() {
        this.props.fetchLoggedUserDocuments(null, true);
        this._isMounted = true;
        if (this._isMounted) {
            let parameters = this.prepareAppointmentsParameters();
            this.props.fetchProviderAppointments(parameters.currentDateMs, parameters.lastDateMs);
        }
        this.props.getAllNotifications(3, 0, true, false);
    }

    componentWillUnmount() {
        this.props.clearSelectedAppointment();
    }

    /**
     * Prepares the parameter list of the appointments
     *
     * @returns {object} the parameters
     */
    prepareAppointmentsParameters = () => {
        let parameters = {};
        let date = new Date();
        date.setHours(0, 0, 0, 0);

        let firstDateOfCurrentWeek = _.contains(['en'], this.props.i18n.selected.lang) ? moment(date).startOf('week')._d : moment(date).startOf('isoWeek')._d;
        parameters.firstDateOfCurrentWeekMs = firstDateOfCurrentWeek.valueOf();
        parameters.currentDateMs = moment(date).valueOf();
        parameters.lastDateMs = moment(firstDateOfCurrentWeek).add(91, 'days').valueOf();

        const oneDay = 24 * 60 * 60 * 1000;
        parameters.days = Math.round(Math.abs((parameters.firstDateOfCurrentWeekMs - parameters.lastDateMs) / oneDay));

        return parameters;
    }

    /**
     * An event handler triggered when 'Today' button is pressed
     */
    selectToday = () => {
        this.onSelectDate(new Date(), true);
    }

    /**
     * An event handler triggered when a new date is choosen
     * from the datepicker
     *
     * @param {Date} date - the new choosen date
     * @param triggeredFromBtn
     */
    onSelectDate = (date, triggeredFromBtn) => {
        this.setState({chosenDate: date, triggeredFromBtn});
    };

    /**
     * An event handler triggered when a new appointment is choosen
     * from the list of appointments
     *
     * @param {object} appointment - the appointment object
     */
    onAppointmentChange = (appointment) => {
        this.props.selectAppointment(appointment);
    }

    /**
     * Filters the appointments based on the selected date
     *
     * @returns {Array} the list of filtered appointments
     */
    getFilteredAppointments = () => {
        return this.props.providerAppointments &&
        this.props.providerAppointments.data &&
        this.props.providerAppointments.data.length > 0
            ? this.props.providerAppointments.data.filter(a =>
                    moment(new Date(a.starts_at)).format('YYYY-MM-DD') === moment(this.state.chosenDate).format('YYYY-MM-DD') && !appointmentUtils.isGroupEventChild(a)
            )
                .sort(function (a, b) {
                    return a.starts_at - b.starts_at;
                })
            : [];
    }

    goToNext = () => {
        if (!this.props.providerAppointments.data || !this.props.providerAppointments.data.length > 0) {
            return;
        }
        let hasFutureAppointmentDate = this.props.providerAppointments.data.slice().reverse().find(a => {
            return new Date(moment(a.starts_at).format("YYYY-MM-DD")) > new Date(moment(this.state.chosenDate).format('YYYY-MM-DD')) && ! appointmentUtils.isAppointmentCanceled(a.status)
        })
        if (hasFutureAppointmentDate) {
            this.setState({chosenDate: new Date(moment(hasFutureAppointmentDate.starts_at).format("YYYY-MM-DD")), triggeredFromBtn: true});
        }
    }

    render() {

        let filteredAppointmentsByDate = this.getFilteredAppointments();

        let hasFutureAppointmentDate = false;
        if (this.props.providerAppointments && this.props.providerAppointments.data.length > 0)
            hasFutureAppointmentDate = this.props.providerAppointments?.data?.slice().reverse().find(a => {
                return new Date(moment(a.starts_at).format("YYYY-MM-DD")) > new Date(moment(this.state.chosenDate).format('YYYY-MM-DD')) &&  ! appointmentUtils.isAppointmentCanceled(a.status)
            })
        return (
            <>
                {this._isMounted &&
                <div>
                    <div className='history d-flex flex-container flex-row'>
                        <div className='landing-page-left-col'>
                            <div className='next-patient-header'>
                                {this.props.selectedAppointment.data.id && appointmentUtils.isGroupEventParent(this.props.selectedAppointment.data) ? $$('appointment_label') : $$('next_patient_label')}
                            </div>
                            <div className='next-patient'>
                                <div className='next-patient-body'>
                                    <NextPatient appointments={filteredAppointmentsByDate}
                                                 appointment={this.props.selectedAppointment.data}
                                                 chosenDate={this.state.chosenDate}
                                                 clearSelectedAppointment={this.props.clearSelectedAppointment}
                                                 goToNext={this.goToNext}
                                                 existsNextDatesAppointments={hasFutureAppointmentDate}
                                    />
                                </div>
                            </div>
                            <div className="pt-3">
                                <ActiveTextConsultations userId={this.props.userId}/>
                            </div>
                            <div className="pt-3">
                                <NowNextLater
                                    i18n={this.props.i18n.selected.lang}
                                    providerAppointments={this.props.providerAppointments}/>
                            </div>
                            <div className="pt-3">
                                <div className="d-flex justify-content-between align-items-center" >
                                    <div className="next-patient-header">
                                        {$$('notifications_label')}
                                    </div>
                                    <div>
                                        <Link className="see-all-notifications"
                                              to={Routes.NOTIFICATIONS}>{$$('see_all_label')}</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='notifications-list'>
                                <div className='notifications-list-body p-2'>
                                    <NotificationsPanel notifications={this.props.notifications}
                                                        getAllNotifications={this.props.getAllNotifications}
                                                        markNotificationAsSeen={this.props.markNotificationAsSeen}
                                                        clearNotifications={this.props.clearNotifications}
                                                        limit={3}
                                                        notSeenOnly={true}/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="next-patient-header">{$$('appointments_label')}</div>
                            <div className='appointments'>
                                <div className='row appointments-header text-right'/>
                                <div className='dashboard-card-body'>
                                    <ReactHorizontalDatePicker selectedDay={this.onSelectDate}
                                                               enableScroll={true}
                                                               enableDays={97}
                                                               i18n={this.props.i18n.selected.lang}
                                                               chosenDate={this.state.chosenDate}
                                                               triggeredFromBtn={this.state.triggeredFromBtn}
                                                               appointments={this.props.providerAppointments.data}
                                                               selectToday={this.selectToday}/>
                                    <AppointmentsList i18n={this.props.i18n.selected.lang}
                                                      chosenDate={moment(this.state.chosenDate).format('YYYY-MM-DD')}
                                                      appointments={filteredAppointmentsByDate}
                                                      onAppointmentChange={this.onAppointmentChange}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </>
        )
    }
}

Dashboard.propTypes = {
    clearSelectedAppointment: PropTypes.func,
    userId: PropTypes.any,
    fetchProviderAppointments: PropTypes.func,
    markNotificationAsSeen: PropTypes.func,
    fetchLoggedUserDocuments: PropTypes.func,
    getAllNotifications: PropTypes.func,
    clearNotifications: PropTypes.func,
    selectAppointment: PropTypes.func,
    history: PropTypes.object,
    i18n: PropTypes.object,
    providerAppointments: PropTypes.object,
    notifications: PropTypes.any,
    selectedAppointment: PropTypes.object,
}

function mapStateToProps(state) {
    return {
        i18n: state.language,
        providerAppointments: state.providerAppointments,
        selectedAppointment: state.selectedAppointment,
        notifications: state.notifications.data,
        userId: state.userInfo.id
    }
}

export default connect(mapStateToProps, {
    fetchProviderAppointments,
    selectAppointment,
    clearSelectedAppointment,
    getAllNotifications,
    fetchLoggedUserDocuments,
    markNotificationAsSeen,
    clearNotifications
})(Dashboard);
