import React, {Component} from 'react';
import {$$} from '../../helpers/localization'
import MedicationEntry from './vitals-logbook-entries/MedicationEntry'
import PropTypes from "prop-types";

export class Medication extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
    }

    /**
     * Get medications entries
     *
     * @returns {Array} of medications entries
     */
    getMedicationsEntries = () => {
        return this.props.medications &&
        this.props.medications.length > 0
            ? this.props.medications.map((m, idx) => {
                return <MedicationEntry key={idx}
                                        entry={m}/>
            })
            : [];
    }

    render() {
        return (
            <div>
                <div key={++this.index} className='row title-row'>
                    <div className='symptom-icon align-items-center'>
                        <i className='kt-menu__link-icon  vitals-logbook-icon-label fas fa-pills'/>
                    </div>
                    &nbsp;
                    <div className='medrec-blue-1 vitals-logbook-icon-label dropdown-link'>
                        {$$('Medications')}
                    </div>
                </div>
                <br/>
                <div className='vitals-logbook-entry-padding vitals-logbook-entry-margin'>
                    {this.getMedicationsEntries()}
                </div>
            </div>
        )
    }
}

Medication.propTypes = {
    medications: PropTypes.array,
}

export default Medication