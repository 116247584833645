import React, {useEffect} from "react";
import {connect} from "react-redux";
import objectPath from "object-path";
import Header from "./header/Header";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
import ScrollTop from "../../components/theme/ScrollTop";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig, {initLayoutConfig} from "./LayoutConfig";
import layoutConfig from "./LayoutConfig";
import LayoutInitializer from "./LayoutInitializer";
import KtContent from "./KtContent";
import "./assets/Base.scss";
import MenuConfig from "../layout/MenuConfig";
import ManagementMenuConfig from "./ManagementMenuConfig";
import {isLoggedIn} from "../../helpers/auth_helper";
import store from "../../store";
import {UPDATE_MENU} from "../../actions/actions";


const htmlClassService = new HTMLClassService();

function Layout({
  children,
  asideDisplay,
  subheaderDisplay,
  menuItems,
  selfLayout,
  layoutConfig,
  contentContainerClasses,
}) {

  useEffect(()=> {
    return ()=>{
      if (!isLoggedIn()) {
        document.body.classList.remove("kt-aside-management")
        document.body.classList.add("kt-aside-dark");
        store.dispatch({type: UPDATE_MENU, payload:1})
      }
    }
  }, [])

  htmlClassService.setConfig(layoutConfig);
  // scroll to top after location changes
  // window.scrollTo(0, 0);

  const contentCssClasses = htmlClassService.classes.content.join(" ");

  return selfLayout !== "blank" ? (
    <LayoutInitializer
      menuConfig={menuItems}
      layoutConfig={layoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile />
      {/* <!-- end:: Header Mobile --> */}

      <div className="kt-grid kt-grid--hor kt-grid--root">
        {/* <!-- begin::Body --> */}
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            {/* <!-- begin:: Header READY --> */}

            <Header />
            {/* <!-- end:: Header --> */}
            {/* <!-- begin:: Aside Left --> */}
            {asideDisplay && (
                <>
                  <AsideLeft />
                </>
            )}
            {/* <!-- end:: Aside Left --> */}
            {/* <!-- begin:: Content --> */}
            <div
              id="kt_content"
              className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
            >
              {/* <!-- begin:: Content Body --> */}
              {/* TODO: add class to animate  kt-grid--animateContent-finished */}
              <KtContent>
                    {children}
              </KtContent>
              {/*<!-- end:: Content Body -->*/}
            </div>
            {/* <!-- end:: Content --> */}
            <Footer />
          </div>
        </div>
        {/* <!-- end:: Body --> */}
      </div>
      <ScrollTop />
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root">
      <KtContent>
        {children}
      </KtContent>
    </div>
  );
}
const emptyMenu = {
  header: {
    self: {},
    items: []
  },
  aside: {
    self: {},
    items: []
  }
};

const ManagementMenuWithoutSwitchToClinic = (() => {
  let menu = JSON.parse(JSON.stringify(ManagementMenuConfig));
  menu.aside.items = menu.aside.items.filter(m => m.title !== 'Back to clinic');
  return menu;
})();

function mapStateToProps(state) {
  let currentMenu = MenuConfig
  let lc = layoutConfig;
  // add custom logic to change the menu here
  if(state.menu.selected === 2) {
     currentMenu = ManagementMenuConfig; // new menu
     if (state.userInfo.data && state.userInfo.data.id) {
       if (state.userInfo.data.internal) {
         currentMenu = ManagementMenuWithoutSwitchToClinic;
       }
       if (state.userInfo.data.organizations?.length < 2) {
         currentMenu.aside.items = currentMenu.aside.items.filter(m => m.title !== 'Switch Practice');
       }
     }
     lc.aside.self.skin = 'management'
  } else {
     lc.aside.self.skin = 'dark'
  }

  if (!state.userInfo.data.id) {
    currentMenu = emptyMenu
  }

  return {
    layoutConfig: lc,
    menuItems: currentMenu,
    selfLayout: objectPath.get(layoutConfig, "self.layout"),
    asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
    subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
    desktopHeaderDisplay: objectPath.get(
        layoutConfig,
        "header.self.fixed.desktop"
    ),
  }
}

export default connect(mapStateToProps)(Layout);
