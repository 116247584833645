import {
    APPLICATION_MS_EXCEL,
    APPLICATION_MS_POWER_POINT,
    APPLICATION_MS_WORD,
    APPLICATION_PDF,
    APPLICATION_SPREADSHEET,
    BYTES,
    DOCX,
    GB,
    HTML,
    IMAGE_JPEG,
    IMAGE_JPG,
    IMAGE_PNG,
    KB,
    MB,
    TB,
    TXT
} from '../constants/files'

export const downloadUtils = {
    fileTypes,
    fileSizeUnits,
    download
}

/**
 * Returns an array of file types
 *
 * @returns {Array} - array of file types
 */
function fileTypes() {
    return [
        IMAGE_JPEG,
        IMAGE_JPG,
        IMAGE_PNG,
        APPLICATION_MS_WORD,
        APPLICATION_MS_EXCEL,
        APPLICATION_SPREADSHEET,
        APPLICATION_MS_POWER_POINT,
        APPLICATION_PDF,
        TXT,
        HTML,
        DOCX
    ];
}

/**
 * Returns an array of file size units
 *
 * @returns {Array} - array of file size units
 */
function fileSizeUnits() {
    return [
        BYTES,
        KB,
        MB,
        GB,
        TB
    ];
}

/**
 * Download file.
 *
 * @param {object} objectUrl the url of the object to attach to the link
 * @param {string} fileName name of the file to download
 * @returns {object} promise object
 */
function download(objectUrl, fileName) {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    const url = objectUrl;
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}