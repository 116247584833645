import {fetchHelper} from "../helpers/request_helper";
import {
    DOWNLOAD_REPORT_URL,
    GET_MEDICATION_SUMMARY_STATS_URL,
    GET_PEAK_FLOW_DIARY_STATS_URL, GET_THERAPY_ADHERENCE_SUMMARY_STATS_URL,
    PREPARE_PEAK_FLOW_DIARY_REPORT_URL,
    GET_PEAK_FLOW_NORM_STATS_URL, GET_LAST_COMPLETED_APPOINTMENT_URL,
    GET_MENSTRUATION_DIARY_SUMMARY_URL
} from "../constants/api_paths";

export const summaryService = {
    getPeakFlowStats,
    getMedicationSummaryStats,
    preparePeakFlowDiaryReport,
    downloadPeakFlowDiaryReport,
    downloadMedicationsReport,
    getTherapyAdherenceStats,
    downloadCardiacReport,
    getLastCompletedAppointment,
    getMenstruationStats,
};

/**
 * Get Peak Flow Stats
 *
 * @param {string} userId id of the user to fetch symptoms for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function getPeakFlowStats(userId, params) {
    return fetchHelper.callGet(GET_PEAK_FLOW_DIARY_STATS_URL.replace('{userId}', userId), params);
}


/**
 * Get Last Completed Appointment
 *
 * @param {string} patientId id of the user to fetch symptoms for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function getLastCompletedAppointment(patientId, params){
    return fetchHelper.callGet(GET_LAST_COMPLETED_APPOINTMENT_URL.replace('{patientId}', patientId), params);
}

/**
 * Get Medication Summary Stats
 *
 * @param {string} userId id of the user to fetch symptoms for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function getMedicationSummaryStats(userId, params) {
    return fetchHelper.callGet(GET_MEDICATION_SUMMARY_STATS_URL.replace('{userId}', userId), params);
}

/**
 * Get Therapy Adherence Stats
 *
 * @param {string} userId id of the user to fetch symptoms for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function getTherapyAdherenceStats(userId, params) {
    return fetchHelper.callGet(GET_THERAPY_ADHERENCE_SUMMARY_STATS_URL.replace('{userId}', userId), params);
}

/**
 * Prepare Peak Flow Diary Report
 *
 * @param {string} userId id of the user to fetch symptoms for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function preparePeakFlowDiaryReport(userId, params) {
    return fetchHelper.callGet(PREPARE_PEAK_FLOW_DIARY_REPORT_URL.replace('{userId}', userId), params);
}

/**
 * Download Peak Flow Diary Report
 *
 * @param {string} lang report language
 * @param {object} reportData data for the report to be downloaded
 * @returns {object} promise object
 */
export function downloadPeakFlowDiaryReport(lang, reportData) {
    return fetchHelper.getBlobWithPost(DOWNLOAD_REPORT_URL.replace("{reportType}", "PeakFlowDiary").replace("{lang}", lang), reportData)
}

/**
 * Download Cardiac Report
 *
 * @param {string} lang report language
 * @param {object} reportData data for the report to be downloaded
 * @returns {object} promise object
 */
export function downloadCardiacReport(lang, reportData) {
    return fetchHelper.getBlobWithPost(DOWNLOAD_REPORT_URL.replace("{reportType}", "CardiacReport").replace("{lang}", lang), reportData)
}

/**
 * Download Medications Report
 *
 * @param {string} lang report language
 * @param {object} reportData data for the report to be downloaded
 * @returns {object} promise object
 */
export function downloadMedicationsReport(lang, reportData) {
    return fetchHelper.getBlobWithPost(DOWNLOAD_REPORT_URL.replace("{reportType}", "MedicationsIntake").replace("{lang}", lang), reportData)
}

/**
 * Download Therapy Adherence Report
 *
 * @param {string} lang report language
 * @param {object} reportData data for the report to be downloaded
 * @returns {object} promise object
 */
export function downloadTherapyAdherenceReport(lang, reportData) {
    return fetchHelper.getBlobWithPost(DOWNLOAD_REPORT_URL.replace("{reportType}", "MedicationsAdherence").replace("{lang}", lang), reportData)
}

/**
 * Get Peak Norm Stats
 *
 * @param {string} userId id of the user to fetch symptoms for
 * @param {object} params params sent together with the request
 * @returns {object} promise object
 */
export function getPeakFlowNormStats(userId, params) {
    return fetchHelper.callGet(GET_PEAK_FLOW_NORM_STATS_URL.replace('{userId}', userId), params);
}

/**
 * Get Menstruation Statistics
 * @param userId {string}
 * @param params {object}
 * @returns {object} promise object
 */
export function getMenstruationStats(userId, params) {
    return fetchHelper.callGet(GET_MENSTRUATION_DIARY_SUMMARY_URL.replace('{userId}', userId), params);
}

/**
 * Download Menstruation Report
 *
 * @param lang
 * @param reportData
 * @return {object} promise
 */
export function downloadMenstruationReport(lang, reportData) {
    return fetchHelper.getBlobWithPost(DOWNLOAD_REPORT_URL.replace("{reportType}", "MenstruationReport").replace("{lang}", lang), reportData)
}