import {fetchHelper} from '../helpers/request_helper'
import {ICD_SEARCH} from '../constants/api_paths'

export const icdService = {
    searchIcd,
};


export function searchIcd(country, q) {
    return fetchHelper.callGet(ICD_SEARCH.replace('{country}', country).replace('{query}', q));
}
