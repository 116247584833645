import React from "react";
import {connect} from "react-redux";
import objectPath from "object-path";
import {version, internal_version} from "../../../../package"
import * as builder from "../../ducks/builder";
import {$$} from "../../../helpers/localization";

class Footer extends React.Component {
    render() {
        const today = new Date().getFullYear();
        return (
            <div
                className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
                id="kt_footer"
            >
                <div className={`kt-container ${this.props.footerContainerClasses}`}>
                    <div className="kt-footer__copyright">
                        {today.toString()}&nbsp;&copy;&nbsp;
                        Sirma Medical Systems
                        <div>&nbsp;| {$$('version')} {version} ({internal_version})</div>
                    </div>
                    <div className="kt-footer__menu">
                        <a
                            href="https://www.medrec-m.com/#!/remote"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="kt-footer__menu-link kt-link"
                        >
                            {$$('about')}
                        </a>
                        <a
                            href="https://medrec-m.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="kt-footer__menu-link kt-link"
                        >
                            {$$('contact')}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    i18n: store.language,
    fluid:
        objectPath.get(store.builder.layoutConfig, "footer.self.width") === "fluid",
    footerClasses: builder.selectors.getClasses(store, {
        path: "footer",
        toString: true
    }),
    footerContainerClasses: builder.selectors.getClasses(store, {
        path: "footer_container",
        toString: true
    })
});

export default connect(mapStateToProps)(Footer);
