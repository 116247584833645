import {UPDATE_CALL_DATA} from './actions';

/**
 * Get the user settings action, fetch the settings and dispatch action
 *
 * @returns {function} dispatch function
 */
export function updateVideoData(data) {
    return (dispatch) => {
        dispatch({type: UPDATE_CALL_DATA, result: data});
    }
}