import React from 'react'
import {$$} from '../../../helpers/localization'
import Dropzone from '../../shared/Dropzone'
import PropTypes from "prop-types";


export class UploadDocuments extends React.Component {
    state = {
        uploadDocumentsFields: {
            documents: this.props.registrationFields.documents || []
        },

        formclass: '',
        errors: {}
    }

    constructor(props) {
        super(props)
    }

    onDocumentsChange = (documents) => {
        const fields = Object.assign({}, this.state);

        fields['uploadDocumentsFields']['documents'] = [...documents];

        if (this.props.profilePage) {
            this.props.changeDocuments([...documents]);
        }

        this.setState(fields, function () {
            if (this.state.uploadDocumentsFields.documents.length > 0) {
                this.setState({errors: {}});
            }
        });
    }

    /**
     * Form submit handler, validate data and set error in state if any. Call register action.
     *
     * @param {object} evt - The event handler argument
     */
    onContinueClick = (evt) => {
        const formErrors = this.validate();
        this.setState({errors: formErrors});

        evt.preventDefault();

        if (this.state.formclass !== 'was-validated') {
            this.setState({formclass: 'was-validated'});
        }

        if (Object.keys(formErrors).length) {
            return;
        }

        if (evt.target.checkValidity() === true) {
            let wizardStep = this.props.wizardStep;
            this.props.onWizardStepChange(this.state.uploadDocumentsFields, wizardStep, true, true);
        }
    }

    /**
     * Handler to be executed when the Back button is clicked
     *
     */
    onBackClick = () => {
        let wizardStep = this.props.wizardStep;
        if (this.state.uploadDocumentsFields.documents.length > 0) {
            this.props.onWizardStepChange(this.state.uploadDocumentsFields, --wizardStep, true);
        } else {
            this.props.onWizardStepChange(this.state.uploadDocumentsFields, --wizardStep, false);
        }
    }

    /**
     * Validate documents.
     *
     * @returns {object} errors - Form errors after validate
     */
    validate = () => {
        const errors = {};
        if (this.state.uploadDocumentsFields.documents && this.state.uploadDocumentsFields.documents.length === 0) {
            errors.documents = 'wizard_upload_documents_required_label';
        }
        return errors;
    }

    render() {
        return (
            <div>
                <form className={this.state.formclass} noValidate={true}>
                    <div className="dropzone-content">
                        <Dropzone documents={this.state.uploadDocumentsFields.documents}
                                  onDocumentsChange={this.onDocumentsChange}
                                  errors={this.state.errors.documents}/>
                    </div>

                    {!this.props.profilePage &&
                    <div className='form-group docs-list'>
                        <div className='row flex-end-justify docs-list-row'>
                            <button type='button' onClick={this.onBackClick} className='btn btn-secondary docs-btn'>
                                {$$('wizard_back_label')}
                            </button>
                            <button type='button' onClick={this.onContinueClick}
                                    className='btn btn-success'>{$$('wizard_continue_label')}</button>
                        </div>
                    </div>}
                </form>
            </div>
        )
    }
}

UploadDocuments.propTypes = {
    i18n: PropTypes.object,
    isStepCompleted: PropTypes.bool,
    profilePage: PropTypes.bool,
    onWizardStepChange: PropTypes.func,
    changeDocuments: PropTypes.func,
    registrationFields: PropTypes.object,
    wizardStep: PropTypes.number
}

export default UploadDocuments
