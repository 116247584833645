import React, {Component} from 'react'
import DateRangePicker from '../shared/DateRangePicker'
import Select from '../shared/Select'
import {$$} from '../../helpers/localization'
import moment from 'moment'
import PropTypes from "prop-types";



export class LabResultsFilters extends Component {

    state = {
        showFilters: false,
        laboratory: this.props.filters.laboratory,
        beforeDateTime: this.props.filters.before_date_time,
        afterDateTime: this.props.filters.after_date_time
    }

    constructor(props) {
        super(props);
    }

    /**
     * Shows or hides the filters based on the showBtn value
     */
    showFilters = () => {
        let showFilters = !this.state.showFilters;
        this.setState({showFilters});
    }

    /**
     * Set the state to the latest selected option.
     *
     * @param {object} evt - The event handler argument
     */
    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields, function () {
            this.props.changeLabResultsFilters(this.state);
        });
    };

    /**
     * Change the lab results filters state.
     *
     * @param {object} range - the custom range selected
     */
    onRangeSelected = (range) => {
        this.setState({
            beforeDateTime: range.endDate ? moment(range.endDate).valueOf() : null,
            afterDateTime: range.startDate ? moment(range.startDate).valueOf() : null
        }, function () {
            this.props.changeLabResultsFilters(this.state);
        });
    }

    render() {
        return (
            <div>
                <div className='lab-results-filters-container'>
                    <div className='lab-results-filters-select-container'>
                        <Select
                            name="laboratory"
                            options={this.props.laboratoryOptions}
                            value={this.state.laboratory}
                            onChange={this.onSelectChange}
                            placeHolder={$$('laboratory_label')}/>
                    </div>
                    <div className='lab-results-filters-date-container'>
                        <DateRangePicker
                            onRangeSelected={this.onRangeSelected}
                            settings ={this.props.settings}
                            startDate={this.props.filters.after_date_time}
                            endDate={this.props.filters.before_date_time}/>
                    </div>
                </div>
            </div>
        )
    }
}

LabResultsFilters.propTypes = {
    changeLabResultsFilters: PropTypes.func,
    filters: PropTypes.object,
    i18n: PropTypes.object,
    labResults: PropTypes.object,
    settings: PropTypes.object,
    laboratoryOptions: PropTypes.array
}

export default LabResultsFilters
