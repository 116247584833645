import {useEffect, useRef, useState} from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useRoomName() {
    const { room } = useVideoContext();
    const [state, setState] = useState<string | undefined>(room?.name);
    const ref = useRef(false)
    useEffect(() => {
        ref.current = true;
        if (room) {
            const setRoomName = () => {
                if (ref.current) {
                    setState(room.name);
                }
            }
            setRoomName();
        }
    }, [room]);

    return state;
}
