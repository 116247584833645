import React, {useCallback, useMemo, useRef, useState} from "react";
import Select, {components} from "react-select";
import {createFilter} from "react-select";
import {FixedSizeList as List} from "react-window";
import AsyncSelect from 'react-select/async';
import {$$} from "../../../../helpers/localization";
import _ from 'lodash';


const styles = {
    option: (baseStyles, state) => ({
        ...baseStyles,
        height: "100%",        
        overflow: 'hidden'
    }),
    control: (baseStyles) => ({
        ...baseStyles,
        border: "none",
        borderColor: "transparent",
        boxShadow: "none",
        minHeight: "auto"
    }),
    indicatorSeparator: (baseStyles) => ({
        ...baseStyles,
        borderTop: "1px solid #DCEBFF",
        borderBottom: "1px solid #DCEBFF",
        backgroundColor: "#DCEBFF",
        margin: 0,
        alignSelf: "center"
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: "#30357C"
    }),
    multiValueLabel: (base) => ({
        ...base,
        whiteSpace: `wrap`,
    }),
    input: (baseStyles, state) => ({
        ...baseStyles,
        color: "#30357C"
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        zIndex: 1000
    }),
    placeholder: (baseStyles) => ({
        ...baseStyles,
        color: "#30357C"
    })
}

const filterOption = createFilter({ignoreAccents: false, trim: false, stringify: option => `${option.label}`});

const getDefaultOptionLabel = (o) => {
    return o.label
};

export function SearchableSelectAsync({value, options, loadOptions, onSelect, getOptionLabel, required, optionComponent, placeholder, isMulti, isClearable,
                                        max, optionHeight=75,
                                        defaultMenuIsOpen = false}) {
    let selectComponents = {MenuList, NoOptionsMessage, LoadingMessage, IndicatorSeparator:null};

    if (optionComponent) {
        selectComponents.Option = optionComponent
    }

    const loadSuggestions = useMemo(() => _.debounce(loadOptions, 200), [loadOptions])

    const classNames = useMemo(()=> ({
        control: () => "input-group searchable-select",
        valueContainer: (state) => {
            let hasValue = isMulti ? value && value.length > 0 : value?.value;
            let base = 'form-control form-control-sm react-select-valueContainer';
            if (required) {
                base += (hasValue ? "  is-valid " : " is-invalid ")
            } else {
                base += " is-valid "
            }
            if (isMulti) {
                base += " is-multi "
            } else {
                base += " single-value "
            }
            return base;
        },
        placeholder: () => 'react-select-placeholder',
        indicatorsContainer: () => `input-group-append`,
        clearIndicator: ()=> `input-group-text ${!required ? "input-group-text is-valid" : (value?.value || isMulti && value?.length > 0? "is-valid" : "is-invalid")}`,
        dropdownIndicator: () => `input-group-text ${required ? (value?.value || isMulti && value?.length > 0 ? "is-valid" : "is-invalid") : "is-valid"}`,
        indicatorSeparator: () => `indicatorSeparator input-group-text ${required ? (value?.value || isMulti && value?.length > 0 ? "is-valid" : "is-invalid") : "is-valid"}`,
        loadingIndicator: ()=> `input-group-text mr-0 ${required ? (value?.value || isMulti && value?.length > 0 ? "is-valid" : "is-invalid") : "is-valid"}`
    }), [value, required])

    return <AsyncSelect components={selectComponents}
                        loadOptions={loadSuggestions}
                        value={value}
                        onChange={onSelect}
                        getOptionLabel={getOptionLabel ? getOptionLabel : getDefaultOptionLabel}
                        placeholder={placeholder}
                        /*cacheOptions*/
                        defaultOptions={options}
                        /*filterOption={filterOption}*/
                        classNames={classNames}
                        styles={styles}
                        maxMenuHeight={450}
                        required={required}
                        isMulti={isMulti}                        
                        isClearable={isClearable}
                        isOptionDisabled={() => isMulti && max && value && value.length >= max}
                        //menuIsOpen={true}
                        optionHeight={optionHeight}
                        defaultMenuIsOpen={defaultMenuIsOpen}/>
}

export default function SearchableSelect({value, options, onSelect, getOptionLabel, required, optionComponent, placeholder, isMulti, defaultMenuIsOpen = false, optionHeight = 75}) {
    let selectComponents = {MenuList, NoOptionsMessage};

    if (optionComponent) {
        selectComponents.Option = optionComponent
    }

    const classNames = useMemo(()=> ({
        control: () => "input-group searchable-select",
        valueContainer: (state) =>
            required ? (value?.value ? "form-control form-control-sm  is-valid" : "form-control form-control-sm is-invalid") : "form-control form-control-sm ",
        indicatorsContainer: () => "input-group-append",
        dropdownIndicator: () => `input-group-text ${required ? (value?.value ? "is-valid" : "is-invalid") : ""}`,
        indicatorSeparator: () => `indicatorSeparator ${required ? (value?.value ? "is-valid" : "is-invalid") : ""}`
    }), [value, required])

    return <Select components={selectComponents}
                   options={options}
                   value={value}
                   onChange={onSelect}
                   getOptionLabel={getOptionLabel ? getOptionLabel : getDefaultOptionLabel}
                   placeholder={placeholder}
                   cacheOptions
                   filterOption={filterOption}
                   classNames={classNames}
                   styles={styles}
                   maxMenuHeight={450}
                   required={true}
                   isMulti={isMulti}
                   optionHeight={optionHeight}
                   defaultMenuIsOpen={defaultMenuIsOpen}/>
}

function MenuList(props) {
    const {options, children, maxHeight, getValue, selectProps} = props;
    const prevMaxHeight = useRef(maxHeight)
    const key = useRef(Math.random());
    const childrenArray = React.Children.toArray(children);
    const [value] = getValue();

    const  height = selectProps.optionHeight;

    //MenuList can be rendered several times since the parent recalculates the maxHeight.
    if (maxHeight !== prevMaxHeight.current) {
        //need to remount since otherwise initialScrollOffset is wrong!
        key.current = Math.random(); //force remount
        prevMaxHeight.current = maxHeight; //store newMaxHeight
    }
    let indexOf = options.indexOf(value);
    const initialOffset = indexOf < 0 ? 0 : indexOf * height;
    return (
        <List key={key.current}
              height={Math.min(maxHeight, childrenArray.length * height)}
              itemCount={childrenArray.length}
              itemSize={height}
              initialScrollOffset={initialOffset-1}
              itemData={childrenArray}
        >
            {MenuListItem}
        </List>
    );

}

function MenuListItem({index, style, data}) {
    const item = data[index];
    return <div style={style}>{item}</div>
}

const NoOptionsMessage = props => {
    return (
        <components.NoOptionsMessage {...props}>
            <span className="">{$$("no_option_found")}</span>
        </components.NoOptionsMessage>
    );
};

const LoadingMessage = props => {
    return (
        <components.LoadingMessage {...props}>
            <span className="">{$$("loading_data_label")}</span>
        </components.LoadingMessage>
    );
};