import {
    CREATE_NOTE_REQUEST_ERROR,
    CREATE_NOTE_REQUEST_SENT,
    DELETE_NOTE_REQUEST_ERROR,
    DELETE_NOTE_REQUEST_SENT,
    DUMMY,
    GET_STAFF_LIST_ERROR,
    GET_STAFF_LIST_SUCCESS,
    MANAGEMENT_CLEAR_DATE_RANGE,
    MANAGEMENT_CREATE_NEW_CONSULTATION_MENU_ITEM_ERROR,
    MANAGEMENT_CREATE_NEW_CONSULTATION_MENU_ITEM_SUCCESS,
    MANAGEMENT_CREATE_NEW_DATE_RANGE,
    MANAGEMENT_CREATE_NEW_DATE_RANGE_ERROR,
    MANAGEMENT_DELETE_CONSULTATION_MENU_ITEM_ERROR,
    MANAGEMENT_DELETE_CONSULTATION_MENU_ITEM_SUCCESS,
    MANAGEMENT_DELETE_DATE_RANGE,
    MANAGEMENT_DELETE_DATE_RANGE_ERROR,
    MANAGEMENT_FETCH_APPOINTMENTS_REQUEST_SENT,
    MANAGEMENT_GET_DATE_RANGE_ERROR,
    MANAGEMENT_GET_DATE_RANGE_SUCCESS,
    MANAGEMENT_UPDATE_CONSULTATION_MENU_ITEM_ERROR,
    MANAGEMENT_UPDATE_DATE_RANGE,
    MANAGEMENT_UPDATE_DATE_RANGE_ERROR,
    MANAGEMENT_UPDATE_NEW_CONSULTATION_MENU_ITEM_SUCCESS,
    NOTES,
    SELECT_ORGANISATION_TO_MANAGE,
    SET_FORM_TO_DISABLED,
    SET_FORM_TO_ENABLED,
    UPDATE_NOTE_REQUEST_ERROR,
    UPDATE_NOTE_REQUEST_SENT
} from "./actions";
import history from "../helpers/history";
import {Routes} from "../constants/routes";
import {managementService} from "../service/management_service";
import {usersService} from "../service/users_service";

export function SelectOrganisationToManage(organisation) {
    return (dispatch) => {
        dispatch({type: SELECT_ORGANISATION_TO_MANAGE, payload: organisation});
        history.push(Routes.MANAGEMENT_DASHBOARD);
    }
}

export function GetStaffList(OrganisationId) {
    return (dispatch) => {
        return managementService.fetchStaffList(OrganisationId).then((res) => {
            if (res) {
                dispatch({type: GET_STAFF_LIST_SUCCESS, payload: res})
            }
        }).catch((err) => {
            dispatch({type: GET_STAFF_LIST_ERROR, payload: err});
        })
    }
}

export function managementDeleteConsultationMenuOption(id) {
    return (dispatch) => {
        return managementService.managementDeleteConsultationMenuOption(id)
            .then((res) => {
                if (res) {
                    dispatch({type: MANAGEMENT_DELETE_CONSULTATION_MENU_ITEM_SUCCESS, result: res});
                }
            })
            .catch((err) => {
                dispatch({type: MANAGEMENT_DELETE_CONSULTATION_MENU_ITEM_ERROR, result: err});
            })
    }
}

export function managementUpdateConsultationMenuOption(consultationMenuOption) {
    return (dispatch) => {
        return managementService.managementUpdateConsultationMenuOption(consultationMenuOption)
            .then((res) => {
                if (res) {
                    dispatch({type: MANAGEMENT_UPDATE_NEW_CONSULTATION_MENU_ITEM_SUCCESS, result: res});
                }
            })
            .catch((err) => {
                dispatch({type: MANAGEMENT_UPDATE_CONSULTATION_MENU_ITEM_ERROR, result: err});
            })
    }
}

export function managementCreateConsultationMenuOption(consultationMenuOption) {
    return (dispatch) => {
        return managementService.managementCreateConsultationMenuOption(consultationMenuOption)
            .then((res) => {
                if (res) {
                    dispatch({type: MANAGEMENT_CREATE_NEW_CONSULTATION_MENU_ITEM_SUCCESS, result: res});
                }
            })
            .catch((err) => {
                dispatch({type: MANAGEMENT_CREATE_NEW_CONSULTATION_MENU_ITEM_ERROR, result: err});
            })
    }
}

export function managementPostNewTimetable(timeTable) {
    return (dispatch) => {
        return managementService.managementCreateNewDateRange(timeTable)
            .then((res) => {
                if (res) {
                    dispatch({type: MANAGEMENT_CREATE_NEW_DATE_RANGE, result: res});
                }
            })
            .catch((err) => {
                dispatch({type: MANAGEMENT_CREATE_NEW_DATE_RANGE_ERROR, result: err});
            })
    }
}

export function managementUpdateTimetable(timeTable) {
    return (dispatch) => {
        return managementService.managementUpdateDateRange(timeTable)
            .then((res) => {
                if (res) {
                    dispatch({type: MANAGEMENT_UPDATE_DATE_RANGE, result: res});
                }
            })
            .catch((err) => {
                dispatch({type: MANAGEMENT_UPDATE_DATE_RANGE_ERROR, result: err});
            })
    }
}

export function managementDeleteTimetable(id) {
    return (dispatch) => {
        return managementService.managementDeleteDateRange(id)
            .then((res) => {
                if (res) {
                    dispatch({type: MANAGEMENT_DELETE_DATE_RANGE, result: res});
                }
            })
            .catch((err) => {
                dispatch({type: MANAGEMENT_DELETE_DATE_RANGE_ERROR, result: err});
            })
    }
}

export function clearDates() {
    return(dispatch) => {
        dispatch({type: MANAGEMENT_CLEAR_DATE_RANGE})
    }
}

export function managementFetchTimeTable(orgId , providerId) {
    return (dispatch) => {
        dispatch({type: MANAGEMENT_FETCH_APPOINTMENTS_REQUEST_SENT});
        return managementService.managementGetDateRanges(orgId , providerId).then((res) => {
            if (res) {
                dispatch({type: MANAGEMENT_GET_DATE_RANGE_SUCCESS, payload: res});
            }
        }).catch((err) => {
            dispatch({type: MANAGEMENT_GET_DATE_RANGE_ERROR, payload: err});
        });
    }
}

export function createNote(userId, note, appointmentId, encounterId) {
    return (dispatch, getState) => {
        dispatch({type: SET_FORM_TO_DISABLED, formName: NOTES});
        dispatch({type: CREATE_NOTE_REQUEST_SENT});
        return usersService.createNote(note, appointmentId, encounterId).then(() => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
            dispatch({type: SET_FORM_TO_ENABLED});
        }).catch((err) => {
            dispatch({type: CREATE_NOTE_REQUEST_ERROR, result: err});
            dispatch({type: SET_FORM_TO_ENABLED});
        });
    }
}

// eslint-disable-next-line no-unused-vars
export function updateNote(userId, note, appointmentId) {
    return (dispatch, getState) => {
        dispatch({type: UPDATE_NOTE_REQUEST_SENT});
        return usersService.editNote(note).then(() => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
        }).catch((err) => {
            dispatch({type: UPDATE_NOTE_REQUEST_ERROR, result: err});
        });
    }
}

// eslint-disable-next-line no-unused-vars
export function deleteNote(userId, noteId, appointmentId) {
    return (dispatch, getState) => {
        dispatch({type: DELETE_NOTE_REQUEST_SENT});
        return usersService.deleteNote(noteId).then(() => {
            if (getState().selectedUser.id !== userId) {
                dispatch({type: DUMMY});
            }
        }).catch((err) => {
            dispatch({type: DELETE_NOTE_REQUEST_ERROR, result: err});
        });
    }
}