import React, {Component} from 'react'
import {connect} from 'react-redux'
import {updateAppointmentStatus} from '../../actions/users_actions'
import PropTypes from "prop-types";
import StaffVerticalCard from "./StaffVerticalCard";
import {fetchAppointments} from "../../actions/clinic_actions";
import StaffAppointmentList from "./StaffAppointmentList";
import {endOfWeek, startOfWeek} from "date-fns";


class StaffAppointments extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        let date = new Date();
        let start = startOfWeek(date);
        let end = endOfWeek(date);

        this.fetchAppointments(start, end)
    }

    fetchAppointments = (start, end) => {
        let params = {
            org_id: this.props.selectedOrganisation.id,
            after: Date.parse(start),
            before: Date.parse(end),
            with_consultations:true
        }
        this.props.fetchAppointments(null, this.props.selectedOrganisation.id, this.props.selectedStaffMember.id, params)
    }


    render() {
        return (
            <div className="med-history-page">
                <div className="history d-flex flex-container flex-row">
                    <div className="main-col">
                        <div className="patient-card-header">
                            <StaffAppointmentList
                                orgId={this.props.selectedOrganisation.id}
                                organisationName={this.props.selectedOrganisation.name}
                                appointments={this.props.appointments}
                                getStaff={this.props.getStaff}
                                fetchAppointments={this.fetchAppointments}
                                i18n={this.props.i18n}
                            />
                        </div>
                    </div>
                    <StaffVerticalCard i18n={this.props.i18n}
                                       selectedStaffMember={this.props.selectedStaffMember}
                    />
                </div>
            </div>
        )
    }
}

StaffAppointments.propTypes = {
    i18n: PropTypes.any,
    selectedOrganisation: PropTypes.object,
    appointments: PropTypes.array,
    fetchAppointments: PropTypes.func,
    getStaff: PropTypes.func,
    selectedStaffMember: PropTypes.object
}

const mapDispatchToProps = {
    updateAppointmentStatus,
    fetchAppointments,
}

function mapStateToProps(state) {
    return {
        selectedOrganisation: state.management.selectedOrganisation,
        appointments: state.management.selectedClinicianAppointment.sort((a,b)=>a.starts_at - b.starts_at)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffAppointments)