import React, {Component, useEffect, useMemo, useRef, useState} from 'react'
import {$$} from '../../helpers/localization'
import {CONVERTER} from '../../utils/converter'
import {formatUtils} from '../../utils/formatUtils'
import {DASH} from '../../constants/dash'
import {countryOptions} from "../../constants/countries"
import UserImage from '../shared/UserImage'
import MedicationPlanV2 from '../dashboard/MedicationPlanV2'
import moment from 'moment'
import _ from 'lodash'
import PropTypes from "prop-types";
import {canEditInfo, isChild} from "../../utils/userUtils";
import Phone from "../common/Phone";
import Email from "../common/Email";
import {Select} from "../shared/Select";
import {ALLERGY_OPTIONS, GENDER, getBloodTypeOptions, getResolvedOptions} from "../../constants/select_options";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import enGB from "date-fns/locale/en-GB";
import bg from "date-fns/locale/bg";
import {validators} from "../../helpers/validators";
import Modal from "react-bootstrap/Modal";
import {Multiselect} from "multiselect-react-dropdown";
import {icdService} from "../../service/icd_service";
import {getFormattedIcds} from "../../reducers/icd_reducer";


class Patient extends Component {
    constructor(props) {
        super(props);
    }

    updatePersonalInfo = (pi) => {
        this.props.updatePatientInfo(pi, this.props.orgId);
    }

    updateMedicalProfile = (mp) => {
        this.props.updateMedicalProfile(mp);
    }

    updateChronicCondition = (cc) => {
        let mp = _.cloneDeep(this.props.medicalProfile)
        let idx = mp.chronic_conditions.findIndex(c => cc.id === c.id);
        mp.chronic_conditions[idx] = cc;
        this.props.updateMedicalProfile(mp);
    }

    createChronicCondition = (cc) => {
        let mp = _.cloneDeep(this.props.medicalProfile)
        mp.chronic_conditions.push(cc);
        this.props.updateMedicalProfile(mp);
    }

    deleteChronicCondition = (cc) => {
        let mp = _.cloneDeep(this.props.medicalProfile)
        mp.chronic_conditions = mp.chronic_conditions.filter(c => cc.id !== c.id);
        this.props.updateMedicalProfile(mp);
    }

    updateAllergy = (a) => {
        let mp = _.cloneDeep(this.props.medicalProfile)
        let idx = mp.allergies.findIndex(aa => a.id === aa.id);
        mp.allergies[idx] = a;
        this.props.updateMedicalProfile(mp);
    }

    createAllergy = (a) => {
        let mp = _.cloneDeep(this.props.medicalProfile)
        mp.allergies.push(a);
        this.props.updateMedicalProfile(mp);
    }

    deleteAllergy = (a) => {
        let mp = _.cloneDeep(this.props.medicalProfile)
        mp.allergies = mp.allergies.filter(aa => aa.id !== a.id);
        this.props.updateMedicalProfile(mp);
    }

    render() {

        if (!this.props.medicalProfile) {
            return null;
        }

        return (
            <div>
                <div className={"space-around-justify"}>
                    <PersonalInfo
                        appointment={this.props.appointment}
                        patient={this.props.patient}
                        onUpdate={this.updatePersonalInfo}
                        result={this.props.updatePatientUserInfoResult}
                        lang={this.props.i18n.selected.lang}
                        clearRequestState={this.props.clearPersonalInfoRequestState}
                    />
                    <hr/>
                    <MedicalProfileInfo
                        patient={this.props.patient}
                        medicalProfile={this.props.medicalProfile}
                        onUpdate={this.updateMedicalProfile}
                        result={this.props.updateMedicalProfileResult}
                        lang={this.props.i18n.selected.lang}
                        clearRequestState={this.props.clearMedProfileRequestState}
                    />
                    <hr/>
                    <ChronicConditionsInfo patient={this.props.patient}
                                           chronicConditions={this.props.medicalProfile.chronic_conditions || []}
                                           onUpdate={this.updateChronicCondition}
                                           onCreate={this.createChronicCondition}
                                           onRemove={this.deleteChronicCondition}
                                           result={this.props.updateMedicalProfileResult}
                                           lang={this.props.i18n.selected.lang}
                                           clearRequestState={this.props.clearMedProfileRequestState}
                    />
                    <hr/>
                    <AllergiesInfo patient={this.props.patient}
                                   allergies={this.props.medicalProfile.allergies || []}
                                   onUpdate={this.updateAllergy}
                                   onCreate={this.createAllergy}
                                   onRemove={this.deleteAllergy}
                                   result={this.props.updateMedicalProfileResult}
                                   lang={this.props.i18n.selected.lang}
                                   clearRequestState={this.props.clearMedProfileRequestState}
                    />
                    <hr/>
                    <div>
                        <div className='medrec-grey-2'>{$$('current_medications')}</div>
                        <MedicationPlanV2 medicationPlan={this.props.medication.medicationPlan}
                                          i18n={this.props.i18n}/>
                    </div>
                </div>
            </div>
        )
    }
}

Patient.propTypes = {
    i18n: PropTypes.object,
    medication: PropTypes.object,
    medicalProfile: PropTypes.object,
    appointment: PropTypes.object,
    orgId: PropTypes.any,
    updatePatientInfo: PropTypes.func,
    updateMedicalProfile: PropTypes.func,
    clearMedProfileRequestState: PropTypes.func,
    updateMedicalProfileResult: PropTypes.object,
    clearPersonalInfoRequestState: PropTypes.func,
    updatePatientUserInfoResult: PropTypes.object,
    patient: PropTypes.object
}

export default Patient;

function getLocale(lang) {
    switch (lang) {
        case "en":
            return enGB;
        case "bg":
            return bg;
        default:
            return enGB;
    }
}

function EditButton({title, patient, onClick}) {
    if (canEditInfo(patient)) {
        return <a className="ml-2" href="#" title={title} onClick={onClick}><i className="fa fa-edit"/></a>
    }
    return null;
}

EditButton.propTypes = {
    title: PropTypes.any,
    onClick: PropTypes.func,
    patient: PropTypes.object
}

function IconButton({title, onClick, iconClass, linkClass}) {
    return <a className={`ml-2 ${linkClass ? linkClass : ''}`} href="#" title={title} onClick={onClick}><i
        className={iconClass}/></a>
}

IconButton.propTypes = {
    title: PropTypes.any,
    iconClass: PropTypes.any,
    linkClass: PropTypes.any,
    onClick: PropTypes.func,
    patient: PropTypes.object
}

function Cell({label, value}) {
    return <div className='col-sm-6 col-md-6'>
        <div className='medrec-grey-2 next-patient-row-label'>
            {label}
        </div>
        <div>{value !== undefined && value !== '' ? value : DASH}</div>
    </div>
}

Cell.propTypes = {
    label: PropTypes.any,
    value: PropTypes.any,
}

function Row({children, alignEnd}) {
    return <div className={`row next-patient-row ${alignEnd ? 'align-items-end' : ''}`}>
        {children}
    </div>
}

Row.propTypes = {
    children: PropTypes.any,
}

function MedicalProfileInfo({patient, medicalProfile, result, onUpdate, lang, clearRequestState}) {
    const [edit, setEdit] = useState(false);
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const [bloodGroup, setBloodGroup] = useState("");
    const [peakFlowLabel, setPeakFlowLabel] = useState(" ");
    const [peakFlowNorm, setPeakFlowNorm] = useState("");

    useEffect(() => {
        if (edit) {
            setEdit(false);
        }
    }, [patient.user_id ? patient.user_id : patient.id])

    useEffect(() => {
        if (medicalProfile && !_.isEmpty(medicalProfile)) {
            let weight = medicalProfile.weight !== 0 ? `${CONVERTER.convertByUnit('WEIGHT', medicalProfile.weight).toFixed(1)} ${CONVERTER.getUnitByType('WEIGHT')}` : DASH;
            setWeight(weight);
            let height = medicalProfile.height !== 0 ? `${CONVERTER.convertByUnit('HEIGHT', medicalProfile.height).toFixed(1)} ${CONVERTER.getUnitByType('HEIGHT')}` : DASH;
            setHeight(height);
            let bloodGroup = CONVERTER.getBloodGroupByType(medicalProfile.blood_type);
            setBloodGroup(bloodGroup);
            setPeakFlowLabel(medicalProfile.peak_flow_norm ? $$("peak_flow_norm") : " ");
            setPeakFlowNorm(medicalProfile.peak_flow_norm ? medicalProfile.peak_flow_norm : " ");
        }
    }, [medicalProfile, lang])

    if (edit) {
        return <EditMedicalProfileInfo medicalProfile={medicalProfile} onSave={onUpdate} onCancel={() => {
            setEdit(false);
            clearRequestState()
        }} lang={lang} result={result}/>
    }

    return <>
        <div className="d-flex justify-content-end">
            <EditButton
                title={$$("edit")}
                patient={patient}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setEdit(true)
                }
                }/>
        </div>
        <Row>
            <Cell label={$$('Weight')} value={weight}/>
            <Cell label={$$('Height')} value={height}/>
        </Row>
        <Row alignEnd>
            <Cell label={$$("blood_group")} value={bloodGroup}/>
            <Cell label={peakFlowLabel} value={peakFlowNorm}/>
        </Row>
    </>

}

MedicalProfileInfo.propTypes = {
    patient: PropTypes.any,
    medicalProfile: PropTypes.any,
    result: PropTypes.any,
    onUpdate: PropTypes.func,
    lang: PropTypes.any,
    clearRequestState: PropTypes.func,
}

function EditMedicalProfileInfo({medicalProfile, onCancel, onSave, result}) {
    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState({
        weight: medicalProfile.weight === 0 ? "" : medicalProfile.weight,
        height: medicalProfile.height === 0 ? "" : medicalProfile.height,
        blood_type: medicalProfile.blood_type,
        peak_flow_norm: medicalProfile.peak_flow_norm || ""
    });
    const [errors, setErrors] = useState({})

    useEffect(() => {
        setValues({
            weight: medicalProfile.weight === 0 ? "" : medicalProfile.weight,
            height: medicalProfile.height === 0 ? "" : medicalProfile.height,
            blood_type: medicalProfile.blood_type,
            peak_flow_norm: medicalProfile.peak_flow_norm || ""
        })
    }, [medicalProfile])

    useEffect(() => {
        if (result && result.finished && result.success && result.result) {
            onCancel();
        } else if (result && result.finished && result.error) {
            setErrors({serverError: result.error.message, status: result.error.status})
        }
    }, [result])


    const updateValueField = (e) => {
        let updatedValues = {...values};
        updatedValues[e.target.name] = e.target.value;
        setValues(updatedValues);
    }

    const updateSelectField = ({name, value}) => {
        let updatedValues = {...values};
        updatedValues[name] = value;
        setValues(updatedValues);
    }

    const onSubmit = (evt) => {
        evt.preventDefault();
        setValidated(true);
        try {
            if (evt.target.checkValidity()) {
                const valuesToUpdate = {
                    blood_type: values.blood_type,
                    weight: values.weight ? values.weight : 0,
                    height: values.height ? values.height : 0,
                    peak_flow_norm: values.peak_flow_norm ? values.peak_flow_norm : null
                };
                onSave({...medicalProfile, ...valuesToUpdate})
            }
        } catch (e) {
            console.log(e);
        }
    }

    return <form className={validated ? "was-validated" : ""} noValidate onSubmit={onSubmit}>
        <fieldset>
            <div className="row form-group align-items-center">
                <div className="col-sm-6">
                    <label>{$$('Weight')} ({$$("kg")})</label>
                    <input
                        type="text"
                        className="form-control my-1 mr-sm-2"
                        value={values.weight}
                        pattern='\d*\.?\d*'
                        name="weight"
                        onChange={updateValueField}/>
                </div>
                <div className="col-sm-6">
                    <label>{$$('Height')} ({$$("cm")})</label>
                    <input
                        type="text"
                        className="form-control my-1 mr-sm-2"
                        value={values.height}
                        pattern='\d*\.?\d*'
                        name="height"
                        onChange={updateValueField}/>
                </div>
            </div>
            <div className="row form-group align-items-end">
                <div className="col-sm-6">
                    <Select
                        label={$$('blood_group')}
                        name="blood_type"
                        class="my-1"
                        value={values.blood_type}
                        options={getBloodTypeOptions()}
                        onChange={updateSelectField}/>
                </div>
                <div className="col-sm-6">
                    <label>{$$('peak_flow_norm')}</label>
                    <input
                        type="text"
                        className="form-control my-1 mr-sm-2"
                        value={values.peak_flow_norm}
                        pattern='\d*'
                        name="peak_flow_norm"
                        onChange={updateValueField}/>
                </div>
            </div>
            {errors.serverError && <div className="form-group">
                <p className="text-danger">{`${errors.serverError} status:${errors.status}`}</p>
            </div>}
            <div className="form-group">
                <div className='row flex-space-between register-btn-back-container'>
                    <div className="col-xs-12 col-md-6 mx-auto mt-2">
                        <button type='button' onClick={onCancel}
                                className='btn btn-secondary register-btn-back'>
                            {$$('cancel_btn')}
                        </button>
                    </div>
                    <div className="col-xs-12 col-md-6 mx-auto mt-2">
                        <button type='submit' className='btn btn-primary full-width'>
                            {$$('update_btn')}
                        </button>
                    </div>
                </div>
            </div>
        </fieldset>

    </form>
}

EditMedicalProfileInfo.propTypes = {
    medicalProfile: PropTypes.any,
    result: PropTypes.any,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
}

function PersonalInfo({patient, appointment, onUpdate, result, lang, clearRequestState}) {
    const [edit, setEdit] = useState(false);
    const [gender, setGender] = useState("");
    const [age, setAge] = useState("");
    const [birthday, setBirthday] = useState("");
    const [city, setCity] = useState("");
    const [currentLocale, setCurrentLocale] = useState(getLocale(lang))
    useEffect(() => setCurrentLocale(getLocale(lang)), [lang])

    useEffect(() => {
        setGender(formatUtils.format(CONVERTER.formatSex(patient.gender)));

        if (patient.birthday === 0) {
            setAge(DASH)
        } else {
            let age = CONVERTER.millisecondsToAge(patient.birthday);
            setAge(`${age} ${$$(age === 1 ? 'year_old' : 'years_old')}`);
        }
        setBirthday(patient.birthday ? moment(patient.birthday).format('DD-MM-YYYY') : DASH)
        setCity(patient.city);
    }, [patient, lang])


    useEffect(() => {
        if (edit) {
            setEdit(false);
        }
    }, [patient.user_id ? patient.user_id : patient.id])

    if (edit) {
        return <EditPersonalInfo patient={patient} result={result} onSave={onUpdate} onCancel={() => {
            setEdit(false), clearRequestState()
        }} locale={currentLocale} lang={lang}/>
    }

    return <>
        <div className='d-flex'>
            <div className={"no-img-container"}>
                <UserImage userID={appointment ? patient.user_id : patient.id}
                           classnames="patient-img"/>
            </div>
            <div className='row space-between-justify no-margin'>
                <div className={"dropdown-link"}>
                    <div className={"patient-name"}>{patient.fullname}</div>
                    <div
                        className='lighter-font'><Email object={patient}/></div>
                    <div
                        className='lighter-font'><Phone object={patient}/></div>
                </div>
            </div>
            <div><EditButton title={$$("edit")} patient={patient}
                             onClick={(e) => {
                                 e.preventDefault();
                                 e.stopPropagation();
                                 setEdit(true)
                             }}/>
            </div>
        </div>
        <br/>
        <Row>
            <Cell label={$$('gender_label')} value={gender}/>
            <Cell label={$$('age_label')} value={age}/>
        </Row>
        <Row>
            <Cell label={$$('birthday_label')} value={birthday}/>
            <Cell label={$$('city_label')} value={city}/>
        </Row>
    </>
}

PersonalInfo.propTypes = {
    patient: PropTypes.any,
    appointment: PropTypes.any,
    onUpdate: PropTypes.func,
    result: PropTypes.any,
    lang: PropTypes.any,
    clearRequestState: PropTypes.func,
}

function EditPersonalInfo({patient, result, onSave, onCancel, locale, lang}) {
    const [validated, setValidated] = useState(false);
    const [values, setValues] = useState({
        fullname: patient.fullname,
        email: patient.email.includes("@") ? patient.email : "",
        phone: patient.phone || "",
        gender: patient.gender || "",
        country: patient.country || "",
        city: patient.city || "",
        birthday: patient.birthday,

    });
    const [errors, setErrors] = useState({});
    const isPatientChild = useMemo(() => isChild(patient), [patient]);
    const ref = useRef();

    useEffect(() => {
        setValues({
            fullname: patient.fullname,
            email: patient.email.includes("@") ? patient.email : "",
            phone: patient.phone || "",
            gender: patient.gender || "",
            country: patient.country || "",
            city: patient.city || "",
            birthday: patient.birthday,
        })
    }, [patient])

    useEffect(() => {
        if (result && result.finished && result.success) {
            onCancel();
        } else if (result && result.finished && result.error) {
            if (result.error.message === 'Username already exists') {
                setErrors({email: 'register_form_email_exists_message'})
                ref.current.setCustomValidity("error")
            } else {
                setErrors({serverError: result.error.message, status: result.error.status})
            }
        }
    }, [result])

    const updateValueField = (e) => {
        let updatedValues = {...values};
        updatedValues[e.target.name] = e.target.value;
        if (e.target.name === 'email' && errors && errors.email) {
            ref.current.setCustomValidity("")
            setErrors({})
        }
        setValues(updatedValues);
    }

    const updateSelectField = ({name, value}) => {
        let updatedValues = {...values};
        updatedValues[name] = value;
        setValues(updatedValues);
    }

    const updateBirthday = (date) => {
        let updatedValues = {...values};
        updatedValues["birthday"] = moment(date).valueOf();
        setValues(updatedValues);
    };

    const onSubmit = (evt) => {
        evt.preventDefault();
        setValidated(true);
        try {
            const formErrors = {};
            if (!isPatientChild && values.email && !validators.validateEmail(values.email) && values.email !== "") {
                formErrors.email = 'register_form_email_not_correct_message';
            }

            if (Object.keys(formErrors).length) {
                setErrors(formErrors);
                return;
            }

            if (evt.target.checkValidity()) {
                let userId = patient.user_id ? patient.user_id : patient.id;
                let emailForSave = values.email ? values.email : userId;
                onSave({id: userId, ...values, email: emailForSave});
            }
        } catch (e) {
            console.log(e);
        }
    }

    return <form className={validated ? "was-validated" : ""} noValidate onSubmit={onSubmit}>
        <fieldset>
            <div className="form-group">
                {values.fullname && <label>{$$('wizard_personal_information_fullname_label')}</label>}
                <input
                    type="text"
                    className="form-control"
                    value={values.fullname}
                    placeholder={$$('wizard_personal_information_fullname_label')}
                    name="fullname"
                    onChange={updateValueField}
                    required
                />
                <div className="invalid-feedback">
                    {$$('wizard_personal_information_fullname_required_label')}
                </div>
            </div>
            {!isPatientChild && <div className="form-group">
                {values.email && <label>{$$('email_label')}</label>}
                <input
                    ref={ref}
                    type="email"
                    className={"form-control"}
                    value={values.email}
                    placeholder={$$('email_label')}
                    name="email"
                    onChange={updateValueField}
                />
                <div
                    className={"invalid-feedback"}>
                    {errors.email && $$(errors.email)}
                </div>
            </div>}
            {!isPatientChild && <div className="form-group">
                {values.phone && <label>{$$('phone_number_required')}</label>}
                <input
                    type="text"
                    className="form-control"
                    value={values.phone}
                    placeholder={$$('phone_number_required')}
                    name="phone"
                    onChange={updateValueField}
                    required
                />
                <div className="invalid-feedback">
                    {$$('phone_number_required_error')}
                </div>
            </div>}
            <div className="row form-group align-items-end">
                <div className="col-sm-6">
                    <Select
                        label={$$('gender_label')}
                        name="gender"
                        value={values.gender}
                        options={getResolvedOptions(GENDER.OPTIONS)}
                        onChange={updateSelectField}/>
                </div>
                <div className="col-sm-6">
                    <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            variant="inline"
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="date-picker-inline"
                            label={$$("birthday_label")}
                            value={values.birthday}
                            KeyboardButtonProps={{'aria-label': 'change date',}}
                            onChange={updateBirthday}
                            required
                        />
                    </MuiPickersUtilsProvider>
                </div>
            </div>
            <div className="row form-group align-items-center">
                <div className="col-sm-6">
                    <Select
                        label={$$('country_label')}
                        name="country"
                        options={countryOptions(lang)}
                        value={values.country}
                        onChange={updateSelectField}/>
                </div>
                <div className="col-sm-6">
                    <label>{$$('city_label')}</label>
                    <input
                        type="text"
                        className="form-control my-1 mr-sm-2"
                        value={values.city}
                        placeholder={$$('city_label')}
                        name="city"
                        onChange={updateValueField}/>
                </div>
            </div>
            {errors.serverError && <div className="form-group">
                <p className="text-danger">{`${errors.serverError} status:${errors.status}`}</p>
            </div>}
            <div className="form-group">
                <div className='row flex-space-between register-btn-back-container'>
                    <div className="col-xs-12 col-md-6 mx-auto mt-2">
                        <button type='button' onClick={onCancel}
                                className='btn btn-secondary register-btn-back'>
                            {$$('cancel_btn')}
                        </button>
                    </div>
                    <div className="col-xs-12 col-md-6 mx-auto mt-2">
                        <button type='submit' className='btn btn-primary full-width'>
                            {$$('update_btn')}
                        </button>
                    </div>
                </div>
            </div>
        </fieldset>


    </form>
}

EditPersonalInfo.propTypes = {
    patient: PropTypes.any,
    result: PropTypes.any,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    locale: PropTypes.any,
    lang: PropTypes.any,
}

function ChronicConditionsInfo({
                                   patient,
                                   chronicConditions,
                                   result,
                                   onUpdate,
                                   onCreate,
                                   onRemove,
                                   lang,
                                   clearRequestState
                               }) {
    const [edit, setEdit] = useState(false);
    const [currentLocale, setCurrentLocale] = useState(getLocale(lang))

    useEffect(() => {
        if (edit) {
            setEdit(false);
        }
    }, [patient.user_id ? patient.user_id : patient.id])

    useEffect(() => setCurrentLocale(getLocale(lang)), [lang])

    if (edit) {
        return <EditChronicConditions lang={lang} result={result} patient={patient}
                                      chronicConditions={chronicConditions}
                                      onUpdate={onUpdate}
                                      onCreate={onCreate}
                                      onRemove={onRemove} onCancel={() => {
            setEdit(false);
            clearRequestState()
        }}
                                      clearRequestState={clearRequestState} locale={currentLocale}/>
    }

    return <div>
        <div className="d-flex space-between-justify">
            <div className='medrec-grey-2'>{$$('chronic_conditions')}</div>
            <EditButton title={$$("edit")} patient={patient} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEdit(true)
            }
            }/>
        </div>
        <div className={`${chronicConditions.length > 0 ? "allergies-conditions-padding" : ""}`}>
            {chronicConditions.length > 0 ? chronicConditions.map((c, i) => {
                return <BlueItem key={i} text={c.name}/>
            }) : DASH}
        </div>
    </div>
}

ChronicConditionsInfo.propTypes = {
    patient: PropTypes.any,
    chronicConditions: PropTypes.any,
    result: PropTypes.any,
    onUpdate: PropTypes.func,
    onCreate: PropTypes.func,
    onRemove: PropTypes.func,
    lang: PropTypes.any,
    clearRequestState: PropTypes.func
}

function BlueItem({text}) {
    return <div className='patient-health-issue-list'
                style={{wordBreak: "normal", overflowWrap: "anywhere"}}>{text}</div>
}

BlueItem.propTypes = {
    text: PropTypes.any,
}

// eslint-disable-next-line no-unused-vars
function EditChronicConditions({ chronicConditions, patient, onUpdate, onCreate,
                                   onRemove, onCancel, locale, result, lang,
                                   clearRequestState
                               }) {
    const [forEdit, setForEdit] = useState();
    const [forDelete, setForDelete] = useState();
    const [createNew, setCreateNew] = useState(false);

    if (forEdit) {
        return <EditChronicCondition result={result} lang={lang} condition={forEdit} onCancel={() => {
            setForEdit(null);
            clearRequestState()
        }} onSave={onUpdate} locale={locale}/>
    }

    if (forDelete) {
        return <ConfirmDeleteChronicCondition result={result} condition={forDelete} onCancel={() => {
            setForDelete(null);
            clearRequestState()
        }} onConfirm={() => onRemove(forDelete)}/>
    }

    if (createNew) {
        return <NewChronicCondition result={result} lang={lang} onCancel={() => {
            setCreateNew(false);
            clearRequestState()
        }} onSave={onCreate} locale={locale}/>
    }

    return <div>{$$('chronic_conditions')}
        <div className={`allergies-conditions-padding`}>
            <table className="table-borderless">
                <tbody>
                {/* eslint-disable-next-line react/prop-types */}
                {chronicConditions.map((c, i) => {
                    return <tr key={i}>
                        <td><BlueItem key={i} text={c.name}/></td>
                        <td>
                            <div className="d-flex">
                                <IconButton onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setForEdit(c);
                                }} title={$$("edit")} iconClass={"fa fa-edit"}/>
                                <IconButton onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setForDelete(c);
                                }} title={$$("delete")} iconClass={"fa fa-times"} linkClass={"text-danger"}/>
                            </div>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
        <div className="form-group">
            <div className='row flex-space-between register-btn-back-container'>
                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                    <button type='button' onClick={onCancel}
                            className='btn btn-secondary register-btn-back'>
                        {$$('cancel_btn')}
                    </button>
                </div>
                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                    {/* eslint-disable-next-line no-unused-vars */}
                    <button type='button' onClick={(e) => {
                        setCreateNew(true);
                    }} className='btn btn-primary full-width'>
                        {$$('add_button')}
                    </button>
                </div>
            </div>
        </div>
    </div>
}

EditChronicConditions.propTypes = {
    chronicConditions: PropTypes.any,
    patient: PropTypes.any,
    onUpdate: PropTypes.func,
    onCreate: PropTypes.func,
    onRemove: PropTypes.func,
    onCancel: PropTypes.func,
    locale: PropTypes.any,
    result: PropTypes.any,
    lang: PropTypes.any,
    clearRequestState: PropTypes.func,
}

function NewChronicCondition({onSave, onCancel, locale, lang, result}) {
    const [validated, setValidated] = useState(false);
    const [pickedDiseaseOption, setPickedDiseaseOption] = useState(null);

    const [values, setValues] = useState({
        name: "",
        description: "",
        date_diagnosed: null,
        disease_code: ""
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (result && result.finished && result.success && result.result) {
            onCancel();
        } else if (result && result.finished && result.error) {
            setErrors({serverError: result.error.message, status: result.error.status})
        }
    }, [result])

    const updateValueField = (e) => {
        let updatedValues = {...values};
        updatedValues[e.target.name] = e.target.value;
        setValues(updatedValues);
    }

    const updateDiagnoseDate = (date) => {
        let updatedValues = {...values};
        updatedValues["date_diagnosed"] = date != null ? moment(date).valueOf() : null;
        setValues(updatedValues);
    };
    const onSubmit = (evt) => {
        evt.preventDefault();
        setValidated(true);
        try {
            //todo: implement
            if (evt.target.checkValidity()) {
                let valuesForUpdate = {...values}
                if (!values.date_diagnosed) {
                    valuesForUpdate.date_diagnosed = 0;
                }
                if (!values.disease_code) {
                    valuesForUpdate.disease_code = null;
                }
                onSave({...values, ...valuesForUpdate});
            }
        } catch (e) {
            console.log(e);
        }
    }

    return <form className={validated ? "was-validated" : ""} noValidate onSubmit={onSubmit}>
        <p>{$$("new_chronic_condition")}</p>
        <div className="wrap-chips">
            <SingleICDPicker lang={lang} placeholder={$$("ICB10_classification")} onChange={(option) => {
                if (option) {
                    setValues({...values, name: option.text, disease_code: option.value});
                } else {
                    setValues({...values, name: pickedDiseaseOption?.text, disease_code: ""});
                }
                setPickedDiseaseOption(option)
            }}/>
        </div>
        {!pickedDiseaseOption && <div className="form-group mt-4">
            {values.name && <label>{$$('name')}</label>}
            <input
                type="text"
                className="form-control"
                value={values.name}
                placeholder={$$('name')}
                name="name"
                onChange={updateValueField}
                required
            />
            <div className="invalid-feedback">
                {$$('chronic_disease_required_label')}
            </div>
        </div>}
        <div className={!pickedDiseaseOption ? "form-group":  "form-group mt-4"}>
            {values.description && <label>{$$('description')}</label>}
            <textarea
                className="form-control"
                value={values.description}
                placeholder={$$('description')}
                name="description"
                onChange={updateValueField}
            />
        </div>
        <div className="form-group">
            <label>{$$("diagnose_date")}</label>
            <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="none"
                    id="date-picker-inline"
                    value={values.date_diagnosed}
                    KeyboardButtonProps={{'aria-label': 'change date',}}
                    onChange={updateDiagnoseDate}
                />
            </MuiPickersUtilsProvider>
        </div>
        {errors.serverError && <div className="form-group">
            <p className="text-danger">{`${errors.serverError} status:${errors.status}`}</p>
        </div>}
        <div className="form-group">
            <div className='row flex-space-between register-btn-back-container'>
                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                    <button type='button' onClick={onCancel}
                            className='btn btn-secondary register-btn-back'>
                        {$$('cancel_btn')}
                    </button>
                </div>
                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                    <button type='submit' className='btn btn-primary full-width'>
                        {$$('save_btn_label')}
                    </button>
                </div>
            </div>
        </div>
    </form>
}

NewChronicCondition.propTypes = {
    onConfirm: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    result: PropTypes.any,
    locale: PropTypes.any,
    lang: PropTypes.any,
}

function EditChronicCondition({condition, onSave, onCancel, locale, lang, result}) {
    const [validated, setValidated] = useState(false);
    const [pickedDiseaseOption, setPickedDiseaseOption] = useState(null);

    const [values, setValues] = useState({
        name: condition.name,
        description: condition.description || "",
        date_diagnosed: condition.date_diagnosed === 0 ? null : condition.date_diagnosed,
        disease_code: condition.disease_code || ""
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (result && result.finished && result.success && result.result) {
            onCancel();
        } else if (result && result.finished && result.error) {
            setErrors({serverError: result.error.message, status: result.error.status})
        }
    }, [result])

    const updateValueField = (e) => {
        let updatedValues = {...values};
        updatedValues[e.target.name] = e.target.value;
        setValues(updatedValues);
    }

    const updateDiagnoseDate = (date) => {
        let updatedValues = {...values};
        updatedValues["date_diagnosed"] = date != null ? moment(date).valueOf() : null;
        setValues(updatedValues);
    };

    const onSubmit = (evt) => {
        evt.preventDefault();
        setValidated(true);
        try {
            //todo: implement
            if (evt.target.checkValidity()) {
                let valuesForUpdate = {...values}
                if (!values.date_diagnosed) {
                    valuesForUpdate.date_diagnosed = 0;
                }
                if (!values.disease_code) {
                    valuesForUpdate.disease_code = null;
                }
                onSave({...condition, ...valuesForUpdate});
            }
        } catch (e) {
            console.log(e);
        }
    }

    return <form className={validated ? "was-validated" : ""} noValidate onSubmit={onSubmit}>
        <p>{$$("edit_chronic_condition")}</p>
        <div className="wrap-chips">
            <SingleICDPicker lang={lang} placeholder={$$("ICB10_classification")} onChange={(option) => {
                if (option) {
                    setValues({...values, name: option.text, disease_code: option.value});
                } else {
                    //todo: rethink this!!!
                    setValues({...values, name: pickedDiseaseOption?.text, disease_code: ""});
                }
                setPickedDiseaseOption(option)
            }}/>
        </div>
        {!pickedDiseaseOption && <div className="form-group mt-4">
            {values.name && <label>{$$('name')}</label>}
            <input
                type="text"
                className="form-control"
                value={values.name}
                placeholder={$$('name')}
                name="name"
                onChange={updateValueField}
                required
            />
            <div className="invalid-feedback">
                {$$('chronic_disease_required_label')}
            </div>
        </div>}
        <div className={!pickedDiseaseOption ? "form-group":  "form-group mt-4"}>
            {values.description && <label>{$$('description')}</label>}
            <textarea
                className="form-control"
                value={values.description}
                placeholder={$$('description')}
                name="description"
                onChange={updateValueField}
            />
        </div>
        <div className="form-group">
            <label>{$$("diagnose_date")}</label>
            <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="none"
                    id="date-picker-inline"
                    value={values.date_diagnosed}
                    KeyboardButtonProps={{'aria-label': 'change date',}}
                    onChange={updateDiagnoseDate}
                />
            </MuiPickersUtilsProvider>
        </div>
        {errors.serverError && <div className="form-group">
            <p className="text-danger">{`${errors.serverError} status:${errors.status}`}</p>
        </div>}
        <div className="form-group">
            <div className='row flex-space-between register-btn-back-container'>
                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                    <button type='button' onClick={onCancel}
                            className='btn btn-secondary register-btn-back'>
                        {$$('cancel_btn')}
                    </button>
                </div>
                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                    <button type='submit' className='btn btn-primary full-width'>
                        {$$('update_btn')}
                    </button>
                </div>
            </div>
        </div>
    </form>
}

EditChronicCondition.propTypes = {
    condition: PropTypes.object,
    onConfirm: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    result: PropTypes.any,
    locale: PropTypes.any,
    lang: PropTypes.any,
}

function ConfirmDeleteChronicCondition({condition, onConfirm, onCancel, result}) {
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (result && result.finished && result.success && result.result) {
            onCancel();
        } else if (result && result.finished && result.error) {
            setErrors({serverError: result.error.message, status: result.error.status})
        }
    }, [result])


    return <Modal onHide={onCancel} show={true}>
        <Modal.Header><h5 className="text-danger">{$$("warning_title")}</h5></Modal.Header>
        <Modal.Body>
            <p>{$$("confirm_delete_chronic_condition_modal_body")}</p>
            <div className="d-flex justify-content-center">
                <BlueItem text={condition.name}/>
            </div>
            {errors.serverError && <p className="text-danger">{`${errors.serverError} status:${errors.status}`}</p>}
        </Modal.Body>
        <Modal.Footer>
            <button type='button' onClick={onCancel}
                    className='btn btn-secondary'>
                {$$('cancel_btn')}
            </button>
            <button type='button' className='btn btn-danger' onClick={onConfirm}>
                {$$('delete')}
            </button>
        </Modal.Footer>
    </Modal>
}

ConfirmDeleteChronicCondition.propTypes = {
    condition: PropTypes.object,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    result: PropTypes.any,
}

function AllergiesInfo({patient, allergies, result, onUpdate, onCreate, onRemove, lang, clearRequestState}) {
    const [edit, setEdit] = useState(false);
    const [currentLocale, setCurrentLocale] = useState(getLocale(lang))

    useEffect(() => {
        if (edit) {
            setEdit(false);
        }
    }, [patient.user_id ? patient.user_id : patient.id])

    useEffect(() => setCurrentLocale(getLocale(lang)), [lang])

    if (edit) {
        return <EditAllergies result={result}
                              lang={lang}
                              allergies={allergies}
                              patient={patient}
                              clearRequestState={clearRequestState}
                              onUpdate={onUpdate}
                              onCreate={onCreate}
                              onRemove={onRemove}
                              onSave={onUpdate}
                              onCancel={() => {
                                  setEdit(false);
                                  clearRequestState()
                              }} locale={currentLocale}/>
    }

    return <div>
        <div className="d-flex space-between-justify">
            <div className='medrec-grey-2'>{$$('allergies')}</div>
            <EditButton title={$$("edit")} patient={patient} onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setEdit(true)
            }}/>
        </div>
        <div className={`row zero-margin-row ${allergies.length > 0 ? "allergies-conditions-padding" : ""}`}>
            {allergies.length > 0 ? allergies.map((a, i) => {
                return <BlueItem key={i} text={CONVERTER.getAllergyNameForDisplay(a)}/>
            }) : DASH}
        </div>
    </div>
}

AllergiesInfo.propTypes = {
    allergies: PropTypes.any,
    patient: PropTypes.any,
    onUpdate: PropTypes.func,
    onCreate: PropTypes.func,
    onRemove: PropTypes.func,
    clearRequestState: PropTypes.func,
    locale: PropTypes.any,
    lang: PropTypes.any,
    result: PropTypes.any,
}

// eslint-disable-next-line no-unused-vars
function EditAllergies({ allergies, patient, onUpdate, onCreate, onRemove,
                           onCancel, locale, lang, result, clearRequestState
                       }) {
    const [forEdit, setForEdit] = useState();
    const [forDelete, setForDelete] = useState();
    const [createNew, setCreateNew] = useState(false);

    if (forEdit) {
        return <EditAllergy result={result} lang={lang} allergy={forEdit} onCancel={() => {
            setForEdit(null);
            clearRequestState()
        }} onSave={onUpdate} locale={locale}/>
    }

    if (forDelete) {
        return <ConfirmDeleteAllergy result={result} allergy={forDelete} onCancel={() => {
            setForDelete(null);
            clearRequestState()
        }} onConfirm={() => onRemove(forDelete)}/>
    }

    if (createNew) {
        return <NewAllergy result={result} lang={lang} onCancel={() => {
            setCreateNew(false);
            clearRequestState()
        }} onSave={onCreate} locale={locale}/>
    }

    return <div>{$$('allergies')}
        <div className={`allergies-conditions-padding`}>
            <table className="table-borderless">
                <tbody>
                {/* eslint-disable-next-line react/prop-types */}
                {allergies.map((c, i) => {
                    return <tr key={i}>
                        <td><BlueItem key={i} text={CONVERTER.getAllergyNameForDisplay(c)}/></td>
                        <td>
                            <div className="d-flex">
                                <IconButton onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setForEdit(c);
                                }} title={$$("edit")} iconClass={"fa fa-edit"}/>
                                <IconButton onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setForDelete(c);
                                }} title={$$("delete")} iconClass={"fa fa-times"} linkClass={"text-danger"}/>
                            </div>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
        <div className="form-group">
            <div className='row flex-space-between register-btn-back-container'>
                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                    <button type='button' onClick={onCancel}
                            className='btn btn-secondary register-btn-back'>
                        {$$('cancel_btn')}
                    </button>
                </div>
                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                    {/* eslint-disable-next-line no-unused-vars */}
                    <button type='button' onClick={(e) => {
                        setCreateNew(true);
                    }} className='btn btn-primary full-width'>
                        {$$('add_button')}
                    </button>
                </div>
            </div>
        </div>
    </div>
}


EditAllergies.propTypes = {
    allergies: PropTypes.any,
    patient: PropTypes.any,
    onUpdate: PropTypes.func,
    onCreate: PropTypes.func,
    onRemove: PropTypes.func,
    clearRequestState: PropTypes.func,
    onCancel: PropTypes.func,
    locale: PropTypes.any,
    lang: PropTypes.any,
    result: PropTypes.any,
}

function NewAllergy({onSave, onCancel, locale, lang, result}) {
    const [validated, setValidated] = useState(false);
    const [pickedDiseaseOption, setPickedDiseaseOption] = useState(null);

    const [values, setValues] = useState({
        name: "",
        description: "",
        date_diagnosed: null,
        disease_code: "",
        type: "NONE",
        severity: "0",
        medications: "",
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (result && result.finished && result.success && result.result) {
            onCancel();
        } else if (result && result.finished && result.error) {
            setErrors({serverError: result.error.message, status: result.error.status})
        }
    }, [result])

    const updateValueField = (e) => {
        let updatedValues = {...values};
        updatedValues[e.target.name] = e.target.value;
        setValues(updatedValues);
    }

    const updateSelectField = ({name, value}) => {
        let updatedValues = {...values};
        updatedValues[name] = value;
        setValues(updatedValues);
    }

    const updateDiagnoseDate = (date) => {
        let updatedValues = {...values};
        updatedValues["date_diagnosed"] = date != null ? moment(date).valueOf() : null;
        setValues(updatedValues);
    };

    const onSubmit = (evt) => {
        evt.preventDefault();
        setValidated(true);
        try {
            //todo: implement
            if (evt.target.checkValidity()) {
                let valuesForUpdate = {...values}
                if (!values.date_diagnosed) {
                    valuesForUpdate.date_diagnosed = 0;
                }
                if (!values.disease_code) {
                    valuesForUpdate.disease_code = null;
                }
                onSave({...values, ...valuesForUpdate});
            }
        } catch (e) {
            console.log(e);
        }
    }

    return <form className={validated ? "was-validated" : ""} noValidate onSubmit={onSubmit}>
        <p>{$$("new_allergy")}</p>
        <div className="form-group">
            <Select
                label={$$('allergy_type')}
                name="type"
                value={values.type}
                options={getResolvedOptions(ALLERGY_OPTIONS.TYPE)}
                onChange={updateSelectField}/>
        </div>
        <div className="form-group">
            {values.name && <label>{$$('allergen')}</label>}
            <input
                type="text"
                className="form-control"
                value={values.name}
                placeholder={$$('allergen')}
                name="name"
                onChange={updateValueField}
                required
            />
            <div className="invalid-feedback">
                {$$('allergen_required_label')}
            </div>
        </div>
        <div className="form-group">
            <Select
                label={$$('allergy_severity')}
                name="severity"
                value={values.severity}
                options={getResolvedOptions(ALLERGY_OPTIONS.SEVERITY)}
                onChange={updateSelectField}/>
        </div>
        <div className="mb-3 wrap-chips">
            <SingleICDPicker lang={lang} placeholder={$$("ICB10_classification")} onChange={(option) => {
                if (option) {
                    setValues({...values, description: option.text, disease_code: option.value});
                } else {
                    setValues({...values, description: pickedDiseaseOption?.text, disease_code: ""});
                }
                setPickedDiseaseOption(option)
            }}/>
        </div>
        {!pickedDiseaseOption &&
            <div className="form-group">
                {values.description && <label>{$$('description')}</label>}
                <textarea
                    className="form-control"
                    value={values.description}
                    placeholder={$$('description')}
                    name="description"
                    onChange={updateValueField}
                />
            </div>
        }
        <div className="form-group">
            {values.medications && <label>{$$('allergy_medications')}</label>}
            <input
                type="text"
                className="form-control"
                value={values.medications}
                placeholder={$$('allergy_medications')}
                name="medications"
                onChange={updateValueField}
            />
        </div>
        <div className="form-group">
            <label>{$$("diagnose_date")}</label>
            <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="none"
                    id="date-picker-inline"
                    value={values.date_diagnosed}
                    KeyboardButtonProps={{'aria-label': 'change date',}}
                    onChange={updateDiagnoseDate}
                />
            </MuiPickersUtilsProvider>
        </div>
        {errors.serverError && <div className="form-group">
            <p className="text-danger">{`${errors.serverError} status:${errors.status}`}</p>
        </div>}
        <div className="form-group">
            <div className='row flex-space-between register-btn-back-container'>
                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                    <button type='button' onClick={onCancel}
                            className='btn btn-secondary register-btn-back'>
                        {$$('cancel_btn')}
                    </button>
                </div>
                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                    <button type='submit' className='btn btn-primary full-width'>
                        {$$('save_btn_label')}
                    </button>
                </div>
            </div>
        </div>
    </form>
}

NewAllergy.propTypes = {
    allergy: PropTypes.any,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    locale: PropTypes.any,
    lang: PropTypes.any,
    result: PropTypes.any,
}


function EditAllergy({allergy, onSave, onCancel, locale, lang, result}) {
    const [validated, setValidated] = useState(false);
    const [pickedDiseaseOption, setPickedDiseaseOption] = useState(null);

    const [values, setValues] = useState({
        name: allergy.name,
        description: allergy.description || "",
        date_diagnosed: allergy.date_diagnosed === 0 ? null : allergy.date_diagnosed,
        disease_code: allergy.disease_code ? allergy.disease_code : "",
        type: allergy.type,
        severity: allergy.severity,
        medications: allergy.medications ? allergy.medications : ""
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (result && result.finished && result.success && result.result) {
            onCancel();
        } else if (result && result.finished && result.error) {
            setErrors({serverError: result.error.message, status: result.error.status})
        }
    }, [result])

    const updateValueField = (e) => {
        let updatedValues = {...values};
        updatedValues[e.target.name] = e.target.value;
        setValues(updatedValues);
    }

    const updateSelectField = ({name, value}) => {
        let updatedValues = {...values};
        updatedValues[name] = value;
        setValues(updatedValues);
    }

    const updateDiagnoseDate = (date) => {
        let updatedValues = {...values};
        updatedValues["date_diagnosed"] = date != null ? moment(date).valueOf() : null;
        setValues(updatedValues);
    };

    const onSubmit = (evt) => {
        evt.preventDefault();
        setValidated(true);
        try {
            //todo: implement
            if (evt.target.checkValidity()) {
                let valuesForUpdate = {...values}
                if (!values.date_diagnosed) {
                    valuesForUpdate.date_diagnosed = 0;
                }
                if (!values.disease_code) {
                    valuesForUpdate.disease_code = null;
                }
                onSave({...allergy, ...valuesForUpdate});
            }
        } catch (e) {
            console.log(e);
        }
    }

    return <form className={validated ? "was-validated" : ""} noValidate onSubmit={onSubmit}>
        <p>{$$("edit_allergy")}</p>
        <div className="form-group">
            <Select
                label={$$('allergy_type')}
                name="type"
                value={values.type}
                options={getResolvedOptions(ALLERGY_OPTIONS.TYPE)}
                onChange={updateSelectField}/>
        </div>
        <div className="form-group">
            {values.name && <label>{$$('allergen')}</label>}
            <input
                type="text"
                className="form-control"
                value={values.name}
                placeholder={$$('allergen')}
                name="name"
                onChange={updateValueField}
                required
            />
            <div className="invalid-feedback">
                {$$('allergen_required_label')}
            </div>
        </div>
        <div className="form-group">
            <Select
                label={$$('allergy_severity')}
                name="severity"
                value={values.severity}
                options={getResolvedOptions(ALLERGY_OPTIONS.SEVERITY)}
                onChange={updateSelectField}/>
        </div>
        <div className="mb-3 wrap-chips">
            <SingleICDPicker lang={lang} placeholder={$$("ICB10_classification")} onChange={(option) => {
                if (option) {
                    setValues({...values, description: option.text, disease_code: option.value});
                } else {
                    setValues({...values, description: pickedDiseaseOption?.text, disease_code: ""});
                }
                setPickedDiseaseOption(option)
            }}/>
        </div>
        {!pickedDiseaseOption &&
            <div className="form-group">
                {values.description && <label>{$$('description')}</label>}
                <textarea
                    className="form-control"
                    value={values.description}
                    placeholder={$$('description')}
                    name="description"
                    onChange={updateValueField}
                />
            </div>
        }
        <div className="form-group">
            {values.medications && <label>{$$('allergy_medications')}</label>}
            <input
                type="text"
                className="form-control"
                value={values.medications}
                placeholder={$$('allergy_medications')}
                name="medications"
                onChange={updateValueField}
            />
        </div>
        <div className="form-group">
            <label>{$$("diagnose_date")}</label>
            <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                <KeyboardDatePicker
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="none"
                    id="date-picker-inline"
                    value={values.date_diagnosed}
                    KeyboardButtonProps={{'aria-label': 'change date',}}
                    onChange={updateDiagnoseDate}
                />
            </MuiPickersUtilsProvider>
        </div>
        {errors.serverError && <div className="form-group">
            <p className="text-danger">{`${errors.serverError} status:${errors.status}`}</p>
        </div>}
        <div className="form-group">
            <div className='row flex-space-between register-btn-back-container'>
                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                    <button type='button' onClick={onCancel}
                            className='btn btn-secondary register-btn-back'>
                        {$$('cancel_btn')}
                    </button>
                </div>
                <div className="col-xs-12 col-md-6 mx-auto mt-2">
                    <button type='submit' className='btn btn-primary full-width'>
                        {$$('save_btn_label')}
                    </button>
                </div>
            </div>
        </div>
    </form>
}

EditAllergy.propTypes = {
    allergy: PropTypes.any,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    locale: PropTypes.any,
    lang: PropTypes.any,
    result: PropTypes.any,
}

function ConfirmDeleteAllergy({allergy, onConfirm, onCancel, result}) {
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (result && result.finished && result.success && result.result) {
            onCancel();
        } else if (result && result.finished && result.error) {
            setErrors({serverError: result.error.message, status: result.error.status})
        }
    }, [result])

    return <Modal onHide={onCancel} show={true}>
        <Modal.Header><h5 className="text-danger">{$$("warning_title")}</h5></Modal.Header>
        <Modal.Body>
            <p>{$$("confirm_delete_allergy_modal_body")}</p>
            <div className="d-flex justify-content-center">
                <BlueItem text={CONVERTER.getAllergyNameForDisplay(allergy)}/>
            </div>
            {errors.serverError && <p className="text-danger">{`${errors.serverError} status:${errors.status}`}</p>}
        </Modal.Body>
        <Modal.Footer>
            <button type='button' onClick={onCancel}
                    className='btn btn-secondary'>
                {$$('cancel_btn')}
            </button>
            <button type='button' className='btn btn-danger' onClick={onConfirm}>
                {$$('delete')}
            </button>
        </Modal.Footer>
    </Modal>
}

ConfirmDeleteAllergy.propTypes = {
    allergy: PropTypes.any,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    result: PropTypes.any,
    locale: PropTypes.any,
    lang: PropTypes.any
}

// eslint-disable-next-line no-unused-vars
function SingleICDPicker({onChange, value, placeholder, lang}) {
    const [selectedValues, setSelectedValues] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [options, setOptions] = useState([])

    useEffect(() => onSearch(searchValue), [lang])

    const onSearch = (text) => {
        setSearchValue(text)
        icdService.searchIcd(lang, text).then(res => {
            let formatted = getFormattedIcds(res);
            if (formatted) {
                setOptions(formatted.map(o => {
                    if (o.label) {
                        let text = o.label;
                        return {value: o.id, text: text}
                    } else return {value: o.id, text: o.label ? o.label : ""}
                }));
            } else {
                setOptions([])
            }
        })
    }

    const onSelect = (arr) => {
        let option = arr[arr.length - 1];
        setSelectedValues([option]);
        onChange(option)
    }

    const onRemove = () => {
        setSelectedValues([]);
        setOptions([]);
        onChange(null)
    }

    return <Multiselect
        style={{
            searchBox: {
                "display": "flex", "flexWrap": "wrap"
            }
        }}
        options={options}
        onSearch={onSearch}
        selectedValues={selectedValues}
        displayValue="text"
        placeholder={placeholder}
        emptyRecordMsg={$$("no_records_found")}
        avoidHighlightFirstOption={true}
        onSelect={onSelect}
        onRemove={onRemove}
        hidePlaceholder={false}
    />

}

SingleICDPicker.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    placeholder: PropTypes.any,
    lang: PropTypes.any,
}