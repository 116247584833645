import React, {Component} from 'react';
import PropTypes from "prop-types";
import {markAppointmentAsPaid, updateAppointment, updateAppointmentStatus} from "../../actions/clinic_actions";
import {Appointments, CustomActions} from "../patient-management/PatientPage";
import {connect} from "react-redux";
import {NotesTab} from "../notes/NotesTab";
import {$$} from "../../helpers/localization";
import {APPOINTMENTS_FILTER, getResolvedOptions} from "../../constants/select_options";
import {Select} from "../shared/Select";
import {addDays, endOfWeek, isThisWeek, startOfWeek, subDays} from "date-fns";
import moment from "moment";
import no_data from "../../resources/images/no_data.png";
import {infoUtils} from "../../utils/infoUtils";
import _ from "underscore";

import {createNote, deleteNote, updateNote} from '../../actions/management_actions';
import enGB from "date-fns/locale/en-GB";
import bg from "date-fns/locale/bg";
import sq from "date-fns/locale/sq";
import {EditAppointmentModal} from "../appointment/AppointmentsManagement";
import {Permissions} from "../../utils/permissions/types";


class StaffAppointmentList extends Component {
    state = {
        type: "",
        AppointmentDate: new Date(),
        showAppointments: true,
        showNotes: false,
        selectedAppointment: {}
    }

    constructor(props) {
        super(props);
    }


    componentDidMount() {
        this.setState({
            AppointmentDate: new Date()
        });
    }

    getFilteredAppointments = () => {
        return this.props.appointments && this.props.appointments.length ? this.props.appointments.filter(appointment => {
            if (this.state.type && appointment.status !== this.state.type) {
                return false;
            }
            if (!this.state.searchValue) {
                return true;
            }
            for (let i = 0; i < appointment.participants.length; i++) {
                if (appointment.participants[i].participant_role === "PATIENT") {
                    return appointment.participants[i].fullname.toLowerCase().indexOf(this.state.searchValue.toLowerCase()) !== -1;
                }
            }
            return false;
        }) : [];
    }

    handleReset = () => {
        let date = new Date();

        this.setState({AppointmentDate: date});

        let start = startOfWeek(date);
        let end = endOfWeek(date);

        this.props.fetchAppointments(start, end);
    }

    onSelectChange = ({name, value}) => {
        const fields = Object.assign({}, this.state);
        fields[name] = value;
        this.setState(fields);
    };

    handleNextWeekClick = () => {
        let date = this.state.AppointmentDate;
        date = addDays(date, 7);

        this.setState({AppointmentDate: date});

        let start = startOfWeek(date);
        let end = endOfWeek(date);

        this.props.fetchAppointments(start, end);
    }

    handlePreviousWeekClick = () => {
        let date = this.state.AppointmentDate;
        date = subDays(date, 7);

        this.setState({AppointmentDate: date});

        let start = startOfWeek(date);
        let end = endOfWeek(date);

        this.props.fetchAppointments(start, end);
    }

    dateLabel = () => {
        let label = "";

        if (isThisWeek(this.state.AppointmentDate)) {
            label = $$('this_week');
        } else {
            let start = moment(startOfWeek(this.state.AppointmentDate)).format('DD MMM');
            let end = moment(endOfWeek(this.state.AppointmentDate)).format('DD MMM');

            label = start.toString() + " - " + end.toString()
        }

        return (
            <button onClick={this.handleReset} className='btn btn-secondary weeks-btn'>{label}</button>
        )
    }


    acceptAppointment = (id) => {
        this.props.updateAppointmentStatus(id, "ACCEPTED", null).then(() => {
            let start = startOfWeek(this.state.AppointmentDate);
            let end = endOfWeek(this.state.AppointmentDate);

            this.props.fetchAppointments(start, end);
        });
    }

    cancelAppointment = (id, status, note) => {
        this.props.updateAppointmentStatus(id, status, note).then(() => {
            let start = startOfWeek(this.state.AppointmentDate);
            let end = endOfWeek(this.state.AppointmentDate);
            this.props.fetchAppointments(start, end);
        });
    }


    markAppointmentAsPaid = (id) => {
        this.props.markAppointmentAsPaid(id);
    }

    onNotesClick = (appointment) => {
        this.setState({
            showAppointments: false, showNotes: true,
            selectedAppointment: appointment
        })
    }

    noAppointments = () => {
        let emptyStatsResultObj = {
            imgClass: 'no-lab-results-img',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: 'no-lab-results-secondary-label',
            src: no_data,
            primaryLabel: $$('no_results_found'),
            secondaryLabel: ''
        }
        return infoUtils.noData(emptyStatsResultObj);
    }

    getSelectedAppointment = () => {
        return this.props.appointments.find((element) => {
            return element.id === this.state.selectedAppointment.id;
        })
    }

    getSelectedAppointmentNotes = () => {
        let appointment = this.props.appointments.find((element) => {
            return element.id === this.state.selectedAppointment.id;
        })

        if (appointment) {
            let notes = [];

            for(let i in appointment.notes){
                let note = appointment.notes[i];
                for(let j in this.props.staffMembers){
                    if(note.owner_id === this.props.staffMembers[j].id){
                        note.owner_fullname = this.props.staffMembers[j].fullname
                    }
                }
                for(let j in this.props.internalStaffMembers){
                    if(note.owner_id === this.props.internalStaffMembers[j].id){
                        note.owner_fullname = this.props.internalStaffMembers[j].fullname
                    }
                }

                if(!note.owner_fullname) {
                    let patient = this.state.selectedAppointment.participants.find(p => p.participant_role === 'PATIENT')
                    if (patient.user_id === note.owner_id) {
                        note.owner_fullname = patient.fullname;
                    }
                }

                if(note.owner_fullname !== null){
                    notes.push(note)
                }
            }
            return notes;
        }
    }


    createNote = (selectedUserId, note, selectedAppointmentId, params) => {
        this.props.createNote(selectedUserId, note, selectedAppointmentId, params).then(
            () => {
                let start = startOfWeek(this.state.AppointmentDate);
                let end = endOfWeek(this.state.AppointmentDate);
                this.props.fetchAppointments(start, end);
            }
        );
    }

    deleteNote = (selectedUserId, selectedNoteId, selectedAppointmentId) => {
        this.props.deleteNote(selectedUserId, selectedNoteId, selectedAppointmentId).then(
            () => {
                let start = startOfWeek(this.state.AppointmentDate);
                let end = endOfWeek(this.state.AppointmentDate);
                this.props.fetchAppointments(start, end);
            }
        );
    }

    updateNote = (selectedUserId, note, selectedAppointmentId, params) => {
        this.props.updateNote(selectedUserId, note, selectedAppointmentId, params).then(
            () => {
                let start = startOfWeek(this.state.AppointmentDate);
                let end = endOfWeek(this.state.AppointmentDate);
                this.props.fetchAppointments(start, end);
            }
        );
    }

    getFilteredOptions = () => {
        return getResolvedOptions(APPOINTMENTS_FILTER.TYPE).filter(o => _.contains(["",
            "WAITING_FOR_DOCTOR_APPROVAL",
            "ACCEPTED",
            "COMPLETED",
            "CANCELED_BY_PATIENT",
            "CANCELED_BY_PROVIDER",
            "CANCELED_NOT_PAID",
            "CANCELED_RESPONSE_OVERDUE"
        ], o.value));
    }

    onEditAppointment = (appointment) => {
        this.setState({appointmentToEdit:appointment})
    }

    clearAppointmentToEdit = () => {
        this.setState({appointmentToEdit:undefined})
    }

    getLocale = () => {
        switch (this.props.i18n.selected.lang) {
            case "en":
                return enGB;
            case "bg":
                return bg;
            case "sq":
                return sq;
            default:
                return enGB;
        }
    }

    onEditAppointmentSave = (app, forceUpdateAppointment) => {
        return this.props.updateAppointment(app, forceUpdateAppointment).then(()=>{
            this.clearAppointmentToEdit();
            let start = startOfWeek(this.state.AppointmentDate);
            let end = endOfWeek(this.state.AppointmentDate);
            this.props.fetchAppointments(start, end);
        });
    }

    render() {
        return (
            <div>
                <div className={"appointment-containter"}>
                    {this.state.showAppointments &&
                        <>
                            <div className='row patient-view-form-container form-margin staff-appointments-filter'>
                                <Select
                                    name="type"
                                    options={this.getFilteredOptions()}
                                    value={this.state.type}
                                    onChange={this.onSelectChange}
                                    placeHolder={$$('document_type_label')}/>
                                <div className={"left-margin-ten"}/>
                                <div className="btn-group align-self-center" role="group" aria-label="Basic example">
                                    <button onClick={this.handlePreviousWeekClick} className='btn btn-secondary'><i
                                        className="fa fa-angle-left"/></button>
                                    {this.dateLabel()}
                                    <button onClick={this.handleNextWeekClick} className='btn btn-secondary'><i
                                        className="fa fa-angle-right"/></button>
                                </div>
                            </div>
                            {this.props.appointments.length > 0 ? <Appointments appointments={this.getFilteredAppointments()}
                                                                                i18n={this.props.i18n}
                                                                                onNotesClick={this.onNotesClick}
                                                                                staffPage={true}
                                                                                settings={this.props.settings}
                                                                                orgId={this.props.orgId}
                                                                                loggedInUserId={this.props.userId}
                                                                                loggedInUser={this.props.loggedInUser}
                                                                                permissions={this.props.permissions}
                                                                                customActions={<CustomActions
                                                                                    permissions={this.props.permissions}
                                                                                    loggedInUserId={this.props.userId}
                                                                                    onCancelAppointment={this.cancelAppointment}
                                                                                    onAcceptAppointment={this.acceptAppointment}
                                                                                    onMarkAppointmentAsPaid={this.markAppointmentAsPaid}
                                                                                    onEditAppointment={this.onEditAppointment}
                                                                                />}/>
                                :
                                this.noAppointments()
                            }</>
                    }

                    {this.state.showNotes && <>
                        <a onClick={() => {
                            this.setState({showAppointments: true, showNotes: false})
                        }}>  {$$("close_notes")}</a>
                        <br/>
                        <br/>
                        <br/>
                        <NotesTab userId={this.props.userId}
                                  selectedAppointment={this.getSelectedAppointment()}
                                  userAppointments={this.props.appointments}
                                  selectedUserId={this.state.selectedAppointment.appointment_price.provider_id}
                                  notes={this.getSelectedAppointmentNotes()}
                                  createNote={this.createNote}
                                  updateNote={this.updateNote}
                                  deleteNote={this.deleteNote}
                                  formDisabled={this.props.formDisabled}
                                  i18n={this.props.i18n.selected}/>
                    </>}

                    {this.state.appointmentToEdit && <EditAppointmentModal
                        appointment={this.state.appointmentToEdit}
                        onClose={()=>{this.clearAppointmentToEdit()}}
                        onSave={this.onEditAppointmentSave}
                        organisationName={this.props.organisationName}
                        locale={this.getLocale()}
                        permissions={this.props.permissions}
                        loggedInUserId={this.props.userId}
                    />}
                </div>
            </div>
        )
    }
}

StaffAppointmentList.propTypes = {
    appointments: PropTypes.array,
    updateAppointmentStatus: PropTypes.func,
    staffMembers: PropTypes.any,
    internalStaffMembers: PropTypes.any,
    fetchAppointments: PropTypes.func,
    createNote: PropTypes.func,
    markAppointmentAsPaid: PropTypes.func,
    updateNote: PropTypes.func,
    userId: PropTypes.string,
    deleteNote: PropTypes.func,
    i18n: PropTypes.any,
    settings: PropTypes.object,
    formDisabled: PropTypes.any

}

function mapStateToProps(state) {
    return {
        userId: state.userInfo.data.id,
        loggedInUser: state.userInfo.data,
        staffMembers: state.management.staff,
        internalStaffMembers: state.management.internalStaff,
        settings: state.settings,
        formDisabled: state.formInteractions,
        permissions: Permissions.fromOrg(state.userInfo.data.organizations.find(o => o.id === state.management.selectedOrganisation.id))
    }
}

const mapDispatchToProps = {
    updateAppointmentStatus,
    createNote,
    updateNote,
    deleteNote,
    markAppointmentAsPaid,
    updateAppointment
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffAppointmentList)
