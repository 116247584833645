import {
    CLEAR_ICD,
    SEARCH_ICD_ERROR,
    SEARCH_ICD_REQUEST_SENT,
    SEARCH_ICD_SUCCESS
} from './actions';
import {icdService} from '../service/icd_service';

/**
 * Search the ICDs.
 */
export function searchIcd(country, q) {
    return (dispatch) => {
        dispatch({type: SEARCH_ICD_REQUEST_SENT});
        return icdService.searchIcd(country, q).then((res) => {
            if (res) {
                dispatch({type: SEARCH_ICD_SUCCESS, result: res})
            }
        }).catch((err) => {
            dispatch({type: SEARCH_ICD_ERROR, result: err});
        })
    }
}

export function clearIcd() {
    return (dispatch) => {
        dispatch({type: CLEAR_ICD});
    }
}
