import {
    LOGOUT,
    UPDATE_CALL_DATA,
    UPDATE_CAMERA_DEVICES
} from "../actions/actions";
import {IDLE} from "../constants/call_status";

const initialState = {
    data: {
        callStatus: IDLE,
        videoSession: {},
        callerQBId: null,
        recipientQBId: null,
        startVideoCall: false,
        videoMessageId: null,
        userToChatFullName: null,
        videoMuted: false,
        audioMuted: false
    }
}

/**
 * Video reducer. Reduce state based on action type.
 *
 * @param {object} state the state of video
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
export function video(state = initialState, action) {
    switch (action.type) {
        case UPDATE_CALL_DATA: {
            let d = {...state.data, ...action.result};
            return {...state, data: d}
        }
        case LOGOUT: {
            return {...state, ...initialState};
        }
        default: {
            return state
        }
    }
}

const cameraInitialState = {list: []}

/**
 * Video reducer. Reduce state based on action type.
 *
 * @param {object} state the state of video
 * @param {action} action the action to execute on the state
 * @returns {data: {}} new state
 */
export function cameraDevices(state = cameraInitialState, action) {
    switch (action.type) {
        case UPDATE_CAMERA_DEVICES: {
            return {list: action.result}
        }
        case LOGOUT: {
            return {...cameraInitialState};
        }
        default: {
            return state
        }
    }
}