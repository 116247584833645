import React, { useState } from 'react';
import Snackbar from '../../Snackbar';
import useDevices from '../../hooks/useDevices/useDevices';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import {TwilioError} from "twilio-video";
import {$$} from "../../../../helpers/localization";

export function getSnackbarContent(hasAudio: boolean, hasVideo: boolean, error?: Error | TwilioError) {
  let headline = '';
  let message = '';
  switch (true) {
    // These custom errors are thrown by the useLocalTracks hook. They are thrown when the user explicitly denies
    // permission to only their camera, or only their microphone.
    case error?.message === 'CameraPermissionsDenied':
      headline = $$("camera_permissions_denied_heading");
      message = $$("camera_permissions_denied_message");
      break;
    case error?.message === 'MicrophonePermissionsDenied':
      headline = $$("microphone_permissions_denied_heading");
      message = $$("microphone_permissions_denied_message");
      break;

    // This error is emitted when the user or the user's system has denied permission to use the media devices
    case error?.name === 'NotAllowedError':
      headline = $$("not_allowed_error_heading");

      if (error!.message === 'Permission denied by system') {
        // Chrome only
        message = $$("not_allowed_error_message");
      } else {
        message = $$("not_allowed_error_message_alt");
      }

      break;

    // This error is emitted when input devices are not connected or disabled in the OS settings
    case error?.name === 'NotFoundError':
      headline = $$("not_found_error_heading");
      message = $$("not_found_error_message");
      break;

    // In p2p connections this error can occur when the connection between the parties is lost due to network inactivity or crashing.
    // It also occurs when the other party does not disconnect from the room.
    case error?.code === 53405:
      headline = $$("connection_error_heading");
      message = $$("connection_error_message")
      break;

    // Other getUserMedia errors are less likely to happen in this app. Here we will display
    // the system's error message directly to the user.
    case Boolean(error):
      headline = $$("error_acquiring_media_heading");
      message = `${error!.name} ${error!.message}`;
      break;

    case !hasAudio && !hasVideo:
      headline = $$("no_camera_or_microphone_detected_heading")
      message = $$("no_camera_or_microphone_detected_message");
      break;

    case !hasVideo:
      headline = $$("no_camera_detected_heading");
      message = $$("no_camera_detected_message");
      break;

    case !hasAudio:
      headline = $$("no_microphone_detected_heading");
      message = $$("no_microphone_detected_message");
  }

  return {
    headline,
    message,
  };
}

export default function MediaErrorSnackbar({ error }: { error?: Error }) {
  const { hasAudioInputDevices, hasVideoInputDevices } = useDevices();

  const { isAcquiringLocalTracks } = useVideoContext();

  const [isSnackbarDismissed, setIsSnackbarDismissed] = useState(false);

  const isSnackbarOpen =
    !isSnackbarDismissed &&
    !isAcquiringLocalTracks &&
    (Boolean(error) || !hasAudioInputDevices || !hasVideoInputDevices);

  const { headline, message } = getSnackbarContent(hasAudioInputDevices, hasVideoInputDevices, error);

  //console.log("Error", error);
  //console.log("isSnackbarOpen", isSnackbarOpen);
  //console.log("Boolean(error)", Boolean(error));

  return (
    <Snackbar
      open={isSnackbarOpen}
      handleClose={() => setIsSnackbarDismissed(true)}
      headline={headline}
      message={message}
      variant="warning"
    />
  );
}
