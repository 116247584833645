import React, {createContext, ReactNode, useCallback, useEffect, useState} from 'react';
import {
    CreateLocalTrackOptions,
    ConnectOptions,
    LocalAudioTrack,
    LocalVideoTrack,
    Room,
    TwilioError
} from 'twilio-video';
import { ErrorCallback } from '../types';
import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant';

import AttachVisibilityHandler from './AttachVisibilityHandler/AttachVisibilityHandler';
import useHandleRoomDisconnection from './useHandleRoomDisconnection/useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks/useLocalTracks';
import useRestartAudioTrackOnDeviceChange from './useRestartAudioTrackOnDeviceChange/useRestartAudioTrackOnDeviceChange';
import useRoom from './useRoom/useRoom';
import {VideoManager} from "../video_manager";
import {chatService} from "../../../service/chat_service";
import moment from "moment";
import {COMPLETED} from "../../../constants/video_message_status";

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
  room: Room | null;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  onError: ErrorCallback;
  getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  removeLocalAudioTrack: () => void;
  getAudioAndVideoTracks: () => Promise<void>;
  error:TwilioError | Error;
  setError: (error?: TwilioError | Error) => void;
  dominantSpeakerMode: boolean,
  setDominantSpeakerMode: (boolean) => void;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  children: ReactNode;
}

export function VideoProvider({ options, children, onError = () => {} }: VideoProviderProps) {
  const [error, setError] = useState<TwilioError | Error>();


  const onErrorCallback: ErrorCallback = useCallback(
    error => {
      setError(error)
      console.log(`ERROR: ${error.message}`, error);
      onError(error);
    },
    [onError]
  );

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, setError, options);

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onErrorCallback,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
  );
  useHandleTrackPublicationFailed(room, onError);
  useRestartAudioTrackOnDeviceChange(localTracks);
  useUpdateServerOnDisconnect(room);

  const [dominantSpeakerMode, setDominantSpeakerMode] = useState<Boolean>(false);

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        removeLocalAudioTrack,
        getAudioAndVideoTracks,
        error,
        setError,
        dominantSpeakerMode,
        setDominantSpeakerMode
      }}
    >
      <SelectedParticipantProvider room={room}>{children}</SelectedParticipantProvider>
      {/* 
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}

function useUpdateServerOnDisconnect(room: Room | null) {
    useEffect(() => {
        if (room) {
            const onDisconnected = (_: Room, error: TwilioError) => {
                //console.log("Updating the video messages and closing the room due to disconnect")
                let messageId = VideoManager.state.mId;
                let messagesIds = VideoManager.state.mIds;
                let roomId = room.name;
                if (messageId) {
                    chatService.updateVideoCallDurationAndStatus(messageId, moment(new Date()).diff(room.started), COMPLETED);
                } else if (messagesIds && messagesIds.length > 0) {
                    let duration = moment(new Date()).diff(room.started);
                    for (let i = 0; i < messagesIds.length; i++) {
                        const mId = messagesIds[i];
                        chatService.updateVideoCallDurationAndStatus(mId, duration, COMPLETED);
                    }
                }
                chatService.closeVideoRoom(roomId);
            };

            room.on('disconnected', onDisconnected);
            return () => {
                room.off('disconnected', onDisconnected);
            };
        }
    }, [room]);
}
