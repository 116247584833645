import React, {Component} from "react";
import {$$, _$$} from "../../helpers/localization";
import FormLoading from "../shared/FormLoading";
import {PRICES} from "../../actions/actions";
import PropTypes from "prop-types";
import {usersService} from "../../service/users_service";
import classNames from "classnames";
import {InformationTooltip} from "../common/tooltips";


export class EditConsultationOptionPopup extends Component {


    constructor(props) {
        super(props);
        this.state = {
            organisations: this.props.organisationNames,
            currencies: [
                {name: $$('bgn') + " - (лв)", value: "BGN"},
                {name: $$('euro') + " - (€)", value: "EUR"},
                {name: $$('lek') + " - (L)", value: "ALL"},
                {name: $$('gbp') + " - (£)", value: "GBP"},
                {name: $$('usd') + " - ($)", value: "USD"}
            ],
            encounter_types: [
                {name: $$('primary_exam'), value: "PRIMARY_EXAM"},
                {name: $$('follow_up_exam'), value: "FOLLOW_UP_EXAM"},
                {name: $$('other'), value: "OTHER"}
            ],
            location_types: [{name: $$('online'), value: "ONLINE"},
                {name: $$('on_site'), value: "ON_SITE"}],
            billing_types: [{name: $$('billing_type_online'), value: "ONLINE"},
                {name: $$('billing_type_on_site'), value: "ONSITE"},
                {name: $$('billing_type_online_and_on_site'), value: "ONLINE_AND_ONSITE"}
            ],
            encounter_type: this.props.selectedMenuItem.encounter_type,
            location_type: this.props.selectedMenuItem.location_type,
            currency: this.props.selectedMenuItem.currency,
            independent_timetable: this.props.selectedMenuItem.independent_timetable,
            price: (this.props.selectedMenuItem.price_cents / 100).toFixed(2),
            name: this.props.selectedMenuItem.name !== null ? this.props.selectedMenuItem.name : "",
            duration_mins: this.props.selectedMenuItem.duration_mins,
            description: this.props.selectedMenuItem.description || "",
            disclaimer: this.props.selectedMenuItem.disclaimer || "",
            private_price: this.props.selectedMenuItem.private_price || false,
            durations: [
                {name: '15:00', value: 15},
                {name: '20:00', value: 20},
                {name: '30:00', value: 30},
                {name: '40:00', value: 40},
                {name: '45:00', value: 45},
                {name: '60:00', value: 60},
                {name: '90:00', value: 90},
                {name: '120:00', value: 120},
            ],
            error: false,
            organization_id: this.props.selectedMenuItem.organization_id !== null ? this.props.selectedMenuItem.organization_id : "",
            questionnaire_id: this.props.selectedMenuItem.questionnaire_id !== null ? this.props.selectedMenuItem.questionnaire_id : "none",
            encounter_price_type: this.props.selectedMenuItem.encounter_price_type,
            averageResponseTimeHours: this.props.selectedMenuItem.average_response_time_hours,
            openedCommunicationDuration: this.props.selectedMenuItem.opened_communication_duration_days,
            allow_video_for_on_site: this.props.selectedMenuItem.allow_video_for_on_site,
            billing_type: this.props.selectedMenuItem.billing_type || 'ONSITE',
            appointment_price_type: this.props.selectedMenuItem.price_cents > 0 ? "PAID" : "FREE",
            appointment_price_types: [{name: $$('paid_label'), value: "PAID"},
                {name: $$('free_label'), value: "FREE"}],
            order_index: this.props.selectedMenuItem.order_index || 100
        };

        if (!this.state.organization_id) {
            this.state.organization_id = this.state.organisations[0].value;
        }

    }

    canEnableVideoForOnSite = () => {
        if (!this.state.organization_id) {
            return false;
        }
        return this.props.organisations.find(org => org.id == this.state.organization_id).can_enable_video_for_on_site_appointments;
    }

    canEnableBillingTypeForOnSite = () => {
        if (!this.state.organization_id) {
            return false;
        }
        return this.props.organisations.find(org => org.id == this.state.organization_id).enable_payments_for_on_site_appointments;
    }

    isOnSite = () => {
        return this.state.location_type === 'ON_SITE';
    }

    showEnableVideoForOnSite = () => {
        return this.isOnSite() && this.canEnableVideoForOnSite();
    }

    showBillingTypeForOnSite = () => {
        return this.isOnSite() && this.canEnableBillingTypeForOnSite() && this.state.appointment_price_type === 'PAID';
    }

    disabledVideoForOnSite = () => {
        return this.state.billing_type !== 'ONLINE' && this.state.appointment_price_type === 'PAID';
    }


    closeEditPopup = () => {
        this.props.closePopup();
    }

    componentDidMount() {
        this.fetchQuestionnaireForOrgAndUpdateState(this.state.organization_id);
    }

    fetchQuestionnaireForOrgAndUpdateState = (orgId) => {
        if (orgId) {
            usersService.fetchQuestionnairesForOrg(orgId)
                .then(r => {
                    if (this.state.organization_id === orgId) {
                        let state = {questionnaire: r};
                        this.setState(state);
                    }
                })
        }
    }


    handleSubmit = () => {

        let hasError = false;
        if (this.state.encounter_price_type === 'TEXT_ONLY' && this.state.averageResponseTimeHours < 1 || this.state.averageResponseTimeHours > 720 || !Number.isInteger(+this.state.averageResponseTimeHours)) {
            this.setState({averageResponseTimeHoursInvalid: true})
            hasError = true;
        }

        if (this.state.encounter_price_type === 'TEXT_ONLY' && this.state.openedCommunicationDuration < 3 || this.state.openedCommunicationDuration > 30 || !Number.isInteger(+this.state.openedCommunicationDuration)) {
            this.setState({openedCommunicationDurationInvalid: true})
            hasError = true;
        }

        if (this.state.appointment_price_type === 'PAID' && !(this.state.price > 0)) {
            this.setState({invalidPrice: true})
            hasError = true;
        }

        if (!Number.isInteger(+this.state.order_index)) {
            this.setState({orderIndexInvalid: true});
            hasError = true;
        }

        if (hasError) {
            return true;
        }

        let form = {
            id: this.props.selectedMenuItem.id,
            provider_id: this.props.selectedMenuItem.provider_id,
            organization_id: this.state.organization_id,
            currency: this.state.currency,
            encounter_type: this.state.encounter_type,
            location_type: this.state.encounter_price_type === 'TEXT_ONLY' ? 'ONLINE' : this.state.location_type,
            name: this.state.encounter_type === "OTHER" ? this.state.name : "",
            price_cents: this.state.price < 0 ? 0 : this.state.price * 100,
            duration_mins: this.state.duration_mins,
            questionnaire_id: this.state.questionnaire_id === "none" ? null : this.state.questionnaire_id,
            encounter_price_type: this.state.encounter_price_type,
            description: this.state.description,
            independent_timetable: this.state.encounter_price_type === "REGULAR" ? this.state.independent_timetable : false,
            disclaimer: this.state.disclaimer,
            private_price: this.state.private_price,
            average_response_time_hours: this.state.encounter_price_type === 'TEXT_ONLY' ? this.state.averageResponseTimeHours : null,
            opened_communication_duration_days: this.state.encounter_price_type === 'TEXT_ONLY' ? this.state.openedCommunicationDuration : null,
            allow_video_for_on_site: this.state.encounter_price_type === "REGULAR" && this.showEnableVideoForOnSite() ? this.state.allow_video_for_on_site : false,
            billing_type: this.state.encounter_price_type === "REGULAR" && this.showBillingTypeForOnSite() ? this.state.billing_type : (this.isOnSite() ? "ONSITE" : "ONLINE")
        }

        if (this.state.order_index) {
            form.order_index = this.state.order_index;
        }

        if (this.state.encounter_type === "OTHER" && this.state.name === "") {
            this.setState({error: true})
        } else {
            this.props.upDateMenuOption(form)
            this.props.closePopup();
        }
    }

    createOption = option => (
        <option
            value={option.value}
            key={option.value}
        >
            {option.name}
        </option>
    );

    getOptions = (options) => {
        let values = [];
        for (let i in options) {
            let value = {name: options[i].name, value: options[i].value}
            values.push(value);
        }
        return values;
    }

    handleExamTypeChange(e) {
        this.setState({encounter_type: e.target.value});
    }

    handleLocationChange(e) {
        this.setState({location_type: e.target.value});
        if (e.target.value === 'ONLINE') {
            this.setState({allow_video_for_on_site: false, billing_type: 'ONLINE'})
        }
    }

    handleBillingTypeChange(e) {
        this.setState({billing_type: e.target.value})
        if (this.canEnableVideoForOnSite()) {
            if (e.target.value !== 'ONLINE' && this.state.appointment_price_type === 'PAID') {
                this.setState({allow_video_for_on_site: false})
            }
        }
    }

    handleAppointmentPriceTypeChange(e) {
        let appointmentPriceType = e.target.value;
        this.setState({appointment_price_type: appointmentPriceType})
        if (appointmentPriceType === 'FREE') {
            this.setState({price: 0, invalidPrice: false})
        } else {
            this.setState({allow_video_for_on_site: false})
        }
    }

    handleCurrencyChange(e) {
        this.setState({currency: e.target.value});
    }

    handleOrganisationChange(e) {
        this.setState({organization_id: e.target.value, questionnaire_id: "none"},
            () => this.fetchQuestionnaireForOrgAndUpdateState(this.state.organization_id));
    }

    handleDurationChanged(e) {
        this.setState({duration_mins: e.target.value});
    }

    handleIndependentTimetableChanged() {
        this.setState({independent_timetable: !this.state.independent_timetable});
    }

    handleQuestionnaireChange(e) {
        this.setState({questionnaire_id: e.target.value})
    }

    handleDescriptionChange(e) {
        this.setState({description: e.target.value})
    }

    handleDisclaimerChange(e) {
        this.setState({disclaimer: e.target.value})
    }

    handlePriceChange(e) {
        if (!isNaN(e.target.value)) {
            let price = e.target.value

            if (price > 9999999.99) {
                price = 9999999.99;
            }

            if (price < 0) {
                price = 0;
            }

            this.setState({price: price, invalidPrice: false});
            if (this.canEnableBillingTypeForOnSite()) {
                if (this.state.billing_type !== 'ONLINE' && price > 0) {
                    this.setState({allow_video_for_on_site: false})
                }
            }
        }
    }

    handleOpenedCommunicationDurationChange(e) {
        if (!isNaN(e.target.value)) {
            let openedCommunicationDuration = e.target.value


            this.setState({
                openedCommunicationDuration: openedCommunicationDuration,
                openedCommunicationDurationInvalid: openedCommunicationDuration < 3 || openedCommunicationDuration > 30 || !Number.isInteger(+openedCommunicationDuration)
            });
        }
    }

    handleAverageResponseTimeChange(e) {
        if (!isNaN(e.target.value)) {
            let averageResponseTimeHours = e.target.value


            this.setState({
                averageResponseTimeHours: averageResponseTimeHours,
                averageResponseTimeHoursInvalid: averageResponseTimeHours < 1 || averageResponseTimeHours > 720 || !Number.isInteger(+averageResponseTimeHours)
            });
        }
    }

    handleOrderIndexChange(e) {
        if (!isNaN(e.target.value)) {

            let order_index = e.target.value;
            if (order_index < 1) {
                order_index = 1;
            }
            this.setState({order_index: order_index, orderIndexInvalid: !Number.isInteger(+order_index)});
        }
    }

    handleNameChange(e) {
        this.setState({name: e.target.value});
    }

    isSubmitDisabled = () => {
        if (this.state.encounter_type !== $$('other')) {
            return false;
        } else return this.state.name === "";
    }

    error = () => {
        if (this.state.error) {
            return " custom-error"
        }
        return ""
    }

    createCurrencyOptions = () => this.getOptions(this.state.currencies).map(this.createOption);
    createEncounterTypeOptions = () => this.getOptions(this.state.encounter_types).map(this.createOption);
    createLocationTypeOptions = () => this.getOptions(this.state.location_types).map(this.createOption);
    createOrganisationOptions = () => this.getOptions(this.state.organisations).map(this.createOption);
    createDurationOptions = () => this.getOptions(this.state.durations).map(this.createOption);
    createQuestionnaireTypeOptions = () => this.getOptions(this.state.questionnaire.map(q => {
        return {name: this.getQTitle(q.questionnaire.title), value: q.id}
    })).map(this.createOption);
    createBillingTypeOptions = () => this.getOptions(this.state.billing_types).map(this.createOption);
    createAppointmentPriceTypeOptions = () => this.getOptions(this.state.appointment_price_types).map(this.createOption);

    getQTitle = (title) => {
        if (!title[this.props.lang]) {
            return title[0];
        }
        return title[this.props.lang];
    }

    onChangePrivatePrice = () => {
        this.setState({private_price: !this.state.private_price});
    }

    onChangeAllowVideoForOnSite = () => {
        this.setState({allow_video_for_on_site: !this.state.allow_video_for_on_site})
    }

    render() {
        return (
            <div className="consultation-option">
                <span className="day-picker-popup-line h4">
                  {this.props.selectedMenuItem.encounter_price_type === 'TEXT_ONLY' ? $$('edit_consultation_type') : $$('edit_appointment_type')}
                    &nbsp;

                </span>
                {
                    this.state.encounter_price_type === 'GROUP_EVENT' && <span className="day-picker-popup-line">
                        <span>
                            {$$('group_appointment')}
                            &nbsp;
                            <InformationTooltip>
                             {$$('group_appointment_info_text')}
                         </InformationTooltip>
                        </span>

                    </span>
                }
                {!this.props.hideOrganisation && <span className="day-picker-popup-line">
                        <label htmlFor="Provider">{$$('organisation')}:</label>
                        <select id="Provider"
                                className="custom-select calendar-select"
                                onChange={(e) => this.handleOrganisationChange(e)}
                                defaultValue={this.state.organization_id}
                        >
                            {this.createOrganisationOptions()}
                        </select>
                </span>}
                <span className="day-picker-popup-line">
                        <label htmlFor="encounter_type">{$$('exam_type')}:</label>
                        <select id="encounter_type"
                                className="custom-select calendar-select"
                                onChange={(e) => this.handleExamTypeChange(e)}
                                defaultValue={this.state.encounter_type}
                        >
                            {this.createEncounterTypeOptions()}
                        </select>
                </span>

                {this.state.encounter_type === "OTHER" && <span className="day-picker-popup-line">
                         <label htmlFor="price">{$$('name')}:</label>
                            <input className={"custom-select custom-input calendar-select" + this.error()}
                                   type="text"
                                   id="name"
                                   name="name"
                                   required={true}
                                   value={this.state.name}
                                   onChange={(e) => this.handleNameChange(e)}
                            />
                            <div className={this.state.error ? "custom-invalid-feedback" : "invalid-feedback"}>
                                {$$('name_required_label')}
                            </div>
                    </span>
                }
                <span className="day-picker-popup-line custom-control custom-checkbox justify-content-end">
                     <input className="custom-control-input" type="checkbox" name="private_price"
                            style={{cursor: "pointer"}}
                            checked={this.state.private_price}
                            onChange={this.onChangePrivatePrice}
                     />
                     <label className="custom-control-label" htmlFor="private_price"
                            style={{cursor: "pointer"}}
                            onClick={this.onChangePrivatePrice}
                     >
                         {$$('private_price_input_label')}
                         &nbsp;
                         <InformationTooltip>
                             {$$('private_price_info_text')}
                         </InformationTooltip>
                     </label>
                </span>
                {this.state.questionnaire?.length > 0 &&
                    <span className="day-picker-popup-line">
                            <label htmlFor="questionnaire">{$$('questionnaire')}:</label>
                            <select id="questionnaire"
                                    className="custom-select calendar-select"
                                    value={this.state.questionnaire_id}
                                    onChange={(e) => this.handleQuestionnaireChange(e)}>
                                <option value="none">{$$("None")}</option>
                                {this.createQuestionnaireTypeOptions()}
                            </select>
                    </span>
                }

                {this.props.selectedMenuItem.encounter_price_type !== 'TEXT_ONLY' &&
                    this.props.selectedMenuItem.encounter_price_type !== 'GROUP_EVENT' &&
                    <span className="day-picker-popup-line">
                        <label htmlFor="location_type">{$$('location')}:</label>
                        <select id="location_type"
                                className="custom-select calendar-select"
                                onChange={(e) => this.handleLocationChange(e)}
                                defaultValue={this.state.location_type}
                        >
                            {this.createLocationTypeOptions()}
                        </select>
                </span>}
                <span className="day-picker-popup-line">
                        <label htmlFor="appointment_price_type">{$$('appointment_label')}:</label>
                        <select id="appointment_price_type"
                                value={this.state.appointment_price_type}
                                className="custom-select calendar-select"
                                onChange={(e) => this.handleAppointmentPriceTypeChange(e)}>
                            {this.createAppointmentPriceTypeOptions()}
                        </select>
                </span>
                {this.state.appointment_price_type === 'PAID' &&
                    <div className="day-picker-popup-line flex-row">
                        <span className="mr-2 w-100">
                                 <label htmlFor="price">{$$('price')}:</label>
                                 <input
                                     className={classNames("custom-select custom-input calendar-select", {"custom-error": this.state.invalidPrice})}
                                     type="text"
                                     id="price"
                                     name="price"
                                     value={this.state.price}
                                     onChange={(e) => this.handlePriceChange(e)}
                                 />
                            </span>
                        <span className="ml-2 w-100">
                                <label htmlFor="currency">{$$('currency')}:</label>
                            <select id="currency"
                                    className="custom-select calendar-select"
                                    onChange={(e) => this.handleCurrencyChange(e)}>
                                    value={this.state.currency}
                                {this.createCurrencyOptions()}
                                </select>
                        </span>
                    </div>
                }
                {this.showBillingTypeForOnSite() && <span className="day-picker-popup-line">
                        <label htmlFor="billing_type">{$$('payment_location_label')}
                            &nbsp;
                            <InformationTooltip>
                                {$$('payment_info_text')}
                            </InformationTooltip>
                            :</label>
                        <select id="billing_type"
                                value={this.state.billing_type}
                                className="custom-select calendar-select"
                                onChange={(e) => this.handleBillingTypeChange(e)}>
                            {this.createBillingTypeOptions()}
                        </select>
                </span>}
                {this.showEnableVideoForOnSite() &&
                    <span className="day-picker-popup-line custom-control custom-checkbox justify-content-end">
                     <input className="custom-control-input" type="checkbox" name="allow_video_for_on_site"
                            style={{cursor: "pointer"}}
                            disabled={this.disabledVideoForOnSite()}
                            checked={this.state.allow_video_for_on_site}
                            onChange={this.onChangeAllowVideoForOnSite}
                     />
                     <label className="custom-control-label" htmlFor="allow_video_for_on_site"
                            style={{cursor: "pointer"}}
                            onClick={this.onChangeAllowVideoForOnSite}
                     >
                         {$$('allow_video_for_on_site_input_label')}
                         &nbsp;
                         <InformationTooltip>
                             {$$('allow_switch_to_online_info_text')}
                         </InformationTooltip>
                     </label>
                </span>}
                {this.props.selectedMenuItem.encounter_price_type !== 'TEXT_ONLY' &&
                    <span className="day-picker-popup-line">
                        <label htmlFor="duration_mins">{$$('duration')}:</label>
                    <select id="duration"
                            className="custom-select calendar-select"
                            onChange={(e) => this.handleDurationChanged(e)}
                            defaultValue={this.state.duration_mins}>
                            {this.createDurationOptions()}
                        </select>
                </span>}

                {this.state.encounter_price_type === "REGULAR" &&
                    <span className="day-picker-popup-line custom-control custom-checkbox justify-content-end">
                     <input className="custom-control-input" type="checkbox" name="is_independent_timetable"
                            checked={this.state.independent_timetable}
                            style={{cursor: "pointer"}}
                            onChange={() => {
                                this.handleIndependentTimetableChanged(!this.state.independent_timetable)
                            }}
                     />
                     <label className="custom-control-label" htmlFor="is_independent_timetable"
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                this.handleIndependentTimetableChanged(!this.state.independent_timetable)
                            }}>
                         {$$('independent_timetable')}
                         &nbsp;
                         <InformationTooltip>
                             {$$('independent_schedule_info_text')}
                         </InformationTooltip>
                     </label>
                </span>}

                {this.props.selectedMenuItem.encounter_price_type === 'TEXT_ONLY' &&
                    <span className="day-picker-popup-line">
                         <label htmlFor="openedCommunicationDuration">{$$('opened_communication_duration')}:</label>
                         <span
                             className={"medrec-grey-2 font-size-smaller"}> {_$$("range_restriction", "3", "30")}</span>
                         <input
                             className={classNames("custom-select custom-input calendar-select", {"custom-error": this.state.openedCommunicationDurationInvalid})}
                             type="text"
                             id="openedCommunicationDuration"
                             name="openedCommunicationDuration"
                             value={this.state.openedCommunicationDuration}
                             onChange={(e) => this.handleOpenedCommunicationDurationChange(e)}
                         />
                </span>}

                {this.props.selectedMenuItem.encounter_price_type === 'TEXT_ONLY' &&
                    <span className="day-picker-popup-line">
                         <label htmlFor="average-response-time">{$$('average_response_time_hours')}:</label>
                         <span
                             className={"medrec-grey-2 font-size-smaller"}> {_$$("range_restriction", "1", "720")}</span>
                         <input
                             className={classNames("custom-select custom-input calendar-select", {"custom-error": this.state.averageResponseTimeHoursInvalid})}
                             type="text"
                             id="average-response-time"
                             name="average-response-time"
                             value={this.state.averageResponseTimeHours}
                             onChange={(e) => this.handleAverageResponseTimeChange(e)}
                         />
                </span>}
                <span className="day-picker-popup-line">
                         <label htmlFor="order_index">{$$('order_index_label')}:</label>
                         <input
                             className={classNames("custom-select custom-input calendar-select", {"custom-error": this.state.orderIndexInvalid})}
                             type="text"
                             id="order_index"
                             name="order_index"
                             value={this.state.order_index}
                             onChange={(e) => this.handleOrderIndexChange(e)}
                         />
                </span>
                <span className="day-picker-popup-line">
                         <label htmlFor="description">{$$('description')}:</label>
                         <textarea className="custom-select custom-input calendar-select custom-textarea"
                                   id="description"
                                   name="description"
                                   value={this.state.description}
                                   onChange={(e) => this.handleDescriptionChange(e)}
                         />
                    </span>
                <span className="day-picker-popup-line">
                         <label htmlFor="disclaimer">{$$('disclaimer')}:</label>
                         <textarea className="custom-select custom-input calendar-select custom-textarea"
                                   id="disclaimer"
                                   name="disclaimer"
                                   value={this.state.disclaimer}
                                   onChange={(e) => this.handleDisclaimerChange(e)}
                         />
                    </span>

                <span className="day-picker-popup-line-end">
                    <input
                        type="button"
                        className="btn btn-secondary date-picker-button"
                        value={$$("cancel")}
                        onClick={this.closeEditPopup}
                    />
                    <input
                        type="button"
                        className="btn btn-primary date-picker-button"
                        value={$$("update")}
                        onClick={this.handleSubmit}
                        disabled={this.isSubmitDisabled()}
                    />
                </span>
                <FormLoading
                    formDisabled={this.props.formDisabled}
                    currentForm={PRICES}
                    marginTop="calc(50% - 70px)"
                    marginLeft="calc(50% - 70px)"
                />
            </div>
        )
    }
}

EditConsultationOptionPopup.propTypes = {
    closePopup: PropTypes.func,
    formDisabled: PropTypes.any,
    lang: PropTypes.any,
    organisationNames: PropTypes.array,
    selectedMenuItem: PropTypes.object,
    hideOrganisation: PropTypes.bool,
    upDateMenuOption: PropTypes.func
}

export default EditConsultationOptionPopup;
