import React, {Component} from 'react'
import {$$} from '../../helpers/localization'
import {
    BLOOD_PRESSURE,
    BLOOD_SUGAR,
    CHOLESTEROL,
    HBA1C,
    HEIGHT,
    HYDRATION,
    KETONES,
    MEDICATION,
    MENSTRUATION,
    RESPIRATORY_RATE,
    SATURATION,
    TEMPERATURE,
    URINE_PH,
    WEIGHT,
    PEAK_FLOW
} from '../../constants/entry_types'
import WeightEntry from './vitals-logbook-entries/WeightEntry'
import CholesterolEntry from './vitals-logbook-entries/CholesterolEntry'
import BloodPressureEntry from './vitals-logbook-entries/BloodPressureEntry'
import MedicationEntry from './vitals-logbook-entries/MedicationEntry'
import HydrationEntry from './vitals-logbook-entries/HydrationEntry'
import TemperatureEntry from './vitals-logbook-entries/TemperatureEntry'
import RespiratoryRateEntry from './vitals-logbook-entries/RespiratoryRateEntry'
import SaturationEntry from './vitals-logbook-entries/SaturationEntry'
import MenstruationEntity from './vitals-logbook-entries/MenstruationEntity'
import HeightEntry from './vitals-logbook-entries/HeightEntry'
import UrinePHEntry from './vitals-logbook-entries/UrinePHEntry'
import KetonesEntry from './vitals-logbook-entries/KetonesEntry'
import HBA1CEntry from './vitals-logbook-entries/HBA1CEntry'
import BloodSugarEntry from './vitals-logbook-entries/BloodSugarEntry'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faCapsules,
    faHeart,
    faHeartbeat,
    faTemperatureLow,
    faTint,
    faWeight,
    faWind
} from '@fortawesome/free-solid-svg-icons'
import {ReactComponent as BloodSugarIcon} from '../../../public/media/icons/blood_sugar.svg'
import {ReactComponent as HBA1CIcon} from '../../../public/media/icons/hba1c.svg'
import {ReactComponent as HeightIcon} from '../../../public/media/icons/height.svg'
import {ReactComponent as MenstruationIcon} from '../../../public/media/icons/menstruation.svg'
import {ReactComponent as KetonesIcon} from '../../../public/media/icons/ketones.svg'
import {ReactComponent as UrinepH} from '../../../public/media/icons/urine_pH.svg'
import PropTypes from "prop-types";
import {PeakFlowIcon} from "./vitals-logbook-entries/PeakFlowIcon";
import PeakFlowEntry from "./vitals-logbook-entries/PeakFlowEntry";

export class GroupedLogbookEntry extends Component {
    constructor(props) {
        super(props);
        this.index = 0;
    }

    /**
     * Prepares the logbook entries in order to have them ready for display
     *
     * @returns {HTMLElement} the logbook entry
     */
    getGroupedLogbookEntry = () => {
        let logbookEntries = [];
        let title = '';
        let icon = null;

        for (let i = 0; i < this.props.entries.length; ++i) {
            let result = this.getLogbookEntry(this.props.type, this.props.entries[i]);
            icon = result.icon;
            title = result.title;
            logbookEntries.push(result.entry);
        }

        return this.getGroupedLogbookEntryElement(icon, title, logbookEntries);
    }

    /**
     * Creates the logbook measurement entry
     *
     * @param {string} type - the logbook type
     * @param {string} value - the measurement value
     * @returns {object} the logbook measurement entry
     */
    getLogbookEntry = (type, value) => {
        let logbookEntry = {};
        let medrec_red = '#FF6475';

        switch (type) {
            case WEIGHT: {
                logbookEntry.title = $$('Weight');
                logbookEntry.icon = <FontAwesomeIcon icon={faWeight} className='vitals-logbook-icon-label font-awesome-logbook-icon'/>
                logbookEntry.entry =
                    <WeightEntry entry={value} key={++this.index} unit={this.props.settings.data.unitsOfMeasurement}/>
                return logbookEntry;
            }
            case CHOLESTEROL: {
                logbookEntry.title = $$('Cholesterol');
                logbookEntry.icon = <FontAwesomeIcon icon={faHeart} className='vitals-logbook-icon-label font-awesome-logbook-icon'/>
                logbookEntry.entry =
                    <CholesterolEntry entry={value} key={++this.index} unit={this.props.settings.data.cholesterolUnit}/>
                return logbookEntry;
            }
            case BLOOD_PRESSURE: {
                logbookEntry.title = $$('blood_pressure');
                logbookEntry.icon = <FontAwesomeIcon icon={faHeartbeat} className='vitals-logbook-icon-label font-awesome-logbook-icon'/>
                logbookEntry.entry = <BloodPressureEntry entry={value} key={++this.index}/>
                return logbookEntry;
            }
            case MEDICATION: {
                logbookEntry.title = $$('Medications');
                logbookEntry.icon = <FontAwesomeIcon icon={faCapsules} className='vitals-logbook-icon-label font-awesome-logbook-icon'/>
                logbookEntry.entry = <MedicationEntry entry={value} key={++this.index}/>
                return logbookEntry;
            }
            case HYDRATION: {
                logbookEntry.title = $$('Hydration');
                logbookEntry.icon = <FontAwesomeIcon icon={faTint} className='vitals-logbook-icon-label font-awesome-logbook-icon'/>
                logbookEntry.entry = <HydrationEntry entry={value} key={++this.index}/>
                return logbookEntry;
            }
            case TEMPERATURE: {
                logbookEntry.title = $$('Temperature');
                logbookEntry.icon = <FontAwesomeIcon icon={faTemperatureLow} className='vitals-logbook-icon-label font-awesome-logbook-icon'/>
                logbookEntry.entry = <TemperatureEntry entry={value} key={++this.index}/>
                return logbookEntry;
            }
            case RESPIRATORY_RATE: {
                logbookEntry.title = $$('Respiratory_rate');
                logbookEntry.icon = <FontAwesomeIcon icon={faWind} className='vitals-logbook-icon-label font-awesome-logbook-icon'/>
                logbookEntry.entry = <RespiratoryRateEntry entry={value} key={++this.index}/>
                return logbookEntry;
            }
            case SATURATION: {
                logbookEntry.title = $$('Saturation');
                logbookEntry.icon = <span className='vitals-logbook-icon-label'><b>O<sub>2</sub></b></span>
                logbookEntry.entry = <SaturationEntry entry={value} key={++this.index}/>
                return logbookEntry;
            }
            case MENSTRUATION: {
                logbookEntry.title = $$('Menstruation');
                logbookEntry.icon = <MenstruationIcon fill={medrec_red} className='vitals-logbook-svg-icon-label'/>
                logbookEntry.entry = <MenstruationEntity entry={value} key={++this.index}/>
                return logbookEntry;
            }
            case HEIGHT: {
                logbookEntry.title = $$('Height');
                logbookEntry.icon = <HeightIcon fill={medrec_red} className='vitals-logbook-svg-icon-label'/>
                logbookEntry.entry = <HeightEntry entry={value} key={++this.index}/>
                return logbookEntry;
            }
            case URINE_PH: {
                logbookEntry.title = $$('Urine_pH');
                logbookEntry.icon = <UrinepH fill={medrec_red} className='vitals-logbook-svg-icon-label'/>
                logbookEntry.entry = <UrinePHEntry entry={value} key={++this.index}/>
                return logbookEntry;
            }
            case KETONES: {
                logbookEntry.title = $$('Ketones');
                logbookEntry.icon = <KetonesIcon fill={medrec_red} className='vitals-logbook-svg-icon-label'/>
                logbookEntry.entry = <KetonesEntry entry={value} key={++this.index}/>
                return logbookEntry;
            }
            case HBA1C: {
                logbookEntry.title = $$('HBA1C');
                logbookEntry.icon = <HBA1CIcon fill={medrec_red} className='vitals-logbook-svg-icon-label'/>
                logbookEntry.entry = <HBA1CEntry entry={value} key={++this.index}/>
                return logbookEntry;
            }
            case BLOOD_SUGAR: {
                logbookEntry.title = $$('Blood_sugar');
                logbookEntry.icon = <BloodSugarIcon fill={medrec_red} className='vitals-logbook-svg-icon-label'/>
                logbookEntry.entry = <BloodSugarEntry entry={value} key={++this.index}/>
                return logbookEntry;
            }
            case PEAK_FLOW: {
                logbookEntry.title = $$("peak_flow")
                logbookEntry.icon = <PeakFlowIcon className="vitals-logbook-svg-icon-label peak-flow-icon" />
                logbookEntry.entry = <PeakFlowEntry entry={value} key={++this.index} />
                return logbookEntry;
            }
            default:
                return '';
        }
    }

    /**
     * Prepares the logbook entry element with its title, icon and list of measurements
     *
     * @param {ReactComponent} icon - the icon to display
     * @param {string} title - the logbook entry type
     * @param {Array} logbookEntries - the logbook entries
     */
    getGroupedLogbookEntryElement = (icon, title, logbookEntries) => {
        return <div key={++this.index}>
            <div key={++this.index} className='row title-row'>
                <div key={++this.index} className='logbook-icon dropdown-link'>
                    {icon}
                </div>
                &nbsp;
                <div key={++this.index} className='vitals-logbook-icon-label medrec-red-2 dropdown-link'>
                    {title}
                </div>
            </div>
            <br/>
            <div key={++this.index}
                 className='vitals-logbook-entry-padding vitals-logbook-logbook-entries-container'>{logbookEntries}</div>
        </div>;
    }

    render() {
        return (
            <div className=' vitals-logbook-entry-margin vitals-logbook-grouped-logbook-entries-container'>
                {this.getGroupedLogbookEntry()}
            </div>
        )
    }
}

GroupedLogbookEntry.propTypes = {
    entries: PropTypes.array,
    settings: PropTypes.object,
    type: PropTypes.string
}


export default GroupedLogbookEntry