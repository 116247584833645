import React, {Component} from "react";
import {$$} from "../../helpers/localization";
import PropTypes from "prop-types";
import endOfDay from 'date-fns/endOfDay'
import DatePicker from "react-datepicker";
import moment from "moment";
import {connect} from "react-redux";
import {dummyActionDirectDispatch} from "../../actions/clinic_actions";


export class TimeSpanPicker extends Component {

    constructor(props) {
        super(props);
        if (props.years) {
            this.state = {
                startDate: moment().subtract(1, "years").toDate(),
                endDate: moment().toDate(),
                timeFrameSelection: this.props.timeFrameSelectionYrs ? this.props.timeFrameSelectionYrs :  "1_YEAR",
                showCustomDate: false
            }
        } else {
            this.state = {
                startDate: moment().subtract(30, "days").toDate(),
                endDate: moment().toDate(),
                timeFrameSelection: this.props.timeFrameSelection ? this.props.timeFrameSelection : "30_DAYS",
                showCustomDate: false
            };
        }

        this.handleTimeFrameSelectionChange = this.handleTimeFrameSelectionChange.bind(this);

    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state !== prevState){
            this.emmitValues()
        }
    }

    componentDidMount() {
        this.emmitValues();
    }

    createOption = option => (
        <option
            value={option.value}
            key={option.value}
        >
            {option.name}
        </option>
    );

    emmitValues = () => {
        let dateRange = {start: this.state.startDate, end: this.state.endDate}
        this.props.onFilter(dateRange, this.props.years, this.state.timeFrameSelection);
        dummyActionDirectDispatch();
    }

    setStartDate = (date) => {
        this.setState({
            startDate: date,
            endDate: date > this.state.endDate && this.state.endDate !== "" ? date : this.state.endDate
        });
    }

    setEndDate = (date) => {
        this.setState({endDate: date ? endOfDay(date) : date});
    }

    getTimesInDay = () => {
        const times = [{name: "7 " + $$("days"), value: "7_DAYS"}, {name: "14 " + $$("days"), value: "14_DAYS"},
            {name: "30 " + $$("days"), value: "30_DAYS"}, {name: "90 " + $$("days"), value: "90_DAYS"},
            {name: $$("other"), value: "OTHER"}];
        return times;
    }

    getYearsOptions = () => {
        const pairs = [{name: "1 " + $$("year"), value: "1_YEAR"},
            {name: "2 " + $$("years"), value: "2_YEARS"},
            {name: "3 " + $$("years"), value: "3_YEARS"},
            {name: "4 " + $$("years"), value: "4_YEARS"},
            {name: "5 " + $$("years"), value: "5_YEARS"},
            {name: $$("other"), value: "OTHER"}];
        return pairs;
    }

    handleTimeFrameSelectionChange(e) {
        let start = "";
        let end = "";

        switch (e.target.value) {
            case "7_DAYS": {
                start = moment().subtract(7, "days").toDate();
                end = moment().toDate();
                break;
            }
            case "14_DAYS": {
                start = moment().subtract(14, "days").toDate();
                end = moment().toDate();
                break;
            }
            case "30_DAYS": {
                start = moment().subtract(30, "days").toDate();
                end = moment().toDate();
                break;
            }
            case "90_DAYS": {
                start = moment().subtract(90, "days").toDate();
                end = moment().toDate();
                break;
            }
            case "1_YEAR": {
                start = moment().subtract(1, "years").toDate();
                end = moment().toDate();
                break;
            }
            case "2_YEARS": {
                start = moment().subtract(2, "years").toDate();
                end = moment().toDate();
                break;
            }
            case "3_YEARS": {
                start = moment().subtract(3, "years").toDate();
                end = moment().toDate();
                break;
            }
            case "4_YEARS": {
                start = moment().subtract(4, "years").toDate();
                end = moment().toDate();
                break;
            }
            case "5_YEARS": {
                start = moment().subtract(5, "years").toDate();
                end = moment().toDate();
                break;
            }
        }

        this.setState({
            timeFrameSelection: e.target.value,
            showCustomDate: e.target.value === "OTHER",
            startDate: start,
            endDate: end,
        });
    }

    createTimeOptions = () => this.getTimesInDay().map(this.createOption);
    createYearsOptions = () => this.getYearsOptions().map(this.createOption)

    render() {
        return (
            <span className="day-picker-popup-line justify-content-start align-items-center">
                 <label className="mr-2 mb-0">{$$("duration_range_picker_label")}</label>
                 <span className="mr-2">
                     <select id="startTime"
                             onChange={this.handleTimeFrameSelectionChange}
                             value={this.state.timeFrameSelection}
                             className="custom-select time">
                         {this.props.years ? this.createYearsOptions() : this.createTimeOptions()}
                </select>
                 </span>
                {this.state.showCustomDate && <span className="mr-2">
                    <DatePicker
                        className="custom-select"
                        selected={this.state.startDate}
                        dateFormat={this.props.settings.data.dateFormat}
                        placeholderText={$$('start_date')}
                        onChange={date => this.setStartDate(date)}/>
                </span>}
                {this.state.showCustomDate && <span>
                    <DatePicker
                        selected={this.state.endDate}
                        className="custom-select"
                        minDate={this.state.startDate}
                        dateFormat={this.props.settings.data.dateFormat}
                        placeholderText={$$('end_date')}
                        onChange={date => this.setEndDate(date)}/>
                </span>}
            </span>
        )
    }
}

function mapStateToProps(state) {
    return {
        settings: state.settings.data
    }
}

TimeSpanPicker.propTypes = {
    i18n: PropTypes.any,
    settings: PropTypes.any,
    timeFrameSelection: PropTypes.any,
    timeFrameSelectionYrs: PropTypes.any,
    years: PropTypes.bool,
    onFilter: PropTypes.func
}

export default connect(mapStateToProps, {})(TimeSpanPicker)
