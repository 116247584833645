import React, {Component} from 'react';
import {connect} from 'react-redux';
import {selectAppointment} from '../../actions/users_actions';
import AppointmentsTabListItem from './AppointmentsTabListItem';
import PropTypes from "prop-types";
import {$$} from "../../helpers/localization";
import no_data from "../../resources/images/no_data.png";
import {infoUtils} from "../../utils/infoUtils";
import AppointmentFilters from "../shared/AppointmentFilters";
import {isSameDay} from "date-fns";
import {restorePreviousFilters, saveAppointmentFilters} from "../../actions/filter_actions";
import {cloneDeep} from "lodash";


class AppointmentsTab extends Component {
    state = {
        events: [],
        selectedAppointmentId: '',
        range: 'ALL',
        selectedType: "0"
    }

    constructor(props) {
        super(props);
        this.index = 0;
    }

    componentDidMount() {

        this.setState({
            range: this.props.selectedFilters.appointmentsTab.range,
            selectedType: this.props.selectedFilters.appointmentsTab.selectedType
        }, () => this.applyFilter())

        if (this.props.appointmentFilters.restoreFiltersOnLoad) {
            this.setState({
                range: this.props.appointmentFilters.range,
                selectedType: this.props.appointmentFilters.selectedType
            }, () => this.applyFilter())
        } else {
            this.applyFilter();
        }
        this.props.restorePreviousFilters(false)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userAppointments !== this.props.userAppointments) {
            this.applyFilter();
        }
    }

    /**
     * Creates the rows of the table
     *
     * @param {object} appointment - the appointment object
     * @param  idx - index value
     * @returns {Array} the table rows created
     */
    getRow = (appointment, idx) => {
        return <AppointmentsTabListItem key={idx}
                                        selectedUser={this.props.selectedUser}
                                        i18n={this.props.i18n}
                                        appointment={appointment}
                                        settings={this.props.settings}
                                        keepSelectedUserAndOpenAppointment={this.props.keepSelectedUserAndOpenAppointment}
                                        selectedAppointmentId={this.state.selectedAppointmentId}
                                        loggedInUser={this.props.loggedInUser}
                                        onAppointmentChange={this.onAppointmentChange}/>;
    }

    /**
     * Creates the full body of the table
     *
     * @returns {Array} the table rows
     */
    getBody = () => {
        let allRows = [];
        let appointments = this.state.userAppointments;

        for (let i = 0; i < appointments?.length; ++i) {
            let appointment = appointments[i];
            allRows.push(this.getRow(appointment, appointment.id));
        }

        return [].concat.apply([], allRows);
    }

    /**
     * An event handler triggered when a new appointment is choosen
     * from the list of appointments
     *
     * @param {object} appointment - the appointment object
     */
    onAppointmentChange = (appointment) => {
        let selectedAppointmentId = appointment.id;
        this.setState({selectedAppointmentId});
    }

    noAppointments = () => {
        let emptyStatsResultObj = {
            imgClass: 'no-lab-results-img',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: 'no-lab-results-secondary-label',
            src: no_data,
            primaryLabel: $$('vitals_logbook_no_data_primary_label'),
            secondaryLabel: ''
        }
        return infoUtils.noData(emptyStatsResultObj);
    }

    applyRange = (appointments) => {
        return appointments.filter((appointment) => {
            if (this.state.range === 'TODAY') {
                return isSameDay(appointment.starts_at, Date.now());
            }
            return true;
        });
    }

    getFilteredAppointments = () => {
        return this.props.userAppointments.filter((appointment) => {
            switch (this.state.selectedType) {
                case "0":
                    return true;

                case "2":
                    return appointment.status === "COMPLETED";

                case "1":
                    return !appointment.text_only && appointment.status !== "COMPLETED" && !appointment.status.includes("CANCELED");

                case "3":
                    return !!(appointment.text_only && appointment.status !== "COMPLETED" && !appointment.status.includes("CANCELED"));

                default:
                    return true;

            }
        })
    }

    getOnChange = ({name, value}) => {
        this.setState({selectedType: value}, this.applyFilter);
        let filterValues = {selectedType: value, range: this.state.range}
        this.props.saveAppointmentFilters(filterValues);

        let filters = cloneDeep(this.props.selectedFilters);
        filters.appointmentsTab = filterValues;

        this.props.updateFilters(filters);
    }

    rangeChanged = (r) => {
        this.setState({range: r}, this.applyFilter);
        let filterValues = {selectedType: this.state.selectedType, range: r}
        this.props.saveAppointmentFilters(filterValues);

        let filters = cloneDeep(this.props.selectedFilters);
        filters.appointmentsTab = filterValues;

        this.props.updateFilters(filters);

    }

    applyFilter = () => {
        this.setState({userAppointments: this.applyRange(this.getFilteredAppointments()), selectedAppointmentId: ''})
    }

    render() {
        return (
            <div className={"minus-twenty-margin-top"}>
                {this.props.userAppointments.length > 0 &&
                    <div className='row zero-margin-row'>
                        <div className='col-xs-12 col-md-12'>
                            <div className='row'>
                                <div className='w-100 appointments-tab'>
                                    <AppointmentFilters
                                        rangeChanged={this.rangeChanged}
                                        getOnChange={this.getOnChange}
                                        selectedType={this.state.selectedType}
                                        range={this.state.range}
                                    />
                                    {this.getBody()}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.props.userAppointments.length === 0 ||
                    this.state.userAppointments?.length === 0 &&
                    <div>
                        {this.noAppointments()}
                    </div>
                }
            </div>
        )
    }
}

AppointmentsTab.propTypes = {
    fetchUserAppointments: PropTypes.func,
    i18n: PropTypes.object,
    isFinished: PropTypes.bool,
    isLastPage: PropTypes.bool,
    keepSelectedUserAndOpenAppointment: PropTypes.func,
    saveAppointmentFilters: PropTypes.func,
    restorePreviousFilters: PropTypes.func,
    settings: PropTypes.object,
    selectedFilters: PropTypes.object,
    updateFilters: PropTypes.func,
    appointmentFilters: PropTypes.object,
    userAppointments: PropTypes.any,
    selectedUser: PropTypes.any,
    userId: PropTypes.string
}

const mapStateToProps = (state) => ({
    i18n: state.language.selected,
    appointmentFilters: state.appointmentFilters,
    users_data: state.users,
    selectedAppointment: state.selectedAppointment
})

export default connect(mapStateToProps, {
    selectAppointment,
    saveAppointmentFilters,
    restorePreviousFilters
})(AppointmentsTab)
