import history from "../../helpers/history";
import {UPDATE_MENU} from "../../actions/actions";
import store from '../../store'
import {Routes} from "../../constants/routes";

export default {
    header: {
        self: {},
        items: []
    },
    aside: {
        self: {},
        items: [
            {
                title: "Main",
                root: true,
                icon: "flaticon-imac",
                page: "management-dashboard",
                translate: "Dashboard",
                bullet: "dot"
            },
            {
                title: "Patients",
                root: true,
                icon: "flaticon-users",
                page: "m-patients",
                translate: "patients_management_label",
                bullet: "dot"
            },
            {
                title: "Staff",
                root: true,
                icon: "flaticon-user-add",
                page: "staff",
                translate: "staff",
                bullet: "dot"
            },
            {
                title: "Appointments",
                root: true,
                icon: "flaticon-calendar-3",
                page: "m-appointments",
                translate: "schedule",
                bullet: "dot"
            },
            {
                title: "Accounting",
                root: true,
                icon: "flaticon-interface-11",
                page: "m-accounting",
                translate: "accounting_menu",
                bullet: "dot"
            },
            {
                title: "Switch Practice",
                root: true,
                icon: "flaticon-location",
                page: "select-practice",
                translate: "switch_practice_menu_item",
                bullet: "dot",
                fixedButton: false,
                separator: false
            },
            {
                title: "Back to clinic",
                root: true,
                icon: "flaticon-home-2",
                onClick: function() { store.dispatch({type: UPDATE_MENU, payload:1});history.push(Routes.DASHBOARD) },
                translate: "title",
                bullet: "dot",
                fixedButton: false,
                separator: true
            },
            {
                title: "Get Help",
                root: true,
                icon: "flaticon-questions-circular-button",
                page: "",
                externalLink: "https://www.manula.com/manuals/sirma-medical-systems/medrec-m-user-guide/clinic/bg/topic/clinic-introduction",
                translate: "get_help_label",
                bullet: "dot",
                fixedButton: true
            },
            {
                title: "Logout",
                root: true,
                icon: "flaticon-logout",
                page: "logout",
                translate: "logout_label",
                bullet: "dot",
                fixedButton: true
            },
            {
                title: "",
                root: true,
                page: "main",
                hidden: true,
                translate: "main_page_label"
            }
        ]
    }
};
