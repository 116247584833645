import React from 'react'
import {CONVERTER} from '../../../utils/converter'
import PropTypes from "prop-types";

export default function BloodPressureEntry(props) {
    return (
        <div className='card logbook-entry-card'>
            <div className='row zero-margin-row'>
                <div className='medrec-grey-2 vitals-logbook-time logbook-entry-time-icon'>
                    <i className='kt-menu__link-icon far fa-clock'/> &nbsp;
                    {CONVERTER.getEntryDateTimeFormat(props.entry.date_time)}
                </div>
                &nbsp;&nbsp;
                {props.entry.systolic}/{props.entry.diastolic} &nbsp;
                {props.entry.pulse > 0 && <i className='kt-menu__link-icon fas fa-heart vitals-logbook-icon-label medrec-grey-2 pulse'/> } &nbsp;
                <div>{props.entry.pulse > 0? props.entry.pulse :""}</div>
            </div>
        </div>
    )
}

BloodPressureEntry.object = {
    entry: PropTypes.array
}
