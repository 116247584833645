import {
    LOGOUT,
    REQUEST_ERROR,
    REQUEST_SENT,
    REQUEST_SUCCESS
} from "../actions/actions";

const initialState = {response: {}}

/**
 * Request reducer. Reduce state based on action type.
 *
 * @param {object} state the state of the request
 * @param {action} action the action to execute on the state
 * @returns the new state
 */
export function requestStatus(state = initialState, action) {
    switch (action.type) {
        case REQUEST_SENT: {
            return {...state, sent: true, finished: false, result: {}}
        }
        case REQUEST_SUCCESS: {
            // eslint-disable-next-line no-unused-vars
            const {sent, ...rest} = state;
            return {...rest, finished: true, success: true};
        }
        case REQUEST_ERROR: {
            // eslint-disable-next-line no-unused-vars
            const {sent, ...rest} = state;
            return {...rest, finished: true, success: false, error: action.response};
        }
        case LOGOUT: {
            return {...state, ...initialState};
        }
        default: {
            return state;
        }
    }
}