import React, {useCallback, useEffect, useState} from "react";
import {getLabel} from "./utils";
import {$$} from "../../../../helpers/localization";

function LabelCell({children}) {
    return <td className="medrec-grey-2 text-right medical-notice-item-label">{children}:</td>
}

function DataCell({children}) {
    return <td className={`w-100 align-bottom whitespace-pre-line`}>{children}</td>
}

export function OptionalRow({val, opt, label, convertor, isDiagnosis}) {
    const [displayValue, setDisplayValue] = useState("");

    const convert = useCallback(() => {
        if (convertor) {
            return convertor(val)
        }
        return val;
    }, []);

    const getOptLabel = useCallback((opt, value) => {
        if (!isDiagnosis) {
            return getLabel(opt, value);
        }

        let mainLabel = getLabel(opt, value.code);
        if (value.additionalCode && value.additionalCode.length > 0) {
            mainLabel = `${mainLabel} (${value.additionalCode.map(c => getLabel(opt, c)).join("; ")})`
        }
        return mainLabel
    }, []);

    useEffect(() => {
        if (!val || !val.length > 1) {
            return;
        }

        if (Array.isArray(val)) {
            setDisplayValue(val.map(v => {
                return opt ? getOptLabel(opt, v) : convert(val);
            }).join("; "));
        } else {
            setDisplayValue(opt ? getOptLabel(opt, val) : convert(val));
        }
    }, [val])

    if (!val || !val.length > 1) {
        return false;
    }

    return <tr>
        <LabelCell>{$$(label)}</LabelCell>
        <DataCell>{displayValue}</DataCell>
    </tr>

}