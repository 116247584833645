import React, {Component} from 'react'
import {connect} from 'react-redux'
import StaffHeader from "./StaffHeader";
import {
    GetStaffList,
    managementCreateConsultationMenuOption,
    managementDeleteConsultationMenuOption,
    managementUpdateConsultationMenuOption
} from "../../actions/management_actions";
import PropTypes from "prop-types";
import StaffList from "./StaffList";
import no_appointments from "../../resources/images/no_appointments.png";
import {$$} from "../../helpers/localization";
import {infoUtils} from "../../utils/infoUtils";
import StaffPriceList from "./StaffPriceList";
import {Modal} from "react-bootstrap";
import DeleteConfirmationModal from "../price-settings/DeleteConfirmationModal";
import EditConsultationOptionPopup from "../price-settings/EditConsultationOptionPopup";
import CreateConsultationOptionPopup from "../price-settings/CreateConsultationOptionPopup";
import StaffWorkTimePage from "./StaffWorkTimePage";
import StaffAppointments from "./StaffAppointments";
import {Permissions} from "../../utils/permissions/types";
import AddNewStaff from "./AddNewStaff";
import {clinicService} from "../../service/clinic_service";
import {UpdateStaffRole, EditStaffProfile} from "./UpdateStaff";
import {getUserInfo} from "../../actions/users_actions";
import ActiveInvitationList from "./ActiveInvitationList";


class StaffPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPrices: false,
            hideStaffList: false,
            deletePriceListOpen: false,
            createPriceListOpen: false,
            editPriceListOpen: false,
            showSchedule: false,
            showAppointments: false,
            selectedPriceListItem: {},
            selectedStaffMember: {}
        }
    }

    componentDidMount() {
        this.getStaff()
    }

    getStaff = () => {
        return this.props.GetStaffList(this.props.organisationId);
    }

    filterUsers = (searchValue) => {
        this.setState({searchValue});
    }

    noStaff = () => {
        let noStaff = {
            imgClass: 'no-vitals-logbook-img',
            primaryLabelClass: 'no-lab-results-primary-label',
            secondaryLabelClass: 'no-lab-results-secondary-label',
            src: no_appointments,
            primaryLabel: $$('no_results_found'),
            secondaryLabel: ''
        }
        return infoUtils.noData(noStaff);
    }

    showPriceList = (staffMember) => {
        this.setState({
            selectedStaffMember: staffMember,
            hideStaffList: true,
            showPrices: true,
            organisations: [... staffMember.organizations.filter(org => org.id = this.props.organisationId)]
        })
    }

    showSchedule = (staffMember) => {
        this.setState({
            selectedStaffMember: staffMember,
            hideStaffList: true,
            showSchedule: true
        })
    }

    showAppointments = (staffMember) => {
        this.setState({
            selectedStaffMember: staffMember,
            hideStaffList: true,
            showAppointments: true
        })
    }

    getEncounterType = (menuItem) => {
        switch (menuItem.encounter_type) {
            case 'PRIMARY_EXAM':
                return $$('primary_exam');
            case 'FOLLOW_UP_EXAM':
                return $$('follow_up_exam');
            case'OTHER':
                return menuItem.name
        }
    }

    clearPriceList = () => {
        this.setState({
            selectedStaffMember: {},
            hideStaffList: false,
            showPrices: false
        })
    }

    clearAppointments = () => {
        this.setState({
            selectedStaffMember: {},
            hideStaffList: false,
            showAppointments: false
        })
    }

    clearSchedule = () => {
        this.setState({
            selectedStaffMember: {},
            hideStaffList: false,
            showSchedule: false
        })
    }

    openCreatePricePopup = (textOnly) => {
        this.setState({textOnly: textOnly, createPriceListOpen: true});
    }

    handleEditPriceClick = (menuItem) => {
        this.setState({
            selectedPriceListItem: menuItem,
            editPriceListOpen: true
        })
    }

    handleDeletePriceClick = (menuItem) => {
        this.setState({
            selectedPriceListItem: menuItem,
            deletePriceListOpen: true
        })
    }

    closeDeletePriceListPopup = () => {
        this.setState({
            deletePriceListOpen: false,
            selectedPriceListItem: {}
        })
    }

    closeEditPriceListPopup = () => {
        this.setState({
            editPriceListOpen: false,
            selectedPriceListItem: {}
        })
    }

    closeCreatePriceListPopup = () => {
        this.setState({
            createPriceListOpen: false
        })
    }

    deleteOption = (id) => {
        this.props.managementDeleteConsultationMenuOption(id).then(() => {
                this.getStaff().then(() => {
                        let selectedUser = {}
                        for (let i in this.props.users_data) {
                            if (this.props.users_data[i].id === this.state.selectedStaffMember.id) {
                                selectedUser = this.props.users_data[i]
                            }
                        }
                        this.setState({selectedStaffMember: selectedUser})
                    }
                )
            }
        )
    }

    editPriceListOption = (item) => {
        item.organization_id = this.props.organisationId

        this.props.managementUpdateConsultationMenuOption(item).then(() => {
                this.getStaff().then(() => {
                        let selectedUser = {}
                        for (let i in this.props.users_data) {
                            if (this.props.users_data[i].id === this.state.selectedStaffMember.id) {
                                selectedUser = this.props.users_data[i]
                            }
                        }
                        this.setState({selectedStaffMember: selectedUser})
                    }
                )
            }
        )
    }

    createPriceListOption = (item) => {
        item.organization_id = this.props.organisationId
        item.provider_id = this.state.selectedStaffMember.id

        this.props.managementCreateConsultationMenuOption(item).then(() => {
                this.getStaff().then(() => {
                        let selectedUser = {}
                        for (let i in this.props.users_data) {
                            if (this.props.users_data[i].id === this.state.selectedStaffMember.id) {
                                selectedUser = this.props.users_data[i]
                            }
                        }
                        this.setState({selectedStaffMember: selectedUser})
                    }
                )
            }
        )
    }

    showManage = (u) => {
        this.setState({manageStaffRole:u});
    }

    showEditStaffPersonalInfo = (u) => {
        this.setState({editStaffPersonalInfo:u});
    }

    render() {
        let ud = this.state.internal ? this.props.internalStaff : this.props.users_data;

        if (this.state.editStaffPersonalInfo) {
            return <EditStaffProfile
                onCancel={() => {
                    this.setState({editStaffPersonalInfo: null})
                }}
                onSave={(u) => {
                    console.log(u);
                    return clinicService.updateStaffPersonalInfo({ id:this.state.editStaffPersonalInfo.id,
                        org_id: this.props.organisationId,
                        ...u}).then(() => {
                        this.getStaff().then(() => {
                            this.setState({editStaffPersonalInfo:null})
                        });
                    });
                }}
                lang={this.props.i18n.selected.lang}
                user={this.state.editStaffPersonalInfo}
            />
        }

        if (this.state.manageStaffRole) {
            let canRemoveAdmin =
                this.props.internalStaff.find(s =>
                    s.id !== this.state.manageStaffRole.id && s.organizations.find(o => o.id === this.props.organisationId).role === 'ADMIN')
                || this.props.users_data.find(s => s.id !== this.state.manageStaffRole.id && s.organizations.find(o => o.id === this.props.organisationId).role === 'ADMIN');

            return <UpdateStaffRole
                        user={this.state.manageStaffRole}
                        org_id={this.props.organisationId}
                        lang={this.props.i18n.selected.lang}
                        canRemoveAdmin={canRemoveAdmin}
                        loggedInUserId={this.props.loggedInUserId}
                        onBack={() => {
                            this.setState({manageStaffRole: null})
                        }}
                        onUpdate={({visibility, internal, role, user}) => {
                            if (internal) {
                                visibility = false;
                            }
                            return clinicService.updateStaffRole({id:user.id, org_id: this.props.organisationId, visibility, role}).then(async () => {
                                if (user.id === this.props.loggedInUserId) {
                                    await this.props.getUserInfo();
                                }
                                this.getStaff().then(() => {
                                    this.setState({manageStaffRole:null})
                                });
                            });
                        }}

            />
        }

        if (this.state.addNew) {
            return (<AddNewStaff
                        onBack={() => {
                            this.setState({addNew: false})
                        }}
                        onCreate={(o) => {
                            let clone = {...o};
                            clone.country = this.props.organisation.country ? this.props.organisation.country : this.props.loggedInUser.country;
                            clone.city = this.props.organisation.city ? this.props.organisation.city : this.props.loggedInUser.city;
                            return clinicService.inviteNewStaff(clone).then(() => {
                                this.getStaff().then(() => {
                                    this.setState({addNew:false})
                                });
                            });
                        }}
                        permissions={this.props.permissions}
                        org_id={this.props.organisationId}
                        org_name={this.props.organisationName}
                        lang={this.props.i18n.selected.lang}
            />)
        }

        if (this.state.showInvitationList) {
            return <ActiveInvitationList onClose={() => {this.setState({showInvitationList:false})}} orgId={this.props.organisationId}/>
        }

        return (
            <div className='patient-card'>
                <StaffHeader filterUsers={this.filterUsers}
                             openCreatePricePopup={this.openCreatePricePopup}
                             clearAppointments={this.clearAppointments}
                             clearPriceList={this.clearPriceList}
                             clearSchedule={this.clearSchedule}
                             PriceList={this.state.showPrices}
                             Schedule={this.state.showSchedule}
                             Appointments={this.state.showAppointments}
                             loggedInUserId={this.props.loggedInUserId}
                             permissions={this.props.permissions}
                             SelectedStaffMember={this.state.selectedStaffMember}/>
                {!this.state.hideStaffList && <div className="pl-3 ml-1 pr-3 d-flex justify-content-between"><div className="btn-group">
                    <button type="button" className={`btn ${this.state.internal ? 'btn-secondary' : 'btn-primary active'}`} onClick={()=>{this.setState({internal:false})}}>{$$("clinicians_label")}</button>
                    <button type="button" className={`btn ${this.state.internal ? 'btn-primary active' : 'btn-secondary'}`} onClick={()=>{this.setState({internal:true})}}>{$$("nonmedical_personnel")}</button>
                </div>
                    {this.props.permissions.canCreateStaff() && <div>
                        <button type="button" className={`btn btn-secondary ml-2`} onClick={()=>{this.setState({showInvitationList:true})}}>
                            {$$(`staff_management.buttons.active_invitations`)}
                        </button>
                        <button type="button" className={`btn btn-primary ml-2`} onClick={()=>{this.setState({addNew:true})}}>
                            {$$(`staff_management.buttons.invite_new_staff_member`)}
                        </button>
                    </div>}
                </div>}
                {!this.state.hideStaffList && ud && ud.length > 0 && this.props.permissions.canSeeStaff() ? <StaffList
                    users={ud}
                    onAppointmentsClick={this.showAppointments}
                    onPriceListClick={this.showPriceList}
                    onScheduleClick={this.showSchedule}
                    onManageClick={this.showManage}
                    onEditStaffClick={this.showEditStaffPersonalInfo}
                    orgId={this.props.organisationId}
                    permissions={this.props.permissions}
                    loggedInUserId={this.props.loggedInUserId}
                    searchValue={this.state.searchValue}/> : !this.state.hideStaffList && this.noStaff()}

                {this.state.showPrices && <StaffPriceList handleDeletePriceClick={this.handleDeletePriceClick}
                                                          handleEditPriceClick={this.handleEditPriceClick}
                                                          organisationId={this.props.organisationId}
                                                          selectedStaffMember={this.state.selectedStaffMember}
                                                          lang={this.props.i18n.selected.lang}
                                                          loggedInUserId={this.props.loggedInUserId}
                                                          permissions={this.props.permissions}
                                                          priceList={this.state.selectedStaffMember.prices}/>}


                {this.state.showAppointments && <StaffAppointments i18n={this.props.i18n}
                                                                   selectedStaffMember={this.state.selectedStaffMember}

                />}

                <Modal show={this.state.deletePriceListOpen} onHide={this.closeDeletePriceListPopup}
                       dialogClassName="w-100 day-picker-popup" backdrop="static">
                    <Modal.Body>
                        {this.state.deletePriceListOpen && (
                            <DeleteConfirmationModal
                                closePopup={this.closeDeletePriceListPopup}
                                delete={this.deleteOption}
                                management={true}
                                item={this.state.selectedPriceListItem}
                                name={this.getEncounterType(this.state.selectedPriceListItem)}
                            />
                        )}
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.editPriceListOpen} onHide={this.closeEditPriceListPopup}
                       dialogClassName=""
                       size={"xl"}
                       scrollable={true}
                       backdrop="static">
                    <Modal.Body>
                        {this.state.editPriceListOpen && (
                            <EditConsultationOptionPopup
                                closePopup={this.closeEditPriceListPopup}
                                upDateMenuOption={this.editPriceListOption}
                                selectedMenuItem={this.state.selectedPriceListItem}
                                hideOrganisation={true}
                                formDisabled={this.props.formDisabled}
                                organisations={this.state.organisations}
                                lang={this.props.i18n.selected.lang}
                            />
                        )}
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.createPriceListOpen} onHide={this.closeCreatePriceListPopup}
                       dialogClassName=""
                       size={"xl"}
                       scrollable={true}
                       backdrop="static">
                    <Modal.Body>
                        {this.state.createPriceListOpen && (
                            <CreateConsultationOptionPopup
                                closePopup={this.closeCreatePriceListPopup}
                                createMenuOption={this.createPriceListOption}
                                formDisabled={this.props.formDisabled}
                                hideOrganisation={true}
                                textOnly={this.state.textOnly}
                                organisations={this.state.organisations}
                                lang={this.props.i18n.selected.lang}
                                canMakeGroupAppointments={this.state.selectedStaffMember?.organizations?.find((o) => o.id === this.props.organisationId)?.canMakeGroupAppointments}
                            />
                        )}
                    </Modal.Body>
                </Modal>

                <br/>
                {this.state.showSchedule && <StaffWorkTimePage organisationId={this.props.organisationId}
                                                               loggedInUserId={this.props.loggedInUserId}
                                                               permissions={this.props.permissions}
                                                               selectedStaffMember={this.state.selectedStaffMember}
                                                               organisationName={this.props.organisationId}/>}
            </div>
        )
    }
}

StaffPage.propTypes = {
    GetStaffList: PropTypes.func,
    i18n: PropTypes.any,
    managementUpdateConsultationMenuOption: PropTypes.func,
    managementDeleteConsultationMenuOption: PropTypes.func,
    managementCreateConsultationMenuOption: PropTypes.func,
    getUserInfo: PropTypes.func,
    users_data: PropTypes.any,
    internalStaff: PropTypes.any,
    organisationName: PropTypes.any,
    formDisabled: PropTypes.any,
    organisationId: PropTypes.string,
    permissions: PropTypes.object,
    loggedInUserId: PropTypes.string,
    loggedInUser: PropTypes.object
}

function mapStateToProps(state) {
    return {
        organisationName: state.management.selectedOrganisation.name,
        organisation: state.management.selectedOrganisation,
        i18n: state.language,
        organisationId: state.management.selectedOrganisation.id,
        users_data: state.management.staff,
        internalStaff: state.management.internalStaff,
        formDisabled: state.formInteractions,
        permissions: Permissions.fromOrg(state.userInfo.data.organizations.find(o => o.id === state.management.selectedOrganisation.id)),
        loggedInUserId: state.userInfo.data.id,
        loggedInUser: state.userInfo.data
    }
}

const mapDispatchToProps = {
    GetStaffList,
    getUserInfo,
    managementDeleteConsultationMenuOption,
    managementUpdateConsultationMenuOption,
    managementCreateConsultationMenuOption
}

export default connect(mapStateToProps, mapDispatchToProps)(StaffPage)
