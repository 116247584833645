import {cloneDeep} from "lodash";

export const testsUtils = {
    prepareTestResults,
    testHasValue,
    getValue,
    groupHasValue,
    rowHasValue
}


export function prepareTestResults(tests) {
    if (!tests?.length > 0) {
        return null;
    }
    let result = [];
    for (let i = 0; i < tests.length; i++) {
        const test_result = cloneDeep(tests[i]);
        let res = [];
        for (let m = 0; m < test_result.tests.length; m++) {
            const test = test_result.tests[m]
            if (testHasValue(test)) {
                res.push(test);
                let groups = [];
                for (let j = 0; j < test.groups.length; j++) {
                    const group = test.groups[j];
                    if (groupHasValue(group)) {
                        groups.push(group);
                        let rows = [];
                        for (let k = 0; k < group.rows.length; k++) {
                            const row = group.rows[k];
                            if (rowHasValue(row)) {
                                rows.push(row)
                            }
                        }
                        group.rows = rows;
                    }
                }
                test.groups = groups;
            }
        }
        test_result.tests = res;
        result.push(test_result)
    }
    return result;
}


export function getValue (field) {
    let value = field.value;
    if (!value) {
        return "";
    }
    switch (field.type) {
        case 'calculate':
        case 'number':
            return value.f_value !== null ? value.f_value : "";
        case 'boolean':
            return value.b_value !== null ? value.b_value : "";
        case 'date':
            return value.date_value ? value.date_value : "";
        case 'string':
            return value.s_value ? value.s_value : "";
        default: return "";
    }
}

export function testHasValue (test) {
    return test.groups.find(g => {
        return groupHasValue(g)
    });
}

export function groupHasValue (group) {
    return group.rows.find(r => {
        return rowHasValue(r)
    });
}

export function rowHasValue(row) {
    return row.fields.find(f => {
        return getValue(f) !== ""
    });
}