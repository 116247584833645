import React, {Component} from "react";
import {Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import classNames from 'classnames'
import PropTypes from "prop-types";


export default class PatientInfoButton extends Component {
    constructor(props, context) {
        super(props, context);
    }

    renderTooltip = props => (
        <Tooltip {...props} show={"true"}>{this.props.title}</Tooltip>
    );

    render() {
        const {infoIsActive, ...otherProps} = this.props;
        return <OverlayTrigger
            placement={"top"}
            overlay={this.renderTooltip}
            popperConfig={{
                modifiers: {
                    preventOverflow: {
                        enabled: false
                    }
                }
            }}
        >
            <div {...otherProps} className={classNames({
                'btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm': true,
                'btn-secondary': infoIsActive
            })}>
                <i className='flaticon2-list-3 centered-text'/>
            </div>
        </OverlayTrigger>
    }
}

PatientInfoButton.propTypes = {
    infoIsActive: PropTypes.bool,
    onClick:PropTypes.func,
    style: PropTypes.object,
    title: PropTypes.string
}

