import {
    LOGOUT,
    REQUEST_SUCCESS,
    SET_USER_SELECTIONS
} from "../actions/actions";
import {requestStatus} from "./requests_reducers";

const initialState = {
    dashboard: {},
    appointments: {
        view: "Calendar",
        calendarView: "Month"
    },
    patients: {
        view: "List",
        medicalHistory: {
            patientView: "Info",
            tab: "Appointments"
        }
    },
    messages: {},
    workTime: {
        calendarView: "Month",
        calendars: []
    },
    settings: {},
    userProfile: {}
}

export function userSelections(state = initialState, action) {
    switch (action.type) {
        case SET_USER_SELECTIONS: {
            return {...state, value: action.result, request: requestStatus(state.request, {type: REQUEST_SUCCESS})}
        }
        case LOGOUT: {
            return state
        }
        default: {
            return state
        }
    }
}
