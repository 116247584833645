import React from 'react'
import {connect} from 'react-redux'
import {$$} from '../../../helpers/localization'
import EditableUserImage from "../../shared/EditableUserImage";
import ImageCrop from '../../shared/ImageCrop';
import CenteredModal from '../../shared/CenteredModal';
import {processImage} from '../../../actions/users_actions'
import {deleteImage, uploadImage} from "../../../service/users_service";
import PropTypes from "prop-types";


export class ProviderImage extends React.Component {
    state = {
        addOrChangeProfilePicture: false,
        blob: null,
        hasImage: this.props.hasImage,
        hasProfilePicture: true,
        showModal: false,
        showEditCrop: false,
    }

    constructor(props) {
        super(props)
    }

    /**
     * Set state to show the modal
     */
    showModal = () => {
        this.setState({showModal: true});
    }

    /**
     * On confirm modal, continue with image removal.
     */
    onConfirmModal = () => {
        this.onRemoveImage();
        this.setState({showModal: false});
    }

    /**
     * Updates the variable and the state as well
     *
     */
    addOrChangeProfilePicture = () => {
        console.log("called")
        let addOrChangeProfilePicture = !this.state.addOrChangeProfilePicture;
        this.setState({addOrChangeProfilePicture});
    }

    showEditCrop = () => {
        this.setState({showEditCrop: true})
    }


    /**
     * Updates the state
     *
     * @param {boolean} hasPicture - boolean value
     */
    setStateForProfilePicture = (hasPicture) => {
        if (this.state.hasProfilePicture !== hasPicture) {
            this.setState({hasProfilePicture: hasPicture});
        }
    }

    /**
     * Handler for completing the image selection
     *
     * @param {object} data - the image file data
     */
    onImageSelectingComplete = (data) => {
        if (this.props.isLoggedUserProfile) {
            uploadImage(data).then(() => this.setState({hasProfilePicture: true}));
        }
        this.setState({
            blob: URL.createObjectURL(data),
            hasImage: true,
        }, function () {
           this.props.processImage(data);
        });
    }

    /**
     * Handler for completing the image removal
     */
    onRemoveImage = () => {
        if (this.props.isLoggedUserProfile) {
            deleteImage().then(() => this.setState({hasProfilePicture: false}));
        }
        this.setState({
            blob: null,
            hasImage: false
        }, function () {
            this.props.processImage();
        });
    }

    render() {
        return (
            <div>
                <div className="row center-justify">
                    <div style={{display: this.state.showEditCrop ? "none" : ""}}
                         className="text-center provider-img-container">

                        <EditableUserImage blob={this.state.blob}
                                           userID={this.props.userInfo.id}
                                           setProfilePictureState={this.setStateForProfilePicture}
                                           hasProfilePicture={this.state}/>

                        {!this.state.hasImage &&
                            <div>
                                <button className="btn" onClick={this.addOrChangeProfilePicture}>
                                    <i className="kt-nav__link-icon flaticon2-plus-1 profile-pic"/>
                                    <br/>
                                    <div className='provider-img-label'>{$$('wizard_provider_image_label')}</div>
                                </button>
                            </div>
                        }
                        {this.state.hasImage &&
                            <div>
                                <div>
                                    <button className="upload-btn" onClick={this.addOrChangeProfilePicture}>
                                        <i className="kt-nav__link-icon flaticon2-photo-camera profile-pic"/>
                                    </button>
                                </div>
                                <div>
                                    <button className="remove-btn" onClick={this.showModal}>
                                        <i className="kt-nav__link-icon flaticon2-cross profile-pic"/>
                                    </button>
                                </div>
                            </div>
                        }
                    </div>

                    <div className= {this.state.showEditCrop ? "image-crop-container" : ""}>
                        <ImageCrop src={undefined} addOrChangeProfilePicture={this.state.addOrChangeProfilePicture}
                                   onComplete={ (blobUri) => {
                                       fetch(blobUri).then(r => {
                                           return r.blob();
                                       }).then(blob => {
                                           this.onImageSelectingComplete(blob);
                                       });

                                   }
                          /*
                            (blobUri) => {

                                       fetch(blobUri).then(r => {
                                           return r.blob();
                                       })
                                           .then(blob => {
                                               this.onImageSelectingComplete(blob);
                                           });
                                   } */

                                   }
                                   onRemove={() => {
                                       this.onRemoveImage();
                                   }}
                                   showEditCrop = {this.showEditCrop}
                                   hideEditCrop={ ()=> {this.setState({ showEditCrop: false})}}
                                   hasProfilePicture={this.state.hasProfilePicture}
                        />
                    </div>
                </div>

                <CenteredModal title={$$('delete_image_modal_title')}
                               show={this.state.showModal}
                               onHide={() => this.setState({showModal: false})}
                               onConfirm={this.onConfirmModal}
                               confirmBtnLabel={$$('OK')}
                               cancelBtnLabel={$$('cancel_btn')}
                >
                    {$$('delete_image_modal_message')}
                </CenteredModal>
            </div>
        )
    }
}

ProviderImage.propTypes = {
    i18n: PropTypes.object,
    processImage: PropTypes.func,
    hasImage: PropTypes.bool,
    isLoggedUserProfile: PropTypes.bool,
    userInfo: PropTypes.any
}


const mapDispatchToProps = {
    processImage
}

export default connect(null, mapDispatchToProps)(ProviderImage)
