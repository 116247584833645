import useVideoContext from "./hooks/useVideoContext/useVideoContext";
import useIsTrackEnabled from "./hooks/useIsTrackEnabled/useIsTrackEnabled";
import useIsTrackSwitchedOff from "./hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff";
import {VideoControlsOverlay} from "./VideoControlsOverlay";
import React from "react";
import useRoomName from "./hooks/useRoomName/useRoomName";
import useIsRoomForGroupEvent from "./hooks/useIsRoomForGroupEvent/useIsRoomForGroupEvent";
import {usersService} from "../../service/users_service";
import {medicalHistoryHelper} from "../../helpers/medical_history_helper";
import {APPOINTMENTS_APPOINTMENT_VIDEO} from "../../constants/pages";
import {Routes} from "../../constants/routes";

// eslint-disable-next-line react/prop-types
export default function VideoControlsWrap({onHangup}) {
    const context = useVideoContext();
    let audioTrack = context.localTracks.find(track => track.kind === 'audio');
    let isAudioTrackEnabled = useIsTrackEnabled(audioTrack);
    let isAudioTrackSwitchedOff = useIsTrackSwitchedOff(audioTrack);
    let audioMuted = !isAudioTrackEnabled || isAudioTrackSwitchedOff;
    let videoTrack = context.localTracks.find(track => track.kind === 'video');
    let isVideoTrackEnabled = useIsTrackEnabled(videoTrack);
    let isVideoTrackSwitchedOff = useIsTrackSwitchedOff(videoTrack);
    let videoMuted = !isVideoTrackEnabled || isVideoTrackSwitchedOff;
    const existingRoomName = useRoomName();
    const isRoomForGroupEvent = useIsRoomForGroupEvent();
    const onOpen = () => {
        usersService.getAppointmentInfo(existingRoomName.split(":")[0]).then((appointmentInfo)=>{
            if (isRoomForGroupEvent) {
                medicalHistoryHelper.openGroupAppointmentPage(appointmentInfo);
            } else {
                let patient = appointmentInfo.participants.filter(a => a.participant_role === 'PATIENT')[0];
                medicalHistoryHelper.prepareMedicalHistoryData(APPOINTMENTS_APPOINTMENT_VIDEO, patient, appointmentInfo, Routes.APPOINTMENT);
            }
        })
    }

    return (<VideoControlsOverlay modal={false} videoMuted={videoMuted} audioMuted={audioMuted}
                                  onHangup={() => {onHangup(); context.setError()}}
                                  onOpen={onOpen}
    />)
}