import React, {useEffect, useState} from "react"
import {$$} from "../../../../helpers/localization";
import CenteredModal2 from "../../../shared/CenteredModal2";
import FormGroup from "react-bootstrap/FormGroup";


export default function DeleteNhisPrescriptionModal(props) {
    const [revokeReason, setRevokeReason] = useState("");
    const [formClass, setFormClass] = useState("");
    const [s, setS] = useState(props.show)

    useEffect(()=>{
        if (props.show !== s) {
            setS(props.show);
            setRevokeReason("");
            setFormClass("");
        }
    }, [props.show])

    return <CenteredModal2
        title={$$('nhis.delete_prescription')}
        headerClassName="modal-header-class"
        body={$$('nhis.delete_prescription_message')}
        show={props.show}
        onHide={props.onHide}
        cancelBtnLabel={$$('cancel_btn')}
        onConfirm={()=>{
            if (!revokeReason.trim()) {
                setFormClass("was-validated");
                return;
            }
            props.onConfirm(revokeReason)}
        }
        id="delete-modal-nhis-prescription"
        className="delete-modal-body"
        idFooter="delete-modal-footer"
        confirmBtnLabel={$$('delete_label')}
        confirmBtnClass="danger"
        idBtnPrimary="btn-danger"
        primary={!!props.deleteNhisPrescriptionModalError || !!props.deleteNhisPrescriptionInProgress}
        secondary={!!props.deleteNhisPrescriptionInProgress}
        idBtnSecondary="btn-secondary">
        <h5 className={"bold"}>
            {props.selectedNhisPrescriptionNrnToDelete ? props.selectedNhisPrescriptionNrnToDelete : ''}
        </h5>

        {props.deleteNhisPrescriptionModalError && <p className="text-danger">
            {props.deleteNhisPrescriptionModalError}
        </p>}
        {!props.deleteNhisPrescriptionInProgress && <div>{/*<div className="bin-align">
            <i className="fas fa-trash-alt fa-2x"/>
        </div>*/}
            <div className="w-100 p-3 text-left">
                <form noValidate={true} className={formClass}>
                    <FormGroup>
                        <label>{$$("nhis.revoke_reason")}*</label>
                        <textarea className="form-control"
                                  required={true}
                                  value={revokeReason}
                                  rows={4}
                                  maxLength={2000}
                                  onChange={(e)=>{setRevokeReason(e.target.value)}}
                        />
                    </FormGroup>
                </form>
            </div>
        </div>}
        {props.deleteNhisPrescriptionInProgress && <div className="h5 p-2">
            {$$("nhis.processing_body")}
        </div>}

    </CenteredModal2>
}