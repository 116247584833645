export class PermissionValue {

    static READ = new PermissionValue("READ");
    static UPDATE = new PermissionValue("UPDATE");
    static CREATE = new PermissionValue("CREATE");
    static DELETE = new PermissionValue("DELETE");
    static APPEND = new PermissionValue("APPEND");

    constructor(value) {
        this.value = value;
    }

    static fromString(stringValue) {
        switch (stringValue) {
            case "READ": return this.READ;
            case "UPDATE": return this.UPDATE;
            case "CREATE": return this.CREATE;
            case "DELETE": return this.DELETE;
            case "APPEND": return this.APPEND;
            default: return new PermissionValue(stringValue);
        }
    }

    static list() {
        return Object.entries(PermissionValue).map(e => e[1]);
    }

}

export class Role {

    /**
     * ADMIN,
     STAFF, //todo: delete this
     CLINICIAN,
     REGISTRATION_DESK,
     CONSULTING_CLINICIAN,
     LAB_ASSISTANT,
     CUSTOM
     * @type {PermissionValue}
     */

    static ADMIN = new PermissionValue("ADMIN");
    static CLINICIAN = new PermissionValue("CLINICIAN");
    static REGISTRATION_DESK = new PermissionValue("REGISTRATION_DESK");
    static LAB_ASSISTANT = new PermissionValue("LAB_ASSISTANT");
    static CONSULTING_CLINICIAN = new PermissionValue("CONSULTING_CLINICIAN");
    static CUSTOM = new PermissionValue("CUSTOM");

    constructor(value) {
        this.value = value;
    }

    static fromString(stringValue) {
        switch (stringValue) {
            case "ADMIN": return this.ADMIN;
            case "CLINICIAN": return this.CLINICIAN;
            case "REGISTRATION_DESK": return this.REGISTRATION_DESK;
            case "CONSULTING_CLINICIAN": return this.CONSULTING_CLINICIAN;
            case "LAB_ASSISTANT": return this.LAB_ASSISTANT;
            case "CUSTOM": return this.CUSTOM;
            default: return new Role(stringValue);
        }
    }


}

/**
 * STAFF,
 PATIENTS,
 APPOINTMENTS_OWN,
 APPOINTMENTS_OTHER,
 WORK_TIME_OWN,
 WORK_TIME_OTHER,
 PRICE_LIST_OWN,
 PRICE_LIST_OTHERS,
 ENCOUNTER_OWN_APPOINTMENT,
 ENCOUNTER_OTHERS_APPOINTMENT,
 APPOINTMENT_STATUS,
 PAYMENT_STATUS,
 APPOINTMENT_NOTES,
 APPOINTMENT_SHARED_USER_DATA,
 USER_DOCUMENTS,
 PROVIDER_DOCUMENTS_OTHER,
 PROVIDER_DOCUMENTS_OWN,
 TESTS_RESULTS,
 PATIENT_NOTES,
 MED_HISTORY_SHARED_USER_DATA,
 REVENUE_REPORT_OWN,
 REVENUE_REPORT_OTHER
 */


export class PermissionName {
    static STAFF = new PermissionName("STAFF");
    static PATIENTS = new PermissionName("PATIENTS");
    static APPOINTMENTS_OWN = new PermissionName("APPOINTMENTS_OWN");
    static APPOINTMENTS_OTHER = new PermissionName("APPOINTMENTS_OTHER");
    static WORK_TIME_OWN = new PermissionName("WORK_TIME_OWN");
    static WORK_TIME_OTHER = new PermissionName("WORK_TIME_OTHER");
    static PRICE_LIST_OWN = new PermissionName("PRICE_LIST_OWN");
    static PRICE_LIST_OTHERS = new PermissionName("PRICE_LIST_OTHERS");
    static ENCOUNTER_OWN_APPOINTMENT = new PermissionName("ENCOUNTER_OWN_APPOINTMENT");
    static ENCOUNTER_OTHERS_APPOINTMENT = new PermissionName("ENCOUNTER_OTHERS_APPOINTMENT");
    static APPOINTMENT_STATUS = new PermissionName("APPOINTMENT_STATUS");
    static PAYMENT_STATUS = new PermissionName("PAYMENT_STATUS");
    static APPOINTMENT_NOTES = new PermissionName("APPOINTMENT_NOTES");
    static APPOINTMENT_SHARED_USER_DATA = new PermissionName("APPOINTMENT_SHARED_USER_DATA");
    static USER_DOCUMENTS = new PermissionName("USER_DOCUMENTS");
    static PROVIDER_DOCUMENTS_OTHER = new PermissionName("PROVIDER_DOCUMENTS_OTHER");
    static PROVIDER_DOCUMENTS_OWN = new PermissionName("PROVIDER_DOCUMENTS_OWN");
    static TESTS_RESULTS = new PermissionName("TESTS_RESULTS");
    static PATIENT_NOTES = new PermissionName("PATIENT_NOTES");
    static MED_HISTORY_SHARED_USER_DATA = new PermissionName("MED_HISTORY_SHARED_USER_DATA");
    static REVENUE_REPORT_OWN = new PermissionName("REVENUE_REPORT_OWN");
    static REVENUE_REPORT_OTHER = new PermissionName("REVENUE_REPORT_OTHER");

    constructor(name) {
        this.name = name;
    }

    static fromString(stringName) {
        let entries = Object.entries(PermissionName).filter(e => e[0] === stringName);
        if (entries.length > 0) {
            return entries[0][1];
        }
        return null;
    }

    static list() {
        return Object.entries(PermissionName).map(e => e[1].name);
    }
}



export class Permissions {

    constructor(role, permissions, rolePermissions) {
        this.role = Role.fromString(role);
        this.permissions = Permissions.extract(permissions);
        this.rolePermissions = Permissions.extract(rolePermissions);
    }

    static fromOrg(organization) {
        return new Permissions(organization.role, organization.permissions, organization.rolePermissions);
    }

    static extract(orgPermissions) {
        if (!orgPermissions) {
            return {};
        }

        let permissions = {};

        Object.keys(orgPermissions).forEach(key => {
            let listOfPermissionValues = orgPermissions[key];
            let name = PermissionName.fromString(key);
            if (name) {
                permissions[name.name] = listOfPermissionValues.map(v => PermissionValue.fromString(v));
            }
        })
        return permissions;
    }

    static hasValues(permissionsValues, p) {
        return p.reduce((accumulator, currentValue) => accumulator && permissionsValues.includes(currentValue), true);
    }

    hasPermissions(name, ...p) {
        let exists = this.permissions.hasOwnProperty(name.name);
        return exists && Permissions.hasValues(this.permissions[name.name], p)
    }

    hasRolePermissions(name, ...p) {
        let exists = this.rolePermissions.hasOwnProperty(name.name);
        return exists && Permissions.hasValues(this.rolePermissions[name.name], p)
    }

    canCreatePatient() {
        return this.hasPermissions(PermissionName.PATIENTS, PermissionValue.CREATE)
    }

    canListPatient() {
        return this.hasPermissions(PermissionName.PATIENTS, PermissionValue.READ)
    }

    canUpdatePatient() {
        return this.hasPermissions(PermissionName.PATIENTS, PermissionValue.UPDATE)
    }

    canSeeStaff() {
        return this.hasPermissions(PermissionName.STAFF, PermissionValue.READ)
    }

    canSeeStaffPrices() {
        return this.hasPermissions(PermissionName.PRICE_LIST_OTHERS, PermissionValue.READ)
    }

    canEditStaffPrices() {
        return this.hasPermissions(PermissionName.PRICE_LIST_OTHERS, PermissionValue.UPDATE)
    }

    canCreateStaffPrices() {
        return this.hasPermissions(PermissionName.PRICE_LIST_OTHERS, PermissionValue.CREATE)
    }

    canDeleteStaffPrices() {
        return this.hasPermissions(PermissionName.PRICE_LIST_OTHERS, PermissionValue.DELETE)
    }

    canSeeOwnPrice() {
        return this.hasPermissions(PermissionName.PRICE_LIST_OWN, PermissionValue.READ)
    }

    canEditOwnPrices() {
        return this.hasPermissions(PermissionName.PRICE_LIST_OWN, PermissionValue.UPDATE)
    }

    canCreateOwnPrices() {
        return this.hasPermissions(PermissionName.PRICE_LIST_OWN, PermissionValue.CREATE)
    }

    canDeleteOwnPrices() {
        return this.hasPermissions(PermissionName.PRICE_LIST_OWN, PermissionValue.DELETE)
    }

    canSeeOthersWorktime() {
        return this.hasPermissions(PermissionName.WORK_TIME_OTHER, PermissionValue.READ)
    }

    canUpdateOthersWorktime() {
        return this.hasPermissions(PermissionName.WORK_TIME_OTHER, PermissionValue.UPDATE)
    }

    canSeeOwnWorktime() {
        return this.hasPermissions(PermissionName.WORK_TIME_OWN, PermissionValue.READ)
    }

    canUpdateStaff() {
        return this.hasPermissions(PermissionName.STAFF, PermissionValue.UPDATE)
    }

    canCreateStaff() {
        return this.hasPermissions(PermissionName.STAFF, PermissionValue.CREATE)
    }

    canUpdateOwnWorktime() {
        return this.hasPermissions(PermissionName.WORK_TIME_OWN, PermissionValue.UPDATE)
    }

    canCreateOwnWorktime() {
        return this.hasPermissions(PermissionName.WORK_TIME_OWN, PermissionValue.CREATE)
    }

    canCreateOthersWorktime() {
        return this.hasPermissions(PermissionName.WORK_TIME_OTHER, PermissionValue.CREATE)
    }

    canSeeAppointments() {
        return this.canSeeOwnAppointments() || this.canSeeOthersAppointments()
    }

    canSeeOwnAppointments() {
        return this.hasPermissions(PermissionName.APPOINTMENTS_OWN, PermissionValue.READ)
    }

    canSeeOthersAppointments() {
        return this.hasPermissions(PermissionName.APPOINTMENTS_OTHER, PermissionValue.READ)
    }

    canCreateOwnAppointment() {
        return this.hasPermissions(PermissionName.APPOINTMENTS_OWN, PermissionValue.CREATE)
    }

    canCreateOthersAppointment() {
        return this.hasPermissions(PermissionName.APPOINTMENTS_OTHER, PermissionValue.CREATE)
    }

    canCreateAppointment() {
        return this.canCreateOwnAppointment() || this.canCreateOthersAppointment();
    }

    canEditAppointmentOwn() {
        return this.hasPermissions(PermissionName.APPOINTMENTS_OWN, PermissionValue.UPDATE)
    }

    canEditAppointmentOthers() {
        return this.hasPermissions(PermissionName.APPOINTMENTS_OTHER, PermissionValue.UPDATE)
    }

    canChangeAppointmentStatusOwn() {
        return this.hasUpdateOnAppointmentStatus();
    }

    canChangeAppointmentStatusOthers() {
        return this.canSeeOthersAppointments() && this.hasUpdateOnAppointmentStatus()
    }

    hasUpdateOnAppointmentStatus() {
        return this.hasPermissions(PermissionName.APPOINTMENT_STATUS, PermissionValue.UPDATE)
    }

    canChangePaymentStatusOthers() {
        return this.canSeeOthersAppointments() && this.hasUpdateOnPaymentStatus()
    }

    canChangePaymentStatusOwn() {
        return this.hasUpdateOnPaymentStatus()
    }

    hasUpdateOnPaymentStatus() {
        return this.hasPermissions(PermissionName.PAYMENT_STATUS, PermissionValue.UPDATE);
    }

    canReadAppointmentNotes() {
        return this.hasPermissions(PermissionName.APPOINTMENT_NOTES, PermissionValue.READ);
    }

    canCreateOwnProviderDocument() {
        return this.hasPermissions(PermissionName.PROVIDER_DOCUMENTS_OWN, PermissionValue.CREATE);
    }

    canCreateOwnProviderDocumentForOtherEncounter() {
        //read on appointment, append on encounter other, anc canCreateOwnProviderDocument
        return this.canSeeOthersAppointments() && this.hasPermissions(PermissionName.ENCOUNTER_OTHERS_APPOINTMENT, PermissionValue.APPEND)
            && this.hasPermissions(PermissionName.PROVIDER_DOCUMENTS_OWN, PermissionValue.CREATE);
    }

    canEditTestResultsOwnEncounter() {
        return this.hasPermissions(PermissionName.TESTS_RESULTS, PermissionValue.UPDATE);
    }

    canEditTestResultsOthersEncounter() {
        return this.canSeeOthersAppointments() && this.hasPermissions(PermissionName.ENCOUNTER_OTHERS_APPOINTMENT, PermissionValue.APPEND)
            && this.canEditTestResultsOwnEncounter();
    }

    canCreateTestResultsOwnEncounter() {
        return this.hasPermissions(PermissionName.TESTS_RESULTS, PermissionValue.CREATE);
    }

    canCreateTestResultsOthersEncounter() {
        return this.canSeeOthersAppointments() && this.hasPermissions(PermissionName.ENCOUNTER_OTHERS_APPOINTMENT, PermissionValue.APPEND)
            && this.canCreateTestResultsOwnEncounter();
    }

    canDeleteTestResultsOwnEncounter() {
        return this.hasPermissions(PermissionName.TESTS_RESULTS, PermissionValue.DELETE);
    }

    canDeleteTestResultsOthersEncounter() {
        return this.canSeeOthersAppointments() && this.hasPermissions(PermissionName.ENCOUNTER_OTHERS_APPOINTMENT, PermissionValue.APPEND)
            && this.canDeleteTestResultsOwnEncounter();
    }

    canCreateAppointmentNoteOwn() {
        return this.hasPermissions(PermissionName.APPOINTMENT_NOTES, PermissionValue.CREATE);
    }

    canCreateAppointmentNoteOther() {
        return this.canSeeOthersAppointments() && this.hasPermissions(PermissionName.APPOINTMENT_NOTES, PermissionValue.CREATE);
    }
}