import React from 'react'
import {render} from 'react-dom'
import App from './App'
import {Provider} from 'react-redux'
import 'bootstrap/dist/js/bootstrap.min.js'
//import 'bootstrap/scss/bootstrap.scss'
import 'react-datepicker/dist/react-datepicker.css'
import './app.scss'
import './flags.css'
import {BrowserRouter} from 'react-router-dom'
import store from './store';
import {ThemeProvider} from "./_metronic";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

import dicomParser from 'dicom-parser';
import cornerstone from 'cornerstone-core';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader'
import cornerstoneMath from 'cornerstone-math';
import cornerstoneTools from 'cornerstone-tools';
import Hammer from 'hammerjs';


// Cornerstone Tools
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.init();


// Image Loaders
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWADOImageLoader.webWorkerManager.initialize({
    maxWebWorkers: navigator.hardwareConcurrency || 1,
    startWebWorkersOnDemand: true,
    taskConfiguration: {
        decodeTask: {
            initializeCodecsOnStartup: false,
            usePDFJS: false,
            strict: false,
        },
    },
});

cornerstoneWADOImageLoader.configure({
    beforeSend: function (xhr) {
        // Add custom headers here (e.g. auth tokens)
        xhr.setRequestHeader('Authorization', 'Bearer ' + store.getState().authentication.data.access_token);
    }
});

cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneWebImageLoader.configure({
    beforeSend: function (xhr) {
        // Add custom headers here (e.g. auth tokens)
        xhr.setRequestHeader('Authorization', 'Bearer ' + store.getState().authentication.data.access_token);
    }
});

window.strings = {}
// eslint-disable-next-line no-undef
Object.entries(require('./i18n')).forEach(([langKey, language]) => window.strings[langKey] = language);
// eslint-disable-next-line react/prop-types
const Root = ({store}) => (
    <Provider store={store}>
        <BrowserRouter>
            <ThemeProvider>
                <App/>
            </ThemeProvider>
        </BrowserRouter>
    </Provider>
);

render(<Root store={store}/>, document.getElementById('root'))
