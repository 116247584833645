import moment from "moment";
import {$$} from '../helpers/localization'
import {CONVERTER} from "./converter";

export const dateTimeUtils = {
    toOffsetDateTime,
    getUtcOffset,
    getFormattedDate,
    getFormattedDateWithTime,
    getDayNames,
    getFormattedTime,
    getElapsedTime,
    getFormattedDateWithTimeWithOffset
}

function toOffsetDateTime(date) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ssZZ');
}

function getFormattedDate(date) {
    return (moment(date).format(CONVERTER.getSelectedDateTimeFormat(true)))
}

function getFormattedDateWithTime(date) {
    return (moment(date).format(CONVERTER.getSelectedDateTimeFormat(false)))
}

function getFormattedDateWithTimeWithOffset(date, offset) {
    return (moment(date).add(offset, 'days').format(CONVERTER.getSelectedDateTimeFormat(false)))
}

function getElapsedTime(date) {
    return moment(date).fromNow()
}

function getFormattedTime(date) {
    return CONVERTER.formatTime(date);
}

function getUtcOffset(date) {
    return moment(date)
        .subtract(
            moment(date).utcOffset(),
            'minutes')
        .utc()
}

function getDayNames(day) {

    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

    return $$(days[day - 1]);
}
