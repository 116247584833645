import React from "react"
import {$$} from "../../helpers/localization";

export default function ExamLinkIcon({onClick, labelKey}) {
    return <div className="mt-3 ml-3 medrec-blue-1">
                <a href="#" className="pointer" onClick={(e) => {e.preventDefault(); onClick(e)}}>
                    {$$(labelKey)}
                    <span className="fa fa-plus add-prescription"/>
                </a>
    </div>
}