import React, {Component} from 'react'
import {connect} from 'react-redux'
import {
    clearPatients,
    getAllPatients
} from '../../actions/users_actions'
import {medicalHistoryHelper} from '../../helpers/medical_history_helper'
import PatientsHeader from './PatientsHeader'
import PatientsListView from './PatientsListView'
import PatientsGridView from './PatientsGridView'
import {Routes} from '../../constants/routes'
import {
    PATIENTS_PATIENT_CHAT,
    PATIENTS_PATIENT_MED_HISTORY
} from '../../constants/pages'
import no_appointments from "../../resources/images/no_appointments.png";
import {$$} from "../../helpers/localization";
import {infoUtils} from "../../utils/infoUtils";
import {setUserSelections} from "../../actions/user_selections_actions";
import PropTypes from "prop-types";
import PaginationComponent from "../common/Pagination";

const PAGE_SIZE = 10

class Patients extends Component {
    state = {
        searchValue: '',
        gridView: this.props.userSelections.patients.view === "Grid",
        page: 0,
        loading: true
    }

    constructor(props) {
        super(props);
        this.debouncedSearch = _.debounce(() => {
            this.search();
        }, 250, {leading: true});
    }

    componentDidMount = async () => {
        await this.props.getAllPatients({page: this.state.page, search: this.state.searchValue, size: PAGE_SIZE}, null, true).then(() => {
            this.setState({loading: false})
        })
    }

    componentWillUnmount() {
        this.props.clearPatients();
    }

    search = () => {
        this.props.getAllPatients({page: this.state.page, search: this.state.searchValue, size: PAGE_SIZE}, null, true)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.searchValue !== this.state.searchValue) {
            this.debouncedSearch();
        }
    }

    /**
     * Sets the state based on what is entered by the user
     *
     * @param {string} searchValue - the value entered by the user, used for filtering purposes
     */
    filterUsers = (searchValue) => {
        this.setState({searchValue, page: 0, size: PAGE_SIZE});
    }

    /**
     * Sets the state based on the view selected by the user
     */
    changeView = () => {
        this.setState({gridView: !this.state.gridView});
        this.props.userSelections.patients.view = !this.state.gridView ? "Grid" : "List";
        this.props.setUserSelections(this.props.userSelections, null);
    }

    /**
     * Prepares the data for the Medical History page
     */
    prepareMedicalHistoryPageData = (patient) => {
        medicalHistoryHelper.prepareMedicalHistoryData(PATIENTS_PATIENT_MED_HISTORY, patient);
    }

    /**
     * Prepares the data for the Messages History page
     *
     * @param {patient} patient - the patient object
     * @returns true
     */
    prepareMessagesPageData = (patient) => {
        medicalHistoryHelper.prepareMessagesData(PATIENTS_PATIENT_CHAT, patient, Routes.MESSAGES);
    }

    renderBody = () => {
        if (this.props.users_data.list && this.props.users_data.list.length > 0) {
            if (this.state.gridView) {
                return <PatientsGridView
                    users={this.props.users_data}
                    onMedicalHistoryClick={this.prepareMedicalHistoryPageData}
                    onMessagesClick={this.prepareMessagesPageData}
                />
            } else {
                return <PatientsListView
                    users={this.props.users_data} o
                    onMedicalHistoryClick={this.prepareMedicalHistoryPageData}
                    onMessagesClick={this.prepareMessagesPageData}
                />
            }
        } else if (this.props.users_data.request.finished) {
            let noPatients = {
                imgClass: 'no-vitals-logbook-img',
                primaryLabelClass: 'no-lab-results-primary-label',
                secondaryLabelClass: 'no-lab-results-secondary-label',
                src: no_appointments,
                primaryLabel: $$('no_patients_primary_label'),
                secondaryLabel: ''
            }
            return infoUtils.noData(noPatients);
        }
    }

    render() {
        return (
            <div className='patient-card'>
                <PatientsHeader filterUsers={this.filterUsers}
                                changeView={this.changeView}
                                userSelections={this.props.userSelections}/>
                {this.state.loading && <div className={"report-loader"}/>}
                {!this.state.loading && this.renderBody()}
                {this.props.users_data.list && this.props.users_data.list.length > 0 &&
                    <div className="pl-3 pt-3">
                        <PaginationComponent alwaysShown={false} itemsCount={this.props.users_data.totalElements}
                                             currentPage={this.state.page + 1} itemsPerPage={PAGE_SIZE}
                                             setCurrentPage={(p) => {
                                                 this.setState({page: p - 1}, this.search)
                                             }}/>
                    </div>}
            </div>
        )
    }
}

Patients.propTypes = {
    setUserSelections: PropTypes.func,
    getAllPatients: PropTypes.func,
    clearPatients: PropTypes.func,
    userSelections: PropTypes.object,
    users_data: PropTypes.object
}

const mapStateToProps = store => ({
    users_data: store.users,
    userSelections: store.userSelections

});

export default connect(mapStateToProps, {getAllPatients, clearPatients, setUserSelections})(Patients);
