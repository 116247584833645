import {notificationsService} from "../service/notifications_service"
import {
    CLEAR_NOTIFICATIONS,
    GET_MORE_NOTIFICATIONS_REQUEST_SUCCESS,
    GET_NOTIFICATIONS_REQUEST_ERROR,
    GET_NOTIFICATIONS_REQUEST_SENT,
    GET_NOTIFICATIONS_REQUEST_SUCCESS,
    UPDATE_NOTIFICATION_STATUS_REQUEST_ERROR,
    UPDATE_NOTIFICATION_STATUS_REQUEST_SENT,
    UPDATE_NOTIFICATION_STATUS_REQUEST_SUCCESS
} from "./actions"

/**
 * Get all users action, call the service and dispatch the appropiate reducer method
 *
 * @param {number} limit query param sent together with the request
 * @param {number} offset query param sent together with the request
 * @param {boolean} onlyNotSeen query param sent together with the request
 * @param {boolean} loadingMore set true if loading more notifications
 * @returns {function} dispatch function
 */
export function getAllNotifications(limit, offset, onlyNotSeen, loadingMore) {
    return (dispatch) => {
        dispatch({type: GET_NOTIFICATIONS_REQUEST_SENT});
        notificationsService.getAllNotifications(limit, offset, onlyNotSeen)
            .then((res) => {
                if (res.length) {
                    if (loadingMore) {
                        dispatch({type: GET_MORE_NOTIFICATIONS_REQUEST_SUCCESS, result: res});
                    } else {
                        dispatch({type: GET_NOTIFICATIONS_REQUEST_SUCCESS, result: res});
                    }
                }
            })
            .catch((err) => {
                dispatch({type: GET_NOTIFICATIONS_REQUEST_ERROR, result: err});
            })
    }
}

/**
 * Mark notification as seen
 *
 * @param {string} notificationId id of the notification to be marked as seen
 * @param {number} limit query param sent together with the request
 * @param {number} offset query param sent together with the request
 * @param {boolean} onlyNotSeen query param sent together with the request
 * @param {boolean} loadingMore set true if loading more notifications
 * @returns {function} dispatch function
 */
export function markNotificationAsSeen(notificationId, limit, offset, onlyNotSeen, loadingMore) {
    return (dispatch) => {
        dispatch({type: UPDATE_NOTIFICATION_STATUS_REQUEST_SENT});
        notificationsService.markNotificationAsSeen(notificationId)
            .then((res) => {
                if (res) {
                    dispatch({type: UPDATE_NOTIFICATION_STATUS_REQUEST_SUCCESS, result: res});
                    dispatch(getAllNotifications(limit, offset, onlyNotSeen, loadingMore));
                }
            })
            .catch((err) => {
                dispatch({type: UPDATE_NOTIFICATION_STATUS_REQUEST_ERROR, result: err});
            })
    }
}

/**
 * Clear the selected encounter for Appointments page.
 *
 * @returns {function} dispatch function
 */
export function clearNotifications() {
    return (dispatch) => {
        dispatch({type: CLEAR_NOTIFICATIONS});
    }
}
