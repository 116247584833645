import React, {Component} from "react";
import {Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PropTypes from "prop-types";

export default class NotesRoundButton extends Component {
    constructor(props, context) {
        super(props, context);
    }

    renderTooltip = props => (
        <Tooltip {...props} show={props.show.toString()}>{this.props.title}</Tooltip>
    );

    render() {
        return <OverlayTrigger
            placement={"top"}
            overlay={this.renderTooltip}
        >
            <div {...this.props} className="btn btn-outline-primary btn-icon btn-icon-sm btn-circle btn-sm">
                <i className='fas fa-sticky-note centered-text'/>
            </div>
        </OverlayTrigger>
    }
}

NotesRoundButton.propTypes = {
    title: PropTypes.string
}