import React, { useCallback, useEffect, useState } from "react";
import { NhisEReferralProvider, useNhisEReferralContext } from "./NhisEReferralProvider";
import { $$ } from "../../../../helpers/localization";
import { FG, FG50SmallL, FG50SmallR, FormRow, Input, InputTextArea, RequiredIndicator, SectionHeader } from "./HelperFormComponents";
import { v4 } from "uuid";
import { Select } from "../../../shared/Select";
import { AdditionalDiagnosis, Buttons } from "./NhisExamination";
import { NhisExaminationProvider } from "./NhisExaminationProvider";
import { SearchableSelectAsync } from "./SearchableSelect";
import { components } from "react-select";
import { BoolSwitch } from "./BoolSwitch";
import { Button, ButtonGroup } from "react-bootstrap"
import CenteredModal from "../../../shared/CenteredModal";
import DatePicker from "react-datepicker"
import PatientProviderForm from "./PatientProviderForm"
import {dateAsStr} from "./NhisPrescription";
import {has} from "underscore";

export function NhisEReferral({ encounter, onClose, onSuccess, orgId, provider, patient, lang, eRef }) {
    return <NhisExaminationProvider>
        <NhisEReferralProvider>
            <div className={"minus-twenty-margin-top"}>
                <div className="ml-4 mr-4">
                    <Content onClose={onClose}
                             onSuccess={onSuccess}
                             orgId={orgId}
                             patient={patient}
                             provider={provider}
                             lang={lang}
                             encounter={encounter}
                             eRef={eRef}
                    />
                </div>
            </div>
        </NhisEReferralProvider>
    </NhisExaminationProvider>
}

function Content({ encounter, onClose, onSuccess, orgId, provider, patient, lang, eRef }) {
    const [category, setCategory] = useState("");
    const [financingSource, setFinancingSource] = useState("");

    const [step, setStep] = useState(1);
    const [eReferral, setEReferral] = useState(eRef || {})
    const [showCloseConfirm, setShowCloseConfirm] = useState(false);

    const {
        isLoading, isError, reload,
        categoryOptions
    } = useNhisEReferralContext();

    const onClosing = () => {
        setShowCloseConfirm(true);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "instant"
        });
    }, [])

    if (isError && !isLoading) {
        return <div className="w-100">
            <h4 className="pl-0 pr-0 pt-2 pb-2">{$$("nhis.ereferral.title")}</h4>

            <div className="mt-4 mb-4">
                <p className={"text-center text-danger"}>{$$("nhis.error_loading_nomenclatures")}</p>
            </div>

            <div className="d-flex justify-content-end">
                <Button type="button" variant="primary" onClick={reload}>{$$("nhis.try_again")}</Button>
                <Button variant={"outline-primary"} type={"button"} className="ml-2"
                        onClick={onClose}>{$$("cancel_btn")}</Button>
            </div>

        </div>
    }

    if (isLoading) {
        return <div>
            <h4 className="pl-0 pr-0 pt-2 pb-2">{$$("nhis.ereferral.title")}</h4>
            <div className="mt-4 mb-4 w-100">
                <div className={"text-center mt-4"}>{$$("nhis.loading_data_message")}</div>
            </div>
        </div>
    }

    return <div style={{ "width": "100%" }}>
        <h4 className="pl-0 pr-0 pt-2 pb-2">{category ? categoryOptions.find(c => c.value === category).label : $$("nhis.ereferral.title")}</h4>
        {step === 1 && <PersonalForms
            patient={patient}
            provider={provider}
            orgId={orgId}
            lang={lang}
            onCancel={onClosing}
            model={{ performer: eReferral.requester, subject: eReferral.subject }}
            onUpdate={(patient, provider) => {
                let copy = { ...eReferral };
                copy.requester = provider;
                copy.subject = patient;
                if (!copy.contents) {
                    copy.contents = {};
                }
                setEReferral(copy);
                setStep(2);
                window.scrollTo({
                    top: 0,
                    behavior: "instant"
                });
            }}

        />}
        {step !== 1 && !category && <NhisEreferralTypeChooser
            content={eReferral.contents || {}}
            onSuccess={({ category, type, rhifAreaNumber, financingSource, viewModel }) => {
                setCategory(category);
                setFinancingSource(financingSource)
                let copy = { ...eReferral };
                if (!copy.contents) {
                    copy.contents = {};
                }
                copy.contents.category = category;
                copy.contents.type = type;
                copy.contents.rhifAreaNumber = rhifAreaNumber;
                copy.contents.financingSource = financingSource;
                copy.viewModel = viewModel;

                if (eReferral.contents?.category !== category) {
                    copy.contents.laboratory = null;
                    copy.contents.consultation = null;
                    copy.contents.specializedActivities = null;
                    copy.contents.hospitalization = null;
                    copy.contents.medicalExpertise = null;
                    copy.contents.workIncapacity = null;
                }
                setEReferral(copy);
                window.scrollTo({
                    top: 0,
                    behavior: "instant"
                });
            }} onClose={onClosing} />}

        {step !== 1 && category && <NhisEReferralForm
            onClose={onClosing}
            onSuccess={(data, viewModel) => {
                let copy = { ...eReferral };
                copy.contents = { ...eReferral.contents, ...data, authoredOn:dateAsStr(new Date())  }
                copy.viewModel = viewModel;
                setEReferral(copy);
                onSuccess(copy);
            }}
            orgId={orgId}
            provider={provider}
            patient={patient}
            lang={lang}
            category={category}
            financingSource={financingSource}
            content={eReferral.contents}
            viewModel={eReferral.viewModel}
        />}
        {showCloseConfirm && <CloseConfirmModal onConfirm={()=>{
            window.scrollTo({
                top: 0,
                behavior: "instant"
            });
            onClose();
        }} onClose={()=>setShowCloseConfirm(false)} />}
    </div>
}

function PersonalForms({ patient, provider, orgId, model, lang, onUpdate, onCancel }) {
    const {
        countyOptions,
        specialtyOptions,
        ekatteOptions,
        rhifAreaNumberOptions
    } = useNhisEReferralContext();

    return <div>
        <PatientProviderForm countyOptions={countyOptions}
                             subject={patient}
                             provider={provider}
                             specialtyOptions={specialtyOptions}
                             orgId={orgId}
                             exam={model}
                             lang={lang}
                             ekatteOptions={ekatteOptions}
                             providerRhifAreaNumberOptions={rhifAreaNumberOptions}
                             onUpdate={onUpdate} />
        <div className="mt-3 text-right">
            <Button variant={"primary"} form={"patient_provider_form"}
                    type={"submit"}>{$$("nhis.btn.save")}</Button>
            <Button variant={"outline-primary"} type={"button"} className="ml-2"
                    onClick={onCancel}>{$$("cancel_btn")}</Button>
        </div>
    </div>
}

function getLabelOrNull(value, options) {
    let opt = options.find(o => value === o.value);
    return opt ? opt.label : null;
}

function NhisEreferralTypeChooser({ onClose, onSuccess, content, lang }) {
    const {
        categoryOptions,
        rhifAreaNumberOptions,
        financingSourceOptions,
        typeOptions
    } = useNhisEReferralContext();

    const [category, setCategory] = useState(content.category || null);
    const [formClass, setFormClass] = useState("");
    const [type, setType] = useState(content.type || null);
    const [rhifAreaNumber, setRhifAreaNumber] = useState(content.rhifAreaNumber || null);
    const [financingSource, setFinancingSource] = useState(content.financingSource || null);

    useEffect(() => {
        if (type && !(financingSource == 2 && ['R1', 'R2', 'R3'].includes(category))) {
            setType(null);
        }
    }, [category, financingSource])


    return <form noValidate={true} className={formClass} onSubmit={(e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            setFormClass("was-validated");
            return;
        }
        let viewModel = {
            category: getLabelOrNull(category, categoryOptions),
            type: getLabelOrNull(type, typeOptions),
            rhifAreaNumber: getLabelOrNull(rhifAreaNumber, rhifAreaNumberOptions),
            financingSource: getLabelOrNull(financingSource, financingSourceOptions)
        }
        onSuccess({ category, financingSource, rhifAreaNumber, type, viewModel })
    }}>

        <FormRow>
            <FG50SmallL>
                <label>{$$("nhis.ereferral.financingSource")}<RequiredIndicator /></label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        options={financingSourceOptions}
                        class="custom-select-sm"
                        value={financingSource || ""}
                        required={true}
                        onChange={({ value }) => {
                            setFinancingSource(value);
                        }}
                />
            </FG50SmallL>
            <FG50SmallR>
                <label>{$$("nhis.ereferral.category")}<RequiredIndicator /></label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        options={categoryOptions}
                        class="custom-select-sm"
                        value={category || ""}
                        required={true}
                        onChange={({ value }) => {
                            setCategory(value);
                        }}
                />
            </FG50SmallR>
        </FormRow>
        <FormRow>
            <FG50SmallL>
                <label>{$$("nhis.ereferral.rhifAreaNumber")}{financingSource == 2 && <RequiredIndicator />}</label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        options={rhifAreaNumberOptions}
                        class="custom-select-sm"
                        value={rhifAreaNumber || ""}
                        required={financingSource == 2}
                        onChange={({ value }) => {
                            setRhifAreaNumber(value);
                        }}
                />
            </FG50SmallL>
            {financingSource == 2 && ['R1', 'R2', 'R3'].includes(category) && <FG50SmallR>
                <label>{$$("nhis.ereferral.type")}<RequiredIndicator /></label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        options={typeOptions}
                        class="custom-select-sm"
                        value={type || ""}
                        required={true}
                        onChange={({ value }) => {
                            setType(value);
                        }}
                />
            </FG50SmallR>}
        </FormRow>
        <Buttons onCancel={onClose} />
    </form>

}

function NhisEReferralForm({ onClose, category, financingSource, content, viewModel, onSuccess, orgId, provider, patient, lang }) {
    const [formClass, setFormClass] = useState("");
    const [lrn, setLrn] = useState(content?.lrn || v4())

    const [mainDiagnoses, setMainDiagnoses] = useState(content?.mainDiagnoses || [{ rank: 1 }])
    const [additionalDiagnoses, setAdditionalDiagnoses] = useState(content?.additionalDiagnoses || [])

    const [note, setNote] = useState(content?.note || "")

    const [i18n, setI18n] = useState({ lang: lang });

    const [data, setData] = useState(_.cloneDeep(content))
    const [vm, setVM] = useState(_.cloneDeep(viewModel))

    useEffect(() => {
        setI18n({ lang: lang });
    }, [lang]);

    const scrollToInvalid = (form) => {
        const invalidInputs = Array.from(form.querySelectorAll(':invalid, .is-invalid')).filter(n => n.localName !== "fieldset");    // set up so you can use any custom invalid classes you're adding to your elements, as well
        invalidInputs.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);
        // sort inputs by offset from top of viewport (handles issues with multi-column layouts, where the first element in the markup isn't necessarily the highest on the page)
        invalidInputs[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (!e.target.checkValidity()) {
            setFormClass("was-validated");

            /*if (!e.target.checkValidity()) {
                document.getElementById("required-exam-information").scrollIntoView({
                    block: "start",
                    behavior: "smooth"
                })
            }*/
            scrollToInvalid(e.target)
            return;
        }
        let content = {
            ...data
        }

        let vModel = {
            ...vm,
            note: note,
        }

        content.lrn = lrn;
        content.mainDiagnoses = mainDiagnoses;
        content.additionalDiagnoses = additionalDiagnoses;
        content.note = note;
        onSuccess(content, vModel)
    }

    return <div className="nhis-ereferral">

        <form className={`has-custom-validation ${formClass}`} onSubmit={onSubmit}
              noValidate={true}>
            <div className="pt-0">
                <fieldset>


                    {category === 'R1' && //laboratory
                    <Laboratory laboratory={data.laboratory}
                                financingSource={financingSource}
                                onUpdate={(c, viewModel) => {
                                    const copy = { ...data };
                                    copy.laboratory = c;
                                    setData(copy);
                                    setVM({...vm, ...viewModel})
                                }}
                    />
                    }


                    {category === 'R2' && //consultation
                    <Consultation consultation={data.consultation}
                                  onUpdate={(c, viewModel) => {
                                      const copy = { ...data };
                                      copy.consultation = c;
                                      setData(copy);
                                      setVM({...vm, ...viewModel})
                                  }}
                    />
                    }

                    {category === 'R3' && //specializedActivities
                    <SpecializedActivities specializedActivities={data.specializedActivities}
                                           viewModel={vm}
                                           onUpdate={(c, viewModel) => {
                                               const copy = { ...data };
                                               copy.specializedActivities = c;
                                               setData(copy);
                                               setVM({...vm, ...viewModel})
                                           }}
                    />
                    }

                    {category === 'R4' && //hospitalization
                    <Hospitalization hospitalization={data.hospitalization}
                                     viewModel={vm}
                                     onUpdate={(h, viewModel) => {
                                         const copy = { ...data };
                                         copy.hospitalization = h;
                                         setData(copy);
                                         setVM({...vm, ...viewModel})
                                     }}
                    />
                    }

                    {category === 'R5' && //medicalExpertise
                    <MedicalExpertise medicalExpertise={data.medicalExpertise}
                                      viewModel={vm}
                                      onUpdate={(h, viewModel) => {
                                          const copy = { ...data };
                                          copy.medicalExpertise = h;
                                          setData(copy);
                                          setVM({...vm, ...viewModel})
                                      }}
                                      financingSource={financingSource}
                    />
                    }

                    {category === 'R8' && //workIncapacity
                    <WorkIncapacity workIncapacity={data.workIncapacity}
                                    lang={lang}
                                    viewModel={vm}
                                    onUpdate={(h, viewModel) => {
                                        const copy = { ...data };
                                        copy.workIncapacity = h;
                                        setVM({...vm, ...viewModel})
                                        setData(copy);
                                    }}
                    />
                    }

                    {
                        //diagnosis
                    }
                    <div className={"low-shadow-container p-3 mt-4 mb-4"}>
                        <AdditionalDiagnosis diagnoses={mainDiagnoses}
                                             onAddNew={() => {
                                                 setMainDiagnoses([...mainDiagnoses, { rank: mainDiagnoses.length + 2 }])
                                             }}
                                             onRemove={(i) => {
                                                 let updated = [...mainDiagnoses];
                                                 updated.splice(i, 1);
                                                 updated.forEach((d, i) => d.rank = i + 2)
                                                 setMainDiagnoses([...updated])
                                             }}
                                             onUpdate={(d, i) => {
                                                 let updated = [...mainDiagnoses];
                                                 updated[i] = d
                                                 setMainDiagnoses(updated)
                                             }}
                                             i18n={i18n}
                                             lock={true}
                                             additional={false}
                                             title="nhis.ereferral.main_diagnoses"
                                             min={1}
                                             required={true}
                                             validated={formClass === 'was-validated'}
                        />
                    </div>

                    {
                        //comorbidity
                    }
                    <div className={"low-shadow-container p-3 mt-4 mb-4"}>
                        <AdditionalDiagnosis diagnoses={additionalDiagnoses}
                                             onAddNew={() => {
                                                 setAdditionalDiagnoses([...additionalDiagnoses, { rank: additionalDiagnoses.length + 2 }])
                                             }}
                                             onRemove={(i) => {
                                                 let updated = [...additionalDiagnoses];
                                                 updated.splice(i, 1);
                                                 updated.forEach((d, i) => d.rank = i + 2)
                                                 setAdditionalDiagnoses([...updated])
                                             }}
                                             onUpdate={(d, i) => {
                                                 let updated = [...additionalDiagnoses];
                                                 updated[i] = d
                                                 setAdditionalDiagnoses(updated)
                                             }}
                                             i18n={i18n}
                                             title="nhis.ereferral.additionalDiagnoses"
                                             validated={formClass === 'was-validated'}
                                             required={true}
                        />
                    </div>
                    {
                        //note
                    }
                    <FG>
                        <InputTextArea type="textarea" value={note || ""} label={"nhis.ereferral.note"}
                                       required={false}
                                       maxLength={8000}
                                       rows={4}
                                       setValue={(v) => {
                                           setNote(v);
                                       }} />
                    </FG>
                    <Buttons onCancel={onClose} />
                </fieldset>
            </div>
        </form>
    </div>
}

const SpecialtyOption = (props) => {
    return (
        <components.Option {...props}>{props.children}
            <div className="small medrec-grey-2">{props.data.item.meta?.role}</div>
        </components.Option>
    );
};

function Employer({ employer, onUpdate }) {
    const [hasEmployer, setHasEmployer] = useState(false);

    if (!hasEmployer) {
        return <div className={"low-shadow-container mt-3 p-3"}>
            <SectionHeader>{$$("nhis.ereferral.employer")} <Button className={"ml-3"} type={"button"} size={"sm"} variant={"primary"} onClick={()=>{
                setHasEmployer(true);
                }}>{$$("add")}</Button> </SectionHeader>
        </div>
    }


    return <div className={"low-shadow-container mt-3 p-3"}>
        <SectionHeader>{$$("nhis.ereferral.employer")} <Button className={"ml-3"} type={"button"} size={"sm"} variant={"danger"} onClick={()=>{
            setHasEmployer(false);
            onUpdate(null)}}>{$$("remove_label")}</Button> </SectionHeader>
        <FormRow>
            <FG50SmallL>
                <Input type="text" value={employer.name || ""} label={"nhis.ereferral.employer_name"}
                       required={true}
                       maxLength={255}
                       setValue={(v) => {
                           let u = { ...employer }
                           u.name = v;
                           onUpdate(u)
                       }} />
            </FG50SmallL>
            <FG50SmallR>
                <Input type="text" value={employer.code || ""} label={"nhis.ereferral.employer_indent_code"}
                       required={true}
                       maxLength={15}
                       setValue={(v) => {
                           let u = { ...employer }
                           u.code = v;
                           onUpdate(u)
                       }} />
            </FG50SmallR>
        </FormRow>
        <FormRow>
            <FG50SmallL>
                <Input type="text" value={employer.phone || ""} label={"nhis.ereferral.employer_phone"}
                       required={false}
                       maxLength={15}
                       setValue={(v) => {
                           let u = { ...employer }
                           u.phone = v;
                           onUpdate(u)
                       }} />
            </FG50SmallL>
            <FG50SmallR>
                <Input type="text" value={employer.email || ""} label={"nhis.ereferral.employer_email"}
                       required={false}
                       maxLength={255}
                       setValue={(v) => {
                           let u = { ...employer }
                           u.email = v;
                           onUpdate(u)
                       }} />
            </FG50SmallR>
        </FormRow>
        <FormRow>
            <FG50SmallL>
                <Input type="text" value={employer.position || ""} label={"nhis.ereferral.employer_position"}
                       required={true}
                       maxLength={255}
                       setValue={(v) => {
                           let u = { ...employer }
                           u.position = v;
                           onUpdate(u)
                       }} />
            </FG50SmallL>
        </FormRow>
        <Address employer={employer} address={employer.address || {}} onUpdate={(a) => {
            let u = { ...employer }
            u.address = a;
            onUpdate(u)
        }} />
    </div>
}

function Address({ employer, address, onUpdate }) {
    const {
        countyOptions,
        countryOptions,
        ekatteOptions
    } = useNhisEReferralContext();

    const getCountyOptions = useCallback((query, callback) => {
        callback(countyOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1));
    }, [countyOptions]);

    const getCountryOptions = useCallback((query, callback) => {
        callback(countryOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1));
    }, [countryOptions]);

    const getEkatteOptions = useCallback((query, callback) => {
        callback(ekatteOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1));
    }, [ekatteOptions]);

    return <div className={"low-shadow-container mt-3 p-3"}>
        <SectionHeader><small>{$$("nhis.ereferral.employer_address")}</small></SectionHeader>
        <FormRow>
            <FG50SmallL>
                <label>{$$("nhis.ereferral.employer_country")}<RequiredIndicator /></label>
                <SearchableSelectAsync
                    loadOptions={getCountryOptions}
                    isMulti={false}
                    options={countryOptions}
                    value={address.country || ""}
                    placeholder={$$("nhis.search_placeholder")}
                    onSelect={(o) => {
                        let u = { ...address }
                        u.country = o;
                        onUpdate(u)
                    }}
                    required={true}
                    defaultMenuIsOpen={false}
                />
            </FG50SmallL>
            <FG50SmallR>
                <label>{$$("nhis.ereferral.employer_county")}</label>
                <SearchableSelectAsync
                    loadOptions={getCountyOptions}
                    isMulti={false}
                    options={countyOptions}
                    value={address.county || ""}
                    placeholder={$$("nhis.search_placeholder")}
                    onSelect={(o) => {
                        let u = { ...address }
                        u.county = o;
                        onUpdate(u)
                    }}
                    required={false}
                    defaultMenuIsOpen={false}
                />
            </FG50SmallR>
        </FormRow>
        <FormRow>
            <FG50SmallL>
                <label>{$$("nhis.ereferral.employer_ekatte")}</label>
                <SearchableSelectAsync
                    loadOptions={getEkatteOptions}
                    isMulti={false}
                    options={ekatteOptions}
                    value={address.ekatte || ""}
                    placeholder={$$("nhis.search_placeholder")}
                    onSelect={(o) => {
                        let u = { ...address }
                        u.ekatte = o;
                        onUpdate(u)
                    }}
                    isClearable={address.country?.value !== 'BG'}
                    required={address.country?.value === 'BG'}
                />
            </FG50SmallL>
            <FG50SmallR>
                <Input type="text" value={address.city || ""} label={"nhis.ereferral.employer_city"}
                       required={true}
                       maxLength={255}
                       setValue={(v) => {
                           let u = { ...address }
                           u.city = v;
                           onUpdate(u)
                       }} />
            </FG50SmallR>
        </FormRow>
        <FormRow>
            <FG50SmallL>
                <Input type="text" value={address.line || ""} label={"nhis.ereferral.employer_line"}
                       required={true}
                       maxLength={255}
                       setValue={(v) => {
                           let u = { ...address }
                           u.line = v;
                           onUpdate(u)
                       }} />
            </FG50SmallL>
            <FG50SmallR>
                <Input type="text" value={address.postalCode || ""} label={"nhis.ereferral.employer_postalCode"}
                       required={true}
                       maxLength={20}
                       setValue={(v) => {
                           let u = { ...address }
                           u.postalCode = v;
                           onUpdate(u)
                       }} />
            </FG50SmallR>
        </FormRow>
    </div>
}

function AttachedDocuments({ documents, onUpdate, onAddNew, onRemove, lang }) {

    const [selectedIdx, setSelectedIdx] = useState(0);
    const [showRemoveDialog, setShowRemoveDialog] = useState(false)

    if (!documents || documents.length === 0) {
        return <div className="mb-4"><SectionHeader>{$$("nhis.ereferral.documents")} <span
            className="float-right">
            <button type="button" className="btn btn-sm btn-primary btn-sm" onClick={() => {
                onAddNew()
            }}>{$$("add")}</button>
        </span></SectionHeader>
        </div>
    }

    return <React.Fragment>
        <SectionHeader>{$$("nhis.ereferral.documents")} <span className="float-right">
            <button type="button" className="btn btn-primary btn-sm" onClick={() => {
                onAddNew();
                setSelectedIdx(selectedIdx + 1);
            }}>{$$("add")}</button>
            <button type="button" className="ml-2 btn btn-danger btn-sm" onClick={() => {
                //setSelectedIdx(selectedIdx > 0 ? selectedIdx - 1 : 0);
                //onRemove(selectedIdx)
                setShowRemoveDialog(true);
            }}>{$$("remove_label")}</button>
        </span></SectionHeader>
        <ButtonGroup>{documents.map((d, i) => {
            return <Button key={i} type="button" size={"sm"} active={i === selectedIdx} variant={"outline-primary"}
                           onClick={() => {
                               setSelectedIdx(i)
                           }}>{i + 1}</Button>
        })}
        </ButtonGroup>
        {documents.map((d, i) => {
            return <div key={i} className={selectedIdx === i ? "" : "d-none"}>
                <Document document={d}
                          onUpdate={(d) => {
                              onUpdate(d, i)
                          }}
                          lang={lang}
                />
            </div>
        })}
        {showRemoveDialog && <CenteredModal title={$$('delete_note_modal_header')}
                                            show={true}
                                            onHide={() => {
                                                setShowRemoveDialog(false)
                                            }}
                                            onConfirm={() => {
                                                setShowRemoveDialog(false)
                                                setSelectedIdx(selectedIdx > 0 ? selectedIdx - 1 : 0);
                                                onRemove(selectedIdx)
                                            }}
                                            backdropClassName={"confirm-over-other-modal"}
        >
            {$$('nhis.ereferral.remove_document_modal_message')}
        </CenteredModal>}
    </React.Fragment>
}

function Document({ document, onUpdate, lang }) {
    const {
        documentTypeOptions,
    } = useNhisEReferralContext();

    return <>
        <FormRow>
            <FG50SmallL>
                <label>{$$("nhis.ereferral.documentType")}<RequiredIndicator /></label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        options={documentTypeOptions}
                        class="custom-select-sm"
                        value={document.type || ""}
                        required={true}
                        onChange={({ value }) => {
                            let doc = { ...document }
                            doc.type = value;
                            onUpdate(doc);
                        }}
                />
            </FG50SmallL>
            <FG50SmallR>
                <label>{$$("nhis.ereferral.document_date")}</label>
                <div><DatePicker
                    locale={lang}
                    selected={document.date ? new Date(document.date) : null}
                    onChange={date => {
                        let d = _.cloneDeep(document)
                        d.date = date;
                        onUpdate(d);
                    }}
                    selectsStart
                    startDate={document.date ? new Date(document.date) : new Date()}
                    isClearable
                    dateFormat="dd/MM/yyyy"
                    placeholderText={$$('nhis.ereferral.document_date')}
                    className="form-control form-control-sm w-100" />
                </div>
            </FG50SmallR>
        </FormRow>
        <FormRow>
            <FG50SmallL>
                <Input type="text" value={document.number || ""} label={"nhis.ereferral.document_number"}
                       required={true}
                       maxLength={36}
                       setValue={(v) => {
                           let u = { ...document }
                           u.number = v;
                           onUpdate(u)
                       }} />
            </FG50SmallL>
            <FG50SmallR>
                <label>{$$("nhis.ereferral.document_isNrn")}</label>
                <BoolSwitch checked={document.isNrN} onChange={() => {
                    let d = _.cloneDeep(document)
                    d.isNrN = !document.isNrN;
                    onUpdate(d);
                }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false} />
            </FG50SmallR>
        </FormRow>
        <FG>
            <InputTextArea type="textarea" value={document.note || ""} label={"nhis.ereferral.document_description"}
                           required={false}
                           maxLength={2000}
                           rows={4}
                           setValue={(v) => {
                               let u = { ...document }
                               u.note = v;
                               onUpdate(u)
                           }} />
        </FG>
    </>
}

function Laboratory({ laboratory, financingSource, onUpdate }) {

    const {
        activityCodeOptions,
        activityCodeOptionsFiltered
    } = useNhisEReferralContext();

    let options = financingSource === "2" ? activityCodeOptionsFiltered : activityCodeOptions;

    const getActivityCodeOptions = useCallback((query, callback) => {
        callback(options.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1));
    }, [activityCodeOptions]);

    return <FG>
        <label>{$$("nhis.ereferral.activity")}<RequiredIndicator /></label>
        <SearchableSelectAsync
            loadOptions={getActivityCodeOptions}
            isMulti={true}
            options={options}
            max={financingSource === "2" ? 6 : null}
            value={laboratory || []}
            placeholder={$$("nhis.search_placeholder")}
            onSelect={(o) => onUpdate(o, {laboratory: o.map((opt) => opt.label)})}
            required={true}
        />
    </FG>
}

/**
 * Renders a form group containing a label and a searchable dropdown for selecting a medical specialty.
 * Uses context to fetch specialty options and filters them based on user input.
 * @param {Object} props - Props object
 * @param {Object} props.consultation - The current selected consultation option.
 * @param {Function} props.onUpdate - Callback function to handle updates when a new option is selected.
 * @returns {JSX.Element} - Rendered form group with label and searchable dropdown.
 */
function Consultation({ consultation, onUpdate }) {
    const { specialtyOptions } = useNhisEReferralContext();

    const getSpecialtyOptions = useCallback((query, callback) => {
        const filteredOptions = specialtyOptions.filter(opt =>
            opt.label.toLowerCase().includes(query.toLowerCase().trim())
        );
        callback(filteredOptions);
    }, [specialtyOptions]);

    return (
        <FG>
            <label>{$$("nhis.ereferral.qualificationTarget")}<RequiredIndicator /></label>
            <SearchableSelectAsync
                optionComponent={SpecialtyOption}
                loadOptions={getSpecialtyOptions}
                isMulti={false}
                options={specialtyOptions}
                value={consultation || null}
                placeholder={$$("nhis.search_placeholder")}
                onSelect={(opt) => {onUpdate(opt, {consultation: opt.label})}}
                required={true}
            />
        </FG>
    );
}

function SpecializedActivities({ specializedActivities, onUpdate, viewModel }) {

    const {
        specialtyOptions,
        specializedActivityCodeOptions
    } = useNhisEReferralContext();

    const getSpecialtyOptions = useCallback((query, callback) => {
        callback(specialtyOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1));
    }, [specialtyOptions]);

    const getSpecializedActivityCodeOptions = useCallback((query, callback) => {
        callback(specializedActivityCodeOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1));
    }, [specializedActivityCodeOptions]);

    return <>
        <FG>
            <label>{$$("nhis.ereferral.qualificationTarget")}<RequiredIndicator /></label>
            <SearchableSelectAsync
                optionComponent={SpecialtyOption}
                loadOptions={getSpecialtyOptions}
                isMulti={false}
                options={specialtyOptions}
                value={specializedActivities?.qualification || null}
                placeholder={$$("nhis.search_placeholder")}
                onSelect={(v) => {
                    let vm = {...viewModel};
                    let vmSA  = {...(vm.specializedActivities || {})}
                    vm.specializedActivities = vmSA;
                    vmSA.qualification = v.label;
                    onUpdate({ ...(specializedActivities ?? {}), qualification: v }, vm)
                }}
                required={true}
            />
        </FG>
        <FG>
            <label>{$$("nhis.ereferral.specializedActivityCode")}<RequiredIndicator /></label>
            <SearchableSelectAsync
                loadOptions={getSpecializedActivityCodeOptions}
                isMulti={false}
                options={specializedActivityCodeOptions}
                value={specializedActivities?.code || null}
                placeholder={$$("nhis.search_placeholder")}
                onSelect={(v) => {
                    let vm = {...viewModel};
                    let vmSA  = {...(vm.specializedActivities || {})}
                    vm.specializedActivities = vmSA;
                    vmSA.code = v.label;
                    onUpdate({ ...(specializedActivities ?? {}), code: v }, vm)
                }}
                required={true}
            />
        </FG>
    </>
}

function Hospitalization({ hospitalization, onUpdate, viewModel }) {

    const {
        admissionTypeOptions,
        directedByOptions,
        clinicalPathwayOptions,
        outpatientProcedureOptions
    } = useNhisEReferralContext();

    const getClinicalPathwayOptions = useCallback((query, callback) => {
        callback(clinicalPathwayOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1));
    }, [clinicalPathwayOptions]);

    const getOutpatientProcedureOptions = useCallback((query, callback) => {
        callback(outpatientProcedureOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1));
    }, [outpatientProcedureOptions]);



    return <>
        <FormRow>
            <FG50SmallL>
                <label>{$$("nhis.ereferral.admissionType")}<RequiredIndicator /></label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        options={admissionTypeOptions}
                        class="custom-select-sm"
                        value={hospitalization?.admissionType || ""}
                        required={true}
                        onChange={({ value }) => {
                            let vm = {...viewModel};
                            let vmH = viewModel.hospitalization || {};
                            vm.hospitalization = vmH;
                            vmH.admissionType = admissionTypeOptions.find((o) => o.value === value)?.label;
                            let v = hospitalization ? { ...hospitalization } : {}
                            v.admissionType = value;
                            onUpdate(v, vm);
                        }}
                />
            </FG50SmallL>
            <FG50SmallR>
                <label>{$$("nhis.ereferral.directedBy")}<RequiredIndicator /></label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        options={directedByOptions}
                        class="custom-select-sm"
                        value={hospitalization?.directedBy || ""}
                        required={true}
                        onChange={({ value }) => {
                            let vm = {...viewModel};
                            let vmH = viewModel.hospitalization || {};
                            vm.hospitalization = vmH;
                            vmH.directedBy = directedByOptions.find((o) => o.value === value)?.label;
                            let v = hospitalization ? { ...hospitalization } : {}
                            v.directedBy = value;
                            onUpdate(v, vm);
                        }}
                />
            </FG50SmallR>
        </FormRow>
        <FG>
            <label>{$$("nhis.ereferral.clinicalPathway")}</label>
            <SearchableSelectAsync
                loadOptions={getClinicalPathwayOptions}
                isMulti={false}
                isClearable={true}
                options={clinicalPathwayOptions}
                value={hospitalization?.clinicalPathway || null}
                placeholder={$$("nhis.search_placeholder")}
                onSelect={(value) => {
                    let vm = {...viewModel};
                    let vmH = viewModel.hospitalization || {};
                    vm.hospitalization = vmH;
                    vmH.clinicalPathway = value?.label;
                    let v = hospitalization ? { ...hospitalization } : {}
                    v.clinicalPathway = value;
                    onUpdate(v, vm);
                }}
                required={false}
                defaultMenuIsOpen={false}
            />
        </FG>
        <FG>
            <label>{$$("nhis.ereferral.outpatientProcedure")}</label>
            <SearchableSelectAsync
                loadOptions={getOutpatientProcedureOptions}
                isMulti={true}
                options={outpatientProcedureOptions}
                value={hospitalization?.outpatientProcedure || null}
                placeholder={$$("nhis.search_placeholder")}
                onSelect={(value) => {
                    let vm = {...viewModel};
                    let vmH = viewModel.hospitalization || {};
                    vm.hospitalization = vmH;
                    vmH.outpatientProcedure = value?.map(v => v.label);
                    let v = hospitalization ? { ...hospitalization } : {}
                    v.outpatientProcedure = value;
                    onUpdate(v, vm);
                }}
                required={false}
                defaultMenuIsOpen={false}
            />
        </FG>
    </>
}


function MedicalExpertise({ medicalExpertise, financingSource, onUpdate, viewModel }) {

    const {
        specialtyOptions,
        examTypeOptions,
    } = useNhisEReferralContext();

    const getSpecialtyOptions = useCallback((query, callback) => {
        callback(specialtyOptions.filter(opt => opt.label.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1));
    }, [specialtyOptions]);

    return <>
        <FG>
            <label>{$$("nhis.ereferral.qualificationTargets")}<RequiredIndicator /></label>
            <SearchableSelectAsync
                optionComponent={SpecialtyOption}
                loadOptions={getSpecialtyOptions}
                isMulti={true}
                options={specialtyOptions}
                value={medicalExpertise?.qualification || []}
                placeholder={$$("nhis.search_placeholder")}
                onSelect={(value) => {
                    let vm = {...viewModel};
                    let vmH = viewModel.medicalExpertise || {};
                    vm.medicalExpertise = vmH;
                    vmH.qualification = value?.map(v => v.label);
                    let v = medicalExpertise ? { ...medicalExpertise } : {}
                    v.qualification = value;
                    onUpdate(v, vm);
                }}
                required={true}
                defaultMenuIsOpen={false}
            />
        </FG>
        <FG>
            <label>{$$("nhis.ereferral.examType")}</label>
            <Select placeHolder={$$("nhis.select")}
                    emptyOption={true}
                    enableClear={financingSource !== '2'}
                    options={examTypeOptions}
                    class="custom-select-sm"
                    value={medicalExpertise?.examType || ""}
                    required={financingSource === '2'}
                    onChange={({ value }) => {
                        let vm = {...viewModel};
                        let vmH = viewModel.medicalExpertise || {};
                        vm.medicalExpertise = vmH;
                        vmH.examType = examTypeOptions.find((o) => o.value === value)?.label;
                        //vmH.examType = name;
                        let v = medicalExpertise ? { ...medicalExpertise } : {}
                        v.examType = value;
                        onUpdate(v, vm);
                    }}
            />
        </FG>
    </>
}

function WorkIncapacity({ workIncapacity, onUpdate, lang, viewModel }) {
    const {
        workIncapacityReasonOptions,
        workIncapacityAddressTypeOptions,
    } = useNhisEReferralContext();

    return <>
        <FormRow>
            <FG50SmallL>
                <label>{$$("nhis.ereferral.workIncapacityReason")}<RequiredIndicator /></label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        options={workIncapacityReasonOptions}
                        class="custom-select-sm"
                        value={workIncapacity?.reason || ""}
                        required={true}
                        onChange={({ value }) => {
                            let vm = {...viewModel};
                            let vmH = viewModel.workIncapacity || {};
                            vm.workIncapacity = vmH;
                            vmH.reason = workIncapacityReasonOptions.find((o) => o.value === value)?.label;
                            let v = workIncapacity ? { ...workIncapacity } : {}
                            v.reason = value;
                            onUpdate(v, vm);
                        }}
                />
            </FG50SmallL>
            <FG50SmallR>
                <label>{$$("nhis.ereferral.workIncapacityAddressType")}<RequiredIndicator /></label>
                <Select placeHolder={$$("nhis.select")}
                        emptyOption={true}
                        options={workIncapacityAddressTypeOptions}
                        class="custom-select-sm"
                        value={workIncapacity?.addressType || ""}
                        required={true}
                        onChange={({ value }) => {
                            let vm = {...viewModel};
                            let vmH = viewModel.workIncapacity || {};
                            vm.workIncapacity = vmH;
                            vmH.addressType = workIncapacityAddressTypeOptions.find((o) => o.value === value)?.label;
                            let v = workIncapacity ? { ...workIncapacity } : {}
                            v.addressType = value;
                            onUpdate(v, vm);
                        }}
                />
            </FG50SmallR>
        </FormRow>
        <FormRow>
            <FG50SmallL>
                <label>{$$("nhis.ereferral.home_visit")}</label>
                <BoolSwitch checked={workIncapacity?.isHomeVisitNecessary} onChange={() => {
                    let vm = {...viewModel};
                    let vmH = viewModel.workIncapacity || {};
                    vm.workIncapacity = vmH;
                    let v = workIncapacity ? { ...workIncapacity } : {}
                    v.isHomeVisitNecessary = !workIncapacity?.isHomeVisitNecessary;
                    vmH.isHomeVisitNecessary = v.isHomeVisitNecessary
                    onUpdate(v, vm);
                }} labelOff={$$("nhis.no_label")} labelOn={$$("nhis.yes_label")} offFirst={false} />
            </FG50SmallL>
        </FormRow>
        {<Employer employer={workIncapacity?.employer || {}} onUpdate={(e) => {
            let v = workIncapacity ? { ...workIncapacity } : {}
            v.employer = e;
            onUpdate(v, {...viewModel});
        }} />}
        {/*<div className={"low-shadow-container p-3 mt-4 mb-4"}>
            <AttachedDocuments documents={workIncapacity?.documents || []}
                               lang={lang}
                               onAddNew={() => {
                                   let v = workIncapacity ? { ...workIncapacity } : {}
                                   let documents = v.documents ? [...v.documents, {}] : [{}]
                                   v.documents = documents
                                   onUpdate(v, {...viewModel});
                               }}
                               onRemove={(i) => {
                                   let updated = [...workIncapacity.documents];
                                   updated.splice(i, 1);
                                   let v = { ...workIncapacity }
                                   v.documents = [...updated];
                                   onUpdate(v, {...viewModel});
                               }}
                               onUpdate={(d, i) => {
                                   let updated = [...workIncapacity.documents];
                                   updated[i] = d
                                   let v = { ...workIncapacity }
                                   v.documents = [...updated];
                                   onUpdate(v, {...viewModel});
                               }} />
        </div>*/}
    </>
}

function CloseConfirmModal({onConfirm, onClose}) {
    return <CenteredModal title={$$('nhis.ereferral.close_ereferral_title')}
                          show={true}
                          onHide={onClose}
                          onConfirm={onConfirm}
                          confirmBtnLabel={$$("confirm_btn_label")}
                          confirmBtnClass="danger"
    >
        {$$('nhis.ereferral.close_ereferral_body')}
    </CenteredModal>
}